import { useLocation, useParams } from 'react-router-dom';

import { AlertList } from '../notes/AlertList';
import { IUserModel } from '../../interfaces/IUser';

function AlertListWrapper(props: { user: IUserModel }) {
    const { type } = useParams();
    const { key } = useLocation();
    return (
        <AlertList
            alertListType={parseInt(type || '0')}
            user={props.user}
            refreshIdentifier={key}
        />
    );
}

export default AlertListWrapper;
