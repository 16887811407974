import { IAssociatedOrganizationModel, IOrganizationModel } from '../interfaces/IOrganization';
import { IDataScopeLookupModel, ILookupModel, IMultiSelectOptions } from '../interfaces/ILookup';
import { PlaintiffsFirmDisplayMode, ReportCaseDetailsLastColumn } from './Constants';

import { IUserModel } from '../interfaces/IUser';
import UIHelper from './UIHelper';

export default class CheckmateSelectHelper {
    static getBooleanOptions() {
        const options: IMultiSelectOptions[] = [];
        options.push({ label: 'Yes', value: 'true', id: 1 });
        options.push({ label: 'No', value: 'false', id: 0 });
        return options;
    }

    static getBooleanValueFromString(valueString?: string): IMultiSelectOptions | undefined {
        if (!valueString) {
            return undefined;
        }

        if (valueString.toUpperCase() === 'YES' || valueString.toUpperCase() === 'TRUE') {
            return { label: 'Yes', value: 'true', id: 1 };
        }

        return { label: 'No', value: 'false', id: 0 };
    }

    static getBooleanValue(value?: boolean): IMultiSelectOptions | undefined {
        if (value === true) {
            return { label: 'Yes', value: 'true', id: 1 };
        } else if (value === false) {
            return { label: 'No', value: 'false', id: 0 };
        }
        return undefined;
    }

    static getLookupOptions(
        values: ILookupModel[] | IDataScopeLookupModel[],
        includeBlankOption?: boolean
    ) {
        const options: IMultiSelectOptions[] = [];
        for (let i = 0; i < values.length; i++) {
            options.push({
                label: values[i].displayName!,
                value: values[i].id.toString(),
                id: values[i].id,
                guid: values[i].guid!,
            });
        }
        if (includeBlankOption) options.push({ label: '<Blank>', value: 'Blank', id: -1 });

        return options;
    }

    static getSelectedValueById(options: ILookupModel[] | IDataScopeLookupModel[], value: number) {
        if (value === -1) return { label: '<Blank>', value: 'Blank', id: -1 };
        const match = options.find((x) => x.id === value);
        if (match) {
            const value: IMultiSelectOptions = {
                label: match.displayName!,
                value: match.id.toString()!,
                id: match.id!,
                guid: match.guid!,
            };
            return value;
        }
    }

    static getOrgOptions(values: IAssociatedOrganizationModel[] | IOrganizationModel[]) {
        const options: IMultiSelectOptions[] = [];
        for (let i = 0; i < values.length; i++) {
            options.push({
                label: values[i].name!,
                value: values[i].guid!,
                id: i,
                guid: values[i].guid!,
            });
        }
        return options;
    }

    static getSelectedOrgValue(
        options: IAssociatedOrganizationModel[] | IOrganizationModel[],
        value: string
    ) {
        for (let index = 0; index < options.length; index++) {
            const element = options[index];
            if (element.guid === value) {
                const value: IMultiSelectOptions = {
                    label: element.name!,
                    value: element.guid!,
                    id: 0,
                    guid: element.guid!,
                };
                return value;
            }
        }
    }

    static getUserOptions(values: IUserModel[]) {
        const options: IMultiSelectOptions[] = [];
        for (let i = 0; i < values.length; i++) {
            const userDisplayName = UIHelper.getUserDisplayName(values[i]);
            options.push({
                label: userDisplayName,
                value: values[i].guid!,
                id: i,
                guid: values[i].guid!,
            });
        }
        return options;
    }

    static getSelectedUserValue(options: IUserModel[], value: string) {
        const match = options.find((x) => x.guid === value);
        if (match) {
            const userDisplayName = UIHelper.getUserDisplayName(match);
            const value: IMultiSelectOptions = {
                label: userDisplayName,
                value: match.guid!,
                id: 0,
                guid: match.guid!,
            };
            return value;
        }
    }

    static getPlaintiffsFirmDisplayOptions = () => {
        const options: IMultiSelectOptions[] = [];
        options.push({
            label: 'Plaintiffs Firm',
            value: PlaintiffsFirmDisplayMode.Local.toString(),
            id: PlaintiffsFirmDisplayMode.Local,
        });
        options.push({
            label: 'National Plaintiffs Firm',
            value: PlaintiffsFirmDisplayMode.National.toString(),
            id: PlaintiffsFirmDisplayMode.National,
        });
        options.push({
            label: 'Both',
            value: PlaintiffsFirmDisplayMode.Both.toString(),
            id: PlaintiffsFirmDisplayMode.Both,
        });
        options.push({
            label: 'National Plaintiffs Firm (If Exists) or Plaintiffs Firm ',
            value: PlaintiffsFirmDisplayMode.Hierarchy.toString(),
            id: PlaintiffsFirmDisplayMode.Hierarchy,
        });
        return options;
    };

    static getAdditionalCaseIdentifierOptions = () => {
        const options: IMultiSelectOptions[] = [];
        options.push({
            label: 'Product/Premises',
            value: ReportCaseDetailsLastColumn.ProductPremises.toString(),
            id: ReportCaseDetailsLastColumn.ProductPremises,
        });
        options.push({
            label: 'Occupation',
            value: ReportCaseDetailsLastColumn.Occupation.toString(),
            id: ReportCaseDetailsLastColumn.Occupation,
        });
        options.push({
            label: 'Unique Case ID',
            value: ReportCaseDetailsLastColumn.UniqueCaseID.toString(),
            id: ReportCaseDetailsLastColumn.UniqueCaseID,
        });
        options.push({
            label: 'Alternate Case ID Number',
            value: ReportCaseDetailsLastColumn.AlternateCaseIDNumber.toString(),
            id: ReportCaseDetailsLastColumn.AlternateCaseIDNumber,
        });
        options.push({
            label: 'Alternate Case ID Number (3)',
            value: ReportCaseDetailsLastColumn.AlternateCaseIDNumber3.toString(),
            id: ReportCaseDetailsLastColumn.AlternateCaseIDNumber3,
        });
        options.push({
            label: 'Alternate Case ID Number (4)',
            value: ReportCaseDetailsLastColumn.AlternateCaseIDNumber4.toString(),
            id: ReportCaseDetailsLastColumn.AlternateCaseIDNumber4,
        });
        return options;
    };

    static getSelectOptionStyles(provided: any, state: any) {
        const padding = {
            paddingBottom: 2,
            paddingTop: 2,
        };
        const styles = {
            ...provided,
            ...padding,
            fontWeight: state.isSelected ? 'bold' : 'normal',
        };

        if (state.isFocused && !state.isSelected) {
            styles.backgroundColor = '#C7C8CA';
            styles['&:hover'] = {
                ...provided,
                ...padding,
                backgroundColor: '#C7C8CA',
            };
        } else if (state.isSelected) {
            styles.backgroundColor = '#F04C25';
            styles['&:hover'] = {
                ...provided,
                ...padding,
                backgroundColor: '#F04C25',
            };
        }

        return styles;
    }

    
    static getGroupedSelectOptionStyles(provided: any, state: any) {
        const padding = {
            paddingBottom: 2,
            paddingTop: 2,
            paddingLeft: 20
        };
        const styles = {
            ...provided,
            ...padding,
            fontWeight: state.isSelected ? 'bold' : 'normal',
        };

        if (state.isFocused && !state.isSelected) {
            styles.backgroundColor = '#C7C8CA';
            styles['&:hover'] = {
                ...provided,
                ...padding,
                backgroundColor: '#C7C8CA',
            };
        } else if (state.isSelected) {
            styles.backgroundColor = '#F04C25';
            styles['&:hover'] = {
                ...provided,
                ...padding,
                backgroundColor: '#F04C25',
            };
        }

        return styles;
    }
}
