import * as React from 'react';

import CheckmateSelect from './CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import { FileUpload } from '../shared/FileUploader';
import { IDocumentModel } from '../../interfaces/ICase';
import { IMultiSelectOptions } from '../../interfaces/ILookup';
import { IValidation } from '../../interfaces/IError';
import { Modal } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { useDocumentCategoryTypes } from '../../shared/react-query-hooks/useDocumentCategoryTypes';
import { useEffect } from 'react';
import { useLoading } from '../../contexts/LoadingContext';

interface IUploadWorkProductModalProps {
    uploadUrl: string;
    entityTypeId: number;
    open: boolean;
    onHide: () => void;
    onSaveComplete: (document: IDocumentModel) => void;
    currentWorkProduct: IDocumentModel;
}

function UploadWorkProductModal(props: IUploadWorkProductModalProps) {
    const [local, setLocal] = React.useState<IDocumentModel>(cloneDeep(props.currentWorkProduct));
    const [validation, setValidation] = React.useState<IValidation>({});

    const categoryDocumentTypes = useDocumentCategoryTypes(props.entityTypeId);
    const loading = useLoading();

    const categoryDocumentTypesExist = (categoryDocumentTypes?.data?.length ?? 0) > 0;
    const options = CheckmateSelectHelper.getLookupOptions(categoryDocumentTypes.data ?? [], false);
    const selectedOptionIds = local.documentCategoryTypeIds;
    const selectedCategoryOptions = options.filter((x) => selectedOptionIds?.includes(x.id));

    const handleSaveDocument = async () => {
        const validation: IValidation = {};
        let hasErrors = false;
        // client side validations

        // File is required for a new document
        if (!local.guid && local.file == undefined) {
            validation.file = ['File is required '];
            hasErrors = true;
        }

        if (!local.title) {
            validation.title = ['Title is required '];
            hasErrors = true;
        }

        if (hasErrors) {
            setValidation(validation);
            return;
        }
        const data = new FormData();
        if (local.file != undefined) {
            data.append('file', local.file);
        }
        data.append('document', JSON.stringify(local));

        loading.showLoading();
        const responseFromServer = await fetch(props.uploadUrl, {
            method: 'POST',
            body: data,
        }).then((response) => {
            return response.json();
        });
        loading.hideLoading();
        if (responseFromServer.message === 'Success') {
            props.onSaveComplete(cloneDeep(responseFromServer.data));
            handleCancel();
        } else {
            validation.model = [responseFromServer.message];
            setValidation(validation);
        }
    };

    const handleCancel = () => {
        setLocal({});
        props.onHide();
    };
    const handleAttachmentChange = (files: FileList) => {
        if (!files || files.length === 0) return;

        setValidation((prev) => ({ ...prev, file: [''] }));
        setLocal((prev) => ({ ...prev, fileName: files[0].name, file: files[0] }));
    };
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocal((prev) => ({ ...prev, title: e.target.value }));
    };
    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocal((prev) => ({ ...prev, description: e.target.value }));
    };
    const handleDocumentCategoriesChange = (options: IMultiSelectOptions[]) => {
        const localCopy = cloneDeep(local);
        if (!options) {
            localCopy.documentCategoryTypeIds = [];
        } else {
            localCopy.documentCategoryTypeIds = options.map((x) => x.id);
        }
        setLocal(localCopy);
    };

    useEffect(() => {
        setLocal(cloneDeep(props.currentWorkProduct) ?? ({} as IDocumentModel));
        setValidation({});
    }, [props.currentWorkProduct]);

    return (
        <Modal
            centered
            show={props.open}
            dialogClassName="official-case-doc-dialog"
            size="lg"
            onHide={props.onHide}
            backdrop={false}
        >
            <Modal.Header>
                <Modal.Title>Upload Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span className="text-danger">{validation.model}</span>
                </div>
                <div className="form-group mb-2">
                    <label className="control-label" htmlFor="title">
                        Title*
                    </label>
                    <input
                        className="form-control"
                        type="text"
                        name="title"
                        value={local.title || ''}
                        onChange={handleTitleChange}
                    />
                    <span className="text-danger">{validation.title}</span>
                </div>
                <div className="form-group mb-2" hidden={!categoryDocumentTypesExist}>
                    <label className="control-label" htmlFor="categories">
                        Document Category
                    </label>
                    <CheckmateSelect
                        isMulti={true}
                        options={options}
                        value={selectedCategoryOptions}
                        onChange={handleDocumentCategoriesChange}
                    />
                    <span className="text-danger">{validation.categories}</span>
                </div>
                <div className="form-group mb-2">
                    <label className="control-label" htmlFor="description">
                        Description
                    </label>
                    <input
                        className="form-control"
                        type="text"
                        name="description"
                        value={local.description || ''}
                        onChange={handleDescriptionChange}
                    />
                </div>
                <div className="form-group">
                    <label className="control-label" htmlFor="file">
                        {'File' + (local.guid ? '' : '*')}
                    </label>
                    {props.currentWorkProduct.fileName ? (
                        <div>{props.currentWorkProduct.fileName}</div>
                    ) : (
                        <FileUpload onChange={handleAttachmentChange} />
                    )}
                    <span className="text-danger">{validation.file}</span>
                </div>

                <div className="dialog-btn-div margin-top-sm">
                    <button className="btn btn-default float-end" onClick={handleCancel}>
                        Cancel
                    </button>
                    <button className="btn btn-orange float-end" onClick={handleSaveDocument}>
                        Save
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default UploadWorkProductModal;
