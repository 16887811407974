import { HubConnection } from '@microsoft/signalr';
import { IUserModel } from '../interfaces/IUser';
import { NavMenu } from './NavMenu';
import { useLocation } from 'react-router-dom';

interface INavMenuProps {
    user?: IUserModel;
    isAuthenticated?: boolean;
    alertHubConnection: HubConnection;
    setIsLocalSwitchZone: () => void;
}

export default function NavMenuWrapper({
    user,
    isAuthenticated,
    alertHubConnection,
    setIsLocalSwitchZone,
}: INavMenuProps) {
    const { key } = useLocation();

    return (
        <NavMenu
            user={user}
            isAuthenticated={isAuthenticated}
            alertHubConnection={alertHubConnection}
            locationKey={key}
            setIsLocalSwitchZone={setIsLocalSwitchZone}
        />
    );
}
