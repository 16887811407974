import * as React from 'react';

import {
    ApplicationHelpType,
    AssertionDescriptorUsageTypesEnum,
    AssertionDescriptorValueDisplayTypes,
    AssertionDescriptorValueSourceTypeEnum,
    AssertionDescriptors,
    CaseFileStaticItems,
    CaseStatusTypesEnum,
    ConditionSelectorEnums,
    DataScopesEnum,
    EmptyGuid,
    FavoriteTypesEnum,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    OrganizationSettingNames,
    OrganizationTypesEnum,
    QueryToolDefaultColumnList,
    QueryToolModeEnum,
    QueryToolRedirectTypeEnum,
    QueryTypes,
    StrategyTypes,
    TutorialTopics,
    UserRightsEnum,
} from '../../utilities/Constants';
import {
    IAssertionDescriptorModel,
    IAssertionDescriptorValueModel,
} from '../../interfaces/IAssertionDescriptor';
import {
    IAssociatedOrganizationModel,
    IOrganizationSettingModel,
} from '../../interfaces/IOrganization';
import {
    ICaseExpertModel,
    ICaseExpertViewDetailModel,
    IViewCaseExpertModelWithDefaultEdit,
} from '../../interfaces/ICaseExpert';
import {
    ICaseExpertsUISearchModel,
    IDepositionUISearchModel,
    INoteUISearchModel,
    IQueryColumnDefinitionModel,
    IQueryFilterCriteria,
    IQueryFilterCriteriaSelectionValue,
    IQueryResultModel,
    IQuerySettingsModel,
} from '../../interfaces/IQuery';
import { ICaseModel, ICaseStatusLookupModel, IDepositionModel } from '../../interfaces/ICase';
import {
    ILookupModel,
    IMultiSelectOptions,
    IStaticItemLookupModel,
} from '../../interfaces/ILookup';
import {
    INoteCategoryTypeModel,
    INoteModel,
    INoteSubCategoryTypeModel,
} from '../../interfaces/INote';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import { CaseDepositionListWrapper } from '../../components/case/static-sections/CaseDepositionListWrapper';
import { CaseEasyUpdate } from '../case/CaseEasyUpdate';
import { CaseExpertEditor } from '../../components/case/static-sections/case-experts/CaseExpertEditor';
import { CaseExpertsList } from '../../components/case/static-sections/case-experts/CaseExpertsList';
import CaseHelper from '../../utilities/CaseHelper';
import { CheckmateDialog } from '../../components/shared/dialog';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import Common from '../../stores/Common';
import { ConditionSelector } from './ConditionSelector';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { ExpertsCriteriaSelector } from './ExpertsCriteriaSelector';
import { ExportNotes } from '../../components/shared/ExportNotes';
import { Favorite } from '../../components/shared/Favorite';
import { Help } from '../../components/shared/Help';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import { NoteCategoryAdditionalInfoDetailDialog } from '../../components/notes/NoteCategoryAdditionalInfoDetailDialog';
import { NoteEditor } from '../../components/notes/NoteEditor';
import NoteHelper from '../../utilities/NoteHelper';
import { NoteListTable } from '../../components/notes/NoteListTable';
import Sort from '../../stores/Sort';
import SortData from '../../components/shared/SortData';
import UIHelper from '../../utilities/UIHelper';
import ValidateUtils from '../../shared/validations';
import { cloneDeep } from 'lodash';

const _apiClient = new ApiClient();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InternalDatabaseFields_CaseName = -1;
const InternalDatabaseFields_CaseStatus_Status = -2;
const InternalDatabaseFields_CaseStatus_SubStatus = -3;
const InternalDatabaseFields_PackageSettlementGuid = -4;

const maxExportRecordCount = 1000;
const maxDisplayRecordCount = 1000;
const maxRecordCountForWebProcessing = 10000;

const QueryStaticItemFields = Object.assign({}, CaseFileStaticItems);

const conditionOpts = [
    {
        //id: 1,
        label: ConditionSelectorEnums.EqualTo,
        value: ConditionSelectorEnums.EqualTo,
    },
    {
        //id: 2,
        label: ConditionSelectorEnums.NotEqualTo,
        value: ConditionSelectorEnums.NotEqualTo,
    },
    {
        //id: 3,
        label: ConditionSelectorEnums.Blank,
        value: ConditionSelectorEnums.Blank,
    },
    {
        //id: 4,
        label: ConditionSelectorEnums.NotBlank,
        value: ConditionSelectorEnums.NotBlank,
    },
];

interface IQueryProps {
    user: IUserModel;
    queryFilterCriteriaSelected?: IQueryFilterCriteria[];
    displayColumnsSelected?: IQueryFilterCriteria[];
    redirectType?: QueryToolRedirectTypeEnum;
    title?: string;
    redirectUrl?: string;
    redirectComponentCurrentState?: string;
    refreshIdentifier?: string;
}

interface IQueryState {
    acknowledgmentStatusTypes: ILookupModel[];
    allDefenseCounselsForZone: IQueryFilterCriteriaSelectionValue[];
    allUsers: IUserModel[];
    allDefenseCounsels: IAssociatedOrganizationModel[];
    approvalStatusTypes: ILookupModel[];
    assertionDescriptors: IAssertionDescriptorModel[];
    caseExpertsResults: ICaseExpertModel[];
    caseExpertsSearchCriteria?: ICaseExpertsUISearchModel;
    caseResults: IQueryResultModel;
    caseResultsCount: number;
    caseStatuses: ICaseStatusLookupModel[];
    caseStatusesForQueryCriteria: IQueryFilterCriteriaSelectionValue[];
    currentCaseExpert?: IViewCaseExpertModelWithDefaultEdit;
    currentFavoriteDetailsString: string;
    currentNote: INoteModel;
    depositionCategories: IMultiSelectOptions[];
    depositionResults?: IDepositionModel[];
    depositionSearchCriteria?: IDepositionUISearchModel;
    depositionStatusTypes: IMultiSelectOptions[];
    displayColumnsAll: IQueryFilterCriteria[];
    displayColumnsSelected: IQueryFilterCriteria[];
    displayColumnsSelectedForExport: IQueryFilterCriteria[];
    displayNoteCategoryAdditionalInfo: boolean;
    easyUpdateAttemptedCaseList: string[];
    easyUpdateAttemptedNoteGuidList: string[];
    easyUpdateCaseGuid?: string;
    easyUpdateMode: boolean;
    enableEasyUpdate: boolean;
    enableNoteUpdate?: boolean;
    loggedInUserDataScopeId: number;
    mode: QueryToolModeEnum;
    noteCategoryTypes: INoteCategoryTypeModel[];
    notePurposeTypes: ILookupModel[];
    noteResults?: INoteModel[];
    noteSearchCriteria: INoteUISearchModel;
    openNoteEditor?: boolean;
    otherDefenseCounselsForZone: IQueryFilterCriteriaSelectionValue[];
    pendingResponse: boolean;
    queryCriteriaMasterList: IQueryFilterCriteria[];
    queryFavorites: IOrganizationSettingModel[];
    queryFilterCriteriaAll: IQueryFilterCriteria[];
    queryFilterCriteriaSelected: IQueryFilterCriteria[];
    queryType: number;
    redirectComponentCurrentState?: any;
    redirectType?: QueryToolRedirectTypeEnum;
    redirectUrl?: string;
    selectAllCasesForRedirect?: boolean;
    selectedBlankOptions: any[];
    selectedGuidsForRedirect: string[];
    selectedNoteCategoriesOnEdit: IMultiSelectOptions[]; // AR - TO DO - 01/18/2023 - Not sure of its purpose. It doesnt seem to be used anywhere
    selectedQueryFavoriteId?: number;
    showConfirmRemoveCaseNoteDialog: boolean;
    showExportInBackgroundDialog?: boolean;
    showExportModal: boolean;
    showTooManyRecordsToDisplayDialog?: boolean;
    showTooManyRecordsToProcessDialog?: boolean;
    staticItems: IStaticItemLookupModel[];
    strategyTypeId?: number;
    title?: string;
    userDefaultSettingsJson?: string;
    usersInZone: IQueryFilterCriteriaSelectionValue[];
    USStates: IQueryFilterCriteriaSelectionValue[];
    validation: IValidation;
}

export class Query extends React.Component<IQueryProps, IQueryState> {
    constructor(props: IQueryProps) {
        super(props);

        this.state = {
            acknowledgmentStatusTypes: [],
            allDefenseCounsels: [],
            allDefenseCounselsForZone: [],
            allUsers: [],
            approvalStatusTypes: [],
            assertionDescriptors: [],
            caseExpertsResults: [],
            caseResults: {},
            caseResultsCount: 0,
            caseStatuses: [],
            caseStatusesForQueryCriteria: [],
            currentFavoriteDetailsString: '',
            currentNote: {
                purpose: {
                    id: NotePurposeTypesEnum.Info,
                },
                status: {
                    id: NoteStatusTypes.Open,
                },
            },
            depositionCategories: [],
            depositionStatusTypes: [],
            displayColumnsAll: [],
            displayColumnsSelected: [],
            displayColumnsSelectedForExport: [],
            displayNoteCategoryAdditionalInfo: false,
            easyUpdateAttemptedCaseList: [],
            easyUpdateAttemptedNoteGuidList: [],
            easyUpdateMode: false,
            enableEasyUpdate: false,
            loggedInUserDataScopeId: 0,
            mode: QueryToolModeEnum.Standalone,
            noteCategoryTypes: [],
            notePurposeTypes: [],
            noteSearchCriteria: {},
            otherDefenseCounselsForZone: [],
            pendingResponse: true,
            queryCriteriaMasterList: [],
            queryFavorites: [],
            queryFilterCriteriaAll: [],
            queryFilterCriteriaSelected: [],
            queryType: QueryTypes.CaseDetails.Value,
            selectedBlankOptions: [],
            selectedGuidsForRedirect: [],
            selectedNoteCategoriesOnEdit: [],
            showConfirmRemoveCaseNoteDialog: false,
            showExportModal: false,
            staticItems: [],
            usersInZone: [],
            USStates: [],
            validation: {},
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.Query, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        if (
            this.props.redirectType === QueryToolRedirectTypeEnum.PackageDeal &&
            (!this.props.queryFilterCriteriaSelected ||
                this.props.queryFilterCriteriaSelected.length === 0)
        ) {
            const validation = this.state.validation;
            validation.model = [DisplayMessages.UnexpectedErrorPackageDealQueryNav];
            this.setState({ validation: validation });
            return;
        }

        if (this.props.queryFilterCriteriaSelected || this.props.displayColumnsSelected) {
            let queryFilterCriteriaSelected: IQueryFilterCriteria[] = [];
            let displayColumnsSelected: IQueryFilterCriteria[] = [];
            let displayColumnsSelectedForExport: IQueryFilterCriteria[] = [];
            let showExportModal = false;

            if (this.props.queryFilterCriteriaSelected) {
                queryFilterCriteriaSelected = this.props.queryFilterCriteriaSelected;
            }

            if (this.props.displayColumnsSelected) {
                displayColumnsSelected = this.props.displayColumnsSelected;
                displayColumnsSelectedForExport = JSON.parse(
                    JSON.stringify(displayColumnsSelected)
                );
                showExportModal = true;
            }

            this.setState({
                queryFilterCriteriaSelected: queryFilterCriteriaSelected,
                displayColumnsSelected: displayColumnsSelected,
                displayColumnsSelectedForExport: displayColumnsSelectedForExport,
                showExportModal: showExportModal,
                mode: QueryToolModeEnum.Redirect,
                redirectType: this.props.redirectType,
                title: this.props.title,
                redirectUrl: this.props.redirectUrl,
                redirectComponentCurrentState: this.props.redirectComponentCurrentState,
            });
        }

        this.loadInitialData();
    }

    componentDidUpdate(prevProps: IQueryProps) {
        if (
            this.props.refreshIdentifier &&
            prevProps.refreshIdentifier != this.props.refreshIdentifier
        ) {
            window.location.assign(LocalRoutes.Query);
        }
    }

    getStaticItemsQueryCriteriasMasterList = (
        staticItems: IStaticItemLookupModel[]
    ): IQueryFilterCriteria[] => {
        const packageDealAuthFilter = (staticItem: IStaticItemLookupModel) => {
            const isPackageDeal = staticItem.guid === QueryStaticItemFields.PackageSettlement.Guid;
            return (
                !isPackageDeal ||
                (isPackageDeal &&
                    Authorization.userHasRight(
                        UserRightsEnum.ViewPackageSettlement,
                        this.props.user
                    ))
            );
        };

        const staticSectionFilter = (staticItem: IStaticItemLookupModel) => {
            return staticItem.id !== CaseFileStaticItems.DefenseCounselSection.Id;
        };

        const staticItemToQueryFilterCriteriaMapper = (staticItem: IStaticItemLookupModel) => ({
            label: CaseHelper.getCaseFileStaticItemDisplayName(staticItems, staticItem.id),
            value: UIHelper.getName(staticItems, staticItem.id),
            isAssertionDescriptor: false,
            staticItemId: staticItem.id,
        });

        return staticItems
            .filter(packageDealAuthFilter)
            .filter(staticSectionFilter)
            .map(staticItemToQueryFilterCriteriaMapper);
    };

    loadInitialData = async () => {
        const caseStatuses = await this.fetchCaseStatuses();
        if (!caseStatuses) return;

        const caseStatusDropdownValuesAll: IQueryFilterCriteriaSelectionValue[] = [];
        for (let i = 0; i < caseStatuses.length; i++) {
            caseStatusDropdownValuesAll.push({
                label: caseStatuses[i].name!,
                value: caseStatuses[i].name!,
                id: caseStatuses[i].id!,
            });
        }

        const caseManagers = await this.fetchCaseManagers();
        if (!caseManagers) return;

        const caseManagerDropdownValuesAll: IQueryFilterCriteriaSelectionValue[] = [];
        for (let i = 0; i < caseManagers.length; i++) {
            caseManagerDropdownValuesAll.push({
                label: caseManagers[i].profile!.firstName + ' ' + caseManagers[i].profile!.lastName,
                value: caseManagers[i].guid!,
                guid: caseManagers[i].guid!,
            });
        }

        const defenseCounsels = await this.fetchAllDefenseCounselsForZone();
        if (!defenseCounsels) return;

        let defenseCounselDropdownValuesAll: IQueryFilterCriteriaSelectionValue[] = [];
        for (let i = 0; i < defenseCounsels.length; i++) {
            defenseCounselDropdownValuesAll.push({
                label: defenseCounsels[i].name!,
                value: defenseCounsels[i].guid!,
                guid: defenseCounsels[i].guid!,
            });
        }

        const defenseCounselDropdownValuesOther: IQueryFilterCriteriaSelectionValue[] = [];
        const otherDefenseCounsels = defenseCounsels.filter((x) => x.priority != 1);
        for (let i = 0; i < otherDefenseCounsels.length; i++) {
            defenseCounselDropdownValuesOther.push({
                label: otherDefenseCounsels[i].name!,
                value: otherDefenseCounsels[i].guid!,
                guid: otherDefenseCounsels[i].guid!,
            });
        }

        const USStatesListAPIResponse = await this.fetchUSStates();
        if (!USStatesListAPIResponse) return;

        const USStates: IQueryFilterCriteriaSelectionValue[] = [];
        for (let i = 0; i < USStatesListAPIResponse.length; i++) {
            USStates.push({
                label:
                    USStatesListAPIResponse[i].displayName! +
                    ' - ' +
                    USStatesListAPIResponse[i].name!,
                value: USStatesListAPIResponse[i].guid!,
                guid: USStatesListAPIResponse[i].guid!,
            });
        }

        const assertionDescriptors = await this.fetchAssertionDescriptors();
        if (!assertionDescriptors) return;

        const staticItems = await this.fetchStaticItems();
        if (!staticItems) return;

        const staticItemQueryCriterias = this.getStaticItemsQueryCriteriasMasterList(staticItems);

        let queryCriteriaMasterList: IQueryFilterCriteria[] = [...staticItemQueryCriterias];

        assertionDescriptors
            .filter((x) => x.usageType && x.usageType.id === AssertionDescriptorUsageTypesEnum.Case)
            .forEach((item: IAssertionDescriptorModel) => {
                queryCriteriaMasterList.push({
                    label: item.alternateDisplayName || item.displayName!,
                    value: item.name!,
                    isAssertionDescriptor: true,
                    assertionDescriptorGuid: item.guid,
                });
            });

        queryCriteriaMasterList = queryCriteriaMasterList.sort(Sort.compareValues('label'));

        const displayColumnFilter = (item: IQueryFilterCriteria) => {
            return (
                item.staticItemId !== QueryStaticItemFields.CaseStatusAsOfDate.Id &&
                item.staticItemId !== QueryStaticItemFields.DefenseCounselSection.Id
            );
        };

        const displayColumnsAll = queryCriteriaMasterList.filter(displayColumnFilter);

        const queryCriteriaAssertionDescriptorNationalPFFilter = (item: IQueryFilterCriteria) => {
            return (
                item.assertionDescriptorGuid?.toUpperCase() !==
                AssertionDescriptors.PlainfirmFirmSecondary.Guid
            );
        };

        const queryCriteriaAssertionDescriptorNationalOtherOccupationFilter = (
            item: IQueryFilterCriteria
        ) => {
            return (
                item.assertionDescriptorGuid?.toUpperCase() !==
                AssertionDescriptors.OtherOccupation.Guid
            );
        };

        const caseManagerMapper = (filter: IQueryFilterCriteria) => {
            if (filter.staticItemId === CaseFileStaticItems.CaseManager.Id) {
                return { ...filter, label: 'A1/A2' };
            }
            return filter;
        };

        const caseAlternateManagerFilter = (item: IQueryFilterCriteria) => {
            return item.staticItemId !== CaseFileStaticItems.AlternateCaseManager.Id;
        };

        const queryFilterCriteriaAll = queryCriteriaMasterList
            .filter(queryCriteriaAssertionDescriptorNationalPFFilter)
            .filter(queryCriteriaAssertionDescriptorNationalOtherOccupationFilter)
            .filter(caseAlternateManagerFilter)
            .map(caseManagerMapper);

        const noteCategoryTypes = await this.fetchNoteCategoryTypes();
        if (!noteCategoryTypes) return;

        const queryFavorites = await this.fetchQueryFavorites();
        if (!queryFavorites) return;

        const allUsers = await this.fetchAllUsers();
        if (!allUsers) return;

        const depoCategories = await this.fetchDepositionCategories();
        if (!depoCategories) return;
        const depoCategoriesDropdownValuesAll: IMultiSelectOptions[] = [];
        for (let i = 0; i < depoCategories.length; i++) {
            depoCategoriesDropdownValuesAll.push({
                label: depoCategories[i].displayName || depoCategories[i].name!,
                value: depoCategories[i].name!,
                id: depoCategories[i].id!,
            });
        }

        const depoStatuses = await this.fetchDepositionStatuses();
        if (!depoStatuses) return;
        const depoStatusesDropdownValuesAll: IMultiSelectOptions[] = [];
        for (let i = 0; i < depoStatuses.length; i++) {
            depoStatusesDropdownValuesAll.push({
                label: depoStatuses[i].displayName || depoStatuses[i].name!,
                value: depoStatuses[i].name!,
                id: depoStatuses[i].id!,
            });
        }

        const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;
        const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(
            this.props.user
        );
        if (
            loggedInUserDataScopeId === DataScopesEnum.LocalBasic.Value &&
            this.props.user.homeOrganization
        ) {
            defenseCounselDropdownValuesAll = defenseCounselDropdownValuesAll.filter(
                (x) => x.guid === this.props.user.homeOrganization!.guid
            );
            queryFilterCriteriaSelected.push({
                staticItemId: QueryStaticItemFields.LocalCounsel.Id,
                readOnly: true,
                required: true,
                isFixed: true,
                label: CaseHelper.getCaseFileStaticItemDisplayName(
                    staticItems,
                    QueryStaticItemFields.LocalCounsel.Id
                ),
                value: UIHelper.getName(staticItems, QueryStaticItemFields.LocalCounsel.Id),
                selectedValues: [
                    {
                        guid: this.props.user.homeOrganization.guid!,
                        label: this.props.user.homeOrganization.name!,
                        value: this.props.user.homeOrganization.name!,
                        isFixed: true,
                    },
                ],
            });

            const lcFilter = queryFilterCriteriaAll.find(
                (filter) => filter.staticItemId === QueryStaticItemFields.LocalCounsel.Id
            );

            if (lcFilter) {
                lcFilter.isFixed = true;
            }
        }

        const notePurposeTypes = await this.fetchNotePurposeTypes();
        if (!notePurposeTypes) return;

        const approvalStatusTypes = await this.fetchApprovalStatusTypes();
        if (!approvalStatusTypes) return;

        const acknowledgmentStatusTypes = await this.fetchAcknowledgmentStatusTypes();
        if (!acknowledgmentStatusTypes) return;

        const defaultColumns = QueryToolDefaultColumnList;
        let displayColumnsSelectedInit: IQueryFilterCriteria[];
        let displayColumnsSelectedForExportInit: IQueryFilterCriteria[];

        const displayColumnsSelectedDefault: IQueryFilterCriteria[] = defaultColumns.map((c) => {
            const value: IQueryFilterCriteria | undefined = displayColumnsAll.find((o) => {
                return (
                    (c.assertionDescriptorGuid &&
                        o.assertionDescriptorGuid?.toUpperCase() ===
                            c.assertionDescriptorGuid?.toUpperCase()) ||
                    (c.staticItemId && o.staticItemId === c.staticItemId)
                );
            });
            if (value) return value;
            else {
                const name: string = c.name ? c.name : '';
                const value: IQueryFilterCriteria = {
                    label: name,
                    value: name,
                };
                return value;
            }
        });

        if (this.state.displayColumnsSelected.length == 0) {
            displayColumnsSelectedInit = [...displayColumnsSelectedDefault];
        } else {
            displayColumnsSelectedInit = [...this.state.displayColumnsSelected];
        }

        if (this.state.displayColumnsSelectedForExport.length == 0) {
            displayColumnsSelectedForExportInit = [...displayColumnsSelectedDefault];
        } else {
            displayColumnsSelectedForExportInit = [...this.state.displayColumnsSelectedForExport];
        }

        this.setState({
            queryCriteriaMasterList,
            queryFilterCriteriaAll,
            displayColumnsAll,
            displayColumnsSelected: displayColumnsSelectedInit,
            displayColumnsSelectedForExport: displayColumnsSelectedForExportInit,
            caseStatuses: caseStatuses,
            caseStatusesForQueryCriteria: caseStatusDropdownValuesAll,
            usersInZone: caseManagerDropdownValuesAll,
            allDefenseCounselsForZone: defenseCounselDropdownValuesAll,
            otherDefenseCounselsForZone: defenseCounselDropdownValuesOther,
            USStates: USStates,
            assertionDescriptors: assertionDescriptors,
            pendingResponse: false,
            noteCategoryTypes: noteCategoryTypes,
            queryFavorites: queryFavorites,
            allUsers: allUsers,
            allDefenseCounsels: defenseCounsels,
            depositionCategories: depoCategoriesDropdownValuesAll,
            depositionStatusTypes: depoStatusesDropdownValuesAll,
            queryFilterCriteriaSelected: queryFilterCriteriaSelected,
            loggedInUserDataScopeId: loggedInUserDataScopeId,
            notePurposeTypes: notePurposeTypes,
            approvalStatusTypes: approvalStatusTypes,
            acknowledgmentStatusTypes: acknowledgmentStatusTypes,
            staticItems,
        });
    };

    fetchCaseStatuses = async () => {
        const response = await _apiClient.getCaseStatusLookupData();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchCaseManagers = async () => {
        const response = await _apiClient.getCaseManagersInZone();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchAllUsers = async () => {
        const response = await _apiClient.getUsersInZone();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchAllDefenseCounselsForZone = async () => {
        const response = await _apiClient.getOrganizationInZone(OrganizationTypesEnum.Firm.Value);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchAssertionDescriptors = async () => {
        const response = await _apiClient.getAssertionDescriptors(
            AssertionDescriptorUsageTypesEnum.Case
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchStaticItems = async () => {
        const response = await _apiClient.getCaseFileStaticItems();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            return;
        }
        return response.payload;
    };

    fetchNoteCategoryTypes = async () => {
        const response = await _apiClient.getAllNoteCategoryTypes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchUSStates = async () => {
        const response = await _apiClient.getUSStates();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchQueryTypes() {
        const queryTypes: ILookupModel[] = [];

        if (Authorization.userHasRight(UserRightsEnum.ViewCaseFile, this.props.user)) {
            queryTypes.push({
                id: QueryTypes.CaseDetails.Value,
                name: QueryTypes.CaseDetails.Name,
                displayName: QueryTypes.CaseDetails.DisplayName,
            });
        }

        if (Authorization.userHasRight(UserRightsEnum.ViewCaseNotes, this.props.user)) {
            queryTypes.push({
                id: QueryTypes.CaseNotes.Value,
                name: QueryTypes.CaseNotes.Name,
                displayName: QueryTypes.CaseNotes.DisplayName,
            });
        }

        if (Authorization.userHasRight(UserRightsEnum.ViewCaseExpert, this.props.user)) {
            queryTypes.push({
                id: QueryTypes.CaseExperts.Value,
                name: QueryTypes.CaseExperts.Name,
                displayName: QueryTypes.CaseExperts.DisplayName,
            });
        }

        if (Authorization.userHasRight(UserRightsEnum.ViewDeposition, this.props.user)) {
            queryTypes.push({
                id: QueryTypes.Deposition.Value,
                name: QueryTypes.Deposition.Name,
                displayName: QueryTypes.Deposition.DisplayName,
            });
        }

        if (Authorization.userHasRight(UserRightsEnum.ViewZoneNotes, this.props.user)) {
            queryTypes.push({
                id: QueryTypes.ZoneNotes.Value,
                name: QueryTypes.ZoneNotes.Name,
                displayName: QueryTypes.ZoneNotes.DisplayName,
            });
        }

        if (Authorization.userHasViewStrategyNotesRight(this.props.user)) {
            queryTypes.push({
                id: QueryTypes.StrategyNote.Value,
                name: QueryTypes.StrategyNote.Name,
                displayName: QueryTypes.StrategyNote.DisplayName,
            });
        }

        return queryTypes;
    }

    fetchQueryFavorites = async () => {
        const response = await _apiClient.getOrganizationSettings(
            OrganizationSettingNames.QueryFavorites.Name
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    getNoteTypeByQueryType = () => {
        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
            case QueryTypes.Deposition.Value:
            case QueryTypes.CaseNotes.Value:
                return NoteTypes.CaseNote;
            case QueryTypes.ZoneNotes.Value:
                return NoteTypes.ZoneNote;
            case QueryTypes.StrategyNote.Value:
                return NoteTypes.StrategyNote;
            default:
                return 0;
        }
    };

    getNoteCategoryTypeOptions = () => {
        const noteTypeId = this.getNoteTypeByQueryType();
        let noteCategoryTypeOptions: IMultiSelectOptions[] = [];
        this.state.noteCategoryTypes
            .filter((x) => x.noteType.id === noteTypeId)
            .map((cat: ILookupModel) => {
                noteCategoryTypeOptions.push({
                    label: cat.displayName!,
                    value: cat.name!,
                    id: cat.id,
                });
            });
        noteCategoryTypeOptions = noteCategoryTypeOptions.sort(Sort.compareValues('label'));
        return noteCategoryTypeOptions;
    };

    getNoteSubCategoryTypeOptions = () => {
        let noteSubCategoryTypeOptions: IMultiSelectOptions[] = [];

        if (this.state.noteSearchCriteria.noteCategories) {
            this.state.noteSearchCriteria.noteCategories.map((cat: IMultiSelectOptions) => {
                const match = this.state.noteCategoryTypes.find((x) => x.id === cat.id);
                if (match && match.subCategories && match.subCategories.length > 0) {
                    match.subCategories.map((item: INoteSubCategoryTypeModel) => {
                        noteSubCategoryTypeOptions.push({
                            label: item.displayName!,
                            value: item.name!,
                            id: item.id,
                            parentId: match.id,
                        });
                    });
                }
            });
        }

        noteSubCategoryTypeOptions = noteSubCategoryTypeOptions.sort(Sort.compareValues('label'));
        return noteSubCategoryTypeOptions;
    };

    fetchDepositionCategories = async () => {
        const response = await _apiClient.getDepositionCategories();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchDepositionStatuses = async () => {
        const response = await _apiClient.getDepositionStatusTypes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    getUsersInDefenseFirmOptions = (guid: string) => {
        let users: IMultiSelectOptions[] = [];
        this.state.allUsers
            .filter((x) => x.homeOrganization && x.homeOrganization.guid === guid)
            .map((user: IUserModel) => {
                const item: IMultiSelectOptions = {
                    label:
                        user.profile!.firstName +
                        ' ' +
                        user.profile!.lastName +
                        (user.activeZoneDataScope &&
                        user.activeZoneDataScope.id === DataScopesEnum.LocalBasic.Value
                            ? ' (Local)'
                            : ''),
                    value: user.guid!,
                    id: 0,
                    guid: user.guid,
                };
                users.push(item);
            });
        users = users.sort(Sort.compareValues('label'));
        return users;
    };

    getAsOfDateStatusLookupValues = () => {
        const statusLookupValues: ILookupModel[] = [];
        //for (let i = 0; i < this.state.caseStatuses.length; i++) {
        //    if (statusLookupValues.filter(x => x.id == this.state.caseStatuses[i].status.id).length == 0) statusLookupValues.push(this.state.caseStatuses[i].status);
        //}
        statusLookupValues.push(
            this.state.caseStatuses.filter(
                (x) => x.status && x.status.id == CaseStatusTypesEnum.Open.Value
            )[0].status
        );

        return statusLookupValues;
    };

    getSubStatusLookupValues = (statusId: number) => {
        const subStatusLookupValues: IQueryFilterCriteriaSelectionValue[] = [];
        this.state.caseStatuses
            .filter((x) => x.status.id == statusId && x.subStatus)
            .map((y) =>
                subStatusLookupValues.push({
                    label: y.subStatus.displayName!,
                    value: y.subStatus.name!,
                    id: y.subStatus.id,
                })
            );
        return subStatusLookupValues;
    };

    fetchNotePurposeTypes = async () => {
        const response = await _apiClient.getAllNotePurposeTypes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        return response.payload!;
    };

    fetchApprovalStatusTypes = async () => {
        const response = await _apiClient.getAllApprovalStatusTypes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        return response.payload!;
    };

    fetchAcknowledgmentStatusTypes = async () => {
        const response = await _apiClient.getAllAcknowledgmentStatusTypes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        return response.payload!;
    };

    queryCriteriaSelected = (optionsList: any) => {
        const validation = this.state.validation;

        const queryFilterCriteriaSelectedBefore: IQueryFilterCriteria[] = JSON.parse(
            JSON.stringify(this.state.queryFilterCriteriaSelected)
        );

        const isDepositionSearchCriteriaRemoved = this.isDepositionSearchCriteriaRemoved(
            optionsList,
            queryFilterCriteriaSelectedBefore
        );

        const isNotesSearchCriteriaRemoved = this.isNotesSearchCriteriaRemoved(
            optionsList,
            queryFilterCriteriaSelectedBefore
        );
        const noteSearchCriteria: INoteUISearchModel = this.state.noteSearchCriteria;
        if (isNotesSearchCriteriaRemoved) {
            this.removeNoteSearchFromSearchCriteria(noteSearchCriteria);
            //noteSearchCriteria.noteType = { id: NoteTypes.CaseNote };
        }

        const queryFilterCriteriaSelected: IQueryFilterCriteria[] = optionsList;

        for (let i = 0; i < queryFilterCriteriaSelected.length; i++) {
            if (
                queryFilterCriteriaSelectedBefore.findIndex(
                    (x) => x.value == queryFilterCriteriaSelected[i].value
                ) == -1
            ) {
                // This means it was added for the first time or added earlier, cleared/deleted and re-added again. Hence clear all input values for search
                queryFilterCriteriaSelected[i].selectedValues = undefined;
                queryFilterCriteriaSelected[i].text = '';
                queryFilterCriteriaSelected[i].booleanValue = undefined;
                queryFilterCriteriaSelected[i].startDate = undefined;
                queryFilterCriteriaSelected[i].endDate = undefined;
                queryFilterCriteriaSelected[i].numericValueFromText = '';
                queryFilterCriteriaSelected[i].numericValueFrom = undefined;
                queryFilterCriteriaSelected[i].numericValueToText = '';
                queryFilterCriteriaSelected[i].numericValueTo = undefined;
                queryFilterCriteriaSelected[i].condition = undefined;
                queryFilterCriteriaSelected[i].validationError = undefined;
            }
        }

        if (optionsList.length > 10) {
            validation.criteria = [DisplayMessages.QueryToolMaximumCriteria];
            //queryFilterCriteriaSelected = optionsList.splice(0, 10);
        } else {
            validation.criteria = [''];
        }

        for (let i = 0; i < queryFilterCriteriaSelected.length; i++) {
            queryFilterCriteriaSelected[i].required = true;
        }

        // Custom Logic for Redirect from Package Deal
        if (this.props.redirectType === QueryToolRedirectTypeEnum.PackageDeal) {
            const plaintiffsFirmCurrent = queryFilterCriteriaSelected.find(
                (x) =>
                    x.assertionDescriptorGuid != undefined &&
                    x.assertionDescriptorGuid.toUpperCase() ===
                        AssertionDescriptors.PlainfirmFirmLocal.Guid
            );

            const plaintiffsFirmBefore = queryFilterCriteriaSelectedBefore.find(
                (x) =>
                    x.assertionDescriptorGuid != undefined &&
                    x.assertionDescriptorGuid.toUpperCase() ===
                        AssertionDescriptors.PlainfirmFirmLocal.Guid
            );

            const packageDealFromBefore = queryFilterCriteriaSelectedBefore.find(
                (x) => x.staticItemId === QueryStaticItemFields.PackageSettlement.Id
            );
            const packageDealCurrent = queryFilterCriteriaSelected.find(
                (x) => x.staticItemId === QueryStaticItemFields.PackageSettlement.Id
            );

            if (
                (!plaintiffsFirmCurrent && plaintiffsFirmBefore) ||
                (!packageDealCurrent && packageDealFromBefore)
            ) {
                // User trying to remove the Pre-selcted Plaintiffs Firm/Package Deal Criteria from the Select.
                return;
            }
        }

        this.setState({
            queryFilterCriteriaSelected: queryFilterCriteriaSelected,
            validation: validation,
            depositionSearchCriteria: isDepositionSearchCriteriaRemoved
                ? undefined
                : this.state.depositionSearchCriteria,
            noteSearchCriteria,
        });
    };

    isDepositionSearchCriteriaRemoved = (
        currentList: IQueryFilterCriteria[],
        queryFilterCriteriaSelectedBefore: IQueryFilterCriteria[]
    ): boolean => {
        let isDepositionSearchCriteriaRemoved = false;
        const previousDepositionCriteriaExists =
            queryFilterCriteriaSelectedBefore.findIndex((a) => a.staticItemId === 13) > -1;
        const curCriteriaExists = currentList.findIndex((a) => a.staticItemId === 13) > -1;
        if (previousDepositionCriteriaExists && !curCriteriaExists) {
            //remove the Depoistion from the Querysettings.
            isDepositionSearchCriteriaRemoved = true;
        }
        return isDepositionSearchCriteriaRemoved;
    };

    isNotesSearchCriteriaRemoved = (
        currentList: IQueryFilterCriteria[],
        queryFilterCriteriaSelectedBefore: IQueryFilterCriteria[]
    ): boolean => {
        let isNotesSearchCriteriaRemoved = false;
        const previousNotesCriteriaExists =
            queryFilterCriteriaSelectedBefore.findIndex((a) => a.staticItemId === 12) > -1;
        const curCriteriaExists = currentList.findIndex((a) => a.staticItemId === 12) > -1;
        if (previousNotesCriteriaExists && !curCriteriaExists) {
            //remove the Notes from the Querysettings.
            isNotesSearchCriteriaRemoved = true;
        }
        return isNotesSearchCriteriaRemoved;
    };

    queryColumnsSelected = (optionsList: any) => {
        const validation = this.state.validation;
        const queryColumnsSelected = optionsList;

        //if (optionsList.length > 10) {
        //    validation.columns = [DisplayMessages.QueryToolMaximumColums];
        //    //queryColumnsSelected = optionsList.splice(0, 10);
        //}
        //else {
        //    validation.columns = [""];
        //}

        this.setState({
            displayColumnsSelected: queryColumnsSelected,
            displayColumnsSelectedForExport: JSON.parse(JSON.stringify(queryColumnsSelected)),
            validation: validation,
        });
    };

    queryColumnsSelectedForExport = (optionsList: any) => {
        this.setState({ displayColumnsSelectedForExport: optionsList });
    };

    getSelectedAsOfSubStatusValues = (item: IQueryFilterCriteria) => {
        const returnData: IQueryFilterCriteriaSelectionValue[] = [];
        if (item.selectedValues && item.selectedValues.length > 1) {
            for (let i = 1; i < item.selectedValues.length; i++) {
                returnData.push(item.selectedValues[i]);
            }
        }
        return returnData;
    };

    filterValuesSelected = (optionsList: any, item: IQueryFilterCriteria) => {
        const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;
        const noteSearchCriteria = this.state.noteSearchCriteria;
        const match = queryFilterCriteriaSelected.find((x) => x.value == item.value);
        if (match) {
            // Custom Logic for As Of Date logic.
            if (match.staticItemId == QueryStaticItemFields.CaseStatusAsOfDate.Id) {
                let asOfStatusSelectedValue: IQueryFilterCriteriaSelectionValue = {
                    label: '',
                    value: '',
                    id: 0,
                };
                if (match.selectedValues && match.selectedValues.length > 0)
                    asOfStatusSelectedValue = match.selectedValues[0];
                match.selectedValues = optionsList.splice(0, 3);
                if (match.selectedValues) match.selectedValues!.unshift(asOfStatusSelectedValue);
                else match.selectedValues = [asOfStatusSelectedValue];
                match.validationError = '';
            }
            // No 3 values Max limit for the following fields
            else if (
                match.staticItemId === QueryStaticItemFields.CaseStatus.Id ||
                match.staticItemId === QueryStaticItemFields.State.Id ||
                (match.isAssertionDescriptor &&
                    match.assertionDescriptorGuid &&
                    (match.assertionDescriptorGuid.toUpperCase() ===
                        AssertionDescriptors.DefenseBRead.Guid ||
                        match.assertionDescriptorGuid.toUpperCase() ===
                            AssertionDescriptors.PlaintiffBRead.Guid))
            ) {
                match.selectedValues = optionsList;
                match.validationError = '';
            } else {
                match.selectedValues = optionsList.splice(0, 3);
                match.validationError = '';
            }
        }

        this.setState({
            queryFilterCriteriaSelected: queryFilterCriteriaSelected,
            noteSearchCriteria: noteSearchCriteria,
        });
    };

    filterValuesSelectedAlt = (optionsList: any, name: string) => {
        const noteSearchDetails = this.state.noteSearchCriteria;
        let depositionSearchCriteria = this.state.depositionSearchCriteria;
        let caseExpertsSearchCriteria = this.state.caseExpertsSearchCriteria;

        switch (name) {
            case 'noteCategories': {
                noteSearchDetails.noteCategories = optionsList;
                let noteSubCategories = noteSearchDetails.noteSubCategories;
                if (
                    !noteSearchDetails.noteCategories ||
                    noteSearchDetails.noteCategories.length == 0
                )
                    noteSubCategories = [];
                else if (noteSubCategories) {
                    for (let i = 0; i < noteSubCategories.length; i++) {
                        const catMatch = noteSearchDetails.noteCategories.find(
                            (x) => x.id === noteSubCategories![i].parentId!
                        );
                        if (!catMatch) noteSubCategories.splice(i, 1);
                    }
                }
                noteSearchDetails.noteSubCategories = noteSubCategories;
                break;
            }
            case 'noteSubCategories':
                noteSearchDetails.noteSubCategories = optionsList;
                break;
            case 'noteSubTypes':
                noteSearchDetails.noteSubTypes = optionsList;
                break;
            case 'defenseCounselUsers':
                if (!depositionSearchCriteria) depositionSearchCriteria = {};
                depositionSearchCriteria.defenseCounselUsers = optionsList;
                break;
            case 'depositionCategories':
                if (!depositionSearchCriteria) depositionSearchCriteria = {};
                depositionSearchCriteria.categories = optionsList;
                break;
            case 'depositionStatuses':
                if (!depositionSearchCriteria) depositionSearchCriteria = {};
                depositionSearchCriteria.statuses = optionsList;
                break;
            case 'caseExpertsExpert':
                if (!caseExpertsSearchCriteria) caseExpertsSearchCriteria = {};
                caseExpertsSearchCriteria.expertGuids = optionsList.map(
                    (opt: { value: string }) => opt.value
                );
                break;
            case 'caseExpertsRetainedBy':
                if (!caseExpertsSearchCriteria) caseExpertsSearchCriteria = {};
                caseExpertsSearchCriteria.retainedByTypeIds = optionsList.map(
                    (opt: { id: number }) => opt.id
                );
                break;
            case 'caseExpertsReportStatus':
                if (!caseExpertsSearchCriteria) caseExpertsSearchCriteria = {};
                caseExpertsSearchCriteria.reportStatusTypeIds = optionsList.map(
                    (opt: { id: number }) => opt.id
                );
                break;
            default:
        }
        this.setState({
            noteSearchCriteria: noteSearchDetails,
            depositionSearchCriteria,
            caseExpertsSearchCriteria,
        });
    };

    onSearchableSingleSelectChange = (selectedItem?: any) => {
        const depositionSearchCriteria: IDepositionUISearchModel =
            this.state.depositionSearchCriteria || {};
        depositionSearchCriteria.defenseCounsel = selectedItem;
        depositionSearchCriteria.defenseCounselUsers = [];
        this.setState({ depositionSearchCriteria: depositionSearchCriteria });
    };

    showExportModal = () => {
        const valid = this.validateCaseQueryInput(true);
        if (!valid) return;

        this.setState({ showExportModal: true });
    };

    closeExportModal = () => {
        this.setState({ showExportModal: false });
    };

    generateAndDownloadReport = async () => {
        this.setState({ pendingResponse: true, validation: {} });

        let saveReport = false;
        let processExternally = false;
        let url = '';
        let count = 0;

        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
            case QueryTypes.CaseExperts.Value:
            case QueryTypes.CaseNotes.Value:
            case QueryTypes.Deposition.Value: {
                url = ApiRoutes.Query;
                const chkSave: any = document.getElementById('chkSave');
                if (chkSave && chkSave.checked) saveReport = true;

                count = await this.getQueryCount();
                break;
            }
            default:
        }

        if (count > maxRecordCountForWebProcessing) {
            this.setState({ showTooManyRecordsToProcessDialog: true, pendingResponse: false });
            return;
        }

        if (count > maxExportRecordCount) {
            saveReport = true;
            processExternally = true;
            const validation: IValidation = {};
            validation.export = [];
            this.setState({
                pendingResponse: false,
                showExportInBackgroundDialog: true,
                showExportModal: false,
                validation: validation,
            });
        }

        if (saveReport) url = url + '?save=true';

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + url, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (count! <= maxExportRecordCount) {
                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                    this.setState({ pendingResponse: false, showExportModal: false });

                    let fileName = 'QueryResults.xlsx';
                    const blob = xhr.response;

                    const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                    if (contentDisposition) {
                        const contentDispositionItems = contentDisposition.split(';');
                        if (contentDispositionItems) {
                            for (let i = 0; i < contentDispositionItems.length; i++) {
                                const currentItem = contentDispositionItems[i];
                                if (currentItem.includes('filename=')) {
                                    const n = currentItem.indexOf('filename=') + 9;
                                    fileName = contentDispositionItems[i].substring(
                                        n + 1,
                                        contentDispositionItems[i].length - 1
                                    );
                                    break;
                                }
                            }
                        }
                    }
                    const a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.download = fileName;
                    a.dispatchEvent(new MouseEvent('click'));
                }

                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                    const validation = this.state.validation;
                    validation.export = [DisplayMessages.ReportGenerateError];
                    this.setState({ pendingResponse: false, validation: validation });
                }
            }
        };

        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
            case QueryTypes.CaseExperts.Value:
            case QueryTypes.CaseNotes.Value:
            case QueryTypes.Deposition.Value: {
                const columns: IQueryColumnDefinitionModel[] =
                    this.getDisplayColumnsSelectedForExport();
                const querySettingsDisplay: IQuerySettingsModel = this.getQuerySettings();

                const querySettings: IQuerySettingsModel = {
                    ...querySettingsDisplay,
                    columns: columns,
                    saveFile: saveReport,
                    export: true,
                    processExternally: processExternally,
                };
                xhr.send(JSON.stringify(querySettings));
                break;
            }
            default:
        }
    };

    getDisplayColumnsSelectedForExport = (): IQueryColumnDefinitionModel[] => {
        const columns: IQueryColumnDefinitionModel[] = [];

        if (this.state.displayColumnsSelectedForExport) {
            for (let i = 0; i < this.state.displayColumnsSelectedForExport.length; i++) {
                const item: IQueryColumnDefinitionModel = {
                    name: this.state.displayColumnsSelectedForExport[i].value,
                    assertionDescriptorGuid:
                        this.state.displayColumnsSelectedForExport[i].assertionDescriptorGuid,
                    staticItemId: this.state.displayColumnsSelectedForExport[i].staticItemId,
                };
                columns.push(item);
            }
        }

        return columns;
    };

    handleQueryTypeChange = (selectedItem: any) => {
        const queryType = selectedItem ? selectedItem.id : 0;
        if (!queryType) return;
        const queryFilterCriteriaAll = [...this.state.queryFilterCriteriaAll];
        const queryFilterCriteriaSelected = [...this.state.queryFilterCriteriaSelected];
        const noteSearchCriteria: INoteUISearchModel = {};

        let initDisplayValues = false;
        let isZoneORStrategyQueryType = false;

        const removeStaticItemFromAvailableCriteria = (staticItemId: number) => {
            for (let i = 0; i < queryFilterCriteriaAll.length; i++) {
                if (queryFilterCriteriaAll[i].staticItemId === staticItemId) {
                    queryFilterCriteriaAll.splice(i, 1);

                    // Remove it from the selected list if it was previously selected
                    for (let i = 0; i < queryFilterCriteriaSelected.length; i++) {
                        if (queryFilterCriteriaSelected[i].staticItemId === staticItemId) {
                            queryFilterCriteriaSelected.splice(i, 1);
                            break;
                        }
                    }
                    break;
                }
            }
        };

        switch (queryType) {
            case QueryTypes.CaseDetails.Value:
                initDisplayValues = true;
                break;
            case QueryTypes.CaseNotes.Value: {
                noteSearchCriteria.noteType = { id: NoteTypes.CaseNote };
                break;
            }
            case QueryTypes.ZoneNotes.Value:
                isZoneORStrategyQueryType = true;
                noteSearchCriteria.noteType = { id: NoteTypes.ZoneNote };
                removeStaticItemFromAvailableCriteria(QueryStaticItemFields.NotesSection.Id);
                removeStaticItemFromAvailableCriteria(QueryStaticItemFields.DepositionSection.Id);
                removeStaticItemFromAvailableCriteria(QueryStaticItemFields.ExpertsSection.Id);
                break;
            case QueryTypes.StrategyNote.Value:
                isZoneORStrategyQueryType = true;
                noteSearchCriteria.noteType = { id: NoteTypes.StrategyNote };
                removeStaticItemFromAvailableCriteria(QueryStaticItemFields.NotesSection.Id);
                removeStaticItemFromAvailableCriteria(QueryStaticItemFields.DepositionSection.Id);
                removeStaticItemFromAvailableCriteria(QueryStaticItemFields.ExpertsSection.Id);
                break;
            case QueryTypes.Deposition.Value: {
                break;
            }
            default:
        }

        if (initDisplayValues) {
            const defaultColumns = QueryToolDefaultColumnList;
            let displayColumnsSelectedInit: IQueryFilterCriteria[];
            let displayColumnsSelectedForExportInit: IQueryFilterCriteria[];
            const displayColumnsAll = this.state.displayColumnsAll;

            const displayColumnsSelectedDefault: IQueryFilterCriteria[] = defaultColumns.map(
                (c) => {
                    const value: IQueryFilterCriteria | undefined = displayColumnsAll.find((o) => {
                        return (
                            (c.assertionDescriptorGuid &&
                                o.assertionDescriptorGuid?.toUpperCase() ===
                                    c.assertionDescriptorGuid?.toUpperCase()) ||
                            (c.staticItemId && o.staticItemId === c.staticItemId)
                        );
                    });
                    if (value) return value;
                    else {
                        const name: string = c.name ? c.name : '';
                        const value: IQueryFilterCriteria = {
                            label: name,
                            value: name,
                        };
                        return value;
                    }
                }
            );

            if (this.state.displayColumnsSelected.length == 0) {
                displayColumnsSelectedInit = [...displayColumnsSelectedDefault];
            } else {
                displayColumnsSelectedInit = [...this.state.displayColumnsSelected];
            }

            if (this.state.displayColumnsSelectedForExport.length == 0) {
                displayColumnsSelectedForExportInit = [...displayColumnsSelectedDefault];
            } else {
                displayColumnsSelectedForExportInit = [
                    ...this.state.displayColumnsSelectedForExport,
                ];
            }

            //set state for noteSearchCriteria only for zone notes or stratagey notes.

            this.setState({
                queryType: queryType,
                queryFilterCriteriaAll,
                queryFilterCriteriaSelected: queryFilterCriteriaSelected,
                caseResults: {},
                noteResults: undefined,
                noteSearchCriteria: isZoneORStrategyQueryType
                    ? noteSearchCriteria
                    : this.state.noteSearchCriteria,
                displayColumnsSelected: displayColumnsSelectedInit,
                displayColumnsSelectedForExport: displayColumnsSelectedForExportInit,
            });
        } else {
            this.setState({
                queryType,
                queryFilterCriteriaAll,
                queryFilterCriteriaSelected,
                caseResults: {},
                noteResults: undefined,
                noteSearchCriteria: isZoneORStrategyQueryType
                    ? noteSearchCriteria
                    : this.state.noteSearchCriteria,
                displayColumnsSelected: [],
                displayColumnsSelectedForExport: [],
            });
        }
    };

    handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
        item: IQueryFilterCriteria
    ) => {
        const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;

        const match = queryFilterCriteriaSelected.find((x) => x.value == item.value);
        if (!match) return;

        if (item.isAssertionDescriptor) {
            const assertionDescriptorMatch = this.state.assertionDescriptors.find(
                (x) => x.guid == item.assertionDescriptorGuid
            );
            if (assertionDescriptorMatch) {
                const valueSourceTypeId = assertionDescriptorMatch.valueSourceType!.id;
                const valueDisplayTypeId = assertionDescriptorMatch.valueDisplayType
                    ? assertionDescriptorMatch.valueDisplayType.id
                    : 0;

                switch (valueSourceTypeId) {
                    case AssertionDescriptorValueSourceTypeEnum.Boolean.Value:
                        match.booleanValue = event.target.value == 'true' ? true : false;
                        break;
                    case AssertionDescriptorValueSourceTypeEnum.Text.Value:
                        switch (valueDisplayTypeId) {
                            case AssertionDescriptorValueDisplayTypes.Currency.Value:
                            case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                            case AssertionDescriptorValueDisplayTypes.Number.Value:
                            case AssertionDescriptorValueDisplayTypes.Decimal.Value:
                                if (event.target.name == 'numericValueFrom') {
                                    match.numericValueFromText = event.target.value;
                                    if (isNaN(Number(event.target.value))) {
                                        match.validationError = 'Enter a valid number';
                                    } else {
                                        match.validationError = '';
                                        match.numericValueFrom = Number(event.target.value);
                                    }
                                } else if (event.target.name == 'numericValueTo') {
                                    match.numericValueToText = event.target.value;
                                    if (isNaN(Number(event.target.value))) {
                                        match.validationError = 'Enter a valid number';
                                    } else {
                                        match.validationError = '';
                                        match.numericValueTo = Number(event.target.value);
                                    }
                                }
                                break;
                            case AssertionDescriptorValueDisplayTypes.DateTime.Value:
                                match.validationError = '';
                                if (event.target.name == 'startDate') {
                                    match.startDate = event.target.value;
                                    match.validationError = Common.isValidDate(match.startDate)
                                        ? ''
                                        : 'Invalid Date';
                                } else if (event.target.name == 'endDate') {
                                    match.endDate = event.target.value;
                                    match.validationError = Common.isValidDate(match.endDate)
                                        ? ''
                                        : 'Invalid Date';
                                }
                                break;
                            default:
                                match.validationError = '';
                                match.text = event.target.value;
                                match.validationError = '';
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }
        } else {
            switch (item.staticItemId) {
                case QueryStaticItemFields.CaseNumber.Id:
                case QueryStaticItemFields.UniqueCaseID.Id:
                    if (event.target.value.length > 0) item.validationError = '';
                    match.text = event.target.value;
                    break;
                case QueryStaticItemFields.CasePriority.Id:
                    match.booleanValue = event.target.value == 'true' ? true : false;
                    item.validationError = '';
                    break;

                case QueryStaticItemFields.ResolutionDate.Id:
                    item.validationError = '';
                    switch (event.target.name) {
                        case 'resolutionDateStartDate':
                            match.startDate = event.target.value;
                            break;
                        case 'resolutionDateEndDate':
                            match.endDate = event.target.value;
                            break;
                        default:
                            break;
                    }
                    break;
                case QueryStaticItemFields.CaseStatusAsOfDate.Id:
                    item.validationError = '';
                    switch (event.target.name) {
                        case 'asOfStatus':
                            match.selectedValues = [
                                { label: '', value: '', id: parseInt(event.target.value) },
                            ];
                            break;
                        case 'asOfStartDate':
                            match.startDate = event.target.value;
                            break;
                        case 'asOfEndDate':
                            match.endDate = event.target.value;
                            break;
                        default:
                            break;
                    }
                    break;
                case QueryStaticItemFields.NotesSection.Id:
                    item.validationError = '';
                    if (event.target.name == 'noteStartDate') {
                        match.startDate = event.target.value;
                    } else if (event.target.name == 'noteEndDate') {
                        match.endDate = event.target.value;
                    }
                    break;
                case QueryStaticItemFields.PackageSettlement.Id:
                    item.validationError = '';
                    switch (event.target.name) {
                        case 'packageSettlementName':
                            match.text = event.target.value;
                            break;
                        case 'packageSettlementYesNo':
                            match.booleanValue = event.target.value == 'true' ? true : false;
                            break;
                        case 'packageSettlementStartDate':
                            match.startDate = event.target.value;
                            break;
                        case 'packageSettlementEndDate':
                            match.endDate = event.target.value;
                            break;
                        default:
                            break;
                    }
                    break;
            }
        }

        this.setState({ queryFilterCriteriaSelected: queryFilterCriteriaSelected });
    };

    // For Assertion Descriptors, identifier needs to be AssertionDescriptor.Guid
    handleCheckmateSingleSelectChange = (
        selectedItem: any,
        name: string,
        item?: IQueryFilterCriteria
    ) => {
        const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;
        const noteSearchCriteria = this.state.noteSearchCriteria;

        if (item) {
            const match = queryFilterCriteriaSelected.find((x) => x.value == item.value);
            if (!match) return;

            if (item.isAssertionDescriptor) {
                const assertionDescriptorMatch = this.state.assertionDescriptors.find(
                    (x) => x.guid == item.assertionDescriptorGuid
                );
                if (assertionDescriptorMatch) {
                    const valueSourceTypeId = assertionDescriptorMatch.valueSourceType!.id;
                    if (valueSourceTypeId === AssertionDescriptorValueSourceTypeEnum.Boolean.Value)
                        match.booleanValue =
                            selectedItem && selectedItem.value
                                ? selectedItem.id === 0
                                    ? false
                                    : true
                                : undefined;
                }
            } else {
                switch (item.staticItemId) {
                    case QueryStaticItemFields.CasePriority.Id:
                    case QueryStaticItemFields.PackageSettlement.Id:
                        match.booleanValue =
                            selectedItem && selectedItem.value
                                ? selectedItem.id === 0
                                    ? false
                                    : true
                                : undefined;
                        break;
                    case QueryStaticItemFields.CaseStatusAsOfDate.Id:
                    case QueryStaticItemFields.ResolutionDate.Id: {
                        const value = selectedItem ? selectedItem.id : 0;
                        match.selectedValues = value ? [{ label: '', value: '', id: value }] : [];
                        break;
                    }
                    default:
                        break;
                }
            }
        } else {
            switch (name) {
                case 'private':
                    noteSearchCriteria.private =
                        selectedItem && selectedItem.value
                            ? selectedItem.id === 0
                                ? false
                                : true
                            : undefined;
                    break;
                case 'allowLocalEdit':
                    noteSearchCriteria.allowLocalEdit =
                        selectedItem && selectedItem.value
                            ? selectedItem.id === 0
                                ? false
                                : true
                            : undefined;
                    break;
                case 'modifiedByLocal':
                    noteSearchCriteria.modifiedByLocal =
                        selectedItem && selectedItem.value
                            ? selectedItem.id === 0
                                ? false
                                : true
                            : undefined;
                    break;
                case 'hasAttachment':
                    noteSearchCriteria.hasAttachment =
                        selectedItem && selectedItem.value
                            ? selectedItem.id === 0
                                ? false
                                : true
                            : undefined;
                    break;
                case 'purpose': {
                    const purposeId = selectedItem ? selectedItem.id : 0;
                    noteSearchCriteria.purpose = purposeId ? [{ id: purposeId }] : undefined;
                    if (purposeId != NotePurposeTypesEnum.Approval)
                        noteSearchCriteria.approvalStatus = undefined;
                    else if (purposeId != NotePurposeTypesEnum.Acknowledgment)
                        noteSearchCriteria.acknowledgementStatus = undefined;
                    break;
                }
                case 'approvalStatus': {
                    const approvalStatusId = selectedItem ? selectedItem.id : 0;
                    noteSearchCriteria.approvalStatus = approvalStatusId
                        ? [{ id: approvalStatusId }]
                        : undefined;
                    break;
                }
                case 'acknowledgementStatus': {
                    const acknowledgementStatusId = selectedItem ? selectedItem.id : 0;
                    noteSearchCriteria.acknowledgementStatus = acknowledgementStatusId
                        ? [{ id: acknowledgementStatusId }]
                        : undefined;
                    break;
                }
                default:
                    break;
            }
        }

        this.setState({
            queryFilterCriteriaSelected: queryFilterCriteriaSelected,
            noteSearchCriteria: noteSearchCriteria,
        });
    };

    handleChangeAlt = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const noteSearchCriteria = this.state.noteSearchCriteria;
        let depositionSearchCriteria = this.state.depositionSearchCriteria;
        switch (event.target.name) {
            case 'noteStartDate':
                noteSearchCriteria.startDate = event.target.value;
                noteSearchCriteria.validationError = Common.isValidDate(
                    noteSearchCriteria.startDate
                )
                    ? ''
                    : 'Invalid Date';
                break;
            case 'noteEndDate':
                noteSearchCriteria.endDate = event.target.value;
                noteSearchCriteria.validationError = Common.isValidDate(noteSearchCriteria.endDate)
                    ? ''
                    : 'Invalid Date';
                break;
            case 'depositionStartDate':
                if (!depositionSearchCriteria) depositionSearchCriteria = {};
                depositionSearchCriteria.startDate = event.target.value;
                depositionSearchCriteria.validationError = Common.isValidDate(
                    depositionSearchCriteria.startDate
                )
                    ? ''
                    : 'Invalid Date';
                break;
            case 'depositionEndDate':
                if (!depositionSearchCriteria) depositionSearchCriteria = {};
                depositionSearchCriteria.endDate = event.target.value;
                depositionSearchCriteria.validationError = Common.isValidDate(
                    depositionSearchCriteria.endDate
                )
                    ? ''
                    : 'Invalid Date';
                break;
            default:
        }

        this.setState({
            depositionSearchCriteria: depositionSearchCriteria,
            noteSearchCriteria: noteSearchCriteria,
        });
    };

    getValue = (item: IQueryFilterCriteria) => {
        const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;

        const match = queryFilterCriteriaSelected.find((x) => x.value == item.value);
        if (!match) return;

        if (item.isAssertionDescriptor) {
            const assertionDescriptorMatch = this.state.assertionDescriptors.find(
                (x) => x.guid == item.assertionDescriptorGuid
            );
            if (
                assertionDescriptorMatch &&
                assertionDescriptorMatch.valueSourceType!.id ==
                    AssertionDescriptorValueSourceTypeEnum.Selection.Value
            ) {
                // do nothing for now
            }
        } else {
            switch (item.staticItemId) {
                case QueryStaticItemFields.CasePriority.Id:
                    // TO DO
                    break;
            }
        }
    };

    handleCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        item?: IQueryFilterCriteria
    ) => {
        if (item) {
            const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;
            const match = queryFilterCriteriaSelected.find((x) => x.value == item.value);
            if (!match) return;
            if (match.staticItemId == QueryStaticItemFields.CasePriority.Id)
                match.booleanValue = event.target.checked;
            else if (event.target.name == 'chkCriteriaRequired')
                match.required = event.target.checked;
            else if (event.target.name == 'chkExactTextMatch')
                match.exactTextMatch = event.target.checked;
            else if (event.target.name == 'chkBlank') {
                match.condition = event.target.checked;
                if (event.target.checked) {
                    match.selectedValues = undefined;
                    match.text = '';
                    match.exactTextMatch = undefined;
                    match.booleanValue = undefined;
                    match.startDate = '';
                    match.endDate = '';
                    match.numericValueFromText = '';
                    match.numericValueFrom = undefined;
                    match.numericValueToText = '';
                    match.numericValueTo = undefined;
                }
            }

            this.setState({ queryFilterCriteriaSelected: queryFilterCriteriaSelected });
        } else if (event.target.name == 'chkEasyUpdate') {
            this.setState({ enableEasyUpdate: event.target.checked });
        } else if (this.state.mode === QueryToolModeEnum.Redirect) {
            let selectedGuidsForRedirect = this.state.selectedGuidsForRedirect;
            let selectAllCasesForRedirect = false;
            if (event.target.name === 'chkSelectAllCases') {
                selectedGuidsForRedirect = [];
                if (event.target.checked) {
                    selectAllCasesForRedirect = true;
                    let packageDealIndex = -1;
                    if (this.state.caseResults.columns) {
                        for (let i = 0; i < this.state.caseResults.columns.length; i++) {
                            if (
                                this.state.caseResults.columns[i].staticItemId ===
                                QueryStaticItemFields.PackageSettlement.Id
                            )
                                packageDealIndex = i;
                        }
                    }
                    if (this.state.caseResults.values) {
                        this.state.caseResults.values.map((row: string[]) => {
                            if (!row[packageDealIndex]) selectedGuidsForRedirect.push(row[0]);
                        }); // Index 0 is ALWAYS Case Guid;
                    }
                }
            } else {
                if (event.target.checked) {
                    if (
                        this.state.selectedGuidsForRedirect.findIndex(
                            (x) => x === event.target.name
                        ) === -1
                    )
                        selectedGuidsForRedirect.push(event.target.name);
                } else {
                    if (
                        this.state.selectedGuidsForRedirect.findIndex(
                            (x) => x === event.target.name
                        ) >= 0
                    )
                        selectedGuidsForRedirect.splice(
                            this.state.selectedGuidsForRedirect.findIndex(
                                (x) => x === event.target.name
                            ),
                            1
                        );
                }
            }

            this.setState({
                selectedGuidsForRedirect: selectedGuidsForRedirect,
                selectAllCasesForRedirect: selectAllCasesForRedirect,
            });
        }
    };

    buildQueryFilterCriteriaValuesColumn = (item: IQueryFilterCriteria): any => {
        const content = [];

        const isInputDisabled = (item: any) => {
            //item.condition == true means (user selected "Blank" ) false means => user selected "Not Blank"
            const disableFilter =
                item.readOnly || item.condition === true || item.condition === false;
            if (disableFilter) {
                item.validationError = undefined;
            }

            return disableFilter;
        };

        if (item.isAssertionDescriptor) {
            const assertionDescriptorMatch = this.state.assertionDescriptors.find((x) => {
                return (
                    item.assertionDescriptorGuid !== undefined &&
                    x.guid !== undefined &&
                    x.guid.toUpperCase() === item.assertionDescriptorGuid.toUpperCase()
                );
            });
            if (assertionDescriptorMatch) {
                switch (assertionDescriptorMatch.valueSourceType!.id) {
                    case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
                        if (
                            assertionDescriptorMatch.assertionDescriptorValues &&
                            assertionDescriptorMatch.assertionDescriptorValues.length > 0
                        ) {
                            const assertionDescriptorValuesAll: IQueryFilterCriteriaSelectionValue[] =
                                [];
                            for (
                                let i = 0;
                                i < assertionDescriptorMatch.assertionDescriptorValues.length;
                                i++
                            ) {
                                assertionDescriptorValuesAll.push({
                                    label: assertionDescriptorMatch.assertionDescriptorValues[i]
                                        .text!,
                                    value: assertionDescriptorMatch.assertionDescriptorValues[i]
                                        .guid!,
                                    guid: assertionDescriptorMatch.assertionDescriptorValues[i]
                                        .guid!,
                                });
                            }
                            content.push(
                                <>
                                    <CheckmateSelect
                                        isMulti={true}
                                        options={assertionDescriptorValuesAll}
                                        value={item.selectedValues}
                                        onChange={(e: any) => {
                                            this.filterValuesSelected(e, item);
                                        }}
                                        isDisabled={isInputDisabled(item)}
                                    />
                                    <span className="text-danger">{item.validationError}</span>
                                </>
                            );
                        }
                        break;
                    case AssertionDescriptorValueSourceTypeEnum.Boolean.Value: {
                        const disableSelect = isInputDisabled(item);
                        //TODO: Set the default value to No boolean criteria.
                        // const booleanValue = item && item.booleanValue !== undefined ? item.booleanValue : false;
                        // item.booleanValue = booleanValue;

                        content.push(
                            <div style={{ width: '135px' }}>
                                <CheckmateSelect
                                    options={CheckmateSelectHelper.getBooleanOptions()}
                                    value={CheckmateSelectHelper.getBooleanValue(item.booleanValue)}
                                    isDisabled={disableSelect}
                                    name={item.assertionDescriptorGuid}
                                    onChange={(selectedItem: any) => {
                                        this.handleCheckmateSingleSelectChange(
                                            selectedItem,
                                            item.value,
                                            item
                                        );
                                    }}
                                />
                                <span className="text-danger">{item.validationError}</span>
                            </div>
                        );
                        break;
                    }
                    case AssertionDescriptorValueSourceTypeEnum.Text.Value: {
                        const valueDisplayTypeId = assertionDescriptorMatch.valueDisplayType
                            ? assertionDescriptorMatch.valueDisplayType.id
                            : 0;
                        switch (valueDisplayTypeId) {
                            case AssertionDescriptorValueDisplayTypes.Currency.Value:
                            case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                            case AssertionDescriptorValueDisplayTypes.Number.Value:
                            case AssertionDescriptorValueDisplayTypes.Decimal.Value:
                                content.push(
                                    <>
                                        {valueDisplayTypeId ==
                                        AssertionDescriptorValueDisplayTypes.Currency.Value ? (
                                            <span>$&nbsp;</span>
                                        ) : null}
                                        <input
                                            className="form-control d-inline-block"
                                            type="number"
                                            name="numericValueFrom"
                                            disabled={isInputDisabled(item)}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleChange(e, item);
                                            }}
                                            value={item.numericValueFromText}
                                        ></input>
                                        <span>&nbsp;&nbsp;to&nbsp;&nbsp;</span>
                                        <input
                                            className="form-control d-inline-block"
                                            type="number"
                                            name="numericValueTo"
                                            disabled={isInputDisabled(item)}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleChange(e, item);
                                            }}
                                            value={item.numericValueToText}
                                        ></input>
                                        <span className="text-danger ms-2">
                                            {item.validationError}
                                        </span>
                                    </>
                                );
                                break;
                            case AssertionDescriptorValueDisplayTypes.DateTime.Value:
                                content.push(
                                    <>
                                        <input
                                            type="date"
                                            className={
                                                'form-control d-inline-block' +
                                                (item.startDate &&
                                                Common.isValidDate(item.startDate)
                                                    ? ''
                                                    : ' unselectClass')
                                            }
                                            disabled={isInputDisabled(item)}
                                            min="1753-01-01"
                                            style={{ display: 'inline-block' }}
                                            value={
                                                item.startDate
                                                    ? Common.dateFormat(item.startDate)
                                                    : ''
                                            }
                                            name="startDate"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleChange(e, item);
                                            }}
                                        />
                                        <span>&nbsp;&nbsp;to&nbsp;&nbsp;</span>
                                        <input
                                            type="date"
                                            className={
                                                'form-control d-inline-block' +
                                                (item.endDate && Common.isValidDate(item.endDate)
                                                    ? ''
                                                    : ' unselectClass')
                                            }
                                            min="1753-01-01"
                                            disabled={isInputDisabled(item)}
                                            style={{ display: 'inline-block' }}
                                            value={
                                                item.endDate ? Common.dateFormat(item.endDate) : ''
                                            }
                                            name="endDate"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleChange(e, item);
                                            }}
                                        />
                                        <span className="text-danger ms-2">
                                            {item.validationError}
                                        </span>
                                    </>
                                );
                                break;
                            default:
                                content.push(
                                    <span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter at least 3 characters"
                                            disabled={isInputDisabled(item)}
                                            value={item.text}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleChange(e, item);
                                            }}
                                        ></input>
                                        <span className="text-danger">{item.validationError}</span>
                                    </span>
                                );
                                break;
                        }
                        break;
                    }
                    default:
                        content.push(<span className="text-danger">Unexpected Error!</span>);
                        break;
                }
            } else {
                content.push(<span className="text-danger">Unexpected Error!</span>);
            }
        } else {
            switch (item.staticItemId) {
                case QueryStaticItemFields.CaseNumber.Id:
                case QueryStaticItemFields.UniqueCaseID.Id:
                    content.push(
                        <span>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Enter at least 3 characters"
                                name={item.value}
                                disabled={isInputDisabled(item)}
                                value={item.text}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleChange(e, item);
                                }}
                            ></input>
                            <span className="text-danger">{item.validationError}</span>
                        </span>
                    );
                    break;
                case QueryStaticItemFields.CaseStatus.Id:
                    content.push(
                        <>
                            <CheckmateSelect
                                isMulti={true}
                                options={this.state.caseStatusesForQueryCriteria}
                                isDisabled={isInputDisabled(item)}
                                value={item.selectedValues}
                                onChange={(e: any) => {
                                    this.filterValuesSelected(e, item);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </>
                    );
                    break;
                case QueryStaticItemFields.CaseManager.Id:
                    content.push(
                        <>
                            <CheckmateSelect
                                isMulti={true}
                                options={this.state.usersInZone}
                                isDisabled={isInputDisabled(item)}
                                value={item.selectedValues}
                                onChange={(e: any) => {
                                    this.filterValuesSelected(e, item);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </>
                    );
                    break;
                case QueryStaticItemFields.LocalCounsel.Id:
                    content.push(
                        <>
                            <CheckmateSelect
                                isMulti={true}
                                options={this.state.allDefenseCounselsForZone}
                                isDisabled={isInputDisabled(item)}
                                value={item.selectedValues}
                                onChange={(e: any) => {
                                    this.filterValuesSelected(e, item);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </>
                    );
                    break;
                case QueryStaticItemFields.OtherDefenseCounsels.Id:
                    content.push(
                        <>
                            <CheckmateSelect
                                isMulti={true}
                                options={this.state.otherDefenseCounselsForZone}
                                isDisabled={isInputDisabled(item)}
                                value={item.selectedValues}
                                onChange={(e: any) => {
                                    this.filterValuesSelected(e, item);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </>
                    );
                    break;
                case QueryStaticItemFields.CasePriority.Id:
                    content.push(
                        <div className="col-sm-2">
                            <CheckmateSelect
                                options={CheckmateSelectHelper.getBooleanOptions()}
                                isDisabled={isInputDisabled(item)}
                                value={CheckmateSelectHelper.getBooleanValue(item.booleanValue)}
                                name={UIHelper.getName(
                                    this.state.staticItems,
                                    QueryStaticItemFields.CasePriority.Id
                                )}
                                onChange={(e: any) => {
                                    this.handleCheckmateSingleSelectChange(
                                        e,
                                        UIHelper.getName(
                                            this.state.staticItems,
                                            QueryStaticItemFields.CasePriority.Id
                                        ),
                                        item
                                    );
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </div>
                    );
                    break;
                case QueryStaticItemFields.ResolutionDate.Id:
                    content.push(
                        <div>
                            <div className="row">
                                <div style={{ display: 'inline-block' }}>
                                    <input
                                        className={
                                            'form-control d-inline-block' +
                                            (item.startDate && Common.isValidDate(item.startDate)
                                                ? ''
                                                : ' unselectClass')
                                        }
                                        min="1753-01-01"
                                        disabled={item.readOnly}
                                        value={
                                            item.startDate ? Common.dateFormat(item.startDate) : ''
                                        }
                                        type="date"
                                        name="resolutionDateStartDate"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleChange(e, item);
                                        }}
                                    />
                                    <span>&nbsp;&nbsp;to&nbsp;&nbsp;</span>
                                    <input
                                        className={
                                            'form-control d-inline-block' +
                                            (item.endDate && Common.isValidDate(item.endDate)
                                                ? ''
                                                : ' unselectClass')
                                        }
                                        value={item.endDate ? Common.dateFormat(item.endDate) : ''}
                                        min="1753-01-01"
                                        disabled={item.readOnly}
                                        type="date"
                                        name="resolutionDateEndDate"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleChange(e, item);
                                        }}
                                    />
                                </div>
                            </div>
                            <span className="text-danger">{item.validationError}</span>
                        </div>
                    );
                    break;
                case QueryStaticItemFields.CaseStatusAsOfDate.Id:
                    content.push(
                        <div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <CheckmateSelect
                                        options={CheckmateSelectHelper.getLookupOptions(
                                            this.getAsOfDateStatusLookupValues()
                                        )}
                                        isDisabled={item.readOnly}
                                        value={CheckmateSelectHelper.getSelectedValueById(
                                            this.getAsOfDateStatusLookupValues(),
                                            item.selectedValues && item.selectedValues.length > 0
                                                ? item.selectedValues[0].id!
                                                : 0
                                        )}
                                        name="asOfStatus"
                                        onChange={(e: any) => {
                                            this.handleCheckmateSingleSelectChange(
                                                e,
                                                'asOfStatus',
                                                item
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <CheckmateSelect
                                        isMulti={true}
                                        options={this.getSubStatusLookupValues(
                                            item.selectedValues && item.selectedValues.length > 0
                                                ? item.selectedValues[0].id!
                                                : 0
                                        )}
                                        isDisabled={item.readOnly}
                                        value={this.getSelectedAsOfSubStatusValues(item)}
                                        onChange={(e: any) => {
                                            this.filterValuesSelected(e, item);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div style={{ display: 'inline-block' }}>
                                    <input
                                        className={
                                            'form-control d-inline-block' +
                                            (item.startDate && Common.isValidDate(item.startDate)
                                                ? ''
                                                : ' unselectClass')
                                        }
                                        min="1753-01-01"
                                        disabled={item.readOnly}
                                        value={
                                            item.startDate ? Common.dateFormat(item.startDate) : ''
                                        }
                                        type="date"
                                        name="asOfStartDate"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleChange(e, item);
                                        }}
                                    />
                                    <span>&nbsp;&nbsp;to&nbsp;&nbsp;</span>
                                    <input
                                        className={
                                            'form-control d-inline-block' +
                                            (item.endDate && Common.isValidDate(item.endDate)
                                                ? ''
                                                : ' unselectClass')
                                        }
                                        value={item.endDate ? Common.dateFormat(item.endDate) : ''}
                                        min="1753-01-01"
                                        disabled={item.readOnly}
                                        type="date"
                                        name="asOfEndDate"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleChange(e, item);
                                        }}
                                    />
                                </div>
                            </div>
                            <span className="text-danger">{item.validationError}</span>
                        </div>
                    );
                    break;
                case QueryStaticItemFields.NotesSection.Id:
                    content.push(
                        <table className="table table-sm">
                            {item.validationError ? (
                                <tr>
                                    <td colSpan={2}>
                                        <span className="text-danger">{item.validationError}</span>
                                    </td>
                                </tr>
                            ) : null}
                            {this.getNoteCriteriaRows()}
                        </table>
                    );
                    break;
                case QueryStaticItemFields.DepositionSection.Id:
                    content.push(
                        <table className="table table-sm">
                            <tbody>
                                {item.validationError ? (
                                    <tr>
                                        <td colSpan={2}>
                                            <span className="text-danger">
                                                {item.validationError}
                                            </span>
                                        </td>
                                    </tr>
                                ) : null}
                                <tr>
                                    <td style={{ borderTop: 'none' }}>Type</td>
                                    <td style={{ borderTop: 'none' }}>
                                        <CheckmateSelect
                                            isMulti={true}
                                            options={this.state.depositionCategories}
                                            value={
                                                this.state.depositionSearchCriteria &&
                                                this.state.depositionSearchCriteria.categories
                                                    ? this.state.depositionSearchCriteria.categories
                                                    : []
                                            }
                                            isDisabled={
                                                this.state.depositionSearchCriteria
                                                    ? this.state.depositionSearchCriteria.readOnly
                                                    : false
                                            }
                                            onChange={(e: any) => {
                                                this.filterValuesSelectedAlt(
                                                    e,
                                                    'depositionCategories'
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td>
                                        <CheckmateSelect
                                            isMulti={true}
                                            options={this.state.depositionStatusTypes}
                                            value={
                                                this.state.depositionSearchCriteria &&
                                                this.state.depositionSearchCriteria.statuses
                                                    ? this.state.depositionSearchCriteria.statuses
                                                    : []
                                            }
                                            isDisabled={
                                                this.state.depositionSearchCriteria
                                                    ? this.state.depositionSearchCriteria.readOnly
                                                    : false
                                            }
                                            onChange={(e: any) => {
                                                this.filterValuesSelectedAlt(
                                                    e,
                                                    'depositionStatuses'
                                                );
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Date Range</td>
                                    <td>
                                        <input
                                            className={
                                                'form-control d-inline-block' +
                                                (this.state.depositionSearchCriteria &&
                                                this.state.depositionSearchCriteria.startDate &&
                                                Common.isValidDate(
                                                    this.state.depositionSearchCriteria.startDate
                                                )
                                                    ? ''
                                                    : ' unselectClass')
                                            }
                                            min="1753-01-01"
                                            disabled={
                                                this.state.depositionSearchCriteria
                                                    ? this.state.depositionSearchCriteria.readOnly
                                                    : false
                                            }
                                            value={
                                                this.state.depositionSearchCriteria &&
                                                this.state.depositionSearchCriteria.startDate
                                                    ? Common.dateFormat(
                                                          this.state.depositionSearchCriteria
                                                              .startDate
                                                      )
                                                    : ''
                                            }
                                            type="date"
                                            name="depositionStartDate"
                                            onChange={this.handleChangeAlt}
                                        />
                                        <span>&nbsp;to&nbsp;</span>
                                        <input
                                            className={
                                                'form-control d-inline-block' +
                                                (this.state.depositionSearchCriteria &&
                                                this.state.depositionSearchCriteria.endDate &&
                                                Common.isValidDate(
                                                    this.state.depositionSearchCriteria.endDate
                                                )
                                                    ? ''
                                                    : ' unselectClass')
                                            }
                                            min="1753-01-01"
                                            disabled={
                                                this.state.depositionSearchCriteria
                                                    ? this.state.depositionSearchCriteria.readOnly
                                                    : false
                                            }
                                            value={
                                                this.state.depositionSearchCriteria &&
                                                this.state.depositionSearchCriteria.endDate
                                                    ? Common.dateFormat(
                                                          this.state.depositionSearchCriteria
                                                              .endDate
                                                      )
                                                    : ''
                                            }
                                            type="date"
                                            name="depositionEndDate"
                                            onChange={this.handleChangeAlt}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Defense Counsel</td>
                                    <td>
                                        <CheckmateSelect
                                            options={this.getAllDefenseCounselMultiSelectValuesForZone()}
                                            name="DepositionDefenseCounsel"
                                            value={
                                                this.state.depositionSearchCriteria &&
                                                this.state.depositionSearchCriteria.defenseCounsel
                                                    ? this.state.depositionSearchCriteria
                                                          .defenseCounsel
                                                    : undefined
                                            }
                                            onChange={this.onSearchableSingleSelectChange}
                                        />
                                    </td>
                                </tr>
                                {this.state.depositionSearchCriteria &&
                                this.state.depositionSearchCriteria.defenseCounsel &&
                                this.state.depositionSearchCriteria.defenseCounsel.guid ? (
                                    <tr>
                                        <td>Defense Attorney</td>
                                        <td>
                                            <CheckmateSelect
                                                isMulti={true}
                                                options={this.getUsersInDefenseFirmOptions(
                                                    this.state.depositionSearchCriteria
                                                        .defenseCounsel.guid
                                                )}
                                                value={
                                                    this.state.depositionSearchCriteria
                                                        .defenseCounselUsers || []
                                                }
                                                isDisabled={
                                                    this.state.depositionSearchCriteria.readOnly
                                                }
                                                onChange={(e: any) => {
                                                    this.filterValuesSelectedAlt(
                                                        e,
                                                        'defenseCounselUsers'
                                                    );
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ) : null}
                            </tbody>
                        </table>
                    );
                    break;
                case QueryStaticItemFields.State.Id:
                    content.push(
                        <div>
                            <CheckmateSelect
                                isMulti={true}
                                options={this.state.USStates}
                                isDisabled={isInputDisabled(item)}
                                value={item.selectedValues}
                                onChange={(e: any) => {
                                    this.filterValuesSelected(e, item);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </div>
                    );
                    break;
                case QueryStaticItemFields.PackageSettlement.Id:
                    content.push(
                        <div>
                            <div className="row">
                                <div className="full-width" style={{ display: 'inline-block' }}>
                                    <span>Name&nbsp;</span>
                                    <input
                                        className="form-control"
                                        style={{ display: 'inline-block', width: '50%' }}
                                        type="text"
                                        placeholder="Enter at least 3 characters"
                                        disabled={isInputDisabled(item)}
                                        name="packageSettlementName"
                                        value={item.text}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleChange(e, item);
                                        }}
                                    ></input>
                                    &nbsp;
                                    <input
                                        type="checkbox"
                                        name="chkExactTextMatch"
                                        className="form-check-input"
                                        style={{ display: 'inline-block' }}
                                        disabled={isInputDisabled(item)}
                                        checked={item.exactTextMatch}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleCheckedChange(e, item);
                                        }}
                                    />{' '}
                                    &nbsp;Exact Match
                                </div>
                            </div>
                            <div className="row">
                                <CheckmateSelect
                                    isMulti={true}
                                    options={this.getPlaintiffFirmsList()}
                                    isDisabled={isInputDisabled(item)}
                                    value={item.selectedValues}
                                    onChange={(e: any) => {
                                        this.filterValuesSelected(e, item);
                                    }}
                                />
                            </div>
                            <div className="row">
                                <div style={{ display: 'inline-block' }}>
                                    <input
                                        className={
                                            item.startDate && Common.isValidDate(item.startDate)
                                                ? 'form-control'
                                                : 'unselectClass form-control'
                                        }
                                        style={{ display: 'inline-block' }}
                                        min="1753-01-01"
                                        disabled={isInputDisabled(item)}
                                        value={
                                            item.startDate ? Common.dateFormat(item.startDate) : ''
                                        }
                                        type="date"
                                        name="packageSettlementStartDate"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleChange(e, item);
                                        }}
                                    />
                                    <span>&nbsp;&nbsp;to&nbsp;&nbsp;</span>
                                    <input
                                        className={
                                            item.endDate && Common.isValidDate(item.endDate)
                                                ? 'form-control'
                                                : 'unselectClass form-control'
                                        }
                                        style={{ display: 'inline-block' }}
                                        min="1753-01-01"
                                        disabled={isInputDisabled(item)}
                                        value={item.endDate ? Common.dateFormat(item.endDate) : ''}
                                        type="date"
                                        name="packageSettlementEndDate"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleChange(e, item);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                    break;
                case QueryStaticItemFields.ExpertsSection.Id:
                    content.push(
                        <ExpertsCriteriaSelector
                            error={item.validationError}
                            selectedExpertGuids={
                                this.state.caseExpertsSearchCriteria?.expertGuids ?? []
                            }
                            selectedRetainedByTypeIds={
                                this.state.caseExpertsSearchCriteria?.retainedByTypeIds ?? []
                            }
                            selectedReportStatusTypeIds={
                                this.state.caseExpertsSearchCriteria?.reportStatusTypeIds ?? []
                            }
                            onExpertChange={(e: any) => {
                                this.filterValuesSelectedAlt(e, 'caseExpertsExpert');
                            }}
                            onRetainedByChange={(e: any) => {
                                this.filterValuesSelectedAlt(e, 'caseExpertsRetainedBy');
                            }}
                            onReportStatusChange={(e: any) => {
                                this.filterValuesSelectedAlt(e, 'caseExpertsReportStatus');
                            }}
                        />
                    );
                    break;
            }
        }

        return content;
    };

    getQuerySettings = (): IQuerySettingsModel => {
        const columns: IQueryColumnDefinitionModel[] = [];
        if (this.state.displayColumnsSelected) {
            for (let i = 0; i < this.state.displayColumnsSelected.length; i++) {
                const item: IQueryColumnDefinitionModel = {
                    name: this.state.displayColumnsSelected[i].value,
                    assertionDescriptorGuid:
                        this.state.displayColumnsSelected[i].assertionDescriptorGuid,
                    staticItemId: this.state.displayColumnsSelected[i].staticItemId,
                };
                columns.push(item);
            }
        }

        const filters = this.processInputQueryFilters();
        const queryType = { id: this.state.queryType };

        const querySettings: IQuerySettingsModel = {
            filters,
            columns,
            queryType,
        };

        if (this.state.depositionSearchCriteria!) {
            querySettings.depositionSearchCriteria = this.state.depositionSearchCriteria;
        }

        if (this.state.caseExpertsSearchCriteria!) {
            querySettings.caseExpertSearchCriteria = this.state.caseExpertsSearchCriteria;
        }

        if (this.state.noteSearchCriteria!) {
            querySettings.noteSearchCriteria = this.state.noteSearchCriteria;
            querySettings.noteSearchCriteria.noteType = { id: NoteTypes.CaseNote };
        }

        if (this.state.queryType === QueryTypes.CaseNotes.Value && this.state.noteSearchCriteria) {
            const noteSearchCriteria = JSON.parse(JSON.stringify(this.state.noteSearchCriteria));
            if (noteSearchCriteria.startDate)
                noteSearchCriteria.startDate = Common.localStartOfDayInUTC(
                    noteSearchCriteria.startDate
                );
            if (noteSearchCriteria.endDate)
                noteSearchCriteria.endDate = Common.localEndOfDayInUTC(noteSearchCriteria.endDate);
            querySettings.noteSearchCriteria = noteSearchCriteria;
        } else if (
            this.state.queryType === QueryTypes.Deposition.Value &&
            this.state.depositionSearchCriteria
        )
            querySettings.depositionSearchCriteria = this.state.depositionSearchCriteria;

        if (this.state.redirectType) querySettings.querySubType = { id: this.state.redirectType };

        return querySettings;
    };

    runQuery = async () => {
        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
            case QueryTypes.CaseExperts.Value:
            case QueryTypes.CaseNotes.Value:
            case QueryTypes.Deposition.Value:
                {
                    const valid = this.validateCaseQueryInput();
                    if (!valid) return;

                    this.setState({
                        pendingResponse: true,
                        validation: {},
                        showTooManyRecordsToProcessDialog: false,
                        caseResultsCount: 0,
                    });

                    const querySettings = this.getQuerySettings();
                    const countResponse = await _apiClient.getCaseQueryCount(querySettings);
                    if (countResponse.httpResponse.status == 401) {
                        window.location.reload();
                        return;
                    }
                    if (countResponse.errorMessage) {
                        this.setState({
                            validation: ValidateUtils.parseErrors(
                                countResponse.errors,
                                countResponse.errorMessage
                            ),
                            pendingResponse: false,
                        });
                        return;
                    }

                    const count = countResponse.payload!.data;

                    if (count > maxRecordCountForWebProcessing) {
                        this.setState({
                            caseResultsCount: count,
                            showTooManyRecordsToProcessDialog: true,
                            pendingResponse: false,
                        });
                        return;
                    }

                    if (count > maxDisplayRecordCount) {
                        this.setState({
                            showTooManyRecordsToDisplayDialog: true,
                            pendingResponse: false,
                        });
                        return;
                    }

                    switch (this.state.queryType) {
                        case QueryTypes.CaseExperts.Value: {
                            const caseExpertsResponse = await _apiClient.getCaseQueryResults<
                                ICaseExpertModel[]
                            >(querySettings);
                            if (caseExpertsResponse.httpResponse.status == 401) {
                                window.location.reload();
                                return;
                            }
                            if (caseExpertsResponse.errorMessage) {
                                this.setState({
                                    validation: ValidateUtils.parseErrors(
                                        caseExpertsResponse.errors,
                                        caseExpertsResponse.errorMessage
                                    ),
                                    pendingResponse: false,
                                });
                                return;
                            }

                            this.setState({
                                caseExpertsResults: caseExpertsResponse.payload!,
                                pendingResponse: false,
                                caseResultsCount: count,
                            });
                            break;
                        }
                        case QueryTypes.CaseDetails.Value: {
                            const caseResponse =
                                await _apiClient.getCaseQueryResults<IQueryResultModel>(
                                    querySettings
                                );
                            if (caseResponse.httpResponse.status == 401) {
                                window.location.reload();
                                return;
                            }
                            if (caseResponse.errorMessage) {
                                this.setState({
                                    validation: ValidateUtils.parseErrors(
                                        caseResponse.errors,
                                        caseResponse.errorMessage
                                    ),
                                    pendingResponse: false,
                                });
                                return;
                            }

                            this.setState({
                                caseResults: caseResponse.payload!,
                                pendingResponse: false,
                                caseResultsCount: count,
                            });
                            break;
                        }
                        case QueryTypes.CaseNotes.Value: {
                            const caseNotesResponse = await _apiClient.getCaseNotesQueryResults(
                                querySettings
                            );
                            if (caseNotesResponse.httpResponse.status == 401) {
                                window.location.reload();
                                return;
                            }
                            if (caseNotesResponse.errorMessage) {
                                this.setState({
                                    validation: ValidateUtils.parseErrors(
                                        caseNotesResponse.errors,
                                        caseNotesResponse.errorMessage
                                    ),
                                    pendingResponse: false,
                                });
                                return;
                            }

                            this.setState({
                                noteResults: caseNotesResponse.payload!,
                                pendingResponse: false,
                            });
                            break;
                        }
                        case QueryTypes.Deposition.Value: {
                            const depoResponse = await _apiClient.getDepositionQueryResults(
                                querySettings
                            );
                            if (depoResponse.httpResponse.status == 401) {
                                window.location.reload();
                                return;
                            }
                            if (depoResponse.errorMessage) {
                                this.setState({
                                    validation: ValidateUtils.parseErrors(
                                        depoResponse.errors,
                                        depoResponse.errorMessage
                                    ),
                                    pendingResponse: false,
                                    caseResultsCount: count,
                                });
                                return;
                            }

                            this.setState({
                                depositionResults: depoResponse.payload!,
                                pendingResponse: false,
                                caseResultsCount: count,
                            });
                            break;
                        }
                        default:
                    }
                }
                break;
            case QueryTypes.ZoneNotes.Value:
            case QueryTypes.StrategyNote.Value: {
                this.setState({ pendingResponse: true, validation: {} });

                const noteSearchCriteria = JSON.parse(
                    JSON.stringify(this.state.noteSearchCriteria)
                );
                if (noteSearchCriteria.startDate)
                    noteSearchCriteria.startDate = Common.localStartOfDayInUTC(
                        noteSearchCriteria.startDate
                    );
                if (noteSearchCriteria.endDate)
                    noteSearchCriteria.endDate = Common.localEndOfDayInUTC(
                        noteSearchCriteria.endDate
                    );

                const notesResponse = await _apiClient.searchNotes(noteSearchCriteria);
                if (notesResponse.httpResponse.status == 401) {
                    window.location.reload();
                    return;
                }
                if (notesResponse.errorMessage) {
                    this.setState({
                        validation: ValidateUtils.parseErrors(
                            notesResponse.errors,
                            notesResponse.errorMessage
                        ),
                        pendingResponse: false,
                    });
                    return;
                }

                this.setState({ noteResults: notesResponse.payload!, pendingResponse: false });
                return;
            }
            default:
                return;
        }
    };

    getQueryCount = async () => {
        const querySettings: IQuerySettingsModel = this.getQuerySettings();
        const caseResponse = await _apiClient.getCaseQueryCount(querySettings);
        if (caseResponse.httpResponse.status == 401) {
            window.location.reload();
            return 0;
        }
        if (caseResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    caseResponse.errors,
                    caseResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return 0;
        }

        return caseResponse.payload!.data;
    };

    validateCaseQueryInput = (isExport?: boolean) => {
        let validationErrors = false;
        const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;

        const itemRequiresValue = (item: any) => {
            return (
                item.condition === undefined ||
                item.condition === ConditionSelectorEnums.EqualTo ||
                item.condition === ConditionSelectorEnums.NotEqualTo
            );
        };

        const verifyBooleanValidation = (item: any) => {
            if (itemRequiresValue(item) && item.booleanValue === undefined) {
                item.validationError = 'Select Yes / No';
                validationErrors = true;
            } else {
                item.validationError = undefined;
                validationErrors = false;
            }
        };

        const depositionSearchFieldValidation = (item: any) => {
            let validationMessage = '';
            let validationRequired: boolean = false;
            if (itemRequiresValue(item)) {
                validationRequired =
                    !this.state.depositionSearchCriteria ||
                    (!this.state.depositionSearchCriteria.startDate &&
                        !this.state.depositionSearchCriteria.endDate &&
                        (!this.state.depositionSearchCriteria.categories ||
                            this.state.depositionSearchCriteria.categories.length == 0 ||
                            this.state.depositionSearchCriteria.categories.filter(
                                (x) => x.id && x.id > 0
                            ).length === 0) &&
                        (!this.state.depositionSearchCriteria.statuses ||
                            this.state.depositionSearchCriteria.statuses.filter(
                                (x) => x.id && x.id > 0
                            ).length === 0) &&
                        !this.state.depositionSearchCriteria.defenseCounsel);

                if (validationRequired) {
                    validationMessage = 'Enter at least one Case deposition Criteria';
                    return validationMessage;
                }

                if (!validationRequired) {
                    if (
                        this.state.depositionSearchCriteria!.startDate! &&
                        this.state.depositionSearchCriteria!.endDate!
                    ) {
                        if (
                            this.state.depositionSearchCriteria!.startDate >
                            this.state.depositionSearchCriteria!.endDate
                        ) {
                            validationRequired = true;
                        }
                    }
                    if (validationRequired) {
                        validationMessage = 'Start Date must be greater than End Date';
                    }
                }
            }
            return validationMessage;
        };

        const notesSearchFieldValidation = (item: any) => {
            let validationMessage = '';
            let validationRequired: boolean = false;
            if (itemRequiresValue(item)) {
                validationRequired =
                    !this.state.noteSearchCriteria ||
                    (!this.state.noteSearchCriteria.startDate &&
                        !this.state.noteSearchCriteria.endDate &&
                        (!this.state.noteSearchCriteria.noteCategories ||
                            this.state.noteSearchCriteria.noteCategories.length == 0) &&
                        (!this.state.noteSearchCriteria.purpose ||
                            this.state.noteSearchCriteria.purpose.filter((x) => x.id && x.id > 0)
                                .length === 0) &&
                        (!this.state.noteSearchCriteria.approvalStatus ||
                            this.state.noteSearchCriteria.approvalStatus.filter(
                                (x) => x.id && x.id > 0
                            ).length === 0) &&
                        this.state.noteSearchCriteria.private === undefined &&
                        this.state.noteSearchCriteria.allowLocalEdit === undefined &&
                        this.state.noteSearchCriteria.modifiedByLocal === undefined &&
                        this.state.noteSearchCriteria.hasAttachment === undefined &&
                        this.state.noteSearchCriteria.isAssociatedCaseExpertCaseNote === undefined);

                if (validationRequired) {
                    validationMessage = 'Enter at least one Case Note Criteria';
                    return validationMessage;
                }

                if (!validationRequired) {
                    if (
                        this.state.noteSearchCriteria!.startDate! &&
                        this.state.noteSearchCriteria!.endDate!
                    ) {
                        if (
                            this.state.noteSearchCriteria!.startDate >
                            this.state.noteSearchCriteria!.endDate
                        ) {
                            validationRequired = true;
                        }
                    }
                    if (validationRequired) {
                        validationMessage = 'Start Date must be greater than End Date';
                    }
                }
            }
            return validationMessage;
        };

        // Loop through all selected options and check for any validation errors
        for (let i = 0; i < queryFilterCriteriaSelected.length; i++) {
            const item = queryFilterCriteriaSelected[i];
            if (item.isAssertionDescriptor) {
                const assertionDescriptorMatch = this.state.assertionDescriptors.find(
                    (x) => x.guid == item.assertionDescriptorGuid
                );
                if (assertionDescriptorMatch) {
                    switch (assertionDescriptorMatch.valueSourceType!.id) {
                        case AssertionDescriptorValueSourceTypeEnum.Boolean.Value:
                            verifyBooleanValidation(item);
                            break;
                        case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
                            if (
                                itemRequiresValue(item) &&
                                (!item.selectedValues || item.selectedValues.length == 0)
                            ) {
                                item.validationError = 'Select at least one value';
                                validationErrors = true;
                            } else {
                                item.validationError = undefined;
                                validationErrors = false;
                            }
                            break;
                        case AssertionDescriptorValueSourceTypeEnum.Text.Value: {
                            const valueDisplayTypeId = assertionDescriptorMatch.valueDisplayType
                                ? assertionDescriptorMatch.valueDisplayType.id
                                : 0;
                            switch (valueDisplayTypeId) {
                                case AssertionDescriptorValueDisplayTypes.DateTime.Value:
                                    if (
                                        itemRequiresValue(item) &&
                                        !item.startDate &&
                                        !item.endDate
                                    ) {
                                        item.validationError = 'Enter at least one date';
                                        validationErrors = true;
                                    } else if (
                                        itemRequiresValue(item) &&
                                        item.startDate &&
                                        !Common.isValidDate(item.startDate)
                                    ) {
                                        item.validationError = 'Invalid Date';
                                        validationErrors = true;
                                    } else if (
                                        itemRequiresValue(item) &&
                                        item.endDate &&
                                        !Common.isValidDate(item.endDate)
                                    ) {
                                        item.validationError = 'Invalid Date';
                                        validationErrors = true;
                                    } else {
                                        item.validationError = undefined;
                                        validationErrors = false;
                                    }
                                    break;
                                case AssertionDescriptorValueDisplayTypes.Currency.Value:
                                case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                                case AssertionDescriptorValueDisplayTypes.Number.Value:
                                case AssertionDescriptorValueDisplayTypes.Decimal.Value:
                                    if (
                                        itemRequiresValue(item) &&
                                        !item.numericValueFrom &&
                                        !item.numericValueTo
                                    ) {
                                        item.validationError = 'Enter at least one value';
                                        validationErrors = true;
                                    } else {
                                        item.validationError = undefined;
                                        validationErrors = false;
                                    }
                                    break;
                                default:
                                    if (
                                        itemRequiresValue(item) &&
                                        (item.text == null || item.text.trim().length == 0)
                                    ) {
                                        item.validationError = 'Enter at least 3 characters';
                                        validationErrors = true;
                                    } else {
                                        item.validationError = undefined;
                                        validationErrors = false;
                                    }
                                    break;
                            }
                            break;
                        }
                        default:
                            break;
                    }
                }
            } else {
                switch (item.staticItemId) {
                    case QueryStaticItemFields.CaseNumber.Id:
                    case QueryStaticItemFields.UniqueCaseID.Id:
                        if (
                            itemRequiresValue(item) &&
                            (item.text == null || item.text.trim().length == 0)
                        ) {
                            item.validationError = 'Enter at least 3 characters';
                            validationErrors = true;
                        } else {
                            item.validationError = undefined;
                            validationErrors = false;
                        }
                        break;
                    case QueryStaticItemFields.CaseStatus.Id:
                    case QueryStaticItemFields.CaseManager.Id: //CHECK739
                    case QueryStaticItemFields.LocalCounsel.Id:
                    case QueryStaticItemFields.OtherDefenseCounsels.Id:
                    case QueryStaticItemFields.State.Id:
                        if (
                            itemRequiresValue(item) &&
                            (!item.selectedValues || item.selectedValues.length == 0)
                        ) {
                            item.validationError = 'Select at least one value';
                            validationErrors = true;
                        } else {
                            item.validationError = undefined;
                            validationErrors = false;
                        }
                        break;
                    case QueryStaticItemFields.CasePriority.Id:
                        verifyBooleanValidation(item);
                        break;
                    case QueryStaticItemFields.CaseStatusAsOfDate.Id:
                    case QueryStaticItemFields.ResolutionDate.Id:
                        if (itemRequiresValue(item) && !item.startDate) {
                            item.validationError = 'Start date is required';
                            validationErrors = true;
                        } else {
                            item.validationError = undefined;
                            validationErrors = false;
                        }
                        break;
                    case QueryStaticItemFields.NotesSection.Id:
                        const noteValidationMessage: string = notesSearchFieldValidation(item);
                        if (noteValidationMessage.length > 0) {
                            item.validationError = noteValidationMessage;
                            validationErrors = true;
                        } else {
                            item.validationError = undefined;
                            validationErrors = false;
                        }

                        break;
                    case QueryStaticItemFields.DepositionSection.Id:
                        const validationMessage: string = depositionSearchFieldValidation(item);
                        if (validationMessage.length > 0) {
                            item.validationError = validationMessage;
                            validationErrors = true;
                        } else {
                            item.validationError = undefined;
                            validationErrors = false;
                        }
                        break;
                    case QueryStaticItemFields.ExpertsSection.Id:
                        if (
                            itemRequiresValue(item) &&
                            (!this.state.caseExpertsSearchCriteria ||
                                ((!this.state.caseExpertsSearchCriteria.expertGuids ||
                                    this.state.caseExpertsSearchCriteria.expertGuids.length == 0) &&
                                    (!this.state.caseExpertsSearchCriteria.reportStatusTypeIds ||
                                        this.state.caseExpertsSearchCriteria.reportStatusTypeIds
                                            .length == 0) &&
                                    (!this.state.caseExpertsSearchCriteria.retainedByTypeIds ||
                                        this.state.caseExpertsSearchCriteria.retainedByTypeIds
                                            .length == 0)))
                        ) {
                            item.validationError = 'Select at least one Case Experts Criteria';
                            validationErrors = true;
                        } else {
                            item.validationError = undefined;
                            validationErrors = false;
                        }
                        break;
                    case QueryStaticItemFields.PackageSettlement.Id:
                        //TODO: validation has to be done for all the optionCSS.
                        // if ((item) &&(item.text == null || item.text.trim().length == 0)) {
                        //     item.validationError = 'Enter at least 3 characters';
                        //     validationErrors = true;
                        // }
                        // else {
                        //     item.validationError = undefined;
                        //     validationErrors = false;
                        // }
                        break;
                }
            }
        }

        const validation: IValidation = {};

        //CHECK1317

        // At least one Criteria is required
        if (
            itemRequiresValue(queryFilterCriteriaSelected) &&
            queryFilterCriteriaSelected.length == 0
        ) {
            validation.criteria = ['Enter at least one criteria'];
            validationErrors = true;
        }

        // Max Criteria limit
        if (queryFilterCriteriaSelected.length > 10) {
            validation.criteria = ['You can only include 10 Criteria at a time'];
            validationErrors = true;
        }

        // Max columns selected
        if (
            !isExport &&
            this.state.queryType == QueryTypes.CaseDetails.Value &&
            this.state.displayColumnsSelected.length > 10
        ) {
            validation.columns = [
                'You can only select a maximum of 10 Columns for display on the screen. You can export to include any number of columns.',
            ];
            validationErrors = true;
        }

        // At least one criteria should be marked as required.
        if (
            queryFilterCriteriaSelected.length > 0 &&
            !queryFilterCriteriaSelected.find((x) => x.required === true)
        ) {
            validation.criteria = ["At least one Criteria should be marked as 'Required'"];
            validationErrors = true;
        }

        if (
            this.state.queryFilterCriteriaSelected.find(
                (x) => x.validationError != null && x.validationError.length > 0
            )
        ) {
            this.setState({ queryFilterCriteriaSelected: queryFilterCriteriaSelected });
            return false;
        }

        if (validationErrors) {
            this.setState({
                validation: validation,
                queryFilterCriteriaSelected: queryFilterCriteriaSelected,
            });
            return false;
        }

        return true;
    };

    processInputQueryFilters(): IQueryFilterCriteria[] {
        // Special Logic to separate Status & Substatus
        const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;

        const filters: IQueryFilterCriteria[] = JSON.parse(
            JSON.stringify(queryFilterCriteriaSelected)
        );
        const caseStatusFilter = filters.find(
            (x) => x.staticItemId === QueryStaticItemFields.CaseStatus.Id
        );

        if (
            caseStatusFilter &&
            caseStatusFilter.selectedValues &&
            caseStatusFilter.selectedValues.length
        ) {
            const statusIds: number[] = [];
            const subStatusIds: number[] = [];

            for (let i = 0; i < caseStatusFilter.selectedValues!.length; i++) {
                const item = caseStatusFilter.selectedValues[i];
                if (item.id) {
                    const match = this.state.caseStatuses.find((x) => x.id == item.id);
                    if (match) {
                        if (match.status) statusIds.push(match.status!.id);
                        if (match.subStatus) subStatusIds.push(match.subStatus.id);
                    }
                }
            }

            if (statusIds.length > 0) {
                const selectedValues = statusIds.map((item) => {
                    const container: IQueryFilterCriteriaSelectionValue = {
                        label: '',
                        value: '',
                        id: item,
                    };
                    return container;
                });

                filters.push({
                    label: '',
                    value: '',
                    required: caseStatusFilter.required,
                    selectedValues: selectedValues,
                    staticItemId: InternalDatabaseFields_CaseStatus_Status,
                    condition: caseStatusFilter.condition,
                });
            }

            if (subStatusIds.length > 0) {
                const selectedValues = subStatusIds.map((item) => {
                    const container: IQueryFilterCriteriaSelectionValue = {
                        label: '',
                        value: '',
                        id: item,
                    };
                    return container;
                });

                filters.push({
                    label: '',
                    value: '',
                    required: caseStatusFilter.required,
                    selectedValues: selectedValues,
                    staticItemId: InternalDatabaseFields_CaseStatus_SubStatus,
                    condition: caseStatusFilter.condition,
                });
            }

            // Remove CaseStatus filter as its only a UI thing
            for (let i = 0; i < filters.length; i++) {
                if (filters[i].staticItemId === QueryStaticItemFields.CaseStatus.Id) {
                    filters.splice(i, 1);
                    break;
                }
            }
        }

        // Additional default logic for Boolean Types. The dropdown will only have Yes & No (No empty item) & defaulting to No. Set the boolean value to false if no value is explicitly selected since the server requires a valid value for boolean filter of true or false
        for (let i = 0; i < filters.length; i++) {
            const item = filters[i];
            if (item.isAssertionDescriptor) {
                const assertionDescriptorMatch = this.state.assertionDescriptors.find(
                    (x) => x.guid == item.assertionDescriptorGuid
                );
                if (
                    assertionDescriptorMatch &&
                    assertionDescriptorMatch.valueSourceType &&
                    assertionDescriptorMatch.valueSourceType.id ==
                        AssertionDescriptorValueSourceTypeEnum.Boolean.Value
                ) {
                    if (!item.booleanValue) item.booleanValue = false;
                }
            } else if (
                item.staticItemId === QueryStaticItemFields.CasePriority.Id &&
                !item.booleanValue
            )
                item.booleanValue = false;
        }

        // Special Time Zone related logic for Case Notes Date Range. All dates in Checkmate so far EXCEPT Case Notes Date are input fields, hence we do an actual match w/o timezone. However, Case Notes Date is stored as UTC in DB. Hence we need to convert to UTC before passing to server.
        const caseNotesFilterMatch = filters.find(
            (x) => x.staticItemId == QueryStaticItemFields.NotesSection.Id
        );
        if (caseNotesFilterMatch) {
            if (caseNotesFilterMatch.startDate)
                caseNotesFilterMatch.startDate = Common.localStartOfDayInUTC(
                    caseNotesFilterMatch.startDate
                );
            if (caseNotesFilterMatch.endDate)
                caseNotesFilterMatch.endDate = Common.localEndOfDayInUTC(
                    caseNotesFilterMatch.endDate
                );
        }

        return filters;
    }

    logQueryFavorite = () => {
        const columns: IQueryColumnDefinitionModel[] = [];
        if (this.state.displayColumnsSelected) {
            for (let i = 0; i < this.state.displayColumnsSelected.length; i++) {
                const item: IQueryColumnDefinitionModel = {
                    name: this.state.displayColumnsSelected[i].value,
                    assertionDescriptorGuid:
                        this.state.displayColumnsSelected[i].assertionDescriptorGuid,
                };
                columns.push(item);
            }
        }
    };

    clearQuery = () => {
        window.location.reload();
    };

    buildResultTableBody() {
        const rows = [];

        if (this.state.caseResults.values) {
            const columnDataTypes: string[] = [];
            let packageDealIndex = -1;
            let packageDealGuidIndex = -1;
            let otherDefenseCounselIndex = -1;
            if (this.state.caseResults.columns) {
                for (let i = 0; i < this.state.caseResults.columns.length; i++) {
                    if (
                        this.state.caseResults.columns[i] &&
                        this.state.caseResults.columns[i].dataType
                    )
                        columnDataTypes.push(this.state.caseResults.columns[i].dataType!);
                    else columnDataTypes.push('');

                    if (
                        this.state.caseResults.columns[i].staticItemId ===
                        QueryStaticItemFields.OtherDefenseCounsels.Id
                    ) {
                        otherDefenseCounselIndex = i;
                    }
                    if (
                        this.state.caseResults.columns[i].staticItemId ===
                        QueryStaticItemFields.PackageSettlement.Id
                    ) {
                        packageDealIndex = i;
                    } else if (
                        this.state.caseResults.columns[i].staticItemId ===
                        InternalDatabaseFields_PackageSettlementGuid
                    ) {
                        packageDealGuidIndex = i;
                    }
                }
            }

            for (let rowIndex = 0; rowIndex < this.state.caseResults.values.length; rowIndex++) {
                // Index 0 is ALWAYS Case Guid;
                // Index 1 is Case Name.

                const row = this.state.caseResults.values[rowIndex];
                const caseGuidColIndex = 0;
                const caseNameColIndex = 1; // Index 1 is ALWAYS Case Name. Add a link to Case File like everywhere else
                const caseGuid: string = row[caseGuidColIndex];
                const columns = [];

                if (this.state.mode === QueryToolModeEnum.Redirect) {
                    let hasPackageDeal = false;

                    if (row[packageDealIndex]) hasPackageDeal = true;

                    if (!hasPackageDeal)
                        columns.push(
                            <td>
                                <input
                                    type="checkbox"
                                    name={caseGuid}
                                    className="form-check-input"
                                    checked={
                                        this.state.selectAllCasesForRedirect ||
                                        this.state.selectedGuidsForRedirect.findIndex(
                                            (x) => x === caseGuid
                                        ) >= 0
                                    }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.handleCheckedChange(e);
                                    }}
                                />{' '}
                            </td>
                        );
                    else columns.push(<td />);
                }

                for (let colIndex = 1; colIndex < row.length; colIndex++) {
                    if (colIndex == caseNameColIndex)
                        columns.push(
                            <td className="col-sm-2">
                                <Link
                                    target="_blank"
                                    to={LocalRoutes.CaseFile.replace(':guid', caseGuid)}
                                >
                                    {row[colIndex]}
                                </Link>
                            </td>
                        );
                    else if (colIndex == packageDealIndex) {
                        const packageDealGuid = row[packageDealGuidIndex];
                        if (packageDealGuid)
                            columns.push(
                                <td>
                                    <Link
                                        target="_blank"
                                        to={LocalRoutes.PackageSettlementDetails.replace(
                                            ':guid',
                                            packageDealGuid
                                        )}
                                    >
                                        {row[colIndex]}
                                    </Link>
                                </td>
                            );
                        else columns.push(<td>{row[colIndex]}</td>);
                    } else if (colIndex == packageDealGuidIndex) {
                        // Dont include in display
                    } else if (colIndex == otherDefenseCounselIndex) {
                        const counsels = row[colIndex].split('|');
                        const parts = [];
                        for (let i = 0; i < counsels.length; i++) {
                            if (i == 0) {
                                parts.push(<span> {counsels[i]} </span>);
                            } else {
                                parts.push(
                                    <span>
                                        <br />
                                        {counsels[i]}
                                    </span>
                                );
                            }
                        }
                        columns.push(<td>{parts}</td>);
                    } else {
                        columns.push(
                            <td>
                                {this.getFormattedValue(row[colIndex], columnDataTypes[colIndex])}
                            </td>
                        );
                    }
                }
                if (this.state.enableEasyUpdate) {
                    columns.push(
                        <td width={85} className="center">
                            {' '}
                            <button
                                className="btn-no-bg"
                                onClick={() => {
                                    this.handleEasyUpdate(caseGuid);
                                }}
                            >
                                {' '}
                                <i
                                    className={`fal fa-edit ${this.getEasyUpdateIconColorStyle(
                                        caseGuid
                                    )} `}
                                />
                            </button>
                        </td>
                    );
                }
                rows.push(<tr key={rowIndex}>{columns}</tr>);
            }
        } else {
            rows.push(<tr />);
        }

        return rows;
    }

    getFormattedValue(value: string, formatType?: string) {
        if (value == null) return null;
        if (!formatType) return value;

        switch (formatType.toLowerCase()) {
            case AssertionDescriptorValueDisplayTypes.Currency.Name.toLowerCase():
                return '$' + value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            case AssertionDescriptorValueDisplayTypes.Percentage.Name.toLowerCase():
                return value + '%';
            default:
                return value;
        }
    }

    sortData = (
        key: string,
        order: string,
        subKey?: string,
        subGrandKey?: string,
        dataItem?: number
    ) => {
        switch (dataItem) {
            case QueryTypes.CaseDetails.Value:
                this.sortCaseData(parseInt(key), order, subKey);
                break;
            case QueryTypes.Deposition.Value:
                this.sortDepositionData(key, order, subKey, subGrandKey);
                break;
        }
    };

    sortCaseData = (index: number, order: string, datatype?: string) => {
        if (this.state.caseResults) {
            const results = this.state.caseResults;
            if (results.values) {
                const values = results.values.sort(
                    Sort.compareValuesByIndex(index, datatype, order)
                );
                results.values = values;
                this.setState({ caseResults: results });
            }
        }
    };

    sortCaseExperts = (index: number, order: string, datatype?: string) => {
        if (this.state.caseExpertsResults) {
            const results = [...this.state.caseExpertsResults];
            results.sort(Sort.compareValuesByIndex(index, datatype, order));
            this.setState({ caseExpertsResults: results });
        }
    };

    sortNotes = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let notes = this.state.noteResults;
        if (notes) {
            if (key == 'date') {
                notes = notes.sort(Sort.compareDate(key, subKey, order));
            } else {
                notes = notes.sort(Sort.compareValues(key, subKey, order, subGrandKey));
            }
        }

        this.setState({ noteResults: notes });
    };

    sortDepositionData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let depositions = this.state.depositionResults;
        if (depositions) {
            if (key == 'date') {
                depositions = depositions.sort(Sort.compareDate(key, subKey, order));
            } else {
                depositions = depositions.sort(Sort.compareValues(key, subKey, order, subGrandKey));
            }
        }

        this.setState({ depositionResults: depositions });
    };

    handleEasyUpdate = (caseGuid: string, noteGuid?: string) => {
        const easyUpdateAttemptedCaseList = [...this.state.easyUpdateAttemptedCaseList];
        const easyUpdateAttemptedNoteGuidList = [...this.state.easyUpdateAttemptedNoteGuidList];

        easyUpdateAttemptedCaseList.push(caseGuid);

        if (noteGuid) {
            easyUpdateAttemptedNoteGuidList.push(noteGuid);
        }

        this.setState({
            easyUpdateMode: true,
            easyUpdateCaseGuid: caseGuid,
            easyUpdateAttemptedCaseList,
            easyUpdateAttemptedNoteGuidList,
        });
    };

    easyUpdateAttempted = (caseGuid: string) => {
        return this.state.easyUpdateAttemptedCaseList.some((x) => x === caseGuid);
    };

    easyUpdateAttemptedOnNote = (noteGuid: string) => {
        return this.state.easyUpdateAttemptedNoteGuidList.some((x) => x === noteGuid);
    };

    getEasyUpdateIconColorStyle = (caseGuid: string, noteGuid?: string) => {
        let cssClass = '';

        if (
            this.state.queryType === QueryTypes.CaseDetails.Value ||
            this.state.queryType === QueryTypes.Deposition.Value
        ) {
            cssClass = this.easyUpdateAttempted(caseGuid) ? 'color-gray' : 'color-black';
        } else if (this.state.queryType === QueryTypes.CaseNotes.Value) {
            if (noteGuid) {
                cssClass = this.easyUpdateAttemptedOnNote(noteGuid) ? 'color-gray' : 'color-black';
            } else {
                cssClass = this.easyUpdateAttempted(caseGuid) ? 'color-gray' : 'color-black';
            }
        }

        return cssClass;
    };

    easyUpdateDone = async (updatedCase: ICaseModel) => {
        const noteResults = this.state.noteResults;
        if (noteResults) {
            const matches = noteResults.filter((x) => x.caseGuid === updatedCase.guid);
            matches.map((item: INoteModel) => {
                item.caseName = updatedCase.caseName;
            });
        }

        this.setState({
            easyUpdateMode: false,
            easyUpdateCaseGuid: undefined,
            noteResults: noteResults,
        });
        if (this.state.queryType === QueryTypes.CaseDetails.Value) await this.runQuery();
    };

    easyUpdateCancel = () => {
        this.setState({ easyUpdateMode: false, easyUpdateCaseGuid: undefined });
    };

    showConfirmRemoveDailog = (note: INoteModel) => {
        this.setState({
            showConfirmRemoveCaseNoteDialog: true,
            currentNote: JSON.parse(JSON.stringify(note)),
        });
    };

    addNewNote = (caseGuid: string) => {
        const newNote: INoteModel = {
            status: { id: NoteStatusTypes.Open },
            type: { id: NoteTypes.CaseNote },
            caseGuid: caseGuid,
            purpose: { id: NotePurposeTypesEnum.Info },
        };

        if (this.state.noteResults) {
            const caseMatch = this.state.noteResults.filter((x) => x.caseGuid === caseGuid);
            if (caseMatch.length > 0) {
                newNote.caseName = caseMatch[0].caseName;
                newNote.qScore = caseMatch[0].qScore;
                newNote.qProjection = caseMatch[0].qProjection;
            }
        }

        const easyUpdateAttemptedCaseList = this.state.easyUpdateAttemptedCaseList;
        easyUpdateAttemptedCaseList.push(caseGuid);
        this.setState({
            openNoteEditor: true,
            currentNote: newNote,
            easyUpdateAttemptedCaseList: easyUpdateAttemptedCaseList,
        });
    };

    viewEditNote = async (currentNote: INoteModel) => {
        const selectedNoteCategories: IMultiSelectOptions[] = [];
        if (currentNote.categories != undefined) {
            for (let i = 0; i < currentNote.categories.length; i++) {
                selectedNoteCategories.push({
                    label: currentNote.categories[i].name!,
                    value: currentNote.categories[i].name!,
                    id: currentNote.categories[i].id,
                });
            }
        }

        let strategyTypeId = 0;
        if (
            this.state.queryType === QueryTypes.StrategyNote.Value &&
            currentNote.associations &&
            currentNote.associations.length > 0
        ) {
            switch (currentNote.associations[0].entityType.name) {
                case 'Local Counsel':
                    strategyTypeId = StrategyTypes.LocalCounsel.Value;
                    break;
                case 'Jurisdiction':
                    strategyTypeId = StrategyTypes.Jurisdiction.Value;
                    break;
                case 'Plaintiffs Firm':
                    strategyTypeId = StrategyTypes.PlaintiffsFirm.Value;
                    break;
                case 'Projects':
                    strategyTypeId = StrategyTypes.Projects.Value;
                    break;
                default:
            }
        }

        this.setState({
            openNoteEditor: true,
            strategyTypeId: strategyTypeId || undefined,
            currentNote: JSON.parse(JSON.stringify(currentNote)),
            selectedNoteCategoriesOnEdit: selectedNoteCategories,
        });
    };

    getModifiedByName = (user?: IUserModel) => {
        if (user && user.profile && user.profile.firstName && user.profile.lastName) {
            return user.profile.firstName.concat(' ', user.profile.lastName);
        }
        return '';
    };

    handleCancelDeleteNote = () => {
        this.setState({
            showConfirmRemoveCaseNoteDialog: false,
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
        });
    };

    onSaveNoteComplete = (noteItem: INoteModel) => {
        let caseNotesResults = this.state.noteResults;

        if (caseNotesResults) {
            let existingNote = false;
            for (let i = 0; i < caseNotesResults.length; i++) {
                if (caseNotesResults[i].guid == noteItem.guid) {
                    caseNotesResults[i] = noteItem;
                    existingNote = true;
                    break;
                }
            }

            if (!existingNote) {
                if (
                    caseNotesResults.filter(
                        (x) => x.caseGuid == noteItem.caseGuid && x.guid && x.guid != EmptyGuid
                    ).length > 0
                ) {
                    caseNotesResults.push(noteItem);
                    caseNotesResults = caseNotesResults.sort(
                        Sort.compareValues('caseName', undefined, 'asc')
                    );
                } else {
                    for (let i = 0; i < caseNotesResults.length; i++) {
                        if (caseNotesResults[i].caseGuid == this.state.currentNote.caseGuid!) {
                            caseNotesResults[i] = noteItem;
                            break;
                        }
                    }
                }
            }
        }
        if (caseNotesResults)
            caseNotesResults = caseNotesResults.sort(
                Sort.compareDate('modifiedDate', undefined, 'desc')
            );
        this.setState({
            pendingResponse: false,
            openNoteEditor: false,
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            noteResults: caseNotesResults,
            selectedNoteCategoriesOnEdit: [],
        });
    };

    deleteNote = async () => {
        if (
            !this.state.currentNote ||
            !this.state.currentNote.guid ||
            !this.state.currentNote.caseGuid
        ) {
            const validation: IValidation = {};
            validation.model = [DisplayMessages.UnexpectedError];
            this.setState({ validation: validation });
            return;
        }

        const noteGuidToDelete = this.state.currentNote.guid!;

        const response = await _apiClient.deleteNote(
            noteGuidToDelete,
            this.state.currentNote.caseGuid
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                showConfirmRemoveCaseNoteDialog: false,
            });
            return;
        }

        const caseNotesResults = this.state.noteResults!;

        // Determine if we should delete the note row or keep it.
        // 1) Delete row for Zone and Strategy Note Search
        // 2) For Case Notes, if there is a Case Note Criteria, delete the row. If not, check if there is another row with the same Case Guid. If another row exists for the same Case, delete this row.

        const caseNotesResultMatch = caseNotesResults.find((x) => x.guid === noteGuidToDelete);

        if (caseNotesResultMatch) {
            let deleteNoteRow = true;
            if (
                this.state.queryType === QueryTypes.CaseNotes.Value &&
                this.state.queryFilterCriteriaSelected.filter(
                    (x) => x.staticItemId === QueryStaticItemFields.NotesSection.Id
                ).length === 0 &&
                caseNotesResults.filter((x) => x.caseGuid === caseNotesResultMatch!.caseGuid)
                    .length === 1
            )
                deleteNoteRow = false;

            for (let i = 0; i < caseNotesResults.length; i++) {
                if (caseNotesResults[i].guid == noteGuidToDelete) {
                    if (deleteNoteRow) {
                        caseNotesResults.splice(i, 1);
                    } else {
                        caseNotesResults[i] = {
                            caseGuid: caseNotesResults[i].caseGuid,
                            caseName: caseNotesResults[i].caseName,
                            status: { id: NoteStatusTypes.Open },
                            purpose: { id: NotePurposeTypesEnum.Info },
                        };
                    }
                    break;
                }
            }
        }

        this.setState({
            noteResults: caseNotesResults,
            showConfirmRemoveCaseNoteDialog: false,
            selectedNoteCategoriesOnEdit: [],
        });
    };

    onCancelAddEditNote = () => {
        const validation = this.state.validation;
        validation.categories = [];
        validation.content = [];
        this.setState({
            openNoteEditor: false,
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            validation: validation,
            selectedNoteCategoriesOnEdit: [],
        });
    };

    handleChangeValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setChangedValue(name, value);
    };

    setChangedValue(name: string, value: any) {
        const currentNoteEditedOrDeleted = this.state.currentNote;
        if (!currentNoteEditedOrDeleted) return;

        const validation = this.state.validation;

        switch (name) {
            case 'noteContent':
                currentNoteEditedOrDeleted.content = value;
                validation.content = [''];
                break;
            default:
                break;
        }
        this.setState({
            currentNote: currentNoteEditedOrDeleted,
            validation: validation,
        });
    }

    handleNoteCategoriesSelected = (optionsList: any) => {
        const currentNoteEditedOrDeleted = NoteHelper.updateCurrentNoteOnCategoryChange(
            this.state.currentNote!,
            optionsList,
            this.state.noteCategoryTypes
        );
        this.setState({
            currentNote: currentNoteEditedOrDeleted,
            selectedNoteCategoriesOnEdit: optionsList,
        });
    };

    deleteField(e: React.MouseEvent<HTMLButtonElement>, item: IQueryFilterCriteria) {
        const queryFilterCriteriaSelected = this.state.queryFilterCriteriaSelected;
        let depositionSearchCriteria = this.state.depositionSearchCriteria;
        const noteSearchCriteria = this.state.noteSearchCriteria;
        if (queryFilterCriteriaSelected) {
            if (item.isAssertionDescriptor) {
                for (let i = 0; i < queryFilterCriteriaSelected.length; i++) {
                    if (
                        queryFilterCriteriaSelected[i].isAssertionDescriptor &&
                        queryFilterCriteriaSelected[i].assertionDescriptorGuid ==
                            item.assertionDescriptorGuid
                    ) {
                        queryFilterCriteriaSelected[i].selectedValues = undefined;
                        queryFilterCriteriaSelected[i].text = '';
                        queryFilterCriteriaSelected[i].booleanValue = false;
                        queryFilterCriteriaSelected[i].startDate = undefined;
                        queryFilterCriteriaSelected[i].endDate = undefined;
                        queryFilterCriteriaSelected[i].numericValueFromText = '';
                        queryFilterCriteriaSelected[i].numericValueFrom = undefined;
                        queryFilterCriteriaSelected[i].numericValueToText = '';
                        queryFilterCriteriaSelected[i].numericValueTo = undefined;
                        queryFilterCriteriaSelected[i].condition = undefined;
                        queryFilterCriteriaSelected[i].validationError = undefined;
                        queryFilterCriteriaSelected.splice(i, 1);
                        break;
                    }
                }
            } else {
                for (let i = 0; i < queryFilterCriteriaSelected.length; i++) {
                    if (queryFilterCriteriaSelected[i].label === item.label) {
                        queryFilterCriteriaSelected[i].selectedValues = undefined;
                        queryFilterCriteriaSelected[i].text = '';
                        queryFilterCriteriaSelected[i].booleanValue = false;
                        queryFilterCriteriaSelected[i].startDate = undefined;
                        queryFilterCriteriaSelected[i].endDate = undefined;
                        queryFilterCriteriaSelected[i].numericValueFromText = '';
                        queryFilterCriteriaSelected[i].numericValueFrom = undefined;
                        queryFilterCriteriaSelected[i].numericValueToText = '';
                        queryFilterCriteriaSelected[i].numericValueTo = undefined;
                        queryFilterCriteriaSelected[i].condition = undefined;
                        queryFilterCriteriaSelected[i].validationError = undefined;
                        queryFilterCriteriaSelected.splice(i, 1);
                        break;
                    }
                }

                if (item.staticItemId === QueryStaticItemFields.DepositionSection.Id)
                    depositionSearchCriteria = undefined;
            }

            if (item.value == 'CaseNotes' || item.value == 'NotesSection') {
                this.removeNoteSearchFromSearchCriteria(noteSearchCriteria);
            }
            if (item.value == 'DepositionSection' && depositionSearchCriteria !== undefined) {
                depositionSearchCriteria.categories = undefined;
                depositionSearchCriteria.defenseCounsel = undefined;
                depositionSearchCriteria.startDate = undefined;
                depositionSearchCriteria.endDate = undefined;
                depositionSearchCriteria.statuses = undefined;
                depositionSearchCriteria.defenseCounselUsers = undefined;
            }
            this.setState({
                queryFilterCriteriaSelected: queryFilterCriteriaSelected,
                depositionSearchCriteria: depositionSearchCriteria,
                noteSearchCriteria: noteSearchCriteria,
            });
        }
    }

    removeNoteSearchFromSearchCriteria = (noteSearchCriteria: INoteUISearchModel) => {
        noteSearchCriteria.acknowledgementStatus = undefined;
        noteSearchCriteria.alertListSearchStatusOptions = undefined;
        noteSearchCriteria.alertListType = undefined;
        noteSearchCriteria.alertTypeIds = undefined;
        noteSearchCriteria.alertUserGuids = undefined;
        noteSearchCriteria.allowLocalEdit = undefined;
        noteSearchCriteria.approvalStatus = undefined;
        noteSearchCriteria.blankApproval = undefined;
        noteSearchCriteria.endDate = undefined;
        noteSearchCriteria.hasAttachment = undefined;
        noteSearchCriteria.modifiedByLocal = undefined;
        noteSearchCriteria.noteCategories = undefined;
        noteSearchCriteria.noteStatusIds = undefined;
        noteSearchCriteria.noteSubCategories = undefined;
        noteSearchCriteria.noteSubTypes = undefined;
        noteSearchCriteria.private = undefined;
        noteSearchCriteria.purpose = undefined;
        noteSearchCriteria.readOnly = undefined;
        noteSearchCriteria.startDate = undefined;
    };

    getNoteCategoryTypeOptionsForNoteEditor() {
        const noteCategoryTypeOptionsBase = this.getNoteCategoryTypeOptions();
        const noteCategoryTypeOptions: IMultiSelectOptions[] = [];
        for (let i = 0; i < noteCategoryTypeOptionsBase.length; i++) {
            noteCategoryTypeOptions.push(
                JSON.parse(JSON.stringify(noteCategoryTypeOptionsBase[i]))
            );
        }
        return noteCategoryTypeOptions;
    }

    getPlaintiffFirmsList = () => {
        const assertionDescriptorMatch = this.state.assertionDescriptors.find(
            (x) =>
                x.name!.toUpperCase() == AssertionDescriptors.PlainfirmFirmLocal.Name.toUpperCase()
        );
        const assertionDescriptorValuesAll: IQueryFilterCriteriaSelectionValue[] = [];
        if (assertionDescriptorMatch && assertionDescriptorMatch.assertionDescriptorValues) {
            assertionDescriptorMatch.assertionDescriptorValues.map(
                (advMatch: IAssertionDescriptorValueModel) => {
                    assertionDescriptorValuesAll.push({
                        label: advMatch.text!,
                        value: advMatch.guid!,
                        guid: advMatch.guid!,
                    });
                }
            );
        }

        return assertionDescriptorValuesAll;
    };

    selectLabel = (e: any, label: string) => {
        if (!e.value || e.value.length === 0) {
            return <span className="unselectClass css-1v99tuv">--{label}--</span>;
        }

        if (e.value.length === 1) {
            return <span className="css-1v99tuv">{e.value[0].label}</span>;
        } else {
            return <span className="css-1v99tuv">{e.value.length} selected</span>;
        }
    };

    getCriteriaHelpText = (item: IQueryFilterCriteria) => {
        let helpText: string | null = null;
        if (item.isAssertionDescriptor) {
            helpText = CaseHelper.getAssertionDescriptorFieldHelpText(
                this.state.assertionDescriptors,
                item.value,
                item.assertionDescriptorGuid,
                true
            );
        } else if (item.staticItemId) {
            helpText = CaseHelper.getStaticItemFieldHelpText(item.staticItemId);
        }

        if (helpText)
            return <Help type={ApplicationHelpType.Info} title={item.label} helpText={helpText} />;
        else return null;
    };

    getFilterCriteriaUIByQueryType = () => {
        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
            case QueryTypes.CaseExperts.Value:
            case QueryTypes.CaseNotes.Value:
            case QueryTypes.Deposition.Value:
                return (
                    <div className="row mb-2">
                        <div className="col-sm-2">
                            <label className="text-gray text-lg">Criteria*</label>&nbsp;
                            <Help
                                type={ApplicationHelpType.Info}
                                title="Criteria"
                                helpText="Select fields and values to identify the subset of cases that fit that criteria; the criteria tells Checkmate which cases you want to see. You can select a maximum of 3 values for dropdown Criteria except for Case Status."
                            />
                        </div>
                        <div className="col-sm-10">
                            <CheckmateSelect
                                isMulti={true}
                                options={[...this.state.queryFilterCriteriaAll]}
                                value={this.state.queryFilterCriteriaSelected}
                                onChange={this.queryCriteriaSelected}
                            />
                            <span className="text-danger">{this.state.validation.criteria}</span>
                        </div>
                    </div>
                );
            default:
        }
    };

    getStrategySubTypes = () => {
        const strategySubTypes: IMultiSelectOptions[] = [];

        if (
            Authorization.isAuthorizedToStrategyType(
                StrategyTypes.Jurisdiction.Name,
                this.props.user
            )
        )
            strategySubTypes.push({
                label: StrategyTypes.Jurisdiction.DisplayName,
                value: StrategyTypes.Jurisdiction.Name,
                id: StrategyTypes.Jurisdiction.Value,
            });
        if (
            Authorization.isAuthorizedToStrategyType(
                StrategyTypes.PlaintiffsFirm.Name,
                this.props.user
            )
        )
            strategySubTypes.push({
                label: StrategyTypes.PlaintiffsFirm.DisplayName,
                value: StrategyTypes.PlaintiffsFirm.Name,
                id: StrategyTypes.PlaintiffsFirm.Value,
            });
        if (
            Authorization.isAuthorizedToStrategyType(
                StrategyTypes.LocalCounsel.Name,
                this.props.user
            )
        )
            strategySubTypes.push({
                label: StrategyTypes.LocalCounsel.DisplayName,
                value: StrategyTypes.LocalCounsel.Name,
                id: StrategyTypes.LocalCounsel.Value,
            });
        if (Authorization.isAuthorizedToStrategyType(StrategyTypes.Projects.Name, this.props.user))
            strategySubTypes.push({
                label: StrategyTypes.Projects.DisplayName,
                value: StrategyTypes.Projects.Name,
                id: StrategyTypes.Projects.Value,
            });

        return strategySubTypes;
    };

    getSelectedFilterCriteriaUIByQueryType = () => {
        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
            case QueryTypes.CaseExperts.Value:
            case QueryTypes.CaseNotes.Value:
            case QueryTypes.Deposition.Value:
                return this.state.queryFilterCriteriaSelected &&
                    this.state.queryFilterCriteriaSelected.length > 0 ? (
                    <div className="row my-2">
                        <div className="col-sm-2" />
                        <div className="col-sm-10">
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th className="col-sm-3">Criteria</th>
                                        <th className="col-sm-2">Condition</th>
                                        <th className="col-sm-7">Values</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.queryFilterCriteriaSelected.map(
                                        (item: IQueryFilterCriteria, i: number) => {
                                            return this.returnQueryFilterCriteriaSelectedRow(
                                                item,
                                                i
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : null;
            case QueryTypes.ZoneNotes.Value:
            case QueryTypes.StrategyNote.Value:
                return (
                    <div className="row">
                        <div className="col-sm-2" />
                        <div className="col-sm-10 padding-left-5">
                            <table className="table margin-top-sm">
                                <thead>
                                    <tr>
                                        <th className="col-sm-3">Criteria</th>
                                        <th className="col-sm-8">Values</th>
                                    </tr>
                                </thead>
                                <tbody>{this.getNoteCriteriaRows()}</tbody>
                            </table>
                        </div>
                    </div>
                );
            default:
        }
    };

    getNoteCriteriaRows = () => {
        const rows: any = [];
        let key = 1;
        if (this.state.queryType === QueryTypes.StrategyNote.Value) {
            rows.push(
                <tr key={key++}>
                    <td className="col-sm-4">Type</td>
                    <td>
                        <CheckmateSelect
                            isMulti={true}
                            options={this.getStrategySubTypes()}
                            value={this.state.noteSearchCriteria.noteSubTypes || []}
                            isDisabled={this.state.noteSearchCriteria.readOnly}
                            onChange={(e: any) => {
                                this.filterValuesSelectedAlt(e, 'noteSubTypes');
                            }}
                        />
                    </td>
                </tr>
            );
        }

        rows.push(
            <tr key={key++}>
                <td className="col-sm-4">Categories</td>
                <td>
                    <CheckmateSelect
                        isMulti={true}
                        options={this.getNoteCategoryTypeOptions()}
                        value={this.state.noteSearchCriteria.noteCategories || []}
                        isDisabled={this.state.noteSearchCriteria.readOnly}
                        onChange={(e: any) => {
                            this.filterValuesSelectedAlt(e, 'noteCategories');
                        }}
                    />
                </td>
            </tr>
        );

        const noteSubCategoryTypes = this.getNoteSubCategoryTypeOptions();
        if (noteSubCategoryTypes.length > 0) {
            rows.push(
                <tr key={key++}>
                    <td className="col-sm-4">Sub Categories</td>
                    <td>
                        <CheckmateSelect
                            isMulti={true}
                            options={noteSubCategoryTypes}
                            value={this.state.noteSearchCriteria.noteSubCategories || []}
                            isDisabled={this.state.noteSearchCriteria.readOnly}
                            onChange={(e: any) => {
                                this.filterValuesSelectedAlt(e, 'noteSubCategories');
                            }}
                        />
                    </td>
                </tr>
            );
        }

        rows.push(
            <tr key={key++}>
                <td className="col-sm-4">Date Range</td>
                <td>
                    <input
                        className={
                            'form-control my-1 ms-1 me-2 d-inline-block' +
                            (this.state.noteSearchCriteria.startDate &&
                            Common.isValidDate(this.state.noteSearchCriteria.startDate)
                                ? ''
                                : ' unselectClass')
                        }
                        min="1753-01-01"
                        disabled={this.state.noteSearchCriteria.readOnly}
                        value={
                            this.state.noteSearchCriteria.startDate
                                ? Common.dateFormat(this.state.noteSearchCriteria.startDate)
                                : ''
                        }
                        type="date"
                        name="noteStartDate"
                        onChange={this.handleChangeAlt}
                    />
                    to
                    <input
                        className={
                            'form-control ms-2 d-inline-block' +
                            (this.state.noteSearchCriteria.endDate &&
                            Common.isValidDate(this.state.noteSearchCriteria.endDate)
                                ? ''
                                : ' unselectClass')
                        }
                        min="1753-01-01"
                        disabled={this.state.noteSearchCriteria.readOnly}
                        value={
                            this.state.noteSearchCriteria.endDate
                                ? Common.dateFormat(this.state.noteSearchCriteria.endDate)
                                : ''
                        }
                        type="date"
                        name="noteEndDate"
                        onChange={this.handleChangeAlt}
                    />
                </td>
            </tr>
        );

        rows.push(
            <tr key={key++}>
                <td className="col-sm-4">Purpose</td>
                <td>
                    <div className="col-sm-5">
                        <CheckmateSelect
                            options={CheckmateSelectHelper.getLookupOptions(
                                this.state.notePurposeTypes
                            )}
                            name="purpose"
                            onChange={(e: any) => {
                                this.handleCheckmateSingleSelectChange(e, 'purpose');
                            }}
                            value={CheckmateSelectHelper.getSelectedValueById(
                                this.state.notePurposeTypes,
                                this.state.noteSearchCriteria.purpose &&
                                    this.state.noteSearchCriteria.purpose.length > 0
                                    ? this.state.noteSearchCriteria.purpose[0].id
                                    : 0
                            )}
                        />
                    </div>
                </td>
            </tr>
        );
        if (
            this.state.noteSearchCriteria.purpose &&
            this.state.noteSearchCriteria.purpose.length > 0 &&
            this.state.noteSearchCriteria.purpose[0].id === NotePurposeTypesEnum.Approval
        ) {
            rows.push(
                <tr key={key++}>
                    <td className="col-sm-4">Decision</td>
                    <td>
                        <div className="col-sm-5">
                            <CheckmateSelect
                                options={CheckmateSelectHelper.getLookupOptions(
                                    this.state.approvalStatusTypes,
                                    true
                                )}
                                name="approvalStatus"
                                onChange={(e: any) => {
                                    this.handleCheckmateSingleSelectChange(e, 'approvalStatus');
                                }}
                                value={CheckmateSelectHelper.getSelectedValueById(
                                    this.state.approvalStatusTypes,
                                    this.state.noteSearchCriteria.approvalStatus &&
                                        this.state.noteSearchCriteria.approvalStatus.length > 0
                                        ? this.state.noteSearchCriteria.approvalStatus[0].id
                                        : 0
                                )}
                            />
                        </div>
                    </td>
                </tr>
            );
        }
        if (
            this.state.noteSearchCriteria.purpose &&
            this.state.noteSearchCriteria.purpose.length > 0 &&
            this.state.noteSearchCriteria.purpose[0].id === 3
        ) {
            rows.push(
                <tr key={key++}>
                    <td className="col-sm-4">Decision Status</td>
                    <td>
                        <div className="col-sm-5">
                            <CheckmateSelect
                                options={CheckmateSelectHelper.getLookupOptions(
                                    this.state.acknowledgmentStatusTypes,
                                    true
                                )}
                                name="acknowledgementStatus"
                                onChange={(e: any) => {
                                    this.handleCheckmateSingleSelectChange(
                                        e,
                                        'acknowledgementStatus'
                                    );
                                }}
                                value={CheckmateSelectHelper.getSelectedValueById(
                                    this.state.acknowledgmentStatusTypes,
                                    this.state.noteSearchCriteria.acknowledgementStatus &&
                                        this.state.noteSearchCriteria.acknowledgementStatus.length >
                                            0
                                        ? this.state.noteSearchCriteria.acknowledgementStatus[0].id
                                        : 0
                                )}
                            />
                        </div>
                    </td>
                </tr>
            );
        }
        if (Authorization.userHasRight(UserRightsEnum.NoteSecurity, this.props.user))
            rows.push(
                <tr key={key++}>
                    <td className="col-sm-4">Private</td>
                    <td>
                        <div className="col-sm-4">
                            <CheckmateSelect
                                options={CheckmateSelectHelper.getBooleanOptions()}
                                value={CheckmateSelectHelper.getBooleanValue(
                                    this.state.noteSearchCriteria.private
                                )}
                                name="private"
                                onChange={(e: any) => {
                                    this.handleCheckmateSingleSelectChange(e, 'private');
                                }}
                            />
                        </div>
                    </td>
                </tr>
            );
        if (Authorization.userHasRight(UserRightsEnum.NoteSecurity, this.props.user))
            rows.push(
                <tr key={key++}>
                    <td className="col-sm-4">Allow Local Edit</td>
                    <td>
                        <div className="col-sm-4">
                            <CheckmateSelect
                                options={CheckmateSelectHelper.getBooleanOptions()}
                                value={CheckmateSelectHelper.getBooleanValue(
                                    this.state.noteSearchCriteria.allowLocalEdit
                                )}
                                name="allowLocalEdit"
                                onChange={(e: any) => {
                                    this.handleCheckmateSingleSelectChange(e, 'allowLocalEdit');
                                }}
                            />
                        </div>
                    </td>
                </tr>
            );
        rows.push(
            <tr key={key++}>
                <td className="col-sm-4">Created/Edited by Local</td>
                <td>
                    <div className="col-sm-4">
                        <CheckmateSelect
                            options={CheckmateSelectHelper.getBooleanOptions()}
                            value={CheckmateSelectHelper.getBooleanValue(
                                this.state.noteSearchCriteria.modifiedByLocal
                            )}
                            name="modifiedByLocal"
                            onChange={(e: any) => {
                                this.handleCheckmateSingleSelectChange(e, 'modifiedByLocal');
                            }}
                        />
                    </div>
                </td>
            </tr>
        );
        rows.push(
            <tr key={key++}>
                <td className="col-sm-4">Has Attachment</td>
                <td>
                    <div className="col-sm-4">
                        <CheckmateSelect
                            options={CheckmateSelectHelper.getBooleanOptions()}
                            value={CheckmateSelectHelper.getBooleanValue(
                                this.state.noteSearchCriteria.hasAttachment
                            )}
                            name="hasAttachment"
                            onChange={(e: any) => {
                                this.handleCheckmateSingleSelectChange(e, 'hasAttachment');
                            }}
                        />
                    </div>
                </td>
            </tr>
        );

        rows.push(
            <tr key={key++}>
                <td className="col-sm-4">Related to</td>
                <td>
                    <div className="col-sm-5">
                        <CheckmateSelect
                            options={
                                [
                                    {
                                        label: 'Case Experts',
                                        value: 'CASE_EXPERTS',
                                    },
                                ] as IMultiSelectOptions[]
                            }
                            value={
                                this.state.noteSearchCriteria.isAssociatedCaseExpertCaseNote
                                    ? ({
                                          label: 'Case Experts',
                                          value: 'CASE_EXPERTS',
                                      } as IMultiSelectOptions)
                                    : undefined
                            }
                            name="relatedTo"
                            onChange={(selectedOption: IMultiSelectOptions) => {
                                this.setState({
                                    noteSearchCriteria: {
                                        ...cloneDeep(this.state.noteSearchCriteria),
                                        isAssociatedCaseExpertCaseNote: !selectedOption
                                            ? undefined
                                            : selectedOption.value === 'CASE_EXPERTS',
                                    },
                                });
                            }}
                        />
                    </div>
                </td>
            </tr>
        );

        return rows;
    };

    returnQueryFilterCriteriaSelectedRow = (item: IQueryFilterCriteria, i: number) => {
        //set the default condition.
        if (item.condition === undefined) {
            item.condition = ConditionSelectorEnums.EqualTo;
        }

        const handleConditionOptionChange = (selectedItem: any) => {
            const queryFilterCriteriaSelected = [...this.state.queryFilterCriteriaSelected];

            const filterIdx =
                queryFilterCriteriaSelected.findIndex((f) => f.value === item.value) ?? -1;

            const filtersCopy = [...queryFilterCriteriaSelected];
            const filterItemToModify = filtersCopy[filterIdx];

            const blankIsSelected = selectedItem?.value === ConditionSelectorEnums.Blank;
            const notBlankIsSelected = selectedItem?.value === ConditionSelectorEnums.NotBlank;
            const conditionValue = blankIsSelected
                ? true
                : notBlankIsSelected
                ? false
                : selectedItem?.value;

            const depositionSelectedBlankOrNotBlank =
                filterItemToModify.staticItemId === QueryStaticItemFields.DepositionSection.Id &&
                (blankIsSelected || notBlankIsSelected);
            const notesSelectedBlankOrNotBlank =
                filterItemToModify.staticItemId === QueryStaticItemFields.NotesSection.Id &&
                (blankIsSelected || notBlankIsSelected);
            const expertsSelectedBlankOrNotBlank =
                filterItemToModify.staticItemId === QueryStaticItemFields.ExpertsSection.Id &&
                (blankIsSelected || notBlankIsSelected);

            if (blankIsSelected || notBlankIsSelected) {
                filterItemToModify.selectedValues = [];
                filterItemToModify.text = '';
                filterItemToModify.startDate = undefined;
                filterItemToModify.endDate = undefined;
                filterItemToModify.booleanValue = undefined;
                filterItemToModify.numericValueFrom = undefined;
                filterItemToModify.numericValueFromText = '';
                filterItemToModify.numericValueTo = undefined;
                filterItemToModify.numericValueToText = '';
            }
            filterItemToModify.condition = conditionValue;

            this.setState({
                queryFilterCriteriaSelected: filtersCopy,
                depositionSearchCriteria: depositionSelectedBlankOrNotBlank
                    ? {}
                    : this.state.depositionSearchCriteria,
                noteSearchCriteria: notesSelectedBlankOrNotBlank
                    ? {}
                    : this.state.noteSearchCriteria,
                caseExpertsSearchCriteria: expertsSelectedBlankOrNotBlank
                    ? {}
                    : this.state.caseExpertsSearchCriteria,
            });
        };

        const GetSelectedAssertionDescriptor = () => {
            const assertionDescriptorMatch = this.state.assertionDescriptors.find((x) => {
                return (
                    item.assertionDescriptorGuid !== undefined &&
                    x.guid !== undefined &&
                    x.guid.toUpperCase() === item.assertionDescriptorGuid.toUpperCase()
                );
            });
            return assertionDescriptorMatch;
        };

        const IsBooleanFieldSelected = () => {
            if (item.staticItemId === QueryStaticItemFields.CasePriority.Id) return true;

            const assertionDescriptorMatch = GetSelectedAssertionDescriptor();

            if (assertionDescriptorMatch) {
                return (
                    assertionDescriptorMatch.valueSourceType!.id ===
                    AssertionDescriptorValueSourceTypeEnum.Boolean.Value
                );
            }
            return false;
        };

        const showConditionalSelectorDropdown = !(
            item.staticItemId === QueryStaticItemFields.CaseStatusAsOfDate.Id
        );

        let optionsToRender = conditionOpts; // Default options

        // Check the item property value and set options accordingly
        if (IsBooleanFieldSelected()) {
            // Filter out the unwanted option based on a condition
            optionsToRender = optionsToRender.filter(
                (option) => option.value !== ConditionSelectorEnums.NotEqualTo
            );
        } else if (!showConditionalSelectorDropdown) {
            optionsToRender = optionsToRender.filter(
                (option) => option.value === ConditionSelectorEnums.EqualTo
            );
        }

        return (
            <tr key={i}>
                <td className="pt-1">
                    {item!.label}
                    {this.getCriteriaHelpText(item)}
                </td>
                <td className="px-12 pt-1">
                    <ConditionSelector
                        options={optionsToRender}
                        value={item.condition}
                        onChange={handleConditionOptionChange}
                        disabled={item.readOnly}
                    />
                </td>
                <td className="pt-1">{this.buildQueryFilterCriteriaValuesColumn(item)}</td>
                <td width="5%" className="pt-1">
                    {!item.readOnly ? (
                        <button
                            className="btn btn-gray btn-icon float-end"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                this.deleteField(e, item);
                            }}
                        >
                            <i className="fal fa-times color-white" />
                        </button>
                    ) : null}
                </td>
            </tr>
        );
    };

    getTotalRecordsByQueryType = () => {
        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
            case QueryTypes.CaseExperts.Value: {
                return this.state.caseResultsCount;
            }
            case QueryTypes.CaseNotes.Value:
            case QueryTypes.ZoneNotes.Value:
            case QueryTypes.StrategyNote.Value:
                return this.state.noteResults ? this.state.noteResults.length : 0;
            case QueryTypes.Deposition.Value:
                return this.state.depositionResults ? this.state.depositionResults.length : 0;
            default:
                return 0;
        }
    };

    getDisplayUIByQueryType = () => {
        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
                return (
                    <div className="row mb-2">
                        <div className="col-sm-2">
                            <label className="text-gray text-lg">Display</label>&nbsp;
                            <Help
                                type={ApplicationHelpType.Info}
                                title="Display"
                                helpText="Select fields that you want to see displayed for your subset of cases; the display fields tell Checkmate what you want to see about the cases"
                            />
                        </div>
                        <div className="col-sm-10">
                            <CheckmateSelect
                                isMulti
                                options={this.exportFilterForDisplayColumns()}
                                value={this.state.displayColumnsSelected}
                                onChange={this.queryColumnsSelected}
                            />
                        </div>
                        <span className="text-danger">{this.state.validation.columns}</span>
                    </div>
                );
            default:
                return null;
        }
    };

    getStrategyURL(strategyTypeId: number): string {
        switch (strategyTypeId) {
            case StrategyTypes.Jurisdiction.Value:
                return LocalRoutes.JurisdictionStrategyDetail;
            case StrategyTypes.PlaintiffsFirm.Value:
                return LocalRoutes.PlaintiffFirmStrategyDetail;
            case StrategyTypes.LocalCounsel.Value:
                return LocalRoutes.LocalCounselStrategyDetail;
            case StrategyTypes.Projects.Value:
                return LocalRoutes.ProjectDetail;
            default:
                return '';
        }
    }

    handleCaseExpertsListRefresh = (caseExperts: ICaseExpertModel[]) => {
        this.setState({ caseExpertsResults: caseExperts });
    };

    getQueryResultsUIByQueryType = () => {
        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
                return (
                    <div>
                        {this.state.caseResults.columns && this.state.caseResults.values ? (
                            <table className="table">
                                <thead>
                                    <tr>
                                        {this.state.mode === QueryToolModeEnum.Redirect ? (
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    name="chkSelectAllCases"
                                                    className="form-check-input"
                                                    checked={
                                                        this.state.selectAllCasesForRedirect ||
                                                        false
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleCheckedChange(e);
                                                    }}
                                                />{' '}
                                            </th>
                                        ) : null}
                                        {this.state.caseResults.columns
                                            .filter(
                                                (x) =>
                                                    (x.index ?? 0) > 0 &&
                                                    x.staticItemId !==
                                                        InternalDatabaseFields_PackageSettlementGuid
                                            )
                                            .map((item: IQueryColumnDefinitionModel) => {
                                                return (
                                                    <th>
                                                        {item.displayName &&
                                                        item.displayName.toUpperCase() ===
                                                            'LOCKED' ? (
                                                            <i className="fal fa-lock color-gray" />
                                                        ) : (
                                                            item.displayName
                                                        )}
                                                        <span className="text-nowrap">
                                                            <SortData
                                                                fieldName={item.index!.toString()}
                                                                subKey={item.dataType}
                                                                dataObjectIdentifier={
                                                                    QueryTypes.CaseDetails.Value
                                                                }
                                                                sortData={this.sortData}
                                                            />
                                                        </span>
                                                    </th>
                                                );
                                            })}
                                        {this.state.enableEasyUpdate ? (
                                            <th className="text-center">
                                                <i className="fal fa-edit" />
                                            </th>
                                        ) : null}
                                    </tr>
                                </thead>
                                <tbody>{this.buildResultTableBody()}</tbody>
                            </table>
                        ) : null}
                    </div>
                );
            case QueryTypes.CaseExperts.Value: {
                // const authorizedToDelete = Authorization.userHasRight(
                //     UserRightsEnum.DeleteCaseExpert,
                //     this.props.user
                // );
                // const authorizedToEdit = Authorization.userHasRight(
                //     UserRightsEnum.UpdateCaseExpert,
                //     this.props.user
                // );
                // const authorizedToView = Authorization.userHasRight(
                //     UserRightsEnum.ViewCaseExpert,
                //     this.props.user
                // );
                return (
                    <div>
                        <CaseExpertsList
                            user={this.props.user}
                            displayTableWhenEmpty
                            caseExperts={this.state.caseExpertsResults ?? []}
                            enableEasyUpdateColumn={this.state.enableEasyUpdate}
                            onEasyUpdateClick={(caseGuid: string) => {
                                this.setState({
                                    easyUpdateMode: true,
                                    easyUpdateCaseGuid: caseGuid,
                                });
                            }}
                            onSetCurrentCaseExpert={(caseExpert) =>
                                this.setState({ currentCaseExpert: caseExpert ?? undefined })
                            }
                            refreshParent={this.handleCaseExpertsListRefresh}
                            showCaseName
                        />
                    </div>
                );
            }
            case QueryTypes.CaseNotes.Value:
            case QueryTypes.ZoneNotes.Value:
            case QueryTypes.StrategyNote.Value:
                return (
                    <NoteListTable
                        getEasyUpdateIconColorStyle={this.getEasyUpdateIconColorStyle}
                        notes={this.state.noteResults ?? []}
                        onEasyUpdateClick={this.handleEasyUpdate}
                        onShowConfirmRemoveDialog={this.showConfirmRemoveDailog}
                        onViewEditNote={this.viewEditNote}
                        onViewNotesCategoryAdditionalInfo={() => {
                            this.setState({
                                displayNoteCategoryAdditionalInfo: true,
                            });
                        }}
                        showEasyUpdateColumn={
                            this.state.queryType === QueryTypes.CaseNotes.Value &&
                            this.state.enableEasyUpdate
                        }
                        showDeleteButton={false}
                        showEditButton={
                            this.state.loggedInUserDataScopeId !== DataScopesEnum.LocalBasic.Value
                        }
                        showCaseColumn={
                            this.state.queryType !== QueryTypes.ZoneNotes.Value &&
                            this.state.queryType !== QueryTypes.StrategyNote.Value
                        }
                        showExpertsColumn={this.state.queryType === QueryTypes.CaseNotes.Value}
                        showTypeColumn={this.state.queryType === QueryTypes.StrategyNote.Value}
                    />
                );
            case QueryTypes.Deposition.Value: {
                return (
                    <CaseDepositionListWrapper
                        user={this.props.user}
                        usersInZone={this.state.allUsers ?? []}
                        allDefenseCounselsForZone={this.state.allDefenseCounsels ?? []}
                        depositions={this.state.depositionResults ?? []}
                        showCaseColumn
                        showEasyUpdateColumn={
                            this.state.queryType === QueryTypes.Deposition.Value &&
                            this.state.enableEasyUpdate
                        }
                        forceHideDelete
                    />
                );
            }
            default:
        }
    };

    getAllDefenseCounselMultiSelectValuesForZone = () => {
        const output: IMultiSelectOptions[] = JSON.parse(
            JSON.stringify(this.state.allDefenseCounselsForZone)
        );
        return output;
    };

    getExportUIByQueryType = () => {
        switch (this.state.queryType) {
            case QueryTypes.CaseDetails.Value:
            case QueryTypes.CaseExperts.Value:
            case QueryTypes.CaseNotes.Value:
            case QueryTypes.Deposition.Value:
                return (
                    <button
                        className="btn btn-no-bg float-end text-gray"
                        onClick={this.showExportModal}
                    >
                        <i className="fal fa-file-export" />
                        &nbsp;Export
                    </button>
                );
            case QueryTypes.ZoneNotes.Value:
            case QueryTypes.StrategyNote.Value:
                if (this.state.noteResults && this.state.noteResults.length > 0) {
                    return (
                        <ExportNotes
                            notes={this.state.noteResults || []}
                            searchModel={this.state.noteSearchCriteria}
                            // eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
                            handleError={() => {}}
                        />
                    );
                } else return null;
            default:
        }
    };

    getNoteCategoryTypesByQueryType = () => {
        let noteTypeId = 0;
        switch (this.state.queryType) {
            case QueryTypes.CaseNotes.Value:
                noteTypeId = NoteTypes.CaseNote;
                break;
            case QueryTypes.ZoneNotes.Value:
                noteTypeId = NoteTypes.ZoneNote;
                break;
            case QueryTypes.StrategyNote.Value:
                noteTypeId = NoteTypes.StrategyNote;
                break;
            default:
        }

        return this.state.noteCategoryTypes.filter((x) => x.noteType.id === noteTypeId);
    };

    enableCaseNoteUpdate = () => {
        if (!Authorization.userHasRight(UserRightsEnum.EditCaseNote, this.props.user)) return false;
        const dataScopeId = Authorization.getUserDefaultOrganizationDataScope(this.props.user);
        switch (dataScopeId) {
            case DataScopesEnum.LocalBasic.Value:
                return (
                    this.state.noteResults &&
                    this.state.noteResults.filter((x) => x.allowLocalEdit).length > 0
                );
            default:
                return true;
        }
    };

    isNoteReadOnly = () => {
        const noteType = this.state.currentNote.type ? this.state.currentNote.type.id : 0;
        if (noteType == NoteTypes.CaseNote) {
            return NoteHelper.isNoteReadOnly(
                this.state.currentNote,
                this.props.user,
                Authorization.userHasRight(UserRightsEnum.ViewCaseNotes, this.props.user),
                Authorization.userHasRight(UserRightsEnum.EditCaseNote, this.props.user),
                Authorization.userHasRight(UserRightsEnum.AddCaseNote, this.props.user)
            );
        }

        return true;
    };

    applyFavoriteSelected = (settingJson?: string) => {
        if (settingJson) {
            const settings: IQuerySettingsModel = JSON.parse(settingJson);
            const columns: IQueryFilterCriteria[] = [];

            if (settings.filters) {
                const adsToRemove: string[] = [];
                for (let i = 0; i < settings.filters.length; i++) {
                    const item = settings.filters[i];
                    // Get the Latest Display Name since the Favorite might have been added with an old value
                    if (item.assertionDescriptorGuid) {
                        const match = this.state.assertionDescriptors.find(
                            (x) =>
                                x.guid!.toUpperCase() ===
                                item.assertionDescriptorGuid!.toUpperCase()
                        );
                        if (match)
                            item.label =
                                match.alternateDisplayName || match.displayName || match.name!;
                        else adsToRemove.push(item.assertionDescriptorGuid);
                    } else {
                        const match = this.state.queryFilterCriteriaAll.find(
                            (x) => x.value === item.value
                        );
                        if (match) item.label = match.label;
                    }
                }
                for (let index = 0; index < adsToRemove.length; index++) {
                    const element = adsToRemove[index];
                    settings.filters = settings.filters.filter(
                        (x) => x.assertionDescriptorGuid != element
                    );
                }
            }

            if (settings.columns) {
                for (let i = 0; i < settings.columns.length; i++) {
                    const item = settings.columns[i];
                    // Get the Latest Display Name since the Favorite might have been added with an old value
                    if (item.assertionDescriptorGuid) {
                        const match = this.state.assertionDescriptors.find(
                            (x) =>
                                x.guid!.toUpperCase() ===
                                item.assertionDescriptorGuid!.toUpperCase()
                        );
                        if (match) item.displayName = match.displayName;
                    } else {
                        const match = this.state.displayColumnsAll.find(
                            (x) => x.value === item.name!
                        );
                        if (match) item.displayName = match.label;
                    }

                    columns.push({
                        staticItemId: item.staticItemId!,
                        label: item.displayName!,
                        value: item.name!,
                        assertionDescriptorGuid: item.assertionDescriptorGuid,
                        isAssertionDescriptor: item.assertionDescriptorGuid ? true : false,
                    });
                }
            }

            this.setState({
                queryType: settings.queryType.id,
                queryFilterCriteriaSelected: settings.filters,
                displayColumnsSelected: columns,
                displayColumnsSelectedForExport: columns,
                validation: {},
                caseResults: {},
            });
        }
    };

    getCurrentFavoriteSettingJson = () => {
        let settingsJson = '';
        if (this.state.queryFilterCriteriaSelected.length > 0) {
            const displayColumnsForFavorite: IQueryColumnDefinitionModel[] = [];
            if (this.state.displayColumnsSelected.length > 0) {
                this.state.displayColumnsSelected.map(
                    (item: IQueryFilterCriteria, index: number) => {
                        displayColumnsForFavorite.push({
                            index: index,
                            name: item.value,
                            displayName: item.label,
                            assertionDescriptorGuid: item.assertionDescriptorGuid,
                            staticItemId: item.staticItemId,
                        });
                    }
                );
            }

            const settings: IQuerySettingsModel = {
                queryType: { id: this.state.queryType },
                filters: this.state.queryFilterCriteriaSelected,
                columns: displayColumnsForFavorite,
            };

            settingsJson = JSON.stringify(settings);
        }

        return settingsJson;
    };

    handleDefaultFavoriteChange = (settingJson?: string) => {
        this.setState({ userDefaultSettingsJson: settingJson });
    };

    handleFavoriteSelected = (settingJson?: string) => {
        let selectedFilters = '';
        if (settingJson) {
            const settings: IQuerySettingsModel = JSON.parse(settingJson);
            let displayName = '';
            let name = '';

            selectedFilters +=
                'QUERY TYPE: ' +
                this.fetchQueryTypes().find((x) => x.id === settings.queryType.id)!.displayName +
                '\n\n';

            if (settings.filters && settings.filters.length > 0) {
                selectedFilters += 'CRITERIA:' + '\n';
                for (let i = 0; i < settings.filters.length; i++) {
                    const match = this.state.queryCriteriaMasterList.find(
                        (x) => x.value === settings.filters[i].value
                    );
                    if (match) displayName = match.label;
                    else if (settings.filters[i].label) displayName = settings.filters[i].label;
                    else if (name) displayName = settings.filters[i].value;

                    selectedFilters += settings.filters[i].label + '\n';
                }
                selectedFilters += '\n';
            }

            if (settings.columns && settings.columns.length > 0) {
                selectedFilters += 'DISPLAY:' + '\n';
                for (let i = 0; i < settings.columns.length; i++) {
                    name = settings.columns[i].name || '';
                    const match = this.state.displayColumnsAll.find((x) => x.value === name);
                    if (match) displayName = match.label;
                    else if (settings.columns[i].displayName)
                        displayName = settings.columns[i].displayName!;
                    else if (name) displayName = name;

                    selectedFilters += displayName + '\n';
                }
            }
        }

        this.setState({ currentFavoriteDetailsString: selectedFilters });
    };

    exportDisplayColumnFilter = (item: IQueryFilterCriteria) => {
        return (
            item.staticItemId !== QueryStaticItemFields.DepositionSection.Id &&
            item.staticItemId !== QueryStaticItemFields.NotesSection.Id
        );
    };

    exportFilterForDisplayColumns = (): IQueryFilterCriteria[] => {
        const displayColumnsAll: IQueryFilterCriteria[] = this.state.displayColumnsAll;
        const filteredColumns = displayColumnsAll.filter(this.exportDisplayColumnFilter);
        if (!filteredColumns) return filteredColumns;
        else return filteredColumns;
    };

    getTutorialTopic = () => {
        if (this.state.queryType === QueryTypes.CaseDetails.Value)
            return TutorialTopics.QueryToolCaseDetails.toString();
        if (this.state.queryType === QueryTypes.CaseNotes.Value)
            return TutorialTopics.QueryToolCaseNotes.toString();
        return '';
    };

    handleCaseExpertEditorSave = (updatedCaseExpert: ICaseExpertViewDetailModel) => {
        const caseExpertsResultsCopy = cloneDeep(this.state.caseExpertsResults);
        const found = caseExpertsResultsCopy.find((ce) => ce.guid === updatedCaseExpert.guid);

        if (found) {
            found.disciplines = updatedCaseExpert.expert.disciplines ?? [];
            found.leadDefendant = updatedCaseExpert.leadDefendant ?? '';
            found.coDefendants = updatedCaseExpert.coDefendants ?? '';
            found.expertReportStatusTypeId = updatedCaseExpert.expertReportStatusTypeId ?? 0;
            found.expertRetainedByTypeId = updatedCaseExpert.expertRetainedByTypeId ?? 0;
            found.purposeOfRetention = updatedCaseExpert.purposeOfRetention;
        }

        this.setState({ caseExpertsResults: caseExpertsResultsCopy });
    };

    render() {
        const showEasyUpdateToggle =
            Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, this.props.user) &&
            (this.state.queryType === QueryTypes.CaseDetails.Value ||
                this.state.queryType === QueryTypes.CaseNotes.Value ||
                this.state.queryType === QueryTypes.Deposition.Value ||
                this.state.queryType === QueryTypes.CaseExperts.Value);

        if (this.state.openNoteEditor)
            return (
                <NoteEditor
                    readOnly={this.isNoteReadOnly()}
                    user={this.props.user}
                    caseTitle={NoteHelper.getCaseTitle(this.state.currentNote)}
                    noteCategoryTypes={this.getNoteCategoryTypesByQueryType()}
                    currentNote={this.state.currentNote}
                    caseLocalCounselGuid={this.state.currentNote.localCounselOrganizationGuid}
                    defaultAlertTarget={
                        Authorization.isLocalScope(this.props.user)
                            ? this.state.currentNote.caseManager
                            : undefined
                    }
                    defaultWatchTargets={
                        Authorization.isLocalScope(this.props.user) &&
                        this.state.currentNote.alternateCaseManager
                            ? [this.state.currentNote.alternateCaseManager]
                            : undefined
                    }
                    handleSaveComplete={this.onSaveNoteComplete}
                    handleCancel={this.onCancelAddEditNote}
                    helpText={[]}
                    strategyTypeId={this.state.strategyTypeId}
                />
            );

        if (this.state.currentCaseExpert) {
            return (
                <CaseExpertEditor
                    caseExpertGuid={this.state.currentCaseExpert.guid}
                    caseGuid={this.state.currentCaseExpert.caseGuid}
                    defaultIsEditMode={this.state.currentCaseExpert.defaultIsEditMode ?? false}
                    onBackClick={() => this.setState({ currentCaseExpert: undefined })}
                    onSave={this.handleCaseExpertEditorSave}
                    user={this.props.user}
                />
            );
        }

        return (
            <div>
                {Authorization.userHasRight(UserRightsEnum.EasyUpdate, this.props.user) ? (
                    <CaseEasyUpdate
                        user={this.props.user}
                        caseGuid={this.state.easyUpdateCaseGuid}
                        onComplete={this.easyUpdateDone}
                        onCancel={this.easyUpdateCancel}
                        hidden={!this.state.easyUpdateMode}
                        excludeNotes={this.state.queryType === QueryTypes.CaseNotes.Value}
                        additionalHeaderText={
                            this.state.queryType === QueryTypes.CaseNotes.Value
                                ? 'Update Case Data | Case Notes Query'
                                : ''
                        }
                    />
                ) : null}

                {this.state.pendingResponse ? (
                    <Loader />
                ) : (
                    <div>
                        {this.state.easyUpdateMode ? null : (
                            <>
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <h1 className="d-inline-block">Query Tool (Legacy)</h1>
                                        <span className="font-size-xs ps-3">
                                            {this.state.title}
                                        </span>
                                    </div>
                                    <div className="col-sm-8">
                                        {this.state.redirectUrl ? (
                                            <span className="btn btn-blue float-end">
                                                <Link
                                                    to={{ pathname: this.state.redirectUrl }}
                                                    style={{ color: 'white' }}
                                                    state={{
                                                        selectedGuids:
                                                            this.state.selectedGuidsForRedirect,
                                                        redirectComponentCurrentState:
                                                            this.state
                                                                .redirectComponentCurrentState,
                                                    }}
                                                >
                                                    Back
                                                </Link>
                                            </span>
                                        ) : null}
                                        <button
                                            className="btn btn-orange float-end text-gray"
                                            onClick={this.runQuery}
                                        >
                                            Run Query
                                        </button>
                                        <button
                                            className="btn btn-default float-end"
                                            onClick={this.clearQuery}
                                        >
                                            Clear
                                        </button>
                                        <>
                                            {this.state.mode === QueryToolModeEnum.Redirect &&
                                            this.state.redirectUrl &&
                                            (this.state.selectAllCasesForRedirect ||
                                                this.state.selectedGuidsForRedirect.length > 0) ? (
                                                <span className="btn btn-orange text-gray float-end">
                                                    {' '}
                                                    <Link
                                                        to={{ pathname: this.state.redirectUrl }}
                                                        state={{
                                                            redirect: true,
                                                            selectedGuids:
                                                                this.state.selectedGuidsForRedirect,
                                                            redirectComponentCurrentState:
                                                                this.state
                                                                    .redirectComponentCurrentState,
                                                        }}
                                                        style={{ color: 'white' }}
                                                    >
                                                        Add Selected Cases
                                                    </Link>
                                                </span>
                                            ) : null}
                                        </>
                                        {/*this.state.mode === QueryToolModeEnum.Standalone ? <span className="btn btn-blue float-end"> <Link to={LocalRoutes.UserReportList} style={{ color: "white" }}>View Existing Reports</Link></span> : null*/}
                                        {this.state.caseResultsCount <=
                                        maxRecordCountForWebProcessing
                                            ? this.getExportUIByQueryType()
                                            : null}
                                        {this.state.redirectUrl ? null : (
                                            <Favorite
                                                user={this.props.user}
                                                type={{ id: FavoriteTypesEnum.Query }}
                                                currentFavoriteSettingJson={this.getCurrentFavoriteSettingJson()}
                                                currentFavoriteDetailsString={
                                                    this.state.currentFavoriteDetailsString
                                                }
                                                handleFavoriteSelected={this.handleFavoriteSelected}
                                                applyFavoriteSelected={this.applyFavoriteSelected}
                                                handleDefaultFavoriteChange={
                                                    this.handleDefaultFavoriteChange
                                                }
                                            />
                                        )}
                                        {showEasyUpdateToggle ? (
                                            <span className="btn btn-no-bg float-end text-gray ps-3">
                                                <label style={{ cursor: 'pointer' }}>
                                                    <input
                                                        name="chkEasyUpdate"
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        onChange={(
                                                            e: React.ChangeEvent<HTMLInputElement>
                                                        ) => {
                                                            this.handleCheckedChange(e);
                                                        }}
                                                        checked={this.state.enableEasyUpdate}
                                                    />
                                                    <i className="fal fa-edit ps-2 pe-1" />
                                                    Easy Update
                                                </label>
                                            </span>
                                        ) : null}
                                        {this.getTutorialTopic() ? (
                                            <Link
                                                className="float-end"
                                                target="_blank"
                                                to={LocalRoutes.ViewPDF.replace(
                                                    ':id',
                                                    this.getTutorialTopic()
                                                )}
                                            >
                                                <span className="btn btn-no-bg text-gray">
                                                    <i className={'fal fa-graduation-cap'} />
                                                    &nbsp;Tutorials
                                                </span>
                                            </Link>
                                        ) : null}
                                    </div>
                                </div>
                                <div>
                                    <span className="text-danger">
                                        {this.state.validation.model}
                                    </span>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-2">
                                        <label className="text-gray text-lg">Query Type</label>
                                        &nbsp;
                                        <Help
                                            type={ApplicationHelpType.Info}
                                            title="Query Type"
                                            helpText="CASE DETAILS QUERY:\n\n• Select fields from the list of criteria, which includes all fields from the Profile, Case Data and Case Strategy sections of the Case File\n\n• Select specific values for those data fields\n\n• Click RUN QUERY, Checkmate will return all cases that meet those criteria and display either the default data fields or the specific display fields you selected\n\n• You can continue to modify the criteria and/or display for your query and RUN QUERY again\n\CASE NOTE QUERY:\n\n• Select fields from the list of criteria, which includes all fields from the Profile, Case Data and Case Strategy sections of the Case File; you can also use Case Notes as a criterion\n\n• Click RUN QUERY, Checkmate will return all cases that meet those criteria\n\n - if you only use fields from the Profile, Case Data and Case Strategy sections of the Case, Checkmate will return all cases that meet those criteria, whether or not the case has a case note\n  - if you include Case Notes as a criterion (this can be criterion in addition to other fields or the only criterion), Checkmate will return only cases that meet those criteria which means you will only see cases that have a case note(s)."
                                        />
                                    </div>
                                    <div className="col-sm-2">
                                        <CheckmateSelect
                                            notClearable={true}
                                            options={CheckmateSelectHelper.getLookupOptions(
                                                this.fetchQueryTypes()
                                            )}
                                            name="queryTypes"
                                            isDisabled={
                                                this.state.mode === QueryToolModeEnum.Redirect
                                            }
                                            onChange={this.handleQueryTypeChange}
                                            value={CheckmateSelectHelper.getSelectedValueById(
                                                this.fetchQueryTypes(),
                                                this.state.queryType
                                            )}
                                        />
                                    </div>
                                    <span className="text-danger">
                                        {this.state.validation.queryType}
                                    </span>
                                </div>
                                {this.getFilterCriteriaUIByQueryType()}
                                {this.getSelectedFilterCriteriaUIByQueryType()}
                                {this.getDisplayUIByQueryType()}
                                {this.getTotalRecordsByQueryType() > 0 && (
                                    <div className="row mb-2">
                                        <div
                                            className="float-end text-gray"
                                            style={{ textAlign: 'right' }}
                                        >
                                            Returned {this.getTotalRecordsByQueryType()} Record(s)
                                        </div>
                                    </div>
                                )}

                                {this.getQueryResultsUIByQueryType()}
                                {this.state.showExportModal ? (
                                    <Modal
                                        centered
                                        show={this.state.showExportModal}
                                        onHide={this.closeExportModal}
                                        backdrop={false}
                                    >
                                        <Modal.Header>
                                            <Modal.Title> Export </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div>
                                                <span className="text-danger">
                                                    {this.state.validation.export}
                                                </span>
                                            </div>
                                            <div className="row mb-2">
                                                <>
                                                    <CheckmateSelect
                                                        isMulti={true}
                                                        options={this.exportFilterForDisplayColumns()}
                                                        value={
                                                            this.state
                                                                .displayColumnsSelectedForExport
                                                        }
                                                        onChange={
                                                            this.queryColumnsSelectedForExport
                                                        }
                                                    />
                                                </>
                                            </div>

                                            <div className="row mb-4">
                                                <div className="col-sm-6">
                                                    <input
                                                        id="chkSave"
                                                        className="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <span>&nbsp;&nbsp;Save Query Report</span>
                                                </div>
                                                <div className="col-sm-6">
                                                    <button
                                                        className="btn btn-orange float-end"
                                                        onClick={this.generateAndDownloadReport}
                                                    >
                                                        Run
                                                    </button>
                                                    <button
                                                        className="btn btn-default float-end me-2"
                                                        onClick={this.closeExportModal}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                ) : null}

                                <CheckmateDialog
                                    isShowingModal={this.state.showConfirmRemoveCaseNoteDialog}
                                    body="Are you sure you want to delete the selected note? Deleting this note will delete the note permanently from the Case File."
                                    handleClose={this.handleCancelDeleteNote}
                                    handleConfirm={this.deleteNote}
                                    confirmText="Yes"
                                    cancelText="No"
                                    confirmButtonClassName="btn btn-black float-end"
                                    dialogClassName="confirm-document-delete-dialog"
                                    closeButtonClassName="btn btn-default float-end"
                                />

                                {this.state.displayNoteCategoryAdditionalInfo ? (
                                    <NoteCategoryAdditionalInfoDetailDialog
                                        noteCategoryTypes={this.state.noteCategoryTypes.filter(
                                            (x) => x.noteType.id === this.getNoteTypeByQueryType()
                                        )}
                                        isShowingModal={true}
                                        handleClose={() =>
                                            this.setState({
                                                displayNoteCategoryAdditionalInfo: false,
                                            })
                                        }
                                    />
                                ) : null}
                            </>
                        )}
                    </div>
                )}

                <CheckmateDialog
                    isShowingModal={this.state.showTooManyRecordsToDisplayDialog || false}
                    title="Query Results"
                    body={
                        'Your query returned more than ' +
                        maxDisplayRecordCount +
                        ' records. Please refine your search or export to view results.'
                    }
                    handleClose={() => this.setState({ showTooManyRecordsToDisplayDialog: false })}
                    cancelText="Ok"
                    dialogClassName="user-profile-dialog"
                    closeButtonClassName="btn btn-black float-end"
                />

                {this.state.showTooManyRecordsToProcessDialog ? (
                    <Modal
                        centered
                        show={this.state.showTooManyRecordsToProcessDialog}
                        onHide={() => this.setState({ showTooManyRecordsToProcessDialog: false })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> Query Results </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container-fluid">
                                <span>
                                    Your query returned more than{' '}
                                    {maxRecordCountForWebProcessing.toLocaleString('en-US')}{' '}
                                    records. The request cannot be completed at this time. If you
                                    did not run this query by error, please send an email with the
                                    following information to{' '}
                                    <a href={'mailto:ltm@checkmate.legal'}>ltm@checkmate.legal</a>{' '}
                                    and we will perform this query for you.{' '}
                                </span>
                                <br />
                                <ul>
                                    <li>- Purpose for the query</li>
                                    <li>- When you need the results</li>
                                    <li>- Criteria</li>
                                    <li>- Display fields</li>
                                </ul>
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({ showTooManyRecordsToProcessDialog: false })
                                    }
                                >
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                {this.state.showExportInBackgroundDialog ? (
                    <Modal
                        centered
                        show={this.state.showExportInBackgroundDialog}
                        onHide={() => this.setState({ showExportInBackgroundDialog: false })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> Query Results </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container-fluid">
                                <span>
                                    Your query returned more than {maxExportRecordCount} records.
                                    The export request will be processed in the background and a{' '}
                                    <i className="fal fa-file-exclamation color-yellow" /> icon will
                                    appear next to the DATA link in the header when the report is
                                    ready. This may take a few minutes.
                                </span>
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({ showExportInBackgroundDialog: false })
                                    }
                                >
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}
            </div>
        );
    }
}
