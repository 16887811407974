import { ApplicationHelpType, UserRightsEnum } from '../../utilities/Constants';
import { ILookupModel, IMultiSelectOptions } from '../../interfaces/ILookup';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Alert } from 'react-bootstrap';
import Authorization from '../../stores/Authorization';
import { CheckmateDialog } from '../../components/shared/dialog';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import { Help } from '../../components/shared/Help';
import { IExpertListModel } from '../../interfaces/IExpert';
import { IUserModel } from '../../interfaces/IUser';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../../components/shared/SortableHeader';
import UIHelper from '../../utilities/UIHelper';
import { useExpertDeleteMutation } from './useExpertDeleteMutation';
import { useExpertDisciplineTypesLookup } from '../../shared/react-query-hooks/useExpertDisciplineTypesLookup';
import { useExpertTypesLookup } from '../../shared/react-query-hooks/useExpertTypesLookup';
import { useExpertsList } from './useExpertsList';

export function ExpertsList(props: { user: IUserModel }) {
    const navigate = useNavigate();
    const authorizedToDelete = Authorization.userHasRight(UserRightsEnum.DeleteExpert, props.user);
    const authorizedToCreate = Authorization.userHasRight(UserRightsEnum.AddExpert, props.user);

    const [expertToDelete, setExpertToDelete] = useState<IExpertListModel | null>(null);

    const [filteredRows, setFilteredRows] = useState<IExpertListModel[]>([]);
    const [currentFilter, setCurrentFilter] = useState<IListFilters | null>(null);

    const [error, setError] = useState<string>();
    const [sort, setSort] = useState<{
        key: string;
        order: string;
        subKey?: string | undefined;
        subGrandKey?: string | undefined;
    } | null>(null);

    const expertsList = useExpertsList(true);
    const expertTypesLookup = useExpertTypesLookup();
    const expertDisciplinesLookup = useExpertDisciplineTypesLookup();

    const deleteMutation = useExpertDeleteMutation((errorMessage: string) =>
        setError(errorMessage)
    );

    const executeDelete = (expertGuid: string) => {
        deleteMutation
            .mutateAsync(expertGuid)
            .then(() => {
                setExpertToDelete(null);
                expertsList.refetch();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleSort = (
        key: string,
        order: string,
        subKey?: string | undefined,
        subGrandKey?: string | undefined
    ) => {
        setSort({
            key,
            order,
            subKey,
            subGrandKey,
        });
    };

    const handleHeaderAddClick = () => {
        navigate(LocalRoutes.ExpertCreate);
    };

    const handleExpertDeleteClick = (expertGuid: string) => {
        setExpertToDelete(expertsList.data?.find((expert) => expert.guid === expertGuid) ?? null);
    };

    useEffect(() => {
        let experts = expertsList.data ?? [];
        if (sort) {
            sortedExperts.sort(
                Sort.compareValues(sort.key, sort.subKey, sort.order, sort.subGrandKey)
            );
        }
        if (currentFilter) {
            if (currentFilter.disciplineTypeIds.length > 0) {
                experts = experts.filter((expert) =>
                    expert.disciplineTypeIds.some((ed) =>
                        currentFilter.disciplineTypeIds.includes(ed)
                    )
                );
            }
        }
        setFilteredRows(experts);
    }, [JSON.stringify(sort), JSON.stringify(currentFilter), expertsList.data]);

    useEffect(() => {
        expertsList.refetch();
    }, []);

    const handleApplyFilters = (filters: IListFilters) => {
        setCurrentFilter(filters);
    };

    const sortedExperts = expertsList.data ?? [];

    function expertTypeIdSort(a: IExpertListModel, b: IExpertListModel) {
        const aExpertTypeString =
            expertTypesLookup.data?.find((et) => et.id === a.expertTypeId)?.displayName ?? '';

        const bExpertTypeString =
            expertTypesLookup.data?.find((et) => et.id === b.expertTypeId)?.displayName ?? '';

        if (aExpertTypeString === bExpertTypeString) return 0;

        if (sort?.order === 'asc') {
            return aExpertTypeString < bExpertTypeString ? -1 : 1;
        } else {
            return aExpertTypeString < bExpertTypeString ? 1 : -1;
        }
    }

    if (sort) {
        if (sort.key === 'expertTypeId') {
            sortedExperts.sort(expertTypeIdSort);
        } else {
            sortedExperts.sort(
                Sort.compareValues(sort.key, sort.subKey, sort.order, sort.subGrandKey)
            );
        }
    }

    if (expertsList.isLoading) {
        return <Loader />;
    }

    return (
        <>
            {expertsList.error instanceof Error && (
                <div className="row mb-1 me-2">
                    <Alert variant="error">{expertsList.error.message}</Alert>
                </div>
            )}
            <PageHeader
                onAddClick={handleHeaderAddClick}
                onApplyFilters={handleApplyFilters}
                showAddButton={authorizedToCreate}
            />
            {error && (
                <div>
                    <span className="text-danger">{error}</span>
                </div>
            )}
            {sortedExperts.length > 0 ? (
                <table className="table table-sm">
                    <thead>
                        <ExpertsListHeaderRow
                            onSort={handleSort}
                            showDeleteInfoButton={authorizedToDelete}
                        />
                    </thead>
                    <tbody>
                        {filteredRows.map((item: IExpertListModel) => {
                            return (
                                <ExpertsListRow
                                    key={item.guid}
                                    expert={item}
                                    expertDisciplines={expertDisciplinesLookup.data ?? []}
                                    expertTypes={expertTypesLookup.data ?? []}
                                    canDelete={authorizedToDelete && !item.hasAssociatedCases}
                                    onDelete={handleExpertDeleteClick}
                                />
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <span>No records have been created for this zone.</span>
            )}
            {expertToDelete && (
                <CheckmateDialog
                    isShowingModal
                    body={`Are you sure you want to delete the Expert data for ${expertToDelete.firstName} ${expertToDelete.lastName}? All Pending and Closed alerts associated with this expert will also be deleted. This operation is permanent and cannot be reverted back.`}
                    handleClose={() => setExpertToDelete(null)}
                    handleConfirm={() => executeDelete(expertToDelete?.guid ?? '')}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end "
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            )}
        </>
    );
}

interface IListFilters {
    disciplineTypeIds: number[];
}
interface IPageHeaderProps {
    onApplyFilters: (filters: IListFilters) => void;
    onAddClick: () => void;
    showAddButton: boolean;
}
function PageHeader(props: IPageHeaderProps) {
    const disciplines = useExpertDisciplineTypesLookup();
    const [currentDisciplineFilter, setCurrentDisciplineFilter] = useState<IMultiSelectOptions[]>(
        []
    );

    const handleClearFilters = () => {
        setCurrentDisciplineFilter([]);
        props.onApplyFilters({ disciplineTypeIds: [] });
    };

    const handleApplyFilters = () => {
        const disciplineTypeIds = currentDisciplineFilter.map((filter) => filter.id);
        props.onApplyFilters({ disciplineTypeIds });
    };

    return (
        <div className="row mb-3">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <h1 className="col-sm-4">Experts</h1>
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 5,
                        justifyContent: 'flex-end',
                    }}
                >
                    <div style={{ maxWidth: 350, width: 350 }}>
                        <CheckmateSelect
                            isMulti={true}
                            options={
                                disciplines.data?.map(
                                    UIHelper.lookupModelToMultiSelectOptionMapper
                                ) ?? []
                            }
                            value={currentDisciplineFilter}
                            onChange={setCurrentDisciplineFilter}
                            placeholder="-- Select Discipline(s) to filter by --"
                        />
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <button
                            type="button"
                            className="btn btn-black btn-icon"
                            onClick={handleApplyFilters}
                        >
                            <i className="fal fa-filter color-white" />
                        </button>
                        <button
                            type="button"
                            className="btn btn-gray btn-icon"
                            onClick={handleClearFilters}
                        >
                            <i className="fal fa-times color-white" />
                        </button>
                        {props.showAddButton && (
                            <button className="btn-no-bg" onClick={props.onAddClick}>
                                <span className="btn-green btn btn-icon">
                                    <i className="fal fa-lg fa-plus color-white" />
                                </span>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

interface IExpertsListHeaderRowProps {
    onSort: (key: string, order: string, subKey?: string, subGrandKey?: string) => void;
    showDeleteInfoButton: boolean;
}

function ExpertsListHeaderRow({ onSort, showDeleteInfoButton }: IExpertsListHeaderRowProps) {
    return (
        <tr>
            <SortableHeader headerText="First Name" sortKey="firstName" onSort={onSort} noWrap />
            <SortableHeader headerText="Last Name" sortKey="lastName" onSort={onSort} noWrap />
            <SortableHeader headerText="Credentials" sortKey="credentials" onSort={onSort} />
            <th>Discipline(s)</th>
            <SortableHeader headerText="Type" sortKey="expertTypeId" onSort={onSort} />
            <th style={{ textAlign: 'right' }}>
                {showDeleteInfoButton && (
                    <Help
                        type={ApplicationHelpType.Info}
                        title="Expert"
                        helpText="Experts associated with one or more cases cannot be deleted."
                    />
                )}
            </th>
        </tr>
    );
}

interface IExpertsListRowProps {
    canDelete: boolean;
    expert: IExpertListModel;
    expertDisciplines: ILookupModel[];
    expertTypes: ILookupModel[];
    onDelete: (expertGuid: string) => void;
}

function ExpertsListRow({
    canDelete,
    expert,
    expertDisciplines,
    expertTypes,
    onDelete,
}: IExpertsListRowProps) {
    return (
        <tr key={expert.guid} style={{ verticalAlign: 'middle' }}>
            <td className="col-sm-2">
                <Link target="_blank" to={LocalRoutes.ExpertDetail.replace(':id', expert.guid)}>
                    {expert.firstName}
                </Link>
            </td>
            <td className="col-sm-2">
                <Link target="_blank" to={LocalRoutes.ExpertDetail.replace(':id', expert.guid)}>
                    {expert.lastName}
                </Link>
            </td>
            <td className="col-sm-2">{expert.credentials}</td>
            <td>
                {expert.disciplineTypeIds
                    ?.map(
                        (discTypeId) =>
                            expertDisciplines.find((lookup) => lookup.id === discTypeId)
                                ?.displayName
                    )
                    .join(' | ')}
            </td>
            <td>{expertTypes.find((lookup) => lookup.id === expert.expertTypeId)?.displayName}</td>

            <td>
                {canDelete ? (
                    <button
                        className="btn btn-no-bg float-end px-2"
                        onClick={() => onDelete(expert.guid)}
                    >
                        <i className="fal fa-lg fa-trash-alt"></i>
                    </button>
                ) : (
                    <div style={{ minHeight: '30px' }}></div>
                )}
            </td>
        </tr>
    );
}
