import * as React from 'react';

interface IFileUploadProps {
    onChange: (files: FileList) => void;
}

export class FileUpload extends React.Component<IFileUploadProps> {
    constructor(props: IFileUploadProps) {
        super(props);
    }

    handleChange(event: any) {
        const files = event.target.files;

        this.props.onChange(files);
    }

    render() {
        return (
            <input
                type="file"
                className="form-control"
                onChange={(e: any) => {
                    this.handleChange(e);
                }}
            />
        );
    }
}
