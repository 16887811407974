import * as React from 'react';

import {
    AssertionDescriptorDisplayValueTypeEnum,
    AssertionDescriptorValueSourceTypeEnum,
    PackageDealCommentReportParameterNames,
    PackageDealSummarySectionsEnum,
    PackageSettlementStatusTypesEnum,
    ReportTypesEnum,
    TimelineReportStatusEnum,
} from '../../utilities/Constants';

import { ApiClient } from '../../tpi.apiclient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import CheckmateApiClient from '../../services/apiClient';
import Common from '../../stores/Common';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { IEntityAssertionModel } from '../../interfaces/IAssertionDescriptor';
import { ILookupModel } from '../../interfaces/ILookup';
import { IPackageSettlementModel } from '../../interfaces/ICase';
import { IReportCaseDetailModel } from '../../interfaces/Report/IReportCaseDetailModel';
import { IReportParametersModel } from '../../interfaces/Report/IReport';
import { IReportSectionDetailModel } from '../../interfaces/Report/ITimelineReport';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import ValidateUtils from '../../shared/validations';
import moment from 'moment';

interface IPackageSettlementProps {
    user: IUserModel;
    guid: string;
}

interface IFirmModel {
    guid?: string;
    hasStrategy?: boolean;
    name?: string;
    priority?: number;
}

interface CaseSummaryModel {
    detail?: string;
    detailSortOrder?: number;
    diagnosis?: string;
    diagnosisSortOrder?: number;
    format?: string | null;
    format2?: string | null;
    format3?: string | null;
    group?: string;
    groupId?: number;
    groupSortOrder?: number;
    value?: number;
    value2?: number;
    value3?: number;
}

interface OverviewStatsModel {
    dismissedCaseCount?: number;
    name: string;
    openActiveCaseCount?: number;
    openInactiveCaseCount?: number;
    settledCaseCount?: number;
    settlementAmount?: number;
    sortOrder?: number;
    altMonitoredCaseCount?: number;
}

interface IPackageSettlementExtension {
    overviewStats?: OverviewStatsModel[];
    caseSummary?: CaseSummaryModel[];
    historicData?: CaseSummaryModel[];
}

interface IPackageSettlementDetailState {
    pendingResponse: boolean;
    validation: IValidation;
    originalPackageSettlement: IPackageSettlementModel;
    packageSettlement: IPackageSettlementModel & IPackageSettlementExtension;
    caseSearchResults?: IReportCaseDetailModel[];
    selectAllCases?: boolean;
    easyUpdateCaseGuid?: string;
    showViewAllFirmsInfoDialog?: boolean;
    notesOnEditMode?: boolean;
    redirect?: boolean;
    currentNarrativeCaseAssertionOnEditMode?: IEntityAssertionModel;
    pendingSave?: boolean;
    status?: ILookupModel;
    today?: string;
    showCommentsModal?: boolean;
    showExportModal?: boolean;
    exportInputModel?: IReportSectionDetailModel[];
}

let _apiClient = new CheckmateApiClient();

export class PackageSettlementDetailExport extends React.Component<
    IPackageSettlementProps,
    IPackageSettlementDetailState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            pendingResponse: false,
            validation: {},
            packageSettlement: {
                valueAddDetails: [],
                notes: [],
                locked: false,
                earliestSettledResolutionDate: '',
            },
            originalPackageSettlement: {
                valueAddDetails: [],
                notes: [],
                locked: false,
                earliestSettledResolutionDate: '',
            },
            today: new Date().toString(),
            showCommentsModal: false,
            showExportModal: false,
            exportInputModel: [],
        };
    }

    componentDidMount() {
        let guid = this.props.guid;
        if (
            !Authorization.isAuthorizedToRoute(
                LocalRoutes.PackageSettlementDetailExport,
                this.props.user
            ) ||
            !guid
        )
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadPackageDetails(guid);
    }

    loadPackageDetails = async (guid: string) => {
        this.setState({ pendingResponse: true });
        var res = await _apiClient.getPackageSettlementDetailWithReport(guid, true);

        if (res.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (res.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (res.payload) {
            this.setState({
                packageSettlement: res.payload,
                originalPackageSettlement: JSON.parse(JSON.stringify(res.payload)),
                pendingResponse: false,
            });
        }
    };

    renderStatusDetails = () => {
        const pkg = this.state.packageSettlement;
        if (pkg && pkg.status) {
            const firms = pkg.firms;
            let plaintiffs: string[] = [];
            let initialDate: string = pkg.initialDate ? Common.dateFormat(pkg.initialDate) : '';
            let finalDate: string =
                pkg.finalDate && pkg.status.id === PackageSettlementStatusTypesEnum.Complete
                    ? Common.dateFormat(pkg.finalDate)
                    : '';
            let displayName = pkg.status.displayName! || pkg.status.name! || '';

            if (firms && firms.length) {
                firms.forEach((firm: IFirmModel) => {
                    plaintiffs.push((firm.name as string) || '');
                });
            }
            return (
                <>
                    <div className="row mb-2">
                        <label className="control-label col-sm-2">Status</label>
                        <span className="col-sm-10">{displayName}</span>
                    </div>

                    {plaintiffs.length > 0 ? (
                        <div className="row mb-2">
                            <label className="control-label col-sm-2">Plaintiffs Firm(s)</label>
                            <span className="col-sm-10">
                                {plaintiffs && plaintiffs.length
                                    ? plaintiffs.map((item: string, index: number) => {
                                          return <div key={`${index}`}>{item}</div>;
                                      })
                                    : null}
                            </span>
                        </div>
                    ) : null}
                    {initialDate ? (
                        <div className="row mb-2">
                            <label className="control-label col-sm-2">Initial Date</label>
                            <span className="col-sm-10">{initialDate}</span>
                        </div>
                    ) : null}
                    {finalDate ? (
                        <div className="row mb-2">
                            <label className="control-label col-sm-2">Final Date</label>
                            <span className="col-sm-10">{finalDate}</span>
                        </div>
                    ) : null}
                </>
            );
        } else {
            return null;
        }
    };

    renderTableRowData = () => {
        if (this.state.packageSettlement && this.state.packageSettlement.overviewStats) {
            const overviewStats = this.state.packageSettlement.overviewStats || [];
            if (overviewStats && overviewStats.length) {
                let result = overviewStats.map((stat: any, index: number) => {
                    return (
                        <tr
                            key={`${index}-${stat.name || ''}`}
                            className={stat.name === 'Total' ? 'bold' : ''}
                        >
                            <td style={{ width: '28%' }}>{stat.name || stat.displayName || ''}</td>
                            <td style={{ width: '10%' }}>{stat.totalCaseCount || 0}</td>
                            <td style={{ width: '10%' }}>{stat.dismissedCaseCount || 0}</td>
                            <td style={{ width: '10%' }}>{stat.settledCaseCount || 0}</td>
                            <td style={{ width: '10%' }}>{stat.altMonitoredCaseCount || 0}</td>
                            <td style={{ width: '10%' }}>
                                {stat.settlementAmount
                                    ? Common.formatCurrency(stat.settlementAmount.toString())
                                    : ''}
                            </td>
                        </tr>
                    );
                });
                return result;
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    getRenderedText(assertion: IEntityAssertionModel, editMode?: boolean) {
        if (!assertion || !assertion.assertionDescriptor) return '';
        let ad = assertion.assertionDescriptor!;
        let returnValue = '';
        let valueSourceTypeId = ad.valueSourceType!.id;
        let displayTypeId = ad.valueDisplayType ? ad.valueDisplayType.id : 0;
        switch (valueSourceTypeId) {
            case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
                returnValue = assertion.assertionDescriptorValue
                    ? assertion.assertionDescriptorValue.text!
                    : '';
                break;
            case AssertionDescriptorValueSourceTypeEnum.Boolean.Value:
                if (assertion.booleanValue != undefined)
                    returnValue = assertion.booleanValue ? 'true' : 'false';
                break;
            case AssertionDescriptorValueSourceTypeEnum.Text.Value:
                switch (displayTypeId) {
                    case AssertionDescriptorDisplayValueTypeEnum.Currency.Value:
                        if (assertion.numericValue)
                            returnValue = Common.formatCurrency(assertion.numericValue.toString())!;
                        break;
                    case AssertionDescriptorDisplayValueTypeEnum.DateTime.Value:
                        if (assertion.dateTimeValue)
                            returnValue = Common.dateFormat(assertion.dateTimeValue.toString())!;
                        break;
                    case AssertionDescriptorDisplayValueTypeEnum.Percentage.Value:
                        if (assertion.numericValue)
                            returnValue =
                                parseInt(assertion.numericValue.toString()).toString() + '%';
                        break;
                    case AssertionDescriptorDisplayValueTypeEnum.Number.Value:
                        if (assertion.numericValue)
                            returnValue = parseInt(assertion.numericValue.toString()).toString();
                        break;
                    case AssertionDescriptorDisplayValueTypeEnum.Decimal.Value:
                        if (assertion.numericValue) returnValue = assertion.numericValue.toString();
                        break;
                    default:
                        returnValue = assertion.textValue || '';
                        break;
                }
                break;
            default:
                break;
        }
        return returnValue;
    }

    getValue(valueAdd: IEntityAssertionModel) {
        let resultValue: any = this.getRenderedText(valueAdd);
        resultValue = resultValue
            ? resultValue.split('\n').map((item: any, key: number) => {
                  return (
                      <span key={key}>
                          {item}
                          <br />
                      </span>
                  );
              })
            : null;
        return resultValue;
    }

    renderValueAddDetails = () => {
        if (this.state.packageSettlement && this.state.packageSettlement.valueAddDetails) {
            const valueAddDetails = this.state.packageSettlement.valueAddDetails;
            if (valueAddDetails && valueAddDetails.length) {
                let result = valueAddDetails.map((item: any, index: number) => {
                    return (
                        <div key={`${index}-${item.correlationKeyId || ''}`}>
                            <div
                                className="col-sm-12 align-top no-padding"
                                style={{ paddingTop: '5px', paddingBottom: '5px' }}
                            >
                                <label>
                                    {item && item.valueAdd ? this.getValue(item.valueAdd) : null}
                                </label>
                            </div>
                            <div
                                className="col-sm-12 align-top no-padding"
                                style={{ paddingTop: '10px', paddingBottom: '5px' }}
                            >
                                {item && item.valueAdd ? this.getValue(item.notes) : null}
                            </div>
                        </div>
                    );
                });
                return result;
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    renderCaseSummaryDetailsRows = () => {
        let rows: any = [];
        if (this.state.packageSettlement && this.state.packageSettlement.caseSummary) {
            const caseSummary = this.state.packageSettlement.caseSummary || [];

            const maxGroupSortOrder = Math.max.apply(
                Math,
                caseSummary.map((x) => x.groupSortOrder!)
            );
            const maxDiagnosisSortOrder = Math.max.apply(
                Math,
                caseSummary.map((x) => x.diagnosisSortOrder!)
            );

            for (var i = 1; i <= maxGroupSortOrder; i++) {
                let currentGroup = caseSummary.filter((x) => x.groupSortOrder === i);
                if (currentGroup.length === 0) continue;
                let columns: any = [];
                columns.push(<td className="bold">{currentGroup[0].group}</td>);
                let allDiagnosisTotal = 0;
                let totalByDiagnosis = 0;
                let isSettled = currentGroup.filter(
                    (item) => item.groupId === TimelineReportStatusEnum.Settled
                ).length;
                let totalPipeValue = 0;
                for (var j = 1; j <= maxDiagnosisSortOrder; j++) {
                    totalByDiagnosis = 0;
                    if (isSettled) {
                        let pipeValue = 0;
                        currentGroup
                            .filter((x) => x.diagnosisSortOrder === j)
                            .forEach((a) => {
                                totalByDiagnosis += a.value || 0;
                                if (a.value2) {
                                    pipeValue = a.value2 || 0;
                                    totalPipeValue += pipeValue;
                                }
                            });
                        allDiagnosisTotal += totalByDiagnosis;
                        columns.push(
                            <td className="bold">
                                {totalByDiagnosis}|${pipeValue}
                            </td>
                        );
                    } else {
                        currentGroup
                            .filter((x) => x.diagnosisSortOrder === j)
                            .forEach((a) => (totalByDiagnosis += a.value || 0));
                        allDiagnosisTotal += totalByDiagnosis;
                        columns.push(<td className="bold">{totalByDiagnosis}</td>);
                    }
                }
                if (isSettled) {
                    columns.push(
                        <td className="bold">
                            {allDiagnosisTotal}|{Common.formatCurrency(totalPipeValue.toString())}
                        </td>
                    );
                } else {
                    columns.push(<td className="bold">{allDiagnosisTotal}</td>);
                }
                rows.push(<tr>{columns}</tr>);

                const maxDetailSortOrder = Math.max.apply(
                    Math,
                    currentGroup.map((x) => x.detailSortOrder!)
                );

                if (isSettled) {
                    for (var k = 2; k <= maxDetailSortOrder; k++) {
                        const currentDetail = currentGroup.filter((x) => x.detailSortOrder === k);
                        if (currentDetail.length === 0) continue;
                        const totalNumberOfCases =
                            currentDetail
                                .map((item) => item.value)
                                .reduce((prev, next) => (prev || 0) + (next || 0)) || 0;
                        let totalSettlementValue =
                            currentDetail
                                .map((item) => item.value2)
                                .reduce((prev, next) => (prev || 0) + (next || 0)) || 0;

                        for (var m: number = 1; m <= 3; m++) {
                            allDiagnosisTotal = 0;
                            let columns: any = [];
                            if (m === 1) {
                                columns.push(
                                    <td className="border-0">{currentDetail[0].detail}</td>
                                );
                            } else {
                                columns.push(<td className="border-0"></td>);
                            }
                            for (var l: number = 1; l <= maxDiagnosisSortOrder; l++) {
                                currentDetail
                                    .filter((x) => x.diagnosisSortOrder === l)
                                    .forEach(
                                        (a: any) =>
                                            (allDiagnosisTotal += a[`value${m}`] || a.value || 0)
                                    );
                                if (m === 1) {
                                    columns.push(
                                        <td className="border-0">
                                            {
                                                currentDetail.filter(
                                                    (x: any) => x.diagnosisSortOrder == l
                                                )[0].value
                                            }
                                        </td>
                                    );
                                } else if (m == 2) {
                                    const value2 =
                                        currentDetail.filter(
                                            (x: any) => x.diagnosisSortOrder == l
                                        )[0].value2 || 0;
                                    columns.push(
                                        <td className="border-0">
                                            {Common.formatCurrency(value2.toString())}
                                        </td>
                                    );
                                } else {
                                    const value3 =
                                        currentDetail.filter(
                                            (x: any) => x.diagnosisSortOrder == l
                                        )[0].value3 || 0;
                                    columns.push(
                                        <td className="border-0">
                                            {Common.formatCurrency(value3.toString())}
                                        </td>
                                    );
                                }
                            }

                            if (m === 1) {
                                columns.push(
                                    <td className="border-0">
                                        {allDiagnosisTotal.toString() + ' Case(s)'}
                                    </td>
                                );
                            } else if (m == 2) {
                                columns.push(
                                    <td className="border-0">
                                        {Common.formatCurrency(allDiagnosisTotal.toString())}
                                    </td>
                                );
                            } else {
                                const avg =
                                    totalNumberOfCases == 0
                                        ? 0
                                        : Math.round(totalSettlementValue / totalNumberOfCases);
                                columns.push(
                                    <td className="border-0">
                                        {Common.formatCurrency(avg.toString()) + '/Case'}
                                    </td>
                                );
                            }

                            rows.push(<tr>{columns}</tr>);
                        }
                    }
                } else {
                    for (var k = 2; k <= maxDetailSortOrder; k++) {
                        allDiagnosisTotal = 0;
                        let columns: any = [];
                        const currentDetail = currentGroup.filter((x) => x.detailSortOrder === k);
                        if (currentDetail.length === 0) continue;
                        columns.push(<td className="border-0">{currentDetail[0].detail}</td>);
                        for (var l = 1; l <= maxDiagnosisSortOrder; l++) {
                            currentDetail
                                .filter((x) => x.diagnosisSortOrder === l)
                                .forEach((a) => (allDiagnosisTotal += a.value || 0));
                            columns.push(
                                <td className="border-0">
                                    {
                                        currentDetail.filter((x) => x.diagnosisSortOrder == l)[0]
                                            .value
                                    }
                                </td>
                            );
                        }
                        columns.push(<td className="border-0">{allDiagnosisTotal}</td>);
                        rows.push(<tr key={k}>{columns}</tr>);
                    }
                }
            }
        }

        return rows;
    };

    renderHistoricDetailsRows = () => {
        let rows: any = [];
        if (this.state.packageSettlement && this.state.packageSettlement.historicData) {
            const historicData = this.state.packageSettlement.historicData || [];

            const maxGroupSortOrder = Math.max.apply(
                Math,
                historicData.map((x) => x.groupSortOrder!)
            );
            const maxDiagnosisSortOrder = Math.max.apply(
                Math,
                historicData.map((x) => x.diagnosisSortOrder!)
            );

            for (var i = 1; i <= maxGroupSortOrder; i++) {
                let currentGroup = historicData.filter((x) => x.groupSortOrder === i);
                if (currentGroup.length === 0) continue;
                let columns: any = [];
                let allDiagnosisTotal = 0;
                const emptyDetailRows = new Array(5).fill(<td>{''}</td>);
                columns.push(<td className="bold">{currentGroup[0].group}</td>);
                columns.push(emptyDetailRows);
                columns = columns.flat();
                rows.push(<tr>{columns}</tr>);

                const maxDetailSortOrder = Math.max.apply(
                    Math,
                    currentGroup.map((x) => x.detailSortOrder!)
                );
                for (var k = 1; k <= maxDetailSortOrder; k++) {
                    //allDiagnosisTotal = 0;
                    let columns: any = [];
                    const currentDetail = currentGroup.filter((x) => x.detailSortOrder === k);
                    if (currentDetail.length > 0 && currentDetail[0] && currentDetail[0].detail) {
                        columns.push(
                            <td className="border-0">
                                {currentDetail[0] && currentDetail[0].detail
                                    ? currentDetail[0].detail
                                    : ''}
                            </td>
                        );
                        for (var l = 1; l <= maxDiagnosisSortOrder; l++) {
                            //currentDetail.filter(x => x.diagnosisSortOrder === l).forEach(a => allDiagnosisTotal += a.value3 || 0);
                            let currentValue =
                                currentDetail.filter((x) => x.diagnosisSortOrder == l).length > 0
                                    ? currentDetail.filter((x) => x.diagnosisSortOrder == l)[0]
                                          .value3 || 0
                                    : 0;
                            columns.push(
                                <td className="border-0">
                                    {currentValue
                                        ? Common.formatCurrency(currentValue.toString())
                                        : ''}
                                </td>
                            );
                        }
                        //columns.push(<td className={k === maxDetailSortOrder ? "border-0-top thin-bottom-border" : "border-0"}>{allDiagnosisTotal ? Common.formatCurrency(allDiagnosisTotal.toString()) : ''}</td>);
                        rows.push(<tr key={k}>{columns}</tr>);
                    }
                }
            }
        }

        return rows;
    };

    renderHistoricDetails = () => {
        if (this.state.packageSettlement && this.state.packageSettlement.historicData) {
            const summary = this.state.packageSettlement.historicData || [];
            if (Array.isArray(summary) && summary.length) {
                let diagnosisHeaders: any = [];
                summary.forEach((item: any) => diagnosisHeaders.push(item.diagnosis));

                if (diagnosisHeaders.length) {
                    diagnosisHeaders = [...Array.from(new Set(diagnosisHeaders))];
                    //diagnosisHeaders.push('Total');
                    return (
                        <div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {diagnosisHeaders.map((header: string, index: number) => {
                                            return (
                                                <th
                                                    key={`${index}-${header}`}
                                                    className="align-top"
                                                >
                                                    {header}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>{this.renderHistoricDetailsRows()}</tbody>
                            </table>
                        </div>
                    );
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    renderCaseSummaryDetails = () => {
        if (this.state.packageSettlement && this.state.packageSettlement.caseSummary) {
            const summary = this.state.packageSettlement.caseSummary || [];
            if (Array.isArray(summary) && summary.length) {
                let diagnosisHeaders: any = [];
                summary.forEach((item: any) => diagnosisHeaders.push(item.diagnosis));

                if (diagnosisHeaders.length) {
                    diagnosisHeaders = [...Array.from(new Set(diagnosisHeaders))];
                    diagnosisHeaders.push('Total');
                    return (
                        <div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {diagnosisHeaders.map((header: string, index: number) => {
                                            return (
                                                <th
                                                    key={`${index}-${header}`}
                                                    className="align-top"
                                                >
                                                    {header}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>{this.renderCaseSummaryDetailsRows()}</tbody>
                            </table>
                        </div>
                    );
                }
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    renderCaseDetails = () => {
        const cases = this.state.packageSettlement.cases;
        if (cases && cases.length) {
            return (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Case Name</th>
                            <th>Status</th>
                            <th>Final Settlement</th>
                            <th>Projected Resolution</th>
                            <th>Projected Settlement</th>
                            <th>Jurisdiction</th>
                            <th>Disease</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.packageSettlement &&
                            this.state.packageSettlement.cases &&
                            this.state.packageSettlement.cases.map(
                                (item: IReportCaseDetailModel) => {
                                    return (
                                        <tr key={item.caseGuid}>
                                            <td className="padding-right-sm">
                                                {item.caseName ? item.caseName : ''}
                                            </td>
                                            <td className="padding-right-sm">
                                                {item.caseStatus ? item.caseStatus.displayName : ''}
                                            </td>
                                            <td className="padding-right-sm">
                                                {item.actualSettlement
                                                    ? Common.formatCurrency(
                                                          item.actualSettlement.toString()
                                                      )
                                                    : ''}
                                            </td>
                                            <td className="padding-right-sm">
                                                {item.expectedOutcome}
                                            </td>
                                            <td className="padding-right-sm">
                                                {item.projectedSettlement
                                                    ? Common.formatCurrency(
                                                          item.projectedSettlement.toString()
                                                      )
                                                    : ''}
                                            </td>
                                            <td className="padding-right-sm">
                                                {item.jurisdiction}
                                            </td>
                                            <td className="padding-right-sm">{item.diagnosis}</td>
                                        </tr>
                                    );
                                }
                            )}
                    </tbody>
                </table>
            );
        } else {
            return null;
        }
    };

    handleExportError = () => {
        let validation = this.state.validation;
        validation.model = [DisplayMessages.UnexpectedError];
        this.setState({ pendingResponse: false, validation: validation });
    };

    getCommentText = (reportSection: PackageDealSummarySectionsEnum) => {
        if (this.state.exportInputModel) {
            const sectionMatch = this.state.exportInputModel.find(
                (x) => x.section === reportSection
            );
            if (sectionMatch) return sectionMatch.comment;
        }

        return '';
    };

    handleCommentsChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
        reportSection: PackageDealSummarySectionsEnum,
        name: string
    ) => {
        let exportInputModel = this.state.exportInputModel;
        if (!exportInputModel) {
            exportInputModel = [];
        }

        let match = exportInputModel.find((x) => x.section === reportSection);
        if (match) {
            match.comment = event.target.value;
        } else {
            exportInputModel.push({
                section: reportSection,
                sectionName: name,
                comment: event.target.value,
            });
        }
        this.setState({ exportInputModel: exportInputModel });
    };

    getExcludeCheckedValue = (section: PackageDealSummarySectionsEnum) => {
        let exportInputModel = this.state.exportInputModel;
        if (exportInputModel) {
            let sectionMatch = exportInputModel.find((x) => x.section === section);
            if (sectionMatch) {
                return sectionMatch.excludeInReport || false;
            }
        }
        return false;
    };

    handleExcludeSectionCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: PackageDealSummarySectionsEnum
    ) => {
        let exportInputModel = this.state.exportInputModel;
        if (!exportInputModel) {
            exportInputModel = [];
        }
        const historicName = PackageDealCommentReportParameterNames[0].name;
        const caseListName = PackageDealCommentReportParameterNames[1].name;
        let sectionMatch = exportInputModel.find((x) => x.section === section);
        if (sectionMatch) {
            sectionMatch.excludeInReport = event.target.checked;
        } else {
            exportInputModel!.push({
                section: section,
                sectionName: historicName,
                excludeInReport: event.target.checked,
            });
            exportInputModel!.push({
                section: PackageDealSummarySectionsEnum.CaseList,
                sectionName: caseListName,
                excludeInReport: event.target.checked,
            });
        }
        this.setState({ exportInputModel: exportInputModel });
    };

    generateAndDownloadReport = async () => {
        this.setState({ pendingResponse: true, validation: {} });

        var xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + ApiRoutes.ExportReport, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this.setState({ pendingResponse: false, validation: {}, showExportModal: false });
                var fileName = 'RecapReport.pdf';
                var blob = xhr.response;

                var contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    let contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (var i = 0; i < contentDispositionItems.length; i++) {
                            let currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                var n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                let validation = this.state.validation;
                validation.model = [DisplayMessages.ReportGenerateError];
                this.setState({
                    pendingResponse: false,
                    validation: validation,
                    showExportModal: false,
                });
            }
        };

        let reportParameters: IReportParametersModel = {
            reportType: { id: ReportTypesEnum.PackageSettlementDetail.Value },
            reportSectionDetails:
                (this.state.exportInputModel as IReportSectionDetailModel[]) || [],
            entityGuid: this.state.packageSettlement.guid || '',
        };

        xhr.send(JSON.stringify(reportParameters));
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;
        return (
            <div>
                <div className="row pb-3">
                    <div className="col-sm-6">
                        <span style={{ fontSize: '24px' }}>
                            Package Deal Summary - {this.state.packageSettlement.name || ''}
                        </span>
                    </div>
                    <div className="col-sm-6">
                        <span className="float-end">
                            <button
                                className="btn btn-orange float-end"
                                onClick={() => this.setState({ showCommentsModal: true })}
                            >
                                Comments
                            </button>
                            <button
                                className="btn btn-no-bg float-end text-gray"
                                onClick={() => this.setState({ showExportModal: true })}
                            >
                                <i className="fal fa-file-chart-column" />
                                &nbsp;Report
                            </button>
                        </span>
                    </div>
                    <div className="form-group">
                        <span className="text-danger">{this.state.validation.model}</span>
                    </div>
                </div>
                <div className="row py-1-med">
                    <div className="col-sm-12">
                        {this.state.packageSettlement ? this.renderStatusDetails() : null}
                    </div>
                    <div className="col-sm-12" style={{ paddingTop: '10px' }}>
                        <label className="form-group">
                            <h5>Deal Overview</h5>
                        </label>
                        <table className="table thick-outer-border">
                            <thead>
                                <tr>
                                    <th className="align-top"></th>
                                    <th className="align-top">Total Cases</th>
                                    <th className="align-top">Dismissed</th>
                                    <th className="align-top">Settled</th>
                                    <th className="align-top">Alt-Monitored</th>
                                    <th className="align-top">Total Settlement</th>
                                </tr>
                            </thead>
                            <tbody className="align-top">
                                {this.state.packageSettlement &&
                                this.state.packageSettlement.overviewStats &&
                                this.state.packageSettlement.overviewStats.length
                                    ? this.renderTableRowData()
                                    : null}
                                <tr>
                                    <td style={{ paddingTop: '25px' }} colSpan={6}>
                                        {this.state.packageSettlement
                                            ? this.state.packageSettlement.description
                                            : null}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {this.state.packageSettlement &&
                    this.state.packageSettlement.valueAddDetails &&
                    this.state.packageSettlement.valueAddDetails.length > 0 ? (
                        <div className="col-sm-12">
                            <label className="form-group">
                                <h5>Value Add</h5>
                            </label>
                            <div>{this.renderValueAddDetails()}</div>
                        </div>
                    ) : null}
                    <div className="col-sm-12">
                        <label className="form-group">
                            <h5>Case Summary</h5>
                        </label>
                        {this.state.packageSettlement && this.state.packageSettlement.caseSummary
                            ? this.renderCaseSummaryDetails()
                            : null}
                    </div>
                    <div className="col-sm-12" style={{ paddingTop: '15px' }}>
                        <label className="form-group">
                            <h5>Historic Settlement Averages</h5>
                        </label>
                        {this.state.packageSettlement.earliestSettledResolutionDate ? (
                            <span>
                                <br />
                                (Oldest settled{' '}
                                {moment(
                                    this.state.packageSettlement.earliestSettledResolutionDate
                                ).format('YYYY-MM-DD')}
                                )
                            </span>
                        ) : null}
                        {this.state.packageSettlement && this.state.packageSettlement.cases
                            ? this.renderHistoricDetails()
                            : null}
                    </div>
                    <div className="col-sm-12" style={{ paddingTop: '15px' }}>
                        <label className="form-group">
                            <h5>Case List</h5>
                        </label>
                        {this.state.packageSettlement && this.state.packageSettlement.cases
                            ? this.renderCaseDetails()
                            : null}
                    </div>
                </div>
                {this.state.showCommentsModal ? (
                    <Modal
                        centered
                        show={this.state.showCommentsModal}
                        onHide={() => this.setState({ showCommentsModal: false })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> Comments </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {PackageDealCommentReportParameterNames.map((comment: any) => {
                                return (
                                    <div className="form-group">
                                        <label className="control-label">
                                            {comment.displayName} Comments
                                        </label>
                                        <textarea
                                            className="form-control"
                                            value={this.getCommentText(comment.reportSection)}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => {
                                                this.handleCommentsChange(
                                                    e,
                                                    comment.reportSection,
                                                    comment.name
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            })}
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={() => this.setState({ showCommentsModal: false })}
                                >
                                    Done
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() => this.setState({ exportInputModel: [] })}
                                >
                                    Clear
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                {this.state.showExportModal ? (
                    <Modal
                        centered
                        show={this.state.showExportModal}
                        onHide={() => this.setState({ showExportModal: false })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> Export Settings </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="margin-top-sm">
                                <input
                                    name="chkExcludeRiskProfile"
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={this.getExcludeCheckedValue(
                                        PackageDealSummarySectionsEnum.HistoricAverages
                                    )}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.handleExcludeSectionCheckedChange(
                                            e,
                                            PackageDealSummarySectionsEnum.HistoricAverages
                                        );
                                    }}
                                />
                                <span>&nbsp;&nbsp;Exclude Historic Settlement Averages</span>
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() => this.setState({ showExportModal: false })}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={this.generateAndDownloadReport}
                                >
                                    Go
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}
            </div>
        );
    }
}
