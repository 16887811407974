import { CaseList } from '../case/CaseList';
import { FavoriteTypesEnum } from '../../utilities/Constants';
import { IOrganizationModel } from '../../interfaces/IOrganization';
import { IUserModel } from '../../interfaces/IUser';
import { Loader } from '../../components/shared/Loader';
import { useCaseManagersInZone } from '../../shared/react-query-hooks/useCaseManagersInZone';
import { useCaseStatusTypesLookup } from '../../shared/react-query-hooks/useCaseStatusTypesLookup';
import { useGetDefaultUserOrg } from '../../shared/react-query-hooks/useGetDefaultUserOrg';
import { useGetDiagnoses } from '../../shared/react-query-hooks/useGetDiagnoses';
import { useGetFavorites } from '../../shared/react-query-hooks/useGetFavorites';
import { useGetJurisdictions } from '../../shared/react-query-hooks/useGetJurisdictions';
import { useGetPlaintiffsFirms } from '../../shared/react-query-hooks/useGetPlaintiffsFirms';
import { useGetQScores } from '../../shared/react-query-hooks/useGetQScores';
import { useLocation } from 'react-router';
import { useUsStatesLookup } from '../../shared/react-query-hooks/useUsStatesLookup';

function CaseListWrapper(props: { user: IUserModel }) {
    const { key } = useLocation();

    const { data: caseStatuses, isLoading: caseStatusesLoading } = useCaseStatusTypesLookup();
    const { data: usStates, isLoading: usStatesLoading } = useUsStatesLookup();
    const { data: diagnoses, isLoading: diagnosesLoading } = useGetDiagnoses();
    const { data: caseManagers, isLoading: caseManagersLoading } = useCaseManagersInZone();
    const { data: favorites, isLoading: favoritesLoading } = useGetFavorites(
        FavoriteTypesEnum.CaseList
    );
    const { data: jurisdictions, isLoading: jurisdictionsLoading } = useGetJurisdictions();
    const { data: qScores, isLoading: qScoresLoading } = useGetQScores();
    const { data: plaintiffsFirms, isLoading: plaintiffFirmsLoading } = useGetPlaintiffsFirms();
    const { data: defaultUserOrg, isLoading: defaultUserOrgLoading } = useGetDefaultUserOrg();

    const showLoader =
        caseStatusesLoading ||
        usStatesLoading ||
        diagnosesLoading ||
        caseManagersLoading ||
        favoritesLoading ||
        jurisdictionsLoading ||
        qScoresLoading ||
        plaintiffFirmsLoading ||
        defaultUserOrgLoading;

    if (showLoader) {
        return <Loader />;
    }

    return (
        <CaseList
            defaultUserOrg={defaultUserOrg ?? ({} as IOrganizationModel)}
            refreshIdentifier={key}
            user={props.user}
            lookups={{
                caseManagers: caseManagers ?? [],
                caseStatuses: caseStatuses ?? [],
                diagnoses: diagnoses ?? [],
                favorites: favorites ?? [],
                jurisdictions: jurisdictions ?? [],
                plaintiffsFirms: plaintiffsFirms ?? [],
                qScores: qScores ?? [],
                usStates: usStates ?? [],
            }}
        />
    );
}

export default CaseListWrapper;
