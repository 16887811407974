import * as React from 'react';

import { ApplicationHelpType, QueryToolModeEnum, QueryTypes } from '../../utilities/Constants';
import CheckmateSelectGrouped, {
    IGroupableOption,
} from '../../components/shared/CheckmateSelectGrouped';

import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import { Help } from '../../components/shared/Help';
import { IMultiSelectOptions as IMultiSelectOption } from '../../interfaces/ILookup';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { getAuthorizedQueryTypes } from './Query2';

export interface IQueryTypeSelectorProps {
    mode: number;
    onChange: (selectedItem: any) => void;
    selectedQueryType: number;
    user: IUserModel;
    validation: IValidation;
}

function getGroupLabel(option: IMultiSelectOption) {
    return option.id === QueryTypes.ZoneNotes.Value || option.id === QueryTypes.StrategyNote.Value
        ? 'Other'
        : 'Case';
}

export function QueryTypeSelector(props: IQueryTypeSelectorProps) {
    const queryTypes = getAuthorizedQueryTypes(props.user);
    const lookupOptions = CheckmateSelectHelper.getLookupOptions(queryTypes);
    const groupableOptions: IGroupableOption[] = lookupOptions.map((option) => ({
        ...option,
        groupLabel: getGroupLabel(option),
    }));

    return (
        <div className="row mb-2">
            <div className="col-sm-2">
                <label className="text-gray text-lg">Query Type</label>
                &nbsp;
                <Help
                    type={ApplicationHelpType.Info}
                    title="Query Type"
                    helpText="CASE DETAILS QUERY:\n\n• Select fields from the list of criteria, which includes all fields from the Profile, Case Data and Case Strategy sections of the Case File\n\n• Select specific values for those data fields\n\n• Click RUN QUERY, Checkmate will return all cases that meet those criteria and display either the default data fields or the specific display fields you selected\n\n• You can continue to modify the criteria and/or display for your query and RUN QUERY again\n\CASE NOTE QUERY:\n\n• Select fields from the list of criteria, which includes all fields from the Profile, Case Data and Case Strategy sections of the Case File; you can also use Case Notes as a criterion\n\n• Click RUN QUERY, Checkmate will return all cases that meet those criteria\n\n - if you only use fields from the Profile, Case Data and Case Strategy sections of the Case, Checkmate will return all cases that meet those criteria, whether or not the case has a case note\n  - if you include Case Notes as a criterion (this can be criterion in addition to other fields or the only criterion), Checkmate will return only cases that meet those criteria which means you will only see cases that have a case note(s)."
                />
            </div>
            <div className="col-sm-2">
                <CheckmateSelectGrouped
                    notClearable={true}
                    options={groupableOptions}
                    name="queryTypes"
                    isDisabled={props.mode === QueryToolModeEnum.Redirect}
                    onChange={props.onChange}
                    value={CheckmateSelectHelper.getSelectedValueById(
                        queryTypes,
                        props.selectedQueryType
                    )}
                />
            </div>
            <span className="text-danger">{props.validation.queryType}</span>
        </div>
    );
}
