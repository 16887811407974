import * as React from 'react';

import {
    AssertionDescriptorDisplayValueTypeEnum,
    AssertionDescriptorValueDisplayTypes,
    AssertionDescriptorValueSourceTypeEnum,
} from '../../utilities/Constants';
import {
    IAssertionDescriptorModel,
    IEntityAssertionModel,
} from '../../interfaces/IAssertionDescriptor';

import CaseFileCheckmateSelectHelper from '../../utilities/CaseFileCheckmateSelectHelper';
import CheckmateSelect from './CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import Common from '../../stores/Common';

interface IEntityAssertionControlProps {
    assertionDescriptor: IAssertionDescriptorModel;
    entityAssertions: IEntityAssertionModel[];
    refreshChange?: (entityAssertions: IEntityAssertionModel[], key?: any) => void;
    // If narrativeFieldAsPopup = true, then handleNarrativeFieldEdit() prop is required.
    narrativeFieldAsPopup?: boolean;
    handleNarrativeFieldEdit?: (entityAssertion: IEntityAssertionModel) => void;
    additionalIdentifierKey?: any;
    editMode?: boolean;
}

interface IEntityAssertionControlState {
    entityAssertions: IEntityAssertionModel[];
    showNarrativeModal?: boolean;
}

export default class EntityAssertionControl extends React.Component<
    IEntityAssertionControlProps,
    IEntityAssertionControlState
> {
    constructor(props: IEntityAssertionControlProps) {
        super(props);
        this.state = {
            entityAssertions: [],
        };

        this.onSearchableSingleSelectChange = this.onSearchableSingleSelectChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            entityAssertions: this.props.entityAssertions,
        });
    }

    componentDidUpdate(prevProps: IEntityAssertionControlProps) {
        if (prevProps.entityAssertions != this.props.entityAssertions) {
            this.setState({
                entityAssertions: this.props.entityAssertions,
            });
        }
    }

    getValue(assertion: IEntityAssertionModel, editMode?: boolean) {
        if (!assertion || !assertion.assertionDescriptor) return '';
        const ad = assertion.assertionDescriptor!;
        let returnValue = '';
        const valueSourceTypeId = ad.valueSourceType!.id;
        const displayTypeId = ad.valueDisplayType ? ad.valueDisplayType.id : 0;
        switch (valueSourceTypeId) {
            case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
                returnValue = assertion.assertionDescriptorValue
                    ? editMode
                        ? assertion.assertionDescriptorValue.guid!
                        : assertion.assertionDescriptorValue.text!
                    : '';
                break;
            case AssertionDescriptorValueSourceTypeEnum.Boolean.Value:
                if (assertion.booleanValue != undefined)
                    returnValue = editMode
                        ? assertion.booleanValue
                            ? 'true'
                            : 'false'
                        : assertion.booleanValue
                        ? 'Yes'
                        : 'No';
                break;
            case AssertionDescriptorValueSourceTypeEnum.Text.Value:
                switch (displayTypeId) {
                    case AssertionDescriptorDisplayValueTypeEnum.Currency.Value:
                        if (assertion.numericValue)
                            returnValue = editMode
                                ? assertion.numericValue.toString()
                                : Common.formatCurrency(assertion.numericValue.toString())!;
                        break;
                    case AssertionDescriptorDisplayValueTypeEnum.DateTime.Value:
                        if (assertion.dateTimeValue)
                            returnValue = Common.dateFormat(assertion.dateTimeValue.toString())!;
                        break;
                    case AssertionDescriptorDisplayValueTypeEnum.Percentage.Value:
                        if (assertion.numericValue)
                            returnValue =
                                parseInt(assertion.numericValue.toString()).toString() + editMode
                                    ? ''
                                    : '%';
                        break;
                    case AssertionDescriptorDisplayValueTypeEnum.Number.Value:
                        if (assertion.numericValue)
                            returnValue = parseInt(assertion.numericValue.toString()).toString();
                        break;
                    case AssertionDescriptorDisplayValueTypeEnum.Decimal.Value:
                        if (assertion.numericValue) returnValue = assertion.numericValue.toString();
                        break;
                    default:
                        returnValue = assertion.textValue || '';
                        break;
                }
                break;
            default:
                break;
        }

        return returnValue;
    }

    onSearchableSingleSelectChange(selectedItem?: any) {
        const entityAssertions = this.state.entityAssertions;
        if (entityAssertions.length > 0) {
            const valueSourceTypeId = entityAssertions[0].assertionDescriptor!.valueSourceType
                ? entityAssertions[0].assertionDescriptor!.valueSourceType.id || 0
                : 0;
            if (valueSourceTypeId === AssertionDescriptorValueSourceTypeEnum.Boolean.Value) {
                if (selectedItem)
                    entityAssertions[0].booleanValue = selectedItem.id === 1 ? true : false;
                else entityAssertions[0].booleanValue = undefined;
            } else
                entityAssertions[0].assertionDescriptorValue = selectedItem
                    ? { guid: selectedItem.guid! }
                    : undefined;
            this.setState({ entityAssertions: entityAssertions });
        }
    }

    getSingleValueEditControl = () => {
        if (this.state.entityAssertions.length != 1) return;
        const assertion = this.state.entityAssertions[0];
        const ad = assertion.assertionDescriptor!;
        const valueSourceTypeId = ad.valueSourceType!.id;
        const displayTypeId = ad.valueDisplayType ? ad.valueDisplayType.id : 0;
        switch (valueSourceTypeId) {
            case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
                return (
                    <span className="case-file-edit">
                        <CheckmateSelect
                            options={CaseFileCheckmateSelectHelper.getOptionsForCheckmateSelect(
                                assertion.assertionDescriptor!.assertionDescriptorValues!
                            )}
                            name={assertion.assertionDescriptor!.guid!}
                            value={CaseFileCheckmateSelectHelper.getCheckmateSelectValue(assertion)}
                            onChange={this.onSearchableSingleSelectChange}
                        />
                    </span>
                );
            case AssertionDescriptorValueSourceTypeEnum.Boolean.Value:
                return (
                    <span className="case-file-edit">
                        <CheckmateSelect
                            options={CheckmateSelectHelper.getBooleanOptions()}
                            name={assertion.assertionDescriptor!.guid!}
                            value={CheckmateSelectHelper.getBooleanValue(assertion.booleanValue)}
                            onChange={this.onSearchableSingleSelectChange}
                        />
                    </span>
                );
            case AssertionDescriptorValueSourceTypeEnum.Text.Value:
                switch (displayTypeId) {
                    case AssertionDescriptorDisplayValueTypeEnum.Currency.Value:
                        return (
                            <span style={{ marginLeft: '10px' }}>
                                <span>$</span>
                                <input
                                    className="form-control"
                                    type="number"
                                    value={assertion.numericValue}
                                    onChange={this.handleChange}
                                />
                            </span>
                        );
                    case AssertionDescriptorDisplayValueTypeEnum.DateTime.Value:
                        return (
                            <input
                                type="date"
                                className={
                                    'form-control' +
                                    (assertion.dateTimeValue &&
                                    Common.isValidDate(assertion.dateTimeValue)
                                        ? ''
                                        : ' unselectClass')
                                }
                                value={Common.dateFormat(assertion.dateTimeValue)}
                                onChange={this.handleChange}
                            />
                        );
                    case AssertionDescriptorDisplayValueTypeEnum.Percentage.Value:
                        return (
                            <span>
                                <span>%</span>
                                <input
                                    className="form-control"
                                    type="number"
                                    name={assertion.assertionDescriptor!.guid}
                                    value={assertion.numericValue}
                                    onChange={this.handleChange}
                                />
                            </span>
                        );
                    case AssertionDescriptorDisplayValueTypeEnum.Number.Value:
                        break;
                    case AssertionDescriptorDisplayValueTypeEnum.Decimal.Value:
                        break;
                    case AssertionDescriptorDisplayValueTypeEnum.ShortText.Value:
                        return (
                            <input
                                className="form-control"
                                type="text"
                                maxLength={200}
                                name={assertion.assertionDescriptor!.guid}
                                value={assertion.textValue ?? ''}
                                onChange={this.handleChange}
                            />
                        );
                    case AssertionDescriptorDisplayValueTypeEnum.Narrative.Value:
                        if (this.props.narrativeFieldAsPopup && this.props.handleNarrativeFieldEdit)
                            return (
                                <button
                                    className="btn btn-no-bg"
                                    onClick={() => this.props.handleNarrativeFieldEdit!(assertion)}
                                    title={assertion.textValue}
                                >
                                    <i
                                        className={
                                            assertion.textValue
                                                ? 'fal fa-edit color-black'
                                                : 'fal fa-edit color-gray'
                                        }
                                    />
                                </button>
                            );
                        else
                            return (
                                <textarea
                                    className="form-control"
                                    name={assertion.assertionDescriptor!.guid}
                                    rows={3}
                                    value={assertion.textValue}
                                    onChange={this.handleChange}
                                />
                            );
                    case AssertionDescriptorDisplayValueTypeEnum.SSN.Value:
                        return (
                            <input
                                className="form-control"
                                type="text"
                                placeholder="XXX-XX-XXXX"
                                maxLength={11}
                                name={assertion.assertionDescriptor!.guid}
                                value={assertion.textValue}
                                onChange={this.handleChange}
                            />
                        );
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    };

    handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const valueSourceTypeId = this.props.assertionDescriptor.valueSourceType!.id;
        const valueDisplayTypeId = this.props.assertionDescriptor.valueDisplayType
            ? this.props.assertionDescriptor.valueDisplayType.id
            : 0;

        const multiValue =
            valueDisplayTypeId == AssertionDescriptorValueDisplayTypes.MultiSelect.Value;
        const entityAssertions = this.state.entityAssertions;
        if (!multiValue && this.state.entityAssertions.length > 0) {
            const entityAssertion = entityAssertions[0];
            const value = e.target.value;

            switch (valueSourceTypeId) {
                case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
                    if (value) entityAssertion.assertionDescriptorValue = { guid: value };
                    else entityAssertion.assertionDescriptorValue = undefined;
                    break;
                case AssertionDescriptorValueSourceTypeEnum.Boolean.Value:
                    if (value) entityAssertion.booleanValue = value == 'true' ? true : false;
                    else entityAssertion.booleanValue = undefined;
                    break;
                case AssertionDescriptorValueSourceTypeEnum.Text.Value:
                    switch (valueDisplayTypeId) {
                        case AssertionDescriptorValueDisplayTypes.Currency.Value:
                        case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                        case AssertionDescriptorValueDisplayTypes.Decimal.Value:
                        case AssertionDescriptorValueDisplayTypes.Number.Value:
                            entityAssertion.numericValue = parseFloat(value);
                            break;
                        case AssertionDescriptorValueDisplayTypes.DateTime.Value:
                            if (value && Common.isValidDate(value))
                                entityAssertion.dateTimeValue = value;
                            else entityAssertion.dateTimeValue = undefined;
                            break;
                        default:
                            entityAssertion.textValue = value;
                            break;
                    }
                    break;
                default:
                    break;
            }
        }

        this.setState({ entityAssertions: entityAssertions });
        this.props.refreshChange?.(entityAssertions, this.props.additionalIdentifierKey);
    };

    render() {
        return (
            <div>
                {this.props.editMode && this.state.entityAssertions.length > 0
                    ? this.getSingleValueEditControl()
                    : this.getValue(this.state.entityAssertions[0])
                    ? this.getValue(this.state.entityAssertions[0])
                          .split('\n')
                          .map((item, key: number) => {
                              return (
                                  <span key={key}>
                                      {item}
                                      <br />
                                  </span>
                              );
                          })
                    : null}
            </div>
        );
    }
}
