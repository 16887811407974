import * as React from 'react';
import { IValidation } from '../../interfaces/IError';
import { IAssertionDescriptorValueModel } from '../../interfaces/IAssertionDescriptor';
import { Modal } from 'react-bootstrap';

interface IAssertionDescriptorValueModalProps {
    displayDialog: boolean;
    handleAssertionDescriptorValueModalClose: () => void;
    addNewAssertionDescriptorValue: boolean;
    handleAssertionDescriptorValueModalConfirm: (
        assertionDescriptorValue: IAssertionDescriptorValueModel
    ) => void;
    assertionDescriptorValue: IAssertionDescriptorValueModel;
}

interface IAssertionDescriptorValueModalState {
    assertionDescriptorValue: IAssertionDescriptorValueModel;
    validation: IValidation;
}

export class AssertionDescriptorValueModal extends React.Component<
    IAssertionDescriptorValueModalProps,
    IAssertionDescriptorValueModalState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            assertionDescriptorValue: {},
            validation: {},
        };
    }

    componentDidUpdate(prevProps: IAssertionDescriptorValueModalProps) {
        if (this.props.assertionDescriptorValue != prevProps.assertionDescriptorValue) {
            this.setState({ assertionDescriptorValue: this.props.assertionDescriptorValue });
        }
    }

    changeValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        let assertionDescriptorValue = this.state.assertionDescriptorValue;
        let validation = this.state.validation;

        if (assertionDescriptorValue) {
            switch (name) {
                case 'text':
                    assertionDescriptorValue.text = value;
                    validation.text = [''];
                    break;
                case 'numericValue':
                    assertionDescriptorValue.numericValue = parseFloat(value);
                    validation.numericvalue = [''];
                    break;
                default:
                    break;
            }
            this.setState({
                assertionDescriptorValue: assertionDescriptorValue,
                validation: validation,
            });
        }
    };

    handleAssertionDescriptorValueModalConfirm = () => {
        if (
            this.state.assertionDescriptorValue.text == undefined ||
            this.state.assertionDescriptorValue.text.length == 0
        ) {
            let validation = this.state.validation;
            validation.text = ['This is a required field'];
            this.setState({
                validation: validation,
            });
            return;
        }
        this.props.handleAssertionDescriptorValueModalConfirm(this.state.assertionDescriptorValue);
    };

    render() {
        return (
            <Modal
                centered
                show={this.props.displayDialog}
                onHide={this.props.handleAssertionDescriptorValueModalClose}
                backdrop={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        {this.props.addNewAssertionDescriptorValue ? 'Add' : 'Update'} - Value
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-2">
                        <label className="control-label col-sm-3" htmlFor="text">
                            Text*
                        </label>
                        <input
                            type="text"
                            name="text"
                            className="form-control col-sm-8"
                            value={this.state.assertionDescriptorValue.text || ''}
                            onChange={this.changeValue}
                        />
                    </div>
                    <div className="row mb-2">
                        <label className="control-label col-sm-3" htmlFor="numericValue">
                            Numeric Value
                        </label>
                        <input
                            type="number"
                            pattern="^\d+(?:\.\d{1,2})?$"
                            name="numericValue"
                            className="form-control"
                            value={this.state.assertionDescriptorValue.numericValue || ''}
                            onChange={this.changeValue}
                        />
                        <span className="text-danger">{this.state.validation.numericvalue}</span>
                    </div>
                    <div className="mt-2">
                        <button
                            className="btn btn-default float-end"
                            onClick={this.props.handleAssertionDescriptorValueModalClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-orange float-end"
                            onClick={this.handleAssertionDescriptorValueModalConfirm}
                        >
                            Save
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
