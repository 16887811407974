import * as React from 'react';

import {
    DataScopesEnum,
    HelpKeys,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    UserRightsEnum,
} from '../../utilities/Constants';
import { INoteCategoryTypeModel, INoteModel } from '../../interfaces/INote';

import Authorization from '../../stores/Authorization';
import CheckmateApiClient from '../../services/apiClient';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { ILookupModel } from '../../interfaces/ILookup';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { NoteEditor } from '../../components/notes/NoteEditor';
import NoteHelper from '../../utilities/NoteHelper';
import { NoteList } from '../../components/notes/NoteList';
import Sort from '../../stores/Sort';
import ValidateUtils from '../../shared/validations';

const _apiClient = new CheckmateApiClient();

interface IZoneNotesProps {
    user: IUserModel;
    refreshIdentifier?: string;
}

interface IZoneNotesState {
    pendingResponse: boolean;
    validation: IValidation;
    noteCategoryTypes: INoteCategoryTypeModel[];
    allNotes: INoteModel[];
    originalNotes: INoteModel[];
    currentNote: INoteModel;
    openNoteEditor: boolean;
    zoneOrganizationGuid: string;
    helpText: ILookupModel[];
    loggedInUserDataScopeId: number;
}

export class ZoneNotes extends React.Component<IZoneNotesProps, IZoneNotesState> {
    constructor(props: any) {
        super(props);
        this.state = {
            pendingResponse: true,
            validation: {},
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            allNotes: [],
            originalNotes: [],
            noteCategoryTypes: [],
            openNoteEditor: false,
            zoneOrganizationGuid: '',
            helpText: [],
            loggedInUserDataScopeId: 0,
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.ZoneNotes, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);
        this.loadInitialData();
        this.getHelpText();
    }

    componentDidUpdate(prevProps: IZoneNotesProps) {
        if (
            this.props.refreshIdentifier &&
            prevProps.refreshIdentifier != this.props.refreshIdentifier
        ) {
            this.setState(
                {
                    pendingResponse: true,
                    validation: {},
                    currentNote: {
                        status: { id: NoteStatusTypes.Open },
                        purpose: { id: NotePurposeTypesEnum.Info },
                    },
                    allNotes: [],
                    originalNotes: [],
                    noteCategoryTypes: [],
                    openNoteEditor: false,
                    zoneOrganizationGuid: '',
                    loggedInUserDataScopeId: 0,
                },
                this.loadInitialData
            );
        }
    }

    loadInitialData = async () => {
        const noteCategoryTypes = await this.fetchNoteCategoryTypes();
        if (!noteCategoryTypes) return;
        //let noteCategoryTypeOptions: IMultiSelectOptions[] = [];
        //noteCategoryTypes.map((cat: ILookupModel) => {
        //    let item: IMultiSelectOptions = { label: cat.displayName!, value: cat.name!, id: cat.id };
        //    noteCategoryTypeOptions.push(item);
        //})

        const defaultOrganization = _apiClient.getDefaultUserOrganization();
        if (!defaultOrganization) return;

        let zoneNotes = await this.fetchNotes(defaultOrganization.guid!);
        if (!zoneNotes) zoneNotes = [];

        const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(
            this.props.user
        );

        this.setState({
            noteCategoryTypes: noteCategoryTypes,
            allNotes: zoneNotes,
            originalNotes: JSON.parse(JSON.stringify(zoneNotes)),
            zoneOrganizationGuid: defaultOrganization.guid!,
            pendingResponse: false,
            loggedInUserDataScopeId: loggedInUserDataScopeId,
        });
    };

    fetchNoteCategoryTypes = async () => {
        const response = await _apiClient.getZoneNoteCategoryTypes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchNotes = async (zoneOrganizationGuid: string) => {
        const response = await _apiClient.getNotes(NoteTypes.ZoneNote, zoneOrganizationGuid);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    handleCancelNoteEditor = () => {
        this.setState({
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            openNoteEditor: false,
        });
    };

    onSaveNoteComplete = (noteItem: INoteModel) => {
        let matchFound = false;
        let allNotes = this.state.allNotes;
        for (let i = 0; i < allNotes.length; i++) {
            if (allNotes[i].guid == noteItem.guid) {
                matchFound = true;
                allNotes[i] = JSON.parse(JSON.stringify(noteItem));
                break;
            }
        }
        if (!matchFound) allNotes.push(JSON.parse(JSON.stringify(noteItem)));

        allNotes = allNotes.sort(Sort.compareDate('modifiedDate', undefined, 'desc'));
        this.setState({ allNotes: allNotes, openNoteEditor: false });
    };

    handleEditNote = (noteGuidToEdit: string) => {
        const validation: IValidation = {};

        if (!this.state.allNotes) {
            validation.model = [DisplayMessages.UnexpectedError];
            this.setState({ pendingResponse: false, validation: validation });
            return;
        }

        const currentNote = this.state.allNotes.find((x) => x.guid == noteGuidToEdit);
        if (!currentNote) {
            validation.model = [DisplayMessages.UnexpectedError];
            this.setState({ pendingResponse: false, validation: validation });
            return;
        }

        this.setState({
            currentNote: JSON.parse(JSON.stringify(currentNote)),
            openNoteEditor: true,
        });
    };

    refreshNotes = (notes: INoteModel[]) => {
        this.setState({ allNotes: notes });
    };

    onClickAddNote = () => {
        const currentNote: INoteModel = {
            type: { id: NoteTypes.ZoneNote },
            status: { id: NoteStatusTypes.Open },
            organizationGuid: this.state.zoneOrganizationGuid,
            purpose: { id: NotePurposeTypesEnum.Info },
        };

        this.setState({ openNoteEditor: true, currentNote: currentNote });
    };

    getHelpText = async () => {
        this.setState({ pendingResponse: true, validation: {} });
        const response = await _apiClient.getOrganizationSettings();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (response.payload) {
            const organizationSettings = response.payload;
            const match = organizationSettings.find(
                (x) => x.settingName == HelpKeys.ZoneNotes.Name
            );
            if (match)
                this.setState({
                    helpText: [
                        {
                            id: match.id,
                            name: 'ZoneNotes',
                            displayName: 'Zone Notes',
                            description: match.settingValue,
                        },
                    ],
                });
        }
    };

    render() {
        console.log(this.state.allNotes);
        if (this.state.pendingResponse) return <Loader />;

        if (
            (Authorization.userHasRight(UserRightsEnum.AddZoneNote, this.props.user) ||
                Authorization.userHasRight(UserRightsEnum.EditZoneNote, this.props.user)) &&
            this.state.openNoteEditor
        )
            return (
                <NoteEditor
                    readOnly={NoteHelper.isNoteReadOnly(
                        this.state.currentNote,
                        this.props.user,
                        Authorization.userHasRight(UserRightsEnum.ViewZoneNotes, this.props.user),
                        Authorization.userHasRight(UserRightsEnum.EditZoneNote, this.props.user) &&
                            (this.state.loggedInUserDataScopeId !==
                                DataScopesEnum.LocalBasic.Value ||
                                this.state.currentNote.allowLocalEdit == true),
                        Authorization.userHasRight(UserRightsEnum.AddZoneNote, this.props.user)
                    )}
                    user={this.props.user}
                    noteCategoryTypes={this.state.noteCategoryTypes}
                    currentNote={this.state.currentNote}
                    handleSaveComplete={this.onSaveNoteComplete}
                    handleCancel={this.handleCancelNoteEditor}
                />
            );

        return (
            <div>
                <div>
                    <span className="text-danger">{this.state.validation.model}</span>
                </div>
                {/*Authorization done in componentDidMount*/}
                <NoteList
                    title="Zone Notes"
                    user={this.props.user}
                    notes={this.state.allNotes}
                    noteCategoryTypes={this.state.noteCategoryTypes}
                    handleAddNote={this.onClickAddNote}
                    handleViewEditNote={this.handleEditNote}
                    refreshParent={this.refreshNotes}
                    allowView={Authorization.userHasRight(
                        UserRightsEnum.ViewZoneNotes,
                        this.props.user
                    )}
                    allowEdit={
                        Authorization.userHasRight(UserRightsEnum.EditZoneNote, this.props.user) &&
                        (this.state.loggedInUserDataScopeId !== DataScopesEnum.LocalBasic.Value ||
                            this.state.currentNote.allowLocalEdit == true)
                    }
                    allowDelete={Authorization.userHasRight(
                        UserRightsEnum.DeleteZoneNote,
                        this.props.user
                    )}
                    allowAddNew={Authorization.userHasRight(
                        UserRightsEnum.AddZoneNote,
                        this.props.user
                    )}
                />
            </div>
        );
    }
}
