import { IUserModel } from '../../interfaces/IUser';
import { useLocation } from 'react-router';
import { StrategicPlan } from '../strategy/StrategicPlan';

function StrategicPlanWrapper(props: { user: IUserModel }) {
    const { key } = useLocation();
    return <StrategicPlan user={props.user} refreshIdentifier={key} />;
}

export default StrategicPlanWrapper;
