import * as React from 'react';
import { IReportParametersModel, IReportTypeModel } from '../../interfaces/Report/IReport';
import ApiClient from '../../services/apiClient';
import { IValidation } from '../../interfaces/IError';
import { DateTypesEnum } from '../../utilities/Constants';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Common from '../../stores/Common';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import { IUserModel } from '../../interfaces/IUser';
import ValidateUtils from '../../shared/validations';
import { Loader } from '../shared/Loader';
import { config } from '../../config/config';
import Html5ReportViewer from './Html5ReportViewer';

let _apiClient = new ApiClient();

interface IViewExportReportProps {
    user: IUserModel;
    id?: number;
}

interface IViewExportReportState {
    pendingResponse: boolean;
    validation: IValidation;
    reportTypeDetail: IReportTypeModel;
    reportParameters: IReportParametersModel;
    initReportParameters: IReportParametersModel;
    typeValue?: string;
    addEditComment: boolean;
    showSettingsModal: boolean;
    refreshReport: boolean;
    renderReportViewer?: boolean;
}

export class ViewExportReport extends React.Component<
    IViewExportReportProps,
    IViewExportReportState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: true,
            validation: {},
            reportTypeDetail: { id: 0 },
            reportParameters: {},
            initReportParameters: {},
            addEditComment: false,
            showSettingsModal: true,
            refreshReport: false,
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.ReportDetail, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        if (this.props.id) {
            this.loadReportDetail(this.props.id);
        } else {
            window.location.assign('/');
        }
    }

    loadReportDetail = async (typeId: number) => {
        var response = await _apiClient.getReportTypeDetail(typeId);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.httpResponse.status == 403) {
            window.location.assign(LocalRoutes.AccessDenied);
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        let reportParameters: IReportParametersModel = {
            reportType: { id: typeId },
        };

        let initReportParameters: IReportParametersModel = {
            reportType: { id: typeId },
        };

        if (response.payload) {
            this.setState(
                {
                    reportTypeDetail: response.payload,
                    pendingResponse: false,
                    reportParameters: reportParameters,
                    initReportParameters: initReportParameters,
                },
                this.autoViewDownloadReport
            );
        }
    };

    handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let reportParameters = this.state.reportParameters;
        reportParameters.commentText = event.target.value;
        this.setState({ reportParameters: reportParameters });
    };

    cancelSettingsModal = () => {
        this.setState({
            reportParameters: JSON.parse(JSON.stringify(this.state.initReportParameters)),
            showSettingsModal: false,
            validation: {},
            refreshReport: false,
        });
    };

    refreshReport = () => {
        if (
            this.state.reportParameters.date &&
            ((this.state.reportParameters.date.startDate &&
                !this.state.reportParameters.date.endDate) ||
                (!this.state.reportParameters.date.startDate &&
                    this.state.reportParameters.date.endDate))
        ) {
            let validation = this.state.validation;
            validation.model = [DisplayMessages.BothStartAndEndDateRequired];
            this.setState({ validation: validation });
            return;
        }

        this.setState({
            initReportParameters: JSON.parse(JSON.stringify(this.state.reportParameters)),
            refreshReport: true,
            showSettingsModal: false,
            renderReportViewer: true,
        });
    };

    autoViewDownloadReport = () => {
        if (
            this.state.reportTypeDetail.viewInUI &&
            !this.state.reportTypeDetail.availableReportOptions
        )
            this.refreshReport();
        else if (
            !this.state.reportTypeDetail.viewInUI &&
            !this.state.reportTypeDetail.allowComments &&
            !this.state.reportTypeDetail.availableReportOptions
        )
            this.generateAndDownloadReport();
    };

    handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>, dateType: number) => {
        let reportParameters = this.state.reportParameters;
        if (!reportParameters.date) reportParameters.date = { type: 0 };
        if (dateType == DateTypesEnum.StartDate) {
            reportParameters.date!.startDate = event.target.value;
            this.setState({ reportParameters: reportParameters });
        } else if (dateType == DateTypesEnum.EndDate) {
            reportParameters.date!.endDate = event.target.value;
            this.setState({ reportParameters: reportParameters });
        }
    };

    handleCheckedChange(event: React.ChangeEvent<HTMLInputElement>) {
        let reportParameters = this.state.reportParameters;
        if (!reportParameters.options) reportParameters.options = {};

        switch (event.target.name) {
            case 'chkIncludePastProjected':
                reportParameters.options.includePastProjected = event.target.checked;
                break;
            case 'chkIncludeNotes':
                reportParameters.options.includeNotes = event.target.checked;
                break;
            case 'chkIncludeTasks':
                reportParameters.options.includeTasks = event.target.checked;
                break;
            default:
        }

        this.setState({ reportParameters: reportParameters });
    }

    generateAndDownloadReport = async () => {
        let validation: IValidation = {};
        if (
            this.state.reportTypeDetail.availableReportOptions &&
            this.state.reportTypeDetail.availableReportOptions.includeDateRange &&
            !(
                this.state.reportParameters &&
                this.state.reportParameters.date &&
                ((this.state.reportParameters.date.startDate &&
                    Common.isValidDate(this.state.reportParameters.date.startDate)) ||
                    (this.state.reportParameters.date.endDate &&
                        Common.isValidDate(this.state.reportParameters.date.endDate)))
            )
        ) {
            validation.model = ['Enter a valid date range'];
            this.setState({ validation: validation });
            return;
        }

        this.setState({ pendingResponse: true, validation: validation });

        var xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + ApiRoutes.ExportReport, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                let reportParameters = this.state.reportParameters;
                reportParameters.commentText = '';
                this.setState({
                    pendingResponse: false,
                    reportParameters: reportParameters,
                    addEditComment: false,
                    showSettingsModal: false,
                });

                var fileName = 'Report.pdf';
                var blob = xhr.response;

                var contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    let contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (var i = 0; i < contentDispositionItems.length; i++) {
                            let currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                var n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                let validation = this.state.validation;
                validation.model = [DisplayMessages.ReportGenerateError];
                this.setState({ pendingResponse: false, validation: validation });
            }
        };

        xhr.send(JSON.stringify(this.state.reportParameters));
    };

    getDateRangeText = () => {
        if (
            !this.state.reportParameters.date ||
            (!this.state.reportParameters.date.startDate &&
                !this.state.reportParameters.date.endDate)
        )
            return '';

        if (
            this.state.reportParameters.date.startDate &&
            Common.isValidDate(this.state.reportParameters.date.startDate) &&
            this.state.reportParameters.date.endDate &&
            Common.isValidDate(this.state.reportParameters.date.endDate)
        )
            return (
                Common.dateFormat(this.state.reportParameters.date.startDate) +
                ' - ' +
                Common.dateFormat(this.state.reportParameters.date.endDate)
            );
        else return '';
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;
        return (
            <div>
                <h1 style={{ marginLeft: '10px' }}>
                    {this.state.reportTypeDetail.displayName}
                    {this.state.reportTypeDetail.availableReportOptions &&
                    this.state.reportTypeDetail.availableReportOptions.includeDateRange ? (
                        <span>
                            {this.getDateRangeText() ? (
                                <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    &nbsp;&nbsp;&nbsp;Date Range
                                </span>
                            ) : null}
                            &nbsp;
                            <span style={{ fontSize: '12px' }}> {this.getDateRangeText()}</span>
                        </span>
                    ) : null}
                    {this.state.reportTypeDetail.availableReportOptions &&
                    this.state.reportTypeDetail.availableReportOptions.includePastProjected ? (
                        <span style={{ fontSize: '12px' }}>
                            &nbsp;&nbsp;(Include Past Projected Settlements)
                        </span>
                    ) : null}
                    <span className="btn btn-blue float-end">
                        {' '}
                        <Link to={LocalRoutes.UserReportList} style={{ color: 'white' }}>
                            View Existing Reports
                        </Link>
                    </span>
                    {this.state.reportTypeDetail.allowComments ? (
                        this.state.addEditComment ? (
                            <span>
                                <button
                                    className="btn btn-orange float-end text-gray"
                                    onClick={() => this.setState({ addEditComment: false })}
                                >
                                    Hide Comments
                                </button>
                            </span>
                        ) : (
                            <button
                                className="btn btn-orange float-end text-gray"
                                onClick={() => this.setState({ addEditComment: true })}
                            >
                                Comments
                            </button>
                        )
                    ) : null}
                    {this.state.reportTypeDetail.viewInUI ? (
                        <button
                            className="btn btn-no-bg float-end text-gray"
                            onClick={this.generateAndDownloadReport}
                        >
                            <i className="fal fa-file-chart-column" />
                            &nbsp;Report
                        </button>
                    ) : null}
                    {this.state.reportTypeDetail.availableReportOptions ? (
                        <button
                            className="btn btn-no-bg float-end text-gray"
                            onClick={() =>
                                this.setState({ showSettingsModal: true, refreshReport: false })
                            }
                        >
                            <i className="fal fa-user-cog" />
                            &nbsp;Settings
                        </button>
                    ) : null}
                </h1>
                <div>
                    <span className="text-danger">{this.state.validation.model}</span>
                </div>
                {this.state.addEditComment ? (
                    <div className="form-group" style={{ paddingLeft: '10px' }}>
                        <label className="control-label mb-2" htmlFor="comments">
                            Comments
                        </label>
                        <textarea
                            className="form-control"
                            value={this.state.reportParameters.commentText}
                            onChange={this.handleCommentChange}
                            rows={3}
                        />
                    </div>
                ) : null}
                {!this.state.reportTypeDetail.viewInUI &&
                !this.state.reportTypeDetail.allowComments &&
                !this.state.reportTypeDetail.availableReportOptions ? (
                    <p className="margin-left">Your report will be automatically downloaded.</p>
                ) : null}

                {this.state.reportTypeDetail.viewInUI &&
                (this.state.renderReportViewer ||
                    !this.state.reportTypeDetail.availableReportOptions) ? (
                    <Html5ReportViewer
                        scale={1.3}
                        addSpaceForCommentOnTop={this.state.addEditComment}
                        reportName={this.state.reportTypeDetail.viewTemplateName!}
                        reportParameters={this.state.reportParameters}
                        refreshReport={false}
                    />
                ) : null}

                {this.state.showSettingsModal &&
                this.state.reportTypeDetail.availableReportOptions ? (
                    <Modal
                        centered
                        show={true}
                        onHide={() => {
                            this.setState({ showSettingsModal: false });
                        }}
                        backdrop={false}
                        dialogClassName="board-settings-dialog"
                    >
                        <Modal.Header>
                            <Modal.Title> Report Parameters & Settings </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                            <div className="container-fluid">
                                <div className="col-sm-12">
                                    {this.state.reportTypeDetail.availableReportOptions
                                        .includeDateRange ? (
                                        <div className="margin-top-sm">
                                            <span>Start Date</span>&nbsp;&nbsp;
                                            <input
                                                type="date"
                                                className={
                                                    this.state.reportParameters.date &&
                                                    this.state.reportParameters.date.startDate &&
                                                    Common.isValidDate(
                                                        this.state.reportParameters.date.startDate
                                                    )
                                                        ? 'form-control'
                                                        : 'unselectClass form-control'
                                                }
                                                name="startDate"
                                                value={
                                                    this.state.reportParameters.date &&
                                                    this.state.reportParameters.date.startDate
                                                        ? Common.dateFormat(
                                                              this.state.reportParameters.date
                                                                  .startDate
                                                          )
                                                        : ''
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleChangeDate(
                                                        e,
                                                        DateTypesEnum.StartDate
                                                    );
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                    {this.state.reportTypeDetail.availableReportOptions
                                        .includeDateRange ? (
                                        <div className="margin-top-sm">
                                            <span>End Date</span>&nbsp;&nbsp;
                                            <input
                                                type="date"
                                                name="endDate"
                                                className={
                                                    this.state.reportParameters.date &&
                                                    this.state.reportParameters.date.endDate &&
                                                    Common.isValidDate(
                                                        this.state.reportParameters.date.endDate
                                                    )
                                                        ? 'form-control'
                                                        : 'unselectClass form-control'
                                                }
                                                value={
                                                    this.state.reportParameters.date &&
                                                    this.state.reportParameters.date.endDate
                                                        ? Common.dateFormat(
                                                              this.state.reportParameters.date
                                                                  .endDate
                                                          )
                                                        : ''
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleChangeDate(e, DateTypesEnum.EndDate);
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                    {this.state.reportTypeDetail.availableReportOptions
                                        .includePastProjected ? (
                                        <div className="margin-top-sm">
                                            <input
                                                name="chkIncludePastProjected"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={
                                                    this.state.reportParameters.options &&
                                                    this.state.reportParameters.options
                                                        .includePastProjected
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleCheckedChange(e);
                                                }}
                                            />
                                            <span>&nbsp;&nbsp;Include Past Projected</span>
                                        </div>
                                    ) : null}
                                    {this.state.reportTypeDetail.availableReportOptions
                                        .includeNotes ? (
                                        <div className="margin-top-sm">
                                            <input
                                                name="chkIncludeNotes"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={
                                                    this.state.reportParameters.options &&
                                                    this.state.reportParameters.options.includeNotes
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleCheckedChange(e);
                                                }}
                                            />
                                            <span>&nbsp;&nbsp;Include Significant Changes</span>
                                        </div>
                                    ) : null}
                                    {this.state.reportTypeDetail.availableReportOptions
                                        .includeTasks ? (
                                        <div className="margin-top-sm">
                                            <input
                                                name="chkIncludeTasks"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={
                                                    this.state.reportParameters.options &&
                                                    this.state.reportParameters.options.includeTasks
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleCheckedChange(e);
                                                }}
                                            />
                                            <span>&nbsp;&nbsp;Include Tactics</span>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <hr />
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.cancelSettingsModal}
                                >
                                    Cancel
                                </button>
                                {this.state.reportTypeDetail.viewInUI ? (
                                    <button
                                        className="btn btn-orange float-end"
                                        onClick={this.refreshReport}
                                    >
                                        Go
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-orange float-end"
                                        onClick={this.generateAndDownloadReport}
                                    >
                                        Go
                                    </button>
                                )}
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}
            </div>
        );
    }
}
