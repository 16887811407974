import * as React from 'react';
import { Navigate, Link } from 'react-router-dom';

import { LocalRoutes } from '../../utilities/LocalRoutes';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { ILoginResponse } from '../../interfaces/ILoginResponse';
import { Modal } from 'react-bootstrap';
import { ILoginUser } from '../../interfaces/IAccount';
import { IValidation } from '../../interfaces/IError';
import { IDialogModel } from '../../interfaces/IDialog';
import ValidateUtils from '../../shared/validations';
import { Loader } from '../../components/shared/Loader';
import { CheckmateDialog } from '../../components/shared/dialog';
import CheckmateApiClient from '../../services/apiClient';
import { IApiResponse } from '../../tpi.apiclient';
import { Help } from '../../components/shared/Help';
import { ApplicationHelpType } from '../../utilities/Constants';

let _apiClient = new CheckmateApiClient();

interface IAccountLoginProps {
    guid?: string;
}

interface IAccountLoginState {
    redirect: boolean;
    redirectTo: string;
    loginUser: ILoginUser;
    validation: IValidation;
    showLoginDialog: boolean;
    displayMessageDialog: IDialogModel;
    displayRequiredDialog: IDialogModel;
    displayUpdateDialog: boolean;
    pendingResponse: boolean;
    userId: string;
    displayOnetimecodeDialog: boolean;
    onetimecode: string;
    isAuthenticated: boolean;
}

export class Login extends React.Component<IAccountLoginProps, IAccountLoginState> {
    constructor(props: any) {
        super(props);

        this.state = {
            redirect: false,
            redirectTo: '/',
            loginUser: {},
            validation: {},
            showLoginDialog: true,
            displayMessageDialog: { isShowingModal: false },
            displayRequiredDialog: { isShowingModal: false },
            pendingResponse: false,
            displayUpdateDialog: false,
            userId: '',
            displayOnetimecodeDialog: false,
            onetimecode: '',
            isAuthenticated: false,
        };
    }

    changeValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setChangedValue(name, value);
    };

    setChangedValue(name: string, value: any) {
        let loginUser = this.state.loginUser;
        let validation = this.state.validation;
        let onetimecode = this.state.onetimecode;

        switch (name) {
            case 'email':
                loginUser.userName = value;
                validation.username = [];
                break;
            case 'password':
                loginUser.password = value;
                validation.password = [];
                validation.model = [];
                break;
            case 'onetimecode':
                var data = value as string;
                if (data) {
                    data = data.replace(/[^\d.-]/g, ''); // this must be done because if we change the type= 'number ' then the size of the field will be too small
                    if (data.length > 6) {
                        onetimecode = data.substring(0, 6);
                    } else {
                        onetimecode = data;
                    }
                }
                validation.onetimecode = [];
                break;
            default:
                break;
        }

        this.setState({
            loginUser: loginUser,
            validation: validation,
            onetimecode: onetimecode,
        });
    }

    handleClose = () => {
        let redirectTo = '/';

        if (this.props.guid) {
            redirectTo = LocalRoutes.AcceptInvitation.replace(':guid', this.props.guid);
        }
        this.setState({
            redirect: true,
            redirectTo: redirectTo,
        });
    };

    handleLogin = async () => {
        this.setState({ pendingResponse: true });

        let redirectTo = '/';

        // let redirectTo = LocalRoutes.Home;

        if (this.state.loginUser.userName && this.state.loginUser.userName.length > 256) {
            this.setState({
                displayMessageDialog: {
                    isShowingModal: true,
                    title: ' ',
                    body: 'User Name cannot exceed 256 characters ',
                    dialogClassName: 'account-update-dialog ',
                    cancelText: 'OK',
                    closeButtonClassName: 'btn btn-default float-end',
                },
                showLoginDialog: false,
                redirectTo: redirectTo,
                pendingResponse: false,
                displayUpdateDialog: true,
            });
            return;
        }
        if (this.state.loginUser.password && this.state.loginUser.password.length > 256) {
            this.setState({
                displayMessageDialog: {
                    isShowingModal: true,
                    title: ' ',
                    body: 'User Password cannot exceed 256 characters ',
                    dialogClassName: 'account-update-dialog',
                    cancelText: 'OK',
                    closeButtonClassName: 'btn btn-default float-end',
                },
                showLoginDialog: false,
                redirectTo: redirectTo,
                pendingResponse: false,
                displayUpdateDialog: true,
            });
            return;
        }

        if (this.props.guid != undefined) {
            var invitationResponse = await _apiClient.loginAcceptInvitation(
                this.state.loginUser,
                this.props.guid
            );
            if (invitationResponse.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(
                        invitationResponse.errors,
                        invitationResponse.errorMessage
                    ),
                    pendingResponse: false,
                });
            } else {
                this.setState({
                    displayMessageDialog: {
                        isShowingModal: true,
                        title: ' ',
                        body: 'User Account updated successfully. ',
                        dialogClassName: 'account-update-dialog ',
                        cancelText: 'OK ',
                        closeButtonClassName: 'btn btn-default float-end ',
                    },
                    showLoginDialog: false,
                    redirectTo: redirectTo,
                    pendingResponse: false,
                    displayUpdateDialog: true,
                });
            }
            return;
        }
        var loginResponse = this.state.displayOnetimecodeDialog
            ? await _apiClient.loginVerifyTwoFactorCode(
                  this.state.loginUser,
                  this.state.onetimecode
              )
            : await _apiClient.login(this.state.loginUser);

        if (loginResponse && loginResponse.payload) {
            if (loginResponse.payload.invalidTwoFactorVerification == true) {
                this.setState({
                    displayMessageDialog: {
                        isShowingModal: true,
                        title: ' ',
                        body: DisplayMessages.TwoFactorTokenInvalid,
                        cancelText: 'OK ',
                        dialogClassName: 'login-username-info-dialog ',
                        closeButtonClassName: 'btn btn-default float-end ',
                    },
                    displayOnetimecodeDialog: false,
                    pendingResponse: false,
                    displayUpdateDialog: true,
                    userId: loginResponse.payload.userId,
                });
                return;
            }
            if (loginResponse.payload.requiresTwoFactorVerification == true) {
                this.setState({
                    displayOnetimecodeDialog: true,
                    pendingResponse: false,
                    displayUpdateDialog: true,
                    userId: loginResponse.payload.userId,
                });
                return;
            }
            if (loginResponse.payload.requiresAcceptingTermsOfService == true) {
                this.setState({
                    displayRequiredDialog: {
                        isShowingModal: true,
                        title: 'User Acknowledgment',
                        body:
                            DisplayMessages.AcceptTermsOfServiceRequest1 +
                            '\n' +
                            DisplayMessages.AcceptTermsOfServiceRequest2,
                        dialogClassName: 'account-accept-terms-dialog ',
                        confirmText: 'I Accept ',
                        cancelText: 'Cancel ',
                        closeButtonClassName: 'btn btn-default float-end ',
                        confirmButtonClassName: 'btn btn-orange float-end ',
                    },
                    showLoginDialog: true,
                    redirectTo: redirectTo,
                    pendingResponse: false,
                    displayUpdateDialog: true,
                    userId: loginResponse.payload.userId,
                });
                return;
            }
        }
        // else if(loginResponse.payload.invalidInvitation) {
        // }
        this.handleResponseAfterLogin(loginResponse, redirectTo);
    };

    handleAcceptTermsOfService = async () => {
        if (!this.state.userId) return;
        var response = await _apiClient.acceptTermsOfService(
            this.state.userId,
            this.state.loginUser
        );
        this.handleResponseAfterLogin(response, '/ ');
    };

    handleResponseAfterLogin = (response: IApiResponse<ILoginResponse>, redirectTo: string) => {
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
        } else {
            this.setState({ pendingResponse: false });
            this.handleStyle();

            setTimeout(
                function (this: any) {
                    //Start the timer
                    this.setState({
                        isAuthenticated: true,
                        showLoginDialog: false,
                        redirect: true,
                        redirectTo: redirectTo,
                    });
                }.bind(this),
                1000
            );
        }
    };

    handleConfirm = () => {
        /*         if (this.state.displayRequiredDialog) {
                    this.setState({
                        redirect: true,
                        redirectTo: '/'
                    });
                    return;
                } */
        let displayMessageDialog = this.state.displayMessageDialog;
        displayMessageDialog.isShowingModal = false;
        this.setState({ displayMessageDialog: displayMessageDialog });
    };

    handleRejectRequired = () => {
        let displayRequiredDialog = this.state.displayRequiredDialog;
        displayRequiredDialog.isShowingModal = false;
        let validation = this.state.validation;
        validation.termsOfService = ['rejected'];
        this.setState({
            displayRequiredDialog: displayRequiredDialog,
            validation: validation,
            displayMessageDialog: {
                isShowingModal: true,
                title: ' ',
                body: DisplayMessages.AcceptTermsOfServiceRequiredLogin,
                cancelText: 'OK',
                dialogClassName: 'login-username-info-dialog',
                closeButtonClassName: 'btn btn-default float-end ',
            },
        });
    };

    handleStyle = () => {
        document.getElementsByClassName('login-panel ')[0].classList.add('set-opacity');
        document.getElementsByClassName('login-sidebar ')[0].classList.add('set-opacity');
        setTimeout(function () {
            document
                .getElementsByClassName('login-rotation ')[0]
                .classList.add('set-transform-rotate');
            document
                .getElementsByClassName('login-translation ')[0]
                .classList.add('set-transform-translate');
        }, 250);
    };

    handleVerifyTwoFactor = async () => {
        if (this.state.onetimecode.length == 0) {
            let validation = this.state.validation;
            validation.onetimecode = ['Code is Required'];
            this.setState({ validation: validation });
        } else {
            await this.handleLogin();
        }
    };

    handleCloseOnetimecode = () => {
        this.setState({ displayOnetimecodeDialog: false, onetimecode: '', validation: {} });
    };

    render() {
        if (this.state.redirect && this.state.isAuthenticated) {
            return <Navigate to={this.state.redirectTo} state={{ isAuthenticated: true }} />;
        }

        return (
            <div className="login-container">
                <img className="app-logo" src="" />
                <svg className="login-background">
                    <g className="login-translation">
                        <g className="login-rotation">
                            <path
                                d="M 0,0 L 1,0 1,1 0,1 Z M 0,0 L -1,0 -1,-1 0,-1 Z"
                                className="login-check"
                            />
                        </g>
                    </g>
                </svg>
                <div className="login-panel col-sm-2 px-3">
                    {this.state.pendingResponse ? <Loader /> : null}
                    <div className="logo-container">
                        <img className="logo-img rounded" src="/images/ckmt_login.svg" />
                    </div>
                    <div className="form-group">
                        <span className="text-danger">{this.state.validation.model}</span>
                        <br />
                        <label className="col-form-label">
                            Username*
                            <Help
                                type={ApplicationHelpType.Info}
                                title="Username"
                                helpText={DisplayMessages.LoginUsernameInfo}
                            />
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            name="email"
                            value={this.state.loginUser.userName || ''}
                            onChange={this.changeValue}
                        />
                        <span className="text-danger">{this.state.validation.username}</span>
                    </div>
                    <div className="form-group">
                        <label className="col-form-label">Password*</label>
                        <input
                            className="form-control"
                            type="password"
                            name="password"
                            value={this.state.loginUser.password || ''}
                            onChange={this.changeValue}
                        />
                        <span className="text-danger">{this.state.validation.password}</span>
                        <Link className="float-end" to={LocalRoutes.ForgotPassword}>
                            Forgot password?
                        </Link>
                    </div>
                    <div className="form-group">
                        <button
                            className="btn btn-black full-width vertical-margin-lg"
                            onClick={this.handleLogin}
                        >
                            Login
                        </button>
                    </div>
                    <CheckmateDialog
                        isShowingModal={this.state.displayMessageDialog.isShowingModal}
                        title={this.state.displayMessageDialog.title}
                        body={this.state.displayMessageDialog.body}
                        handleClose={this.handleConfirm}
                        dialogClassName={this.state.displayMessageDialog.dialogClassName}
                        cancelText={this.state.displayMessageDialog.cancelText}
                        closeButtonClassName={this.state.displayMessageDialog.closeButtonClassName}
                    />

                    <CheckmateDialog
                        isShowingModal={this.state.displayRequiredDialog.isShowingModal}
                        title={this.state.displayRequiredDialog.title}
                        body={this.state.displayRequiredDialog.body}
                        handleClose={this.handleRejectRequired}
                        handleConfirm={this.handleAcceptTermsOfService}
                        dialogClassName={this.state.displayRequiredDialog.dialogClassName}
                        confirmText={this.state.displayRequiredDialog.confirmText}
                        cancelText={this.state.displayRequiredDialog.cancelText}
                        closeButtonClassName={this.state.displayRequiredDialog.closeButtonClassName}
                        confirmButtonClassName={
                            this.state.displayRequiredDialog.confirmButtonClassName
                        }
                    />

                    <Modal
                        show={this.state.displayOnetimecodeDialog}
                        dialogClassName="onetimecode-dialog centered-modal"
                        contentClassName="onetimecode-dialog-content"
                        onHide={this.handleClose}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Two Factor Authentication</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{DisplayMessages.TwoFactorSubtitle}</p>
                            <>
                                <span className="text-danger">
                                    {this.state.validation.onetimecode}
                                </span>
                                <label className="control-label" htmlFor="onetimecode">
                                    Two Factor Code*
                                </label>
                                <input
                                    className="form-control mb-3"
                                    type="text"
                                    pattern="[0-9]"
                                    autoComplete="one-time-code"
                                    name="onetimecode"
                                    value={this.state.onetimecode || ''}
                                    onChange={this.changeValue}
                                />
                            </>
                            <div>
                                <button
                                    className="btn btn-black full-width vertical-margin"
                                    onClick={this.handleVerifyTwoFactor}
                                >
                                    Verify Code
                                </button>
                                <button
                                    className="btn btn-default float-end vertical-margin-lg"
                                    onClick={this.handleCloseOnetimecode}
                                >
                                    Cancel
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                <div className="login-sidebar"></div>
            </div>
        );
    }
}
