export const LocalRoutes = {
    Root: '/',
    Home: '/Home',
    CreateInvitation: '/invitation',
    AcceptInvitation: '/user/acceptinvitation/:guid',
    RegisterWithInvitation: '/invitation/register/:guid',

    Zones: '/zones',
    ZoneDetail: '/zone/:guid',
    ZoneNew: '/zone',

    OrganizationDetail: '/organization/:guid',

    Login: '/account/login',
    LoginAdvanced: '/account/login/:guid',
    Logout: '/account/logout',
    ForgotPassword: '/account/forgotpassword',
    ResetPassword: '/account/resetpassword',

    Users: '/users',
    UserDetail: '/user/:guid',
    UserTasks: '/tasks',
    UserReportList: '/userreports',
    UserZoneProfile: '/userzoneprofile',
    TaskBarSetting: '/taskbarsettings',

    AssertionDescriptors: '/assertiondescriptors',
    AssertionDescriptorNew: '/assertiondescriptor',
    AssertionDescriptorDetail: '/assertiondescriptor/:guid',

    PackageSettlementDetailExportID: '/packagesettlementDetailexport/',
    PackageSettlementDetailExport: '/packagesettlementDetailexport/:guid',

    Cases: '/cases',
    CaseFileNew: '/case/new',
    CaseFile: '/case/:guid',
    CaseEasyUpdate: '/easyupdate',
    CasePlan: '/caseplan/:guid',
    CasePlanTaskDetail: '/caseplantask/:caseguid/:caseplanguid/:taskguid',
    WorkProductDetail: '/workproduct/:entityguid/:entitytypeid/:documentguid?',

    PackageSettlements: '/packagesettlements',
    PackageSettlementDetails: '/packagesettlement/:guid',
    PackageSettlementNew: '/packagesettlement/',

    ReportTypeList: '/reporttypes',
    ThreatReport: '/threatreport',
    ReportDetail: '/reports/:id',
    AutoExportReport: '/autoexportreport/:id',
    Query: '/query',
    Query2: '/query2',
    QueryLegacy: '/query-legacy',
    ZoneNotes: '/zonenotes',

    // Zone Specific Local Routes. This will match dbo.OrganizationReportSetting.Url or dbo.ReportType.Url
    WeeklyRecap: '/weeklyrecap',
    MonthlyRecap: '/monthlyrecap',

    Trends: '/trends',
    KpiReport: '/kpireport',

    Board: '/board',
    BoardCaseDetail: '/boardcasedetail',
    BoardViewDetail: '/boardviewdetail',

    Experts: '/experts',
    ExpertCreate: '/experts/new',
    ExpertDetail: '/experts/:id',

    CaseExpertDetail: '/caseexpert/:id',

    AccessDenied: '/accessdenied',
    StrategicPlan: '/strategicplan',
    StrategyExecutionPlan: '/strategyexecutionplan',
    Strategy: '/strategy/:type',

    PlaintiffFirmStrategyDetail: '/plaintifffirmstrategydetail/:guid',
    JurisdictionStrategyDetail: '/jurisdictionstrategydetail/:guid',
    LocalCounselStrategyDetail: '/localcounselstrategydetail/:guid',

    PlaintiffFirmStrategyNew: '/plaintifffirmstrategydetail/',
    JurisdictionStrategyNew: '/jurisdictionstrategydetail/',
    LocalCounselStrategyNew: '/localcounselstrategydetail/',

    Projects: '/projects',
    ProjectDetail: '/project/:guid',
    ProjectNew: '/project/',
    MeetingAgenda: '/meetingagenda/:guid',

    Snapshot: '/snapshot',
    Alerts: '/alerts/:type',
    ViewPDF: '/viewpdf',
    Tutorials: '/tutorials',
};
