import CheckmateSelect from '../../components/shared/CheckmateSelect';
import { useState, useEffect } from 'react';
import { ConditionSelectorEnums } from '../../utilities/Constants';


interface IConditionSelectorProps {
    options: { label: string; value: string }[];
    value: boolean | string | undefined;
    onChange: (selectedItem: any) => void;
    disabled?: boolean;
}

export function ConditionSelector(props: IConditionSelectorProps) {
    const [value, setValue] = useState<{ label: string; value: string }[]>([]);

    // Update the value state whenever props.value changes
    useEffect(() => {
        const selectedOption = props.options.find((opt) => {
            if (props.value === true) {
                return opt.value === ConditionSelectorEnums.Blank;
            } else if (props.value === false) {
                return opt.value === ConditionSelectorEnums.NotBlank;
            }
            else if(props.value === ConditionSelectorEnums.NotEqualTo){
                return opt.value === ConditionSelectorEnums.NotEqualTo;
            }
            else if(props.value === ConditionSelectorEnums.EqualTo){
                return opt.value === ConditionSelectorEnums.EqualTo;
            }
        });
        setValue(selectedOption ? [selectedOption] : []);
    }, [props.value, props.options]);

    const handleChange = (selectedOption: any) => {
        setValue(selectedOption);
        props.onChange(selectedOption); 
    };

    return (
        <CheckmateSelect
            isMulti={false}
            options={props.options}
            value={value}
            onChange={handleChange}
            isDisabled={props.disabled}
        />
    );
}
