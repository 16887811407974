import * as React from 'react';

import { DocumentTypesEnum, EntityTypes, UserRightsEnum } from '../../utilities/Constants';
import { ICaseModel, ICheckmateTaskModel, IDocumentModel } from '../../interfaces/ICase';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import { CheckmateDialog } from '../shared/dialog';
import Common from '../../stores/Common';
import { FileUpload } from '../shared/FileUploader';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../shared/SortableHeader';
import ValidateUtils from '../../shared/validations';

const _apiClient = new ApiClient();

interface IOfficialCaseDocumentsProp {
    user: IUserModel;
    case: ICaseModel;
    taskGuid?: string;
}

interface IOfficialCaseDocumentsState {
    case: ICaseModel;
    taskGuid?: string;
    validation: IValidation;
    document: IDocumentModel;
    file?: File;
    displayOfficialDocumentDialog: boolean;
    tasks: ICheckmateTaskModel[];
    showConfirmRemoveDialog: boolean;
    documentGuidToDelete: string;
}

export class OfficialCaseDocuments extends React.Component<
    IOfficialCaseDocumentsProp,
    IOfficialCaseDocumentsState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            case: {},
            validation: {},
            document: {
                documentType: { id: DocumentTypesEnum.OfficialCaseDocument.Value },
            },
            displayOfficialDocumentDialog: false,
            tasks: [],
            showConfirmRemoveDialog: false,
            documentGuidToDelete: '',
        };
        this.saveDocument = this.saveDocument.bind(this);
    }

    componentDidMount() {
        this.setState({
            case: this.props.case,
            tasks:
                this.props.case.casePlan && this.props.case.casePlan.checkmateTasks
                    ? this.props.case.casePlan.checkmateTasks
                    : [],
            taskGuid: this.props.taskGuid,
        });
    }

    removeOfficialCaseDocument = async () => {
        const caseGuid = this.state.case.guid!;
        const response = await _apiClient.deleteEntityDocument(
            caseGuid,
            EntityTypes.Case,
            this.state.documentGuidToDelete
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        window.location.reload();
    };

    editOfficialCaseDocument = async (
        event: React.FormEvent<HTMLButtonElement>,
        document: IDocumentModel
    ) => {
        event.preventDefault();

        this.setState({ document: document, displayOfficialDocumentDialog: true });
    };

    getDocumentList = () => {
        if (this.state.case.documents) {
            if (this.state.taskGuid) {
                return this.state.case.documents.filter(
                    (x) =>
                        x.documentType &&
                        x.documentType.id == DocumentTypesEnum.OfficialCaseDocument.Value &&
                        x.task &&
                        x.task.guid == this.state.taskGuid
                );
            }
            return this.state.case.documents.filter(
                (x) =>
                    x.documentType &&
                    x.documentType.id == DocumentTypesEnum.OfficialCaseDocument.Value
            );
        }
        return [];
    };

    saveDocument = async () => {
        const caseGuid = this.state.case.guid!;
        const document = this.state.document;
        const validation = this.state.validation;
        // client side validations

        // File is required for a new document
        if (!document.guid && this.state.file == undefined) {
            validation.file = ['File is required '];
            this.setState({ validation: validation });
            return;
        }

        const data = new FormData();
        if (this.state.file != undefined) {
            data.append('file', this.state.file);
        }
        data.append('document', JSON.stringify(document));

        const responseFromServer = await fetch(
            ApiRoutes.EntityDocumentUpload.replace('{id}', caseGuid),
            {
                method: 'POST',
                body: data,
            }
        ).then((response) => {
            return response.json();
        });

        if (responseFromServer.message == 'Success') {
            window.location.reload();
        } else {
            validation.model = [responseFromServer.message];
            this.setState({ validation: validation });
            return;
        }
    };

    changeValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setChangedValue(name, value);
    };

    setChangedValue(name: string, value: any) {
        const document = this.state.document;
        const validation = this.state.validation;

        switch (name) {
            case 'title':
                document.title = value;
                validation.title = [''];
                break;
            case 'description':
                document.description = value;
                validation.description = [''];
                break;
            case 'task':
                document.task = this.state.tasks.filter((x) => x.guid == value)[0];
                validation.task = [''];
                break;

            default:
                break;
        }
        this.setState({
            document: document,
        });
    }

    openOfficialDocumentDialog = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        const validation = this.state.validation;
        validation.file = [''];
        const document = this.state.document;
        if (this.state.taskGuid) {
            document.task = this.state.tasks.filter((x) => x.guid == this.state.taskGuid)[0];
        }
        this.setState({
            displayOfficialDocumentDialog: true,
            document: document,
            validation: validation,
        });
    };

    closeOfficialDocumentDialog = () => {
        this.setState({
            displayOfficialDocumentDialog: false,
            document: {
                documentType: { id: DocumentTypesEnum.OfficialCaseDocument.Value },
            },
        });
    };

    changeAttachment = (files: FileList) => {
        const document = this.state.document;
        document.fileName = files[0].name;
        this.setState({ document: document, file: files[0] });
    };

    getTaskStatus = (task?: ICheckmateTaskModel) => {
        if (task && task.status && task.status.displayName) {
            return task.status.displayName;
        }
        return '';
    };

    getTaskGuid = (task?: ICheckmateTaskModel) => {
        if (task && task.guid) {
            return task.guid;
        }
        return '';
    };

    getModifiedByName = (user?: IUserModel) => {
        if (user && user.profile && user.profile.firstName && user.profile.lastName) {
            return user.profile.firstName.concat(' ', user.profile.lastName);
        }
        return '';
    };

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        const currCase = this.state.case;
        let documents = currCase.documents;
        if (documents) {
            if (key == 'modifiedDate') {
                documents = documents.sort(Sort.compareDate(key, subKey, order));
            } else {
                documents = documents.sort(Sort.compareValues(key, subKey, order, subGrandKey));
            }
        }
        //currentCase.casePlan!.checkmateTasks = documents;
        this.setState({ case: currCase });
    };

    showConfirmRemoveDailog = (docuemntGuid: string) => {
        this.setState({ showConfirmRemoveDialog: true, documentGuidToDelete: docuemntGuid });
    };

    clickCancel = () => {
        this.setState({
            showConfirmRemoveDialog: false,
        });
    };

    downloadDocument = async (
        event: React.FormEvent<HTMLButtonElement>,
        docuemntGuid: string,
        documentName?: string
    ) => {
        event.preventDefault();

        const xhr = new XMLHttpRequest();
        xhr.open(
            'GET',
            ApiRoutes.EntityDocumentDownload.replace('{id}', this.props.case.guid!).replace(
                '{documentid}',
                docuemntGuid
            ),
            true
        );
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/msword');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                let fileName = documentName ? documentName : 'Work Product.doc';
                const blob = xhr.response;

                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    const contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (let i = 0; i < contentDispositionItems.length; i++) {
                            const currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                const n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                // TODO: display custom validation returned from the api call
                /*let validation = this.state.validation;
                validation.model = [DisplayMessages.DocumentDownloadError];
                this.setState({ pendingResponse: false, validation: validation });*/
            }
        };

        xhr.send();
    };

    public render() {
        return (
            <div className="container-fluid admin-detail">
                {Authorization.userHasRight(UserRightsEnum.EditCaseFile, this.props.user) ? (
                    <span
                        className="btn btn-orange float-end"
                        onClick={this.openOfficialDocumentDialog}
                    >
                        Upload Official Document
                    </span>
                ) : null}
                <table className="table vertical-margin padding-left">
                    <thead>
                        <tr>
                            <SortableHeader
                                headerText="Title"
                                sortKey="title"
                                onSort={this.sortData}
                            />
                            <SortableHeader
                                headerText="Filename"
                                sortKey="fileName"
                                onSort={this.sortData}
                            />
                            <SortableHeader
                                headerText="Task status"
                                sortKey="task"
                                subKey="status"
                                subGrandKey="displayName"
                                onSort={this.sortData}
                                noWrap
                            />
                            <SortableHeader
                                headerText="Modified by"
                                sortKey="modifiedBy"
                                subKey="profile"
                                subGrandKey="firstName"
                                onSort={this.sortData}
                                noWrap
                            />
                            <SortableHeader
                                headerText="Modified date"
                                sortKey="modifiedDate"
                                onSort={this.sortData}
                            />
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {this.getDocumentList().map((document: IDocumentModel) => {
                            return (
                                <tr key={document.guid}>
                                    <td>{document.title ? document.title : ''}</td>
                                    <td>{document.fileName ? document.fileName : ''}</td>
                                    <td>
                                        {document.task &&
                                        this.props.case.casePlan &&
                                        this.props.case.casePlan.guid ? (
                                            <Link
                                                to={LocalRoutes.CasePlanTaskDetail.replace(
                                                    ':caseguid',
                                                    this.props.case.guid!
                                                )
                                                    .replace(
                                                        ':caseplanguid',
                                                        this.props.case!.casePlan!.guid!
                                                    )
                                                    .replace(
                                                        ':taskguid',
                                                        this.getTaskGuid(document.task)
                                                    )}
                                            >
                                                {this.getTaskStatus(document.task)}
                                            </Link>
                                        ) : null}
                                    </td>
                                    <td>{this.getModifiedByName(document.modifiedBy)}</td>
                                    <td>{Common.dateFormat(document.modifiedDate)}</td>
                                    <td>
                                        {Authorization.userHasRight(
                                            UserRightsEnum.EditCaseFile,
                                            this.props.user
                                        ) ? (
                                            <button
                                                className="btn btn-no-bg float-end"
                                                onClick={() => {
                                                    this.showConfirmRemoveDailog(document.guid!);
                                                }}
                                            >
                                                <i className="fal fa-lg fa-trash-alt"></i>
                                            </button>
                                        ) : null}
                                        {Authorization.userHasRight(
                                            UserRightsEnum.EditCaseFile,
                                            this.props.user
                                        ) ? (
                                            <button
                                                className="btn btn-no-bg float-end"
                                                onClick={(
                                                    e: React.MouseEvent<HTMLButtonElement>
                                                ) => {
                                                    this.editOfficialCaseDocument(e, document);
                                                }}
                                            >
                                                <i className="fal fa-lg fa-edit" />
                                            </button>
                                        ) : null}
                                        <button
                                            className="btn btn-no-bg float-end"
                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                this.downloadDocument(
                                                    e,
                                                    document.guid!,
                                                    document.fileName
                                                );
                                            }}
                                        >
                                            <i className="fal fa-lg fa-file-download" />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <Modal
                    centered
                    show={this.state.displayOfficialDocumentDialog}
                    dialogClassName="official-case-doc-dialog"
                    size="lg"
                    onHide={this.closeOfficialDocumentDialog}
                    backdrop={false}
                >
                    <Modal.Header>
                        <Modal.Title>Official Case Document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <span className="text-danger">{this.state.validation.model}</span>
                        </div>
                        <div className="form-group">
                            <label className="control-label" htmlFor="title">
                                Title
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                name="title"
                                value={this.state.document.title || ''}
                                onChange={this.changeValue}
                            />
                            <span className="text-danger">{this.state.validation.title}</span>
                        </div>

                        <div className="form-group">
                            <label className="control-label" htmlFor="title">
                                Description
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                name="description"
                                value={this.state.document.description || ''}
                                onChange={this.changeValue}
                            />
                            <span className="text-danger">{this.state.validation.description}</span>
                        </div>
                        {/* AR - TO DO - This component is currently turned off and not sure when/if it will be turned on. 
                        Will change the styling to use new CheckmateSelect if needed at the time of re-enabling this component */}
                        {/*  <div className="form-group">
                            <label className="control-label" htmlFor="task">Task</label>*/}
                        {/*<select required className="form-select" name="task"
                                onChange={this.changeValue}
                                value={this.state.document.task ? this.state.document.task.guid : 0}>
                                <option value="">-- Select --</option>
                                {this.state.tasks.map((task: ICheckmateTaskModel) => {
                                    return <option key={task.guid} value={task.guid}>{task.displayName}</option>;
                                })}
                            </select>
                            <span className="text-danger">{this.state.validation.task}</span>
                        </div> */}

                        <div className="form-group">
                            <label className="control-label" htmlFor="title">
                                File*
                            </label>
                            <FileUpload onChange={this.changeAttachment} />
                            <span className="text-danger">{this.state.validation.file}</span>
                        </div>

                        <div className="dialog-btn-div margin-top-sm">
                            <button
                                className="btn btn-default float-end"
                                onClick={this.closeOfficialDocumentDialog}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-orange float-end"
                                onClick={this.saveDocument}
                            >
                                Save
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
                <CheckmateDialog
                    isShowingModal={this.state.showConfirmRemoveDialog}
                    body="Are you sure you want to delete the selected item?"
                    handleClose={this.clickCancel}
                    handleConfirm={this.removeOfficialCaseDocument}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </div>
        );
    }
}
