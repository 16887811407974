import * as React from 'react';

import Authorization from '../../../stores/Authorization';
import CheckmateSelect from '../../shared/CheckmateSelect';
import CheckmateSelectHelper from '../../../utilities/CheckmateSelectHelper';
import { IAssociatedOrganizationModel } from '../../../interfaces/IOrganization';
import { ICaseModel } from '../../../interfaces/ICase';
import { IMultiSelectOptions } from '../../../interfaces/ILookup';
import { IUserModel } from '../../../interfaces/IUser';
import { IValidation } from '../../../interfaces/IError';
import { UserRightsEnum } from '../../../utilities/Constants';

interface ICaseDefenseCounselsProps {
    user: IUserModel;
    allDefenseCounsels: IAssociatedOrganizationModel[];
    caseLocalCounsels?: IAssociatedOrganizationModel[];
    validation: IValidation;
    readOnlyMode: boolean;
    setCurrentCase?: React.Dispatch<React.SetStateAction<ICaseModel>>;
}

export function CaseDefenseCounsels(props: ICaseDefenseCounselsProps) {
    const getNationalCounselName = (): string => {
        return props.caseLocalCounsels?.find((lc) => lc.priority === -1)?.name ?? '';
    };

    const getPrimaryLocalCounselName = (): string => {
        return props.caseLocalCounsels?.find((lc) => lc.priority === 1)?.name ?? '';
    };

    const addLocalCounsel = () => {
        let maxPriority = 0;
        const localCopy = [...(props.caseLocalCounsels ?? [])];

        if (localCopy.length > 0) {
            const priorityArray: number[] = localCopy.map((item) => item.priority || 0);
            maxPriority = Math.max(...priorityArray);
        }

        const newcaseDefenseCounsel: IAssociatedOrganizationModel = {
            guid: '',
            name: '',
            priority: maxPriority + 1,
        };

        localCopy.push(newcaseDefenseCounsel);

        if (props.setCurrentCase) {
            props.setCurrentCase((prev) => ({ ...prev, defenseCounsels: localCopy }));
        }
    };

    const handleChangeLocalDefenseCounsel = (optionList: IMultiSelectOptions, priority: number) => {
        const localCounsels = [...(props.caseLocalCounsels ?? [])];
        const localCounselMatch = localCounsels.find((x) => x.priority === priority);

        const guid = optionList ? optionList.guid : '';

        if (localCounselMatch) {
            if (optionList === null) {
                localCounselMatch.guid = undefined;
                localCounselMatch.name = undefined;
            } else {
                const found = props.allDefenseCounsels.find((x) => x.guid === guid);
                if (found) {
                    localCounselMatch.name = found.name;
                    localCounselMatch.guid = found.guid;
                }
            }
        }

        if (typeof props.setCurrentCase === 'function') {
            props.setCurrentCase((prev) => ({ ...prev, defenseCounsels: localCounsels }));
        }
    };

    const handleDeleteLocalDefenseCounsel = (priority: number) => {
        const localCounsels = [...(props.caseLocalCounsels ?? [])];
        const filtered = localCounsels.filter((lc) => lc.priority !== priority && !!lc.guid);

        if (props.setCurrentCase) {
            props.setCurrentCase((prev) => ({ ...prev, defenseCounsels: filtered }));
        }
    };

    const filterDefenseCounselList = () => {
        return props.allDefenseCounsels.filter((x) => x.priority !== 1);
    };

    return (
        <div>
            <ul>
                <li>
                    <div className="padding-left">
                        <span className="case-profile-item-title">National Counsel</span>
                        <span>{getNationalCounselName()}</span>
                    </div>
                </li>
                <li>
                    <div className="padding-left">
                        <span className="case-profile-item-title">Primary Local Counsel</span>
                        <span>{getPrimaryLocalCounselName()}</span>
                    </div>
                </li>
                {props.caseLocalCounsels
                    ?.filter((x) => (x.priority ?? 0) > 1)
                    ?.sort((a, b) => ((a.priority ?? 0) < (b.priority ?? 0) ? -1 : 1))
                    .map((localCounsel, idx) => {
                        if (props.readOnlyMode) {
                            return (
                                <li key={localCounsel.guid + '_' + idx}>
                                    <div className="padding-left">
                                        <span className="case-profile-item-title">
                                            Local Counsel {localCounsel.priority}
                                        </span>
                                        <span>{localCounsel.name}</span>
                                    </div>
                                </li>
                            );
                        } else {
                            return (
                                <NewLocalCounsel
                                    key={idx}
                                    localCounsel={localCounsel}
                                    defenseCounselList={CheckmateSelectHelper.getOrgOptions(
                                        filterDefenseCounselList()
                                    )}
                                    onChange={handleChangeLocalDefenseCounsel}
                                    onDelete={handleDeleteLocalDefenseCounsel}
                                />
                            );
                        }
                    })}
                <li style={{ paddingLeft: '15px', paddingTop: '15px' }}>
                    {!props.readOnlyMode &&
                    Authorization.userHasRight(UserRightsEnum.EditCaseFile, props.user) ? (
                        <input
                            type="button"
                            className="btn btn-blue"
                            disabled={props.caseLocalCounsels?.some((x) => x.guid == '')}
                            onClick={addLocalCounsel}
                            value="Add Local Counsel"
                        />
                    ) : null}
                </li>
            </ul>
        </div>
    );
}

interface INewLocalCounselProps {
    localCounsel: IAssociatedOrganizationModel;
    defenseCounselList: IMultiSelectOptions[];
    onChange: (optionList: IMultiSelectOptions, priority: number) => void;
    onDelete: (priority: number) => void;
}
function NewLocalCounsel({
    localCounsel,
    defenseCounselList,
    onChange,
    onDelete,
}: INewLocalCounselProps) {
    const selectedValue = defenseCounselList.find((dc) => dc.guid === localCounsel.guid);

    return (
        <li>
            <div className="my-2">
                <label
                    className="control-label d-inline-block ps-3"
                    htmlFor="portfolioOrganization"
                >
                    {'Local Counsel ' + localCounsel.priority}
                </label>
                <div className="d-inline-block ms-2 w-25">
                    <CheckmateSelect
                        options={defenseCounselList}
                        value={selectedValue}
                        onChange={(optionsList: any) => {
                            onChange(optionsList, localCounsel.priority!);
                        }}
                    />
                </div>
                <button
                    className="btn btn-no-bg"
                    onClick={() => {
                        onDelete(localCounsel.priority!);
                    }}
                >
                    <i className="fal fa-lg fa-trash-alt"></i>
                </button>
            </div>
        </li>
    );
}
