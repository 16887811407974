import * as React from 'react';
import { Modal } from 'react-bootstrap';
import ValidateUtils from '../../shared/validations';
import { IValidation } from '../../interfaces/IError';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { IInvitationModel } from '../../interfaces/IUser';

import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Navigate } from 'react-router';
import CheckmateApiClient from '../../services/apiClient';
let _apiClient = new CheckmateApiClient();

interface IRegistrationOptionProps {
    guid: string;
}

interface IRegistrationOptionState {
    userInvitation: IInvitationModel;
    redirect: boolean;
    redirectTo: string;
    validation: IValidation;
    CreateNewAccountOption: boolean;
}

export class RegistrationOption extends React.Component<
    IRegistrationOptionProps,
    IRegistrationOptionState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            userInvitation: { organizationRoles: [] },
            redirect: false,
            redirectTo: '/',
            validation: {},
            CreateNewAccountOption: true,
        };
    }

    componentDidMount() {
        this.loadUserInviation(this.props.guid);
    }

    loadUserInviation = async (guid: string) => {
        var response = await _apiClient.getUserInvitation(guid);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        if (response.payload) {
            this.setState(
                {
                    userInvitation: response.payload,
                },
                this.loadRegistrationOptions
            );
        }
    };

    loadRegistrationOptions = () => {
        let userInvitation = this.state.userInvitation;
        if (userInvitation.userAccountExistsForInvitatioEmail == true) {
            this.setState({ CreateNewAccountOption: false });
            return;
        }
    };

    handleClose = () => {
        this.setState({
            redirect: true,
            redirectTo: '/',
        });
    };

    handleClick = (redirectTo: string) => {
        this.setState({
            redirect: true,
            redirectTo: redirectTo,
        });
    };

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirectTo} />;
        }

        return (
            <Modal
                centered
                show={true}
                dialogClassName="registration-options-dialog"
                onHide={this.handleClose}
                backdrop={false}
            >
                <Modal.Body>
                    {this.state.userInvitation && this.state.userInvitation.invitationAccepted ? (
                        <div>
                            <p>
                                This invitation has already been accepted. Please login to access
                                your account.
                            </p>
                            <div className="dialog-btn-div">
                                <button
                                    className="btn btn-black float-end"
                                    onClick={this.handleClose}
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <span className="text-danger">{this.state.validation.model}</span>
                            <p>{DisplayMessages.RegistrationOptionSubtitle}</p>
                            {this.state.CreateNewAccountOption ? (
                                <div className="dialog-btn-div">
                                    <button
                                        className="btn btn-black full-width"
                                        onClick={() => {
                                            this.handleClick(
                                                LocalRoutes.RegisterWithInvitation.replace(
                                                    ':guid',
                                                    this.props.guid
                                                )
                                            );
                                        }}
                                    >
                                        Create New Account
                                    </button>
                                </div>
                            ) : null}
                            <div className="dialog-btn-div">
                                <button
                                    className="btn btn-black full-width"
                                    onClick={() => {
                                        this.handleClick(
                                            LocalRoutes.LoginAdvanced.replace(
                                                ':guid',
                                                this.props.guid
                                            )
                                        );
                                    }}
                                >
                                    Use Existing Account
                                </button>
                            </div>
                            <div className="dialog-btn-div">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.handleClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        );
    }
}
