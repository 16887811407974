import * as React from 'react';

import {
    ApplicationHelpType,
    AssertionDescriptorUsageTypesEnum,
    AssertionDescriptors,
    CaseFileStaticItems,
    CaseFilterType,
    CaseStatusTypesEnum,
    CaseSubStatusTypesEnum,
    DataScopesEnum,
    EmptyGuid,
    EntityTypes,
    ExpectedOutcomeTexts,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    PackageSettlementStatusTypesEnum,
    QueryToolRedirectTypeEnum,
    ReportTypesEnum,
    UserRightsEnum,
} from '../../../utilities/Constants';
import {
    IAssertionDescriptorModel,
    IEntityAssertionModel,
} from '../../../interfaces/IAssertionDescriptor';
import {
    ICaseFilterModel,
    ICaseModel,
    ICaseSearchModel,
    IDocumentModel,
    IPackageDealValueAddModel,
    IPackageSettlementFirmModel,
    IPackageSettlementModel,
} from '../../../interfaces/ICase';
import { ILookupModel, IMultiSelectOptions } from '../../../interfaces/ILookup';
import { INoteCategoryTypeModel, INoteModel } from '../../../interfaces/INote';

import ApiClient from '../../../services/apiClient';
import { ApiRoutes } from '../../../utilities/ApiRoutes';
import Authorization from '../../../stores/Authorization';
import { CaseEasyUpdate } from '../CaseEasyUpdate';
import CaseHelper from '../../../utilities/CaseHelper';
import { CheckmateDialog } from '../../../components/shared/dialog';
import CheckmateSelect from '../../../components/shared/CheckmateSelect';
import CheckmateSelectHelper from '../../../utilities/CheckmateSelectHelper';
import Common from '../../../stores/Common';
import { DisplayMessages } from '../../../utilities/DisplayMessages';
import EntityAssertionControl from '../../../components/shared/EntityAssertionControl';
import { Help } from '../../../components/shared/Help';
import { IQueryFilterCriteria } from '../../../interfaces/IQuery';
import { IReportCaseDetailModel } from '../../../interfaces/Report/IReportCaseDetailModel';
import { IReportParametersBaseModel } from '../../../interfaces/Report/IReport';
import { IUserModel } from '../../../interfaces/IUser';
import { IValidation } from '../../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../../components/shared/Loader';
import { LocalRoutes } from '../../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import { NoteEditor } from '../../../components/notes/NoteEditor';
import NoteHelper from '../../../utilities/NoteHelper';
import { NoteList } from '../../../components/notes/NoteList';
import Sort from '../../../stores/Sort';
import { SortableHeader } from '../../../components/shared/SortableHeader';
import { TextWithLineBreaks } from '../../../components/shared/TextWithLineBreaks';
import ValidateUtils from '../../../shared/validations';
import { WorkProductWrapper } from '../../../components/work-product/WorkProductWrapper';

interface IPackageSettlementDetailProps {
    user: IUserModel;
    guid?: string;
    redirect?: boolean;
    selectedGuids?: string[];
    redirectComponentCurrentState?: IPackageSettlementModel;
}

interface IPackageSettlementDetailState {
    pendingResponse: boolean;
    validation: IValidation;
    originalPackageSettlement: IPackageSettlementModel;
    packageSettlement: IPackageSettlementModel;
    firms: IMultiSelectOptions[];
    statuses: ILookupModel[];
    readOnly: boolean;
    showSuccessDialog: boolean;
    showAddCasesOptionsDialog: boolean;
    showAddCasesDialog: boolean;
    caseSearchString: string;
    caseSearchResults?: IReportCaseDetailModel[];
    selectedCaseGuids: string[];
    selectAllCases?: boolean;
    enableEasyUpdate: boolean;
    easyUpdateMode: boolean;
    easyUpdateCaseGuid?: string;
    easyUpdateAttemptedCaseList: string[];
    showViewAllFirmsInfoDialog?: boolean;
    notesOnEditMode?: boolean;
    redirect?: boolean;
    currentNarrativeCaseAssertionOnEditMode?: IEntityAssertionModel;
    assertionDescriptors: IAssertionDescriptorModel[];
    pendingSave?: boolean;
    loggedInUserDataScopeId: number;
    noteCategoryTypes: INoteCategoryTypeModel[];
    currentNote: INoteModel;
    openNoteEditor: boolean;
    showUnlockPackageSettlementDialog: boolean;
    showLockPackageSettlementDialog: boolean;
    showAllCasesLockedDialog: boolean;
}

const _apiClient = new ApiClient();

enum DataItems {
    PackageSettlementCases = 1,
    CaseSearchResults = 2,
}

export class PackageSettlementDetail extends React.Component<
    IPackageSettlementDetailProps,
    IPackageSettlementDetailState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            pendingResponse: false,
            originalPackageSettlement: {
                valueAddDetails: [],
                notes: [],
                locked: false,
                earliestSettledResolutionDate: '',
            },
            packageSettlement: {
                valueAddDetails: [],
                notes: [],
                locked: false,
                earliestSettledResolutionDate: '',
            },
            validation: {},
            readOnly: true,
            firms: [],
            statuses: [],
            showSuccessDialog: false,
            showAddCasesOptionsDialog: false,
            showAddCasesDialog: false,
            caseSearchString: '',
            selectedCaseGuids: [],
            enableEasyUpdate: false,
            easyUpdateMode: false,
            easyUpdateAttemptedCaseList: [],
            assertionDescriptors: [],
            loggedInUserDataScopeId: 0,
            noteCategoryTypes: [],
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            openNoteEditor: false,
            showUnlockPackageSettlementDialog: false,
            showLockPackageSettlementDialog: false,
            showAllCasesLockedDialog: false,
        };
    }

    componentDidMount() {
        const guid = this.props.guid;
        if (guid) {
            if (
                !Authorization.isAuthorizedToRoute(
                    LocalRoutes.PackageSettlementDetails,
                    this.props.user
                )
            )
                window.location.assign(LocalRoutes.AccessDenied);
        } else {
            if (
                !Authorization.isAuthorizedToRoute(
                    LocalRoutes.PackageSettlementNew,
                    this.props.user
                )
            )
                window.location.assign(LocalRoutes.AccessDenied);
        }

        if (this.props.redirect && this.props.redirectComponentCurrentState) {
            this.setState({ redirect: true });
            this.handleRedirectFromQueryTool(
                this.props.selectedGuids || [],
                this.props.redirectComponentCurrentState
            );
            window.history.replaceState(null, '');
        } else this.loadInitialData(true, guid);
    }

    loadInitialData = async (
        loadPackageSettlementDetails: boolean,
        packageSettlementGuid?: string
    ) => {
        this.setState({ pendingResponse: true });
        const plaintiffsfirmsOptions = await this.fetchPlaintiffsfirm();
        const statuses = await this.fetchStatuses();
        const noteCategoryTypes = await this.fetchNoteCategoryTypes();

        if (!plaintiffsfirmsOptions || !statuses || !noteCategoryTypes) {
            const validation: IValidation = {};
            validation.model = [DisplayMessages.UnexpectedError];
            this.setState({ validation: validation, pendingResponse: false });
            return;
        }

        if (loadPackageSettlementDetails)
            this.setState(
                {
                    firms: plaintiffsfirmsOptions,
                    statuses: statuses,
                    noteCategoryTypes: noteCategoryTypes,
                },
                () => this.loadPackageSettlement(packageSettlementGuid)
            );
        else
            this.setState({
                firms: plaintiffsfirmsOptions,
                statuses: statuses,
                noteCategoryTypes: noteCategoryTypes,
                pendingResponse: false,
            });
    };

    fetchPlaintiffsfirm = async () => {
        const plaintiffsfirmsOptions: IMultiSelectOptions[] = [];
        const response = await _apiClient.getPlaintiffsFirms();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        if (response.payload) {
            const plaintiffsfirms = response.payload;
            plaintiffsfirms.map((plaintiffsfirm: ILookupModel) => {
                const item: IMultiSelectOptions = {
                    label: plaintiffsfirm.displayName!,
                    value: plaintiffsfirm.guid!,
                    id: 0,
                };
                plaintiffsfirmsOptions.push(item);
            });
        }

        return plaintiffsfirmsOptions;
    };

    fetchStatuses = async () => {
        const response = await _apiClient.getPackageSettlementStatusTypes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        return response.payload!;
    };

    fetchNoteCategoryTypes = async () => {
        const noteCategoryTypesResponse = await _apiClient.getPackageSettlementNoteCategoryTypes();
        if (noteCategoryTypesResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (noteCategoryTypesResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    noteCategoryTypesResponse.errors,
                    noteCategoryTypesResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        return noteCategoryTypesResponse.payload!;
    };

    fetchPackageSettlement = async (guid: string) => {
        const res = await _apiClient.getPackageSettlementDetail(guid);
        if (res.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (res.errorMessage) {
            this.setState({ validation: ValidateUtils.parseErrors(res.errors, res.errorMessage) });
            return;
        }
        if (res.payload) {
            return res.payload;
        }
    };

    loadPackageSettlement = async (guid?: string) => {
        if (guid) {
            const packageSettlementFromDB = await this.fetchPackageSettlement(guid);
            if (!packageSettlementFromDB) {
                const validation: IValidation = {};
                validation.model = [DisplayMessages.UnexpectedError];
                this.setState({ validation: validation, pendingResponse: false });
                return;
            }
            this.setState({
                packageSettlement: packageSettlementFromDB,
                originalPackageSettlement: JSON.parse(JSON.stringify(packageSettlementFromDB)),
                readOnly: true,
                pendingResponse: false,
            });
        } else {
            const defaultOrg = await _apiClient.getDefaultUserOrganizationFromServer();
            if (!defaultOrg) return;
            if (defaultOrg.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (defaultOrg.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(
                        defaultOrg.errors,
                        defaultOrg.errorMessage
                    ),
                    pendingResponse: false,
                });
                return;
            }
            const newPackageSettlement: IPackageSettlementModel = {
                organization: { guid: defaultOrg.payload!.guid },
                valueAddDetails: [],
                notes: [],
                locked: false,
                earliestSettledResolutionDate: '',
            };

            const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(
                this.props.user
            );

            this.setState(
                {
                    packageSettlement: newPackageSettlement,
                    readOnly: false,
                    loggedInUserDataScopeId: loggedInUserDataScopeId,
                },
                this.loadAssertionDescriptors
            );
        }
    };

    handleRedirectFromQueryTool = async (
        selectedCaseGuids: string[],
        packageSettlement: IPackageSettlementModel
    ) => {
        // Always refresh case data for the current package settlement when redirected back from the Query Tool even if new cases were not added.
        const allCaseGuids = selectedCaseGuids;
        if (packageSettlement.cases) {
            packageSettlement.cases.map((caseItem: IReportCaseDetailModel) => {
                if (
                    allCaseGuids.filter((x) => x.toUpperCase() === caseItem.caseGuid.toUpperCase())
                        .length === 0
                )
                    allCaseGuids.push(caseItem.caseGuid);
            });
        }

        if (allCaseGuids.length > 0) {
            const caseSearchResponse = await _apiClient.runPackageSettlementSearch({
                packageSettlementSearch: true,
                caseGuids: selectedCaseGuids,
            });
            if (caseSearchResponse.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (caseSearchResponse.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(
                        caseSearchResponse.errors,
                        caseSearchResponse.errorMessage
                    ),
                });
                return;
            }
            if (caseSearchResponse.payload && caseSearchResponse.payload.length > 0) {
                packageSettlement.cases = [];
                caseSearchResponse.payload.map((item: IReportCaseDetailModel) => {
                    packageSettlement.cases!.push(JSON.parse(JSON.stringify(item)));
                });
            }

            if (selectedCaseGuids.length > 0) {
                this.setState({ packageSettlement: packageSettlement }, () =>
                    this.savePackageSettlement()
                );
                return;
            }
        }

        this.setState({ packageSettlement: packageSettlement });
    };

    loadAssertionDescriptors = async () => {
        if (this.state.packageSettlement.guid) return;

        const response = await _apiClient.getAssertionDescriptors(
            AssertionDescriptorUsageTypesEnum.PackageDeal
        );

        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ assertionDescriptors: response.payload!, pendingResponse: false });
    };

    savePackageSettlement = async () => {
        // loadInitialData = true when navigating from Package Deal to Query Tool to add cases
        let hasValidationErrors = false;
        const validation: IValidation = {};
        if (!this.state.packageSettlement.name) {
            hasValidationErrors = true;
            validation.name = ['Name is Required'];
        }

        if (!this.state.packageSettlement.initialDate) {
            hasValidationErrors = true;
            validation.initialDate = ['Initial Date is Required'];
        } else if (!Common.isValidDate(this.state.packageSettlement.initialDate)) {
            hasValidationErrors = true;
            validation.initialDate = ['Invalid Date'];
        }

        if (
            this.state.packageSettlement.finalDate &&
            !Common.isValidDate(this.state.packageSettlement.finalDate)
        ) {
            hasValidationErrors = true;
            validation.finalDate = ['Invalid Date'];
        }

        if (!this.state.packageSettlement.status) {
            hasValidationErrors = true;
            validation.status = ['Status is Required'];
        } else if (
            this.state.packageSettlement.status.id == PackageSettlementStatusTypesEnum.Complete &&
            !this.state.packageSettlement.finalDate
        ) {
            hasValidationErrors = true;
            validation.finalDate = ['Final Date is Required for a Completed Package Deal'];
        }

        if (!this.state.packageSettlement.firms || this.state.packageSettlement.firms.length == 0) {
            hasValidationErrors = true;
            validation.firms = ['At least one firm is Required'];
        }

        if (hasValidationErrors) {
            this.setState({ validation: validation });
            return;
        }

        this.setState({ pendingResponse: true });

        if (this.state.packageSettlement.guid) {
            const response = await _apiClient.updatePackageSettlement(this.state.packageSettlement);
            if (response.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (response.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                    pendingResponse: false,
                });
                return;
            }

            this.setState(
                {
                    packageSettlement: response.payload!,
                    pendingResponse: false,
                    validation: {},
                    readOnly: true,
                    pendingSave: false,
                },
                () => this.loadInitialData(false)
            );
        } else {
            const response = await _apiClient.createPackageSettlement(this.state.packageSettlement);
            if (response.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (response.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                    pendingResponse: false,
                });
                return;
            }

            window.location.assign(
                LocalRoutes.PackageSettlementDetails.replace(':guid', response.payload!.guid!)
            );
            return;
        }
    };

    cancelEdit = () => {
        if (this.state.packageSettlement.guid) {
            window.location.assign(window.location.pathname + window.location.search);
        } else {
            window.location.assign(LocalRoutes.PackageSettlements);
        }
    };

    getSelectedFirms = () => {
        const selectedFirms: IMultiSelectOptions[] = [];
        if (this.state.packageSettlement && this.state.packageSettlement.firms) {
            for (let i = 0; i < this.state.packageSettlement.firms.length; i++) {
                selectedFirms.push({
                    label: this.state.packageSettlement.firms[i].name!,
                    value: this.state.packageSettlement.firms[i].guid,
                    id: 0,
                });
            }
        }

        return selectedFirms;
    };

    handleFirmsSelected = (optionsList: any) => {
        const packageSettlement = this.state.packageSettlement;
        const firms: IPackageSettlementFirmModel[] = [];
        const validation = this.state.validation;
        const selectedFirmsGuids: string[] = [];
        for (let i = 0; i < optionsList.length; i++) {
            selectedFirmsGuids.push(optionsList[i].value);
        }

        if (selectedFirmsGuids.length > 0) {
            validation.firms = [''];
            for (let i = 0; i < selectedFirmsGuids.length; i++) {
                const match = this.state.firms.find((x) => x.value == selectedFirmsGuids[i]);
                if (match)
                    firms.push({
                        plaintiffsFirmStrategyGuid: EmptyGuid,
                        guid: match.value!,
                        priority: i + 1,
                        name: match.label,
                    });
            }
        }

        packageSettlement.firms = firms;
        this.setState({ packageSettlement: packageSettlement, validation: validation });
    };

    handleStatusChange = (selectedItem: any) => {
        const packageSettlement = this.state.packageSettlement;
        const validation = this.state.validation;
        this.setState({ pendingSave: true });
        packageSettlement.status =
            selectedItem && selectedItem.id ? { id: selectedItem.id } : undefined;
        if (selectedItem && selectedItem.id) validation.status = [''];
        this.setState({ packageSettlement: packageSettlement, validation: validation });
    };

    changeValue = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const packageSettlement = this.state.packageSettlement;
        const validation = this.state.validation;
        let caseSearchString = this.state.caseSearchString;
        this.setState({ pendingSave: true });

        switch (event.target.name) {
            case 'name':
                packageSettlement.name = event.target.value;
                if (event.target.value) validation.name = [''];
                break;
            case 'initialDate':
                packageSettlement.initialDate = event.target.value;
                if (event.target.value) validation.initialDate = [''];
                break;
            case 'finalDate':
                packageSettlement.finalDate = event.target.value;
                if (event.target.value) validation.finalDate = [''];
                break;
            case 'description':
                packageSettlement.description = event.target.value;
                if (event.target.value) validation.description = [''];
                break;
            case 'caseNameOrNumberSearch':
                caseSearchString = event.target.value;
                if (event.target.value) validation.caseNameOrNumberSearch = [''];
                break;
            default:
        }

        this.setState({
            packageSettlement: packageSettlement,
            validation: validation,
            caseSearchString: caseSearchString,
        });
    };

    handleSaveComplete = () => {
        //if (this.state.packageSettlement.guid)
        this.setState({
            showSuccessDialog: false,
            packageSettlement: JSON.parse(JSON.stringify(this.state.originalPackageSettlement)),
            readOnly: true,
        });
        //else
        //    window.location.assign(LocalRoutes.PackageSettlements);
    };

    removeCase(e: React.MouseEvent<HTMLButtonElement>, caseGuid: string) {
        if (this.state.packageSettlement.cases) {
            const packageSettlement = this.state.packageSettlement;
            packageSettlement.cases!.splice(
                packageSettlement.cases!.findIndex((x) => x.caseGuid == caseGuid),
                1
            );
            this.setState({ packageSettlement: packageSettlement });
        }
    }

    runCaseSearch = async (e: React.MouseEvent<HTMLButtonElement>, caseSearch?: boolean) => {
        const validation: IValidation = {};

        if (
            caseSearch &&
            (!this.state.caseSearchString || this.state.caseSearchString.trim() == '')
        ) {
            validation.caseNameOrNumberSearch = ['Enter a Case Number or Case Name. '];
            this.setState({ validation: validation });
            return;
        } else {
            validation.caseNameOrNumberSearch = [''];
        }

        const firmFilters: ICaseFilterModel[] = [];
        if (this.state.packageSettlement && this.state.packageSettlement.firms) {
            this.state.packageSettlement.firms.map((item: IPackageSettlementFirmModel) => {
                return firmFilters.push({
                    typeId: CaseFilterType.PlaintiffsFirm.Value,
                    text: item.name!,
                });
            });
        }

        this.setState({ pendingResponse: true });
        const caseSearchParameters: ICaseSearchModel = {
            searchText: this.state.caseSearchString,
            filters: firmFilters,
            packageSettlementSearch: true,
        };

        const res = await _apiClient.runPackageSettlementSearch(caseSearchParameters);
        if (res.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (res.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({
            caseSearchResults: res.payload!,
            validation: validation,
            pendingResponse: false,
        });
    };

    handleCheckChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        let selectedCaseGuids = this.state.selectedCaseGuids;
        let selectAllCases = this.state.selectAllCases;

        this.setState({ pendingSave: true });

        switch (event.target.name) {
            case 'selectAllCases':
                selectedCaseGuids = [];
                if (event.target.checked) {
                    selectAllCases = true;
                    if (this.state.caseSearchResults)
                        this.state.caseSearchResults
                            .filter((x) => !x.packageSettlementGuid)
                            .map((item: IReportCaseDetailModel) => {
                                return selectedCaseGuids.push(item.caseGuid);
                            });
                } else {
                    selectAllCases = false;
                }
                break;
            default:
                if (event.target.checked) {
                    selectedCaseGuids.push(event.target.name);
                } else {
                    selectedCaseGuids.splice(
                        selectedCaseGuids.findIndex((x) => x == event.target.name),
                        1
                    );
                    selectAllCases = false;
                }
                break;
        }

        this.setState({ selectedCaseGuids: selectedCaseGuids, selectAllCases: selectAllCases });
    };

    addSelectedCaseToPackageSettlement = (
        event: React.FormEvent<HTMLButtonElement>,
        caseGuid: string
    ) => {
        this.addCasesToPackageSettlement([caseGuid]);
    };

    addAllSelectedCasesToPackageSettlement = () => {
        this.addCasesToPackageSettlement(this.state.selectedCaseGuids);
    };

    addCasesToPackageSettlement = (cases: string[]) => {
        const packageSettlement = this.state.packageSettlement;
        if (cases.length > 0 && !packageSettlement.cases) packageSettlement.cases = [];

        cases.map((item: string) => {
            if (this.state.caseSearchResults) {
                const match = this.state.caseSearchResults!.find((x) => x.caseGuid == item);
                if (match) packageSettlement.cases!.push(JSON.parse(JSON.stringify(match)));
            }
        });

        this.setState({
            packageSettlement: packageSettlement,
            selectedCaseGuids: [],
            selectAllCases: false,
            caseSearchResults: undefined,
            showAddCasesOptionsDialog: false,
        });
    };

    handleEasyUpdate = (e: React.FormEvent<HTMLButtonElement>, caseGuid: string) => {
        const easyUpdateAttemptedCaseList = this.state.easyUpdateAttemptedCaseList;
        easyUpdateAttemptedCaseList.push(caseGuid);
        this.setState({
            easyUpdateMode: true,
            easyUpdateCaseGuid: caseGuid,
            easyUpdateAttemptedCaseList: easyUpdateAttemptedCaseList,
        });
    };

    easyUpdateAttempted = (caseGuid: string) => {
        return this.state.easyUpdateAttemptedCaseList.filter((x) => x == caseGuid).length > 0;
    };

    easyUpdateDone = (updatedCase: ICaseModel) => {
        if (!this.state.packageSettlement || !this.state.packageSettlement.cases) return;

        const packageSettlement = this.state.packageSettlement;
        const match = packageSettlement.cases!.find((x) => x.caseGuid == updatedCase.guid);
        if (match) {
            match.caseStatus = updatedCase.caseStatus;
            match.caseNumber = updatedCase.caseNumber!;
            match.caseName =
                CaseHelper.getAssertionTextByName(
                    updatedCase,
                    AssertionDescriptors.InjuredPartyLastName.Name
                ) +
                ', ' +
                CaseHelper.getAssertionTextByName(
                    updatedCase,
                    AssertionDescriptors.InjuredPartyFirstName.Name
                );
            match.plaintiffsFirm = CaseHelper.getAssertionTextByName(
                updatedCase,
                AssertionDescriptors.PlainfirmFirmLocal.Name
            );
            match.jurisdiction = CaseHelper.getAssertionTextByName(
                updatedCase,
                AssertionDescriptors.Jurisdiction.Name
            );
            match.diagnosis = CaseHelper.getAssertionTextByName(
                updatedCase,
                AssertionDescriptors.Diagnosis.Name
            );

            const amountString = CaseHelper.getAssertionTextByName(
                updatedCase,
                AssertionDescriptors.SettlementAmount.Name
            );
            match.actualSettlement = amountString ? parseInt(amountString) : undefined;

            match.secondaryPlaintiffsFirm = CaseHelper.getAssertionTextByName(
                updatedCase,
                AssertionDescriptors.PlainfirmFirmSecondary.Name
            );
            match.age = CaseHelper.getAssertionTextByName(
                updatedCase,
                AssertionDescriptors.CalculatedAgeRange.Name
            );
            match.livingDeceased = CaseHelper.getAssertionTextByName(
                updatedCase,
                AssertionDescriptors.LivingDeceased.Name
            );
            match.trialDate = Common.dateFormat(
                CaseHelper.getAssertionTextByName(updatedCase, AssertionDescriptors.TrialDate.Name)
            );
            match.occupation = CaseHelper.getAssertionTextByName(
                updatedCase,
                AssertionDescriptors.Occupation.Name
            );
            match.expectedOutcome = CaseHelper.getAssertionTextByName(
                updatedCase,
                AssertionDescriptors.ExpectedOutcome.Name
            );

            const projectedSettlementAmountString = CaseHelper.getAssertionTextByName(
                updatedCase,
                AssertionDescriptors.ProjectedSettlementAmount.Name
            );
            match.projectedSettlement = projectedSettlementAmountString
                ? parseInt(projectedSettlementAmountString)
                : undefined;
        }

        this.setState({
            easyUpdateMode: false,
            easyUpdateCaseGuid: undefined,
            packageSettlement: packageSettlement,
        });

        this.refreshStats();
    };

    generateAndDownloadReport = async (exportCases?: boolean) => {
        this.setState({ pendingResponse: true, validation: {} });

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        if (exportCases) {
            xhr.open(
                'GET',
                ApiRoutes.PackageSettlementDetail.replace(
                    '{id}',
                    this.state.packageSettlement.guid!
                ) + '?exportCases=true',
                true
            );
        } else {
            xhr.open('POST', '/' + ApiRoutes.ExportReport, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
        }

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this.setState({ pendingResponse: false, validation: {} });
                let fileName = exportCases
                    ? 'PackageSettlementCaseDetails.csv'
                    : 'PackageSettlementDetails.pdf';
                const blob = xhr.response;

                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    const contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (let i = 0; i < contentDispositionItems.length; i++) {
                            const currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                const n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                const validation = this.state.validation;
                validation.model = [DisplayMessages.ReportGenerateError];
                this.setState({ pendingResponse: false, validation: validation });
            }
        };

        if (exportCases) {
            xhr.send();
        } else {
            const reportParameters: IReportParametersBaseModel = {
                reportType: {
                    id:
                        this.state.packageSettlement.status!.id ==
                        PackageSettlementStatusTypesEnum.Complete
                            ? ReportTypesEnum.PackageSettlementDetail.Value
                            : ReportTypesEnum.PackageSettlementDetail.Value,
                },
                entityGuid: this.state.packageSettlement.guid,
            };

            xhr.send(JSON.stringify(reportParameters));
        }
    };

    getCaseExportColumns = () => {
        // AR - TO DO - Better way to store this JSON
        const result: IQueryFilterCriteria[] = JSON.parse(
            '[ { "label": "Case Status", "value": "CaseStatus", "staticItemId": 1}, { "label": "Projected Resolution", "value": "Expected Outcome", "assertionDescriptorGuid": "8a406d6e-478f-497e-bc7f-d089c19930a2", "isAssertionDescriptor": true }, { "label": "Final Settlement", "value": "Actual Settlement", "assertionDescriptorGuid": "44c0acec-adcb-4eb4-abc4-10f6ef4b370b", "isAssertionDescriptor": true }, { "label": "National Plaintiffs Firm", "value": "Plaintiffs firm - Secondary", "assertionDescriptorGuid": "640bbbbd-d12f-4c8f-9a2d-9d79c2bff954", "isAssertionDescriptor": true }, { "label": "Plaintiffs Firm", "value": "Plaintiffs firm - local", "assertionDescriptorGuid": "d8c65132-f8d7-4fba-a1cc-005dd9def8c2", "isAssertionDescriptor": true }, { "label": "Jurisdiction", "value": "Jurisdiction", "assertionDescriptorGuid": "b93f4046-de24-44e8-89a8-310c19c8d0d2", "isAssertionDescriptor": true }, { "label": "Disease", "value": "Diagnosis", "assertionDescriptorGuid": "72c60f8c-93bf-4ceb-bdc5-fe666bfc32b4", "isAssertionDescriptor": true }, { "label": "Trial Date", "value": "Trial Date", "assertionDescriptorGuid": "333a857a-9151-429e-9ebd-d17756e7f15f", "isAssertionDescriptor": true }, { "label": "Age", "value": "Injured Party Age", "assertionDescriptorGuid": "a61dc182-9553-4e25-a09d-b268cf8c1cba", "isAssertionDescriptor": true }, { "label": "Injured Party living/deceased", "value": "Plaintiff living/deceased", "assertionDescriptorGuid": "ccff8542-0470-4f03-ba27-66a03b9e6e18", "isAssertionDescriptor": true }, { "label": "Occupation", "value": "Occupation", "assertionDescriptorGuid": "aab1086a-2e5b-4db0-ae45-407362e1456e", "isAssertionDescriptor": true } ]'
        );
        return result;
    };

    getCaseExportFilterCriteria = () => {
        const item: IQueryFilterCriteria = JSON.parse(
            '{"label":"Package Deal","value":"PackageSettlement", "readOnly":true,"exactTextMatch":true,"booleanValue":true,"validationError":"","required":true}'
        );
        const { name, guid } = this.state.packageSettlement;
        item.staticItemId = CaseFileStaticItems.PackageSettlement.Id;
        item.selectedValues = [
            {
                guid,
                label: name ?? '',
                value: guid ?? '',
            },
        ];
        return [item];
    };

    getQueryToolFilterCriteriaToSelectCases = () => {
        if (!this.state.packageSettlement.firms) return;

        const item: IQueryFilterCriteria = {
            label: AssertionDescriptors.PlainfirmFirmLocal.DisplayName,
            value: AssertionDescriptors.PlainfirmFirmLocal.Name,
            assertionDescriptorGuid: AssertionDescriptors.PlainfirmFirmLocal.Guid,
            isAssertionDescriptor: true,
            selectedValues: [],
            required: true,
            readOnly: true,
        };

        this.state.packageSettlement.firms.map((firm: IPackageSettlementFirmModel) => {
            item.selectedValues!.push({
                label: firm.name || '',
                value: firm.guid,
                guid: firm.guid,
            });
        });

        return [item];
    };

    sortData = (
        key: string,
        order: string,
        subKey?: string,
        subGrandKey?: string,
        dataItem?: number
    ) => {
        switch (dataItem) {
            case DataItems.PackageSettlementCases:
                this.sortPackageSettlementCases(key, order, subKey);
                break;
            case DataItems.CaseSearchResults:
                this.sortCaseSearchResults(key, order, subKey);
                break;
        }
    };

    sortPackageSettlementCases = (key: string, order: string, subKey?: string) => {
        this.setState({ pendingResponse: true });

        if (this.state.packageSettlement) {
            const packageSettlement = this.state.packageSettlement;
            if (packageSettlement.cases) {
                packageSettlement.cases = packageSettlement.cases.sort(
                    Sort.compareValues(key, subKey, order)
                );
                this.setState({ packageSettlement: packageSettlement, pendingResponse: false });
            }
        }
    };

    sortCaseSearchResults = (key: string, order: string, subKey?: string) => {
        this.setState({ pendingResponse: true });

        if (this.state.caseSearchResults) {
            const caseSearchResults = this.state.caseSearchResults.sort(
                Sort.compareValues(key, subKey, order)
            );
            this.setState({ caseSearchResults: caseSearchResults, pendingResponse: false });
        }
    };

    sortDate = (key: string, order: string, subKey?: string) => {
        this.setState({ pendingResponse: true });

        if (this.state.packageSettlement) {
            const packageSettlement = this.state.packageSettlement;
            if (packageSettlement.cases) {
                packageSettlement.cases = packageSettlement.cases.sort(
                    Sort.compareDate(key, subKey, order)
                );
                this.setState({ packageSettlement: packageSettlement, pendingResponse: false });
            }
        }
    };

    selectLabel = (e: any, label: string) => {
        if (!e.value || e.value.length === 0) {
            return <span className="unselectClass css-1v99tuv">--{label}--</span>;
        }

        if (e.value.length === 1) {
            return <span className="css-1v99tuv">{e.value[0].label}</span>;
        } else {
            return <span className="css-1v99tuv">{e.value.length} selected</span>;
        }
    };

    handleClearDescription = () => {
        const packageSettlement = this.state.packageSettlement;
        packageSettlement.description = '';
        this.setState({ packageSettlement: packageSettlement });
    };

    addNewValueAddItem = () => {
        if (this.state.packageSettlement) {
            const packageSettlement = this.state.packageSettlement;
            const existingIds = packageSettlement.valueAddDetails.map(
                (item: IPackageDealValueAddModel) => {
                    return item.correlationKeyId;
                }
            );
            const maxCorrelationId = existingIds.length > 0 ? Math.max(...existingIds) : 0;

            let valueAddAD: IAssertionDescriptorModel = {};
            let valueAddNotesAD: IAssertionDescriptorModel = {};

            if (packageSettlement.valueAddDetails.length > 0) {
                valueAddAD = packageSettlement.valueAddDetails[0].valueAdd.assertionDescriptor;
                valueAddNotesAD = packageSettlement.valueAddDetails[0].notes.assertionDescriptor;
            } else {
                valueAddAD = this.state.assertionDescriptors.filter(
                    (x) =>
                        x.guid &&
                        x.guid.toUpperCase() === AssertionDescriptors.ValueAdd.Guid.toUpperCase()
                )[0];
                valueAddNotesAD = this.state.assertionDescriptors.filter(
                    (x) =>
                        x.guid &&
                        x.guid.toUpperCase() ===
                            AssertionDescriptors.ValueAddNotes.Guid.toUpperCase()
                )[0];
            }

            packageSettlement.valueAddDetails.push({
                correlationKeyId: maxCorrelationId + 1,
                valueAdd: {
                    correlationKeyId: maxCorrelationId + 1,
                    assertionDescriptor: valueAddAD,
                    entityType: { id: EntityTypes.PackageSettlement },
                    entityGuid: packageSettlement.guid || EmptyGuid,
                },
                notes: {
                    correlationKeyId: maxCorrelationId + 1,
                    assertionDescriptor: valueAddNotesAD,
                    entityType: { id: EntityTypes.PackageSettlement },
                    entityGuid: packageSettlement.guid || EmptyGuid,
                },
            });

            this.setState({ packageSettlement: packageSettlement });
        }
    };

    handleNarrativeFieldEdit = (item: IEntityAssertionModel) => {
        if (this.state.packageSettlement) {
            const packageSettlement = this.state.packageSettlement;

            for (let i = 0; i < packageSettlement.valueAddDetails.length; i++) {
                const valueAddItem = packageSettlement.valueAddDetails[i];
                if (
                    valueAddItem.valueAdd.guid === item.guid &&
                    valueAddItem.valueAdd.assertionDescriptor &&
                    item.assertionDescriptor &&
                    valueAddItem.valueAdd.assertionDescriptor.guid ===
                        item.assertionDescriptor.guid &&
                    valueAddItem.valueAdd.entityType &&
                    item.entityType &&
                    valueAddItem.valueAdd.entityType.id === item.entityType.id &&
                    valueAddItem.valueAdd.entityId === item.entityId &&
                    valueAddItem.valueAdd.correlationKeyId === item.correlationKeyId
                ) {
                    this.setState({
                        currentNarrativeCaseAssertionOnEditMode: JSON.parse(
                            JSON.stringify(valueAddItem.valueAdd)
                        ),
                    });
                    break;
                } else if (
                    valueAddItem.notes.guid === item.guid &&
                    valueAddItem.notes.assertionDescriptor &&
                    item.assertionDescriptor &&
                    valueAddItem.notes.assertionDescriptor.guid === item.assertionDescriptor.guid &&
                    valueAddItem.notes.entityType &&
                    item.entityType &&
                    valueAddItem.notes.entityType.id === item.entityType.id &&
                    valueAddItem.notes.entityId === item.entityId &&
                    valueAddItem.notes.correlationKeyId === item.correlationKeyId
                ) {
                    this.setState({
                        currentNarrativeCaseAssertionOnEditMode: JSON.parse(
                            JSON.stringify(valueAddItem.notes)
                        ),
                    });
                    break;
                }
            }
        }
    };

    handleClearNarrativeFieldEdit = () => {
        if (this.state.currentNarrativeCaseAssertionOnEditMode) {
            const currentNarrativeCaseAssertionOnEditMode =
                this.state.currentNarrativeCaseAssertionOnEditMode;
            currentNarrativeCaseAssertionOnEditMode.textValue = '';
            this.setState({
                currentNarrativeCaseAssertionOnEditMode: currentNarrativeCaseAssertionOnEditMode,
            });
        }
    };

    handleNarrativeFieldTextChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (this.state.currentNarrativeCaseAssertionOnEditMode) {
            const currentNarrativeCaseAssertionOnEditMode =
                this.state.currentNarrativeCaseAssertionOnEditMode;
            currentNarrativeCaseAssertionOnEditMode.textValue = event.target.value;
            this.setState({
                currentNarrativeCaseAssertionOnEditMode: currentNarrativeCaseAssertionOnEditMode,
            });
        }
    };

    finalizeChange = (modifiedAssertions: IEntityAssertionModel[]) => {
        if (this.state.packageSettlement && modifiedAssertions.length == 1) {
            const packageSettlement = this.state.packageSettlement;

            for (let i = 0; i < packageSettlement.valueAddDetails.length; i++) {
                const valueAddItem = packageSettlement.valueAddDetails[i];
                if (
                    valueAddItem.valueAdd.guid === modifiedAssertions[0].guid &&
                    valueAddItem.valueAdd.assertionDescriptor &&
                    modifiedAssertions[0].assertionDescriptor &&
                    valueAddItem.valueAdd.assertionDescriptor.guid ===
                        modifiedAssertions[0].assertionDescriptor.guid &&
                    valueAddItem.valueAdd.entityType &&
                    modifiedAssertions[0].entityType &&
                    valueAddItem.valueAdd.entityType.id === modifiedAssertions[0].entityType.id &&
                    valueAddItem.valueAdd.entityId === modifiedAssertions[0].entityId &&
                    valueAddItem.valueAdd.correlationKeyId ===
                        modifiedAssertions[0].correlationKeyId
                ) {
                    valueAddItem.valueAdd.textValue = modifiedAssertions[0].textValue;
                    valueAddItem.valueAdd.booleanValue = modifiedAssertions[0].booleanValue;
                    valueAddItem.valueAdd.numericValue = modifiedAssertions[0].numericValue;
                    valueAddItem.valueAdd.dateTimeValue = modifiedAssertions[0].dateTimeValue;
                    valueAddItem.valueAdd.assertionDescriptorValue =
                        modifiedAssertions[0].assertionDescriptorValue;
                    break;
                } else if (
                    valueAddItem.notes.guid === modifiedAssertions[0].guid &&
                    valueAddItem.notes.assertionDescriptor &&
                    modifiedAssertions[0].assertionDescriptor &&
                    valueAddItem.notes.assertionDescriptor.guid ===
                        modifiedAssertions[0].assertionDescriptor.guid &&
                    valueAddItem.notes.entityType &&
                    modifiedAssertions[0].entityType &&
                    valueAddItem.notes.entityType.id === modifiedAssertions[0].entityType.id &&
                    valueAddItem.notes.entityId === modifiedAssertions[0].entityId &&
                    valueAddItem.notes.correlationKeyId === modifiedAssertions[0].correlationKeyId
                ) {
                    valueAddItem.notes.textValue = modifiedAssertions[0].textValue;
                    valueAddItem.notes.booleanValue = modifiedAssertions[0].booleanValue;
                    valueAddItem.notes.numericValue = modifiedAssertions[0].numericValue;
                    valueAddItem.notes.dateTimeValue = modifiedAssertions[0].dateTimeValue;
                    valueAddItem.notes.assertionDescriptorValue =
                        modifiedAssertions[0].assertionDescriptorValue;
                    break;
                }
            }

            this.setState({
                packageSettlement: packageSettlement,
                currentNarrativeCaseAssertionOnEditMode: undefined,
            });
        }
    };

    refreshStats = () => {
        const packageSettlement = this.state.packageSettlement;
        if (packageSettlement.cases && packageSettlement.cases.length > 0) {
            const cases = packageSettlement.cases.filter(
                (item) => item.caseStatus && item.caseStatus.status
            );
            const allNonResolvedCases = cases.filter(
                (item) => item.caseStatus!.status.id !== CaseStatusTypesEnum.Resolved.Value
            );

            let settlementAmount = 0;
            cases
                .filter(
                    (item) =>
                        item.caseStatus!.subStatus &&
                        item.caseStatus!.status.id === CaseStatusTypesEnum.Resolved.Value &&
                        item.caseStatus!.subStatus.id === CaseSubStatusTypesEnum.Settled.Value
                )
                .map((item: IReportCaseDetailModel) => {
                    settlementAmount += item.actualSettlement || 0;
                });

            packageSettlement.caseStats = {
                total: cases.length,
                settled: cases.filter(
                    (item) =>
                        item.caseStatus!.subStatus &&
                        item.caseStatus!.status.id === CaseStatusTypesEnum.Resolved.Value &&
                        item.caseStatus!.subStatus.id === CaseSubStatusTypesEnum.Settled.Value
                ).length,
                dismissed: cases.filter(
                    (item) =>
                        item.caseStatus!.subStatus &&
                        item.caseStatus!.status.id === CaseStatusTypesEnum.Resolved.Value &&
                        (item.caseStatus!.subStatus.id === CaseSubStatusTypesEnum.DWOP.Value ||
                            item.caseStatus!.subStatus.id ===
                                CaseSubStatusTypesEnum.InvoluntaryDismissal.Value)
                ).length,
            };

            if (
                packageSettlement.status &&
                packageSettlement.status.id === PackageSettlementStatusTypesEnum.Complete
            ) {
                packageSettlement.caseStats.alt = cases.filter(
                    (item) =>
                        item.caseStatus!.subStatus &&
                        item.caseStatus!.status.id === CaseStatusTypesEnum.Alt.Value &&
                        item.caseStatus!.subStatus.id === CaseSubStatusTypesEnum.Monitored.Value
                ).length;
            } else {
                packageSettlement.caseStats.dismissed =
                    (packageSettlement.caseStats.dismissed || 0) +
                    allNonResolvedCases.filter(
                        (item) =>
                            item.expectedOutcome &&
                            item.expectedOutcome.toUpperCase() ===
                                ExpectedOutcomeTexts.Dismissal.Text
                    ).length;
                packageSettlement.caseStats.settled =
                    (packageSettlement.caseStats.settled || 0) +
                    allNonResolvedCases.filter(
                        (item) =>
                            item.expectedOutcome &&
                            item.expectedOutcome.toUpperCase() ==
                                ExpectedOutcomeTexts.Settlement.Text
                    ).length;
                packageSettlement.caseStats.alt = allNonResolvedCases.filter(
                    (item) =>
                        item.expectedOutcome &&
                        item.expectedOutcome.toUpperCase() == ExpectedOutcomeTexts.Alt.Text
                ).length;
                allNonResolvedCases.map((item: IReportCaseDetailModel) => {
                    settlementAmount += item.projectedSettlement || 0;
                });
            }

            packageSettlement.caseStats.settlement = settlementAmount;

            this.setState({ packageSettlement: packageSettlement });
        }
    };

    handleExportError = () => {
        const validation = this.state.validation;
        validation.model = [DisplayMessages.UnexpectedError];
        this.setState({ pendingResponse: false, validation: validation });
    };

    handleCancelNoteEditor = () => {
        this.setState({
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            openNoteEditor: false,
        });
    };

    onSaveNoteComplete = (noteItem: INoteModel) => {
        let matchFound = false;
        const packageSettlement = this.state.packageSettlement;
        for (let i = 0; i < packageSettlement!.notes.length; i++) {
            if (packageSettlement!.notes[i].guid == noteItem.guid) {
                matchFound = true;
                packageSettlement!.notes[i] = JSON.parse(JSON.stringify(noteItem));
                break;
            }
        }

        if (!matchFound) packageSettlement!.notes.push(JSON.parse(JSON.stringify(noteItem)));
        packageSettlement!.notes = packageSettlement!.notes.sort(
            Sort.compareDate('modifiedDate', undefined, 'desc')
        );
        this.setState({ packageSettlement: packageSettlement, openNoteEditor: false });
    };

    handleEditNote = (noteGuidToEdit: string) => {
        const validation: IValidation = {};

        const currentNote = this.state.packageSettlement!.notes.find(
            (x) => x.guid == noteGuidToEdit
        );
        if (!currentNote) {
            validation.model = [DisplayMessages.UnexpectedError];
            this.setState({ pendingResponse: false, validation: validation });
            return;
        }

        this.setState({
            currentNote: JSON.parse(JSON.stringify(currentNote)),
            openNoteEditor: true,
        });
    };

    refreshNotes = (notes: INoteModel[]) => {
        const packageSettlement = this.state.packageSettlement;
        if (packageSettlement) {
            packageSettlement.notes = notes;
            this.setState({ packageSettlement: packageSettlement });
        }
    };

    onClickAddNote = () => {
        const currentNote: INoteModel = {
            type: { id: NoteTypes.PackageSettlementNote },
            status: { id: NoteStatusTypes.Open },
            parent: {
                entityType: { id: EntityTypes.PackageSettlement },
                entityGuid: this.state.packageSettlement!.guid,
            },
            purpose: { id: NotePurposeTypesEnum.Info },
        };

        this.setState({ openNoteEditor: true, currentNote: currentNote });
    };

    handleShowLockPackageSettlement = () => {
        this.setState({ showLockPackageSettlementDialog: true });
    };

    handleShowUnlockPackageSettlement = () => {
        this.setState({ showUnlockPackageSettlementDialog: true });
    };

    handleLockPackageSettlement = async () => {
        const packageSettlement = this.state.packageSettlement;
        packageSettlement.locked = true;
        this.setState({
            packageSettlement: packageSettlement,
            showLockPackageSettlementDialog: false,
        });
        await this.savePackageSettlement();
    };

    handleUnlockPackageSettlement = async () => {
        const packageSettlement = this.state.packageSettlement;
        packageSettlement.locked = false;
        this.setState({
            packageSettlement: packageSettlement,
            showUnlockPackageSettlementDialog: false,
        });
        await this.savePackageSettlement();
    };

    handleChkEasyUpdateChange = (checked: boolean) => {
        this.setState({ enableEasyUpdate: checked });
    };

    handleCloseShowAllCasesLockedDialog = () => {
        this.setState({ showAllCasesLockedDialog: false });
    };

    handleDocumentSaveComplete = (document: IDocumentModel) => {
        const packageSettlementCopy = { ...this.state.packageSettlement };
        const docsCopy = [...(packageSettlementCopy?.documents ?? [])];

        const foundIdx = docsCopy.findIndex((doc) => doc.guid === document.guid) ?? -1;

        if (foundIdx > -1) {
            docsCopy[foundIdx] = document;
        } else {
            docsCopy.push(document);
        }

        docsCopy.sort(Sort.compareDate('modifiedDate', undefined, 'desc'));
        packageSettlementCopy.documents = docsCopy;

        this.setState({ packageSettlement: packageSettlementCopy });
    };

    handleDocumentDeleteComplete = (documentGuid: string) => {
        const packageSettlementCopy = { ...this.state.packageSettlement };
        const docsCopy = [...(packageSettlementCopy?.documents ?? [])];

        const filteredDocsCopy = docsCopy.filter((doc) => doc.guid !== documentGuid);

        filteredDocsCopy.sort(Sort.compareDate('modifiedDate', undefined, 'desc'));

        packageSettlementCopy.documents = filteredDocsCopy;

        this.setState({ packageSettlement: packageSettlementCopy });
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;

        if (this.state.openNoteEditor)
            return (
                <NoteEditor
                    readOnly={NoteHelper.isNoteReadOnly(
                        this.state.currentNote,
                        this.props.user,
                        Authorization.userHasRight(
                            UserRightsEnum.ViewPackageDealNote,
                            this.props.user
                        ),
                        !this.state.packageSettlement.locked &&
                            Authorization.userHasRight(
                                UserRightsEnum.UpdatePackageDealNote,
                                this.props.user
                            ),
                        !this.state.packageSettlement.locked &&
                            Authorization.userHasRight(
                                UserRightsEnum.AddPackageDealNote,
                                this.props.user
                            )
                    )}
                    user={this.props.user}
                    originEntityName={this.state.packageSettlement.name}
                    noteCategoryTypes={this.state.noteCategoryTypes}
                    currentNote={this.state.currentNote}
                    handleSaveComplete={this.onSaveNoteComplete}
                    handleCancel={this.handleCancelNoteEditor}
                />
            );

        const authorizedToRunReport = Authorization.userHasRight(
            UserRightsEnum.Reports,
            this.props.user
        );

        return (
            <div>
                {Authorization.userHasRight(UserRightsEnum.EditCaseFile, this.props.user) &&
                this.state.packageSettlement.cases &&
                this.state.packageSettlement.cases.length > 0 ? (
                    <CaseEasyUpdate
                        user={this.props.user}
                        caseGuid={this.state.easyUpdateCaseGuid}
                        onComplete={this.easyUpdateDone}
                        onCancel={() =>
                            this.setState({ easyUpdateMode: false, easyUpdateCaseGuid: undefined })
                        }
                        hidden={!this.state.easyUpdateMode}
                    />
                ) : null}
                {this.state.easyUpdateMode ? null : (
                    <>
                        <div className="row mb-1 me-2">
                            <h1 className="col-sm-4">
                                {this.state.packageSettlement.guid
                                    ? this.state.packageSettlement.name
                                    : 'Add New Package Deal'}
                            </h1>
                            <div className="col-sm-8">
                                {this.state.readOnly && authorizedToRunReport && (
                                    <span className="btn btn-orange float-end">
                                        {' '}
                                        <Link
                                            to={
                                                LocalRoutes.PackageSettlementDetailExportID +
                                                this.state.packageSettlement.guid!
                                            }
                                            style={{ color: 'white' }}
                                        >
                                            Run Report
                                        </Link>
                                    </span>
                                )}

                                {Authorization.userHasRight(
                                    UserRightsEnum.AddEditDeletePackageSettlement,
                                    this.props.user
                                ) ? (
                                    <>
                                        {this.state.packageSettlement.locked &&
                                        Authorization.userHasRight(
                                            UserRightsEnum.LockUnlockPackageDeal,
                                            this.props.user
                                        ) ? (
                                            <button
                                                className="btn btn-no-bg float-end text-gray"
                                                onClick={() =>
                                                    this.handleShowUnlockPackageSettlement()
                                                }
                                            >
                                                <i className="fal fa-unlock" />
                                                &nbsp;Unlock
                                            </button>
                                        ) : null}
                                        {!this.state.packageSettlement.locked &&
                                        Authorization.userHasRight(
                                            UserRightsEnum.LockUnlockPackageDeal,
                                            this.props.user
                                        ) ? (
                                            <button
                                                className="btn btn-no-bg float-end text-gray"
                                                onClick={() =>
                                                    this.handleShowLockPackageSettlement()
                                                }
                                            >
                                                <i className="fal fa-lock" />
                                                &nbsp;Lock
                                            </button>
                                        ) : null}
                                        {this.state.readOnly ? (
                                            !this.state.packageSettlement.locked ? (
                                                <button
                                                    className="btn btn-no-bg float-end text-gray"
                                                    onClick={() =>
                                                        this.setState({
                                                            readOnly: false,
                                                            enableEasyUpdate: false,
                                                        })
                                                    }
                                                >
                                                    <i className="fal fa-edit" />
                                                    &nbsp;Edit
                                                </button>
                                            ) : null
                                        ) : (
                                            <>
                                                <button
                                                    className="btn btn-default float-end"
                                                    onClick={this.cancelEdit}
                                                >
                                                    Cancel
                                                </button>
                                                <input
                                                    type="button"
                                                    className="btn btn-orange float-end me-2"
                                                    onClick={() => this.savePackageSettlement()}
                                                    value="Save"
                                                />
                                            </>
                                        )}
                                    </>
                                ) : null}
                                {this.state.readOnly &&
                                this.state.packageSettlement.guid &&
                                this.state.packageSettlement.cases &&
                                this.state.packageSettlement.cases.length > 0 ? (
                                    <Link
                                        to={{ pathname: LocalRoutes.Query2 }}
                                        state={{
                                            redirectModel: {
                                                queryFilterCriteriaSelected:
                                                    this.getCaseExportFilterCriteria(),
                                                displayColumnsSelected: this.getCaseExportColumns(),
                                                title:
                                                    'Export Case Details | ' +
                                                    this.state.packageSettlement.name,
                                                redirectUrl:
                                                    window.location.pathname +
                                                    window.location.search,
                                                redirectType: QueryToolRedirectTypeEnum.PackageDeal,
                                                showSelectAllCheckbox: false,
                                            },
                                        }}
                                    >
                                        <span className="btn btn-no-bg float-end text-gray">
                                            <i className="fal fa-file-export" />
                                            &nbsp;Export Cases
                                        </span>
                                    </Link>
                                ) : null}
                                {!this.state.packageSettlement.locked &&
                                Authorization.userHasRight(
                                    UserRightsEnum.EditCaseFile,
                                    this.props.user
                                ) &&
                                this.state.readOnly &&
                                this.state.packageSettlement.guid &&
                                this.state.packageSettlement.cases &&
                                this.state.packageSettlement.cases.length > 0 ? (
                                    <span className="btn btn-no-bg float-end text-gray ps-3">
                                        <input
                                            name="chkEasyUpdate"
                                            type="checkbox"
                                            className="form-check-input"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleChkEasyUpdateChange(e.target.checked);
                                            }}
                                            checked={this.state.enableEasyUpdate}
                                        />
                                        <i className="fal fa-edit ps-2 pe-1" />
                                        Easy Update
                                    </span>
                                ) : null}
                                <a
                                    className="btn btn-no-bg float-end text-gray"
                                    href={window.location.pathname + '#Notes'}
                                >
                                    <i className="fal fa-memo" />
                                    &nbsp;Deal Notes
                                </a>
                            </div>
                        </div>
                        <span className="text-danger">{this.state.validation.model}</span>

                        <div className="row mb-2">
                            <label className="col-form-label text-gray col-sm-2">Name*</label>
                            <div className="col-sm-5 ">
                                {this.state.readOnly ? (
                                    <label className="col-form-label fw-normal">
                                        {this.state.packageSettlement.name}
                                    </label>
                                ) : (
                                    <div style={{ display: 'inline-block' }}>
                                        <input
                                            className="form-control"
                                            style={{ display: 'inline-block' }}
                                            type="text"
                                            name="name"
                                            value={this.state.packageSettlement.name || ''}
                                            onChange={this.changeValue}
                                        />
                                        <span className="text-danger ">
                                            {this.state.validation.name}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label text-gray col-sm-2">Status*</label>
                            <div className="col-sm-2 ">
                                {this.state.readOnly ? (
                                    <label className="col-form-label fw-normal">
                                        {this.state.packageSettlement.status
                                            ? this.state.packageSettlement.status.displayName
                                            : ''}
                                    </label>
                                ) : (
                                    <>
                                        <CheckmateSelect
                                            options={CheckmateSelectHelper.getLookupOptions(
                                                this.state.statuses
                                            )}
                                            name="status"
                                            value={CheckmateSelectHelper.getSelectedValueById(
                                                this.state.statuses,
                                                this.state.packageSettlement &&
                                                    this.state.packageSettlement.status
                                                    ? this.state.packageSettlement.status.id
                                                    : 0
                                            )}
                                            onChange={this.handleStatusChange}
                                        />
                                    </>
                                )}
                            </div>
                            <div className="col-sm-6 text-danger ">
                                {this.state.validation.status}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label text-gray col-sm-2">
                                Initial Date*
                            </label>
                            <div className="col-sm-5 ">
                                {this.state.readOnly ? (
                                    <label className="col-form-label fw-normal">
                                        {Common.dateFormat(
                                            this.state.packageSettlement.initialDate
                                        )}
                                    </label>
                                ) : (
                                    <div style={{ display: 'inline-block' }}>
                                        <input
                                            type="date"
                                            value={Common.dateFormat(
                                                this.state.packageSettlement.initialDate
                                            )}
                                            name="initialDate"
                                            onChange={this.changeValue}
                                            className={
                                                'form-control d-inline-block' +
                                                (this.state.packageSettlement.initialDate &&
                                                Common.isValidDate(
                                                    this.state.packageSettlement.initialDate
                                                )
                                                    ? ''
                                                    : ' unselectClass')
                                            }
                                        />
                                        <span className="text-danger ">
                                            {this.state.validation.initialDate}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label text-gray col-sm-2">Final Date</label>
                            <div className="col-sm-5 ">
                                {this.state.readOnly ? (
                                    <label className="col-form-label fw-normal">
                                        {Common.dateFormat(this.state.packageSettlement.finalDate)}
                                        &nbsp;
                                    </label>
                                ) : (
                                    <div style={{ display: 'inline-block' }}>
                                        <input
                                            type="date"
                                            className={
                                                'form-control d-inline-block' +
                                                (this.state.packageSettlement.finalDate &&
                                                Common.isValidDate(
                                                    this.state.packageSettlement.finalDate
                                                )
                                                    ? ''
                                                    : ' unselectClass')
                                            }
                                            value={Common.dateFormat(
                                                this.state.packageSettlement.finalDate
                                            )}
                                            name="finalDate"
                                            onChange={this.changeValue}
                                        />
                                        <span className="text-danger ">
                                            {this.state.validation.finalDate}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-form-label text-gray col-sm-2">Notes</label>
                            <div className="col-sm-10 ">
                                {this.state.readOnly ? (
                                    <TextWithLineBreaks
                                        text={this.state.packageSettlement.description || ''}
                                    ></TextWithLineBreaks>
                                ) : (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={() => this.setState({ notesOnEditMode: true })}
                                        title={this.state.packageSettlement.description}
                                    >
                                        <i
                                            className={
                                                this.state.packageSettlement.description
                                                    ? 'fal fa-edit color-black'
                                                    : 'fal fa-edit color-gray'
                                            }
                                        />
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-sm-2">
                                <label className="col-form-label text-gray">Value Add</label>&nbsp;{' '}
                                <Help
                                    type={ApplicationHelpType.Info}
                                    title="Value Add"
                                    helpText="Reflect the value add of the entire deal and provide context or details, i.e. application to specific cases or jurisdiction, timeframe for extensions, application to specific witnesses or documents or motions etc."
                                />
                                {this.state.readOnly ? null : (
                                    <button
                                        className="btn btn-default btn-icon"
                                        onClick={this.addNewValueAddItem}
                                    >
                                        <i className="fal fa-lg fa-plus" />
                                    </button>
                                )}
                            </div>
                            <div className="col-sm-10 pt-2">
                                <table className="table">
                                    <thead>
                                        <th className="col-sm-3">Value Add</th>
                                        <th className="col-sm-9">Notes</th>
                                    </thead>
                                    <tbody>
                                        {this.state.packageSettlement.valueAddDetails!.map(
                                            (item: IPackageDealValueAddModel, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="col-sm-3 align-top">
                                                            <EntityAssertionControl
                                                                assertionDescriptor={
                                                                    item.valueAdd
                                                                        .assertionDescriptor
                                                                }
                                                                entityAssertions={[item.valueAdd]}
                                                                editMode={!this.state.readOnly}
                                                                refreshChange={this.finalizeChange}
                                                                handleNarrativeFieldEdit={
                                                                    this.handleNarrativeFieldEdit
                                                                }
                                                                narrativeFieldAsPopup={true}
                                                            />
                                                        </td>
                                                        <td className="col-sm-9 align-top">
                                                            <EntityAssertionControl
                                                                assertionDescriptor={
                                                                    item.notes.assertionDescriptor
                                                                }
                                                                entityAssertions={[item.notes]}
                                                                editMode={!this.state.readOnly}
                                                                refreshChange={this.finalizeChange}
                                                                handleNarrativeFieldEdit={
                                                                    this.handleNarrativeFieldEdit
                                                                }
                                                                narrativeFieldAsPopup={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div
                            className={
                                'row' +
                                (!this.state.packageSettlement.guid &&
                                (!this.state.packageSettlement.valueAddDetails ||
                                    this.state.packageSettlement.valueAddDetails.length === 0)
                                    ? ' margin-top-med'
                                    : '')
                            }
                        >
                            <label className="col-form-label text-gray col-sm-2">
                                Plaintiffs Firm(s)*
                            </label>
                            <div className="col-sm-5 pt-2">
                                {this.state.readOnly ? (
                                    this.state.packageSettlement.firms &&
                                    this.state.packageSettlement.firms.length > 0 ? (
                                        <ul>
                                            {this.state.packageSettlement.firms.map(
                                                (item: IPackageSettlementFirmModel) => {
                                                    return (
                                                        <li>
                                                            {' '}
                                                            {item.hasStrategy &&
                                                            item.plaintiffsFirmStrategyGuid ? (
                                                                <Link
                                                                    to={LocalRoutes.PlaintiffFirmStrategyDetail.replace(
                                                                        ':guid',
                                                                        item.plaintiffsFirmStrategyGuid!
                                                                    )}
                                                                    target="_blank"
                                                                >
                                                                    {item.name}
                                                                </Link>
                                                            ) : (
                                                                item.name
                                                            )}
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    ) : null
                                ) : (
                                    <div className="keep-together full-width">
                                        <CheckmateSelect
                                            isMulti={true}
                                            options={this.state.firms}
                                            value={this.getSelectedFirms()}
                                            onChange={this.handleFirmsSelected}
                                        />
                                        <span className="text-danger ">
                                            {this.state.validation.firms}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>

                        {this.state.packageSettlement.guid &&
                        this.state.packageSettlement.caseStats ? (
                            <div>
                                <label className="col-form-label text-gray col-sm-2">
                                    Case Stats
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Package Deal Case Stats"
                                        helpText="If Deal is Complete:\n\nDismissed Count: Case Count where Case Status is Resolved - Dismissed (DWOP & Involuntary Dismissal) \n\nSettled Count: Case Count where Case Status is Resolved - Settled\n\Alt Count: Case Count where Case Status is Alt - Monitored \n\nIf Deal is In Process:\n\nDismissed Count: Case Count where Case Status = Resolved - Dismissed (DWOP & Involuntary Dismissal) + Cases count where Case Status is NOT Resolved and Projected Resolution = Dismissal\n\nSettled Count: Case Count where Case Status = Resolved - Settled + Cases count where Case Status is NOT Resolved and Projected Resolution is Settlement\n\nAlt Count: Cases count where Case Status is NOT Resolved and Projected Resolution is Alt Status"
                                    />
                                </label>
                                <table className="table thick-outer-border">
                                    <thead>
                                        <tr>
                                            <th className="align-top text-center">Total Cases</th>
                                            <th className="align-top text-center">Dismissed</th>
                                            <th className="align-top text-center">Settled</th>
                                            {/*  */}
                                            <th className="align-top text-center">Alt-Monitored</th>
                                            <th className="align-top text-center">
                                                Total Settlement
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="align-top text-center">
                                        <tr>
                                            <td>{this.state.packageSettlement.caseStats.total}</td>
                                            <td>
                                                {this.state.packageSettlement.caseStats.dismissed}
                                            </td>
                                            <td>
                                                {this.state.packageSettlement.caseStats.settled}
                                            </td>
                                            <td>{this.state.packageSettlement.caseStats.alt}</td>
                                            <td>
                                                {this.state.packageSettlement.caseStats.settlement
                                                    ? Common.formatCurrency(
                                                          this.state.packageSettlement.caseStats.settlement.toString()
                                                      )
                                                    : ''}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ) : null}
                        <div>
                            {this.state.packageSettlement.guid ? (
                                <div className="col-sm-2">
                                    <label className="col-form-label text-gray">Cases</label>&nbsp;
                                    {!this.state.readOnly &&
                                    this.state.packageSettlement.firms &&
                                    this.state.packageSettlement.firms.length > 0 ? (
                                        <button
                                            className="btn btn-default btn-icon ms-2"
                                            onClick={() =>
                                                this.setState({ showAddCasesOptionsDialog: true })
                                            }
                                        >
                                            <i className="fal fa-lg fa-plus" />
                                        </button>
                                    ) : null}
                                </div>
                            ) : null}
                            {this.state.packageSettlement.guid ? (
                                this.state.packageSettlement.cases &&
                                this.state.packageSettlement.cases.length > 0 ? (
                                    <table className="table table-sm">
                                        <thead>
                                            <tr style={{ verticalAlign: 'middle' }}>
                                                <SortableHeader
                                                    headerText="Case Name"
                                                    sortKey="caseName"
                                                    onSort={this.sortData}
                                                    dataObjectIdentifier={
                                                        DataItems.PackageSettlementCases
                                                    }
                                                    noWrap
                                                />
                                                <SortableHeader
                                                    headerText="Case Status"
                                                    sortKey="caseStatus"
                                                    subKey="displayName"
                                                    onSort={this.sortData}
                                                    dataObjectIdentifier={
                                                        DataItems.PackageSettlementCases
                                                    }
                                                    noWrap
                                                />
                                                <SortableHeader
                                                    headerText="Projected Resolution"
                                                    sortKey="expectedOutcome"
                                                    onSort={this.sortData}
                                                    dataObjectIdentifier={
                                                        DataItems.PackageSettlementCases
                                                    }
                                                />
                                                <SortableHeader
                                                    headerText="Projected Settlement"
                                                    sortKey="projectedSettlement"
                                                    onSort={this.sortData}
                                                    dataObjectIdentifier={
                                                        DataItems.PackageSettlementCases
                                                    }
                                                />
                                                <SortableHeader
                                                    headerText="Final Settlement"
                                                    sortKey="actualSettlement"
                                                    onSort={this.sortData}
                                                    dataObjectIdentifier={
                                                        DataItems.PackageSettlementCases
                                                    }
                                                />
                                                <SortableHeader
                                                    headerText="Jurisdiction"
                                                    sortKey="jurisdiction"
                                                    onSort={this.sortData}
                                                    dataObjectIdentifier={
                                                        DataItems.PackageSettlementCases
                                                    }
                                                />
                                                <SortableHeader
                                                    headerText="Disease"
                                                    sortKey="diagnosis"
                                                    onSort={this.sortData}
                                                    dataObjectIdentifier={
                                                        DataItems.PackageSettlementCases
                                                    }
                                                />
                                                <SortableHeader
                                                    headerText="Trial Date"
                                                    sortKey="trialDate"
                                                    onSort={this.sortData}
                                                    dataObjectIdentifier={
                                                        DataItems.PackageSettlementCases
                                                    }
                                                    noWrap
                                                />
                                                <SortableHeader
                                                    headerText="Age"
                                                    sortKey="age"
                                                    onSort={this.sortData}
                                                    dataObjectIdentifier={
                                                        DataItems.PackageSettlementCases
                                                    }
                                                />
                                                <SortableHeader
                                                    headerText="Living / Deceased"
                                                    sortKey="livingDeceased"
                                                    onSort={this.sortData}
                                                    dataObjectIdentifier={
                                                        DataItems.PackageSettlementCases
                                                    }
                                                />
                                                <SortableHeader
                                                    headerText="Occupation"
                                                    sortKey="occupation"
                                                    onSort={this.sortData}
                                                    dataObjectIdentifier={
                                                        DataItems.PackageSettlementCases
                                                    }
                                                />
                                                {this.state.readOnly ? null : <th />}
                                                {this.state.enableEasyUpdate &&
                                                this.state.packageSettlement.cases.length > 0 ? (
                                                    <th className="text-center">
                                                        <i className="fal fa-edit" />
                                                    </th>
                                                ) : null}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.packageSettlement.cases.map(
                                                (item: IReportCaseDetailModel) => {
                                                    return (
                                                        <tr key={item.caseGuid}>
                                                            <td className="padding-right-sm">
                                                                {' '}
                                                                {Authorization.userHasRight(
                                                                    UserRightsEnum.ViewCaseFileBasic,
                                                                    this.props.user
                                                                ) ||
                                                                Authorization.userHasRight(
                                                                    UserRightsEnum.ViewCaseFile,
                                                                    this.props.user
                                                                ) ? (
                                                                    <Link
                                                                        target="_blank"
                                                                        to={LocalRoutes.CaseFile.replace(
                                                                            ':guid',
                                                                            item.caseGuid
                                                                        )}
                                                                    >
                                                                        {item.caseName}
                                                                    </Link>
                                                                ) : (
                                                                    item.caseName
                                                                )}
                                                            </td>
                                                            <td className="padding-right-sm">
                                                                {item.caseStatus
                                                                    ? item.caseStatus.displayName
                                                                    : ''}
                                                            </td>
                                                            <td className="padding-right-sm">
                                                                {item.expectedOutcome}
                                                            </td>
                                                            <td className="padding-right-sm">
                                                                {item.projectedSettlement
                                                                    ? Common.formatCurrency(
                                                                          item.projectedSettlement.toString()
                                                                      )
                                                                    : ''}
                                                            </td>
                                                            <td className="padding-right-sm">
                                                                {item.actualSettlement
                                                                    ? Common.formatCurrency(
                                                                          item.actualSettlement.toString()
                                                                      )
                                                                    : ''}
                                                            </td>
                                                            {/*
                                                    <td>{item.secondaryPlaintiffsFirm}</td>
                                                    <td>{item.plaintiffsFirm}</td>
                                                    */}
                                                            <td className="padding-right-sm">
                                                                {item.jurisdiction}
                                                            </td>
                                                            <td className="padding-right-sm">
                                                                {item.diagnosis}
                                                            </td>
                                                            <td className="padding-right-sm">
                                                                {Common.dateFormat(item.trialDate)}
                                                            </td>
                                                            <td className="padding-right-sm">
                                                                {item.age}
                                                            </td>
                                                            <td className="padding-right-sm">
                                                                {item.livingDeceased}
                                                            </td>
                                                            <td className="padding-right-sm">
                                                                {item.occupation}
                                                            </td>
                                                            {this.state.readOnly ? null : (
                                                                <td>
                                                                    <button
                                                                        className="btn btn-gray btn-icon float-end"
                                                                        onClick={(
                                                                            e: React.MouseEvent<HTMLButtonElement>
                                                                        ) => {
                                                                            this.removeCase(
                                                                                e,
                                                                                item.caseGuid
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i className="fal fa-times color-white" />
                                                                    </button>
                                                                </td>
                                                            )}
                                                            <td className="text-center">
                                                                {this.state.enableEasyUpdate ? (
                                                                    <button
                                                                        className="btn-no-bg"
                                                                        onClick={(
                                                                            e: React.MouseEvent<HTMLButtonElement>
                                                                        ) => {
                                                                            this.handleEasyUpdate(
                                                                                e,
                                                                                item.caseGuid
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className={`fal fa-edit ${
                                                                                this.easyUpdateAttempted(
                                                                                    item.caseGuid
                                                                                )
                                                                                    ? 'color-gray'
                                                                                    : 'color-black'
                                                                            } `}
                                                                        />
                                                                    </button>
                                                                ) : null}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                ) : (
                                    <span>No Cases added to this Package Deal</span>
                                )
                            ) : null}
                        </div>

                        {this.state.readOnly && (
                            <div className="row" id="AttachmentsDiv">
                                <WorkProductWrapper
                                    uploadOnly={true}
                                    user={this.props.user}
                                    entityGuid={this.state.packageSettlement.guid!}
                                    entityTypeId={EntityTypes.PackageSettlement}
                                    title="Attachments"
                                    documents={this.state.packageSettlement.documents}
                                    parentEntityJson={JSON.stringify(this.state.packageSettlement)}
                                    parentEntityRedirectUrl={LocalRoutes.PackageSettlementDetails.replace(
                                        ':guid',
                                        this.state.packageSettlement.guid!
                                    )}
                                    authorizedToEdit={Authorization.userHasRight(
                                        UserRightsEnum.AddEditDeleteProject,
                                        this.props.user
                                    )}
                                    onSaveComplete={this.handleDocumentSaveComplete}
                                    onDeleteComplete={this.handleDocumentDeleteComplete}
                                />
                            </div>
                        )}

                        {this.state.readOnly ? (
                            <div className="row" id="Notes">
                                {/*No explicit View Strategy Note Right. User has access to Strategy Notes if they have access to the corresponding Package Settlement Page*/}
                                <NoteList
                                    title="Deal Notes"
                                    titleClassName="text-gray font-size-sm"
                                    user={this.props.user}
                                    notes={this.state.packageSettlement.notes}
                                    noteCategoryTypes={this.state.noteCategoryTypes}
                                    handleViewEditNote={this.handleEditNote}
                                    refreshParent={this.refreshNotes}
                                    handleAddNote={this.onClickAddNote}
                                    allowView={Authorization.userHasRight(
                                        UserRightsEnum.ViewPackageSettlement,
                                        this.props.user
                                    )}
                                    allowEdit={
                                        !this.state.packageSettlement.locked &&
                                        Authorization.userHasRight(
                                            UserRightsEnum.UpdatePackageDealNote,
                                            this.props.user
                                        ) &&
                                        (this.state.loggedInUserDataScopeId !==
                                            DataScopesEnum.LocalBasic.Value ||
                                            this.state.currentNote.allowLocalEdit == true)
                                    }
                                    allowDelete={
                                        !this.state.packageSettlement.locked &&
                                        Authorization.userHasRight(
                                            UserRightsEnum.DeletePackageDealNote,
                                            this.props.user
                                        )
                                    }
                                    allowAddNew={
                                        !this.state.packageSettlement.locked &&
                                        Authorization.userHasRight(
                                            UserRightsEnum.AddPackageDealNote,
                                            this.props.user
                                        )
                                    }
                                />
                            </div>
                        ) : null}

                        {this.state.showAddCasesOptionsDialog ? (
                            <Modal
                                centered
                                show={true}
                                onHide={() => this.setState({ showAddCasesOptionsDialog: false })}
                                backdrop={false}
                                size="lg"
                            >
                                <Modal.Header>
                                    <Modal.Title>
                                        Add Cases to Package Deal
                                        <Help
                                            type={ApplicationHelpType.Info}
                                            title="Package Deal - Case Search"
                                            helpText="Only cases with the designated plaintiffs firm will appear in the search list."
                                        />
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row mb-2">
                                        {/*
                                        <div className="col-sm-4">
                                            < button className="btn btn-black" onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.runCaseSearch(e)}>View All</button>
                                            &nbsp;
                                           <Help type={ApplicationHelpType.Info} title="View All" helpText="Displays all cases for the selected Plaintiffs Firm." />
                                        </div>
                                        */}
                                        <div className="col-sm-4">
                                            <span className="btn btn-blue">
                                                <Link
                                                    to={{ pathname: LocalRoutes.Query2 }}
                                                    state={{
                                                        redirectModel: {
                                                            queryFilterCriteriaSelected:
                                                                this.getQueryToolFilterCriteriaToSelectCases(),
                                                            title:
                                                                'Add Cases to Package Deal | ' +
                                                                this.state.packageSettlement.name,
                                                            redirectUrl:
                                                                window.location.pathname +
                                                                window.location.search,
                                                            redirectType:
                                                                QueryToolRedirectTypeEnum.PackageDeal,
                                                            redirectComponentCurrentState:
                                                                this.state.packageSettlement,
                                                            showSelectAllCheckbox: true,
                                                        },
                                                    }}
                                                    style={{ color: 'white' }}
                                                >
                                                    Go To Query Tool
                                                </Link>
                                            </span>
                                        </div>
                                        <div className="col-sm-8">
                                            <div className="pe-1 case-search-div">
                                                <input
                                                    className="form-control float-end"
                                                    type="text"
                                                    name="caseNameOrNumberSearch"
                                                    placeholder="Search by Name or Number"
                                                    value={this.state.caseSearchString}
                                                    onChange={this.changeValue}
                                                ></input>
                                            </div>
                                            <div
                                                className="float-end"
                                                style={{ display: 'inline-block' }}
                                            >
                                                <button
                                                    type="button"
                                                    className="btn btn-gray btn-icon float-end"
                                                    onClick={() => {
                                                        this.setState({
                                                            caseSearchString: '',
                                                            caseSearchResults: undefined,
                                                            selectAllCases: false,
                                                            selectedCaseGuids: [],
                                                        });
                                                    }}
                                                >
                                                    <i className="fal fa-times color-white" />
                                                </button>
                                                <div
                                                    style={{ display: 'inline-block' }}
                                                    className="float-end"
                                                >
                                                    <button
                                                        type="submit"
                                                        className="btn btn-black btn-icon float-end "
                                                        onClick={(
                                                            e: React.MouseEvent<HTMLButtonElement>
                                                        ) => this.runCaseSearch(e, true)}
                                                    >
                                                        <i className="fal fa-search color-white" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span>
                                                <span className="text-danger">
                                                    {this.state.validation.caseNameOrNumberSearch}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div style={{ maxHeight: '500px', overflowX: 'auto' }}>
                                        {this.state.caseSearchResults ? (
                                            this.state.caseSearchResults.length > 0 ? (
                                                <table className="table">
                                                    <thead>
                                                        <tr style={{ verticalAlign: 'middle' }}>
                                                            <th
                                                                /* width={25} */ className="border-0"
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    checked={
                                                                        this.state.selectAllCases ||
                                                                        false
                                                                    }
                                                                    name="selectAllCases"
                                                                    onChange={
                                                                        this.handleCheckChanged
                                                                    }
                                                                />
                                                            </th>
                                                            <SortableHeader
                                                                headerText="Case Number"
                                                                sortKey="caseNumber"
                                                                onSort={this.sortData}
                                                                dataObjectIdentifier={
                                                                    DataItems.PackageSettlementCases
                                                                }
                                                            />
                                                            <SortableHeader
                                                                headerText="Case Name"
                                                                sortKey="caseName"
                                                                onSort={this.sortData}
                                                                dataObjectIdentifier={
                                                                    DataItems.PackageSettlementCases
                                                                }
                                                            />
                                                            <SortableHeader
                                                                headerText="Case Status"
                                                                sortKey="caseStatus"
                                                                subKey="displayName"
                                                                onSort={this.sortData}
                                                                dataObjectIdentifier={
                                                                    DataItems.PackageSettlementCases
                                                                }
                                                            />
                                                            <th>Package Deal</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.caseSearchResults.map(
                                                            (
                                                                caseItem: IReportCaseDetailModel,
                                                                i: number
                                                            ) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td
                                                                            width={25}
                                                                            className="border-0"
                                                                        >
                                                                            {caseItem.packageSettlementGuid ||
                                                                            (this.state
                                                                                .packageSettlement
                                                                                .cases &&
                                                                                this.state.packageSettlement.cases.findIndex(
                                                                                    (x) =>
                                                                                        x.caseGuid ==
                                                                                        caseItem.caseGuid
                                                                                ) >= 0) ? null : (
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    name={
                                                                                        caseItem.caseGuid
                                                                                    }
                                                                                    checked={
                                                                                        this.state.selectedCaseGuids.filter(
                                                                                            (x) =>
                                                                                                x ==
                                                                                                caseItem.caseGuid
                                                                                        ).length > 0
                                                                                    }
                                                                                    onChange={
                                                                                        this
                                                                                            .handleCheckChanged
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {caseItem.caseNumber}
                                                                        </td>
                                                                        <td>{caseItem.caseName}</td>
                                                                        <td>
                                                                            {
                                                                                caseItem.caseStatus!
                                                                                    .displayName
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {caseItem.packageSettlementGuid ? (
                                                                                <Link
                                                                                    target="_blank"
                                                                                    to={LocalRoutes.PackageSettlementDetails.replace(
                                                                                        ':guid',
                                                                                        caseItem.packageSettlementGuid
                                                                                    )}
                                                                                >
                                                                                    {
                                                                                        caseItem.packageSettlementName
                                                                                    }
                                                                                </Link>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <span>No results found!</span>
                                            )
                                        ) : null}
                                    </div>
                                    <div className="dialog-btn-div">
                                        {this.state.selectedCaseGuids.length > 0 ? (
                                            <button
                                                className="btn btn-orange float-end"
                                                onClick={
                                                    this.addAllSelectedCasesToPackageSettlement
                                                }
                                            >
                                                Add Selected Cases
                                            </button>
                                        ) : null}
                                        <button
                                            className="btn btn-default float-end"
                                            onClick={() =>
                                                this.setState({
                                                    showAddCasesOptionsDialog: false,
                                                    caseSearchResults: undefined,
                                                })
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        ) : null}
                        <CheckmateDialog
                            isShowingModal={this.state.showSuccessDialog}
                            title="Package Deal"
                            body="Package Deal successfully saved."
                            handleClose={this.handleSaveComplete}
                            cancelText="Ok"
                            dialogClassName="user-profile-dialog"
                            closeButtonClassName="btn btn-black float-end"
                        />
                    </>
                )}

                {this.state.notesOnEditMode ? (
                    <Modal
                        centered
                        show={true}
                        onHide={() => this.setState({ notesOnEditMode: false })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>Notes</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="text-danger">{this.state.validation.notes}</span>
                            </div>
                            <div>
                                <textarea
                                    className="form-control"
                                    value={this.state.packageSettlement.description}
                                    name="description"
                                    rows={10}
                                    onChange={this.changeValue}
                                />
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={() => this.setState({ notesOnEditMode: false })}
                                >
                                    OK
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.handleClearDescription}
                                >
                                    Clear
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                {this.state.currentNarrativeCaseAssertionOnEditMode ? (
                    <Modal
                        centered
                        show={true}
                        onHide={() =>
                            this.setState({ currentNarrativeCaseAssertionOnEditMode: undefined })
                        }
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>
                                {
                                    this.state.currentNarrativeCaseAssertionOnEditMode
                                        .assertionDescriptor!.displayName
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                            <div>
                                <textarea
                                    className="form-control"
                                    value={
                                        this.state.currentNarrativeCaseAssertionOnEditMode.textValue
                                    }
                                    name={
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            .assertionDescriptor!.guid
                                    }
                                    rows={10}
                                    onChange={this.handleNarrativeFieldTextChange}
                                />
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={() =>
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            ? this.finalizeChange([
                                                  this.state
                                                      .currentNarrativeCaseAssertionOnEditMode,
                                              ])
                                            : {}
                                    }
                                >
                                    OK
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({
                                            currentNarrativeCaseAssertionOnEditMode: undefined,
                                        })
                                    }
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.handleClearNarrativeFieldEdit}
                                >
                                    Clear
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}
                <CheckmateDialog
                    isShowingModal={this.state.showUnlockPackageSettlementDialog}
                    body="Are you sure you want to unlock this package settlement to allow editing? "
                    handleClose={() => this.setState({ showUnlockPackageSettlementDialog: false })}
                    handleConfirm={() => this.handleUnlockPackageSettlement()}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
                <CheckmateDialog
                    isShowingModal={this.state.showLockPackageSettlementDialog}
                    body="Are you sure you want lock this package settlement to prevent editing? "
                    handleClose={() => this.setState({ showLockPackageSettlementDialog: false })}
                    handleConfirm={() => this.handleLockPackageSettlement()}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />

                <CheckmateDialog
                    isShowingModal={this.state.showAllCasesLockedDialog}
                    title="Package Deal"
                    body="All cases are locked and cannot be edited."
                    handleClose={this.handleCloseShowAllCasesLockedDialog}
                    cancelText="Ok"
                    dialogClassName="all-cases-locked-dialog"
                    closeButtonClassName="btn btn-black float-end"
                />
            </div>
        );
    }
}
