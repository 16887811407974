import ApiClient from '../../services/apiClient';
import { IExpertCreateModel } from '../../interfaces/IExpert';
import { useMutation } from 'react-query';
import ValidateUtils from '../../shared/validations';
const _apiClient = new ApiClient();

export const useExpertCreateMutation = (onError: (errorMessage: string) => void) =>
    useMutation({
        mutationFn: async (newExpert: IExpertCreateModel) => {
            const response = await _apiClient.createExpert(newExpert);

            if (!response?.httpResponse.ok) {
                if (response.errorMessage && response.httpResponse.status == 400) {
                    const validation = ValidateUtils.parseErrors(response.errors, response.errorMessage);
                    if(response.errors !== undefined && response.errors[0] !== undefined){
                        throw new Error(`Failed to create Expert : ${response.errors[0].message}`); 
                    }
                } 
                throw new Error('Failed to create Expert');
            }
            return response.payload;
        },
        onError(error) {
            if (error instanceof Error) {
                onError(error.message);
            }
        },
    });
