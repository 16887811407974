import { NavLink, Navigate, Outlet } from 'react-router-dom';

import { IUserModel } from '../interfaces/IUser';
import { LocalRoutes } from '../utilities/LocalRoutes';
import { TutorialDetails } from '../utilities/Constants';

interface ITutorialLayoutProps {
    user?: IUserModel;
    isAuthenticated: boolean;
    initialLoad: boolean;
    inProgress: boolean;
}

export function TutorialLayout({ isAuthenticated, inProgress, initialLoad }: ITutorialLayoutProps) {
    if (initialLoad) {
        return <div>Loading...</div>;
    }

    if (!inProgress && !isAuthenticated && !initialLoad) {
        return <Navigate to={LocalRoutes.Login} />;
    }

    return (
        <div className="container-fluid" style={{ background: 'white' }}>
            <div className="row">
                <div
                    className="col-sm-2 print-hide-display"
                    style={{ paddingLeft: '15px', paddingRight: '15px' }}
                >
                    <div className="logo-container">
                        <img
                            className="logo-img rounded p-2"
                            src="/images/CheckmateGreenLogo.svg"
                            alt="Checkmate Logo"
                        />
                    </div>
                </div>
                <div
                    className="col-sm-10  print-hide-display"
                    style={{ height: '78px', padding: '0px', background: '#52bdad' }}
                >
                    <div className="outerContainer">
                        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <div className="innerContainer float-end"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ background: '#52bdad', color: '#ffffff' }}>
                <div className="col-sm-2">
                    <div
                        className="bold"
                        style={{ paddingTop: '25px', paddingLeft: '18px', fontSize: '15px' }}
                    >
                        <span>TUTORIALS</span>
                    </div>
                    <div className="pt-2" style={{ paddingLeft: '18px' }}>
                        {TutorialDetails.map((details) => (
                            <SideNavMenuItem
                                key={details.fileName}
                                label={details.displayName}
                                url={details.id.toString()}
                                icon={details.icon}
                            />
                        ))}
                    </div>
                </div>
                <div
                    className="col-sm-10 main-display"
                    style={{
                        background: '#ffffff',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

interface ISideNavMenuItemProps {
    url: string;
    icon: string;
    label: string;
}

function SideNavMenuItem({ url, icon, label }: ISideNavMenuItemProps) {
    return (
        <div>
            <NavLink
                to={url}
                style={({ isActive }) => {
                    return {
                        borderBottom: isActive ? '1px solid #ffffff' : 'none',
                        color: '#ffffff',
                        display: 'inline-block',
                        textDecoration: 'none',
                    };
                }}
            >
                <div
                    className="mt-2"
                    style={{
                        alignItems: 'baseline',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5px',
                    }}
                >
                    <i className={`fa ${icon}`} />
                    <span className="font-size-xs">{label}</span>
                </div>
            </NavLink>
        </div>
    );
}
