import {
    AssertionDescriptors,
    CalculatedAgeAssertionDescriptorValues,
    CaseStatusTypesEnum,
    CaseSubStatusTypesEnum,
} from './Constants';
import {
    IAssertionDescriptorModel,
    IAssertionDescriptorValueModel,
} from '../interfaces/IAssertionDescriptor';
import { ICaseAssertionModel, ICaseModel, ICaseStatusModel } from '../interfaces/ICase';

import Common from '../stores/Common';

export default class CaseDataCustomLogicHelper {
    static Run(currentCase: ICaseModel, assertionDescriptors: IAssertionDescriptorModel[]) {
        if (!currentCase.caseAssertions) currentCase.caseAssertions = [];
        CaseDataCustomLogicHelper.RunAgeRelatedCustomLogic(currentCase, assertionDescriptors);
        CaseDataCustomLogicHelper.RunSmokingRelatedCustomLogic(currentCase, assertionDescriptors);
        CaseDataCustomLogicHelper.RunMSJPJRelatedCustomLogic(currentCase, assertionDescriptors);
        CaseDataCustomLogicHelper.RunDemandRelatedCustomLogic(currentCase, assertionDescriptors);
    }

    private static RunAgeRelatedCustomLogic(
        currentCase: ICaseModel,
        assertionDescriptors: IAssertionDescriptorModel[]
    ) {
        //#region FIND ALL THE CASE ASSERTION MATCHES FROM THE CURRENT CASE

        const livingDeceasedCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.LivingDeceased.Guid.toUpperCase()
        );
        const dobCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.DOB.Guid.toUpperCase()
        );
        const dateOfDeathCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.DateOfDeath.Guid.toUpperCase()
        );
        const calculatedAgeRangeCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.CalculatedAgeRange.Guid.toUpperCase()
        );
        const calculatedExactAgeCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.CalculatedExactAge.Guid.toUpperCase()
        );
        const estimatedAgeCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.EstimatedAge.Guid.toUpperCase()
        );
        const resolutionAgreementDateCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.ResolutionAgreementDate.Guid.toUpperCase()
        );
        const dismissalDateCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.DismissalDate.Guid.toUpperCase()
        );

        //#endregion

        //#region  RESET ALL INFERRED VALUES TO NULL; THE LOGIC BELOW CAN RECALCULATE
        if (calculatedAgeRangeCaseAssertionMatch)
            calculatedAgeRangeCaseAssertionMatch.assertionDescriptorValue = undefined;

        if (calculatedExactAgeCaseAssertionMatch) calculatedExactAgeCaseAssertionMatch.text = '';

        if (livingDeceasedCaseAssertionMatch && !livingDeceasedCaseAssertionMatch.userOverride)
            livingDeceasedCaseAssertionMatch.assertionDescriptorValue = undefined;

        //#endregion

        const endDateForCalcAgeLiving: string =
            CaseDataCustomLogicHelper.GetEndDateForAgeCalculation(
                currentCase.caseStatus,
                livingDeceasedCaseAssertionMatch,
                dateOfDeathCaseAssertionMatch,
                resolutionAgreementDateCaseAssertionMatch,
                dismissalDateCaseAssertionMatch
            );

        const validDOB = Common.isValidDate(dobCaseAssertionMatch?.text ?? '');
        const validDateOfDeath = Common.isValidDate(dateOfDeathCaseAssertionMatch?.text ?? '');

        if (validDOB && endDateForCalcAgeLiving) {
            const calculatedAge = Common.dateDiffInYears(
                endDateForCalcAgeLiving,
                dobCaseAssertionMatch?.text ?? ''
            );

            CaseDataCustomLogicHelper.PopulateCalculatedExactAge(
                calculatedAge,
                assertionDescriptors,
                currentCase,
                calculatedExactAgeCaseAssertionMatch
            );

            CaseDataCustomLogicHelper.PopulateCalculatedAgeRange(
                calculatedAge,
                calculatedAgeRangeCaseAssertionMatch,
                estimatedAgeCaseAssertionMatch,
                assertionDescriptors,
                currentCase
            );
        }

        const livingDeceasedAD = assertionDescriptors.find(
            (x) => x.guid?.toUpperCase() === AssertionDescriptors.LivingDeceased.Guid
        );

        let livingDeceasedADV: IAssertionDescriptorModel | undefined = undefined;
        if (validDateOfDeath) {
            livingDeceasedADV = livingDeceasedAD?.assertionDescriptorValues?.find(
                (x) =>
                    x.guid!.toUpperCase() ===
                    AssertionDescriptors.LivingDeceased.DeceasedADVGuid.toUpperCase()
            );
        } else if (!livingDeceasedCaseAssertionMatch?.userOverride && validDOB) {
            livingDeceasedADV = livingDeceasedAD?.assertionDescriptorValues?.find(
                (x) =>
                    x.guid!.toUpperCase() ===
                    AssertionDescriptors.LivingDeceased.LivingADVGuid.toUpperCase()
            );
        }

        CaseDataCustomLogicHelper.PopulateLivingDeceased(
            livingDeceasedADV,
            livingDeceasedCaseAssertionMatch,
            livingDeceasedAD,
            currentCase
        );
    }

    private static RunSmokingRelatedCustomLogic(
        currentCase: ICaseModel,
        assertionDescriptors: IAssertionDescriptorModel[]
    ) {
        //#region FIND ALL THE CASE ASSERTION MATCHES FROM THE CURRENT CASE
        const smokingHistoryPacksPerDayCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.SmokingHistoryPacksPerDay.Guid.toUpperCase()
        );
        const smokingHistoryPackYearCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor?.guid?.toUpperCase() ===
                AssertionDescriptors.SmokingHistoryPackYear.Guid.toUpperCase()
        );
        const smokingStartDateCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.SmokingStartDate.Guid.toUpperCase()
        );
        const smokingEndDateCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor?.guid?.toUpperCase() ===
                AssertionDescriptors.SmokingEndDate.Guid.toUpperCase()
        );
        const isSmokerCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.IsSmoker.Guid.toUpperCase()
        );

        //#endregion

        //#region  RESET ALL INFERRED VALUES TO NULL; THE LOGIC BELOW CAN RECALCULATE

        if (isSmokerCaseAssertionMatch && !isSmokerCaseAssertionMatch.userOverride) {
            isSmokerCaseAssertionMatch.assertionDescriptorValue = undefined;
        }

        if (
            smokingHistoryPackYearCaseAssertionMatch &&
            !smokingHistoryPackYearCaseAssertionMatch.userOverride
        ) {
            smokingHistoryPackYearCaseAssertionMatch.text = '';
        }

        //#endregion

        //#region isSmoker

        const isSmoker = !!(
            (smokingStartDateCaseAssertionMatch?.text &&
                Common.isValidDate(smokingStartDateCaseAssertionMatch.text)) ||
            (smokingEndDateCaseAssertionMatch?.text &&
                Common.isValidDate(smokingEndDateCaseAssertionMatch.text)) ||
            smokingHistoryPacksPerDayCaseAssertionMatch?.assertionDescriptorValue ||
            smokingHistoryPackYearCaseAssertionMatch?.assertionDescriptorValue
        );

        if (isSmoker) {
            const isSmokerAD = assertionDescriptors.find(
                (x) =>
                    x.guid != undefined &&
                    x.guid.toUpperCase() === AssertionDescriptors.IsSmoker.Guid
            );

            const isSmokerYesADV = isSmokerAD?.assertionDescriptorValues?.find(
                (x) =>
                    x.guid != undefined &&
                    x.guid.toUpperCase() === AssertionDescriptors.IsSmoker.YesADVGuid.toUpperCase()
            );

            CaseDataCustomLogicHelper.PopulateIsSmokerYes(
                isSmokerYesADV,
                isSmokerCaseAssertionMatch,
                isSmokerAD,
                currentCase
            );
        }

        //#endregion

        //#region PACK YEARS

        let packYears = 0;

        const hasUserSuppliedEndDate = Common.isValidDate(
            smokingEndDateCaseAssertionMatch?.text ?? ''
        );

        const packsPerDayNumericValue =
            smokingHistoryPacksPerDayCaseAssertionMatch?.assertionDescriptorValue?.numericValue ??
            0;

        if (packsPerDayNumericValue > 0) {
            let smokingDays = 0;

            const startDate = smokingStartDateCaseAssertionMatch?.text ?? '';
            const endDate = smokingEndDateCaseAssertionMatch?.text ?? '';

            const hasValidStartDate = Common.isValidDate(startDate);

            if (hasValidStartDate) {
                const smokingEndDate = hasUserSuppliedEndDate ? endDate : Common.currentDate();

                smokingDays = Common.dateDiffInDays(smokingEndDate, startDate);
            }

            if (smokingDays > 0) {
                packYears = packsPerDayNumericValue * (smokingDays / 365);
            }
        }

        CaseDataCustomLogicHelper.PopulateSmokingPackYears(
            packYears,
            smokingHistoryPackYearCaseAssertionMatch,
            assertionDescriptors,
            currentCase
        );

        //#endregion
    }

    private static RunMSJPJRelatedCustomLogic(
        currentCase: ICaseModel,
        assertionDescriptors: IAssertionDescriptorModel[]
    ) {
        //#region FIND ALL THE CASE ASSERTION MATCHES FROM THE CURRENT CASE

        const msjStatusCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.MSJStatus.Guid.toUpperCase()
        );
        const msjFiledCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.MSJFiled.Guid.toUpperCase()
        );
        const msjGrantedDateCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.MSJGrantedDate.Guid.toUpperCase()
        );
        const pjStatusCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.PJStatus.Guid.toUpperCase()
        );
        const pjFiledCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.PJFiled.Guid.toUpperCase()
        );
        const pjGrantedDateCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.PJGrantedDate.Guid.toUpperCase()
        );

        //#endregion

        //#region  RESET ALL INFERRED VALUES TO NULL; THE LOGIC BELOW CAN RECALCULATE
        // ???
        //#endregion

        //#region MSJ/PJ STUFF
        const defaultMSJStatusToNoRuling: boolean =
            (msjFiledCaseAssertionMatch &&
                msjFiledCaseAssertionMatch.assertionDescriptorValue &&
                msjFiledCaseAssertionMatch.assertionDescriptorValue.guid &&
                msjFiledCaseAssertionMatch.assertionDescriptorValue.guid.toUpperCase() ===
                    AssertionDescriptors.MSJFiled.AvailableAndFiledGuid) ||
            (msjGrantedDateCaseAssertionMatch &&
                msjGrantedDateCaseAssertionMatch.text &&
                Common.isValidDate(msjGrantedDateCaseAssertionMatch.text) &&
                Common.dateDiff(msjGrantedDateCaseAssertionMatch.text, Common.currentDate()) < 0)
                ? true
                : false;

        if (!msjStatusCaseAssertionMatch) {
            if (defaultMSJStatusToNoRuling && assertionDescriptors) {
                const msjStatusADMatch = assertionDescriptors.find(
                    (x) => x.guid!.toUpperCase() == AssertionDescriptors.MSJStatus.Guid
                );
                if (msjStatusADMatch && msjStatusADMatch.assertionDescriptorValues) {
                    const noRulingMSJADV = msjStatusADMatch.assertionDescriptorValues.find(
                        (x) => x.guid!.toUpperCase() === AssertionDescriptors.MSJStatus.NoRulingGuid
                    );
                    if (noRulingMSJADV) {
                        if (!currentCase.caseAssertions) currentCase.caseAssertions = [];
                        currentCase.caseAssertions.push({
                            assertionDescriptor: JSON.parse(JSON.stringify(msjStatusADMatch)),
                            assertionDescriptorValue: JSON.parse(JSON.stringify(noRulingMSJADV)),
                        });
                    }
                }
            }
        } else if (!msjStatusCaseAssertionMatch.userOverride) {
            if (defaultMSJStatusToNoRuling) {
                if (
                    msjStatusCaseAssertionMatch.assertionDescriptor &&
                    msjStatusCaseAssertionMatch.assertionDescriptor.assertionDescriptorValues
                ) {
                    const noRulingMSJADV =
                        msjStatusCaseAssertionMatch.assertionDescriptor.assertionDescriptorValues.find(
                            (x) =>
                                x.guid!.toUpperCase() ===
                                AssertionDescriptors.MSJStatus.NoRulingGuid
                        );
                    if (noRulingMSJADV)
                        msjStatusCaseAssertionMatch.assertionDescriptorValue = JSON.parse(
                            JSON.stringify(noRulingMSJADV)
                        );
                }
            } else msjStatusCaseAssertionMatch.assertionDescriptorValue = undefined;
        }

        const defaultPJStatusToNoRuling: boolean =
            (pjFiledCaseAssertionMatch &&
                pjFiledCaseAssertionMatch.assertionDescriptorValue &&
                pjFiledCaseAssertionMatch.assertionDescriptorValue.guid &&
                pjFiledCaseAssertionMatch.assertionDescriptorValue.guid.toUpperCase() ===
                    AssertionDescriptors.PJFiled.AvailableAndFiledGuid) ||
            (pjGrantedDateCaseAssertionMatch &&
                pjGrantedDateCaseAssertionMatch.text &&
                Common.isValidDate(pjGrantedDateCaseAssertionMatch.text) &&
                Common.dateDiff(pjGrantedDateCaseAssertionMatch.text, Common.currentDate()) < 0)
                ? true
                : false;

        if (!pjStatusCaseAssertionMatch) {
            if (defaultPJStatusToNoRuling && assertionDescriptors) {
                const pjStatusADMatch = assertionDescriptors.find(
                    (x) => x.guid!.toUpperCase() == AssertionDescriptors.PJStatus.Guid
                );
                if (pjStatusADMatch && pjStatusADMatch.assertionDescriptorValues) {
                    const noRulingPJADV = pjStatusADMatch.assertionDescriptorValues.find(
                        (x) => x.guid!.toUpperCase() === AssertionDescriptors.PJStatus.NoRulingGuid
                    );
                    if (noRulingPJADV) {
                        if (!currentCase.caseAssertions) currentCase.caseAssertions = [];
                        currentCase.caseAssertions.push({
                            assertionDescriptor: JSON.parse(JSON.stringify(pjStatusADMatch)),
                            assertionDescriptorValue: JSON.parse(JSON.stringify(noRulingPJADV)),
                        });
                    }
                }
            }
        } else if (!pjStatusCaseAssertionMatch.userOverride) {
            if (defaultPJStatusToNoRuling) {
                if (
                    pjStatusCaseAssertionMatch.assertionDescriptor &&
                    pjStatusCaseAssertionMatch.assertionDescriptor.assertionDescriptorValues
                ) {
                    const noRulingPJADV =
                        pjStatusCaseAssertionMatch.assertionDescriptor.assertionDescriptorValues.find(
                            (x) =>
                                x.guid!.toUpperCase() === AssertionDescriptors.PJStatus.NoRulingGuid
                        );
                    if (noRulingPJADV)
                        pjStatusCaseAssertionMatch.assertionDescriptorValue = JSON.parse(
                            JSON.stringify(noRulingPJADV)
                        );
                }
            } else pjStatusCaseAssertionMatch.assertionDescriptorValue = undefined;
        }

        //#endregion
    }

    private static RunDemandRelatedCustomLogic(
        currentCase: ICaseModel,
        assertionDescriptors: IAssertionDescriptorModel[]
    ) {
        //#region FIND ALL THE CASE ASSERTION MATCHES FROM THE CURRENT CASE

        const demandForCorporateRepDepoCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.DemandForCorporateRepDepo.Guid.toUpperCase()
        );
        const corporateRepDepoReceivedDateCaseAssertionMatch = currentCase.caseAssertions!.find(
            (x) =>
                x.assertionDescriptor != undefined &&
                x.assertionDescriptor.guid != undefined &&
                x.assertionDescriptor.guid.toUpperCase() ===
                    AssertionDescriptors.CorporateRepDemandReceivedDate.Guid.toUpperCase()
        );

        //#endregion

        //#region  RESET ALL INFERRED VALUES TO NULL; THE LOGIC BELOW CAN RECALCULATE
        if (
            demandForCorporateRepDepoCaseAssertionMatch &&
            !demandForCorporateRepDepoCaseAssertionMatch.userOverride
        )
            demandForCorporateRepDepoCaseAssertionMatch.text = undefined;
        //#endregion

        if (
            corporateRepDepoReceivedDateCaseAssertionMatch &&
            corporateRepDepoReceivedDateCaseAssertionMatch.text &&
            Common.isValidDate(corporateRepDepoReceivedDateCaseAssertionMatch.text)
        ) {
            const demandForCorporateRepDepoAD = assertionDescriptors.find(
                (x) => x.guid!.toUpperCase() == AssertionDescriptors.DemandForCorporateRepDepo.Guid
            );

            // User added Corporate Rep Depo Received Date. Hence ALWAYS Default Demand for Corporate Rep Depo to TRUE
            CaseDataCustomLogicHelper.SetDemandforCorporateRepDepTrue(
                demandForCorporateRepDepoCaseAssertionMatch,
                demandForCorporateRepDepoAD,
                currentCase
            );
        }
    }

    private static SetDemandforCorporateRepDepTrue(
        demandForCorporateRepDepoCaseAssertionMatch: ICaseAssertionModel | undefined,
        demandForCorporateRepDepoAD: IAssertionDescriptorModel | undefined,
        currentCase: ICaseModel
    ) {
        if (demandForCorporateRepDepoCaseAssertionMatch) {
            demandForCorporateRepDepoCaseAssertionMatch.text = 'true';
            demandForCorporateRepDepoCaseAssertionMatch.userOverride = false;
        } else if (demandForCorporateRepDepoAD) {
            if (!currentCase.caseAssertions) currentCase.caseAssertions = [];
            currentCase.caseAssertions.push({
                assertionDescriptor: JSON.parse(JSON.stringify(demandForCorporateRepDepoAD)),
                text: 'true',
            });
        }
    }

    private static PopulateSmokingPackYears(
        packYears: number,
        smokingHistoryPackYearCaseAssertionMatch: ICaseAssertionModel | undefined,
        assertionDescriptors: IAssertionDescriptorModel[],
        currentCase: ICaseModel
    ) {
        if (packYears) {
            if (smokingHistoryPackYearCaseAssertionMatch) {
                smokingHistoryPackYearCaseAssertionMatch.text = packYears.toFixed(1);
                smokingHistoryPackYearCaseAssertionMatch.userOverride = false;
            } else {
                const smokingHistoryPackYearAD = assertionDescriptors.find(
                    (x) =>
                        x.guid?.toUpperCase() === AssertionDescriptors.SmokingHistoryPackYear.Guid
                );
                if (smokingHistoryPackYearAD) {
                    if (!currentCase.caseAssertions) currentCase.caseAssertions = [];
                    currentCase.caseAssertions.push({
                        assertionDescriptor: JSON.parse(JSON.stringify(smokingHistoryPackYearAD)),
                        text: packYears.toFixed(1),
                        userOverride: false,
                    });
                }
            }
        }
    }

    private static PopulateIsSmokerYes(
        isSmokerYesADV: IAssertionDescriptorValueModel | undefined,
        isSmokerCaseAssertionMatch: ICaseAssertionModel | undefined,
        isSmokerAD: IAssertionDescriptorModel | undefined,
        currentCase: ICaseModel
    ) {
        if (isSmokerYesADV) {
            if (isSmokerCaseAssertionMatch) {
                isSmokerCaseAssertionMatch.assertionDescriptorValue = JSON.parse(
                    JSON.stringify(isSmokerYesADV)
                );
                isSmokerCaseAssertionMatch.userOverride = false;
            } else {
                if (isSmokerAD) {
                    if (!currentCase.caseAssertions) currentCase.caseAssertions = [];
                    currentCase.caseAssertions.push({
                        assertionDescriptor: JSON.parse(JSON.stringify(isSmokerAD)),
                        assertionDescriptorValue: JSON.parse(JSON.stringify(isSmokerYesADV)),
                        userOverride: false,
                    });
                }
            }
        }
    }

    private static PopulateCalculatedAgeRange(
        calculatedAge: number,
        calculatedAgeRangeCaseAssertionMatch: ICaseAssertionModel | undefined,
        estimatedAgeRangeCaseAssertionMatch: ICaseAssertionModel | undefined,
        assertionDescriptors: IAssertionDescriptorModel[],
        currentCase: ICaseModel
    ) {
        if (calculatedAge) {
            if (estimatedAgeRangeCaseAssertionMatch) {
                estimatedAgeRangeCaseAssertionMatch.assertionDescriptorValue = undefined;
                estimatedAgeRangeCaseAssertionMatch.userOverride = false;
            }

            const calculatedAgeRangeAD = assertionDescriptors.find(
                (x) => x.guid?.toUpperCase() === AssertionDescriptors.CalculatedAgeRange.Guid
            );

            const match = CalculatedAgeAssertionDescriptorValues.find(
                (x) => calculatedAge >= x.From && calculatedAge <= x.To
            );

            const calculatedAgeRangeADV = calculatedAgeRangeAD?.assertionDescriptorValues?.find(
                (x) => x.guid?.toUpperCase() === match?.Guid
            );

            if (calculatedAgeRangeCaseAssertionMatch) {
                calculatedAgeRangeCaseAssertionMatch.assertionDescriptorValue =
                    calculatedAgeRangeADV
                        ? JSON.parse(JSON.stringify(calculatedAgeRangeADV))
                        : undefined;
            } else if (calculatedAgeRangeAD && calculatedAgeRangeADV) {
                if (!currentCase.caseAssertions) currentCase.caseAssertions = [];
                currentCase.caseAssertions.push({
                    assertionDescriptor: JSON.parse(JSON.stringify(calculatedAgeRangeAD)),
                    assertionDescriptorValue: JSON.parse(JSON.stringify(calculatedAgeRangeADV)),
                });
            }
        }
    }

    private static PopulateLivingDeceased(
        deceasedADV: IAssertionDescriptorValueModel | undefined,
        livingDeceasedCaseAssertionMatch: ICaseAssertionModel | undefined,
        livingDeceasedAD: IAssertionDescriptorModel | undefined,
        currentCase: ICaseModel
    ) {
        if (deceasedADV) {
            if (livingDeceasedCaseAssertionMatch) {
                livingDeceasedCaseAssertionMatch.assertionDescriptorValue = JSON.parse(
                    JSON.stringify(deceasedADV)
                );
                livingDeceasedCaseAssertionMatch.userOverride = false;
            } else {
                if (livingDeceasedAD) {
                    if (!currentCase.caseAssertions) currentCase.caseAssertions = [];
                    currentCase.caseAssertions.push({
                        assertionDescriptor: JSON.parse(JSON.stringify(livingDeceasedAD)),
                        assertionDescriptorValue: JSON.parse(JSON.stringify(deceasedADV)),
                    });
                }
            }
        }
    }

    private static GetEndDateForAgeCalculation(
        caseStatus: ICaseStatusModel | undefined,
        livingDeceasedCaseAssertionMatch: ICaseAssertionModel | undefined,
        dateOfDeathCaseAssertionMatch: ICaseAssertionModel | undefined,
        resolutionAgreementDateCaseAssertionMatch: ICaseAssertionModel | undefined,
        dismissalDateCaseAssertionMatch: ICaseAssertionModel | undefined
    ) {
        let endDateForCalcAgeLiving = '';

        if (Common.isValidDate(dateOfDeathCaseAssertionMatch?.text ?? '')) {
            endDateForCalcAgeLiving = dateOfDeathCaseAssertionMatch?.text ?? '';
        } else if (
            livingDeceasedCaseAssertionMatch?.assertionDescriptorValue?.guid?.toUpperCase() ===
                AssertionDescriptors.LivingDeceased.DeceasedADVGuid.toUpperCase() &&
            !Common.isValidDate(dateOfDeathCaseAssertionMatch?.text || '')
        ) {
            // Cannot Calculate Age if User set to Deceased but didnt add a Date of Death
            endDateForCalcAgeLiving = '';
        }
        // If the case is a Resolved or Alt-Closed case, only User is Living, Only Calculate Age with End Date = Resolution Date. Not current Date.
        else if (
            caseStatus &&
            (caseStatus.status?.id === CaseStatusTypesEnum.Resolved.Value ||
                (caseStatus.status?.id === CaseStatusTypesEnum.Alt.Value &&
                    caseStatus.subStatus?.id === CaseSubStatusTypesEnum.Closed.Value))
        ) {
            if (
                resolutionAgreementDateCaseAssertionMatch &&
                resolutionAgreementDateCaseAssertionMatch.text &&
                Common.isValidDate(resolutionAgreementDateCaseAssertionMatch.text)
            )
                endDateForCalcAgeLiving = resolutionAgreementDateCaseAssertionMatch.text;
            else if (
                dismissalDateCaseAssertionMatch &&
                dismissalDateCaseAssertionMatch.text &&
                Common.isValidDate(dismissalDateCaseAssertionMatch.text)
            )
                endDateForCalcAgeLiving = dismissalDateCaseAssertionMatch.text;
            else endDateForCalcAgeLiving = Common.currentDate();
        } else endDateForCalcAgeLiving = Common.currentDate();

        return endDateForCalcAgeLiving;
    }

    private static PopulateCalculatedExactAge(
        calculatedAge: number,
        assertionDescriptors: IAssertionDescriptorModel[],
        currentCase: ICaseModel,
        calculatedExactAgeCaseAssertionMatch?: ICaseAssertionModel
    ) {
        if (calculatedExactAgeCaseAssertionMatch) {
            calculatedExactAgeCaseAssertionMatch.text = calculatedAge.toString();
        } else {
            const calculatedExactAgeAD = assertionDescriptors.find(
                (x) =>
                    x.guid != undefined &&
                    x.guid.toUpperCase() === AssertionDescriptors.CalculatedExactAge.Guid
            );
            if (calculatedExactAgeAD) {
                if (!currentCase.caseAssertions) currentCase.caseAssertions = [];
                currentCase.caseAssertions.push({
                    assertionDescriptor: JSON.parse(JSON.stringify(calculatedExactAgeAD)),
                    text: calculatedAge.toString(),
                });
            }
        }
    }
}
