import { ITutorialDetail, TutorialDetails } from '../utilities/Constants';

import { Link } from 'react-router-dom';
import { LocalRoutes } from '../utilities/LocalRoutes';

function Tutorials() {
    return (
        <>
            <h1>Tutorials</h1>
            <br />
            <ul>
                {TutorialDetails.map((item: ITutorialDetail) => {
                    return (
                        <li key={item.id}>
                            <Link
                                target="_blank"
                                to={LocalRoutes.ViewPDF + '/' + item.id.toString()}
                            >
                                <span className="btn btn-no-bg text-gray">
                                    <i className={'fal ' + item.icon} />
                                    &nbsp;{item.displayName}
                                </span>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}

export default Tutorials;
