import ApiClient from '../../services/apiClient';
import { useQuery } from 'react-query';

const _apiClient = new ApiClient();

export const useGetApprovalStatusTypes = () => {
    return useQuery({
        queryKey: ['get-approval-status-types'],
        queryFn: async () => {
            const response = await _apiClient.getAllApprovalStatusTypes();

            if (response.httpResponse.status === 401) {
                window.location.reload();
            }

            if (response.httpResponse.status === 500) {
                throw new Error('Failed to retrieve approval status types.');
            } else if (response.errorMessage) {
                throw new Error(response.errorMessage);
            }

            return response.payload;
        },
        staleTime: Infinity,
    });
};
