import * as React from 'react';

import Common from '../../stores/Common';
import { EmptyGuid } from '../../utilities/Constants';
import { IDepositionModel } from '../../interfaces/ICase';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import Sort from '../../stores/Sort';
import { SortableHeader } from './SortableHeader';
import UIHelper from '../../utilities/UIHelper';
import { cloneDeep } from 'lodash';

export interface IDepositionTableProps {
    depostions: IDepositionModel[];
    currentCaseMeta?: {
        guid: string;
        caseName: string;
    };
    getEasyUpdateIconColorStyle?: (caseGuid: string, noteGuid?: string) => string;
    onEasyUpdateClick?: (caseGuid: string, noteGuid: string) => void;
    onRowAddClick?: (caseGuid: string) => void;
    onRowDeleteClick: (item: IDepositionModel) => void;
    onRowEditClick: (item: IDepositionModel) => void;
    showCaseColumn?: boolean;
    showEasyUpdateColumn?: boolean;
    showDeleteButton?: boolean;
    showEditButton?: boolean;
}

export function DepositionTable({
    depostions,
    currentCaseMeta,
    getEasyUpdateIconColorStyle,
    onEasyUpdateClick,
    onRowAddClick,
    onRowDeleteClick,
    onRowEditClick,
    showCaseColumn,
    showEasyUpdateColumn,
    showDeleteButton,
    showEditButton,
}: IDepositionTableProps) {
    const [sortedRows, setSortedRows] = React.useState<IDepositionModel[]>(depostions);

    const [sortState, setSortState] = React.useState<{
        key: string;
        order: string;
        subKey: string | undefined;
        subGrandKey: string | undefined;
    }>();

    const allowEdit = showDeleteButton || showEditButton;
    const showActionsColumn = allowEdit || showEasyUpdateColumn;

    const sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        const depos = cloneDeep(depostions);

        if (depos) {
            depos.sort(Sort.compareValues(key, subKey, order, subGrandKey));
        }

        setSortedRows(depos);
    };

    const handleSortDataClick = (
        key: string,
        order: string,
        subKey?: string | undefined,
        subGrandKey?: string | undefined
    ) => {
        setSortState({ key, order, subKey, subGrandKey });
    };

    React.useEffect(() => {
        if (sortState?.key) {
            sortData(sortState?.key, sortState?.order, sortState?.subKey, sortState?.subGrandKey);
        } else {
            setSortedRows(depostions);
        }
    }, [sortState, depostions]);

    return (
        <table className="table table-sm vertical-margin-xl padding-left">
            <thead>
                <tr>
                    {showCaseColumn && (
                        <SortableHeader
                            headerText="Case Name"
                            sortKey="case"
                            subKey="caseName"
                            onSort={handleSortDataClick}
                            noWrap
                        />
                    )}
                    <SortableHeader
                        headerText="Deposition"
                        sortKey="category"
                        subKey="displayName"
                        onSort={handleSortDataClick}
                    />
                    <SortableHeader
                        headerText="Status"
                        sortKey="status"
                        subKey="displayName"
                        onSort={handleSortDataClick}
                    />
                    <SortableHeader
                        headerText="Witness"
                        sortKey="witness"
                        onSort={handleSortDataClick}
                    />
                    <SortableHeader
                        headerText="Title/ Relationship"
                        sortKey="title"
                        onSort={handleSortDataClick}
                        noWrap
                    />
                    <SortableHeader headerText="Date" sortKey="date" onSort={handleSortDataClick} />
                    <SortableHeader
                        headerText="Defense Counsel"
                        sortKey="defenseCounselOrganization"
                        subKey="name"
                        onSort={handleSortDataClick}
                        noWrap
                    />
                    <SortableHeader
                        headerText="Defense Attorney"
                        sortKey="defenseCounselUser"
                        subKey="profile"
                        subGrandKey="firstName"
                        onSort={handleSortDataClick}
                        noWrap
                    />
                    <SortableHeader
                        headerText="Plaintiffs Firm Attorney"
                        sortKey="plaintiffsFirmAttorney"
                        onSort={handleSortDataClick}
                        noWrap
                    />
                    {showActionsColumn && <th />}
                </tr>
            </thead>
            <tbody>
                {sortedRows.map((item: IDepositionModel) => {
                    const caseGuid = currentCaseMeta?.guid ? currentCaseMeta.guid : item.case?.guid;

                    return (
                        <CaseDepositionListRow
                            key={`${item.guid}-${caseGuid}`}
                            item={item}
                            currentCaseMeta={currentCaseMeta}
                            getEasyUpdateIconColorStyle={getEasyUpdateIconColorStyle}
                            onEasyUpdateClick={onEasyUpdateClick}
                            onRowAddClick={onRowAddClick}
                            onRowDeleteClick={onRowDeleteClick}
                            onRowEditClick={onRowEditClick}
                            showActionsColumn={!!showActionsColumn}
                            showCaseColumn={showCaseColumn}
                            showEasyUpdateColumn={showEasyUpdateColumn}
                            showDeleteButton={showDeleteButton}
                            showEditButton={showEditButton}
                        />
                    );
                })}
            </tbody>
        </table>
    );
}

export interface ICaseDepositionListRowProps {
    item: IDepositionModel;
    currentCaseMeta?: {
        guid: string;
        caseName: string;
    };
    getEasyUpdateIconColorStyle?: (caseGuid: string, noteGuid?: string) => string;
    onEasyUpdateClick?: (caseGuid: string, noteGuid: string) => void;
    onRowAddClick?: (caseGuid: string) => void;
    onRowDeleteClick: (item: IDepositionModel) => void;
    onRowEditClick: (item: IDepositionModel) => void;
    showActionsColumn: boolean;
    showCaseColumn?: boolean;
    showEasyUpdateColumn?: boolean;
    showDeleteButton?: boolean;
    showEditButton?: boolean;
}

function CaseDepositionListRow({
    currentCaseMeta,
    item,
    getEasyUpdateIconColorStyle,
    onEasyUpdateClick,
    onRowAddClick,
    onRowDeleteClick,
    onRowEditClick,
    showActionsColumn,
    showCaseColumn,
    showEasyUpdateColumn,
    showDeleteButton,
    showEditButton,
}: ICaseDepositionListRowProps) {
    const isEmptyEntity = item.guid === EmptyGuid;

    const caseGuid = currentCaseMeta?.guid ? currentCaseMeta.guid : item.case?.guid;
    const caseNameDisplay = currentCaseMeta?.caseName
        ? currentCaseMeta.caseName
        : item.case?.caseName;

    const getEasyUpdateIconClass = (): string => {
        if (typeof getEasyUpdateIconColorStyle === 'function' && currentCaseMeta?.guid) {
            return getEasyUpdateIconColorStyle(currentCaseMeta.guid);
        }

        return '';
    };

    const handleEasyUpdateClick = (item: IDepositionModel) => {
        if (typeof onEasyUpdateClick === 'function' && currentCaseMeta?.guid && item?.guid) {
            onEasyUpdateClick(currentCaseMeta.guid, item.guid);
        }
    };

    const handleRowAddClick = (caseGuid: string) => {
        if (typeof onRowAddClick === 'function') {
            onRowAddClick(caseGuid);
        }
    };

    return (
        <tr>
            {showCaseColumn && (
                <td>
                    {caseGuid && (
                        <Link target="_blank" to={LocalRoutes.CaseFile.replace(':guid', caseGuid)}>
                            {caseNameDisplay}
                        </Link>
                    )}
                </td>
            )}
            <td>{item.category?.displayName}</td>
            <td>{item.status?.displayName}</td>
            <td>{item.witness}</td>
            <td>{item.title}</td>
            <td>{Common.dateFormat(item.date)}</td>
            <td>{item.defenseCounselOrganization?.name ?? ''}</td>
            <td>{UIHelper.getUserDisplayName(item.defenseCounselUser)}</td>
            <td>{item.plaintiffsFirmAttorney}</td>
            {showActionsColumn && (
                <td className="table-actions">
                    <div
                        className="text-nowrap"
                        style={{
                            display: 'flex',
                            gap: 0,
                            justifyContent: 'flex-end',
                        }}
                    >
                        {!isEmptyEntity && (
                            <>
                                {showEditButton && (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={() => {
                                            onRowEditClick(item);
                                        }}
                                    >
                                        <i className="fal fa-lg fa-pen"></i>
                                    </button>
                                )}
                                {showDeleteButton && (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={() => {
                                            onRowDeleteClick(item);
                                        }}
                                    >
                                        <i className="fal fa-lg fa-trash-alt"></i>
                                    </button>
                                )}
                            </>
                        )}
                        {isEmptyEntity && (
                            <button
                                className="btn btn-no-bg"
                                style={{ margin: 0 }}
                                onClick={() => handleRowAddClick(caseGuid ?? '')}
                            >
                                <i className="fal fa-lg fa-plus" />
                            </button>
                        )}
                        {showEasyUpdateColumn && (
                            <button
                                className="btn btn-no-bg float-end"
                                onClick={() => handleEasyUpdateClick(item)}
                            >
                                <i className={`fal fa-lg fa-edit ${getEasyUpdateIconClass()}`} />
                            </button>
                        )}
                    </div>
                </td>
            )}
        </tr>
    );
}
