import { useLocation, useParams } from 'react-router-dom';

import { IUserModel } from '../../interfaces/IUser';
import { Trends } from '../report/Trends';

function TrendsWrapper(props: { user: IUserModel }) {
    const { key } = useParams();
    const location = useLocation();
    return <Trends key={key || ''} user={props.user} refreshIdentifier={location.key} />;
}

export default TrendsWrapper;
