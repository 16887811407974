import * as React from 'react';
import Common from '../../stores/Common';
import classNames from 'classnames';

export interface IDateRangePickerProps {
    disableEndDate?: boolean;
    disableStartDate?: boolean;
    endDate: string | undefined;
    startDate: string | undefined;
    onEndDateChange: (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => void;
    onStartDateChange: (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => void;
    validationError?: string | undefined;
    startInputFieldName: string;
    endInputFieldName: string;
}

export function DateRangePicker(props: IDateRangePickerProps) {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
            <input
                type="date"
                className={classNames('form-control d-inline-block', {
                    unselectClass: !(props.startDate && Common.isValidDate(props.startDate)),
                })}
                disabled={props.disableStartDate}
                min="1753-01-01"
                style={{ display: 'inline-block' }}
                value={props.startDate ? Common.dateFormat(props.startDate) : ''}
                name={props.startInputFieldName}
                onChange={props.onStartDateChange}
            />
            <span>to</span>
            <input
                type="date"
                className={classNames('form-control d-inline-block', {
                    unselectClass: !(props.endDate && Common.isValidDate(props.endDate)),
                })}
                min="1753-01-01"
                disabled={props.disableEndDate}
                style={{ display: 'inline-block' }}
                value={props.endDate ? Common.dateFormat(props.endDate) : ''}
                name={props.endInputFieldName}
                onChange={props.onEndDateChange}
            />
            {props.validationError && (
                <span className="text-danger ms-2">{props.validationError}</span>
            )}
        </div>
    );
}
