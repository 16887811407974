import { INoteCategoryModel, INoteModel } from '../../interfaces/INote';

import Authorization from '../../stores/Authorization';
import Common from '../../stores/Common';
import { DataScopesEnum } from '../../utilities/Constants';
import { IUserModel } from '../../interfaces/IUser';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import NoteHelper from '../../utilities/NoteHelper';

interface IAlertListRowProps {
    enableEasyUpdate: boolean;
    hasEasyUpdateBeenAttempted: boolean;
    isMyAlertList: boolean;
    item: INoteModel;
    nameNavigateUrl: string;
    onEasyUpdate: (caseGuid: string) => void;
    onViewClick: () => void;
    user: IUserModel;
}

export default function AlertListRow({
    enableEasyUpdate,
    hasEasyUpdateBeenAttempted,
    isMyAlertList,
    item,
    nameNavigateUrl,
    onEasyUpdate,
    onViewClick,
    user,
}: IAlertListRowProps) {
    return (
        <tr className={isMyAlertList && !item.alertReadForLoggedInUser ? 'bold' : ''}>
            <td>{item.type?.displayName ?? item.type?.name}</td>
            <td>
                {Authorization.isAuthorizedToRoute(LocalRoutes.CaseFile, user) ? (
                    <Link target="_blank" to={nameNavigateUrl}>
                        {' '}
                        {item.originEntityName}
                    </Link>
                ) : (
                    item.originEntityName
                )}
            </td>
            <td width={80}>{item!.qScore}</td>
            <td>
                <ul>
                    {item.categories?.map((value: INoteCategoryModel, i: number) => {
                        return <li key={i}>{value.fullText}</li>;
                    })}
                </ul>
            </td>
            <td>{item.decisionText}</td>
            <td>
                {item.actionTarget?.profile?.firstName + ' ' + item.actionTarget?.profile?.lastName}
            </td>
            <td>{item.watchUser ? <i className="fal fa-lg fa-glasses-alt color-gray" /> : null}</td>
            <td>{NoteHelper.getModifiedByName(item.author)}</td>
            <td>{Common.dateTimeFormatToLocal(item.modifiedDate)}</td>
            <td>{item.purpose.displayName}</td>
            <td>{item.status.displayName}</td>
            <td width={60} className="text-center">
                {item.private ? <i className="fal fa-lg fa-location-dot-slash color-gray" /> : null}
            </td>
            <td width={60} className="text-center">
                {item.allowLocalEdit ||
                item.actionTarget?.activeZoneDataScope?.id === DataScopesEnum.LocalBasic.Value ? (
                    <i
                        className={
                            'fal fa-lg fa-map-marker-edit ' +
                            (item.actionTarget?.activeZoneDataScope?.id ===
                            DataScopesEnum.LocalBasic.Value
                                ? 'color-green'
                                : 'color-gray')
                        }
                    />
                ) : null}
            </td>
            <td width={60} className="text-left">
                {enableEasyUpdate &&
                !item.originStrategyType &&
                Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, user) ? (
                    <button
                        className="btn-no-bg float-end padding-left-10 padding-top-5"
                        onClick={() => {
                            onEasyUpdate(item.caseGuid!);
                        }}
                    >
                        <i
                            className={`fal fa-lg fa-edit ${
                                hasEasyUpdateBeenAttempted ? 'color-gray' : 'color-black'
                            } `}
                        />
                    </button>
                ) : null}
                <img
                    className="view-edit-btn"
                    src="/images/ViewEditIcon.svg"
                    onClick={onViewClick}
                />
            </td>
        </tr>
    );
}
