import './resizable-dialog.scss';

import { DraggableHandle, DraggableModalDialog } from './dialog';

import { Modal } from 'react-bootstrap';
import { Resizable } from 're-resizable';

interface IDialogProps {
    body?: string;
    bodyComponent?: JSX.Element;
    cancelText?: string;
    closeButtonClassName?: string;
    confirmButtonClassName?: string;
    confirmText?: string;
    draggable?: boolean;
    handleClose: () => void;
    handleConfirm?: () => void;
    isShowingModal: boolean;
    title?: string;
    defaultHeight?: number;
    defaultWidth?: number;
    minHeight?: number;
    minWidth?: number;
    lockAspectRatio?: boolean;
}

export function ResizableDialog(props: IDialogProps) {
    return (
        <Modal
            dialogAs={props.draggable ? DraggableModalDialog : undefined}
            centered
            show={props.isShowingModal}
            dialogClassName="resizable-dialog"
            size="lg"
            onHide={props.handleClose}
            backdrop={false}
        >
            <Resizable
                defaultSize={{
                    width: props.defaultWidth ?? 'auto',
                    height: props.defaultHeight ?? 'auto',
                }}
                minHeight={props.minHeight}
                minWidth={props.minWidth}
                className="modal-resizable"
                lockAspectRatio={props.lockAspectRatio}
            >
                <div className="flex-container">
                    {props.title ? (
                        <DraggableHandle enabled={props.draggable}>
                            <Modal.Header>
                                <Modal.Title>{props.title}</Modal.Title>
                                {props.draggable && (
                                    <i className="fa-regular fa-grip-dots-vertical" />
                                )}
                            </Modal.Header>
                        </DraggableHandle>
                    ) : null}
                    <Modal.Body>
                        {props.body &&
                            props.body.split('\n').map((text: string, i: number) => {
                                return (
                                    <p key={i}>
                                        {text}
                                        <br />
                                    </p>
                                );
                            })}
                        {props.bodyComponent && <>{props.bodyComponent}</>}
                    </Modal.Body>
                    <Modal.Footer>
                        {props.handleClose ? (
                            <button
                                className={props.closeButtonClassName}
                                onClick={props.handleClose}
                            >
                                {props.cancelText ? props.cancelText : 'Cancel'}
                            </button>
                        ) : null}
                        {props.handleConfirm ? (
                            <button
                                className={props.confirmButtonClassName}
                                onClick={props.handleConfirm}
                            >
                                {props.confirmText ? props.confirmText : 'OK'}
                            </button>
                        ) : null}
                    </Modal.Footer>
                </div>
            </Resizable>
        </Modal>
    );
}
