import { useParams } from 'react-router-dom';
import { IUserModel } from '../../interfaces/IUser';
import { LocalCounselStrategyDetail } from '../strategy/LocalCounselStrategyDetail';

function LocalCounselStrategyDetailWrapper(props: { user: IUserModel }) {
    const { guid } = useParams();
    return <LocalCounselStrategyDetail guid={guid} user={props.user} />;
}

export default LocalCounselStrategyDetailWrapper;
