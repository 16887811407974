import { IUserModel } from '../../interfaces/IUser';
import { useLocation } from 'react-router';
import { Snapshot } from '../Snapshot';

function SnapshotWrapper(props: { user: IUserModel }) {
    const { key } = useLocation();
    return <Snapshot user={props.user} refreshIdentifier={key} />;
}

export default SnapshotWrapper;
