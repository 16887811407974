import * as React from 'react';
import { Navigate } from 'react-router-dom';

import {
    IAssertionDescriptorModel,
    IAssertionDescriptorValueModel,
    IAssertionDescriptorExtendedModel,
    IAssertionDescriptorImpliedValueModel,
} from '../../interfaces/IAssertionDescriptor';
import { IValidation } from '../../interfaces/IError';
import { ILookupModel } from '../../interfaces/ILookup';
import ApiClient from '../../services/apiClient';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import ValidateUtils from '../../shared/validations';
import Authorization from '../../stores/Authorization';
import { AssertionDescriptorValueModal } from '../../components/assertiondescriptor/AssertionDescriptorValueModal';
import { Loader } from '../../components/shared/Loader';
import { AssertionDescriptorValueSourceTypeEnum } from '../../utilities/Constants';
import { IUserModel } from '../../interfaces/IUser';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
let _apiClient = new ApiClient();

interface IAssertionDescriptorDetailProps {
    guid?: string;
    user: IUserModel;
}

interface IAssertionDescriptorDetailState {
    assertionDescriptor: IAssertionDescriptorExtendedModel;
    assertionDescriptorLevels: ILookupModel[];
    assertionDescriptorUsageTypes: ILookupModel[];
    assertionDescriptorCategories: ILookupModel[];
    assertionDescriptorValueSourceTypes: ILookupModel[];
    assertionDescriptorValueDisplayTypes: ILookupModel[];
    assertionDescriptorDispositionTypes: ILookupModel[];
    validation: IValidation;
    createNew: boolean;
    redirect: boolean;
    displayDialog: boolean;
    addNewAssertionDescriptorValue: boolean;
    selectedAssertionDescriptorValue: IAssertionDescriptorValueModel;
    selectedAssertionDescriptorValueIndex: number;
    pendingResponse: boolean;
    displayDependentImpliedValues: boolean;
    readOnlyName: boolean;
}

export class AssertionDescriptorDetail extends React.Component<
    IAssertionDescriptorDetailProps,
    IAssertionDescriptorDetailState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            assertionDescriptor: {},
            assertionDescriptorLevels: [],
            assertionDescriptorUsageTypes: [],
            assertionDescriptorCategories: [],
            assertionDescriptorValueSourceTypes: [],
            assertionDescriptorDispositionTypes: [],
            assertionDescriptorValueDisplayTypes: [],
            validation: {},
            createNew: false,
            redirect: false,
            displayDialog: false,
            addNewAssertionDescriptorValue: false,
            selectedAssertionDescriptorValue: {},
            selectedAssertionDescriptorValueIndex: 0,
            pendingResponse: true,
            displayDependentImpliedValues: false,
            readOnlyName: false,
        };
    }

    componentDidMount() {
        if (
            !Authorization.isAuthorizedToRoute(
                LocalRoutes.AssertionDescriptorDetail,
                this.props.user
            )
        )
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadAssertionDescriptorLevels();
        this.loadAssertionDescriptorUsageTypes();
        this.loadAssertionDescriptorCategories();
        this.loadAssertionDescriptorValueSourceTypes();
        this.loadAssertionDescriptorDispositionTypes();
        this.loadAssertionDescriptorValueDisplayTypes();

        this.setState({ pendingResponse: false });

        if (this.props.guid) {
            this.loadAssertionDescriptorById(this.props.guid);
            this.setState({ readOnlyName: true });
        } else {
            this.setState({ createNew: true });
        }
    }

    /*     componentDidUpdate(newProps: any) {
            const { guid } = newProps.match.params;
            this.loadAssertionDescriptorById(guid);
        } */

    loadAssertionDescriptorLevels = async () => {
        var response = await _apiClient.getAssertionDescriptorLevels();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        this.setState({ assertionDescriptorLevels: response.payload! });
    };

    loadAssertionDescriptorUsageTypes = async () => {
        var response = await _apiClient.getAssertionDescriptorUsageTypes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        this.setState({ assertionDescriptorUsageTypes: response.payload! });
    };

    loadAssertionDescriptorCategories = async () => {
        var response = await _apiClient.getAssertionDescriptorCategories();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        this.setState({ assertionDescriptorCategories: response.payload! });
    };

    loadAssertionDescriptorValueSourceTypes = async () => {
        var response = await _apiClient.getAssertionDescriptorValueSourceType();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        this.setState({ assertionDescriptorValueSourceTypes: response.payload! });
    };

    loadAssertionDescriptorValueDisplayTypes = async () => {
        var response = await _apiClient.getAssertionDescriptorValueDisplayType();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        this.setState({ assertionDescriptorValueDisplayTypes: response.payload! });
    };

    loadAssertionDescriptorDispositionTypes = async () => {
        var response = await _apiClient.getAssertionDescriptorDispositionType();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        this.setState({ assertionDescriptorDispositionTypes: response.payload! });
    };

    loadAssertionDescriptorById = async (guid: string) => {
        this.setState({ pendingResponse: true });
        try {
            var res = await _apiClient.getAssertionDescriptorById(guid);
            if (res.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (res.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                    pendingResponse: false,
                });
                return;
            }
            if (res.payload) {
                this.setState({ assertionDescriptor: res.payload, pendingResponse: false });
            }
            return true;
        } catch (error) {
            return false;
        }
    };

    handleCheckmateSelectChange = (selectedItem: any, name: string) => {
        let assertionDesciptor = this.state.assertionDescriptor;
        let validation = this.state.validation;
        const value = selectedItem ? selectedItem.id : 0;
        switch (name) {
            case 'assertionDescriptorLevel':
                if (value != '') {
                    assertionDesciptor.level = {
                        id: value,
                    };
                } else {
                    assertionDesciptor.level = undefined;
                }

                validation.level = [''];
                break;
            case 'assertionDescriptorUsageType':
                if (value != '') {
                    assertionDesciptor.usageType = {
                        id: value,
                    };
                } else {
                    assertionDesciptor.usageType = undefined;
                }

                validation.usagetype = [''];
                break;
            case 'assertionDescriptorCategory':
                if (value != '') {
                    assertionDesciptor.category = {
                        id: value,
                    };
                } else {
                    assertionDesciptor.category = undefined;
                }
                validation.category = [''];
                break;
            case 'assertionDescriptorValueSourceType':
                if (value != '') {
                    assertionDesciptor.valueSourceType = {
                        id: value,
                    };
                } else {
                    assertionDesciptor.valueSourceType = undefined;
                }
                validation.valuesourcetype = [''];
                break;
            case 'assertionDescriptorValueDisplayType':
                if (value != '') {
                    assertionDesciptor.valueDisplayType = {
                        id: value,
                    };
                } else {
                    assertionDesciptor.valueDisplayType = undefined;
                }
                validation.valuedisplaytype = [''];
                break;
            case 'assertionDescriptorDispositionType':
                if (value != '') {
                    assertionDesciptor.dispositionType = {
                        id: value,
                    };
                } else {
                    assertionDesciptor.dispositionType = undefined;
                }
                validation.dispositiontype = [''];
                break;
            default:
                break;
        }
    };

    changeValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setChangedValue(name, value);
    };

    setChangedValue = (name: string, value: any) => {
        let assertionDesciptor = this.state.assertionDescriptor;
        let validation = this.state.validation;
        let displayDependentImpliedValues = this.state.displayDependentImpliedValues;

        if (assertionDesciptor) {
            switch (name) {
                case 'name':
                    assertionDesciptor.name = value;
                    validation.name = [''];
                    break;
                case 'displayName':
                    assertionDesciptor.displayName = value;
                    validation.displayname = [''];
                    break;
                case 'description':
                    assertionDesciptor.description = value;
                    validation.description = [''];
                    break;
                case 'weight':
                    assertionDesciptor.weight = value;
                    validation.weight = [''];
                    break;
                case 'displayDependentImpliedValues':
                    displayDependentImpliedValues = !displayDependentImpliedValues;
                    break;
                default:
                    break;
            }
            this.setState({
                assertionDescriptor: assertionDesciptor,
                displayDependentImpliedValues: displayDependentImpliedValues,
                validation: validation,
            });
        }
    };

    removeAssertionDescriptorValue = (
        event: React.FormEvent<HTMLButtonElement>,
        index: number,
        assertionDescriptorValue: IAssertionDescriptorValueModel
    ) => {
        event.preventDefault();
        let assertionDescriptor = this.state.assertionDescriptor;
        if (assertionDescriptor && assertionDescriptor.assertionDescriptorValues) {
            assertionDescriptor.assertionDescriptorValues.splice(index, 1);
        }
        this.setState({ assertionDescriptor: assertionDescriptor });
    };

    updateAssertionDescriptorValue = (
        event: React.FormEvent<HTMLButtonElement>,
        index: number,
        assertionDescriptorValue: IAssertionDescriptorValueModel
    ) => {
        event.preventDefault();
        this.setState({
            displayDialog: true,
            addNewAssertionDescriptorValue: false,
            selectedAssertionDescriptorValueIndex: index,
            selectedAssertionDescriptorValue: assertionDescriptorValue,
        });
    };

    addAssertionDescriptorValue = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        this.setState({
            displayDialog: true,
            addNewAssertionDescriptorValue: true,
            selectedAssertionDescriptorValue: {},
        });
    };

    handleAssertionDescriptorValueModalClose = () => {
        this.setState({ displayDialog: false });
    };

    handleAssertionDescriptorValueModalConfirm = (
        assertionDescriptorValue: IAssertionDescriptorValueModel
    ) => {
        let assertionDescriptor = this.state.assertionDescriptor;
        if (this.state.addNewAssertionDescriptorValue) {
            if (assertionDescriptor.assertionDescriptorValues) {
                assertionDescriptor.assertionDescriptorValues.push(assertionDescriptorValue);
            } else {
                assertionDescriptor.assertionDescriptorValues = new Array(assertionDescriptorValue);
            }
        } else {
            if (
                assertionDescriptor.assertionDescriptorValues &&
                assertionDescriptor.assertionDescriptorValues.length != 0
            ) {
                assertionDescriptor.assertionDescriptorValues[
                    this.state.selectedAssertionDescriptorValueIndex
                ] = assertionDescriptorValue;
            }
        }
        this.setState({
            assertionDescriptor: assertionDescriptor,
            displayDialog: false,
        });
    };

    saveDetail = async () => {
        this.setState({ pendingResponse: true });
        let assertionDescriptor: IAssertionDescriptorModel = this.state.assertionDescriptor;
        if (this.state.createNew) {
            var response = await _apiClient.createAssertionDescriptor(assertionDescriptor);
            if (response.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (response.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                    pendingResponse: false,
                });
            } else {
                this.setState({ redirect: true, pendingResponse: false });
            }
        } else {
            var response = await _apiClient.updateAssertionDescriptor(assertionDescriptor);
            if (response.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (response.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                    pendingResponse: false,
                });
            } else {
                this.setState({ redirect: true, pendingResponse: false });
            }
        }
    };

    cancelDetail = () => {
        this.setState({ redirect: true });
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;

        if (this.state.redirect) return <Navigate to={LocalRoutes.AssertionDescriptors} />;

        return (
            <>
                <div className="row mb-3">
                    <h1 className="col-sm-6">
                        Assertion Descriptor - {this.state.createNew ? 'New' : 'Detail'}
                    </h1>
                    <div className="col-sm-6 float-end">
                        <input
                            type="button"
                            className="btn btn-default float-end"
                            onClick={this.cancelDetail}
                            value="Cancel"
                        />
                        <input
                            type="button"
                            className="btn btn-orange float-end "
                            onClick={this.saveDetail}
                            value="Save"
                        />
                    </div>
                </div>

                <div className="row mb-2">
                    <input
                        type="hidden"
                        name="id"
                        value={this.state.assertionDescriptor.guid || ''}
                    />
                    <span className="text-danger">{this.state.validation.model}</span>
                </div>
                <div className="row mb-2">
                    <label className="control-label col-sm-2" htmlFor="name">
                        Name*
                    </label>
                    <input
                        readOnly={this.state.readOnlyName}
                        type="text"
                        name="name"
                        className="form-control col-sm-10"
                        value={this.state.assertionDescriptor.name || ''}
                        onChange={this.changeValue}
                    />
                    <span className="text-danger">{this.state.validation.name}</span>
                </div>
                <div className="row mb-2">
                    <label className="control-label col-sm-2" htmlFor="displayName">
                        Display Name
                    </label>
                    <input
                        type="text"
                        name="displayName"
                        className="form-control col-sm-10"
                        value={this.state.assertionDescriptor.displayName || ''}
                        onChange={this.changeValue}
                    />
                    <span className="text-danger">{this.state.validation.displayname}</span>
                </div>
                <div className="row mb-2">
                    <label className="control-label col-sm-2" htmlFor="description">
                        Description
                    </label>
                    <input
                        type="text"
                        name="description"
                        className="form-control col-sm-10"
                        value={this.state.assertionDescriptor.description || ''}
                        onChange={this.changeValue}
                    />
                    <span className="text-danger">{this.state.validation.description}</span>
                </div>
                <div className="row mb-2">
                    <label className="control-label col-sm-2" htmlFor="weight">
                        Weight
                    </label>
                    <input
                        type="number"
                        name="weight"
                        className="form-control col-sm-10"
                        value={this.state.assertionDescriptor.weight || ''}
                        onChange={this.changeValue}
                    />
                    <span className="text-danger">{this.state.validation.weight}</span>
                </div>
                <div className="row mb-2">
                    <label className="control-label col-sm-2" htmlFor="assertionDescriptorLevel">
                        Level
                    </label>
                    <CheckmateSelect
                        name="assertionDescriptorLevel"
                        options={CheckmateSelectHelper.getLookupOptions(
                            this.state.assertionDescriptorLevels
                        )}
                        value={CheckmateSelectHelper.getSelectedValueById(
                            this.state.assertionDescriptorLevels,
                            this.state.assertionDescriptor.level
                                ? this.state.assertionDescriptor.level.id
                                : 0
                        )}
                        onChange={(e: any) => {
                            this.handleCheckmateSelectChange(e, 'assertionDescriptorLevel');
                        }}
                    />
                    <span className="text-danger">{this.state.validation.level}</span>
                </div>
                <div className="row mb-2">
                    <label
                        className="control-label col-sm-2"
                        htmlFor="assertionDescriptorUsageType"
                    >
                        Usage Type*
                    </label>
                    <CheckmateSelect
                        name="assertionDescriptorUsageType"
                        options={CheckmateSelectHelper.getLookupOptions(
                            this.state.assertionDescriptorUsageTypes
                        )}
                        value={CheckmateSelectHelper.getSelectedValueById(
                            this.state.assertionDescriptorUsageTypes,
                            this.state.assertionDescriptor.usageType
                                ? this.state.assertionDescriptor.usageType.id
                                : 0
                        )}
                        onChange={(e: any) => {
                            this.handleCheckmateSelectChange(e, 'assertionDescriptorUsageType');
                        }}
                    />
                    <span className="text-danger">{this.state.validation.usagetype}</span>
                </div>
                <div className="row mb-2">
                    <label className="control-label col-sm-2" htmlFor="assertionDescriptorCategory">
                        Category
                    </label>
                    <CheckmateSelect
                        name="assertionDescriptorCategory"
                        options={CheckmateSelectHelper.getLookupOptions(
                            this.state.assertionDescriptorCategories
                        )}
                        value={CheckmateSelectHelper.getSelectedValueById(
                            this.state.assertionDescriptorCategories,
                            this.state.assertionDescriptor.category
                                ? this.state.assertionDescriptor.category.id
                                : 0
                        )}
                        onChange={(e: any) => {
                            this.handleCheckmateSelectChange(e, 'assertionDescriptorCategory');
                        }}
                    />
                    <span className="text-danger">{this.state.validation.category}</span>
                </div>
                <div className="row mb-2">
                    <label
                        className="control-label col-sm-2"
                        htmlFor="assertionDescriptorValueDisplayType"
                    >
                        Value Display Type
                    </label>
                    <CheckmateSelect
                        name="assertionDescriptorValueDisplayType"
                        options={CheckmateSelectHelper.getLookupOptions(
                            this.state.assertionDescriptorValueDisplayTypes
                        )}
                        value={CheckmateSelectHelper.getSelectedValueById(
                            this.state.assertionDescriptorValueDisplayTypes,
                            this.state.assertionDescriptor.valueDisplayType
                                ? this.state.assertionDescriptor.valueDisplayType.id
                                : 0
                        )}
                        onChange={(e: any) => {
                            this.handleCheckmateSelectChange(
                                e,
                                'assertionDescriptorValueDisplayType'
                            );
                        }}
                    />
                    <span className="text-danger">{this.state.validation.valuedisplaytype}</span>
                </div>
                <div className="row mb-2">
                    <label
                        className="control-label col-sm-2"
                        htmlFor="assertionDescriptorValueSourceType"
                    >
                        Value Source Type*
                    </label>
                    <CheckmateSelect
                        name="assertionDescriptorValueSourceType"
                        options={CheckmateSelectHelper.getLookupOptions(
                            this.state.assertionDescriptorValueSourceTypes
                        )}
                        value={CheckmateSelectHelper.getSelectedValueById(
                            this.state.assertionDescriptorValueSourceTypes,
                            this.state.assertionDescriptor.valueSourceType
                                ? this.state.assertionDescriptor.valueSourceType.id
                                : 0
                        )}
                        onChange={(e: any) => {
                            this.handleCheckmateSelectChange(
                                e,
                                'assertionDescriptorValueSourceType'
                            );
                        }}
                    />
                    <span className="text-danger">{this.state.validation.valuesourcetype}</span>
                </div>
                <div className="row mb-2">
                    <label
                        className="control-label col-sm-2"
                        htmlFor="assertionDescriptorDispositionType"
                    >
                        Disposition Type*
                    </label>
                    <CheckmateSelect
                        name="assertionDescriptorDispositionType"
                        options={CheckmateSelectHelper.getLookupOptions(
                            this.state.assertionDescriptorDispositionTypes
                        )}
                        value={CheckmateSelectHelper.getSelectedValueById(
                            this.state.assertionDescriptorDispositionTypes,
                            this.state.assertionDescriptor.dispositionType
                                ? this.state.assertionDescriptor.dispositionType.id
                                : 0
                        )}
                        onChange={(e: any) => {
                            this.handleCheckmateSelectChange(
                                e,
                                'assertionDescriptorDispositionType'
                            );
                        }}
                    />
                    <span className="text-danger">{this.state.validation.dispositiontype}</span>
                </div>
                {this.state.assertionDescriptor &&
                this.state.assertionDescriptor.valueSourceType &&
                this.state.assertionDescriptor.valueSourceType.id != 2 ? (
                    <div className="row mb-2">
                        <label
                            className="control-label col-sm-2"
                            htmlFor="assertionDescriptorValueSourceType"
                        >
                            Assertion Descriptor Value(s)
                        </label>
                        <br />
                        <button
                            className="btn btn-default  btn-icon"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                this.addAssertionDescriptorValue(e);
                            }}
                        >
                            <i className="fal fa-lg fa-plus" />
                        </button>
                        {this.state.assertionDescriptor &&
                        this.state.assertionDescriptor.assertionDescriptorValues ? (
                            <table className="table mx-4">
                                <thead>
                                    <tr>
                                        <th>Text</th>
                                        <th>Numeric Value</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.assertionDescriptor.assertionDescriptorValues.map(
                                        (
                                            assertionDescriptorValue: IAssertionDescriptorValueModel,
                                            index: number
                                        ) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="col">
                                                        {assertionDescriptorValue.text}
                                                    </td>
                                                    <td className="col">
                                                        {assertionDescriptorValue.numericValue}
                                                    </td>
                                                    <td className="col pe-4">
                                                        <button
                                                            className="btn btn-no-bg float-end"
                                                            onClick={(
                                                                e: React.MouseEvent<HTMLButtonElement>
                                                            ) => {
                                                                this.removeAssertionDescriptorValue(
                                                                    e,
                                                                    index,
                                                                    assertionDescriptorValue
                                                                );
                                                            }}
                                                        >
                                                            <i className="fal fa-lg fa-trash-alt"></i>
                                                        </button>
                                                        <button
                                                            className="btn btn-no-bg float-end "
                                                            onClick={(
                                                                e: React.MouseEvent<HTMLButtonElement>
                                                            ) => {
                                                                this.updateAssertionDescriptorValue(
                                                                    e,
                                                                    index,
                                                                    assertionDescriptorValue
                                                                );
                                                            }}
                                                        >
                                                            <i className="fal fa-lg fa-edit" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        ) : null}
                    </div>
                ) : null}
                {this.state.assertionDescriptor.valueSourceType &&
                this.state.assertionDescriptor.valueSourceType.id ==
                    AssertionDescriptorValueSourceTypeEnum.Implied.Value ? (
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name="displayDependentImpliedValues"
                            onChange={this.changeValue}
                            checked={this.state.displayDependentImpliedValues}
                        />
                        <label
                            className="form-check-label "
                            htmlFor="displayDependentImpliedValues"
                        >
                            Display Dependent Assertion Descriptor(s)
                        </label>
                    </div>
                ) : null}
                {this.state.displayDependentImpliedValues &&
                this.state.assertionDescriptor &&
                this.state.assertionDescriptor.impliedAssertionDescriptorValues &&
                this.state.assertionDescriptor.impliedAssertionDescriptorValues.length != 0 ? (
                    <table className="table mx-4">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Dependant Assertion Descriptor Value(s)</th>
                                <th>Implied Assertion Descriptor Value</th>
                            </tr>
                            <tr>
                                <th />
                                <th>
                                    <ul>
                                        {this.state.assertionDescriptor
                                            .impliedAssertionDescriptorValues &&
                                        this.state.assertionDescriptor
                                            .impliedAssertionDescriptorValues.length > 0 &&
                                        this.state.assertionDescriptor
                                            .impliedAssertionDescriptorValues[0]
                                            .dependentAssertionDescriptorValues
                                            ? this.state.assertionDescriptor.impliedAssertionDescriptorValues[0].dependentAssertionDescriptorValues!.map(
                                                  (
                                                      assertionDescriptorValue: IAssertionDescriptorValueModel
                                                  ) => {
                                                      return (
                                                          <li className="text-gray-li">
                                                              {
                                                                  assertionDescriptorValue.assertionDescriptor!
                                                                      .displayName
                                                              }
                                                          </li>
                                                      );
                                                  }
                                              )
                                            : null}
                                    </ul>
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.assertionDescriptor.impliedAssertionDescriptorValues.map(
                                (
                                    impliedAD: IAssertionDescriptorImpliedValueModel,
                                    index: number
                                ) => {
                                    return (
                                        <tr key={index}>
                                            <td className="col">{index + 1}</td>
                                            <td className="col">
                                                <ul>
                                                    {impliedAD.dependentAssertionDescriptorValues!.map(
                                                        (
                                                            dependentAD: IAssertionDescriptorValueModel,
                                                            i: number
                                                        ) => {
                                                            return <li>{dependentAD.text}</li>;
                                                        }
                                                    )}
                                                </ul>
                                            </td>
                                            <td className="col pe-4">
                                                {impliedAD.impliedAssertionDescriptorValue!.text}
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                ) : null}

                <AssertionDescriptorValueModal
                    displayDialog={this.state.displayDialog}
                    addNewAssertionDescriptorValue={this.state.addNewAssertionDescriptorValue}
                    handleAssertionDescriptorValueModalClose={
                        this.handleAssertionDescriptorValueModalClose
                    }
                    assertionDescriptorValue={this.state.selectedAssertionDescriptorValue}
                    handleAssertionDescriptorValueModalConfirm={
                        this.handleAssertionDescriptorValueModalConfirm
                    }
                />
            </>
        );
    }
}
