import { EntityTypes, UserRightsEnum } from '../../utilities/Constants';
import { IExpertCreateModel, IGeneralOpinion } from '../../interfaces/IExpert';

import ApiClient from '../../services/apiClient';
import Authorization from '../../stores/Authorization';
import { CheckmateDialog } from '../../components/shared/dialog';
import { ExpertDetailForm } from '../../components/expert/ExpertDetailForm';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { WorkProductWrapper } from '../../components/work-product/WorkProductWrapper';
import { useExpertCreateMutation } from './useExpertCreateMutation';
import { useNavigate } from 'react-router';
import { useState } from 'react';

const _apiClient = new ApiClient();

export interface IExpertCreateProps {
    user: IUserModel;
}

export function ExpertCreate(props: IExpertCreateProps) {
    const navigate = useNavigate();

    const authorizedToCreateExpert = Authorization.userHasRight(
        UserRightsEnum.AddExpert,
        props.user
    );

    const [showDuplicateExpertMsg, setShowDuplicateExpertMsg] = useState<boolean>(false);
    const [formData, setFormData] = useState<IExpertCreateModel>();
    const [error, setError] = useState<string>();
    const [validation, setValidation] = useState<IValidation>({});

    const createMutation = useExpertCreateMutation((errorMessage: string) =>
        setError(errorMessage)
    );

    const handleSaveClick = async (onSuccess?: (epxertGuid: string) => void) => {
        const validation: IValidation = {};
        let hasErrors = false;

        if (!formData?.lastName) {
            validation.lastName = ['Required'];
            hasErrors = true;
        }

        if (!formData?.firstName) {
            validation.firstName = ['Required'];
            hasErrors = true;
        }

        /*if (!formData?.credentials) {
            validation.credentials = ['Required'];
            hasErrors = true;
        }*/

        if (!formData?.expertTypeId) {
            validation.type = ['Required'];
            hasErrors = true;
        }

        if (!formData?.disciplines?.length ?? 0 === 0) {
            validation.discipline = ['Required'];
            hasErrors = true;
        }

        setValidation(validation);

        if (hasErrors) {
            return;
        }

        const expertLastName = formData?.lastName ?? '';
        const response = await _apiClient.isExpertNameExists(expertLastName, '');
        if (response.errorMessage) {
            if (response.errors !== undefined && response.errors[0] !== undefined) {
                setError(`Failed to create Expert : ${response.errors[0].message}`);
            }
            return;
        }

        if (response?.httpResponse.ok) {
            //if expert Last Name is duplicate then show the warning message.
            if (response.payload === true) {
                setShowDuplicateExpertMsg(true);
            } else {
                //other wise Create the New expert.
                createExpert(onSuccess);
            }
        }
    };

    const handleCancelClick = () => {
        navigate(LocalRoutes.Experts);
    };

    const handleCreateEntityBeforeUpload = () => {
        const onSaveSuccess = (expertGuid: string) => {
            navigate(LocalRoutes.ExpertDetail.replace(':id', expertGuid), {
                state: { showUpload: true },
            });
        };

        handleSaveClick(onSaveSuccess);
    };

    const createExpertWithDuplicateName = async () => {
        await createExpert();
    };

    const createExpert = async (onSuccess?: (epxertGuid: string) => void) => {
        const mappedFormData: IExpertCreateModel = {
            ...formData,
            firstName: formData?.firstName ?? '',
            lastName: formData?.lastName ?? '',
            credentials: formData?.credentials ?? '',
            expertTypeId: formData?.expertTypeId ?? 0,
            generalOpinions: formData?.generalOpinions?.map(
                (go, idx) =>
                    ({
                        topic: {
                            ...go.topic,
                            correlationKeyId: idx,
                        },
                        opinion: {
                            ...go.opinion,
                            correlationKeyId: idx,
                        },
                    } as IGeneralOpinion)
            ),
        };

        createMutation
            .mutateAsync(mappedFormData!)
            .then((newExpert) => {
                if (typeof onSuccess === 'function' && newExpert) {
                    onSuccess(newExpert?.guid);
                } else {
                    navigate(LocalRoutes.Experts);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    if (!authorizedToCreateExpert) {
        return (
            <div className="row">
                <div className="text-danger">
                    You do not have permission to perform this action.
                </div>
            </div>
        );
    }
    return (
        <>
            {createMutation.isLoading && <Loader />}
            <div className="row mb-3">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <h1>Add New Expert</h1>
                    <div>
                        <button
                            type="button"
                            className="btn btn-orange me-2"
                            onClick={() => handleSaveClick()}
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            className="btn btn-default"
                            onClick={handleCancelClick}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            {error && (
                <div>
                    <span className="text-danger">{error}</span>
                </div>
            )}
            <ExpertDetailForm
                user={props.user}
                onFormDataUpdate={setFormData}
                isCreateMode
                isEditMode
                validation={validation}
            />
            <div className="row" id="AttachmentsDiv">
                <WorkProductWrapper
                    uploadOnly={true}
                    user={props.user}
                    entityGuid={''}
                    entityTypeId={EntityTypes.Expert}
                    title="Attachments"
                    documents={[]}
                    parentEntityJson={'{}'}
                    parentEntityRedirectUrl={LocalRoutes.ExpertDetail.replace(':id', '')}
                    authorizedToEdit={Authorization.userHasRight(
                        UserRightsEnum.UpdateExpert,
                        props.user
                    )}
                    onCreateEntityBeforeUpload={handleCreateEntityBeforeUpload}
                />
            </div>
            <CheckmateDialog
                isShowingModal={showDuplicateExpertMsg}
                body="An expert with the same last name already exists. Do you want to continue?"
                handleClose={() => {
                    setShowDuplicateExpertMsg(false);
                }}
                handleConfirm={() => {
                    createExpertWithDuplicateName();
                    setShowDuplicateExpertMsg(false);
                }}
                confirmText="Yes"
                cancelText="No"
                confirmButtonClassName="btn btn-black float-end"
                dialogClassName="confirm-document-delete-dialog"
                closeButtonClassName="btn btn-default float-end"
            />
        </>
    );
}
