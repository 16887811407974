export default class Sort {
    static compareValues(key: string, subKey?: string, order = 'asc', subGrandKey?: string) {
        return function (a: any, b: any) {
            if (
                !Object.getPrototypeOf(a).hasOwnProperty.call(a, key) ||
                !Object.getPrototypeOf(b).hasOwnProperty.call(b, key)
            ) {
                return 0;
            }
            let varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
            let varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
            if (subKey) {
                if (varA !== null)
                    varA =
                        typeof a[key][subKey] === 'string'
                            ? a[key][subKey].toUpperCase()
                            : a[key][subKey];
                if (varB !== null)
                    varB =
                        typeof b[key][subKey] === 'string'
                            ? b[key][subKey].toUpperCase()
                            : b[key][subKey];
            }
            if (subGrandKey && subKey) {
                if (varA !== null)
                    varA =
                        typeof a[key][subKey][subGrandKey] === 'string'
                            ? a[key][subKey][subGrandKey].toUpperCase()
                            : a[key][subKey][subGrandKey];
                if (varB !== null)
                    varB =
                        typeof b[key][subKey][subGrandKey] === 'string'
                            ? b[key][subKey][subGrandKey].toUpperCase()
                            : b[key][subKey][subGrandKey];
            }

            if (varA === null) varA = '';
            if (varB === null) varB = '';

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return order === 'desc' ? comparison * -1 : comparison;
        };
    }

    static compareDate(key: string, subKey?: string, order = 'asc') {
        return function (a: any, b: any) {
            if (
                a === undefined ||
                b === undefined ||
                !Object.getPrototypeOf(a).hasOwnProperty.call(a, key) ||
                !Object.getPrototypeOf(b).hasOwnProperty.call(b, key)
            ) {
                return 0;
            }
            let varA = '';
            let varB = '';
            if (subKey) {
                if (a[key] != null) {
                    varA =
                        typeof a[key][subKey] === 'string'
                            ? a[key][subKey].substring(0, 16).toUpperCase()
                            : a[key][subKey];
                }
                if (b[key] != null) {
                    varB =
                        typeof b[key][subKey] === 'string'
                            ? b[key][subKey].substring(0, 16).toUpperCase()
                            : b[key][subKey];
                }
            } else {
                varA = typeof a[key] === 'string' ? a[key].substring(0, 16).toUpperCase() : a[key];
                varB = typeof b[key] === 'string' ? b[key].substring(0, 16).toUpperCase() : b[key];
            }

            if (varA == null) varA = '0001-01-01';
            if (varB == null) varB = '0001-01-01';

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return order == 'desc' ? comparison * -1 : comparison;
        };
    }

    static compareValuesByIndex(index: number, datatype?: string, order = 'asc') {
        return function (a: any, b: any) {
            let varA;
            let varB;

            if (!datatype) datatype = 'string';

            if (datatype === 'currency' || datatype === 'percentage') {
                varA = a[index] == null ? null : Number(a[index]);
                varB = b[index] == null ? null : Number(b[index]);

                if (varA == null) varA = -1;
                if (varB == null) varB = -1;
            } else if (datatype === 'datetime') {
                varA = a[index] == null ? null : a[index].substring(0, 10).toUpperCase();
                varB = b[index] == null ? null : b[index].substring(0, 10).toUpperCase();

                if (varA == null) varA = '0001-01-01';
                if (varB == null) varB = '0001-01-01';
            } else {
                varA = a[index] == null ? null : a[index].toUpperCase();
                varB = b[index] == null ? null : b[index].toUpperCase();

                if (varA == null) varA = '';
                if (varB == null) varB = '';
            }

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return order == 'desc' ? comparison * -1 : comparison;
        };
    }
}
