import * as React from 'react';

import { AlertListTypeEnum, StrategyTypes } from '../utilities/Constants';
import { IOrganizationRoleModel, IUserModel } from '../interfaces/IUser';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';

import { AccessDenied } from '../pages/AccessDenied';
import AlertListWrapper from '../pages/wrapper/AlertListWrapper';
import AssertionDescriptorDetailWrapper from '../pages/wrapper/AssertionDescriptorDetailWrapper';
import { AssertionDescriptorList } from '../pages/assertiondescriptor/AssertionDescriptorList';
import Auth from '../stores/authentication';
import Authorization from '../stores/Authorization';
import { Board } from '../pages/board/Board';
import BoardCaseDetailWrapper from '../pages/wrapper/BoardCaseDetailWrapper';
import { BoardViewDetail } from '../pages/board/BoardViewDetail';
import CaseEasyUpdateWrapper from '../pages/wrapper/CaseEasyUpdateWrapper';
import { CaseFile } from '../pages/case/CaseFile';
import { CaseFileProvider } from '../contexts/CaseFileContext';
import CaseListWrapper from '../pages/wrapper/CaseListWrapper';
import CheckmateApiClient from '../services/apiClient';
import { CheckmateDialog } from './shared/dialog';
import CheckmatePDFViewer from '../pages/CheckmatePDFViewer';
import EntityStrategyWrapper from '../pages/wrapper/EntityStrategyWrapper';
import ExecutionPlanWrapper from '../pages/wrapper/ExecutionPlanWrapper';
import { ExpertCreate } from '../pages/experts/ExpertCreate';
import { ExpertDetail } from '../pages/experts/ExpertDetail';
import { ExpertsList } from '../pages/experts/ExpertsList';
import { Home } from '../pages/Home';
import { HubConnection } from '@microsoft/signalr';
import { IAlertSummaryModel } from '../interfaces/INote';
import { Invitation } from '../pages/user/Invitation';
import JurisdictionStrategyDetailWrapper from '../pages/wrapper/JurisdictionStrategyDetailWrapper';
import { KpiReport } from '../pages/report/KpiReport';
import KpiViewWrapper from '../pages/wrapper/TrendsWrapper';
import LocalCounselStrategyDetailWrapper from '../pages/wrapper/LocalCounselStrategyDetailWrapper';
import { LocalRoutes } from '../utilities/LocalRoutes';
import { Login } from '../pages/account/Login';
import { Logout } from '../pages/account/Logout';
import MeetingAgendaReportWrapper from '../pages/wrapper/MeetingAgendaReportWrapper';
import { MonthlyRecap } from '../pages/report/timeline/MonthlyRecap';
import NavMenuWrapper from './NavMenuWrapper';
import OrganizationDetailWrapper from '../pages/wrapper/OrganizationDetailWrapper';
import { OrganizationExperienceDetail } from '../pages/organization/OrganizationExperienceDetail';
import OrganizationExperienceDetailWrapper from '../pages/wrapper/OrganizationExperienceDetailWrapper';
import { OrganizationExperienceList } from '../pages/organization/OrganizationExperienceList';
import PackageSettlementDetailExportWrapper from '../pages/wrapper/PackageSettlementDetailExportWrapper';
import PackageSettlementDetailWrapper from '../pages/wrapper/PackageSettlementDetailWrapper';
import { PackageSettlementList } from '../pages/case/packagesettlement/PackageSettlementList';
import PlaintiffsFirmStrategyDetailWrapper from '../pages/wrapper/PlaintiffsFirmStrategyDetailWrapper';
import ProjectDetailWrapper from '../pages/wrapper/ProductDetailWrapper';
import ProjectListWrapper from '../pages/wrapper/ProjectListWrapper';
import { Query2Wrapper } from '../pages/query2/Query2Wrapper';
import { QueryLegacyWrapper } from '../pages/query2/QueryLegacyWrapper';
import QueryWrapper from '../pages/wrapper/QueryWrapper';
import RecoverAccount from '../pages/account/RecoverAccount';
import RegisterWithInvitationWrapper from '../pages/wrapper/RegisterWithInvitationWrapper';
import RegistrationOptionWrapper from '../pages/wrapper/RegistrationOptionWrapper';
import { ReportListHome } from '../pages/report/ReportListHome';
import { ReportStatus } from './report/ReportStatus';
import { ResetPassword } from '../pages/account/ResetPassword';
import SnapshotWrapper from '../pages/wrapper/SnapshotWrapper';
import StrategicPlanWrapper from '../pages/wrapper/StrategicPlanWrapper';
import { ThreatReport } from '../pages/report/ThreatReport';
import { TutorialLayout } from './TutorialLayout';
import Tutorials from '../pages/Tutorials';
import UserDetailWrapper from '../pages/wrapper/UserDetailWrapper';
import { UserList } from '../pages/user/UserList';
import { UserReportList } from '../pages/report/UserReportList';
import ViewExportReportWrapper from '../pages/wrapper/ViewExportReportWrapper';
import { WeeklyRecap } from '../pages/report/timeline/WeeklyRecap';
import WorkProductDetailWrapper from '../pages/wrapper/WorkProductDetailWrapper';
import ZoneNotesWrapper from '../pages/wrapper/ZoneNotesWrapper';

const _apiClient = new CheckmateApiClient();

const SecureLayout = ({
    children,
    isAuthenticated,
}: {
    children: JSX.Element;
    isAuthenticated?: boolean;
}) => {
    if (!isAuthenticated) {
        return <Navigate to={{ pathname: LocalRoutes.Login }} />;
    }
    return children;
};

interface ILayoutState {
    user?: IUserModel;
    isAuthenticated: boolean;
    inProgress: boolean;
    initialLoad: boolean;
    showZoneChangeDetectedDialog: boolean;
    localSwitchZone: boolean;
}

interface ILayoutProps {
    isAuthenticated: boolean;
    alertHubConnection: HubConnection;
    locationKey: string;
}

export class AuthLayout extends React.Component<ILayoutProps, ILayoutState> {
    private reportStatusRef: React.RefObject<ReportStatus>;

    constructor(props: ILayoutProps) {
        super(props);

        this.state = {
            isAuthenticated: false,
            inProgress: true,
            initialLoad: true,
            showZoneChangeDetectedDialog: false,
            localSwitchZone: false,
        };

        this.reportStatusRef = React.createRef();

        props.alertHubConnection.on('SendReportNotification', (payload: IAlertSummaryModel[]) => {
            console.log('***** SendReportNotification ', payload);
            this.reportRefreshHook();
        });

        props.alertHubConnection.on('ZoneChanged', (payload: { locationKey: string }) => {
            console.info('***** ZoneChanged', payload);

            if (this.state.localSwitchZone) return;

            this.setState({ showZoneChangeDetectedDialog: true });
        });
    }

    reportSignalrState() {
        console.log(`SignalR - Alert Hub / State: ${this.props.alertHubConnection.state}`);
    }

    reportRefreshHook = () => {
        if (this.reportStatusRef.current) this.reportStatusRef.current.loadData();
    };

    async startConnection() {
        try {
            if (this.props.alertHubConnection.state === 'Disconnected') {
                await this.props.alertHubConnection.start();
                this.reportSignalrState();
            }
        } catch (err) {
            console.log(err);
            this.reportSignalrState();

            window.setTimeout(() => this.startConnection(), 10000);
        }
    }

    componentDidMount() {
        this.getUser();

        this.props.alertHubConnection.onclose(async () => {
            this.reportSignalrState();
            await this.startConnection();
        });

        this.startConnection();
    }

    componentWillUnmount(): void {
        this.props.alertHubConnection.stop();
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.isAuthenticated && !prevProps.isAuthenticated) {
            this.getUser();
        }
    }

    getUser = async () => {
        this.setState({ inProgress: true });
        const response = await _apiClient.getLoggedInUserInfo();
        if (response) {
            if (response.httpResponse.status === 401) {
                this.setState({
                    user: { active: false },
                    isAuthenticated: false,
                    inProgress: false,
                    initialLoad: false,
                });
                return;
            }

            if (response.payload) {
                const user = response.payload;
                if (user && user.organizationRoles) {
                    for (let i = 0; i < user.organizationRoles.length; i++) {
                        if (user.organizationRoles[i].default) {
                            const defaultOrganization = user.organizationRoles[i];
                            Auth.setUserDefaultOrganization(
                                user.organizationRoles[i].guid!,
                                defaultOrganization.shortName!
                            );
                        }
                    }
                }
                this.setState({
                    user: user,
                    isAuthenticated: true,
                    inProgress: false,
                    initialLoad: false,
                });
            }
        }
    };

    onSwitchZone = async (organizationGuid: string, organizationName: string) => {
        // Call API to Save the switch. Then redirect to home page.
        const response = await _apiClient.setDefaultUserOrganization(
            organizationGuid,
            organizationName,
            this.props.locationKey
        );

        if (response.httpResponse.status === 401) {
            window.location.reload();
            return;
        }
        if (!response.errorMessage) {
            window.location.reload();
        }

        this.redirectToHome();
    };

    isPlanningExecutionEnabled() {
        if (this.state.user && this.state.user.organizationRoles) {
            for (let i = 0; i < this.state.user.organizationRoles.length; i++) {
                if (this.state.user.organizationRoles[i].default) {
                    const defaultOrganization = this.state.user.organizationRoles[i];
                    return defaultOrganization.enablePlanningExecution;
                }
            }
        }
        return false;
    }

    onLogout = () => {
        this.setState({
            user: { active: false },
            isAuthenticated: false,
            inProgress: false,
        });
    };

    redirectToHome = () => {
        window.location.assign('/');
    };

    render() {
        const user = this.state.user;
        const defaultOrg = Auth.getUserDefaultOrganization();
        const defaultOrganizationName = defaultOrg ? defaultOrg.name! : '';

        const reportRefreshHookLocal = () => {
            this.reportRefreshHook();
        };

        return this.state.initialLoad ? (
            <div style={{ background: 'white' }} className="login-background">
                Loading...
            </div>
        ) : (
            <>
                <div className="container-fluid" style={{ background: 'white' }}>
                    <div className="row">
                        <div
                            className="col-sm-2 print-hide-display"
                            style={{ paddingLeft: '15px', paddingRight: '15px' }}
                        >
                            <div className="logo-container">
                                <img
                                    className="logo-img rounded"
                                    src="/images/CheckmateLogoFromBrian.svg"
                                    onClick={this.redirectToHome}
                                    alt="Checkmate Logo"
                                />
                            </div>
                        </div>
                        <div className="col-sm-10 nav-menu-item-container">
                            <div
                                className="dropdown-container print-hide-display"
                                style={{
                                    padding: '0',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {this.state.user && this.state.user.profile ? (
                                    <div
                                        style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '4vw',
                                            paddingLeft: '4vw',
                                        }}
                                    >
                                        {Authorization.isAuthorizedToRoute(
                                            LocalRoutes.Cases,
                                            user
                                        ) ||
                                        Authorization.isAuthorizedToRoute(
                                            LocalRoutes.CaseEasyUpdate,
                                            user
                                        ) ||
                                        Authorization.isAuthorizedToRoute(
                                            LocalRoutes.PackageSettlements,
                                            user
                                        ) ? (
                                            <div className="innerContainer">
                                                <button
                                                    className="btn-black bold"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    CASES
                                                </button>
                                                <div className="dropdown-menu adjust-left">
                                                    {this.state.isAuthenticated ? (
                                                        <div>
                                                            {Authorization.isAuthorizedToRoute(
                                                                LocalRoutes.Cases,
                                                                user
                                                            ) ? (
                                                                <NavLink
                                                                    className="dropdown-item"
                                                                    to={LocalRoutes.Cases}
                                                                >
                                                                    <div className="drop-down-icon-wrapper">
                                                                        <i className="fal fa-list" />
                                                                    </div>
                                                                    CASE LIST
                                                                </NavLink>
                                                            ) : null}
                                                            {Authorization.isAuthorizedToRoute(
                                                                LocalRoutes.CaseEasyUpdate,
                                                                user
                                                            ) ? (
                                                                <NavLink
                                                                    className="dropdown-item"
                                                                    to={LocalRoutes.CaseEasyUpdate.replace(
                                                                        ':guid',
                                                                        ''
                                                                    )}
                                                                >
                                                                    <div className="drop-down-icon-wrapper">
                                                                        <i className="fal fa-edit" />
                                                                    </div>
                                                                    EASY UPDATE
                                                                </NavLink>
                                                            ) : null}
                                                            {Authorization.isAuthorizedToRoute(
                                                                LocalRoutes.PackageSettlements,
                                                                user
                                                            ) ? (
                                                                <NavLink
                                                                    className="dropdown-item"
                                                                    to={
                                                                        LocalRoutes.PackageSettlements
                                                                    }
                                                                >
                                                                    <div className="drop-down-icon-wrapper">
                                                                        <i className="fal fa-box-usd" />
                                                                    </div>
                                                                    PACKAGE DEALS
                                                                </NavLink>
                                                            ) : null}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        ) : null}
                                        <DataMenuItem
                                            isAuthenticated={this.state.isAuthenticated}
                                            user={this.state.user}
                                            reportStatusRef={this.reportStatusRef}
                                        />
                                        {Authorization.showStrategyMenuItem(user) ? (
                                            <div className="innerContainer">
                                                <button
                                                    className="btn-black bold"
                                                    data-bs-toggle="dropdown"
                                                >
                                                    STRATEGY
                                                </button>
                                                <div className="dropdown-menu adjust-left">
                                                    {this.state.isAuthenticated ? (
                                                        <div>
                                                            {Authorization.isAuthorizedToRoute(
                                                                LocalRoutes.StrategicPlan,
                                                                user
                                                            ) ? (
                                                                <NavLink
                                                                    className="dropdown-item"
                                                                    to={LocalRoutes.StrategicPlan}
                                                                >
                                                                    <div className="drop-down-icon-wrapper">
                                                                        <i className="fal fa-flag" />
                                                                    </div>
                                                                    STRATEGIC PLAN
                                                                </NavLink>
                                                            ) : null}
                                                            {Authorization.isAuthorizedToRoute(
                                                                LocalRoutes.StrategyExecutionPlan,
                                                                user
                                                            ) ? (
                                                                <NavLink
                                                                    className="dropdown-item"
                                                                    to={
                                                                        LocalRoutes.StrategyExecutionPlan
                                                                    }
                                                                >
                                                                    <div className="drop-down-icon-wrapper">
                                                                        <i className="fal fa-users" />
                                                                    </div>
                                                                    EXECUTION PLAN
                                                                </NavLink>
                                                            ) : null}
                                                            {Authorization.isAuthorizedToStrategyType(
                                                                StrategyTypes.PlaintiffsFirm.Name,
                                                                user
                                                            ) ? (
                                                                <NavLink
                                                                    className="dropdown-item"
                                                                    to={LocalRoutes.Strategy.replace(
                                                                        ':type',
                                                                        StrategyTypes.PlaintiffsFirm.Value.toString()
                                                                    )}
                                                                >
                                                                    <div className="drop-down-icon-wrapper">
                                                                        <i className="fal fa-building" />
                                                                    </div>
                                                                    PLAINTIFFS FIRMS
                                                                </NavLink>
                                                            ) : null}
                                                            {Authorization.isAuthorizedToStrategyType(
                                                                StrategyTypes.Jurisdiction.Name,
                                                                user
                                                            ) ? (
                                                                <NavLink
                                                                    className="dropdown-item"
                                                                    to={LocalRoutes.Strategy.replace(
                                                                        ':type',
                                                                        StrategyTypes.Jurisdiction.Value.toString()
                                                                    )}
                                                                >
                                                                    <div className="drop-down-icon-wrapper">
                                                                        <i className="fal fa-gavel" />
                                                                    </div>
                                                                    JURISDICTIONS
                                                                </NavLink>
                                                            ) : null}
                                                            {Authorization.isAuthorizedToStrategyType(
                                                                StrategyTypes.Projects.Name,
                                                                user
                                                            ) ? (
                                                                <NavLink
                                                                    className="dropdown-item"
                                                                    to={LocalRoutes.Projects}
                                                                >
                                                                    <div className="drop-down-icon-wrapper">
                                                                        <i className="fal fa-chart-gantt" />
                                                                    </div>
                                                                    PROJECTS
                                                                </NavLink>
                                                            ) : null}
                                                            {Authorization.isAuthorizedToStrategyType(
                                                                StrategyTypes.LocalCounsel.Name,
                                                                user
                                                            ) ? (
                                                                <NavLink
                                                                    className="dropdown-item"
                                                                    to={LocalRoutes.Strategy.replace(
                                                                        ':type',
                                                                        StrategyTypes.LocalCounsel.Value.toString()
                                                                    )}
                                                                >
                                                                    <div className="drop-down-icon-wrapper">
                                                                        <i className="fal fa-map-marker-smile" />
                                                                    </div>
                                                                    LOCAL COUNSEL
                                                                </NavLink>
                                                            ) : null}
                                                            {Authorization.isAuthorizedToStrategyType(
                                                                StrategyTypes.Experts.Name,
                                                                user
                                                            ) && (
                                                                <NavLink
                                                                    className="dropdown-item"
                                                                    to={LocalRoutes.Experts}
                                                                >
                                                                    <div className="drop-down-icon-wrapper">
                                                                        <i className="fal fa-head-side-brain" />
                                                                    </div>
                                                                    EXPERTS
                                                                </NavLink>
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        ) : null}

                                        {this.state.isAuthenticated &&
                                        Authorization.showReviewMenuItem(user) ? (
                                            <div className="innerContainer">
                                                <button
                                                    className="btn-black bold"
                                                    data-bs-toggle="dropdown"
                                                >
                                                    REVIEW
                                                </button>
                                                <div className="dropdown-menu adjust-left">
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.Alerts,
                                                        user
                                                    ) ? (
                                                        <NavLink
                                                            className="dropdown-item"
                                                            to={LocalRoutes.Alerts.replace(
                                                                ':type',
                                                                AlertListTypeEnum.MyAlertList.toString()
                                                            )}
                                                        >
                                                            <div className="drop-down-icon-wrapper">
                                                                <i className="fal fa-bell-exclamation" />
                                                            </div>
                                                            MY ALERT LIST
                                                        </NavLink>
                                                    ) : null}
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.Alerts,
                                                        user
                                                    ) ? (
                                                        <NavLink
                                                            className="dropdown-item"
                                                            to={LocalRoutes.Alerts.replace(
                                                                ':type',
                                                                AlertListTypeEnum.ZoneAlertList.toString()
                                                            )}
                                                        >
                                                            <div className="drop-down-icon-wrapper">
                                                                <i className="fal fa-bells" />
                                                            </div>
                                                            ZONE ALERT LIST
                                                        </NavLink>
                                                    ) : null}
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.Trends,
                                                        user
                                                    ) ? (
                                                        <NavLink
                                                            className="dropdown-item"
                                                            to={LocalRoutes.Trends}
                                                        >
                                                            <div className="drop-down-icon-wrapper">
                                                                <i className="fal fa-chart-line-down" />
                                                            </div>
                                                            TRENDS
                                                        </NavLink>
                                                    ) : null}
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.Snapshot,
                                                        user
                                                    ) ? (
                                                        <NavLink
                                                            className="dropdown-item"
                                                            to={LocalRoutes.Snapshot}
                                                        >
                                                            <div className="drop-down-icon-wrapper">
                                                                <i className="fal fa-camera-retro" />
                                                            </div>
                                                            SNAPSHOT
                                                        </NavLink>
                                                    ) : null}
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.Board,
                                                        user
                                                    ) ? (
                                                        <NavLink
                                                            className="dropdown-item"
                                                            to={LocalRoutes.Board}
                                                        >
                                                            <div className="drop-down-icon-wrapper">
                                                                <i className="fal fa-game-board" />
                                                            </div>
                                                            BOARD
                                                        </NavLink>
                                                    ) : null}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className="float-end" style={{ margin: '39px' }}></div>
                                )}
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '7vw',
                                    }}
                                >
                                    {Authorization.AllowOrganizationSwitch(user) ? (
                                        <div className="zoneContainer">
                                            <button
                                                id="btn-zone"
                                                className=" btn-zone-select font-green"
                                                data-bs-toggle="dropdown"
                                            >
                                                <span style={{ whiteSpace: 'nowrap' }}>
                                                    ZONE{' '}
                                                    <span className="horizontal-margin-lg font-green">
                                                        {' '}
                                                        {defaultOrganizationName}
                                                    </span>
                                                </span>
                                            </button>
                                            <div className="dropdown-menu no-margin-top">
                                                {this.state.isAuthenticated ? (
                                                    <div>
                                                        {this.state.user?.organizationRoles?.map(
                                                            (org: IOrganizationRoleModel) => {
                                                                return (
                                                                    <a
                                                                        key={org.guid}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                localSwitchZone:
                                                                                    true,
                                                                            });
                                                                            this.onSwitchZone(
                                                                                org.guid!,
                                                                                org.name!
                                                                            );
                                                                        }}
                                                                        className="dropdown-item"
                                                                        style={{
                                                                            textAlign: 'center',
                                                                        }}
                                                                    >
                                                                        {org.shortName} (
                                                                        {org.displayName})
                                                                    </a>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : null}

                                    <div
                                        className="innerContainer"
                                        style={{ paddingRight: '2.5vw' }}
                                    >
                                        <button className="btn-black" data-bs-toggle="dropdown">
                                            <span style={{ whiteSpace: 'nowrap' }}>
                                                <i className="fal fa-user" />
                                                &nbsp; Welcome, {this.state.user?.profile?.lastName}
                                                , {this.state.user?.profile?.firstName}
                                            </span>
                                        </button>
                                        <div className="dropdown-menu adjust-right">
                                            {this.state.isAuthenticated ? (
                                                <div>
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.Zones,
                                                        user
                                                    ) ? (
                                                        <NavLink
                                                            className="dropdown-item"
                                                            to={LocalRoutes.Zones}
                                                        >
                                                            <div className="drop-down-icon-wrapper">
                                                                <i className="fal fa-building" />
                                                            </div>
                                                            ZONES
                                                        </NavLink>
                                                    ) : null}
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.AssertionDescriptors,
                                                        user
                                                    ) ? (
                                                        <NavLink
                                                            className="dropdown-item"
                                                            to={LocalRoutes.AssertionDescriptors}
                                                        >
                                                            <div className="drop-down-icon-wrapper">
                                                                <i className="fal fa-list" />
                                                            </div>
                                                            ASSERTION DESCRIPTORS
                                                        </NavLink>
                                                    ) : null}
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.Users,
                                                        user
                                                    ) ? (
                                                        <NavLink
                                                            className="dropdown-item"
                                                            to={LocalRoutes.Users}
                                                        >
                                                            <div className="drop-down-icon-wrapper">
                                                                <i className="fal fa-users" />
                                                            </div>
                                                            USERS
                                                        </NavLink>
                                                    ) : null}
                                                    {this.isPlanningExecutionEnabled() &&
                                                    Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.UserZoneProfile,
                                                        user
                                                    ) ? (
                                                        <NavLink
                                                            className="dropdown-item"
                                                            to={LocalRoutes.UserZoneProfile}
                                                        >
                                                            <div className="drop-down-icon-wrapper">
                                                                <i className="fal fa-user-plus" />
                                                            </div>
                                                            USER ZONE PROFILE
                                                        </NavLink>
                                                    ) : null}
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.CreateInvitation,
                                                        user
                                                    ) ? (
                                                        <NavLink
                                                            className="dropdown-item"
                                                            to={LocalRoutes.CreateInvitation}
                                                        >
                                                            <div className="drop-down-icon-wrapper">
                                                                <i className="fal fa-user-plus" />
                                                            </div>
                                                            USER INVITATION
                                                        </NavLink>
                                                    ) : null}
                                                    {this.isPlanningExecutionEnabled() &&
                                                    Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.UserTasks,
                                                        user
                                                    ) ? (
                                                        <NavLink
                                                            className="dropdown-item"
                                                            to={LocalRoutes.UserTasks}
                                                        >
                                                            <div className="drop-down-icon-wrapper">
                                                                <i className="fal fa-tasks" />
                                                            </div>
                                                            MY TASK LIST
                                                        </NavLink>
                                                    ) : null}
                                                    {this.isPlanningExecutionEnabled() &&
                                                    Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.TaskBarSetting,
                                                        user
                                                    ) ? (
                                                        <NavLink
                                                            className="dropdown-item"
                                                            to={LocalRoutes.TaskBarSetting}
                                                        >
                                                            <div className="drop-down-icon-wrapper">
                                                                <i className="fal fa-cog" />
                                                            </div>
                                                            TASK BAR SETTINGS
                                                        </NavLink>
                                                    ) : null}
                                                    <NavLink
                                                        className="dropdown-item"
                                                        to={LocalRoutes.Tutorials}
                                                    >
                                                        <div className="drop-down-icon-wrapper">
                                                            <i className="fal fa-graduation-cap" />
                                                        </div>
                                                        TUTORIALS
                                                    </NavLink>
                                                    <NavLink
                                                        className="dropdown-item"
                                                        to={LocalRoutes.Logout}
                                                    >
                                                        <div className="drop-down-icon-wrapper">
                                                            <i className="fal fa-sign-out-alt" />
                                                        </div>
                                                        LOGOUT
                                                    </NavLink>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-2" style={{ padding: '0px' }}>
                            <NavMenuWrapper
                                user={this.state.user}
                                isAuthenticated={this.state.isAuthenticated}
                                alertHubConnection={this.props.alertHubConnection}
                                setIsLocalSwitchZone={() =>
                                    this.setState({ localSwitchZone: true })
                                }
                            />
                        </div>

                        <CaseFileProvider>
                            <div className="col-sm-10 main-display" style={{ paddingLeft: '15px' }}>
                                {this.state.inProgress ? null : (
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <Home />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.Login}
                                            element={
                                                this.state.isAuthenticated ? <Home /> : <Login />
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.LoginAdvanced}
                                            element={<Login />}
                                        />
                                        <Route
                                            path={LocalRoutes.Logout}
                                            element={<Logout onLogout={this.onLogout} />}
                                        />
                                        <Route
                                            path={LocalRoutes.AcceptInvitation}
                                            element={<RegistrationOptionWrapper />}
                                        />
                                        <Route
                                            path={LocalRoutes.RegisterWithInvitation}
                                            element={<RegisterWithInvitationWrapper />}
                                        />
                                        <Route
                                            path={LocalRoutes.ForgotPassword}
                                            element={<RecoverAccount />}
                                        />
                                        <Route
                                            path={LocalRoutes.ResetPassword}
                                            element={<ResetPassword />}
                                        />
                                        <Route
                                            path={LocalRoutes.AccessDenied}
                                            element={<AccessDenied />}
                                        />

                                        <Route
                                            path={LocalRoutes.CreateInvitation}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <Invitation
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        <Route
                                            path={LocalRoutes.Zones}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <OrganizationExperienceList
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.ZoneDetail}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <OrganizationExperienceDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.ZoneNew}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <OrganizationExperienceDetail
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.OrganizationDetail}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <OrganizationDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        <Route
                                            path={LocalRoutes.Users}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <UserList
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.UserDetail}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <UserDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.UserReportList}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <UserReportList
                                                        reportRefreshHook={reportRefreshHookLocal}
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        {/*  
                                <SecureLayout path={LocalRoutes.UserTasks} component={UserTaskList} isAuthenticated={this.state.isAuthenticated} />                                
                                <SecureLayout path={LocalRoutes.UserZoneProfile} component={UserZoneProfile} isAuthenticated={this.state.isAuthenticated} />
                                <SecureLayout path={LocalRoutes.TaskBarSetting} component={UserTaskBarSetting} isAuthenticated={this.state.isAuthenticated}
                                    userOrganizations={this.state.user && this.state.user.organizationRoles ? this.state.user.organizationRoles : []} /> */}

                                        <Route
                                            path={LocalRoutes.AssertionDescriptors}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <AssertionDescriptorList
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.AssertionDescriptorNew}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <AssertionDescriptorDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.AssertionDescriptorDetail}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <AssertionDescriptorDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        <Route
                                            path={LocalRoutes.PackageSettlementDetailExport}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <PackageSettlementDetailExportWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        <Route
                                            path={LocalRoutes.Cases}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <CaseListWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        <Route
                                            path={LocalRoutes.CaseFile}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <CaseFile
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.CaseFileNew}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <CaseFile
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.CaseEasyUpdate}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <CaseEasyUpdateWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        {/*                                 
                                            <Route path={LocalRoutes.CasePlan} element={<SecureLayout isAuthenticated={this.state.isAuthenticated}><CasePlan user={this.state.user || { active: false }} /></SecureLayout>}/>
                                            <Route path={LocalRoutes.CasePlanTaskDetail} element={<SecureLayout isAuthenticated={this.state.isAuthenticated}><CasePlanTaskDetail user={this.state.user || { active: false }} /></SecureLayout>}/> 
                                        */}
                                        <Route
                                            path={LocalRoutes.WorkProductDetail}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <WorkProductDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        <Route
                                            path={LocalRoutes.ReportTypeList}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <ReportListHome
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.ThreatReport}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <ThreatReport
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.ReportDetail}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <ViewExportReportWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        <Route
                                            path={LocalRoutes.Query}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <QueryWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        <Route
                                            path={LocalRoutes.QueryLegacy}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <QueryLegacyWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.Query2}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <Query2Wrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.ZoneNotes}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <ZoneNotesWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.WeeklyRecap}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <WeeklyRecap
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.MonthlyRecap}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <MonthlyRecap
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.MeetingAgenda}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <MeetingAgendaReportWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.Trends}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <KpiViewWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.KpiReport}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <KpiReport
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        <Route
                                            path={LocalRoutes.Board}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <Board
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.BoardCaseDetail}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <BoardCaseDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.BoardViewDetail}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <BoardViewDetail
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        <Route
                                            path={LocalRoutes.PackageSettlements}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <PackageSettlementList
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.PackageSettlementDetails}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <PackageSettlementDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.PackageSettlementNew}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <PackageSettlementDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        <Route
                                            path={LocalRoutes.Experts}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <ExpertsList
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.ExpertCreate}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <ExpertCreate
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.ExpertDetail}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <ExpertDetail
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.StrategicPlan}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <StrategicPlanWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.StrategyExecutionPlan}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <ExecutionPlanWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.Strategy}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <EntityStrategyWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.PlaintiffFirmStrategyDetail}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <PlaintiffsFirmStrategyDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.JurisdictionStrategyDetail}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <JurisdictionStrategyDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.LocalCounselStrategyDetail}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <LocalCounselStrategyDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        <Route
                                            path={LocalRoutes.PlaintiffFirmStrategyNew}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <PlaintiffsFirmStrategyDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.JurisdictionStrategyNew}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <JurisdictionStrategyDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.LocalCounselStrategyNew}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <LocalCounselStrategyDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        <Route
                                            path={LocalRoutes.Projects}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <ProjectListWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.ProjectDetail}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <ProjectDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.ProjectNew}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <ProjectDetailWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />

                                        <Route
                                            path={LocalRoutes.Snapshot}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <SnapshotWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.Alerts}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <AlertListWrapper
                                                        user={this.state.user || { active: false }}
                                                    />
                                                </SecureLayout>
                                            }
                                        />
                                        <Route
                                            path={LocalRoutes.ViewPDF}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <div
                                                        style={{
                                                            position: 'fixed',
                                                            top: 0,
                                                            right: 0,
                                                            bottom: 0,
                                                            left: 0,
                                                        }}
                                                    >
                                                        <TutorialLayout
                                                            isAuthenticated={
                                                                this.state.isAuthenticated
                                                            }
                                                            inProgress={false}
                                                            initialLoad={false}
                                                        />
                                                    </div>
                                                </SecureLayout>
                                            }
                                        >
                                            <Route
                                                path=":id"
                                                element={
                                                    <SecureLayout
                                                        isAuthenticated={this.state.isAuthenticated}
                                                    >
                                                        <CheckmatePDFViewer />
                                                    </SecureLayout>
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path={LocalRoutes.Tutorials}
                                            element={
                                                <SecureLayout
                                                    isAuthenticated={this.state.isAuthenticated}
                                                >
                                                    <Tutorials />
                                                </SecureLayout>
                                            }
                                        />
                                    </Routes>
                                )}
                            </div>
                        </CaseFileProvider>
                    </div>
                </div>
                <CheckmateDialog
                    isShowingModal={this.state.showZoneChangeDetectedDialog}
                    title={'Current Active Zone Change Detected'}
                    body={
                        'Your current active zone has been changed on a different tab and this page will automatically reload to reflect the same.'
                    }
                    handleClose={() => {
                        this.redirectToHome();
                    }}
                    cancelText={'OK'}
                    dialogClassName="registration-options-dialog"
                    closeButtonClassName="btn btn-black float-end"
                />
            </>
        );
    }
}

interface IMenuItemNavLinkWithIcon {
    to: string;
    iconClass: string;
    label: string;
}

function MenuItemNavLinkWithIcon(props: IMenuItemNavLinkWithIcon) {
    return (
        <NavLink className="dropdown-item" to={props.to}>
            <div className="drop-down-icon-wrapper">
                <i className={`${props.iconClass}`} />
            </div>
            {props.label}
        </NavLink>
    );
}

interface IDataMenuItemProps {
    isAuthenticated: boolean;
    reportStatusRef: React.RefObject<ReportStatus>;
    user: IUserModel;
}

function DataMenuItem({ isAuthenticated, reportStatusRef, user }: IDataMenuItemProps) {
    const show =
        Authorization.isAuthorizedToRoute(LocalRoutes.ReportDetail, user) ||
        Authorization.isAuthorizedToRoute(LocalRoutes.Query, user) ||
        Authorization.isAuthorizedToRoute(LocalRoutes.ZoneNotes, user);

    return (
        <>
            {show && (
                <div className="innerContainer">
                    <button className="btn-black bold" data-bs-toggle="dropdown">
                        DATA
                    </button>
                    <div className="dropdown-menu adjust-left">
                        {isAuthenticated ? (
                            <div>
                                {Authorization.isAuthorizedToRoute(
                                    LocalRoutes.ReportDetail,
                                    user
                                ) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-file-chart-column"
                                        label="REPORTS"
                                        to={LocalRoutes.ReportTypeList}
                                    />
                                )}
                                {/*                                 {Authorization.isAuthorizedToRoute(LocalRoutes.Query, user) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-database"
                                        label="QUERY LEGACY"
                                        to={LocalRoutes.QueryLegacy}
                                    />
                                )} */}
                                {Authorization.isAuthorizedToRoute(LocalRoutes.Query, user) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-database"
                                        label="QUERY"
                                        to={LocalRoutes.Query2}
                                    />
                                )}
                                {Authorization.isAuthorizedToRoute(LocalRoutes.ZoneNotes, user) && (
                                    <MenuItemNavLinkWithIcon
                                        iconClass="fal fa-memo"
                                        label="ZONE NOTES"
                                        to={LocalRoutes.ZoneNotes}
                                    />
                                )}
                            </div>
                        ) : null}
                    </div>
                    <ReportStatus ref={reportStatusRef} user={user ? user : { active: false }} />
                </div>
            )}
        </>
    );
}
