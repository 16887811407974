import * as React from 'react';
import { IValidation } from '../../interfaces/IError';
import ValidateUtils from '../../shared/validations';
import { IUserModel, IOrganizationRoleModel } from '../../interfaces/IUser';
import { IDataScopeLookupModel, ILookupModel } from '../../interfaces/ILookup';
import { IOrganizationModel } from '../../interfaces/IOrganization';
import { IAddressModel } from '../../interfaces/IAddress';
import { IDialogModel } from '../../interfaces/IDialog';
import { IPhoneModel } from '../../interfaces/IPhone';
import ApiClient from '../../services/apiClient';

import { LocalRoutes } from '../../utilities/LocalRoutes';
import Authorization from '../../stores/Authorization';
import { ApplicationHelpType, OrganizationTypesEnum } from '../../utilities/Constants';
import { Navigate } from 'react-router-dom';
import { Help } from '../../components/shared/Help';
import { CheckmateDialog } from '../../components/shared/dialog';
import { OrganizationRole } from '../../components/organization/OrganizationRoles';
import { Loader } from '../../components/shared/Loader';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import CheckmateSelect from '../../components/shared/CheckmateSelect';

let _apiClient = new ApiClient();

interface IUserDetailProps {
    guid?: string;
    user: IUserModel;
}

interface IUserDetailState {
    pendingResponse: boolean;
    user: IUserModel;
    disabledUser: boolean;
    readOnly: boolean;
    redirect: boolean | false;
    displayAddressDetail: boolean | false;
    displayPhoneDetail: boolean | false;
    openOrganizationRolesModal: boolean | false;
    newUserOrganization: boolean;
    validation: IValidation;
    addresses: IAddressModel[];
    phones: IPhoneModel[];
    selectedAddress: IAddressModel;
    editAddressIndex: number;
    selectedPhone: IPhoneModel;
    editPhoneIndex: number;
    selectedUserOrganization: IOrganizationRoleModel;
    dialog: IDialogModel;
    userOrganizationToDelete?: IOrganizationRoleModel;
    phoneIndexToDelete?: number;
    dialogToOpen?: string;
    organizationList: IOrganizationModel[];
    dataScopes: IDataScopeLookupModel[];
    showUserEnableDisableDialog?: boolean;
}

export class UserDetail extends React.Component<IUserDetailProps, IUserDetailState> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: true,
            user: {
                guid: '',
                profile: undefined,
                active: true,
            },
            disabledUser: false,
            readOnly: false,
            validation: {},
            redirect: false,
            displayAddressDetail: false,
            displayPhoneDetail: false,
            openOrganizationRolesModal: false,
            newUserOrganization: false,
            addresses: [],
            phones: [],
            selectedAddress: {},
            selectedPhone: {},
            selectedUserOrganization: {},
            editAddressIndex: -1,
            editPhoneIndex: -1,
            dialog: { isShowingModal: false },
            organizationList: [],
            dataScopes: [],
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.UserDetail, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);
        this.loadOrganizations(this.props.guid);
    }

    errorMessage = (msg: string) => {
        let validation = this.state.validation;
        validation.errorMessage = [msg];
        this.setState({ validation: validation });
    };

    loadOrganizations = async (guid?: string) => {
        var res = await _apiClient.getOrganizations();
        if (res.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (res.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (res.payload) {
            if (guid) this.setState({ organizationList: res.payload }, () => this.loadUser(guid));
            else this.setState({ organizationList: res.payload, pendingResponse: false });
        } else {
            this.errorMessage(DisplayMessages.UnexpectedError);
        }
    };

    loadUser = async (guid: string) => {
        try {
            var res = await _apiClient.getUserDetails(guid);
            if (res.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (res.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                    pendingResponse: false,
                });
                return;
            }
            let usr = res.payload!;
            if (res.payload) {
                this.setState({
                    user: usr,
                    disabledUser: !usr.active,
                    addresses: usr.profile ? usr.profile.addresses || [] : [],
                    phones: usr.profile ? usr.profile.phones || [] : [],
                    pendingResponse: false,
                });
            } else {
                this.setState({ pendingResponse: false }, () =>
                    this.errorMessage('User not found')
                );
            }
            return true;
        } catch (error) {
            this.errorMessage('Error: ' + error);
            return false;
        }
    };

    handleCheckmateSelectChange = (selectedItem: any, name: string) => {
        let user = this.state.user;
        let validation = this.state.validation;
        switch (name) {
            case 'homeOrganization':
                const value = selectedItem && selectedItem.guid ? selectedItem.guid : '';
                this.state.organizationList.map((organization: IOrganizationModel, i: number) => {
                    if (organization.guid == value) {
                        if (user.homeOrganization) {
                            user.homeOrganization.guid = organization.guid;
                            user.homeOrganization.name = organization.name;
                        } else {
                            user.homeOrganization = {
                                guid: organization.guid,
                                name: organization.name,
                            };
                        }
                    }
                });
                validation.homeOrganization = [''];
                break;
            default:
                break;
        }
        this.setState({
            user: user,
            validation: validation,
        });
    };

    changeValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setChangedValue(name, value);
    };

    setChangedValue = (name: string, value: any) => {
        let user = this.state.user;
        let validation = this.state.validation;

        if (user.profile) {
            switch (name) {
                case 'designation':
                    user.profile.designation = value;
                    validation['profile.designation'] = [''];
                    break;
                case 'firstName':
                    user.profile.firstName = value;
                    validation['profile.firstname'] = [''];
                    break;
                case 'lastName':
                    user.profile.lastName = value;
                    validation['profile.lastname'] = [''];
                    break;
                default:
                    break;
            }
        }
        this.setState({
            user: user,
            validation: validation,
        });
    };

    saveDetail = async () => {
        let user: IUserModel = this.state.user;

        // custom validation to check for minimum one portfolio
        if (user.organizationRoles && user.organizationRoles.length == 0) {
            let validation = this.state.validation;
            validation['user.organizations'] = ['At least one Zone is required'];
            this.setState({ validation: validation });
            return;
        }

        if (user.profile) {
            user.profile.addresses = this.state.addresses;
            user.profile.phones = this.state.phones;
        }

        if (user.guid) {
            var response = await _apiClient.updateUserDetails(user);
            if (response.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (response.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                });
            } else {
                this.setState({ redirect: true });
            }
        } /* else { // only create new users from invitations
            var response = await _apiClient.createUserDetails(user)
            if (response.errorMessage) {
                this.setState({ validation: ValidateUtils.parseErrors(response.errors) });
            } else {
                this.setState({ redirect: true });
            }
        } */
    };

    cancelDetail = () => {
        this.setState({ redirect: true });
    };

    addAddress = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();

        this.setState({
            displayAddressDetail: true,
            selectedAddress: {},
            editAddressIndex: -1,
        });
    };

    cancelAddress = () => {
        this.setState({
            displayAddressDetail: false,
        });
    };

    saveAddress = (address: IAddressModel) => {
        let addresses = this.state.addresses;

        if (this.state.editAddressIndex < 0) {
            let newAddress: IAddressModel = {
                addressType: address.addressType,
                addressLine1: address.addressLine1,
                addressLine2: address.addressLine2,
                city: address.city,
                state: address.state,
                zipcode: address.zipcode,
            };
            addresses.push(newAddress);
        } else {
            addresses[this.state.editAddressIndex].addressLine1 = address.addressLine1;
            addresses[this.state.editAddressIndex].addressLine2 = address.addressLine2;
            addresses[this.state.editAddressIndex].addressType = address.addressType;
            addresses[this.state.editAddressIndex].city = address.city;
            addresses[this.state.editAddressIndex].state = address.state;
            addresses[this.state.editAddressIndex].zipcode = address.zipcode;
        }

        this.setState({
            addresses: addresses,
            displayAddressDetail: false,
        });
    };

    editAddress = (
        event: React.FormEvent<HTMLButtonElement>,
        address: IAddressModel,
        index: number
    ) => {
        event.preventDefault();
        this.setState({
            displayAddressDetail: true,
            selectedAddress: address,
            editAddressIndex: index,
        });
    };

    deleteAddress = (index: number) => {
        let addresses = this.state.addresses;
        addresses.splice(index, 1);
        this.setState({
            addresses: addresses,
        });
    };

    addPhone = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();

        this.setState({
            displayPhoneDetail: true,
            selectedPhone: {},
            editPhoneIndex: -1,
        });
    };

    cancelPhone = () => {
        this.setState({
            displayPhoneDetail: false,
        });
    };

    savePhone = (phone: IPhoneModel) => {
        let phones = this.state.phones;

        if (this.state.editPhoneIndex < 0) {
            let newPhone: IPhoneModel = {
                phoneType: phone.phoneType,
                phoneNumber: phone.phoneNumber,
                extension: phone.extension,
                primary: phone.primary,
            };
            phones.push(newPhone);
        } else {
            phones[this.state.editPhoneIndex].phoneType = phone.phoneType;
            phones[this.state.editPhoneIndex].phoneNumber = phone.phoneNumber;
            phones[this.state.editPhoneIndex].extension = phone.extension;
            phones[this.state.editPhoneIndex].primary = phone.primary;
        }

        this.setState({
            phones: phones,
            displayPhoneDetail: false,
        });
    };

    editPhone = (event: React.FormEvent<HTMLButtonElement>, phone: IPhoneModel, index: number) => {
        event.preventDefault();

        this.setState({
            displayPhoneDetail: true,
            selectedPhone: phone,
            editPhoneIndex: index,
        });
    };

    deletePhone = (index: number) => {
        let phones = this.state.phones;
        phones.splice(index, 1);

        this.setState({
            phones: phones,
        });
    };

    openModalToAddUserOrganization = (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        let validation = this.state.validation;
        validation['user.organizations'] = [''];

        this.setState({
            openOrganizationRolesModal: true,
            newUserOrganization: true,
            selectedUserOrganization: {},
            validation: validation,
        });
    };

    openModalToEditUserOrganization = (
        event: React.FormEvent<HTMLButtonElement>,
        userorganization: IOrganizationRoleModel
    ) => {
        event.preventDefault();
        let validation = this.state.validation;
        validation['user.organizations'] = [''];

        this.setState({
            openOrganizationRolesModal: true,
            newUserOrganization: false,
            selectedUserOrganization: userorganization,
            validation: validation,
        });
    };

    saveUserOrganization = (organizationRole: IOrganizationRoleModel) => {
        let currentUser = this.state.user;
        let currentUserOrganizations = currentUser.organizationRoles;
        let validation = this.state.validation;

        if (currentUser && currentUserOrganizations) {
            if (this.state.newUserOrganization) {
                currentUserOrganizations!.push(organizationRole);
                validation['user.organizations'] = [''];
            } else {
                for (var i = 0; i < currentUserOrganizations!.length; i++) {
                    if (currentUserOrganizations![i].guid == organizationRole) {
                        currentUserOrganizations![i] = organizationRole;
                        break;
                    }
                }
            }

            currentUser.organizationRoles = currentUserOrganizations;

            this.setState({
                user: currentUser,
                openOrganizationRolesModal: false,
                newUserOrganization: false,
                selectedUserOrganization: {},
                validation: validation,
            });
        }
    };

    deleteUserOrganization = (userorganization: IOrganizationRoleModel) => {
        if (this.state.user && this.state.user.organizationRoles) {
            let user = this.state.user;
            let userOrganizations = user.organizationRoles!;

            for (var i = 0; i < userOrganizations.length; i++) {
                if (userOrganizations[i].guid == userorganization.guid) {
                    userOrganizations.splice(i, 1);
                    break;
                }
            }

            user.organizationRoles = userOrganizations;

            this.setState({
                user: user,
            });
        }
    };

    cancelUserOrganization = () => {
        this.setState({
            openOrganizationRolesModal: false,
            newUserOrganization: false,
            selectedUserOrganization: {},
        });
    };

    ShowDialog = (
        e: React.FormEvent<HTMLButtonElement>,
        dialogOption: string,
        userOrganization?: IOrganizationRoleModel,
        deleteIndex?: number
    ) => {
        e.preventDefault();
        let validation = this.state.validation;
        validation['user.organizations'] = [''];

        if (dialogOption == 'organization') {
            this.setState({
                dialog: {
                    isShowingModal: true,
                    body: 'Are you sure you want to delete this organization?',
                    confirmText: 'Yes',
                    cancelText: 'No',
                    dialogClassName: 'user-role-delete',
                },
                userOrganizationToDelete: userOrganization,
                dialogToOpen: 'organization',
                validation: validation,
            });
            return;
        }
        this.setState({
            dialog: {
                isShowingModal: true,
                title: 'Delete Phone Number',
                body: 'Are you sure you want to delete this phone number?',
                confirmText: 'Yes',
                cancelText: 'No',
            },
            phoneIndexToDelete: deleteIndex,
            dialogToOpen: 'phone',
        });
    };

    handleConfirmDialog = () => {
        if (this.state.dialogToOpen == 'organization') {
            this.deleteUserOrganization(this.state.userOrganizationToDelete!);
        } else {
            this.deletePhone(this.state.phoneIndexToDelete!);
        }
        this.handleCloseDialog();
    };

    handleCloseDialog = () => {
        this.setState({ dialog: { isShowingModal: false } });
    };

    handleUserDisableChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        let user = this.state.user;
        user.active = !event.target.checked;
        this.setState({ user: user, showUserEnableDisableDialog: event.target.checked });
    };

    disableUser = () => {
        let user = this.state.user;
        user.active = false;
        this.setState({ user: user, showUserEnableDisableDialog: false });
    };

    undoDisableUser = () => {
        let user = this.state.user;
        user.active = true;
        this.setState({ user: user, showUserEnableDisableDialog: false });
    };

    render() {
        if (this.state.redirect) return <Navigate to={LocalRoutes.Users} />;
        if (this.state.pendingResponse) return <Loader />;

        return (
            <>
                <div className="row mb-3">
                    <h1 className="col-sm-6">
                        {this.state.user.profile
                            ? this.state.user.profile.firstName +
                              ' ' +
                              this.state.user.profile.lastName +
                              (this.state.disabledUser ? ' (Disabled)' : '')
                            : 'New User'}
                    </h1>
                    <div className="col-sm-6">
                        {!this.state.disabledUser ? (
                            <span className="float-end">
                                {!this.state.disabledUser ? (
                                    <input
                                        type="button"
                                        className="btn btn-default float-end"
                                        onClick={this.cancelDetail}
                                        value="Cancel"
                                    />
                                ) : null}
                                {!this.state.disabledUser ? (
                                    <input
                                        type="button"
                                        className="btn btn-orange float-end"
                                        onClick={this.saveDetail}
                                        value="Save"
                                    />
                                ) : null}
                                <input
                                    name="chkDisableUser"
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={!this.state.user.active}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.handleUserDisableChecked(e);
                                    }}
                                />
                                <span className="font-size-sm horizontal-padding-only-med">
                                    Disable User
                                </span>
                            </span>
                        ) : null}
                    </div>
                </div>

                <div className="row mb-2">
                    <input type="hidden" name="id" value={this.state.user.guid || ''} />
                    <span className="text-danger">{this.state.validation.model}</span>
                </div>
                <div className="row mb-2">
                    <label className="control-label col-sm-2" htmlFor="firstName">
                        First Name*{' '}
                    </label>
                    <input
                        type="text"
                        name="firstName"
                        readOnly={this.state.disabledUser}
                        className="form-control"
                        value={this.state.user.profile ? this.state.user.profile.firstName : ''}
                        onChange={this.changeValue}
                    />
                    <span className="text-danger">
                        {this.state.validation['profile.firstname']}
                    </span>
                </div>
                <div className="row mb-2">
                    <label className="control-label col-sm-2" htmlFor="lastName">
                        Last Name*{' '}
                    </label>
                    <input
                        type="text"
                        name="lastName"
                        readOnly={this.state.disabledUser}
                        className="form-control"
                        value={this.state.user.profile ? this.state.user.profile.lastName : ''}
                        onChange={this.changeValue}
                    />
                    <span className="text-danger">{this.state.validation['profile.lastname']}</span>
                </div>
                <div className="row mb-2">
                    <label className="control-label col-sm-2" htmlFor="email">
                        Email*{' '}
                    </label>
                    <input
                        type="text"
                        name="email"
                        readOnly={true}
                        className="form-control"
                        value={this.state.user.profile ? this.state.user.profile.email : ''}
                        onChange={this.changeValue}
                    />
                    <span className="text-danger">{this.state.validation.email}</span>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-2">
                        <label className="control-label" htmlFor="homeOrganization">
                            Organization{' '}
                        </label>
                        <Help
                            type={ApplicationHelpType.Info}
                            title="Organization"
                            helpText="Remove all Zone Access in order to change the User's organization"
                        />
                    </div>
                    <div className="ps-0 col-sm-4">
                        <CheckmateSelect
                            options={CheckmateSelectHelper.getOrgOptions(
                                this.state.organizationList.filter(
                                    (x) =>
                                        x.organizationType &&
                                        x.organizationType.id !==
                                            OrganizationTypesEnum.Portfolio.Value
                                )
                            )}
                            isDisabled={
                                !this.state.user.active ||
                                (this.state.user.organizationRoles &&
                                    this.state.user.organizationRoles.length > 0)
                            }
                            name="homeOrganization"
                            value={CheckmateSelectHelper.getSelectedOrgValue(
                                this.state.organizationList,
                                this.state.user.homeOrganization
                                    ? this.state.user.homeOrganization.guid || ''
                                    : ''
                            )}
                            onChange={(e: any) => {
                                this.handleCheckmateSelectChange(e, 'homeOrganization');
                            }}
                        />
                    </div>
                    <div className="text-danger col-sm-6">{this.state.validation.organization}</div>
                </div>
                {this.state.user.applicationRoles &&
                this.state.user.applicationRoles.length != 0 ? (
                    <div className="row mb-2">
                        <label className="control-label col-sm-2" htmlFor="appicationRoles">
                            Application Roles
                        </label>
                        <ul>
                            {this.state.user.applicationRoles.map(
                                (applicationRole: ILookupModel) => {
                                    return <li>{applicationRole.displayName}</li>;
                                }
                            )}
                        </ul>
                    </div>
                ) : null}
                {this.state.user.homeOrganization ? (
                    <div className="row mb-2">
                        <div className="col-sm-2">
                            <label className="control-label" htmlFor="zones">
                                Zone Access
                            </label>
                            {this.state.user.active ? (
                                <button
                                    className="btn btn-default btn-icon ms-2"
                                    onClick={this.openModalToAddUserOrganization}
                                >
                                    <i className="fal fa-lg fa-plus" />
                                </button>
                            ) : null}
                        </div>
                        <div>
                            {this.state.user &&
                            this.state.user.organizationRoles &&
                            this.state.user.organizationRoles.length > 0 ? (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Default</th>
                                            <th>Name</th>
                                            <th>Role</th>
                                            <th>Data Scope</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.user.organizationRoles.map(
                                            (userorganization: IOrganizationRoleModel) => {
                                                return (
                                                    <tr key={userorganization.guid}>
                                                        <td>
                                                            {userorganization.default ? (
                                                                <div className="square-blue" />
                                                            ) : null}
                                                        </td>
                                                        <td>{userorganization.displayName}</td>
                                                        <td>
                                                            {userorganization.role
                                                                ? userorganization.role.displayName
                                                                : ''}
                                                        </td>
                                                        <td>
                                                            {userorganization.dataScope
                                                                ? userorganization.dataScope
                                                                      .displayName
                                                                : ''}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn btn-no-bg float-end"
                                                                onClick={(
                                                                    e: React.MouseEvent<HTMLButtonElement>
                                                                ) => {
                                                                    this.ShowDialog(
                                                                        e,
                                                                        'organization',
                                                                        userorganization,
                                                                        undefined
                                                                    );
                                                                }}
                                                            >
                                                                <i className="fal fa-lg fa-trash-alt"></i>
                                                            </button>
                                                            <button
                                                                className="btn btn-no-bg float-end"
                                                                onClick={(
                                                                    e: React.MouseEvent<HTMLButtonElement>
                                                                ) => {
                                                                    this.openModalToEditUserOrganization(
                                                                        e,
                                                                        userorganization
                                                                    );
                                                                }}
                                                            >
                                                                <i className="fal fa-lg fa-edit" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            ) : null}
                        </div>
                    </div>
                ) : null}

                {/*
                    <div className="row mb-2">
                        <label className="control-label col-sm-2" htmlFor="addresses">Address</label><br />
                        <button className="btn btn-default btn-icon" onClick={this.addAddress}><i className="fal fa-lg fa-plus" /></button>
                        <AddressList addressList={this.state.addresses} editAddress={this.editAddress} deleteAddress={this.deleteAddress} />
                    </div>
                    <div className="row mb-2">
                        <label className="control-label col-sm-2" htmlFor="phones">Phone</label><br />
                        <button className="btn btn-default btn-icon" onClick={this.addPhone}><i className="fal fa-lg fa-plus" /></button>
                        <div>
                            {this.state.phones && this.state.phones.length > 0
                                ?
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Phone Type</th>
                                            <th>Phone Number</th>
                                            <th>Phone Extension</th>
                                            <th>Primary</th>
                                            <th className="col-sm-2" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.phones.map((phone: IPhoneModel, i: number) => {
                                            return (
                                                <tr key={phone.guid}>
                                                    <td>{phone.phoneType ? phone.phoneType.name : ""}</td>
                                                    <td>{phone.phoneNumber}</td>
                                                    <td>{phone.extension}</td>
                                                    <td>{phone.primary ? "Yes" : "No"}</td>
                                                    <td>
                                                        <button className="btn btn-no-bg float-end" onClick={(e: React.MouseEvent<HTMLButtonElement>) => { this.ShowDialog(e, 'phone', undefined, i); }}><i className="fal fa-lg fa-trash-alt"></i></button>
                                                        <button className="btn btn-no-bg float-end" onClick={(e: React.MouseEvent<HTMLButtonElement>) => { this.editPhone(e, phone, i); }}><i className="fal fa-lg fa-edit" /></button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                : null
                            }
                        </div>
                    </div>
                <Modal centered show={this.state.displayAddressDetail} size="lg" onHide={this.cancelAddress} backdrop={false}>
                    <Modal.Body>
                        <Address cancelAddress={this.cancelAddress} saveAddress={this.saveAddress} address={this.state.selectedAddress} />
                    </Modal.Body>
                </Modal>
         
                <Modal centered show={this.state.displayPhoneDetail} size="lg" onHide={this.cancelAddress} backdrop={false}>
                    <Modal.Body>
                        <Phone cancelPhone={this.cancelPhone} savePhone={this.savePhone} phone={this.state.selectedPhone} />
                    </Modal.Body>
                </Modal>
                */}

                <OrganizationRole
                    open={this.state.openOrganizationRolesModal}
                    zones={this.state.organizationList.filter(
                        (x) =>
                            x.organizationType &&
                            x.organizationType.id === OrganizationTypesEnum.Portfolio.Value &&
                            this.state.user.homeOrganization &&
                            x.dependentOrganizations &&
                            x.dependentOrganizations.filter(
                                (x) => x.guid === this.state.user.homeOrganization!.guid
                            ).length > 0
                    )}
                    currentOrganizationRole={this.state.selectedUserOrganization}
                    handleSave={this.saveUserOrganization}
                    handleCancel={this.cancelUserOrganization}
                    homeOrganization={this.state.user.homeOrganization || {}}
                />

                <CheckmateDialog
                    isShowingModal={this.state.dialog.isShowingModal}
                    title={this.state.dialog.title}
                    body={this.state.dialog.body}
                    handleClose={this.handleCloseDialog}
                    handleConfirm={this.handleConfirmDialog}
                    confirmText={this.state.dialog.confirmText}
                    cancelText={this.state.dialog.cancelText}
                    dialogClassName={this.state.dialog.dialogClassName}
                    confirmButtonClassName="btn btn-black float-end"
                    closeButtonClassName="btn btn-default float-end"
                />
                <CheckmateDialog
                    isShowingModal={this.state.showUserEnableDisableDialog || false}
                    body={
                        'Are you sure you want to disable this user? This operation is permanent and cannot be reverted back.'
                    }
                    handleClose={this.undoDisableUser}
                    handleConfirm={this.disableUser}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </>
        );
    }
}
