import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { IValidation } from '../../interfaces/IError';
import { IReportParametersModel, IReportTypeModel } from '../../interfaces/Report/IReport';
import { Loader } from './Loader';

interface IExportFileProps {
    url: string;
    reportType: IReportTypeModel;
    reportParameters?: IReportParametersModel;
    handleError?: () => void;
    text?: string;
    icon?: string;
    extractReportParametersJSONString?: () => string;
}

interface IExportFileState {
    showSettingsModal?: boolean;
    pendingResponse: boolean;
    validation: IValidation;
    includeNotes: boolean;
    includeStrategyNotes: boolean;
    includeTasks: boolean;
    includeAttachments: boolean;
    includeHistoricData: boolean;
    commentText?: string;
}

export class ExportFile extends React.Component<IExportFileProps, IExportFileState> {
    constructor(props: any) {
        super(props);
        this.state = {
            pendingResponse: false,
            validation: {},
            includeNotes: false,
            includeStrategyNotes: false,
            includeTasks: false,
            includeHistoricData: false,
            includeAttachments: false,
        };
    }

    generateAndDownloadReport = async () => {
        this.setState({ pendingResponse: true });

        var xhr = new XMLHttpRequest();
        xhr.open('POST', this.props.url, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                var fileName = '';
                var blob = xhr.response;

                var contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    let contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (var i = 0; i < contentDispositionItems.length; i++) {
                            let currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                var n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {

                if (typeof this.props.handleError === 'function') {
                    this.props.handleError();
                }
            }
            this.setState({
                pendingResponse: false,
                showSettingsModal: false,
                includeTasks: false,
                includeNotes: false,
                includeAttachments: false,
                includeStrategyNotes: false,
                includeHistoricData: false,
                commentText: undefined,
            });
        };

        let reportParamsString = '';
        if (this.props.extractReportParametersJSONString) {
            reportParamsString = this.props.extractReportParametersJSONString();
        } else {
            let reportParams: IReportParametersModel = this.props.reportParameters || {
                reportType: { id: this.props.reportType.id },
            };

            if (this.props.reportType.availableReportOptions) {
                if (!reportParams.options) reportParams.options = {};
                reportParams.options.includeTasks = this.state.includeTasks;
                reportParams.options.includeNotes = this.state.includeNotes;
                reportParams.options.includeHistoricData = this.state.includeHistoricData;
                reportParams.options.includeAttachments = this.state.includeAttachments;
                reportParams.options.includeStrategyNotes = this.state.includeStrategyNotes;
                reportParams.commentText = this.state.commentText;
            }
            reportParamsString = JSON.stringify(reportParams);
        }

        xhr.send(reportParamsString);
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;
        return (
            <span>
                {this.props.reportType.availableReportOptions ? (
                    <button
                        className="btn btn-no-bg float-end text-gray"
                        onClick={() => this.setState({ showSettingsModal: true })}
                    >
                        <i
                            className={`fal ${
                                this.props.icon ? this.props.icon : 'fa-file-chart-column'
                            }`}
                        />
                        &nbsp;{this.props.text ? this.props.text : 'Report'}
                    </button>
                ) : (
                    <button
                        className="btn btn-no-bg float-end text-gray"
                        onClick={this.generateAndDownloadReport}
                    >
                        <i
                            className={`fal ${
                                this.props.icon ? this.props.icon : 'fa-file-chart-column'
                            }`}
                        />
                        &nbsp;{this.props.text ? this.props.text : 'Report'}
                    </button>
                )}
                {this.props.reportType.availableReportOptions && this.state.showSettingsModal ? (
                    <Modal
                        centered
                        show={true}
                        onHide={() => {
                            this.setState({ showSettingsModal: false });
                        }}
                        backdrop={false}
                        dialogClassName="board-settings-dialog"
                    >
                        <Modal.Header>
                            <Modal.Title> Report Parameters & Settings </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                            <div className="container-fluid">
                                <div className="col-sm-12">
                                    {this.props.reportType.availableReportOptions.includeNotes ? (
                                        <div className="margin-top-sm">
                                            <input
                                                name="chkIncludeNotes"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={this.state.includeNotes}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.setState({
                                                        includeNotes: e.target.checked,
                                                    });
                                                }}
                                            />
                                            <span>&nbsp;&nbsp;Include Significant Changes</span>
                                        </div>
                                    ) : null}
                                    {this.props.reportType.availableReportOptions.includeTasks ? (
                                        <div className="margin-top-sm">
                                            <input
                                                name="chkIncludeTasks"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={this.state.includeTasks}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.setState({
                                                        includeTasks: e.target.checked,
                                                    });
                                                }}
                                            />
                                            <span>&nbsp;&nbsp;Include Tactics</span>
                                        </div>
                                    ) : null}


                                    {this.props.reportType.availableReportOptions.includeAttachments ? (
                                        <div className="margin-top-sm">
                                            <input
                                                name="chkIncludeAttachments"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={this.state.includeAttachments}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.setState({
                                                        includeAttachments: e.target.checked,
                                                    });
                                                }}
                                            />
                                            <span>&nbsp;&nbsp;Include Attachments</span>
                                        </div>
                                    ) : null}

                                    
                                    {this.props.reportType.availableReportOptions.includeStrategyNotes ? (
                                        <div className="margin-top-sm">
                                            <input
                                                name="chkIncludeStrategyNotes"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={this.state.includeStrategyNotes}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.setState({
                                                        includeStrategyNotes: e.target.checked,
                                                    });
                                                }}
                                            />
                                            <span>&nbsp;&nbsp;Include Strategy Notes</span>
                                        </div>
                                    ) : null}



                                    {this.props.reportType.availableReportOptions
                                        .includeHistoricData ? (
                                        <div className="margin-top-sm">
                                            <input
                                                name="chkIncludeHistoricData"
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={this.state.includeHistoricData}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.setState({
                                                        includeHistoricData: e.target.checked,
                                                    });
                                                }}
                                            />
                                            <span>&nbsp;&nbsp;Include Historic Settlement Averages</span>
                                        </div>
                                    ) : null}
                                    {this.props.reportType.availableReportOptions
                                        .includeComments ? (
                                        <div className="margin-top-sm">
                                            <label className="control-label">Comments</label>
                                            <span className="text-danger">
                                                &nbsp;{this.state.validation.commentText}
                                            </span>
                                            <textarea
                                                className="form-control"
                                                value={this.state.commentText}
                                                name="commentText"
                                                rows={10}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLTextAreaElement>
                                                ) => {
                                                    this.setState({ commentText: e.target.value });
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <hr />
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() => this.setState({ 
                                        showSettingsModal: false, 
                                        includeNotes:false, 
                                        includeStrategyNotes: false,
                                        includeTasks: false, 
                                        includeHistoricData: false, 
                                        includeAttachments: false,
                                        commentText: undefined })}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={this.generateAndDownloadReport}
                                >
                                    Go
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}
            </span>
        );
    }
}
