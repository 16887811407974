import * as React from 'react';
import { Link } from 'react-router-dom';
import { Help } from '../../components/shared/Help';
import { Loader } from '../../components/shared/Loader';
import { ICaseStatusLookupModel, ICaseModel } from '../../interfaces/ICase';
import { IValidation } from '../../interfaces/IError';
import { IMultiSelectOptions, ILookupModel } from '../../interfaces/ILookup';
import { IUserModel } from '../../interfaces/IUser';
import {
    IMeetingDetailsModel,
    IReportDeadlineManualModel,
} from '../../interfaces/Report/ILocalCounselAgendaReportMeetingLogistics';
import CheckmateApiClient from '../../services/apiClient';
import ValidateUtils from '../../shared/validations';
import Authorization from '../../stores/Authorization';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import {
    ReportTypesEnum,
    CaseFilterType,
    SortColumns,
    ApplicationHelpType,
} from '../../utilities/Constants';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { BasicCaseSearch } from '../case/BasicCaseSearch';

import CheckmateSelect from '../../components/shared/CheckmateSelect';
import { config } from '../../config/config';
import Common from '../../stores/Common';

let _apiClient = new CheckmateApiClient();

interface IMeetingAgendaReportProps {
    user: IUserModel;
    guid?: string;
}

interface IAgendaReportState {
    pendingResponse: boolean;
    validation: IValidation;
    meetingDetails: IMeetingDetailsModel;
    entityName: string;
    showCaseSearch?: boolean;
    currentDeadlineIndex: number;
    caseStatusLookup: ICaseStatusLookupModel[];
    caseStatuses: IMultiSelectOptions[];
    USStates: IMultiSelectOptions[];
    jurisdictions: IMultiSelectOptions[];
    selectedStatusOptions: IMultiSelectOptions[];
    selectedUSStatesOptions: IMultiSelectOptions[];
    selectedJurisdictionOptions: IMultiSelectOptions[];
}

export class MeetingAgendaReport extends React.Component<
    IMeetingAgendaReportProps,
    IAgendaReportState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: false,
            validation: {},
            meetingDetails: {
                reportTypeId: ReportTypesEnum.LocalCounselMeetingAgendaReport.Value,
                entityGuid: this.props.guid,
                participants: [],
                deadlines: [],
                caseSearchDetails: {},
            },
            entityName: '',
            currentDeadlineIndex: -1,
            caseStatusLookup: [],
            caseStatuses: [],
            USStates: [],
            jurisdictions: [],
            selectedStatusOptions: [],
            selectedJurisdictionOptions: [],
            selectedUSStatesOptions: [],
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.ReportDetail, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        if (this.props.guid) {
            this.loadLocalCounselDetails(this.props.guid);
        } else {
            window.location.assign('/');
        }
    }

    loadLocalCounselDetails = async (guid: string) => {
        this.setState({
            pendingResponse: true,
        });

        var orgResponse = await _apiClient.getOrganizationById(guid);
        if (orgResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (orgResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(orgResponse.errors, orgResponse.errorMessage),
            });
            return;
        }

        let caseStatuses: ICaseStatusLookupModel[] = [];
        let caseStatusOptions: IMultiSelectOptions[] = [];
        let jurisdictionsOptions: IMultiSelectOptions[] = [];
        let USStatesOptions: IMultiSelectOptions[] = [];

        var caseStatusesResponse = await _apiClient.getCaseStatusLookupData();
        if (caseStatusesResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (caseStatusesResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(orgResponse.errors, orgResponse.errorMessage),
            });
            return;
        }
        if (caseStatusesResponse.payload) {
            caseStatuses = caseStatusesResponse.payload!;
            caseStatuses.map((status: ICaseStatusLookupModel) => {
                let item: IMultiSelectOptions = {
                    label: status.name!,
                    value: status.name!,
                    id: status.id,
                }; // Use id to hold the actual Status.Id unlike the other filters
                caseStatusOptions.push(item);
            });
        }

        var jurisdictionResponse = await _apiClient.getJurisdictions();
        if (jurisdictionResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (jurisdictionResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    jurisdictionResponse.errors,
                    jurisdictionResponse.errorMessage
                ),
            });
            return;
        }
        if (jurisdictionResponse.payload) {
            let jurisdictions = jurisdictionResponse.payload;
            jurisdictions.map((jurisdiction: ILookupModel, index: number) => {
                let item: IMultiSelectOptions = {
                    label: jurisdiction.displayName!,
                    value: jurisdiction.name!,
                    id: CaseFilterType.Jurisdiction.Value,
                };
                jurisdictionsOptions.push(item);
            });
            this.setState({ jurisdictions: jurisdictionsOptions });
        }

        var usStatesResponse = await _apiClient.getUSStates();
        if (usStatesResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (usStatesResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    usStatesResponse.errors,
                    usStatesResponse.errorMessage
                ),
            });
            return;
        }
        if (usStatesResponse.payload) {
            let USStates = usStatesResponse.payload;
            USStates.map((jurisdiction: ILookupModel, index: number) => {
                let item: IMultiSelectOptions = {
                    label: jurisdiction.displayName!,
                    value: jurisdiction.name!,
                    id: CaseFilterType.Jurisdiction.Value,
                };
                USStatesOptions.push(item);
            });
        }

        this.setState({
            pendingResponse: false,
            meetingDetails: {
                reportTypeId: ReportTypesEnum.LocalCounselMeetingAgendaReport.Value,
                entityGuid: this.props.guid,
                participants: [],
                deadlines: [],
                caseSearchDetails: {},
            },
            entityName: orgResponse.payload!.name!,
            caseStatusLookup: caseStatuses,
            caseStatuses: caseStatusOptions,
            jurisdictions: jurisdictionsOptions,
            USStates: USStatesOptions,
        });
    };

    getSortByOptions = () => {
        let options: IMultiSelectOptions[] = [];
        options.push({
            label: 'Priority Cases First',
            value: SortColumns.Priority.toString(),
            id: SortColumns.Priority,
        });
        options.push({
            label: 'Trial Date',
            value: SortColumns.TrialDate.toString(),
            id: SortColumns.TrialDate,
        });
        return options;
    };

    getSortByValue = () => {
        switch (this.state.meetingDetails.caseSearchDetails.sortBy) {
            case SortColumns.Priority:
                return {
                    label: 'Priority Cases First',
                    value: SortColumns.Priority.toString(),
                    id: SortColumns.Priority,
                };
            case SortColumns.TrialDate:
                return {
                    label: 'Trial Date',
                    value: SortColumns.TrialDate.toString(),
                    id: SortColumns.TrialDate,
                };
            default:
                break;
        }
    };

    handleCheckmateSelectChange = (selectedItem: any, name: string) => {
        let meetingDetails = this.state.meetingDetails;
        let validation = this.state.validation;
        switch (name) {
            case 'sortBy':
                meetingDetails.caseSearchDetails.sortBy = selectedItem
                    ? selectedItem.id
                    : undefined;
                break;
            default:
                break;
        }
        this.setState({ meetingDetails: meetingDetails, validation: validation });
    };

    changeValue = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>,
        index?: number
    ) => {
        let meetingDetails = this.state.meetingDetails;
        let validation = this.state.validation;

        switch (event.target.name) {
            case 'datetime':
                meetingDetails.dateTime = event.target.value;
                validation.datetime = [event.target.value ? '' : 'Date & Time are Required'];
                break;
            case 'organizer':
                meetingDetails.organizer = event.target.value;
                validation.organizer = [event.target.value ? '' : 'Organizer is Required'];
                break;
            case 'dialInInfo':
                meetingDetails.dialInInfo = event.target.value;
                validation.dialInInfo = [
                    event.target.value ? '' : 'Dial In Information is Required',
                ];
                break;
            case 'participant':
                meetingDetails.participants[index!] = event.target.value;
                validation.participants = [''];
                break;
            case 'deadlineTask':
                meetingDetails.deadlines[index!].deadline = event.target.value;
                validation.deadlines = [''];
                break;
            case 'deadlineDate':
                meetingDetails.deadlines[index!].date = event.target.value;
                validation.deadlines = [''];
                break;
            default:
        }

        this.setState({ meetingDetails: meetingDetails, validation: validation });
    };

    generateAndDownloadReport = async () => {
        let hasErrors = false;
        let validation: IValidation = {};

        if (!this.state.meetingDetails.dateTime) {
            hasErrors = true;
            validation.dateTime = ['Date & Time are Required'];
        }

        if (!this.state.meetingDetails.organizer) {
            hasErrors = true;
            validation.organizer = ['Organizer is Required'];
        }

        if (!this.state.meetingDetails.dialInInfo) {
            hasErrors = true;
            validation.dialInInfo = ['Dial In Information is Required'];
        }

        if (this.state.meetingDetails.participants.filter((x) => !x).length > 0) {
            hasErrors = true;
            validation.participants = ['Missing Participant entry'];
        } else if (this.state.meetingDetails.participants.length == 0) {
            hasErrors = true;
            validation.participants = ['Enter at least one Participant'];
        }

        if (
            this.state.selectedJurisdictionOptions.length === 0 &&
            this.state.selectedStatusOptions.length === 0 &&
            this.state.selectedUSStatesOptions.length === 0
        ) {
            hasErrors = true;
            validation.caseFilter = ['Select at least one Case Filter'];
        }

        if (hasErrors) {
            this.setState({ validation: validation });
            return;
        }

        this.setState({ pendingResponse: true, validation: {} });

        var xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + ApiRoutes.MeetingAgendaReport, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this.setState({ pendingResponse: false });
                var fileName = 'Report.pdf';
                var blob = xhr.response;

                var contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    let contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (var i = 0; i < contentDispositionItems.length; i++) {
                            let currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                var n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                let validation = this.state.validation;
                validation.model = [DisplayMessages.ReportGenerateError];
                this.setState({ pendingResponse: false, validation: validation });
            }
        };

        xhr.send(JSON.stringify(this.state.meetingDetails));
    };

    addNewParticipantItem = () => {
        if (this.state.meetingDetails.participants.filter((x) => !x).length > 0) return;
        let meetingDetails = this.state.meetingDetails;
        meetingDetails.participants.push('');
        this.setState({ meetingDetails: meetingDetails });
    };

    deleteParticipantItem = (index: number) => {
        let meetingDetails = this.state.meetingDetails;
        meetingDetails.participants.splice(index, 1);
        this.setState({ meetingDetails: meetingDetails });
    };

    addDeadlineItem = () => {
        let meetingDetails = this.state.meetingDetails;
        meetingDetails.deadlines.push({});
        this.setState({ meetingDetails: meetingDetails });
    };

    deleteDeadlineItem = (index: number) => {
        let meetingDetails = this.state.meetingDetails;
        meetingDetails.deadlines.splice(index, 1);
        this.setState({ meetingDetails: meetingDetails });
    };

    handleCaseSelectedForDeadline = (caseItem: ICaseModel) => {
        let meetingDetails = this.state.meetingDetails;
        if (
            caseItem.caseName &&
            caseItem.guid &&
            meetingDetails.deadlines.length > this.state.currentDeadlineIndex
        ) {
            meetingDetails.deadlines[this.state.currentDeadlineIndex].caseGuid = caseItem.guid!;
            meetingDetails.deadlines[this.state.currentDeadlineIndex].caseName = caseItem.caseName!;
        }

        this.setState({ meetingDetails: meetingDetails, showCaseSearch: false });
    };

    selectLabel = (e: any, label: string) => {
        if (!e.value || e.value.length === 0) {
            return <span className="unselectClass css-1v99tuv">--{label}--</span>;
        }

        if (e.value.length === 1) {
            return <span className="css-1v99tuv">{e.value[0].label}</span>;
        } else {
            return <span className="css-1v99tuv">{e.value.length} selected</span>;
        }
    };

    statusOptionsSelected = (optionsList: any) => {
        let meetingDetails = this.state.meetingDetails;
        let caseSearchParameters = meetingDetails.caseSearchDetails;
        caseSearchParameters.statusIds = [];
        caseSearchParameters.subStatusIds = [];
        if (optionsList && optionsList.length > 0) {
            for (var i = 0; i < optionsList.length; i++) {
                const match = this.state.caseStatusLookup.filter(
                    (x) => x.name == optionsList![i].value
                );
                if (match.length > 0) {
                    caseSearchParameters.statusIds.push(match[0].status.id);
                    if (match[0].subStatus)
                        caseSearchParameters.subStatusIds.push(match[0].subStatus.id);
                }
            }
        }
        this.setState({ selectedStatusOptions: optionsList, meetingDetails: meetingDetails });
    };

    jurisdictionOptionsSelected = (optionsList: any) => {
        let meetingDetails = this.state.meetingDetails;
        let caseSearchParameters = meetingDetails.caseSearchDetails;
        if (caseSearchParameters.filters) {
            caseSearchParameters.filters = caseSearchParameters.filters!.filter(
                (x) => x.typeId != CaseFilterType.Jurisdiction.Value
            );
        } else {
            caseSearchParameters.filters = [];
        }
        if (optionsList && optionsList.length > 0) {
            for (var i = 0; i < optionsList.length; i++) {
                const match = this.state.jurisdictions.filter(
                    (x) => x.value == optionsList![i].value
                );
                if (match.length > 0) {
                    caseSearchParameters.filters.push({
                        typeId: CaseFilterType.Jurisdiction.Value,
                        text: match[0].value,
                    });
                }
            }
        }
        this.setState({ selectedJurisdictionOptions: optionsList, meetingDetails: meetingDetails });
    };

    USStatesOptionsSelected = (optionsList: any) => {
        let meetingDetails = this.state.meetingDetails;
        let caseSearchParameters = meetingDetails.caseSearchDetails;
        if (caseSearchParameters.filters) {
            caseSearchParameters.filters = caseSearchParameters.filters!.filter(
                (x) => x.typeId != CaseFilterType.State.Value
            );
        } else {
            caseSearchParameters.filters = [];
        }
        if (optionsList && optionsList.length > 0) {
            for (var i = 0; i < optionsList.length; i++) {
                const match = this.state.USStates.filter((x) => x.value == optionsList![i].value);
                if (match.length > 0) {
                    caseSearchParameters.filters.push({
                        typeId: CaseFilterType.State.Value,
                        text: match[0].value,
                    });
                }
            }
        }
        this.setState({ selectedUSStatesOptions: optionsList, meetingDetails: meetingDetails });
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;
        return (
            <div>
                <h1>
                    {this.state.entityName} Meeting Agenda
                    <button
                        className="btn btn-orange float-end text-gray horizontal-margin"
                        onClick={this.generateAndDownloadReport}
                    >
                        Run Report
                    </button>
                    <button
                        className="btn btn-default float-end"
                        onClick={() =>
                            this.setState({
                                validation: {},
                                meetingDetails: {
                                    reportTypeId:
                                        ReportTypesEnum.LocalCounselMeetingAgendaReport.Value,
                                    entityGuid: this.props.guid,
                                    participants: [],
                                    deadlines: [],
                                    caseSearchDetails: {},
                                },
                            })
                        }
                    >
                        Clear
                    </button>
                </h1>
                <div className="vertical-margin-xl">
                    <span className="text-danger">{this.state.validation.model}</span>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-3">
                        <label className="text-gray">Date & Time*</label>
                    </div>
                    <div className="col-sm-8 horizontal-padding">
                        <div className="form-input-container">
                            <input
                                className={
                                    'form-control d-inline-block' +
                                    (this.state.meetingDetails.dateTime &&
                                    Common.isValidDate(this.state.meetingDetails.dateTime)
                                        ? ''
                                        : ' unselectClass')
                                }
                                type="datetime-local"
                                name="datetime"
                                value={this.state.meetingDetails.dateTime || ''}
                                onChange={this.changeValue}
                            />{' '}
                            Central Time
                            <span className="text-danger margin-left">
                                {this.state.validation.dateTime}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-3">
                        <label className="text-gray">Organizer*</label>
                    </div>
                    <div className="col-sm-8 horizontal-padding">
                        <div className="form-input-container">
                            <input
                                className="form-control"
                                style={{ display: 'inline-block' }}
                                type="text"
                                name="organizer"
                                value={this.state.meetingDetails.organizer || ''}
                                onChange={this.changeValue}
                            />
                            <span className="text-danger margin-left">
                                {this.state.validation.organizer}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-3">
                        <label className="text-gray">Dial In Information*</label>
                    </div>
                    <div className="col-sm-8 horizontal-padding">
                        <div className="form-input-container">
                            <input
                                className="form-control"
                                style={{ display: 'inline-block' }}
                                type="text"
                                name="dialInInfo"
                                value={this.state.meetingDetails.dialInInfo || ''}
                                onChange={this.changeValue}
                            />
                            <span className="text-danger margin-left">
                                {this.state.validation.dialInInfo}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-3 margin-bottom">
                        <label className="text-gray">Participants*</label>&nbsp;
                        <button
                            className="btn btn-default btn-icon"
                            onClick={this.addNewParticipantItem}
                        >
                            <i className="fal fa-lg fa-plus" />
                        </button>
                    </div>
                    <div className="col-sm-8 horizontal-padding">
                        <div className="form-input-container">
                            <table className="border-0 full-width">
                                <tbody>
                                    {this.state.meetingDetails.participants.map(
                                        (item: string, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="col-sm-4 align-top pb-2">
                                                        <input
                                                            className="form-control"
                                                            style={{ display: 'inline-block' }}
                                                            type="text"
                                                            name="participant"
                                                            value={item}
                                                            onChange={(e) =>
                                                                this.changeValue(e, index)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <button
                                                            className="btn btn-no-bg"
                                                            onClick={(
                                                                e: React.MouseEvent<HTMLButtonElement>
                                                            ) => {
                                                                this.deleteParticipantItem(index);
                                                            }}
                                                        >
                                                            <i className="fal fa-lg fa-trash-alt"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                            <span className="text-danger">
                                {this.state.validation.participants}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-3">
                        <label className="text-gray">Case Filters</label>
                    </div>
                    <div className="col-sm-8 horizontal-padding">
                        <div className="row">
                            <div className="col-sm-4">
                                <CheckmateSelect
                                    isMulti={true}
                                    options={this.state.caseStatuses}
                                    value={this.state.selectedStatusOptions}
                                    onChange={this.statusOptionsSelected}
                                    placeholder="By Case Status"
                                />
                            </div>
                            <div className="col-sm-4">
                                <CheckmateSelect
                                    isMulti={true}
                                    options={this.state.jurisdictions}
                                    value={this.state.selectedJurisdictionOptions}
                                    onChange={this.jurisdictionOptionsSelected}
                                    placeholder="By Jurisdiction"
                                />
                            </div>
                            <div className="col-sm-4">
                                <CheckmateSelect
                                    isMulti={true}
                                    options={this.state.USStates}
                                    value={this.state.selectedUSStatesOptions}
                                    onChange={this.USStatesOptionsSelected}
                                    placeholder="By State"
                                />
                            </div>
                            <span className="text-danger margin-left">
                                {this.state.validation.caseFilter}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-3">
                        <label className="text-gray">Case Data Sort Option</label>
                    </div>
                    <div className="col-sm-3 horizontal-padding">
                        <CheckmateSelect
                            options={this.getSortByOptions()}
                            value={this.getSortByValue()}
                            name="sortBy"
                            onChange={(e: any) => {
                                this.handleCheckmateSelectChange(e, 'sortBy');
                            }}
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-sm-3">
                        <label className="text-gray">Case Management Deadlines</label>
                        <Help
                            type={ApplicationHelpType.Info}
                            title="Case Management Deadlines"
                            helpText="Use this section to manually add case activity that is not auto-populated in other sections of the Local Counsel Agenda using data in the Case Files; auto-populated fields that should NOT be manually entered here include:\n\n\n* Hot Cases\n* Outstanding Settlement Authority\n* Pending Corporate Rep Depo Requests\n* Upcoming Trial Dockets\n* Upcoming Depositions"
                        />
                        &nbsp;
                        <button className="btn btn-default btn-icon" onClick={this.addDeadlineItem}>
                            <i className="fal fa-lg fa-plus" />
                        </button>
                    </div>
                    <div className="col-sm-8 horizontal-padding">
                        <table className="border-0 full-width">
                            <thead>
                                <th>Case</th>
                                <th>Task</th>
                                <th>Due Date</th>
                            </thead>
                            <tbody>
                                {this.state.meetingDetails.deadlines.map(
                                    (item: IReportDeadlineManualModel, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    {item.caseName && item.caseGuid ? (
                                                        <Link
                                                            target="_blank"
                                                            to={LocalRoutes.CaseFile.replace(
                                                                ':guid',
                                                                item.caseGuid!
                                                            )}
                                                        >
                                                            {item.caseName}
                                                        </Link>
                                                    ) : (
                                                        <button
                                                            className="btn btn-default btn-icon"
                                                            onClick={() =>
                                                                this.setState({
                                                                    currentDeadlineIndex: index,
                                                                    showCaseSearch: true,
                                                                })
                                                            }
                                                        >
                                                            <i className="fal fa-lg fa-plus" />
                                                        </button>
                                                    )}
                                                </td>
                                                <td
                                                    className="col-sm-5 align-top no-padding"
                                                    style={{
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="deadlineTask"
                                                        value={item.deadline}
                                                        onChange={(e) => this.changeValue(e, index)}
                                                    />
                                                </td>
                                                <td
                                                    className="col-sm-2 align-top no-padding"
                                                    style={{
                                                        paddingTop: '5px',
                                                        paddingBottom: '5px',
                                                    }}
                                                >
                                                    <input
                                                        className={
                                                            'form-control' +
                                                            (item.date &&
                                                            Common.isValidDate(item.date)
                                                                ? ''
                                                                : ' unselectClass')
                                                        }
                                                        type="date"
                                                        name="deadlineDate"
                                                        value={item.date || ''}
                                                        onChange={(e) => this.changeValue(e, index)}
                                                    />
                                                </td>
                                                <td>
                                                    <button
                                                        className="btn btn-no-bg"
                                                        onClick={(
                                                            e: React.MouseEvent<HTMLButtonElement>
                                                        ) => {
                                                            this.deleteDeadlineItem(index);
                                                        }}
                                                    >
                                                        <i className="fal fa-lg fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <BasicCaseSearch
                    showCaseSearch={this.state.showCaseSearch || false}
                    handleCaseSelected={this.handleCaseSelectedForDeadline}
                />
            </div>
        );
    }
}
