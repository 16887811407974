import { Modal, ModalDialog, ModalDialogProps } from 'react-bootstrap';

import Draggable from 'react-draggable';

interface IDialogProps {
    body?: string;
    cancelText?: string;
    closeButtonClassName?: string;
    confirmButtonClassName?: string;
    confirmText?: string;
    dialogClassName?: string;
    draggable?: boolean;
    dragHandleClassName?: string;
    handleClose: () => void;
    handleConfirm?: () => void;
    isShowingModal: boolean;
    resizable?: boolean;
    size?: 'sm' | 'lg' | 'xl' | undefined;
    title?: string;
}

export function DraggableModalDialog(props: ModalDialogProps) {
    return (
        <Draggable handle=".modal-header">
            <ModalDialog {...props} />
        </Draggable>
    );
}

export function DraggableHandle(props: { children: JSX.Element; enabled?: boolean }) {
    if (!props.enabled) return <>{props.children}</>;

    return (
        <div
            className="drag-handle"
            title="Click to Drag"
            style={{
                cursor: 'grab',
            }}
        >
            {props.children}
        </div>
    );
}

export function CheckmateDialog(props: IDialogProps) {
    return (
        <Modal
            dialogAs={props.draggable ? DraggableModalDialog : undefined}
            centered
            show={props.isShowingModal}
            dialogClassName={props.dialogClassName}
            size={props.size}
            onHide={props.handleClose}
            backdrop={false}
        >
            {props.title ? (
                <DraggableHandle enabled={props.draggable}>
                    <Modal.Header>
                        <Modal.Title>{props.title}</Modal.Title>
                        {props.draggable && <i className="fa-regular fa-grip-dots-vertical" />}
                    </Modal.Header>
                </DraggableHandle>
            ) : null}
            <Modal.Body>
                {props.body
                    ? props.body.split('\n').map((text: string, i: number) => {
                          return (
                              <p key={i}>
                                  {text}
                                  <br />
                              </p>
                          );
                      })
                    : null}
                <div className="dialog-btn-div">
                    {props.handleClose ? (
                        <button className={props.closeButtonClassName} onClick={props.handleClose}>
                            {props.cancelText ? props.cancelText : 'Cancel'}
                        </button>
                    ) : null}
                    {props.handleConfirm ? (
                        <button
                            className={props.confirmButtonClassName}
                            onClick={props.handleConfirm}
                        >
                            {props.confirmText ? props.confirmText : 'OK'}
                        </button>
                    ) : null}
                </div>
            </Modal.Body>
        </Modal>
    );
}
