import { IUserModel } from '../../interfaces/IUser';
import { useLocation } from 'react-router';
import { ExecutionPlan } from '../strategy/ExecutionPlan';

function ExecutionPlanWrapper(props: { user: IUserModel }) {
    const { key } = useLocation();
    return <ExecutionPlan user={props.user} refreshIdentifier={key} />;
}

export default ExecutionPlanWrapper;
