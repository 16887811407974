import * as React from 'react';

import { ApplicationHelpType, ReportTypesEnum, UserRightsEnum } from '../../../utilities/Constants';
import {
    IPackageSettlementBaseModel,
    IPackageSettlementFirmModel,
} from '../../../interfaces/ICase';
import {
    IReportDateFilterModel,
    IReportParametersBaseModel,
} from '../../../interfaces/Report/IReport';

import ApiClient from '../../../services/apiClient';
import { ApiRoutes } from '../../../utilities/ApiRoutes';
import Authorization from '../../../stores/Authorization';
import { CheckmateDialog } from '../../../components/shared/dialog';
import Common from '../../../stores/Common';
import { DisplayMessages } from '../../../utilities/DisplayMessages';
import { Help } from '../../../components/shared/Help';
import { IUserModel } from '../../../interfaces/IUser';
import { IValidation } from '../../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../../components/shared/Loader';
import { LocalRoutes } from '../../../utilities/LocalRoutes';
import Sort from '../../../stores/Sort';
import { SortableHeader } from '../../../components/shared/SortableHeader';
import ValidateUtils from '../../../shared/validations';

interface IPackageSettlementProps {
    user: IUserModel;
    refreshIdentifier?: string;
}

interface IPackageSettlementListState {
    pendingResponse: boolean;
    validation: IValidation;
    packageSettlementList: IPackageSettlementBaseModel[];
    startDateFilter?: string;
    endDateFilter?: string;
    statusFilter?: number;
    selectedPackageSettlementGuidToDelete?: string;
    showConfirmDeleteDialog?: boolean;
}

const _apiClient = new ApiClient();

export class PackageSettlementList extends React.Component<
    IPackageSettlementProps,
    IPackageSettlementListState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            pendingResponse: false,
            packageSettlementList: [],
            validation: {},
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.PackageSettlements, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);
        this.loadPackageSettlementList();
    }

    componentDidUpdate(prevProps: IPackageSettlementProps) {
        if (
            this.props.refreshIdentifier &&
            prevProps.refreshIdentifier != this.props.refreshIdentifier
        ) {
            this.setState(
                { startDateFilter: undefined, endDateFilter: undefined, statusFilter: undefined },
                this.loadPackageSettlementList
            );
        }
    }

    loadPackageSettlementList = async () => {
        this.setState({ pendingResponse: true });
        const res = await _apiClient.getPackageSettlements(
            this.state.startDateFilter,
            this.state.endDateFilter,
            this.state.statusFilter
        );
        if (res.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (res.errorMessage) {
            this.setState({ validation: ValidateUtils.parseErrors(res.errors, res.errorMessage) });
            return;
        }
        if (res.payload) {
            this.setState({ packageSettlementList: res.payload, pendingResponse: false });
        }
    };

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let packageSettlementList = this.state.packageSettlementList;
        if (packageSettlementList) {
            if (key.includes('date')) {
                packageSettlementList = packageSettlementList.sort(
                    Sort.compareDate(key, subKey, order)
                );
            } else {
                packageSettlementList = packageSettlementList.sort(
                    Sort.compareValues(key, subKey, order, subGrandKey)
                );
            }
        }

        this.setState({ packageSettlementList: packageSettlementList });
    };

    handleClearSearch = async () => {
        this.setState(
            { startDateFilter: undefined, endDateFilter: undefined, statusFilter: undefined },
            this.loadPackageSettlementList
        );
    };

    generateAndDownloadReport = async () => {
        this.setState({ pendingResponse: true, validation: {} });

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + ApiRoutes.ExportReport, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this.setState({ pendingResponse: false, validation: {} });
                let fileName = 'PackageSettlementOverview.pdf';
                const blob = xhr.response;

                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    const contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (let i = 0; i < contentDispositionItems.length; i++) {
                            const currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                const n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                const validation = this.state.validation;
                validation.model = [DisplayMessages.ReportGenerateError];
                this.setState({ pendingResponse: false, validation: validation });
            }
        };

        const dateFilter: IReportDateFilterModel = {};
        if (this.state.startDateFilter) dateFilter.startDate = this.state.startDateFilter;
        if (this.state.endDateFilter) dateFilter.endDate = this.state.endDateFilter;
        const reportParameters: IReportParametersBaseModel = {
            reportType: { id: ReportTypesEnum.PackageSettlements.Value },
            date: dateFilter,
        };

        xhr.send(JSON.stringify(reportParameters));
    };

    delete = async () => {
        if (!this.state.selectedPackageSettlementGuidToDelete) return;

        const response = await _apiClient.deletePackageSettlement(
            this.state.selectedPackageSettlementGuidToDelete
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        const packageSettlementList = this.state.packageSettlementList;
        packageSettlementList.splice(
            packageSettlementList.findIndex(
                (x) => x.guid === this.state.selectedPackageSettlementGuidToDelete
            ),
            1
        );
        this.setState({
            packageSettlementList: packageSettlementList,
            showConfirmDeleteDialog: false,
        });
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;

        return (
            <div>
                <div className="row pb-3">
                    <div className="col-sm-3">
                        <span style={{ fontSize: '24px' }}>Package Deals</span>
                    </div>
                    <div className="col-sm-7">
                        <input
                            type="date"
                            name="startDate"
                            value={this.state.startDateFilter}
                            onChange={(e) => this.setState({ startDateFilter: e.target.value })}
                            className={
                                'form-control d-inline-block' +
                                (this.state.startDateFilter &&
                                Common.isValidDate(this.state.startDateFilter)
                                    ? ''
                                    : ' unselectClass')
                            }
                        />
                        <span className="px-2">to</span>
                        <input
                            type="date"
                            name="endDate"
                            value={this.state.endDateFilter}
                            onChange={(e) => this.setState({ endDateFilter: e.target.value })}
                            className={
                                'form-control d-inline-block' +
                                (this.state.endDateFilter &&
                                Common.isValidDate(this.state.endDateFilter)
                                    ? ''
                                    : ' unselectClass')
                            }
                        />
                        <button
                            type="button"
                            className="btn btn-black btn-icon ms-2"
                            onClick={() => this.loadPackageSettlementList()}
                        >
                            <i className="fal fa-filter color-white" />
                        </button>
                        <button
                            type="button"
                            className="btn btn-gray btn-icon"
                            onClick={this.handleClearSearch}
                        >
                            <i className="fal fa-times color-white" />
                        </button>
                        <Help
                            type={ApplicationHelpType.Info}
                            title="Date Range"
                            helpText="The date range filter/criteria looks at the Final Date if the deal status is Complete; for all other statuses, the filter/criteria looks at Initial Date."
                        />
                    </div>
                    <div
                        className="col-sm-2"
                        style={{ display: 'flex', flexDirection: 'row-reverse' }}
                    >
                        {Authorization.userHasRight(
                            UserRightsEnum.AddEditDeletePackageSettlement,
                            this.props.user
                        ) ? (
                            <Link
                                to={LocalRoutes.PackageSettlementDetails.replace(':guid', '')}
                                style={{ textDecoration: 'none' }}
                            >
                                <span className="btn-green btn float-end btn-icon">
                                    <i className="fal fa-lg fa-plus color-white" />
                                </span>
                            </Link>
                        ) : null}
                        {/*<button className="btn btn-no-bg float-end text-gray" onClick={this.generateAndDownloadReport}><i className="fal fa-file-alt" />&nbsp;Export</button>*/}
                    </div>
                </div>
                {this.state.packageSettlementList && this.state.packageSettlementList.length > 0 ? (
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <SortableHeader
                                    headerText="Name"
                                    sortKey="name"
                                    onSort={this.sortData}
                                    thClassName="col-sm-2"
                                />
                                <SortableHeader
                                    headerText="Status"
                                    sortKey="status"
                                    subKey="displayName"
                                    onSort={this.sortData}
                                />
                                <SortableHeader
                                    headerText="Initial Date"
                                    sortKey="initialDate"
                                    onSort={this.sortData}
                                    noWrap
                                />
                                <SortableHeader
                                    headerText="Final Date"
                                    sortKey="finalDate"
                                    onSort={this.sortData}
                                    noWrap
                                />
                                <SortableHeader
                                    headerText="Plaintiffs Firms"
                                    sortKey="firmSortText"
                                    onSort={this.sortData}
                                    thClassName="col-sm-2"
                                    noWrap
                                />
                                <th className="center">Total Cases</th>
                                <th className="center">Dismissed</th>
                                <th className="center">Settled</th>
                                <th className="center">Alt-Monitored</th>
                                <th className="center">Total Settlement</th>
                                {Authorization.userHasRight(
                                    UserRightsEnum.AddEditDeletePackageSettlement,
                                    this.props.user
                                ) && <th />}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.packageSettlementList.map(
                                (deal: IPackageSettlementBaseModel) => {
                                    return (
                                        <tr key={deal.guid}>
                                            <td>
                                                {' '}
                                                <Link
                                                    target="_blank"
                                                    to={LocalRoutes.PackageSettlementDetails.replace(
                                                        ':guid',
                                                        deal!.guid!
                                                    )}
                                                >
                                                    {deal.name}
                                                </Link>
                                            </td>
                                            <td>{deal.status!.displayName}</td>
                                            <td>{Common.dateFormat(deal.initialDate)}</td>
                                            <td>{Common.dateFormat(deal.finalDate)}</td>
                                            <td>
                                                {deal.firms ? (
                                                    <ul className="m-0 list-unstyled">
                                                        {deal.firms.map(
                                                            (firm: IPackageSettlementFirmModel) => {
                                                                return <li>{firm.name}</li>;
                                                            }
                                                        )}
                                                    </ul>
                                                ) : null}
                                            </td>
                                            <td className="center">
                                                {!deal.caseStats ? '' : deal.caseStats.total}
                                            </td>
                                            <td className="center">
                                                {!deal.caseStats ? '' : deal.caseStats.dismissed}
                                            </td>
                                            <td className="center">
                                                {!deal.caseStats ? '' : deal.caseStats.settled}
                                            </td>
                                            <td className="center">
                                                {!deal.caseStats ? '' : deal.caseStats.alt}
                                            </td>
                                            <td className="center">
                                                {!deal.caseStats
                                                    ? ''
                                                    : deal.caseStats.settlement
                                                    ? Common.formatCurrency(
                                                          deal.caseStats.settlement.toString()
                                                      )
                                                    : ''}
                                            </td>
                                            {Authorization.userHasRight(
                                                UserRightsEnum.AddEditDeletePackageSettlement,
                                                this.props.user
                                            ) && (
                                                <td>
                                                    <button
                                                        className="btn-no-bg float-end"
                                                        onClick={() => {
                                                            this.setState({
                                                                showConfirmDeleteDialog: true,
                                                                selectedPackageSettlementGuidToDelete:
                                                                    deal.guid!,
                                                            });
                                                        }}
                                                    >
                                                        <i className="fal fa-lg fa-trash-alt"></i>
                                                    </button>
                                                </td>
                                            )}
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                ) : null}

                <CheckmateDialog
                    isShowingModal={this.state.showConfirmDeleteDialog || false}
                    body="Are you sure you want to delete this item? This operation is permanent and cannot be reverted back."
                    handleClose={() => this.setState({ showConfirmDeleteDialog: false })}
                    handleConfirm={this.delete}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </div>
        );
    }
}
