import * as React from 'react';

import {
    BoardCriteriaEnum,
    BoardDataOtherGuid,
    BoardFilterDateTypesEnum,
    BoardViewDataTypesEnum,
    CaseStatusTypesEnum,
    CaseSubStatusTypesEnum,
    UserRightsEnum,
} from '../../utilities/Constants';

import ApiClient from '../../services/apiClient';
import Authorization from '../../stores/Authorization';
import { BoardPreferencesHeader } from '../../components/board/BoardPreferencesHeader';
import Common from '../../stores/Common';
import { IBoardParametersModel } from '../../interfaces/Report/IBoard';
import { ICaseStatusLookupModel } from '../../interfaces/ICase';
import { IReportCaseDetailModel } from '../../interfaces/Report/IReportCaseDetailModel';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../../components/shared/SortableHeader';
import ValidateUtils from '../../shared/validations';

const _apiClient = new ApiClient();

interface IBoardCaseDetailProps {
    user: IUserModel;
    type: string;
    preferences: string;
    id?: string;
    date?: string;
}

interface IBoardCaseDetailState {
    pendingResponse: boolean;
    details: IReportCaseDetailModel[];
    validation: IValidation;
    boardPreferences: IBoardParametersModel;
    type?: string;
    typeValue?: string;
    caseStatuses: ICaseStatusLookupModel[];
}

// AR - TO DO - WRAPPER
export class BoardCaseDetail extends React.Component<IBoardCaseDetailProps, IBoardCaseDetailState> {
    constructor(props: any) {
        super(props);

        this.state = {
            details: [],
            validation: {},
            pendingResponse: false,
            boardPreferences: {
                statusId: CaseStatusTypesEnum.Open.Value,
                criteria: BoardCriteriaEnum.CaseVolume.Value,
            },
            caseStatuses: [],
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.BoardCaseDetail, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadCaseDetails(
            this.props.type,
            this.props.preferences,
            this.props.id,
            this.props.date
        );
        this.loadCaseStatuses();
    }

    loadCaseDetails = async (type: string, preferences: string, id?: string, date?: string) => {
        this.setState({ pendingResponse: true });

        let boardPreferences: IBoardParametersModel = {
            statusId: CaseStatusTypesEnum.Open.Value,
            criteria: BoardCriteriaEnum.CaseVolume.Value,
        };

        if (preferences) {
            const jsonString = atob(preferences);
            boardPreferences = JSON.parse(jsonString);
        }

        const response = await _apiClient.getBoardCaseDetails(type, boardPreferences, id, date);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (response.payload) {
            const details = response.payload;

            // Get Type Name and Type Value for Header
            let typeName = '';
            let typeValue = '';
            if (type && Number(type)) {
                switch (Number(type)) {
                    case BoardViewDataTypesEnum.State.Value:
                        typeName = BoardViewDataTypesEnum.State.DisplayName;
                        if (id && id.toUpperCase() === BoardDataOtherGuid)
                            typeValue = 'Other States';
                        else if (details.length > 0) typeValue = details[0].state;
                        break;
                    case BoardViewDataTypesEnum.Jurisdiction.Value:
                        typeName = BoardViewDataTypesEnum.Jurisdiction.DisplayName;
                        if (id && id.toUpperCase() === BoardDataOtherGuid)
                            typeValue = 'Other Jurisdictions';
                        else if (details.length > 0 && details[0].jurisdiction)
                            typeValue = details[0].jurisdiction!;
                        break;
                    case BoardViewDataTypesEnum.PlaintiffsFirm.Value:
                        typeName = BoardViewDataTypesEnum.PlaintiffsFirm.DisplayName;
                        if (id && id.toUpperCase() === BoardDataOtherGuid)
                            typeValue = 'Other Plaintiff Firms';
                        else if (details.length > 0 && details[0].plaintiffsFirm)
                            typeValue = details[0].plaintiffsFirm!;
                        break;
                    case BoardViewDataTypesEnum.Diagnosis.Value:
                        typeName = BoardViewDataTypesEnum.Diagnosis.DisplayName;
                        if (id && id.toUpperCase() === BoardDataOtherGuid)
                            typeValue = 'Other Diagnoses';
                        else if (details.length > 0 && details[0].diagnosis)
                            typeValue = details[0].diagnosis!;
                        break;
                    case BoardViewDataTypesEnum.Timeline.Value:
                        if (date) {
                            typeValue = Common.getMonthAndYear(date);
                            let dateType: number = BoardFilterDateTypesEnum.AsOfDate.Value;
                            if (
                                boardPreferences.date &&
                                boardPreferences.date.type &&
                                boardPreferences.date.type > 0
                            )
                                dateType = boardPreferences.date.type;
                            boardPreferences.date = {};
                            switch (dateType) {
                                case BoardFilterDateTypesEnum.AsOfDate.Value:
                                    typeName = BoardFilterDateTypesEnum.AsOfDate.DisplayName;
                                    break;
                                case BoardFilterDateTypesEnum.ServiceDate.Value:
                                    typeName = BoardFilterDateTypesEnum.ServiceDate.DisplayName;
                                    break;
                                case BoardFilterDateTypesEnum.ResolutionDate.Value:
                                    typeName = BoardFilterDateTypesEnum.ResolutionDate.DisplayName;
                                    break;
                                case BoardFilterDateTypesEnum.TrialDate.Value:
                                    typeName = BoardFilterDateTypesEnum.TrialDate.DisplayName;
                                    break;
                                case BoardFilterDateTypesEnum.ProjectedSettlementDate.Value:
                                    typeName =
                                        BoardFilterDateTypesEnum.ProjectedSettlementDate
                                            .DisplayName;
                                    break;
                                default:
                                    typeValue = '';
                                    break;
                            }
                        }
                        break;
                    default:
                        break;
                }
            }

            this.setState({
                details: response.payload,
                boardPreferences: boardPreferences,
                pendingResponse: false,
                type: typeName,
                typeValue: typeValue,
            });
        }
    };

    loadCaseStatuses = async () => {
        const response = await _apiClient.getCaseStatusLookupData();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        this.setState({ caseStatuses: response.payload! });
    };

    sortData = (key: string, order: string, subKey?: string) => {
        this.setState({ pendingResponse: true });

        if (this.state.details) {
            let details: IReportCaseDetailModel[] = [];
            if (key.includes('Date')) {
                details = this.state.details.sort(Sort.compareDate(key, subKey, order));
            } else {
                details = this.state.details.sort(Sort.compareValues(key, subKey, order));
            }

            this.setState({ details: details, pendingResponse: false });
        }
    };

    render() {
        if (this.state.pendingResponse) {
            return <Loader />;
        } else {
            return (
                <div>
                    <h1 style={{ paddingBottom: '10px' }}>
                        Board - Case Details
                        <BoardPreferencesHeader
                            boardPreferences={this.state.boardPreferences}
                            type={this.state.type}
                            typeValue={this.state.typeValue}
                            caseStatuses={this.state.caseStatuses}
                        />
                        <span className="btn btn-blue float-end">
                            {' '}
                            <Link
                                to={LocalRoutes.Board + '?preferences=' + this.props.preferences}
                                style={{ color: 'white' }}
                            >
                                Back to Board
                            </Link>
                        </span>
                    </h1>
                    <table className="table">
                        <thead>
                            <tr style={{ verticalAlign: 'middle' }}>
                                <SortableHeader
                                    headerText="Case Name"
                                    sortKey="caseName"
                                    onSort={this.sortData}
                                    noWrap
                                />
                                <SortableHeader
                                    headerText="Status"
                                    sortKey="caseStatus"
                                    onSort={this.sortData}
                                />
                                <SortableHeader
                                    headerText="SQ Score"
                                    sortKey="qScore"
                                    onSort={this.sortData}
                                    thClassName="col-sm-1 align-top"
                                    noWrap
                                />
                                <SortableHeader
                                    headerText="SQ Proj"
                                    sortKey="qProjection"
                                    onSort={this.sortData}
                                    thClassName="col-sm-1"
                                    noWrap
                                />
                                <SortableHeader
                                    headerText="Jurisdiction"
                                    sortKey="jurisdiction"
                                    onSort={this.sortData}
                                />
                                <SortableHeader
                                    headerText="Plaintiffs Firm"
                                    sortKey="plaintiffsFirm"
                                    onSort={this.sortData}
                                    noWrap
                                />
                                <SortableHeader
                                    headerText="Disease"
                                    sortKey="diagnosis"
                                    onSort={this.sortData}
                                    thClassName="col-sm-1"
                                />
                                {this.state.boardPreferences.criteria ==
                                BoardCriteriaEnum.ProjectedSettlement.Value ? (
                                    <SortableHeader
                                        headerText="Projected Settlement"
                                        sortKey="projectedSettlement"
                                        onSort={this.sortData}
                                        thClassName="col-sm-1"
                                    />
                                ) : null}
                                {this.state.boardPreferences.criteria ==
                                BoardCriteriaEnum.ProjectedSettlement.Value ? (
                                    <SortableHeader
                                        headerText="Projected Resolution Date"
                                        sortKey="projectedResolutionDate"
                                        onSort={this.sortData}
                                        thClassName="col-sm-1"
                                    />
                                ) : null}
                                {this.state.boardPreferences.statusId ==
                                    CaseStatusTypesEnum.Resolved.Value &&
                                (!this.state.boardPreferences.subStatusIdList ||
                                    this.state.boardPreferences.subStatusIdList.length == 0 ||
                                    this.state.boardPreferences.subStatusIdList.filter(
                                        (x) => x == CaseSubStatusTypesEnum.Settled.Value
                                    ).length > 0) ? (
                                    <SortableHeader
                                        headerText="Final Settlement"
                                        sortKey="actualSettlement"
                                        onSort={this.sortData}
                                        thClassName="col-sm-1"
                                    />
                                ) : null}
                                {this.state.boardPreferences.statusId ==
                                CaseStatusTypesEnum.Resolved.Value ? (
                                    <SortableHeader
                                        headerText="Resolution Agreement Date"
                                        sortKey="resolutionAgreementDate"
                                        onSort={this.sortData}
                                        thClassName="col-sm-1"
                                    />
                                ) : null}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.details.map((item: IReportCaseDetailModel, i: number) => {
                                return (
                                    <tr key={i}>
                                        <td>
                                            {Authorization.userHasRight(
                                                UserRightsEnum.ViewCaseFileBasic,
                                                this.props.user
                                            ) ||
                                            Authorization.userHasRight(
                                                UserRightsEnum.ViewCaseFile,
                                                this.props.user
                                            ) ? (
                                                <Link
                                                    target="_blank"
                                                    to={LocalRoutes.CaseFile.replace(
                                                        ':guid',
                                                        item!.caseGuid!
                                                    )}
                                                >
                                                    {item.caseName}
                                                </Link>
                                            ) : (
                                                item.caseName
                                            )}
                                        </td>
                                        <td>{item!.caseStatus!.displayName!}</td>
                                        <td>{item!.qScore}</td>
                                        <td>{item!.qProjection}</td>
                                        <td>{item!.jurisdiction}</td>
                                        <td>{item!.plaintiffsFirm}</td>
                                        <td>{item!.diagnosis}</td>
                                        {this.state.boardPreferences.criteria ==
                                        BoardCriteriaEnum.ProjectedSettlement.Value ? (
                                            <td>
                                                {item!.projectedSettlement
                                                    ? Common.formatCurrency(
                                                          item!.projectedSettlement!.toString()
                                                      )
                                                    : ''}
                                            </td>
                                        ) : null}
                                        {this.state.boardPreferences.criteria ==
                                        BoardCriteriaEnum.ProjectedSettlement.Value ? (
                                            <td>
                                                {item!.projectedResolutionDate
                                                    ? Common.dateFormat(
                                                          item!.projectedResolutionDate
                                                      )
                                                    : ''}
                                            </td>
                                        ) : null}
                                        {this.state.boardPreferences.statusId ==
                                            CaseStatusTypesEnum.Resolved.Value &&
                                        (!this.state.boardPreferences.subStatusIdList ||
                                            this.state.boardPreferences.subStatusIdList.length ==
                                                0 ||
                                            this.state.boardPreferences.subStatusIdList.filter(
                                                (x) => x == CaseSubStatusTypesEnum.Settled.Value
                                            ).length > 0) ? (
                                            <td>
                                                {item!.actualSettlement
                                                    ? Common.formatCurrency(
                                                          item!.actualSettlement!.toString()
                                                      )
                                                    : ''}
                                            </td>
                                        ) : null}
                                        {this.state.boardPreferences.statusId ==
                                        CaseStatusTypesEnum.Resolved.Value ? (
                                            <td>
                                                {item!.resolutionDate
                                                    ? Common.dateFormat(item!.resolutionDate)
                                                    : ''}
                                            </td>
                                        ) : null}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            );
        }
    }
}
