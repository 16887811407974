import * as React from 'react';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { ILookupModel } from '../../interfaces/ILookup';
import ApiClient from '../../services/apiClient';
import { IValidation } from '../../interfaces/IError';
import ValidateUtils from '../../shared/validations';
import { Link } from 'react-router-dom';
import { IUserModel } from '../../interfaces/IUser';
import { ReportTypesEnum } from '../../utilities/Constants';
import Authorization from '../../stores/Authorization';
import { IReportTypeModel } from '../../interfaces/Report/IReport';

let _apiClient = new ApiClient();

interface IReportListHomeState {
    reportTypes: IReportTypeModel[];
    validation: IValidation;
}

export class ReportListHome extends React.Component<any, IReportListHomeState> {
    constructor(props: any) {
        super(props);

        this.state = {
            reportTypes: [],
            validation: {},
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.ReportTypeList, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadReportTypes();
    }

    loadReportTypes = async () => {
        var response = await _apiClient.getReportTypes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        if (response.payload) {
            this.setState({ reportTypes: response.payload });
        }
    };

    render() {
        return (
            <div>
                <h1>
                    Reports
                    <span className="btn btn-blue float-end">
                        {' '}
                        <Link to={LocalRoutes.UserReportList} style={{ color: 'white' }}>
                            View Existing Reports
                        </Link>
                    </span>
                </h1>
                <span className="text-danger">{this.state.validation.model}</span>
                <br />
                <ul>
                    {this.state.reportTypes.map((item: IReportTypeModel) => {
                        return (
                            <li key={item.id}>
                                <Link
                                    to={
                                        item.clientRouteUrl ||
                                        LocalRoutes.ReportDetail.replace(':id', item.id.toString())
                                    }
                                >
                                    <span className="btn btn-no-bg text-gray">
                                        <i
                                            className={
                                                item.name == 'PackageSettlements'
                                                    ? 'fal fa-box-usd'
                                                    : 'fal fa-file-chart-column'
                                            }
                                        />
                                        &nbsp;{item.displayName}
                                    </span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
