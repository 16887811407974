import * as React from 'react';
import { IValidation } from '../../interfaces/IError';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Loader } from '../../components/shared/Loader';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import {
    TimelineReportSections,
    KpiReportCommentReportParameterNames,
    HelpKeys,
    ReportTypesEnum,
    ApplicationHelpType,
} from '../../utilities/Constants';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import Common from '../../stores/Common';
import Html5ReportViewer from '../../components/report/Html5ReportViewer';
import { IReportSectionDetailModel } from '../../interfaces/Report/ITimelineReport';
import { IReportParametersBaseModel } from '../../interfaces/Report/IReport';
import { CheckmateDialog } from '../../components/shared/dialog';
import { Help } from '../../components/shared/Help';

interface IKpiReportState {
    pendingResponse: boolean;
    // "renderReport" controls whether the HTML5ReportViewer is automatically rendered when the page is loaded. In this case, NO; The user has to enter a Defense Spend amount and then click Run Report.
    renderReport?: boolean;
    // "refreshReport" controls how to make the HTML5ReportViewer re-render on the page. It re-rendered when this value is toggled (See componentDidUpdate of the HTML5ReportViewer)
    refreshReport: boolean;
    validation: IValidation;
    reportParameters: IKPIReportParameterModel;
    reportViewerHeight: number;
    showCommentsModal?: boolean;
    helpDialogToShow: string;
    displayExportOption: boolean;
    commentsValidation: IValidation;
    showClearCommentsConfirmModal?: boolean;
    showClearReportConfirmModal?: boolean;
}

interface IKPIReportParameterModel {
    endDate: string;
    amount: number;
    reportSectionDetails?: IReportSectionDetailModel[];
    comments_OpenActiveCases?: string;
    comments_SettlementSpend?: string;
    comments_DefenseSpend?: string;
    comments_AvgSettlementTrends?: string;
    comments_DataSourceFootnote?: string;
}

export class KpiReport extends React.Component<any, IKpiReportState> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: false,
            refreshReport: false,
            validation: {},
            helpDialogToShow: '',
            displayExportOption: false,
            reportParameters: {
                endDate: moment().format('YYYY-MM-DD'),
                amount: 0,
                reportSectionDetails: [],
            },
            commentsValidation: {},
            reportViewerHeight: 0,
        };
    }

    handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const params = this.state.reportParameters;

        const name = event.target.name;
        const value = event.target.value;

        if (name == 'endDate') {
            params.endDate = value;
        } else if (name == 'defenseSpend' && params) {
            params.amount = parseFloat(value);
        }

        this.setState({ reportParameters: params });
    };

    clear = () => {
        this.setState({
            renderReport: false,
            reportParameters: {
                endDate: moment().format('YYYY-MM-DD'),
                amount: 0,
                reportSectionDetails: [],
            },
            displayExportOption: false,
            showClearReportConfirmModal: false,
        });
    };

    generateAndDownloadReport = async () => {
        this.setState({ pendingResponse: true, validation: {} });

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + ApiRoutes.ExportReport, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this.setState({ pendingResponse: false, validation: {} });
                let fileName = 'KpiReport.pdf';
                const blob = xhr.response;

                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    const contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (let i = 0; i < contentDispositionItems.length; i++) {
                            const currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                const n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                const validation = this.state.validation;
                validation.model = [DisplayMessages.ReportGenerateError];
                this.setState({ pendingResponse: false, validation: validation });
            }
        };

        const reportParamsExport: IReportParametersBaseModel = {
            reportType: { id: ReportTypesEnum.KpiReport.Value },
            date: { endDate: this.state.reportParameters.endDate },
            amount: this.state.reportParameters.amount,
            reportSectionDetails: [...(this.state.reportParameters.reportSectionDetails ?? [])],
        };

        xhr.send(JSON.stringify(reportParamsExport));
    };

    handleCommentsChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
        section: TimelineReportSections,
        displayName: string
    ) => {
        const reportParameters = this.state.reportParameters;
        if (!reportParameters) {
            return;
        }
        if (!reportParameters.reportSectionDetails) {
            reportParameters.reportSectionDetails = [];
        }

        const value = event.target.value;
        const validation: IValidation = {};

        const configMatch = KpiReportCommentReportParameterNames.find((x) => x.section === section);
        const maxLines = configMatch ? configMatch.maxLines : 0;
        const maxLength = configMatch ? configMatch.maxLength : 0;

        const valueLines = value.split(/\r\n|\r|\n/).length;

        if (valueLines > maxLines) {
            validation.model = [`${displayName} cannot be longer than ${maxLines} line(s)`];
            this.setState({ commentsValidation: validation });
            return;
        } else {
            this.setState({ commentsValidation: validation });
        }

        const valueLength = value.length;
        if (valueLength > maxLength) {
            validation.model = [`${displayName} cannot be longer than ${maxLength} characters`];
            this.setState({ commentsValidation: validation });
            return;
        } else {
            this.setState({ commentsValidation: validation });
        }

        const match = reportParameters.reportSectionDetails.find((x) => x.section === section);
        if (match) {
            match.comment = value;
        } else {
            reportParameters.reportSectionDetails.push({
                section: section,
                sectionName: section.toString(),
                comment: event.target.value,
            });
        }

        switch (section) {
            case TimelineReportSections.DatasourceFootnote:
                reportParameters.comments_DataSourceFootnote = value;
                break;
            case TimelineReportSections.OpenClaims:
                reportParameters.comments_OpenActiveCases = value;
                break;
            case TimelineReportSections.SettlementSpend:
                reportParameters.comments_SettlementSpend = value;
                break;
            case TimelineReportSections.DefenseSpend:
                reportParameters.comments_DefenseSpend = value;
                break;
            case TimelineReportSections.AvgSettlementTrends:
                reportParameters.comments_AvgSettlementTrends = value;
                break;
            default:
                break;
        }

        this.setState({ reportParameters: reportParameters });
    };

    getCommentText = (section: TimelineReportSections) => {
        if (this.state.reportParameters && this.state.reportParameters.reportSectionDetails) {
            const sectionMatch = this.state.reportParameters.reportSectionDetails.find(
                (x) => x.section === section
            );
            if (sectionMatch) return sectionMatch.comment;
        }

        return '';
    };

    clearComments = () => {
        const reportParameters = this.state.reportParameters;
        if (reportParameters.reportSectionDetails) {
            for (let i = 0; i < reportParameters.reportSectionDetails.length; i++) {
                reportParameters.reportSectionDetails[i].comment = '';
            }
        }

        reportParameters.comments_OpenActiveCases = '';
        reportParameters.comments_SettlementSpend = '';
        reportParameters.comments_DefenseSpend = '';
        reportParameters.comments_AvgSettlementTrends = '';
        reportParameters.comments_DataSourceFootnote = '';

        this.setState({
            reportParameters: reportParameters,
            showClearCommentsConfirmModal: false,
        });
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;
        return (
            <div>
                <div className="row py-1-lg">
                    <div className="col-sm-2">
                        <span style={{ fontSize: '24px' }}>KPI Report</span>
                    </div>
                    <div className="col-sm-6">
                        <span className="horizontal-margin">as of</span>
                        <input
                            type="date"
                            name="endDate"
                            className={
                                'form-control mx-2 d-inline-block' +
                                (Common.isValidDate(this.state.reportParameters.endDate)
                                    ? ''
                                    : ' unselectClass')
                            }
                            value={this.state.reportParameters.endDate}
                            onChange={this.handleChange}
                        />
                        <span className="ms-2">YTD Defense Spend</span>
                        <Help
                            type={ApplicationHelpType.Info}
                            title="YTD Defense Spend"
                            helpText="If you set the “as of” date for the report to be 12/31/XX of any prior year, then the report will display the defense spend information that you provided for the lookup table for that year and 3 previous years; if you set the “as of” date for any date other than 12/31 of a prior year OR for YTD of the current year, then you need to input the YTD defense spend value for that partial period to be displayed along with the values from the lookup table for the previous 3 years."
                        />
                        <input
                            className={
                                this.state.reportParameters.amount === 0
                                    ? 'unselectClass form-control'
                                    : 'form-control'
                            }
                            style={{ display: 'inline-block' }}
                            type="number"
                            name="defenseSpend"
                            value={
                                this.state.reportParameters
                                    ? this.state.reportParameters.amount
                                    : ''
                            }
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="col-sm-4">
                        <button
                            className="btn btn-orange float-end text-gray"
                            onClick={() =>
                                this.setState({
                                    renderReport: true,
                                    refreshReport: !this.state.refreshReport,
                                    displayExportOption: true,
                                })
                            }
                        >
                            Run Report
                        </button>
                        <button
                            className="btn btn-default float-end "
                            onClick={() => this.setState({ showClearReportConfirmModal: true })}
                        >
                            Clear
                        </button>
                        <button
                            className="btn btn-orange float-end"
                            onClick={() => this.setState({ showCommentsModal: true })}
                        >
                            Comments
                        </button>
                        {this.state.displayExportOption ? (
                            <button
                                className="btn btn-no-bg float-end text-gray"
                                onClick={this.generateAndDownloadReport}
                            >
                                <i className="fal fa-file-chart-column" />
                                &nbsp;Report
                            </button>
                        ) : null}
                    </div>
                </div>

                <div className="margin-top-med">
                    <span className="text-danger">{this.state.validation.model}</span>
                </div>
                {this.state.renderReport ? (
                    <Html5ReportViewer
                        reportName="KpiReport.trdp"
                        reportParameters={this.state.reportParameters}
                        refreshReport={this.state.refreshReport}
                        scale={1.3}
                    />
                ) : null}

                {this.state.showCommentsModal ? (
                    <Modal
                        centered
                        show={this.state.showCommentsModal}
                        onHide={() => this.setState({ showCommentsModal: false })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> Comments</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="margin-top-med">
                                <span className="text-danger">
                                    {this.state.commentsValidation.model}
                                </span>
                            </div>
                            {KpiReportCommentReportParameterNames.map((comment: any) => {
                                return (
                                    <div className="form-group">
                                        <label className="control-label">
                                            {comment.displayName}
                                        </label>
                                        <span className="text-gray">
                                            &nbsp;&nbsp;(
                                            {comment.maxLength -
                                                (this.getCommentText(comment.section) ?? '')
                                                    .length +
                                                ' characters remaining'}
                                            )
                                        </span>
                                        <textarea
                                            className="form-control"
                                            value={this.getCommentText(comment.section)}
                                            maxLength={comment.maxLength}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => {
                                                this.handleCommentsChange(
                                                    e,
                                                    comment.section,
                                                    comment.displayName
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            })}
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={() =>
                                        this.setState({
                                            showCommentsModal: false,
                                            commentsValidation: {},
                                        })
                                    }
                                >
                                    Done
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({
                                            showClearCommentsConfirmModal: true,
                                        })
                                    }
                                >
                                    Clear
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                <CheckmateDialog
                    isShowingModal={this.state.showClearCommentsConfirmModal || false}
                    body="Are you sure you want to clear all comments?"
                    handleClose={() => {
                        this.setState({ showClearCommentsConfirmModal: false });
                    }}
                    handleConfirm={this.clearComments}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />

                <CheckmateDialog
                    isShowingModal={this.state.showClearReportConfirmModal || false}
                    body="Are you sure you want all report data?"
                    handleClose={() => {
                        this.setState({ showClearCommentsConfirmModal: false });
                    }}
                    handleConfirm={this.clear}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </div>
        );
    }
}
