import * as React from 'react';

import {
    ApplicationHelpType,
    AssertionDescriptorCategories,
    CommunicationProtocolAssertionDescriptors,
    DataScopesEnum,
    EntityTypes,
    NoteCategories,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    OperatingMechanisms,
    ReportTypesEnum,
    UserRightsEnum,
} from '../../utilities/Constants';
import {
    INoteCategoryModel,
    INoteCategoryTypeModel,
    INoteModel,
    INotesUICustomization,
} from '../../interfaces/INote';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import { CheckmateDialog } from '../../components/shared/dialog';
import Common from '../../stores/Common';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import EntityAssertionControl from '../../components/shared/EntityAssertionControl';
import { ExportFile } from '../../components/shared/ExportFile';
import { Help } from '../../components/shared/Help';
import { IEntityAssertionModel } from '../../interfaces/IAssertionDescriptor';
import { IOrganizationModel } from '../../interfaces/IOrganization';
import { IReportTypeModel } from '../../interfaces/Report/IReport';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import { NoteEditor } from '../../components/notes/NoteEditor';
import NoteHelper from '../../utilities/NoteHelper';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../../components/shared/SortableHeader';
import ValidateUtils from '../../shared/validations';

let _apiClient = new ApiClient();

interface IExecutionPlanProps {
    user: IUserModel;
    refreshIdentifier?: string;
}

interface IExecutionPlanState {
    pendingResponse: boolean;
    readOnly: boolean;
    validation: IValidation;
    assertions: IEntityAssertionModel[];
    originalAssertions: IEntityAssertionModel[];
    currentNarrativeCaseAssertionOnEditMode?: IEntityAssertionModel;
    significantChanges: INoteModel[];
    originalSignificantChanges: INoteModel[];
    showSignificantChanges?: boolean;
    selectedSignificantChange?: INoteModel;
    openNoteEditor?: boolean;
    noteCategoryTypes: INoteCategoryTypeModel[];
    defaultOrganization: IOrganizationModel;
    showConfirmRemoveSignificantChangeDialog?: boolean;
    selectedSignificantChangeToDelete?: INoteModel;
    reportType: IReportTypeModel;
    loggedInUserDataScopeId: number;
}

export class ExecutionPlan extends React.Component<IExecutionPlanProps, IExecutionPlanState> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: true,
            readOnly: true,
            validation: {},
            assertions: [],
            originalAssertions: [],
            noteCategoryTypes: [],
            defaultOrganization: {},
            significantChanges: [],
            originalSignificantChanges: [],
            reportType: { id: 0 },
            loggedInUserDataScopeId: 0,
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.StrategyExecutionPlan, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);
        this.loadLoggedInUserDefaultOrganization();
    }

    componentDidUpdate(prevProps: IExecutionPlanProps) {
        if (this.props.refreshIdentifier != prevProps.refreshIdentifier) {
            this.setState(
                {
                    pendingResponse: true,
                    readOnly: true,
                    validation: {},
                    assertions: [],
                    originalAssertions: [],
                    noteCategoryTypes: [],
                    defaultOrganization: {},
                    significantChanges: [],
                    originalSignificantChanges: [],
                    reportType: { id: 0 },
                    loggedInUserDataScopeId: 0,
                    openNoteEditor: false,
                    selectedSignificantChange: undefined,
                    showSignificantChanges: false,
                },
                this.loadLoggedInUserDefaultOrganization
            );
        }
    }

    loadLoggedInUserDefaultOrganization = async () => {
        let response = await _apiClient.getDefaultUserOrganizationFromServer();
        if (!response) return;
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(
            this.props.user
        );

        this.setState(
            {
                defaultOrganization: response.payload!,
                loggedInUserDataScopeId: loggedInUserDataScopeId,
            },
            this.loadExecutionPlanAssertions
        );
    };

    loadExecutionPlanAssertions = async () => {
        this.setState({ pendingResponse: true });

        var response = await _apiClient.getExecutionPlanEntityAssertions();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        if (response.payload) {
            this.setState(
                {
                    assertions: response.payload,
                    originalAssertions: JSON.parse(JSON.stringify(response.payload)),
                    readOnly: true,
                },
                this.loadNoteCategoryTypes
            );
        }
    };

    loadNoteCategoryTypes = async () => {
        var response = await _apiClient.getSignificantChangesCategoryTypes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        if (response.payload) {
            this.setState(
                { noteCategoryTypes: response.payload, readOnly: true },
                this.loadSignificantChanges
            );
        }
    };

    loadSignificantChanges = async () => {
        var response = await _apiClient.getNotes(
            NoteTypes.StrategySignificantChange,
            NoteCategories.StrategySignificantChanges.toString()
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (response.payload) {
            this.setState({
                significantChanges: response.payload,
                pendingResponse: false,
                readOnly: true,
            });
        }
    };

    getHelpText = (originalText?: string) => {
        let text: string = '';
        if (originalText) {
            const temp = originalText.split('-');
            for (var i = 0; i < temp.length; i++) {
                if (temp[i]) text += '- ' + temp[i] + '\n';
            }
        }

        return text;
    };

    getLastUpdated = () => {
        if (this.state.assertions.filter((x) => x.modifiedDate).length > 0)
            return (
                'Last Updated ' +
                Common.dateTimeFormatToLocal(
                    this.state.assertions
                        .filter((x) => x.modifiedDate)
                        .sort(Sort.compareDate('modifiedDate', 'desc'))[0].modifiedDate
                )
            );
        else return '';
    };

    save = async () => {
        this.setState({ pendingResponse: true, validation: {} });
        var response = await _apiClient.saveEntityAssertions(this.state.assertions);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.loadExecutionPlanAssertions();
    };

    onClickViewAddEditNotesSection = () => {
        this.setState({ showSignificantChanges: !this.state.showSignificantChanges });
    };

    handleAddNote = () => {
        const noteCategory: INoteCategoryModel = { id: NoteCategories.StrategySignificantChanges };
        let currentNote: INoteModel = {
            type: { id: NoteTypes.StrategySignificantChange },
            status: { id: NoteStatusTypes.Open },
            organizationGuid: this.state.defaultOrganization.guid,
            categories: [noteCategory],
            purpose: { id: NotePurposeTypesEnum.Info },
        };

        this.setState({
            openNoteEditor: true,
            selectedSignificantChange: currentNote,
            showSignificantChanges: false,
        });
    };

    handleEditNote = (e: React.MouseEvent<HTMLButtonElement>, noteGuidToEdit: string) => {
        if (!this.state.significantChanges) return;

        let currentNote = this.state.significantChanges.find((x) => x.guid == noteGuidToEdit);
        if (currentNote) {
            this.setState({
                selectedSignificantChange: currentNote,
                openNoteEditor: true,
                showSignificantChanges: false,
            });
        }
    };

    refreshParent = (notes: INoteModel[]) => {
        this.setState({ significantChanges: JSON.parse(JSON.stringify(notes)) });
    };

    handleCancelNoteEditor = () => {
        this.setState({
            significantChanges: this.state.originalSignificantChanges
                ? JSON.parse(JSON.stringify(this.state.originalSignificantChanges))
                : undefined,
            selectedSignificantChange: undefined,
            openNoteEditor: false,
            showSignificantChanges: true,
        });
    };

    onSaveNoteComplete = (noteItem: INoteModel) => {
        let significantChanges = this.state.significantChanges || [];
        let matchFound = false;
        for (var i = 0; i < significantChanges.length; i++) {
            if (significantChanges[i].guid == noteItem.guid) {
                matchFound = true;
                significantChanges[i] = JSON.parse(JSON.stringify(noteItem));
                break;
            }
        }

        if (!matchFound) significantChanges.push(JSON.parse(JSON.stringify(noteItem)));
        significantChanges = significantChanges.sort(
            Sort.compareDate('modifiedDate', undefined, 'desc')
        );
        this.setState({
            pendingResponse: false,
            openNoteEditor: false,
            significantChanges: significantChanges,
            originalSignificantChanges: JSON.parse(JSON.stringify(significantChanges)),
            selectedSignificantChange: undefined,
            showSignificantChanges: true,
        });
    };

    getNotesUICustomization = () => {
        const config: INotesUICustomization = {
            hideCategories: true,
            contentHeaderText: 'Impact',
            displayAdditionalInfoAsColumns: true,
            hideFilterByCategory: true,
            hideAuditFields: true,
            hideExport: true,
        };

        return config;
    };

    getEntityAssertionMatch(
        assertionDescriptorId: string,
        entityTypeId: number,
        entityId?: number
    ) {
        if (entityId)
            return this.state.assertions.filter(
                (x) =>
                    x.assertionDescriptor &&
                    x.assertionDescriptor.guid!.toUpperCase() ==
                        assertionDescriptorId.toUpperCase() &&
                    x.entityType &&
                    x.entityType.id == entityTypeId &&
                    x.entityId == entityId
            )[0];
        else
            return this.state.assertions.filter(
                (x) =>
                    x.assertionDescriptor &&
                    x.assertionDescriptor.guid!.toUpperCase() ==
                        assertionDescriptorId.toUpperCase() &&
                    x.entityType &&
                    x.entityType.id == entityTypeId
            )[0];
    }

    finalizeChange = (modifiedAssertions: IEntityAssertionModel[]) => {
        if (modifiedAssertions.length == 1) {
            let assertions = this.state.assertions;
            let match = assertions.filter(
                (x) =>
                    x.guid === modifiedAssertions[0].guid &&
                    x.assertionDescriptor &&
                    modifiedAssertions[0].assertionDescriptor &&
                    x.assertionDescriptor.guid === modifiedAssertions[0].assertionDescriptor.guid &&
                    x.entityType &&
                    modifiedAssertions[0].entityType &&
                    x.entityType.id === modifiedAssertions[0].entityType.id &&
                    x.entityId === modifiedAssertions[0].entityId
            );
            if (match.length > 0) {
                match[0].textValue = modifiedAssertions[0].textValue;
                match[0].booleanValue = modifiedAssertions[0].booleanValue;
                match[0].numericValue = modifiedAssertions[0].numericValue;
                match[0].dateTimeValue = modifiedAssertions[0].dateTimeValue;
                match[0].assertionDescriptorValue = modifiedAssertions[0].assertionDescriptorValue;
                this.setState({
                    assertions: assertions,
                    currentNarrativeCaseAssertionOnEditMode: undefined,
                });
            }
        }
    };

    handleNarrativeFieldEdit = (item: IEntityAssertionModel) => {
        let match = this.state.assertions.find(
            (x) =>
                x.guid === item.guid &&
                x.assertionDescriptor &&
                item.assertionDescriptor &&
                x.assertionDescriptor.guid === item.assertionDescriptor.guid &&
                x.entityType &&
                item.entityType &&
                x.entityType.id === item.entityType.id &&
                x.entityId === item.entityId
        );
        if (match)
            this.setState({
                currentNarrativeCaseAssertionOnEditMode: JSON.parse(JSON.stringify(match)),
            });
    };

    handleClearNarrativeFieldEdit = () => {
        if (this.state.currentNarrativeCaseAssertionOnEditMode) {
            let currentNarrativeCaseAssertionOnEditMode =
                this.state.currentNarrativeCaseAssertionOnEditMode;
            currentNarrativeCaseAssertionOnEditMode.textValue = '';
            this.setState({
                currentNarrativeCaseAssertionOnEditMode: currentNarrativeCaseAssertionOnEditMode,
            });
        }
    };

    handleNarrativeFieldTextChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (this.state.currentNarrativeCaseAssertionOnEditMode) {
            let currentNarrativeCaseAssertionOnEditMode =
                this.state.currentNarrativeCaseAssertionOnEditMode;
            currentNarrativeCaseAssertionOnEditMode.textValue = event.target.value;
            this.setState({
                currentNarrativeCaseAssertionOnEditMode: currentNarrativeCaseAssertionOnEditMode,
            });
        }
    };

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        if (this.state.significantChanges) {
            let significantChanges: INoteModel[] = [];
            if (key == 'date') {
                // HACK. Only for Signiticant Changes List, Date = Associated Entity Assertion and NOT Modified Date
                let significantChangesTemp: INoteModel[] = JSON.parse(
                    JSON.stringify(this.state.significantChanges)
                );
                {
                    significantChangesTemp.map((item: INoteModel) => {
                        item.modifiedDate = Common.dateFormat(
                            NoteHelper.getAssertionValue(item, 'DATE')
                        );
                    });
                }

                const sortedData = significantChangesTemp.sort(
                    Sort.compareDate('modifiedDate', subKey, order)
                );
                sortedData.map((item: INoteModel) => {
                    significantChanges.push(
                        this.state.significantChanges.find((x) => x.guid === item.guid)!
                    );
                });
            } else {
                significantChanges = this.state.significantChanges.sort(
                    Sort.compareValues(key, subKey, order, subGrandKey)
                );
            }

            this.setState({ significantChanges: significantChanges });
        }
    };

    showConfirmRemoveDailog = (note: INoteModel) => {
        this.setState({
            showConfirmRemoveSignificantChangeDialog: true,
            selectedSignificantChangeToDelete: note,
        });
    };

    cancelRemoveSignificantChange = () => {
        this.setState({
            showConfirmRemoveSignificantChangeDialog: false,
            selectedSignificantChangeToDelete: undefined,
        });
    };

    deleteNote = async () => {
        if (!this.state.selectedSignificantChangeToDelete) return;

        var key;
        if (this.state.selectedSignificantChangeToDelete.type!.id == NoteTypes.CaseNote) {
            key = this.state.selectedSignificantChangeToDelete.caseGuid;
        }

        var response = await _apiClient.deleteNote(
            this.state.selectedSignificantChangeToDelete!.guid!,
            key
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        let updatedSignificantChanges = this.state.significantChanges.filter(
            (x) => x.guid != this.state.selectedSignificantChangeToDelete!.guid!
        );
        this.setState({
            significantChanges: updatedSignificantChanges,
            originalSignificantChanges: JSON.parse(JSON.stringify(updatedSignificantChanges)),
            showConfirmRemoveSignificantChangeDialog: false,
        });
    };

    handleSignificantChangesExportError = () => {
        let validation = this.state.validation;
        validation.significantChanges = [DisplayMessages.UnexpectedError];
        this.setState({ pendingResponse: false, validation: validation });
    };

    handleExportError = () => {
        let validation = this.state.validation;
        validation.model = [DisplayMessages.UnexpectedError];
        this.setState({ pendingResponse: false, validation: validation });
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;

        if (
            this.state.openNoteEditor &&
            this.state.selectedSignificantChange &&
            this.state.openNoteEditor
        )
            return (
                <NoteEditor
                    user={this.props.user}
                    noteCategoryTypes={this.state.noteCategoryTypes}
                    currentNote={this.state.selectedSignificantChange}
                    handleSaveComplete={this.onSaveNoteComplete}
                    handleCancel={this.handleCancelNoteEditor}
                    notesUICustomization={this.getNotesUICustomization()}
                    enableNoteAssociations={true}
                    noteAssociationTitle="Associated Topic(s)"
                    entityType={{ id: EntityTypes.AssertionDescriptor }}
                    entityItems={NoteHelper.getAssociatedAssertionDescriptorListForSignificantChanges(
                        this.state.assertions
                    )}
                />
            );

        return (
            <div>
                <h1>
                    Execution Plan
                    <span className=" font-size-xs ms-2">{this.getLastUpdated()}</span>
                    <span>
                        {this.state.readOnly ? (
                            <span>
                                {Authorization.userHasRight(
                                    UserRightsEnum.ViewStrategicPlan,
                                    this.props.user
                                ) ||
                                Authorization.userHasRight(
                                    UserRightsEnum.EditStrategicPlan,
                                    this.props.user
                                ) ? (
                                    <button
                                        className="btn btn-no-bg float-end text-gray -only"
                                        onClick={() =>
                                            this.setState({ showSignificantChanges: true })
                                        }
                                    >
                                        <i className="fal fa-directions" />
                                        &nbsp;Significant Changes
                                    </button>
                                ) : null}
                                {Authorization.userHasRight(
                                    UserRightsEnum.EditExecutionPlan,
                                    this.props.user
                                ) ? (
                                    <>
                                        <ExportFile
                                            url={ApiRoutes.ExportReport}
                                            reportType={{
                                                id: ReportTypesEnum.ExecutionPlanExport.Value,
                                            }}
                                            handleError={this.handleExportError}
                                            text="PDF"
                                            icon="fa-file-pdf"
                                        />
                                        <button
                                            className="btn btn-no-bg float-end text-gray"
                                            onClick={() => this.setState({ readOnly: false })}
                                        >
                                            <i className="fal fa-edit" />
                                            &nbsp;Edit
                                        </button>
                                    </>
                                ) : null}
                            </span>
                        ) : Authorization.userHasRight(
                              UserRightsEnum.EditExecutionPlan,
                              this.props.user
                          ) ? (
                            <>
                                <span>
                                    <button
                                        className="btn btn-default float-end"
                                        onClick={() =>
                                            this.setState({
                                                assertions: this.state.originalAssertions,
                                                readOnly: true,
                                            })
                                        }
                                    >
                                        Cancel
                                    </button>
                                    <input
                                        type="button"
                                        className="btn btn-orange float-end "
                                        onClick={this.save}
                                        value="Save"
                                    />
                                </span>
                            </>
                        ) : null}
                    </span>
                    {Authorization.userHasRight(
                        UserRightsEnum.EditExecutionPlan,
                        this.props.user
                    ) ? (
                        <></>
                    ) : null}
                </h1>
                <span className="text-danger">{this.state.validation.model}</span>
                <div className="panel panel-default thick-outer-border p-2">
                    <div className="panel-heading bold">Principles</div>
                    <div className="panel-body" style={{ backgroundColor: '#f5f5f5' }}>
                        {this.state.assertions
                            .filter(
                                (x) =>
                                    x.assertionDescriptor &&
                                    x.assertionDescriptor.category &&
                                    x.assertionDescriptor.category.id ==
                                        AssertionDescriptorCategories.Strategy_Principles.Value &&
                                    x.entityType &&
                                    x.entityType.id == EntityTypes.Organization
                            )
                            .map((item: IEntityAssertionModel, index: number) => {
                                return (
                                    <div>
                                        <div className="row no-margin">
                                            <div className="col-sm-3">
                                                <label className="text-gray">
                                                    {item.assertionDescriptor.displayName}
                                                </label>
                                                {item.assertionDescriptor.helpText ? (
                                                    <Help
                                                        type={ApplicationHelpType.Info}
                                                        title={
                                                            item.assertionDescriptor.displayName!
                                                        }
                                                        helpText={item.assertionDescriptor.helpText}
                                                    />
                                                ) : null}
                                                {(Authorization.userHasRight(
                                                    UserRightsEnum.ViewStrategicPlan,
                                                    this.props.user
                                                ) ||
                                                    Authorization.userHasRight(
                                                        UserRightsEnum.EditStrategicPlan,
                                                        this.props.user
                                                    )) &&
                                                this.state.significantChanges &&
                                                NoteHelper.hasSignificantChange(
                                                    item.assertionDescriptor.guid!,
                                                    this.state.significantChanges
                                                ) ? (
                                                    <button
                                                        className="btn btn-no-bg"
                                                        onClick={() =>
                                                            this.setState({
                                                                showSignificantChanges: true,
                                                            })
                                                        }
                                                    >
                                                        <i className="fal fa-lg fa-directions color-gray" />
                                                    </button>
                                                ) : null}
                                            </div>
                                            <div className="col-sm-9 horizontal-padding">
                                                <EntityAssertionControl
                                                    assertionDescriptor={item.assertionDescriptor}
                                                    entityAssertions={[item]}
                                                    editMode={false}
                                                    refreshChange={() => {}}
                                                    handleNarrativeFieldEdit={() => {}}
                                                />
                                            </div>
                                        </div>
                                        {index !=
                                        this.state.assertions.filter(
                                            (x) =>
                                                x.assertionDescriptor &&
                                                x.assertionDescriptor.category &&
                                                x.assertionDescriptor.category.id ==
                                                    AssertionDescriptorCategories
                                                        .Strategy_Principles.Value &&
                                                x.entityType &&
                                                x.entityType.id == EntityTypes.Organization
                                        ).length -
                                            1 ? (
                                            <hr className="thin-top-border strategic-plan-line-margin" />
                                        ) : null}
                                    </div>
                                );
                            })}
                    </div>
                </div>

                <table className="table mt-3">
                    <thead>
                        <tr>
                            <th className="col-sm-2">Operating Mechanisms</th>
                            <th className="col-sm-2">Client Service</th>
                            <th className="col-sm-2">Portfolio Management</th>
                            <th className="col-sm-2">Case Development</th>
                            <th className="col-sm-2">COEs</th>
                            <th className="col-sm-2">Local Counsel</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.assertions
                            .filter(
                                (x) =>
                                    x.assertionDescriptor &&
                                    x.assertionDescriptor.category &&
                                    x.assertionDescriptor.category.id ==
                                        AssertionDescriptorCategories.Strategy_Principles.Value &&
                                    x.entityType &&
                                    x.entityType.id == EntityTypes.Organization
                            )
                            .map((item: IEntityAssertionModel, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td className="col-sm-2 text-gray">
                                            {item.assertionDescriptor!.displayName}
                                        </td>
                                        <td className="col-sm-2">
                                            <EntityAssertionControl
                                                assertionDescriptor={item.assertionDescriptor}
                                                entityAssertions={[
                                                    this.getEntityAssertionMatch(
                                                        item.assertionDescriptor!.guid!,
                                                        EntityTypes.OperatingMechanism,
                                                        OperatingMechanisms.ClientService
                                                    ),
                                                ]}
                                                editMode={!this.state.readOnly}
                                                refreshChange={this.finalizeChange}
                                                handleNarrativeFieldEdit={
                                                    this.handleNarrativeFieldEdit
                                                }
                                                narrativeFieldAsPopup={true}
                                            />
                                        </td>
                                        <td className="col-sm-2">
                                            <EntityAssertionControl
                                                assertionDescriptor={item.assertionDescriptor}
                                                entityAssertions={[
                                                    this.getEntityAssertionMatch(
                                                        item.assertionDescriptor!.guid!,
                                                        EntityTypes.OperatingMechanism,
                                                        OperatingMechanisms.PortfolioManagement
                                                    ),
                                                ]}
                                                editMode={!this.state.readOnly}
                                                refreshChange={this.finalizeChange}
                                                handleNarrativeFieldEdit={
                                                    this.handleNarrativeFieldEdit
                                                }
                                                narrativeFieldAsPopup={true}
                                            />
                                        </td>
                                        <td className="col-sm-2">
                                            <EntityAssertionControl
                                                assertionDescriptor={item.assertionDescriptor}
                                                entityAssertions={[
                                                    this.getEntityAssertionMatch(
                                                        item.assertionDescriptor!.guid!,
                                                        EntityTypes.OperatingMechanism,
                                                        OperatingMechanisms.CaseDevelopment
                                                    ),
                                                ]}
                                                editMode={!this.state.readOnly}
                                                refreshChange={this.finalizeChange}
                                                handleNarrativeFieldEdit={
                                                    this.handleNarrativeFieldEdit
                                                }
                                                narrativeFieldAsPopup={true}
                                            />
                                        </td>
                                        <td className="col-sm-2">
                                            <EntityAssertionControl
                                                assertionDescriptor={item.assertionDescriptor}
                                                entityAssertions={[
                                                    this.getEntityAssertionMatch(
                                                        item.assertionDescriptor!.guid!,
                                                        EntityTypes.OperatingMechanism,
                                                        OperatingMechanisms.COE
                                                    ),
                                                ]}
                                                editMode={!this.state.readOnly}
                                                refreshChange={this.finalizeChange}
                                                handleNarrativeFieldEdit={
                                                    this.handleNarrativeFieldEdit
                                                }
                                                narrativeFieldAsPopup={true}
                                            />
                                        </td>
                                        <td className="col-sm-3">
                                            <EntityAssertionControl
                                                assertionDescriptor={item.assertionDescriptor}
                                                entityAssertions={[
                                                    this.getEntityAssertionMatch(
                                                        item.assertionDescriptor!.guid!,
                                                        EntityTypes.OperatingMechanism,
                                                        OperatingMechanisms.LocalCounsel
                                                    ),
                                                ]}
                                                editMode={!this.state.readOnly}
                                                refreshChange={this.finalizeChange}
                                                handleNarrativeFieldEdit={
                                                    this.handleNarrativeFieldEdit
                                                }
                                                narrativeFieldAsPopup={true}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>

                <table className="table">
                    <thead>
                        <tr>
                            <th colSpan={3}>Communication Protocol</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="col-sm-2 text-gray">
                                Day-to-day
                                {this.getEntityAssertionMatch(
                                    CommunicationProtocolAssertionDescriptors.Daily.Guid,
                                    EntityTypes.Organization
                                ).assertionDescriptor.helpText ? (
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Day-to-day"
                                        helpText={
                                            this.getEntityAssertionMatch(
                                                CommunicationProtocolAssertionDescriptors.Daily
                                                    .Guid,
                                                EntityTypes.Organization
                                            ).assertionDescriptor.helpText!
                                        }
                                    />
                                ) : null}
                                {(Authorization.userHasRight(
                                    UserRightsEnum.ViewStrategicPlan,
                                    this.props.user
                                ) ||
                                    Authorization.userHasRight(
                                        UserRightsEnum.EditStrategicPlan,
                                        this.props.user
                                    )) &&
                                this.state.significantChanges &&
                                NoteHelper.hasSignificantChange(
                                    this.getEntityAssertionMatch(
                                        CommunicationProtocolAssertionDescriptors.Daily.Guid,
                                        EntityTypes.Organization
                                    ).assertionDescriptor.guid!,
                                    this.state.significantChanges
                                ) ? (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={() =>
                                            this.setState({ showSignificantChanges: true })
                                        }
                                    >
                                        <i className="fal fa-lg fa-directions color-gray" />
                                    </button>
                                ) : null}
                            </td>
                            <td className="col-sm-1"></td>
                            <td>
                                <EntityAssertionControl
                                    assertionDescriptor={
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors.Daily.Guid,
                                            EntityTypes.Organization
                                        ).assertionDescriptor
                                    }
                                    entityAssertions={[
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors.Daily.Guid,
                                            EntityTypes.Organization
                                        ),
                                    ]}
                                    editMode={!this.state.readOnly}
                                    refreshChange={this.finalizeChange}
                                    handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                                    narrativeFieldAsPopup={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="col-sm-2 text-gray">Meetings</td>
                            <td className="col-sm-1 text-gray">
                                Week
                                {this.getEntityAssertionMatch(
                                    CommunicationProtocolAssertionDescriptors.WeeklyMeetings.Guid,
                                    EntityTypes.Organization
                                ).assertionDescriptor.helpText ? (
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Day-to-day"
                                        helpText={
                                            this.getEntityAssertionMatch(
                                                CommunicationProtocolAssertionDescriptors
                                                    .WeeklyMeetings.Guid,
                                                EntityTypes.Organization
                                            ).assertionDescriptor.helpText!
                                        }
                                    />
                                ) : null}
                                {(Authorization.userHasRight(
                                    UserRightsEnum.ViewStrategicPlan,
                                    this.props.user
                                ) ||
                                    Authorization.userHasRight(
                                        UserRightsEnum.EditStrategicPlan,
                                        this.props.user
                                    )) &&
                                this.state.significantChanges &&
                                NoteHelper.hasSignificantChange(
                                    this.getEntityAssertionMatch(
                                        CommunicationProtocolAssertionDescriptors.WeeklyMeetings
                                            .Guid,
                                        EntityTypes.Organization
                                    ).assertionDescriptor.guid!,
                                    this.state.significantChanges
                                ) ? (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={() =>
                                            this.setState({ showSignificantChanges: true })
                                        }
                                    >
                                        <i className="fal fa-lg fa-directions color-gray" />
                                    </button>
                                ) : null}
                            </td>
                            <td>
                                <EntityAssertionControl
                                    assertionDescriptor={
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors.WeeklyMeetings
                                                .Guid,
                                            EntityTypes.Organization
                                        ).assertionDescriptor
                                    }
                                    entityAssertions={[
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors.WeeklyMeetings
                                                .Guid,
                                            EntityTypes.Organization
                                        ),
                                    ]}
                                    editMode={!this.state.readOnly}
                                    refreshChange={this.finalizeChange}
                                    handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                                    narrativeFieldAsPopup={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="col-sm-2 text-gray"></td>
                            <td className="col-sm-1 text-gray">
                                Month
                                {this.getEntityAssertionMatch(
                                    CommunicationProtocolAssertionDescriptors.MonthlyMeetings.Guid,
                                    EntityTypes.Organization
                                ).assertionDescriptor.helpText ? (
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Day-to-day"
                                        helpText={
                                            this.getEntityAssertionMatch(
                                                CommunicationProtocolAssertionDescriptors
                                                    .MonthlyMeetings.Guid,
                                                EntityTypes.Organization
                                            ).assertionDescriptor.helpText!
                                        }
                                    />
                                ) : null}
                                {(Authorization.userHasRight(
                                    UserRightsEnum.ViewStrategicPlan,
                                    this.props.user
                                ) ||
                                    Authorization.userHasRight(
                                        UserRightsEnum.EditStrategicPlan,
                                        this.props.user
                                    )) &&
                                this.state.significantChanges &&
                                NoteHelper.hasSignificantChange(
                                    this.getEntityAssertionMatch(
                                        CommunicationProtocolAssertionDescriptors.MonthlyMeetings
                                            .Guid,
                                        EntityTypes.Organization
                                    ).assertionDescriptor.guid!,
                                    this.state.significantChanges
                                ) ? (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={() =>
                                            this.setState({ showSignificantChanges: true })
                                        }
                                    >
                                        <i className="fal fa-lg fa-directions color-gray" />
                                    </button>
                                ) : null}
                            </td>
                            <td>
                                <EntityAssertionControl
                                    assertionDescriptor={
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors
                                                .MonthlyMeetings.Guid,
                                            EntityTypes.Organization
                                        ).assertionDescriptor
                                    }
                                    entityAssertions={[
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors
                                                .MonthlyMeetings.Guid,
                                            EntityTypes.Organization
                                        ),
                                    ]}
                                    editMode={!this.state.readOnly}
                                    refreshChange={this.finalizeChange}
                                    handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                                    narrativeFieldAsPopup={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="col-sm-2 text-gray"></td>
                            <td className="col-sm-1 text-gray">
                                Quarter
                                {this.getEntityAssertionMatch(
                                    CommunicationProtocolAssertionDescriptors.QuarterlyMeetings
                                        .Guid,
                                    EntityTypes.Organization
                                ).assertionDescriptor.helpText ? (
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Day-to-day"
                                        helpText={
                                            this.getEntityAssertionMatch(
                                                CommunicationProtocolAssertionDescriptors
                                                    .QuarterlyMeetings.Guid,
                                                EntityTypes.Organization
                                            ).assertionDescriptor.helpText!
                                        }
                                    />
                                ) : null}
                                {(Authorization.userHasRight(
                                    UserRightsEnum.ViewStrategicPlan,
                                    this.props.user
                                ) ||
                                    Authorization.userHasRight(
                                        UserRightsEnum.EditStrategicPlan,
                                        this.props.user
                                    )) &&
                                this.state.significantChanges &&
                                NoteHelper.hasSignificantChange(
                                    this.getEntityAssertionMatch(
                                        CommunicationProtocolAssertionDescriptors.QuarterlyMeetings
                                            .Guid,
                                        EntityTypes.Organization
                                    ).assertionDescriptor.guid!,
                                    this.state.significantChanges
                                ) ? (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={() =>
                                            this.setState({ showSignificantChanges: true })
                                        }
                                    >
                                        <i className="fal fa-lg fa-directions color-gray" />
                                    </button>
                                ) : null}
                            </td>
                            <td>
                                <EntityAssertionControl
                                    assertionDescriptor={
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors
                                                .QuarterlyMeetings.Guid,
                                            EntityTypes.Organization
                                        ).assertionDescriptor
                                    }
                                    entityAssertions={[
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors
                                                .QuarterlyMeetings.Guid,
                                            EntityTypes.Organization
                                        ),
                                    ]}
                                    editMode={!this.state.readOnly}
                                    refreshChange={this.finalizeChange}
                                    handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                                    narrativeFieldAsPopup={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="col-sm-2 text-gray"></td>
                            <td className="col-sm-1 text-gray">
                                Annual
                                {this.getEntityAssertionMatch(
                                    CommunicationProtocolAssertionDescriptors.AnnualMeetings.Guid,
                                    EntityTypes.Organization
                                ).assertionDescriptor.helpText ? (
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Day-to-day"
                                        helpText={
                                            this.getEntityAssertionMatch(
                                                CommunicationProtocolAssertionDescriptors
                                                    .AnnualMeetings.Guid,
                                                EntityTypes.Organization
                                            ).assertionDescriptor.helpText!
                                        }
                                    />
                                ) : null}
                                {(Authorization.userHasRight(
                                    UserRightsEnum.ViewStrategicPlan,
                                    this.props.user
                                ) ||
                                    Authorization.userHasRight(
                                        UserRightsEnum.EditStrategicPlan,
                                        this.props.user
                                    )) &&
                                this.state.significantChanges &&
                                NoteHelper.hasSignificantChange(
                                    this.getEntityAssertionMatch(
                                        CommunicationProtocolAssertionDescriptors.AnnualMeetings
                                            .Guid,
                                        EntityTypes.Organization
                                    ).assertionDescriptor.guid!,
                                    this.state.significantChanges
                                ) ? (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={() =>
                                            this.setState({ showSignificantChanges: true })
                                        }
                                    >
                                        <i className="fal fa-lg fa-directions color-gray" />
                                    </button>
                                ) : null}
                            </td>
                            <td>
                                <EntityAssertionControl
                                    assertionDescriptor={
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors.AnnualMeetings
                                                .Guid,
                                            EntityTypes.Organization
                                        ).assertionDescriptor
                                    }
                                    entityAssertions={[
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors.AnnualMeetings
                                                .Guid,
                                            EntityTypes.Organization
                                        ),
                                    ]}
                                    editMode={!this.state.readOnly}
                                    refreshChange={this.finalizeChange}
                                    handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                                    narrativeFieldAsPopup={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="col-sm-2 text-gray">Reports</td>
                            <td className="col-sm-1 text-gray">
                                Week
                                {this.getEntityAssertionMatch(
                                    CommunicationProtocolAssertionDescriptors.WeeklyReports.Guid,
                                    EntityTypes.Organization
                                ).assertionDescriptor.helpText ? (
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Day-to-day"
                                        helpText={
                                            this.getEntityAssertionMatch(
                                                CommunicationProtocolAssertionDescriptors
                                                    .WeeklyReports.Guid,
                                                EntityTypes.Organization
                                            ).assertionDescriptor.helpText!
                                        }
                                    />
                                ) : null}
                                {(Authorization.userHasRight(
                                    UserRightsEnum.ViewStrategicPlan,
                                    this.props.user
                                ) ||
                                    Authorization.userHasRight(
                                        UserRightsEnum.EditStrategicPlan,
                                        this.props.user
                                    )) &&
                                this.state.significantChanges &&
                                NoteHelper.hasSignificantChange(
                                    this.getEntityAssertionMatch(
                                        CommunicationProtocolAssertionDescriptors.WeeklyReports
                                            .Guid,
                                        EntityTypes.Organization
                                    ).assertionDescriptor.guid!,
                                    this.state.significantChanges
                                ) ? (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={() =>
                                            this.setState({ showSignificantChanges: true })
                                        }
                                    >
                                        <i className="fal fa-lg fa-directions color-gray" />
                                    </button>
                                ) : null}
                            </td>
                            <td>
                                <EntityAssertionControl
                                    assertionDescriptor={
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors.WeeklyReports
                                                .Guid,
                                            EntityTypes.Organization
                                        ).assertionDescriptor
                                    }
                                    entityAssertions={[
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors.WeeklyReports
                                                .Guid,
                                            EntityTypes.Organization
                                        ),
                                    ]}
                                    editMode={!this.state.readOnly}
                                    refreshChange={this.finalizeChange}
                                    handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                                    narrativeFieldAsPopup={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="col-sm-2 text-gray"></td>
                            <td className="col-sm-1 text-gray">
                                Month
                                {this.getEntityAssertionMatch(
                                    CommunicationProtocolAssertionDescriptors.MonthlyReports.Guid,
                                    EntityTypes.Organization
                                ).assertionDescriptor.helpText ? (
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Day-to-day"
                                        helpText={
                                            this.getEntityAssertionMatch(
                                                CommunicationProtocolAssertionDescriptors
                                                    .MonthlyReports.Guid,
                                                EntityTypes.Organization
                                            ).assertionDescriptor.helpText!
                                        }
                                    />
                                ) : null}
                                {(Authorization.userHasRight(
                                    UserRightsEnum.ViewStrategicPlan,
                                    this.props.user
                                ) ||
                                    Authorization.userHasRight(
                                        UserRightsEnum.EditStrategicPlan,
                                        this.props.user
                                    )) &&
                                this.state.significantChanges &&
                                NoteHelper.hasSignificantChange(
                                    this.getEntityAssertionMatch(
                                        CommunicationProtocolAssertionDescriptors.MonthlyReports
                                            .Guid,
                                        EntityTypes.Organization
                                    ).assertionDescriptor.guid!,
                                    this.state.significantChanges
                                ) ? (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={() =>
                                            this.setState({ showSignificantChanges: true })
                                        }
                                    >
                                        <i className="fal fa-lg fa-directions color-gray" />
                                    </button>
                                ) : null}
                            </td>
                            <td>
                                <EntityAssertionControl
                                    assertionDescriptor={
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors.MonthlyReports
                                                .Guid,
                                            EntityTypes.Organization
                                        ).assertionDescriptor
                                    }
                                    entityAssertions={[
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors.MonthlyReports
                                                .Guid,
                                            EntityTypes.Organization
                                        ),
                                    ]}
                                    editMode={!this.state.readOnly}
                                    refreshChange={this.finalizeChange}
                                    handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                                    narrativeFieldAsPopup={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="col-sm-2 text-gray"></td>
                            <td className="col-sm-1 text-gray">
                                Quarter
                                {this.getEntityAssertionMatch(
                                    CommunicationProtocolAssertionDescriptors.QuarterlyReports.Guid,
                                    EntityTypes.Organization
                                ).assertionDescriptor.helpText ? (
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Day-to-day"
                                        helpText={
                                            this.getEntityAssertionMatch(
                                                CommunicationProtocolAssertionDescriptors
                                                    .QuarterlyReports.Guid,
                                                EntityTypes.Organization
                                            ).assertionDescriptor.helpText!
                                        }
                                    />
                                ) : null}
                                {(Authorization.userHasRight(
                                    UserRightsEnum.ViewStrategicPlan,
                                    this.props.user
                                ) ||
                                    Authorization.userHasRight(
                                        UserRightsEnum.EditStrategicPlan,
                                        this.props.user
                                    )) &&
                                this.state.significantChanges &&
                                NoteHelper.hasSignificantChange(
                                    this.getEntityAssertionMatch(
                                        CommunicationProtocolAssertionDescriptors.QuarterlyReports
                                            .Guid,
                                        EntityTypes.Organization
                                    ).assertionDescriptor.guid!,
                                    this.state.significantChanges
                                ) ? (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={() =>
                                            this.setState({ showSignificantChanges: true })
                                        }
                                    >
                                        <i className="fal fa-lg fa-directions color-gray" />
                                    </button>
                                ) : null}
                            </td>
                            <td>
                                <EntityAssertionControl
                                    assertionDescriptor={
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors
                                                .QuarterlyReports.Guid,
                                            EntityTypes.Organization
                                        ).assertionDescriptor
                                    }
                                    entityAssertions={[
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors
                                                .QuarterlyReports.Guid,
                                            EntityTypes.Organization
                                        ),
                                    ]}
                                    editMode={!this.state.readOnly}
                                    refreshChange={this.finalizeChange}
                                    handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                                    narrativeFieldAsPopup={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="col-sm-2 text-gray"></td>
                            <td className="col-sm-1 text-gray">
                                Annual
                                {this.getEntityAssertionMatch(
                                    CommunicationProtocolAssertionDescriptors.AnnualReports.Guid,
                                    EntityTypes.Organization
                                ).assertionDescriptor.helpText ? (
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Day-to-day"
                                        helpText={
                                            this.getEntityAssertionMatch(
                                                CommunicationProtocolAssertionDescriptors
                                                    .AnnualReports.Guid,
                                                EntityTypes.Organization
                                            ).assertionDescriptor.helpText!
                                        }
                                    />
                                ) : null}
                                {(Authorization.userHasRight(
                                    UserRightsEnum.ViewStrategicPlan,
                                    this.props.user
                                ) ||
                                    Authorization.userHasRight(
                                        UserRightsEnum.EditStrategicPlan,
                                        this.props.user
                                    )) &&
                                this.state.significantChanges &&
                                NoteHelper.hasSignificantChange(
                                    this.getEntityAssertionMatch(
                                        CommunicationProtocolAssertionDescriptors.AnnualReports
                                            .Guid,
                                        EntityTypes.Organization
                                    ).assertionDescriptor.guid!,
                                    this.state.significantChanges
                                ) ? (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={() =>
                                            this.setState({ showSignificantChanges: true })
                                        }
                                    >
                                        <i className="fal fa-lg fa-directions color-gray" />
                                    </button>
                                ) : null}
                            </td>
                            <td>
                                <EntityAssertionControl
                                    assertionDescriptor={
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors.AnnualReports
                                                .Guid,
                                            EntityTypes.Organization
                                        ).assertionDescriptor
                                    }
                                    entityAssertions={[
                                        this.getEntityAssertionMatch(
                                            CommunicationProtocolAssertionDescriptors.AnnualReports
                                                .Guid,
                                            EntityTypes.Organization
                                        ),
                                    ]}
                                    editMode={!this.state.readOnly}
                                    refreshChange={this.finalizeChange}
                                    handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                                    narrativeFieldAsPopup={true}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                {this.state.currentNarrativeCaseAssertionOnEditMode ? (
                    <Modal
                        centered
                        show={true}
                        onHide={() =>
                            this.setState({ currentNarrativeCaseAssertionOnEditMode: undefined })
                        }
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>
                                {
                                    this.state.currentNarrativeCaseAssertionOnEditMode
                                        .assertionDescriptor!.displayName
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                            <div>
                                <textarea
                                    className="form-control"
                                    value={
                                        this.state.currentNarrativeCaseAssertionOnEditMode.textValue
                                    }
                                    name={
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            .assertionDescriptor!.guid
                                    }
                                    rows={
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            .entityType &&
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            .entityType.id === EntityTypes.OperatingMechanism
                                            ? 4
                                            : 15
                                    }
                                    maxLength={
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            .entityType &&
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            .entityType.id === EntityTypes.OperatingMechanism
                                            ? 300
                                            : -1
                                    }
                                    onChange={this.handleNarrativeFieldTextChange}
                                />
                            </div>
                            <div className="dialog-btn-div ">
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={() =>
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            ? this.finalizeChange([
                                                  this.state
                                                      .currentNarrativeCaseAssertionOnEditMode,
                                              ])
                                            : {}
                                    }
                                >
                                    OK
                                </button>
                                <button
                                    className="btn btn-default float-end "
                                    onClick={() =>
                                        this.setState({
                                            currentNarrativeCaseAssertionOnEditMode: undefined,
                                        })
                                    }
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.handleClearNarrativeFieldEdit}
                                >
                                    Clear
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                {this.state.showSignificantChanges ? (
                    <Modal
                        centered
                        size="xl"
                        show={true}
                        onHide={() =>
                            this.setState({
                                showSignificantChanges: false,
                                selectedSignificantChange: undefined,
                                openNoteEditor: false,
                            })
                        }
                        backdrop={false}
                        dialogClassName="add-task-dialog"
                    >
                        <Modal.Header>
                            <Modal.Title className="w-100">
                                <div className="row">
                                    <div className="col-sm-6">Significant Changes</div>
                                    <div className="col-sm-6">
                                        {Authorization.userHasRight(
                                            UserRightsEnum.EditStrategicPlan,
                                            this.props.user
                                        ) ? (
                                            <button
                                                className="btn-no-bg float-end  "
                                                onClick={this.handleAddNote}
                                            >
                                                {' '}
                                                <span className="btn-green btn float-end btn-icon">
                                                    <i className="fal fa-lg fa-plus color-white" />
                                                </span>
                                            </button>
                                        ) : null}
                                        <ExportFile
                                            url={ApiRoutes.ExportReport}
                                            reportType={{
                                                id: ReportTypesEnum.StrategySignificantChanges
                                                    .Value,
                                            }}
                                            reportParameters={{
                                                reportType: {
                                                    id: ReportTypesEnum.StrategySignificantChanges
                                                        .Value,
                                                },
                                                options: {
                                                    typeId: NoteTypes.StrategySignificantChange,
                                                    key: NoteCategories.StrategySignificantChanges.toString(),
                                                },
                                            }}
                                            handleError={this.handleSignificantChangesExportError}
                                        />
                                    </div>
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <table className="table">
                                <thead>
                                    <tr style={{ verticalAlign: 'middle' }}>
                                        <th className="col-sm-2 ">Change</th>
                                        <SortableHeader
                                            headerText="Date"
                                            sortKey="date"
                                            onSort={this.sortData}
                                            thClassName="col-sm-1"
                                        />
                                        <th>Impact</th>
                                        <th className="col-sm-2 ">Associated Topic(s)</th>
                                        {Authorization.userHasRight(
                                            UserRightsEnum.EditStrategicPlan,
                                            this.props.user
                                        ) && <th />}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.significantChanges.map((item: INoteModel) => {
                                        return (
                                            <tr key={item.guid}>
                                                <td className="col-sm-2">
                                                    {NoteHelper.getAssertionValue(item, 'CHANGE')}
                                                </td>
                                                <td className="col-sm-1  ">
                                                    {Common.dateFormat(
                                                        NoteHelper.getAssertionValue(item, 'DATE')
                                                    )}
                                                </td>
                                                <td>
                                                    {item.content!.split('\n').map((item, key) => {
                                                        return (
                                                            <span key={key}>
                                                                {item}
                                                                <br />
                                                            </span>
                                                        );
                                                    })}
                                                </td>
                                                <td className="col-sm-3  ">
                                                    {NoteHelper.getAssociatedTopicsForSignificantChanges(
                                                        item
                                                    )
                                                        .split('\n')
                                                        .map((item, key) => {
                                                            return (
                                                                <span key={key}>
                                                                    {item}
                                                                    <br />
                                                                </span>
                                                            );
                                                        })}
                                                </td>
                                                {Authorization.userHasRight(
                                                    UserRightsEnum.EditStrategicPlan,
                                                    this.props.user
                                                ) ? (
                                                    <td className="col-sm-1">
                                                        <button
                                                            className="btn btn-no-bg float-end"
                                                            onClick={(
                                                                e: React.MouseEvent<HTMLButtonElement>
                                                            ) => {
                                                                this.showConfirmRemoveDailog(item);
                                                            }}
                                                        >
                                                            <i className="fal fa-lg fa-trash-alt"></i>
                                                        </button>

                                                        {Authorization.userHasRight(
                                                            UserRightsEnum.EditCaseNote,
                                                            this.props.user
                                                        ) &&
                                                        (this.state.loggedInUserDataScopeId !==
                                                            DataScopesEnum.LocalBasic.Value ||
                                                            item.allowLocalEdit) ? (
                                                            <img
                                                                className="view-edit-btn float-end"
                                                                src="/images/ViewEditIcon.svg"
                                                                onClick={(e: any) => {
                                                                    this.handleEditNote(
                                                                        e,
                                                                        item.guid!
                                                                    );
                                                                }}
                                                            />
                                                        ) : (
                                                            <button
                                                                className="btn btn-no-bg float-end"
                                                                onClick={(
                                                                    e: React.MouseEvent<HTMLButtonElement>
                                                                ) => {
                                                                    this.handleEditNote(
                                                                        e,
                                                                        item.guid!
                                                                    );
                                                                }}
                                                            >
                                                                <i className="fal fa-lg fa-eye" />
                                                            </button>
                                                        )}
                                                    </td>
                                                ) : null}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div className="dialog-btn-div ">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({
                                            showSignificantChanges: false,
                                            selectedSignificantChange: undefined,
                                            openNoteEditor: false,
                                        })
                                    }
                                >
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                <CheckmateDialog
                    isShowingModal={this.state.showConfirmRemoveSignificantChangeDialog || false}
                    body="Are you sure you want to delete the selected item?"
                    handleClose={this.cancelRemoveSignificantChange}
                    handleConfirm={this.deleteNote}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end "
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </div>
        );
    }
}
