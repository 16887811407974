import { IUserModel } from '../../interfaces/IUser';
import { useLocation } from 'react-router';
import { ProjectList } from '../strategy/ProjectList';

function ProjectListWrapper(props: { user: IUserModel }) {
    const { key } = useLocation();
    return <ProjectList user={props.user} refreshIdentifier={key} />;
}

export default ProjectListWrapper;
