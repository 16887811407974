import CheckmateSelect from '../../components/shared/CheckmateSelect';
import { IQueryFilterCriteria } from '../../interfaces/IQuery';
import { IValidation } from '../../interfaces/IError';
import { Modal } from 'react-bootstrap';

interface IQueryExportModalProps {
    availableColumnsForExport: IQueryFilterCriteria[];
    open: boolean;
    onExportColumnsChange: (optionsList: any) => void;
    onHide: () => void;
    onSubmit: () => void;
    selectedColumns: IQueryFilterCriteria[];
    validation: IValidation;
}

export function QueryExportModal(props: IQueryExportModalProps) {
    return (
        <Modal centered show={props.open} onHide={props.onHide} backdrop={false}>
            <Modal.Header>
                <Modal.Title>Export</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.validation.export && (
                    <div className="text-danger mb-3">{props.validation.export}</div>
                )}
                <div className="row mb-2">
                    <>
                        <CheckmateSelect
                            isMulti={true}
                            options={props.availableColumnsForExport}
                            value={props.selectedColumns}
                            onChange={props.onExportColumnsChange}
                        />
                    </>
                </div>

                <div className="row mb-4">
                    <div className="col-sm-6">
                        <input id="chkSave" className="form-check-input" type="checkbox" />
                        <span>&nbsp;&nbsp;Save Query Report</span>
                    </div>
                    <div className="col-sm-6">
                        <button className="btn btn-orange float-end" onClick={props.onSubmit}>
                            Run
                        </button>
                        <button className="btn btn-default float-end me-2" onClick={props.onHide}>
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
