import { CaseEasyUpdate, ICaseEasyUpdateProps } from '../case/CaseEasyUpdate';

import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

function CaseEasyUpdateWrapper(props: ICaseEasyUpdateProps) {
    const { key } = useLocation();
    return <CaseEasyUpdate user={props.user} refreshIdentifier={key} navigate={useNavigate()} />;
}

export default CaseEasyUpdateWrapper;
