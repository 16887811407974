import * as React from 'react';

import {
    AlertListSearchStatusOptionsEnum,
    AlertListTypeEnum,
    AlertOriginTypesEnum,
    AlertTypes,
    ApplicationHelpType,
    DataScopesEnum,
    EntityTypes,
    ListMode,
    LookupDataEnums,
    MyAlertListSearchStatusOptions,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    Operation,
    SortColumns,
    SortDirections,
    StrategyTypes,
    TutorialTopics,
    UserRightsEnum,
} from '../../utilities/Constants';
import { ICaseModel, ICaseTitleModel } from '../../interfaces/ICase';
import { ILookupModel, IMultiSelectOptions } from '../../interfaces/ILookup';
import {
    INoteCategoryTypeModel,
    INoteModel,
    INoteSubCategoryTypeModel,
} from '../../interfaces/INote';

import AlertListRow from './AlertListRow';
import Auth from '../../stores/authentication';
import Authorization from '../../stores/Authorization';
import { CaseEasyUpdate } from '../case/CaseEasyUpdate';
import CheckmateApiClient from '../../services/apiClient';
import CheckmateNSelect from '../../components/shared/CheckmateNSelect';
import { Favorite } from '../../components/shared/Favorite';
import { Help } from '../../components/shared/Help';
import { INoteUISearchModel } from '../../interfaces/IQuery';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import { NoteEditor } from '../../components/notes/NoteEditor';
import NoteHelper from '../../utilities/NoteHelper';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../../components/shared/SortableHeader';
import ValidateUtils from '../../shared/validations';
import { cloneDeep } from 'lodash';

const _apiClient = new CheckmateApiClient();

const PageSize = 100;
const maxZoneAlertListCount = 1000;

interface IAlertListProps {
    user: IUserModel;
    alertListType: number;
    refreshIdentifier: string;
}

interface IAlertListState {
    pendingResponse: boolean;
    alertSearchParameters: INoteUISearchModel;
    validation: IValidation;
    types: IMultiSelectOptions[];
    noteStatusTypes: IMultiSelectOptions[];
    users: IMultiSelectOptions[];
    purposes: IMultiSelectOptions[];
    noteCategoryTypes: INoteCategoryTypeModel[];
    noteTypes: IMultiSelectOptions[];

    selectedAlertOriginTypesOptions: IMultiSelectOptions[];
    selectedMyAlertListStatusOptions: IMultiSelectOptions[];
    selectedNoteStatusOptions: IMultiSelectOptions[];
    selectedTypeOptions: IMultiSelectOptions[];
    selectedUserOptions: IMultiSelectOptions[];
    selectedNoteCategoryOptions: IMultiSelectOptions[];
    selectedNoteSubCategoryOptions: IMultiSelectOptions[];
    selectedPurposeOptions: IMultiSelectOptions[];

    alertList: INoteModel[];
    //totalCount: number;
    loggedInUserDataScopeId: number;
    openNoteEditor: boolean;
    currentNote: INoteModel;
    currentFavoriteDetailsString: string;
    userDefaultSettingsJson?: string;

    enableEasyUpdate: boolean;
    easyUpdateMode: boolean;
    easyUpdateCaseGuid?: string;
    easyUpdateAttemptedCaseList: string[];

    showTooManyRecordsToProcessDialog?: boolean;
}

interface ISortableNoteModel extends INoteModel {
    allowLocalEditSortOrder: number;
}

export class AlertList extends React.Component<IAlertListProps, IAlertListState> {
    constructor(props: IAlertListProps) {
        super(props);

        const selectedAlertOriginTypesOptions: IMultiSelectOptions[] = [];

        this.state = {
            pendingResponse: true,
            alertSearchParameters: {
                alertListType: props.alertListType,
                queryModel: {
                    pageNumber: 0,
                    pageSize: PageSize,
                    sortBy: SortColumns.NoteDate,
                    sortDirection: SortDirections.Desc,
                },
            },
            validation: {},
            types: [],
            noteStatusTypes: [],
            noteTypes: [],
            users: [],
            purposes: [],
            noteCategoryTypes: [],
            selectedAlertOriginTypesOptions,
            selectedMyAlertListStatusOptions: this.getDefaultStatuses(),
            selectedNoteStatusOptions: [],
            selectedTypeOptions: [],
            selectedUserOptions: [],
            selectedNoteCategoryOptions: [],
            selectedNoteSubCategoryOptions: [],
            selectedPurposeOptions: [],
            alertList: [],
            //totalCount: 0,
            loggedInUserDataScopeId: 0,
            openNoteEditor: false,
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            enableEasyUpdate: false,
            easyUpdateMode: false,
            easyUpdateAttemptedCaseList: [],
            currentFavoriteDetailsString: '',
        };
    }

    defaultOriginTypes = [AlertOriginTypesEnum.CaseNote, AlertOriginTypesEnum.StrategyNote];

    componentDidMount() {
        if (Authorization.isAuthorizedToRoute(LocalRoutes.Alerts, this.props.user)) {
            if (
                this.props.alertListType === AlertListTypeEnum.MyAlertList ||
                this.props.alertListType === AlertListTypeEnum.ZoneAlertList
            ) {
                this.loadInitialLookupData();
                return;
            }
        }
        window.location.assign(LocalRoutes.AccessDenied);
    }

    componentDidUpdate(prevProps: IAlertListProps, prevState: IAlertListState) {
        if (prevProps.refreshIdentifier != this.props.refreshIdentifier)
            this.setState(
                {
                    pendingResponse: true,
                    validation: {},
                    types: [],
                    noteStatusTypes: [],
                    users: [],
                    purposes: [],
                    noteCategoryTypes: [],
                    selectedMyAlertListStatusOptions: [],
                    selectedNoteStatusOptions: [],
                    selectedTypeOptions: [],
                    selectedUserOptions: [],
                    selectedNoteCategoryOptions: [],
                    selectedNoteSubCategoryOptions: [],
                    selectedPurposeOptions: [],
                    alertList: [],
                    //totalCount: 0,
                    loggedInUserDataScopeId: 0,
                    openNoteEditor: false,
                    currentNote: {
                        status: { id: NoteStatusTypes.Open },
                        purpose: { id: NotePurposeTypesEnum.Info },
                    },
                    enableEasyUpdate: false,
                    easyUpdateMode: false,
                    easyUpdateAttemptedCaseList: [],
                    currentFavoriteDetailsString: '',
                },
                () => this.loadInitialLookupData()
            );

        if (
            prevState.selectedAlertOriginTypesOptions.length === 1 &&
            this.state.selectedAlertOriginTypesOptions.length !== 1
        ) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { noteCategories, noteSubCategories, ...rest } = this.state.alertSearchParameters;

            this.setState((prev) => ({
                ...prev,
                selectedNoteCategoryOptions: [],
                selectedNoteSubCategoryOptions: [],
                alertSearchParameters: rest,
            }));
        }
    }

    loadInitialLookupData = async () => {
        const typesDropdownValuesAll: IMultiSelectOptions[] = [
            { id: AlertTypes.Act.Value, label: AlertTypes.Act.Name, value: AlertTypes.Act.Name },
            {
                id: AlertTypes.Watch.Value,
                label: AlertTypes.Watch.Name,
                value: AlertTypes.Watch.Name,
            },
        ];

        const users = await this.fetchAllUsers();
        if (!users) return;

        let usersDropdownValuesAll: IMultiSelectOptions[] = [];
        const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(
            this.props.user
        );
        const defaultOrg = Auth.getUserDefaultOrganization();
        if (defaultOrg) {
            if (
                loggedInUserDataScopeId === DataScopesEnum.LocalBasic.Value &&
                this.props.user.homeOrganization
            ) {
                for (let i = 0; i < users.length; i++) {
                    const currentItem = users[i];
                    if (
                        Authorization.getUserDefaultOrganizationDataScope(currentItem) ===
                        DataScopesEnum.National.Value
                    ) {
                        const item: IMultiSelectOptions = {
                            label:
                                currentItem.profile!.firstName +
                                ' ' +
                                currentItem.profile!.lastName +
                                (currentItem.activeZoneDataScope &&
                                currentItem.activeZoneDataScope!.id ===
                                    DataScopesEnum.LocalBasic.Value
                                    ? ' (Local)'
                                    : ''),
                            value: currentItem.guid!,
                            id: 0,
                            guid: currentItem.guid,
                        };
                        usersDropdownValuesAll.push(item);
                    }
                }

                users
                    .filter(
                        (x) =>
                            x.homeOrganization &&
                            this.props.user.homeOrganization &&
                            x.homeOrganization.guid === this.props.user.homeOrganization.guid
                    )
                    .map((user: IUserModel) => {
                        const item: IMultiSelectOptions = {
                            label:
                                user.profile!.firstName +
                                ' ' +
                                user.profile!.lastName +
                                (user.activeZoneDataScope &&
                                user.activeZoneDataScope.id === DataScopesEnum.LocalBasic.Value
                                    ? ' (Local)'
                                    : ''),
                            value: user.guid!,
                            id: 0,
                            guid: user.guid,
                        };
                        usersDropdownValuesAll.push(item);
                    });
            } else {
                for (let i = 0; i < users.length; i++) {
                    usersDropdownValuesAll.push({
                        label:
                            users[i].profile!.firstName +
                            ' ' +
                            users[i].profile!.lastName +
                            (users[i].activeZoneDataScope &&
                            users[i].activeZoneDataScope!.id === DataScopesEnum.LocalBasic.Value
                                ? ' (Local)'
                                : ''),
                        value: users[i].guid!,
                        guid: users[i].guid!,
                        id: 0,
                    });
                }
            }
        }
        usersDropdownValuesAll = usersDropdownValuesAll.sort(Sort.compareValues('label'));

        const noteStatusTypes = await this.fetchNoteStatusTypes();
        if (!noteStatusTypes) return;

        const noteStatusTypesDropdownValuesAll: IMultiSelectOptions[] = [];
        for (let i = 0; i < noteStatusTypes.length; i++) {
            noteStatusTypesDropdownValuesAll.push({
                id: noteStatusTypes[i].id,
                label: noteStatusTypes[i].displayName!,
                value: noteStatusTypes[i].name!,
            });
        }

        const notePurposeTypes = await this.fetchNotePurposeTypes();
        if (!notePurposeTypes) return;

        const notePurposeTypesDropdownValuesAll: IMultiSelectOptions[] = [];
        for (
            let i = 0;
            i < notePurposeTypes.filter((x) => x.id !== NotePurposeTypesEnum.Info).length;
            i++
        ) {
            notePurposeTypesDropdownValuesAll.push({
                id: notePurposeTypes[i].id,
                label: notePurposeTypes[i].displayName!,
                value: notePurposeTypes[i].name!,
            });
        }

        const noteCategoryTypes = await this.fetchNoteCategoryTypes();
        if (!noteCategoryTypes) return;

        const noteCategoryTypesDropdownValuesAll: IMultiSelectOptions[] = [];
        for (let i = 0; i < noteCategoryTypes.length; i++) {
            noteCategoryTypesDropdownValuesAll.push({
                id: noteCategoryTypes[i].id,
                label: noteCategoryTypes[i].displayName!,
                value: noteCategoryTypes[i].name!,
            });
        }

        let noteTypes = await this.fetchNoteTypes();
        if (!noteTypes) return;

        const isLocalBasicUser =
            Authorization.getUserDefaultOrganizationDataScope(this.props.user) ===
            DataScopesEnum.LocalBasic.Value;

        noteTypes = noteTypes.filter(
            (noteType) =>
                noteType.id !== NoteTypes.Personal &&
                noteType.id !== NoteTypes.StrategySignificantChange
        );

        if (isLocalBasicUser) {
            noteTypes = noteTypes.filter(
                (noteType) =>
                    noteType.id !== NoteTypes.StrategyNote &&
                    noteType.id !== NoteTypes.PackageSettlementNote
            );
        }

        let noteTypesDropdownValuesAll: IMultiSelectOptions[] = noteTypes.map(
            (item: ILookupModel) => ({
                id: item.id,
                label: item.displayName!,
                value: item.name!,
            })
        );

        if (!Authorization.userHasRight(UserRightsEnum.ViewCaseNotes, this.props.user)) {
            noteTypesDropdownValuesAll = noteTypesDropdownValuesAll.filter(
                (x) => x.id != NoteTypes.CaseNote
            );
        }

        if (!Authorization.userHasRight(UserRightsEnum.ViewZoneNotes, this.props.user)) {
            noteTypesDropdownValuesAll = noteTypesDropdownValuesAll.filter(
                (x) => x.id != NoteTypes.ZoneNote
            );
        }

        if (!Authorization.userHasRight(UserRightsEnum.ViewPackageDealNote, this.props.user)) {
            noteTypesDropdownValuesAll = noteTypesDropdownValuesAll.filter(
                (x) => x.id != NoteTypes.PackageSettlementNote
            );
        }

        if (!Authorization.userHasViewStrategyNotesRight(this.props.user)) {
            noteTypesDropdownValuesAll = noteTypesDropdownValuesAll.filter(
                (x) => x.id != NoteTypes.StrategyNote
            );
        }

        const userDefaultSettingsJson = await this.fetchUserDefaultSettingsJson();
        this.setState(
            {
                //pendingResponse: false,
                loggedInUserDataScopeId: loggedInUserDataScopeId,
                types: typesDropdownValuesAll,
                users: usersDropdownValuesAll,
                purposes: notePurposeTypesDropdownValuesAll,
                noteTypes: noteTypesDropdownValuesAll,
                noteCategoryTypes: noteCategoryTypes,
                noteStatusTypes: noteStatusTypesDropdownValuesAll,
                userDefaultSettingsJson: userDefaultSettingsJson,
                selectedAlertOriginTypesOptions: noteTypesDropdownValuesAll.filter(
                    (opt) =>
                        [
                            NoteTypes.CaseNote,
                            NoteTypes.StrategyNote,
                            NoteTypes.ZoneNote,
                            NoteTypes.PackageSettlementNote,
                        ].indexOf(opt.id) > -1
                ),
            },
            () => {
                this.loadAlertList(ListMode.UserDefault);
            }
        );
    };

    fetchUserDefaultSettingsJson = async () => {
        const defaultFavoriteResponse = await _apiClient.getFavorites(
            this.props.alertListType || AlertListTypeEnum.MyAlertList,
            true
        );
        if (defaultFavoriteResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }

        if (defaultFavoriteResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    defaultFavoriteResponse.errors,
                    defaultFavoriteResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        return defaultFavoriteResponse.payload &&
            defaultFavoriteResponse.payload.length > 0 &&
            defaultFavoriteResponse.payload[0].settingJson
            ? defaultFavoriteResponse.payload[0].settingJson!
            : '';
    };

    fetchCaseStatuses = async () => {
        const response = await _apiClient.getCaseStatusLookupData();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchAllUsers = async () => {
        const response = await _apiClient.getUsersInZone();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchNoteCategoryTypes = async () => {
        const response = await _apiClient.getAllNoteCategoryTypes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchNoteTypes = async () => {
        const response = await _apiClient.getLookupData(LookupDataEnums.NoteTypes);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchNoteStatusTypes = async () => {
        const response = await _apiClient.getAllNoteStatusTypes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchNotePurposeTypes = async () => {
        const response = await _apiClient.getAllNotePurposeTypes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    getNoteCategoryTypeOptions = () => {
        if (this.state.selectedAlertOriginTypesOptions.length !== 1) {
            return [];
        }

        const noteTypeId = this.state.selectedAlertOriginTypesOptions[0].id; // Temporarily only Case Notes.
        let noteCategoryTypeOptions: IMultiSelectOptions[] = [];
        this.state.noteCategoryTypes
            .filter((x) => x.noteType.id === noteTypeId)
            .map((cat: ILookupModel) => {
                noteCategoryTypeOptions.push({
                    label: cat.displayName!,
                    value: cat.name!,
                    id: cat.id,
                });
            });
        noteCategoryTypeOptions = noteCategoryTypeOptions.sort(Sort.compareValues('label'));
        return noteCategoryTypeOptions;
    };

    getNoteSubCategoryTypeOptions = () => {
        let noteSubCategoryTypeOptions: IMultiSelectOptions[] = [];

        if (this.state.selectedNoteCategoryOptions.length > 0) {
            this.state.selectedNoteCategoryOptions.map((cat: IMultiSelectOptions) => {
                const match = this.state.noteCategoryTypes.find((x) => x.id === cat.id);
                if (match && match.subCategories && match.subCategories.length > 0) {
                    match.subCategories.map((item: INoteSubCategoryTypeModel) => {
                        noteSubCategoryTypeOptions.push({
                            label: item.displayName!,
                            value: item.name!,
                            id: item.id,
                            parentId: match.id,
                        });
                    });
                }
            });
        }

        noteSubCategoryTypeOptions = noteSubCategoryTypeOptions.sort(Sort.compareValues('label'));
        return noteSubCategoryTypeOptions;
    };

    myAlertListStatusFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParameters = { ...this.state.alertSearchParameters };
        alertSearchParameters.alertListSearchStatusOptions = [];
        if (optionsList.length > 0) {
            optionsList.map((item: IMultiSelectOptions) => {
                alertSearchParameters.alertListSearchStatusOptions!.push(item.id);
            });
        }

        this.setState((prevState) => ({
            ...prevState,
            selectedMyAlertListStatusOptions: optionsList,
            alertSearchParameters,
            selectedNoteCategoryOptions: [],
            selectedNoteSubCategoryOptions: [],
        }));
    };

    onNoteStatusFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParameters = this.state.alertSearchParameters;
        alertSearchParameters.noteStatusIds = [];
        if (optionsList.length > 0) {
            optionsList.map((item: IMultiSelectOptions) => {
                alertSearchParameters.noteStatusIds!.push(item.id);
            });
        }
        this.setState({ selectedNoteStatusOptions: optionsList });
    };

    onTypeFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParameters = this.state.alertSearchParameters;
        alertSearchParameters.alertTypeIds = [];
        if (optionsList.length > 0) {
            optionsList.map((item: IMultiSelectOptions) => {
                alertSearchParameters.alertTypeIds!.push(item.id);
            });
        }
        this.setState({ selectedTypeOptions: optionsList });
    };

    onUserFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParameters = this.state.alertSearchParameters;
        alertSearchParameters.alertUserGuids = [];
        if (optionsList.length > 0) {
            optionsList.map((item: IMultiSelectOptions) => {
                alertSearchParameters.alertUserGuids!.push(item.guid!);
            });
        }
        this.setState({
            selectedUserOptions: optionsList,
            alertSearchParameters,
        });
    };

    onNoteCategoryFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParameters = this.state.alertSearchParameters;
        alertSearchParameters.noteCategories = [];
        if (optionsList.length > 0) {
            optionsList.map((item: IMultiSelectOptions) => {
                alertSearchParameters.noteCategories!.push(item);
            });
        }
        this.setState({
            selectedNoteCategoryOptions: optionsList,
            alertSearchParameters,
        });
    };

    onNoteSubCategoryFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParameters = this.state.alertSearchParameters;
        alertSearchParameters.noteSubCategories = [];
        if (optionsList.length > 0) {
            optionsList.map((item: IMultiSelectOptions) => {
                alertSearchParameters.noteSubCategories!.push(item);
            });
        }
        this.setState({
            selectedNoteSubCategoryOptions: optionsList,
            alertSearchParameters,
        });
    };

    onPurposeFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParameters = this.state.alertSearchParameters;
        alertSearchParameters.purpose = [];
        if (optionsList.length > 0) {
            optionsList.map((item: IMultiSelectOptions) => {
                alertSearchParameters.purpose!.push({ id: item.id! });
            });
        }
        this.setState({
            selectedPurposeOptions: optionsList,
            alertSearchParameters,
        });
    };

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        //if (this.state.alertSearchParameters.alertListType === AlertListTypeEnum.MyAlertList) {

        let alertList = this.state.alertList;

        const noteToAllowLocalEditSortableMapper = (note: INoteModel): ISortableNoteModel => {
            let sortOrder = 0;
            if (note.actionTarget?.activeZoneDataScope?.id === DataScopesEnum.LocalBasic.Value) {
                sortOrder = 2;
            } else if (note.allowLocalEdit) {
                sortOrder = 1;
            }

            return {
                ...note,
                allowLocalEditSortOrder: sortOrder,
            };
        };

        if (alertList) {
            if (key.includes('date')) {
                alertList = alertList.sort(Sort.compareDate(key, subKey, order));
            } else if (key === 'allowLocalEdit') {
                const allowLocalEditSortableNotes = alertList.map(
                    noteToAllowLocalEditSortableMapper
                );
                const sortedAlerts = allowLocalEditSortableNotes
                    .sort(Sort.compareValues('allowLocalEditSortOrder', undefined, order))
                    .map((alert) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { allowLocalEditSortOrder, ...note } = alert;
                        return note as INoteModel;
                    });

                alertList = sortedAlerts;
            } else {
                alertList = alertList.sort(Sort.compareValues(key, subKey, order, subGrandKey));
            }
        }

        this.setState({ alertList });
        return;

        /*  }
         else if (this.state.alertSearchParameters.alertListType === AlertListTypeEnum.ZoneAlertList) {
             let alertSearchParameters = this.state.alertSearchParameters;
             if (alertSearchParameters.queryModel) {
                 alertSearchParameters.queryModel.sortBy = this.getSortColumnsEnumFromString(key);
                 alertSearchParameters.queryModel.sortDirection = order === 'desc' ? SortDirections.Desc : SortDirections.Asc;
                 alertSearchParameters.queryModel.pageNumber = 0;
             }
             this.setState({ alertSearchParameters }, () => { this.loadAlertList(Mode.RunSearch) });
         } */
    };

    getSortColumnsEnumFromString = (key: string) => {
        switch (key) {
            case 'caseName':
                return SortColumns.CaseName;
            case 'qScore':
                return SortColumns.QScore;
            case 'private':
                return SortColumns.NotePrivate;
            case 'allowLocalEdit':
                return SortColumns.NoteAllowLocalEdit;
            case 'decisionText':
                return SortColumns.NoteDecision;
            case 'actionTargetName':
                return SortColumns.ActionTarget;
            case 'watchUser':
                return SortColumns.WatchUser;
            case 'author':
                return SortColumns.NoteWriter;
            case 'modifiedDate':
                return SortColumns.NoteDate;
            case 'purpose':
                return SortColumns.NotePurpose;
            case 'status':
                return SortColumns.NoteStatus;
            default:
                return SortColumns.NoteDate;
        }
    };

    loadAlertList = async (
        mode: ListMode,
        excludeFullCount?: boolean,
        settingJson?: string,
        isDefaultSetting?: boolean
    ) => {
        const validation: IValidation = {};
        validation.favorites = this.state.validation.favorites;
        let selectedMyAlertListStatusOptions = this.state.selectedMyAlertListStatusOptions;
        let selectedTypeOptions = this.state.selectedTypeOptions;
        let selectedUserOptions = this.state.selectedUserOptions;
        let selectedNoteCategoryOptions = this.state.selectedNoteCategoryOptions;
        let selectedNoteSubCategoryOptions = this.state.selectedNoteSubCategoryOptions;
        let selectedPurposeOptions = this.state.selectedPurposeOptions;
        let selectedNoteStatusOptions = this.state.selectedNoteStatusOptions;
        let selectedAlertOriginTypesOptions = this.state.selectedAlertOriginTypesOptions;

        let alertSearchParameters = this.state.alertSearchParameters;
        let userDefaultSettingsJson = this.state.userDefaultSettingsJson;

        if (mode === ListMode.RunSearch) {
            if (
                this.props.alertListType === AlertListTypeEnum.ZoneAlertList &&
                this.state.selectedTypeOptions.length > 0 &&
                this.state.selectedUserOptions.length === 0
            ) {
                validation.model = ['Please select a User in order to search by Alert Type'];
                this.setState({ validation: validation });
                return;
            }

            if (
                this.state.selectedMyAlertListStatusOptions.length === 0 &&
                this.state.selectedTypeOptions.length === 0 &&
                this.state.selectedUserOptions.length === 0 &&
                this.state.selectedNoteCategoryOptions.length === 0 &&
                this.state.selectedNoteSubCategoryOptions.length === 0 &&
                this.state.selectedPurposeOptions.length === 0 &&
                this.state.selectedNoteStatusOptions.length === 0
            ) {
                validation.model = ['Enter at least one filter criteria'];
                this.setState({ validation: validation });
                return;
            }

            if (this.state.selectedAlertOriginTypesOptions.length == 0) {
                validation.model = ['Enter at least one type to filter'];
                this.setState({ validation: validation });
                return;
            }
        } else if (
            mode === ListMode.FavoriteSelected ||
            (mode === ListMode.UserDefault && this.state.userDefaultSettingsJson)
        ) {
            if (mode === ListMode.UserDefault) settingJson = this.state.userDefaultSettingsJson!;
            else if (!settingJson) return;

            selectedMyAlertListStatusOptions = [];
            selectedTypeOptions = [];
            selectedUserOptions = [];
            selectedNoteCategoryOptions = [];
            selectedNoteSubCategoryOptions = [];
            selectedPurposeOptions = [];
            selectedNoteStatusOptions = [];

            // const currentAlertSearchParameterString = JSON.stringify(
            //     this.state.alertSearchParameters
            // );
            //if (currentAlertSearchParameterString.trim() === settingJson.trim()) return;
            alertSearchParameters = JSON.parse(settingJson);
            if (alertSearchParameters.queryModel) {
                alertSearchParameters.queryModel.pageNumber = 0;
                alertSearchParameters.queryModel.pageSize = PageSize;
            }

            if (isDefaultSetting) userDefaultSettingsJson = settingJson;

            // Populate all the filters with the filter values from the Favorite that was just selected.
            if (alertSearchParameters.alertListSearchStatusOptions) {
                for (
                    let i = 0;
                    i < alertSearchParameters.alertListSearchStatusOptions.length;
                    i++
                ) {
                    const match = MyAlertListSearchStatusOptions.filter(
                        (x) => x.id == alertSearchParameters.alertListSearchStatusOptions![i]
                    );
                    if (match.length > 0) selectedMyAlertListStatusOptions.push(match[0]);
                }
            }

            if (alertSearchParameters.noteStatusIds) {
                for (let i = 0; i < alertSearchParameters.noteStatusIds.length; i++) {
                    const match = this.state.noteStatusTypes.filter(
                        (x) => x.id == alertSearchParameters.noteStatusIds![i]
                    );
                    if (match.length > 0) selectedNoteStatusOptions.push(match[0]);
                }
            }

            if (alertSearchParameters.alertTypeIds) {
                for (let i = 0; i < alertSearchParameters.alertTypeIds.length; i++) {
                    const match = this.state.types.filter(
                        (x) => x.id == alertSearchParameters.alertTypeIds![i]
                    );
                    if (match.length > 0) selectedTypeOptions.push(match[0]);
                }
            }

            if (alertSearchParameters.alertUserGuids) {
                for (let i = 0; i < alertSearchParameters.alertUserGuids.length; i++) {
                    const match = this.state.users.filter(
                        (x) => x.guid == alertSearchParameters.alertUserGuids![i]
                    );
                    if (match.length > 0) selectedUserOptions.push(match[0]);
                }
            }

            if (
                alertSearchParameters.noteCategories &&
                alertSearchParameters.noteCategories.length > 0
            ) {
                selectedNoteCategoryOptions = JSON.parse(
                    JSON.stringify(alertSearchParameters.noteCategories)
                );
            }

            if (
                alertSearchParameters.noteSubCategories &&
                alertSearchParameters.noteSubCategories.length > 0
            ) {
                selectedNoteSubCategoryOptions = JSON.parse(
                    JSON.stringify(alertSearchParameters.noteSubCategories)
                );
            }

            if (alertSearchParameters.purpose) {
                for (let i = 0; i < alertSearchParameters.purpose.length; i++) {
                    const match = this.state.purposes.filter(
                        (x) => x.id == alertSearchParameters.purpose![i].id
                    );
                    if (match.length > 0) selectedPurposeOptions.push(match[0]);
                }
            }
        } else {
            // Checkmate Default View
            selectedTypeOptions = [];
            selectedUserOptions = [];
            selectedNoteCategoryOptions = [];
            selectedNoteSubCategoryOptions = [];
            selectedPurposeOptions = [];
            selectedNoteStatusOptions = [];
            selectedAlertOriginTypesOptions = this.state.noteTypes.filter(
                (opt) =>
                    [
                        NoteTypes.CaseNote,
                        NoteTypes.StrategyNote,
                        NoteTypes.ZoneNote,
                        NoteTypes.PackageSettlementNote,
                    ].indexOf(opt.id) > -1
            );

            alertSearchParameters = {
                alertListType: this.props.alertListType,
                noteTypes: selectedAlertOriginTypesOptions,
                queryModel: { pageSize: PageSize, pageNumber: 0, sortBy: 0, sortDirection: 0 },
            };

            if (this.props.alertListType === AlertListTypeEnum.ZoneAlertList) {
                alertSearchParameters.noteStatusIds = [NoteStatusTypes.Open];
                selectedNoteStatusOptions = this.state.noteStatusTypes.filter(
                    (x) => x.id === NoteStatusTypes.Open
                );
            } else {
                alertSearchParameters.alertListSearchStatusOptions = [
                    AlertListSearchStatusOptionsEnum.OpenRead,
                    AlertListSearchStatusOptionsEnum.OpenUnread,
                    AlertListSearchStatusOptionsEnum.ResolvedUnread,
                ];
                selectedMyAlertListStatusOptions = MyAlertListSearchStatusOptions.filter(
                    (x) =>
                        x.id === AlertListSearchStatusOptionsEnum.OpenRead ||
                        x.id === AlertListSearchStatusOptionsEnum.OpenUnread ||
                        x.id === AlertListSearchStatusOptionsEnum.ResolvedUnread
                );
            }
        }

        this.setState({ pendingResponse: true });
        const response = await _apiClient.searchAlerts(alertSearchParameters, excludeFullCount);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),

                pendingResponse: false,
            });
            return;
        }

        this.setState({
            alertSearchParameters,
            alertList: response.payload!.item1,
            // totalCount: excludeFullCount ? this.state.totalCount : response.payload!.item2,
            showTooManyRecordsToProcessDialog:
                response.payload!.item2 > maxZoneAlertListCount ? true : false,
            pendingResponse: false,
            validation: validation,
            selectedAlertOriginTypesOptions,
            selectedMyAlertListStatusOptions,
            selectedTypeOptions,
            selectedUserOptions,
            selectedNoteCategoryOptions,
            selectedNoteSubCategoryOptions,
            selectedPurposeOptions,
            selectedNoteStatusOptions,
            userDefaultSettingsJson,
        });
    };

    getDefaultStatuses = () => {
        const defaultValues = [
            AlertListSearchStatusOptionsEnum.OpenRead,
            AlertListSearchStatusOptionsEnum.OpenUnread,
            AlertListSearchStatusOptionsEnum.ResolvedUnread,
        ];
        return MyAlertListSearchStatusOptions.filter((x) =>
            defaultValues.find((dv) => dv === x.id)
        );
    };

    getCaseTitle = () => {
        const caseTitle: ICaseTitleModel = {};
        if (this.state.currentNote) {
            caseTitle.caseName = this.state.currentNote.caseName;
            caseTitle.guid = this.state.currentNote.caseGuid;
            caseTitle.priority = this.state.currentNote.priority;
            caseTitle.SQScore = this.state.currentNote.qScore;
            caseTitle.SQProjection = this.state.currentNote.qProjection;
        }

        return caseTitle;
    };

    onSaveNoteComplete = (noteItem: INoteModel) => {
        let alertList = cloneDeep(this.state.alertList);
        for (let i = 0; i < alertList.length; i++) {
            if (alertList[i].guid === noteItem.guid) {
                alertList[i] = cloneDeep(noteItem);
            }
        }
        alertList = alertList.sort(Sort.compareDate('modifiedDate', undefined, 'desc'));
        this.setState(
            {
                pendingResponse: false,
                openNoteEditor: false,
                currentNote: {
                    status: { id: NoteStatusTypes.Open },
                    purpose: { id: NotePurposeTypesEnum.Info },
                },
                alertList,
            },
            () => this.loadAlertList(ListMode.UserDefault)
        );
    };

    getActionTarget = (item: INoteModel) => {
        if (
            item.alert &&
            item.alert.users &&
            item.alert.users.filter((x) => x.type && x.type.id === AlertTypes.Act.Value).length > 0
        )
            return (
                item.alert.users.filter((x) => x.type && x.type.id === AlertTypes.Act.Value)[0]
                    .user!.profile!.firstName +
                ' ' +
                item.alert.users.filter((x) => x.type && x.type.id === AlertTypes.Act.Value)[0]
                    .user!.profile!.lastName
            );
        return '';
    };

    handleNoteEditorCancel = () => {
        this.setState({
            currentNote: { status: { id: 0 }, purpose: { id: NotePurposeTypesEnum.Info } },
            openNoteEditor: false,
        });
    };

    handleFavoriteSelected = (settingJson?: string) => {
        let selectedFilters = '';

        if (settingJson) {
            const alertSearchParameters: INoteUISearchModel = JSON.parse(settingJson!);

            if (
                this.props.alertListType === AlertListTypeEnum.MyAlertList &&
                alertSearchParameters.alertListSearchStatusOptions &&
                alertSearchParameters.alertListSearchStatusOptions.length > 0
            ) {
                selectedFilters += 'STATUS:' + '\n';
                for (
                    let i = 0;
                    i < alertSearchParameters.alertListSearchStatusOptions.length;
                    i++
                ) {
                    const match = MyAlertListSearchStatusOptions.filter(
                        (x) => x.id == alertSearchParameters.alertListSearchStatusOptions![i]
                    );
                    if (match.length > 0) selectedFilters += match[0].label + '\n';
                }
                selectedFilters += '\n';
            } else if (
                this.props.alertListType === AlertListTypeEnum.ZoneAlertList &&
                alertSearchParameters.noteStatusIds &&
                alertSearchParameters.noteStatusIds.length > 0
            ) {
                selectedFilters += 'STATUS:' + '\n';
                for (let i = 0; i < alertSearchParameters.noteStatusIds.length; i++) {
                    const match = this.state.noteStatusTypes.filter(
                        (x) => x.id == alertSearchParameters.noteStatusIds![i]
                    );
                    if (match.length > 0) selectedFilters += match[0].label + '\n';
                }
                selectedFilters += '\n';
            }

            if (
                alertSearchParameters.alertTypeIds &&
                alertSearchParameters.alertTypeIds.length > 0
            ) {
                selectedFilters += 'TYPE:' + '\n';
                for (let i = 0; i < alertSearchParameters.alertTypeIds.length; i++) {
                    const match = this.state.types.filter(
                        (x) => x.id == alertSearchParameters.alertTypeIds![i]
                    );
                    if (match.length > 0) selectedFilters += match[0].label + '\n';
                }
                selectedFilters += '\n';
            }

            if (
                alertSearchParameters.alertUserGuids &&
                alertSearchParameters.alertUserGuids.length > 0
            ) {
                selectedFilters += 'USERS:' + '\n';
                for (let i = 0; i < alertSearchParameters.alertUserGuids.length; i++) {
                    const match = this.state.users.filter(
                        (x) => x.guid == alertSearchParameters.alertUserGuids![i]
                    );
                    if (match.length > 0) selectedFilters += match[0].label + '\n';
                }
                selectedFilters += '\n';
            }

            if (
                alertSearchParameters.noteCategories &&
                alertSearchParameters.noteCategories.length > 0
            ) {
                selectedFilters += 'NOTE CATEGORIES:' + '\n';
                for (let i = 0; i < alertSearchParameters.noteCategories.length; i++) {
                    selectedFilters += alertSearchParameters.noteCategories[i].label + '\n';
                }
                selectedFilters += '\n';
            }

            if (
                alertSearchParameters.noteSubCategories &&
                alertSearchParameters.noteSubCategories.length > 0
            ) {
                selectedFilters += 'NOTE SUB-CATEGORIES:' + '\n';
                for (let i = 0; i < alertSearchParameters.noteSubCategories.length; i++) {
                    selectedFilters += alertSearchParameters.noteSubCategories[i].label + '\n';
                }
                selectedFilters += '\n';
            }

            if (alertSearchParameters.purpose && alertSearchParameters.purpose.length > 0) {
                selectedFilters += 'PURPOSE:' + '\n';
                for (let i = 0; i < alertSearchParameters.purpose.length; i++) {
                    const match = this.state.purposes.filter(
                        (x) => x.id == alertSearchParameters.purpose![i].id
                    );
                    if (match.length > 0) selectedFilters += match[0].label + '\n';
                }
                selectedFilters += '\n';
            }
        }

        this.setState({ currentFavoriteDetailsString: selectedFilters });
    };

    applyFavoriteSelected = (settingJson?: string, isDefault?: boolean) => {
        if (settingJson) {
            this.loadAlertList(ListMode.FavoriteSelected, false, settingJson, isDefault);
        }
    };

    handleDefaultFavoriteChange = (settingJson?: string) => {
        this.setState({ userDefaultSettingsJson: settingJson });
    };

    nextPage = async () => {
        const alertSearchParameters = this.state.alertSearchParameters;
        if (alertSearchParameters.queryModel)
            alertSearchParameters.queryModel.pageNumber =
                alertSearchParameters.queryModel.pageNumber + 1;
        this.setState({ alertSearchParameters }, () => {
            this.loadAlertList(ListMode.RunSearch);
        });
    };

    previousPage = async () => {
        const alertSearchParameters = this.state.alertSearchParameters;
        if (alertSearchParameters.queryModel)
            alertSearchParameters.queryModel.pageNumber =
                alertSearchParameters.queryModel.pageNumber - 1;
        this.setState({ alertSearchParameters }, () => {
            this.loadAlertList(ListMode.RunSearch);
        });
    };

    handleEasyUpdate = (caseGuid: string) => {
        const easyUpdateAttemptedCaseList = this.state.easyUpdateAttemptedCaseList;
        easyUpdateAttemptedCaseList.push(caseGuid);
        this.setState({
            easyUpdateMode: true,
            easyUpdateCaseGuid: caseGuid,
            easyUpdateAttemptedCaseList: easyUpdateAttemptedCaseList,
        });
    };

    easyUpdateAttempted = (caseGuid: string) => {
        return this.state.easyUpdateAttemptedCaseList.filter((x) => x == caseGuid).length > 0;
    };

    easyUpdateDone = (updatedCase: ICaseModel) => {
        const alertList = this.state.alertList;
        for (let i = 0; i < alertList.length; i++) {
            if (alertList[i].caseGuid == updatedCase.guid) {
                alertList[i].caseName = updatedCase.caseName;
                alertList[i].qScore = updatedCase.qScore;
            }
        }

        this.setState({
            easyUpdateMode: false,
            easyUpdateCaseGuid: undefined,
            alertList: alertList,
        });
    };

    easyUpdateCancel = () => {
        this.setState({
            easyUpdateMode: false,
            easyUpdateCaseGuid: undefined,
        });
    };

    getSelectedFilters = () => {
        let selectedFilters = '';
        if (
            this.props.alertListType === AlertListTypeEnum.MyAlertList &&
            this.state.selectedMyAlertListStatusOptions.length > 0
        ) {
            selectedFilters += 'STATUS:' + '\n';
            for (let i = 0; i < this.state.selectedMyAlertListStatusOptions.length; i++) {
                selectedFilters += this.state.selectedMyAlertListStatusOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        } else if (
            this.props.alertListType === AlertListTypeEnum.ZoneAlertList &&
            this.state.selectedNoteStatusOptions.length > 0
        ) {
            selectedFilters += 'STATUS:' + '\n';
            for (let i = 0; i < this.state.selectedNoteStatusOptions.length; i++) {
                selectedFilters += this.state.selectedNoteStatusOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedTypeOptions.length > 0) {
            selectedFilters += 'TYPE:' + '\n';
            for (let i = 0; i < this.state.selectedTypeOptions.length; i++) {
                selectedFilters += this.state.selectedTypeOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedUserOptions.length > 0) {
            selectedFilters += 'USERS:' + '\n';
            for (let i = 0; i < this.state.selectedUserOptions.length; i++) {
                selectedFilters += this.state.selectedUserOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedNoteCategoryOptions.length > 0) {
            selectedFilters += 'NOTE CATEGORIES:' + '\n';
            for (let i = 0; i < this.state.selectedNoteCategoryOptions.length; i++) {
                selectedFilters += this.state.selectedNoteCategoryOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedNoteSubCategoryOptions.length > 0) {
            selectedFilters += 'NOTE SUB-CATEGORIES:' + '\n';
            for (let i = 0; i < this.state.selectedNoteSubCategoryOptions.length; i++) {
                selectedFilters += this.state.selectedNoteSubCategoryOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        if (this.state.selectedPurposeOptions.length > 0) {
            selectedFilters += 'PURPOSE:' + '\n';
            for (let i = 0; i < this.state.selectedPurposeOptions.length; i++) {
                selectedFilters += this.state.selectedPurposeOptions[i].label + '\n';
            }
            selectedFilters += '\n';
        }
        return selectedFilters;
    };

    handleAlertOriginTypeFilterOptionsChange = (optionsList: IMultiSelectOptions[]) => {
        const alertSearchParameters = { ...this.state.alertSearchParameters };
        let noteType = null;

        if (optionsList.length === 1) {
            noteType = optionsList[0];
            alertSearchParameters.noteType = {
                id: noteType.id,
            } as ILookupModel;
        } else alertSearchParameters.noteType = undefined;

        alertSearchParameters.noteTypes = [];
        for (let i = 0; i < optionsList.length; i++) {
            alertSearchParameters.noteTypes.push({ id: optionsList[i].id } as ILookupModel);
        }

        this.setState((prevState) => ({
            ...prevState,
            alertSearchParameters,
            selectedAlertOriginTypesOptions: optionsList,
        }));
    };

    getStrategyURL(strategyTypeId: number): string {
        switch (strategyTypeId) {
            case StrategyTypes.Jurisdiction.Value:
                return LocalRoutes.JurisdictionStrategyDetail;
            case StrategyTypes.PlaintiffsFirm.Value:
                return LocalRoutes.PlaintiffFirmStrategyDetail;
            case StrategyTypes.LocalCounsel.Value:
                return LocalRoutes.LocalCounselStrategyDetail;
            case StrategyTypes.Projects.Value:
                return LocalRoutes.ProjectDetail;
            default:
                return '';
        }
    }

    getEntityURL(entityTypeId: number): string {
        switch (entityTypeId) {
            case EntityTypes.PackageSettlement:
                return LocalRoutes.PackageSettlementDetails;
            case EntityTypes.Case:
                return LocalRoutes.CaseFile;
            default:
                return '';
        }
    }

    getNotesRight(operation: Operation): number {
        switch (this.state.currentNote?.type?.id) {
            case NoteTypes.CaseNote:
                switch (operation) {
                    case Operation.View:
                        return UserRightsEnum.ViewCaseNotes;
                    case Operation.Create:
                        return UserRightsEnum.AddCaseNote;
                    case Operation.Edit:
                        return UserRightsEnum.EditCaseNote;
                    case Operation.Delete:
                        return UserRightsEnum.DeleteCaseNote;
                    default:
                        return 0;
                }
            case NoteTypes.PackageSettlementNote:
                switch (operation) {
                    case Operation.View:
                        return UserRightsEnum.ViewPackageDealNote;
                    case Operation.Create:
                        return UserRightsEnum.AddPackageDealNote;
                    case Operation.Edit:
                        return UserRightsEnum.UpdatePackageDealNote;
                    case Operation.Delete:
                        return UserRightsEnum.DeletePackageDealNote;
                    default:
                        return 0;
                }
            case NoteTypes.ZoneNote:
                switch (operation) {
                    case Operation.View:
                        return UserRightsEnum.ViewZoneNotes;
                    case Operation.Create:
                        return UserRightsEnum.AddZoneNote;
                    case Operation.Edit:
                        return UserRightsEnum.EditZoneNote;
                    case Operation.Delete:
                        return UserRightsEnum.DeleteZoneNote;
                    default:
                        return 0;
                }
            case NoteTypes.StrategyNote:
                switch (this.state.currentNote?.originStrategyType) {
                    case StrategyTypes.PlaintiffsFirm.Value:
                        switch (operation) {
                            case Operation.View:
                                return UserRightsEnum.ViewPlaintiffsFirmStrategyNote;
                            case Operation.Create:
                                return UserRightsEnum.AddPlaintiffsFirmStrategyNote;
                            case Operation.Edit:
                                return UserRightsEnum.UpdatePlaintiffsFirmStrategyNote;
                            case Operation.Delete:
                                return UserRightsEnum.DeletePlaintiffsFirmStrategyNote;
                            default:
                                return 0;
                        }
                    case StrategyTypes.Jurisdiction.Value:
                        switch (operation) {
                            case Operation.View:
                                return UserRightsEnum.ViewJurisdictionStrategyNote;
                            case Operation.Create:
                                return UserRightsEnum.AddJurisdictionStrategyNote;
                            case Operation.Edit:
                                return UserRightsEnum.UpdateJurisdictionStrategyNote;
                            case Operation.Delete:
                                return UserRightsEnum.DeleteJurisdictionStrategyNote;
                            default:
                                return 0;
                        }
                    case StrategyTypes.LocalCounsel.Value:
                        switch (operation) {
                            case Operation.View:
                                return UserRightsEnum.ViewLocalCounselStrategyNote;
                            case Operation.Create:
                                return UserRightsEnum.AddLocalCounselStrategyNote;
                            case Operation.Edit:
                                return UserRightsEnum.UpdateLocalCounselStrategyNote;
                            case Operation.Delete:
                                return UserRightsEnum.DeleteLocalCounselStrategyNote;
                            default:
                                return 0;
                        }
                    case StrategyTypes.Projects.Value:
                        switch (operation) {
                            case Operation.View:
                                return UserRightsEnum.ViewProjectsStrategyNote;
                            case Operation.Create:
                                return UserRightsEnum.AddProjectsStrategyNote;
                            case Operation.Edit:
                                return UserRightsEnum.UpdateProjectsStrategyNote;
                            case Operation.Delete:
                                return UserRightsEnum.DeleteProjectsStrategyNote;
                            default:
                                return 0;
                        }
                    case StrategyTypes.Experts.Value:
                        switch (operation) {
                            case Operation.View:
                                return UserRightsEnum.ViewExpertStrategyNote;
                            case Operation.Create:
                                return UserRightsEnum.AddExpertStrategyNote;
                            case Operation.Edit:
                                return UserRightsEnum.UpdateExpertStrategyNote;
                            case Operation.Delete:
                                return UserRightsEnum.DeleteExpertStrategyNote;
                            default:
                                return 0;
                        }
                    default:
                        return 0;
                }
            default:
                return 0;
        }
    }

    handleFavoriteValidateFilters = () => {
        const valid = this.state.selectedAlertOriginTypesOptions.length > 0;

        if (!valid) {
            this.setState({
                validation: {
                    favorites: ['Enter at least one type to save a favorite'],
                    model: this.state.validation.model,
                },
                pendingResponse: false,
            });
            return true;
        }

        this.setState({
            validation: {
                favorites: [],
            },
            pendingResponse: false,
        });
        return false;
    };

    render() {
        const isMyAlertList = this.props.alertListType === AlertListTypeEnum.MyAlertList;
        const isZoneAlertList = this.props.alertListType === AlertListTypeEnum.ZoneAlertList;

        if (this.state.openNoteEditor) {
            return (
                <NoteEditor
                    readOnly={NoteHelper.isNoteReadOnly(
                        this.state.currentNote,
                        this.props.user,
                        Authorization.userHasRight(
                            this.getNotesRight(Operation.View),
                            this.props.user
                        ),
                        Authorization.userHasRight(
                            this.getNotesRight(Operation.Edit),
                            this.props.user
                        ),
                        Authorization.userHasRight(
                            this.getNotesRight(Operation.Create),
                            this.props.user
                        )
                    )}
                    caseLocalCounselGuid={this.state.currentNote.localCounselOrganizationGuid}
                    defaultAlertTarget={
                        Authorization.isLocalScope(this.props.user)
                            ? this.state.currentNote.caseManager
                            : undefined
                    }
                    defaultWatchTargets={
                        Authorization.isLocalScope(this.props.user) &&
                        this.state.currentNote.alternateCaseManager
                            ? [this.state.currentNote.alternateCaseManager]
                            : undefined
                    }
                    caseTitle={this.getCaseTitle()}
                    originEntityName={this.state.currentNote.originEntityName}
                    originEntityKey={this.state.currentNote.originEntityKey}
                    strategyTypeId={this.state.currentNote.originStrategyType}
                    user={this.props.user}
                    noteCategoryTypes={this.state.noteCategoryTypes.filter(
                        (x) => x.noteType && x.noteType.id === NoteTypes.CaseNote
                    )}
                    currentNote={cloneDeep(this.state.currentNote)}
                    handleSaveComplete={this.onSaveNoteComplete}
                    handleCancel={this.handleNoteEditorCancel}
                />
            );
        }

        return (
            <div>
                {Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, this.props.user) ? (
                    <CaseEasyUpdate
                        user={this.props.user}
                        caseGuid={this.state.easyUpdateCaseGuid}
                        onComplete={this.easyUpdateDone}
                        onCancel={this.easyUpdateCancel}
                        hidden={!this.state.easyUpdateMode}
                        excludeNotes={true}
                        additionalHeaderText={
                            isMyAlertList
                                ? 'Update Case Data | My Alert List'
                                : this.props.alertListType === AlertListTypeEnum.ZoneAlertList
                                ? 'Update Case Data | Zone Alert List'
                                : ''
                        }
                    />
                ) : null}
                {this.state.easyUpdateMode ? null : this.state.pendingResponse ? (
                    <Loader />
                ) : (
                    <>
                        <div className="row mb-3">
                            <div className="col-sm-3">
                                <h1 className="d-inline-block">
                                    {isMyAlertList ? 'My Alert List' : 'Zone Alert List'}
                                </h1>
                                {isMyAlertList ? (
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="My Alert List"
                                        helpText="Alerts in this view are limited to those alerts that include YOU as the alert target or as part of the watch group.  MY ALERT LIST displays unread (bold font) and read status and you can filter on unread/read status."
                                    />
                                ) : (
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Zone Alert List"
                                        helpText="This view includes ALL alerts for the zone.  This view does not display unread/read status and you cannot filter on that status; however,  if you read an alert from this view and then see that same alert in MY ALERT LIST, the alert will display as read."
                                    />
                                )}
                            </div>
                            <div className="col-sm-9">
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={() => {
                                        this.loadAlertList(ListMode.UserDefault);
                                    }}
                                >
                                    Refresh
                                </button>
                                {this.props.alertListType ? (
                                    <Favorite
                                        user={this.props.user}
                                        type={{ id: this.props.alertListType }}
                                        currentFavoriteSettingJson={JSON.stringify(
                                            this.state.alertSearchParameters
                                        )}
                                        currentFavoriteDetailsString={
                                            this.state.currentFavoriteDetailsString
                                        }
                                        handleFavoriteSelected={this.handleFavoriteSelected}
                                        applyFavoriteSelected={this.applyFavoriteSelected}
                                        handleDefaultFavoriteChange={
                                            this.handleDefaultFavoriteChange
                                        }
                                        onValidateFilters={this.handleFavoriteValidateFilters}
                                    />
                                ) : null}
                                {Authorization.isAuthorizedToRoute(
                                    LocalRoutes.CaseEasyUpdate,
                                    this.props.user
                                ) ? (
                                    <span className="btn btn-no-bg float-end text-gray ps-3">
                                        <input
                                            name="chkEasyUpdate"
                                            type="checkbox"
                                            className="form-check-input"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.setState({
                                                    enableEasyUpdate: e.target.checked,
                                                });
                                            }}
                                            checked={this.state.enableEasyUpdate}
                                        />
                                        <i className="fal fa-edit ps-2 pe-1" />
                                        Easy Update
                                    </span>
                                ) : null}
                                <Link
                                    className="float-end"
                                    target="_blank"
                                    to={LocalRoutes.ViewPDF.replace(
                                        ':id',
                                        TutorialTopics.Alerts.toString()
                                    )}
                                >
                                    <span className="btn btn-no-bg text-gray">
                                        <i className={'fal fa-graduation-cap'} />
                                        &nbsp;Tutorials
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <div className="row text-danger mb-2">
                            {this.state.validation.model
                                ? this.state.validation.model.map((s: string) => {
                                      return <span>{s}</span>;
                                  })
                                : null}
                            {this.state.validation.favorites
                                ? this.state.validation.favorites.map((s: string) => {
                                      return <span>{s}</span>;
                                  })
                                : null}
                        </div>
                        <div>
                            <div className="row no-padding">
                                <div className="col flex-grow-0 case-profile-item-div">
                                    <div className="text-gray text-lg" style={{ width: 50 }}>
                                        Type
                                    </div>
                                </div>
                                <div className="col horizontal-padding" style={{ maxWidth: 250 }}>
                                    <CheckmateNSelect
                                        name="alertOriginType"
                                        options={this.state.noteTypes}
                                        value={this.state.selectedAlertOriginTypesOptions}
                                        onChange={this.handleAlertOriginTypeFilterOptionsChange}
                                        placeholder="-- Type --"
                                    />
                                </div>
                                <div className="col horizontal-padding" style={{ maxWidth: 250 }} />
                                <div className="col horizontal-padding" style={{ maxWidth: 250 }} />
                                <div className="col horizontal-padding" style={{ maxWidth: 250 }} />
                                <div className="col horizontal-padding" style={{ maxWidth: 250 }} />
                                {isZoneAlertList && (
                                    <div
                                        className="col horizontal-padding"
                                        style={{ maxWidth: 250 }}
                                    />
                                )}
                                <div className="col-2 pe-3 float-end">
                                    <div style={{ width: '100%' }} />
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col flex-grow-0 case-profile-item-div">
                                    <div className="text-gray text-lg" style={{ width: 50 }}>
                                        Filter
                                    </div>
                                </div>
                                {isMyAlertList ? (
                                    <div
                                        className="col horizontal-padding"
                                        style={{ maxWidth: 250 }}
                                    >
                                        <CheckmateNSelect
                                            name="myAlertListStatus"
                                            options={MyAlertListSearchStatusOptions}
                                            value={this.state.selectedMyAlertListStatusOptions}
                                            onChange={this.myAlertListStatusFilterOptionsChange}
                                            placeholder="-- Status --"
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className="col horizontal-padding"
                                        style={{ maxWidth: 250 }}
                                    >
                                        <CheckmateNSelect
                                            name="noteStatus"
                                            options={this.state.noteStatusTypes}
                                            value={this.state.selectedNoteStatusOptions}
                                            onChange={this.onNoteStatusFilterOptionsChange}
                                            placeholder="-- Status --"
                                        />
                                    </div>
                                )}
                                <div className="col horizontal-padding" style={{ maxWidth: 250 }}>
                                    <CheckmateNSelect
                                        name="type"
                                        options={this.state.types}
                                        value={this.state.selectedTypeOptions}
                                        onChange={this.onTypeFilterOptionsChange}
                                        placeholder="-- Act/Watch --"
                                    />
                                </div>
                                {!isMyAlertList ? (
                                    <div
                                        className="col horizontal-padding"
                                        style={{ maxWidth: 250 }}
                                    >
                                        <CheckmateNSelect
                                            name="user"
                                            options={this.state.users}
                                            value={this.state.selectedUserOptions}
                                            onChange={this.onUserFilterOptionsChange}
                                            placeholder="-- Alert User --"
                                        />
                                    </div>
                                ) : null}
                                <div className="col horizontal-padding" style={{ maxWidth: 250 }}>
                                    <CheckmateNSelect
                                        name="noteCategory"
                                        options={this.getNoteCategoryTypeOptions()}
                                        value={this.state.selectedNoteCategoryOptions}
                                        onChange={this.onNoteCategoryFilterOptionsChange}
                                        placeholder="-- Category --"
                                        disabled={
                                            this.state.selectedAlertOriginTypesOptions.length > 1
                                        } // temp rule
                                    />
                                </div>
                                <div className="col horizontal-padding" style={{ maxWidth: 250 }}>
                                    <CheckmateNSelect
                                        name="noteSubCategory"
                                        options={this.getNoteSubCategoryTypeOptions()}
                                        value={this.state.selectedNoteSubCategoryOptions}
                                        onChange={this.onNoteSubCategoryFilterOptionsChange}
                                        placeholder="-- Sub Category --"
                                        disabled={
                                            this.state.selectedAlertOriginTypesOptions.length > 1
                                        } // temp rule
                                    />
                                </div>
                                <div className="col horizontal-padding" style={{ maxWidth: 250 }}>
                                    <CheckmateNSelect
                                        name="purpose"
                                        options={this.state.purposes}
                                        value={this.state.selectedPurposeOptions}
                                        onChange={this.onPurposeFilterOptionsChange}
                                        placeholder="-- Purpose --"
                                    />
                                </div>
                                <div className="col-2 pe-3 float-end">
                                    <div className="text-right">
                                        <Help
                                            type={ApplicationHelpType.Check}
                                            title="Selected Filters"
                                            helpText={this.getSelectedFilters()}
                                        />
                                        <button
                                            className="btn btn-black btn-icon"
                                            onClick={() => {
                                                this.loadAlertList(ListMode.RunSearch);
                                            }}
                                        >
                                            <i className="fal fa-filter color-white" />
                                        </button>
                                        <button
                                            className="btn btn-gray btn-icon"
                                            onClick={() => {
                                                this.loadAlertList(ListMode.SystemDefault);
                                            }}
                                        >
                                            <i className="fal fa-times color-white" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr style={{ verticalAlign: 'middle' }}>
                                    <SortableHeader
                                        headerText="Type"
                                        sortKey="type"
                                        subKey="displayName"
                                        onSort={this.sortData}
                                    />
                                    <SortableHeader
                                        headerText="Name"
                                        sortKey="originEntityName"
                                        onSort={this.sortData}
                                        thClassName="col-sm-1"
                                    />
                                    <SortableHeader
                                        headerComponent={
                                            <div style={{ whiteSpace: 'nowrap' }}>SQ Score</div>
                                        }
                                        sortKey="qScore"
                                        onSort={this.sortData}
                                    />
                                    <th className="col-sm-1">Category</th>
                                    <SortableHeader
                                        headerText="Decision"
                                        sortKey="decisionText"
                                        onSort={this.sortData}
                                    />
                                    <SortableHeader
                                        headerText="Action Target"
                                        sortKey="actionTarget"
                                        subKey="profile"
                                        subGrandKey="firstName"
                                        onSort={this.sortData}
                                        noWrap
                                    />

                                    <SortableHeader
                                        headerComponent={
                                            <button
                                                className="sort-arrow btn-no-bg"
                                                title="Watch List"
                                            >
                                                <i className="fal fa-lg fa-glasses-alt color-gray" />
                                            </button>
                                        }
                                        sortKey="watchUser"
                                        onSort={this.sortData}
                                    />
                                    <SortableHeader
                                        headerText="Writer"
                                        sortKey="author"
                                        subKey="profile"
                                        subGrandKey="firstName"
                                        onSort={this.sortData}
                                    />
                                    <SortableHeader
                                        headerText="Date"
                                        sortKey="modifiedDate"
                                        onSort={this.sortData}
                                    />
                                    <SortableHeader
                                        headerText="Purpose"
                                        sortKey="displayName"
                                        onSort={this.sortData}
                                    />
                                    <SortableHeader
                                        headerText="Status"
                                        sortKey="status"
                                        subKey="displayName"
                                        onSort={this.sortData}
                                    />
                                    <SortableHeader
                                        headerComponent={
                                            <button
                                                className="sort-arrow btn-no-bg"
                                                title="Private"
                                            >
                                                <i className="fal fa-lg fa-location-dot-slash color-gray" />
                                            </button>
                                        }
                                        sortKey="private"
                                        onSort={this.sortData}
                                    />
                                    <SortableHeader
                                        headerComponent={
                                            <button
                                                className="sort-arrow btn-no-bg"
                                                title="Allow Local Edit"
                                            >
                                                <i className="fal fa-lg fa-map-marker-edit color-gray" />
                                            </button>
                                        }
                                        sortKey="allowLocalEdit"
                                        onSort={this.sortData}
                                    />
                                    <th /* width={60} */></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.alertList.map((item: INoteModel, i: number) => {
                                    const nameNavigateUrl = item.originStrategyType
                                        ? this.getStrategyURL(item.originStrategyType ?? 0).replace(
                                              ':guid',
                                              item.originEntityKey ?? ''
                                          )
                                        : item.originEntityType
                                        ? this.getEntityURL(item.originEntityType ?? 0).replace(
                                              ':guid',
                                              item.originEntityKey ?? ''
                                          )
                                        : LocalRoutes.CaseFile.replace(
                                              ':guid',
                                              item.caseGuid ?? ''
                                          );

                                    return (
                                        <AlertListRow
                                            key={i}
                                            item={item}
                                            isMyAlertList={isMyAlertList}
                                            nameNavigateUrl={nameNavigateUrl}
                                            onViewClick={() =>
                                                this.setState({
                                                    currentNote: item,
                                                    openNoteEditor: true,
                                                })
                                            }
                                            hasEasyUpdateBeenAttempted={this.easyUpdateAttempted(
                                                item.caseGuid!
                                            )}
                                            enableEasyUpdate={this.state.enableEasyUpdate}
                                            onEasyUpdate={() => {
                                                this.handleEasyUpdate(item.caseGuid!);
                                            }}
                                            user={this.props.user}
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                    </>
                )}

                {this.state.showTooManyRecordsToProcessDialog ? (
                    <Modal
                        centered
                        show={this.state.showTooManyRecordsToProcessDialog}
                        onHide={() => this.setState({ showTooManyRecordsToProcessDialog: false })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> Zone Alert List Results </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container-fluid">
                                <span>
                                    Your query returned more than{' '}
                                    {maxZoneAlertListCount.toLocaleString('en-US')} records. The
                                    request cannot be completed at this time. If you did not run
                                    this query by error, please send an email with the following
                                    information to{' '}
                                    <a href={'mailto:ltm@checkmate.legal'}>ltm@checkmate.legal</a>{' '}
                                    and we will perform this query for you.{' '}
                                </span>
                                <br />
                                <ul>
                                    <li>- Purpose for the query</li>
                                    <li>- When you need the results</li>
                                    <li>- Filter Options</li>
                                </ul>
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({ showTooManyRecordsToProcessDialog: false })
                                    }
                                >
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}
            </div>
        );
    }
}
