import * as React from 'react';

import ApiClient from '../../services/apiClient';
import { AssertionDescriptorUsageTypesEnum } from '../../utilities/Constants';
import Authorization from '../../stores/Authorization';
import { BasicSearch } from '../../components/shared/BasicSearch';
import { IAssertionDescriptorModel } from '../../interfaces/IAssertionDescriptor';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import Sort from '../../stores/Sort';
import { SortableHeader } from '../../components/shared/SortableHeader';
import ValidateUtils from '../../shared/validations';

const _apiClient = new ApiClient();

interface IAssertionDescriptorListProps {
    user: IUserModel;
}

interface IAssertionDescriptorListState {
    assertionDescriptorList?: IAssertionDescriptorModel[];
    validation: IValidation;
    pendingResponse: boolean;
}
export class AssertionDescriptorList extends React.Component<
    IAssertionDescriptorListProps,
    IAssertionDescriptorListState
> {
    constructor(props: IAssertionDescriptorListProps) {
        super(props);

        this.state = {
            validation: {},
            pendingResponse: true,
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.AssertionDescriptors, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadAssertionDescriptors();
    }

    loadAssertionDescriptors = async (searchString?: string, checkForEditAccess = true) => {
        this.setState({ pendingResponse: true });
        try {
            const res = await _apiClient.getAssertionDescriptors(
                AssertionDescriptorUsageTypesEnum.Admin,
                searchString,
                checkForEditAccess
            );
            if (res.httpResponse.status == 401) {
                window.location.reload();
                return;
            }
            if (res.errorMessage) {
                this.setState({
                    validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                    pendingResponse: false,
                });
                return;
            }
            if (res.payload) {
                this.setState({
                    assertionDescriptorList: res.payload,
                    pendingResponse: false,
                });
            }

            return true;
        } catch (error) {
            return false;
        }
    };

    runSearch = (event: React.FormEvent<HTMLButtonElement>, searchString: string) => {
        event.preventDefault();
        this.loadAssertionDescriptors(searchString);
    };

    sortData = (key: string, order: string, subKey?: string) => {
        if (this.state.assertionDescriptorList) {
            const assertionDescriptorList = this.state.assertionDescriptorList.sort(
                Sort.compareValues(key, subKey, order)
            );
            this.setState({ assertionDescriptorList: assertionDescriptorList });
        }
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;

        return (
            <div>
                <h1>
                    Assertion Descriptors
                    <Link to={LocalRoutes.AssertionDescriptorDetail.replace(':guid', '')}>
                        <span className="btn btn-green float-end btn-icon">
                            <i className="fal fa-lg fa-plus" />
                        </span>
                    </Link>
                </h1>
                <div className="user-search-div margin-top">
                    <BasicSearch
                        placeholderText="Search by Assertion Descriptor Name"
                        onSearch={this.runSearch}
                        justifyLeft={true}
                    />
                </div>
                {this.state.assertionDescriptorList &&
                this.state.assertionDescriptorList.length > 0 ? (
                    <table className="table table-striped">
                        <thead>
                            <tr style={{ verticalAlign: 'middle' }}>
                                <SortableHeader
                                    headerText="Usage"
                                    sortKey="usageType"
                                    subKey="displayName"
                                    onSort={this.sortData}
                                />
                                <SortableHeader
                                    headerText="Name"
                                    sortKey="name"
                                    onSort={this.sortData}
                                />
                                <SortableHeader
                                    headerText="Display Name"
                                    sortKey="displayName"
                                    onSort={this.sortData}
                                    noWrap
                                />
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.assertionDescriptorList.map(
                                (assertionDescriptor: IAssertionDescriptorModel, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>{assertionDescriptor.usageType!.name}</td>
                                            <td>
                                                <Link
                                                    to={LocalRoutes.AssertionDescriptorDetail.replace(
                                                        ':guid',
                                                        assertionDescriptor!.guid!
                                                    )}
                                                >
                                                    {assertionDescriptor.name}
                                                </Link>
                                            </td>
                                            <td>{assertionDescriptor.displayName}</td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                ) : (
                    <span>No Assertion Descriptors Found!</span>
                )}
                {/*<Dialog isShowingModal={this.state.isShowingModal}
                    title={'Delete organization'}
                    body={'Are you sure you want to delete this organization?'}
                    handleClose={this.handleCloseDialog}
                    handleConfirm={this.handleConfirmDialog}
                    confirmText={'Yes'}
                    cancelText={'No'}
                /> */}
            </div>
        );
    }
}
