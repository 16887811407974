import * as React from 'react';

import { Accordion, Modal } from 'react-bootstrap';
import {
    ApplicationHelpType,
    CaseInventoryByStateDisplayMode,
    DataScopesEnum,
    EntityTypes,
    HelpKeys,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    PlaintiffsFirmDisplayMode,
    ReportCaseDetailsLastColumn,
    ReportSettings_PlaintiffsFirmDisplayMode,
    ReportSettings_ReportCaseDetailsLastColumn,
    ReportSettings_ReportCaseDetailsLastColumnDisplayName,
    TimelineReportSections,
    TimelineReportStatusEnum,
    TimelineReportTypeEnum,
    UserRightsEnum,
    WeeklyRecapReportSectionList,
} from '../../../utilities/Constants';
import { IKeyValuePairModel, ILookupModel, IMultiSelectOptions } from '../../../interfaces/ILookup';
import { INoteCategoryTypeModel, INoteModel } from '../../../interfaces/INote';
import {
    IReportCaseDetailModel,
    IReportNoteBaseModel,
} from '../../../interfaces/Report/IReportCaseDetailModel';
import {
    IReportDepositionModel,
    IWeeklyRecapModel,
    IZoneNoteModel,
} from '../../../interfaces/Report/ITimelineReport';
import {
    IReportSectionDetailModel,
    ITimelineReportInputModel,
} from '../../../interfaces/Report/ITimelineReport';

import { ApiRoutes } from '../../../utilities/ApiRoutes';
import Authorization from '../../../stores/Authorization';
import { CaseEasyUpdate } from '../../case/CaseEasyUpdate';
import CheckmateApiClient from '../../../services/apiClient';
import { CheckmateDialog } from '../../../components/shared/dialog';
import CheckmateSelect from '../../../components/shared/CheckmateSelect';
import CheckmateSelectHelper from '../../../utilities/CheckmateSelectHelper';
import Common from '../../../stores/Common';
import { DisplayMessages } from '../../../utilities/DisplayMessages';
import { Help } from '../../../components/shared/Help';
import { IOrganizationSettingModel } from '../../../interfaces/IOrganization';
import { IValidation } from '../../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../../components/shared/Loader';
import { LocalRoutes } from '../../../utilities/LocalRoutes';
import { NoteEditor } from '../../../components/notes/NoteEditor';
import NoteHelper from '../../../utilities/NoteHelper';
import { ResizableDialog } from '../../../components/shared/ResizableDialog';
import { TextWithLineBreaks } from '../../../components/shared/TextWithLineBreaks';
import ValidateUtils from '../../../shared/validations';

const _apiClient = new CheckmateApiClient();

interface IWeeklyRecapState {
    pendingResponse: boolean;
    validation: IValidation;
    inputModel: ITimelineReportInputModel;
    reportData?: IWeeklyRecapModel;
    organizationSettings: IOrganizationSettingModel[];
    selectAllSections: TimelineReportSections[];
    easyUpdateMode: boolean;
    easyUpdateCaseGuid?: string;
    easyUpdateAttemptedCaseList: string[];
    noteEditAttemptedGuidList: string[];
    openNoteEditor?: boolean;
    currentNote: INoteModel;
    noteCategoryTypes: INoteCategoryTypeModel[];
    showSettingsModal: boolean;
    showCommentsModal?: boolean;
    currentCommentSection: number;
    plaintiffsFirmDisplayMode: IMultiSelectOptions;
    reportCaseDetailsLastColumn: IMultiSelectOptions;
    caseInventoryByStateDisplayMode: IMultiSelectOptions;
    showClearCommentsConfirmModal?: boolean;
    showClearSettingssConfirmModal?: boolean;
    sectionToBeRemoved?: TimelineReportSections;
}

export class WeeklyRecap extends React.Component<any, IWeeklyRecapState> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: false,
            validation: {},
            organizationSettings: [],
            selectAllSections: [],
            inputModel: this.getDefaultReportInputModel(),
            easyUpdateMode: false,
            easyUpdateAttemptedCaseList: [],
            noteEditAttemptedGuidList: [],
            noteCategoryTypes: [],
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            showSettingsModal: true,
            currentCommentSection: 0,
            plaintiffsFirmDisplayMode: {
                label: 'National Plaintiffs Firm (If Exists) or Plaintiffs Firm ',
                value: PlaintiffsFirmDisplayMode.Hierarchy.toString(),
                id: PlaintiffsFirmDisplayMode.Hierarchy,
            },
            reportCaseDetailsLastColumn: {
                label: 'Product/Premises',
                value: ReportCaseDetailsLastColumn.ProductPremises.toString(),
                id: ReportCaseDetailsLastColumn.ProductPremises,
            },
            caseInventoryByStateDisplayMode: {
                label: 'Top 5 States',
                value: CaseInventoryByStateDisplayMode.Top5.toString(),
                id: CaseInventoryByStateDisplayMode.Top5,
            },
        };
    }

    componentDidMount() {
        this.loadInitialData();
    }

    loadInitialData = async () => {
        const validation: IValidation = {};
        this.setState({ pendingResponse: true, validation: validation });
        const orgSettings = await this.getOrganizationSettings();
        const noteCategoryTypes = await this.fetchNoteCategoryTypes();
        if (!orgSettings || !noteCategoryTypes) {
            validation.model = [DisplayMessages.UnexpectedError];
        }
        this.setState({
            organizationSettings: orgSettings!,
            noteCategoryTypes: noteCategoryTypes!,
            pendingResponse: false,
        });
    };

    getDefaultReportInputModel = () => {
        const inputModel: ITimelineReportInputModel = {
            type: TimelineReportTypeEnum.Weekly,
            reportSectionDetails: [],
        };
        WeeklyRecapReportSectionList.map((section: ILookupModel) => {
            const sectionDetail: IReportSectionDetailModel = {
                section: section.id,
                sectionName: section.name!,
            };
            inputModel.reportSectionDetails!.push(sectionDetail);
        });

        inputModel.additionalSettings = {
            plaintiffsFirmDisplayMode: PlaintiffsFirmDisplayMode.Hierarchy,
            reportCaseDetailsLastColumn: ReportCaseDetailsLastColumn.ProductPremises,
            reportCaseDetailsLastColumnDisplayName: 'Product/Premises',
        };

        return inputModel;
    };

    getOrganizationSettings = async () => {
        this.setState({ pendingResponse: true, validation: {} });
        const response = await _apiClient.getOrganizationSettings();
        if (response.httpResponse.status === 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        return response.payload;
    };

    fetchNoteCategoryTypes = async () => {
        const response = await _apiClient.getAllNoteCategoryTypes();
        if (response.httpResponse.status === 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    runValidation = () => {
        const validation: IValidation = {};

        if (!this.state.inputModel.startDate || !this.state.inputModel.endDate) {
            validation.settings = ['Enter Report Parameters'];
            return validation;
        }

        if (
            Common.dateDiffInDays(this.state.inputModel.endDate, this.state.inputModel.startDate) <
            0
        ) {
            validation.settings = ['Invalid Report Parameters'];
            return validation;
        }

        if (
            !this.state.inputModel.reportSectionDetails ||
            this.state.inputModel.reportSectionDetails.length === 0
        ) {
            validation.settings = ['Select at least 1 section'];
            return validation;
        }

        if (
            this.state.inputModel.reportSectionDetails &&
            this.state.inputModel.reportSectionDetails.filter(
                (element) =>
                    element.section === TimelineReportSections.RiskIssues ||
                    element.section === TimelineReportSections.SignificantActivity ||
                    element.section === TimelineReportSections.UpcomingTrialDates ||
                    element.section === TimelineReportSections.TenderedCases
            ).length > 0 &&
            (!this.state.inputModel.additionalSettings ||
                !this.state.inputModel.additionalSettings.reportCaseDetailsLastColumnDisplayName)
        ) {
            validation.settings = ['Case Details - Last Column Header Name is required'];
            return validation;
        }

        return null;
    };

    getReportData = async () => {
        const validation = this.runValidation();

        if (validation) {
            this.setState({ validation });
            return;
        }

        this.setState({ pendingResponse: true, validation: {} });

        const response = await _apiClient.getWeeklyRecapReportData(this.state.inputModel);
        if (response.httpResponse.status === 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (response.payload) {
            this.setState({
                reportData: response.payload,
                pendingResponse: false,
                showSettingsModal: false,
            });
        }
    };

    handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const inputModel = this.state.inputModel;
        const validation = this.state.validation;
        const name = event.target.name;
        const value = event.target.value;

        switch (name) {
            case 'startDate':
                inputModel.startDate = value;
                break;
            case 'endDate':
                inputModel.endDate = value;
                break;
            case ReportSettings_ReportCaseDetailsLastColumnDisplayName:
                if (!inputModel.additionalSettings) inputModel.additionalSettings = {};
                inputModel.additionalSettings.reportCaseDetailsLastColumnDisplayName = value;
                if (value) validation.settings = [''];
                break;
            default:
                break;
        }
        this.setState({
            inputModel: inputModel,
            validation: validation,
        });
    };

    getCheckedValue(section: TimelineReportSections, entityType: EntityTypes, guid: string) {
        const inputModel = this.state.inputModel;
        if (inputModel.reportSectionDetails && inputModel.reportSectionDetails) {
            const sectionMatch = inputModel.reportSectionDetails.find((x) => x.section === section);
            if (sectionMatch) {
                switch (entityType) {
                    case EntityTypes.Case:
                        if (sectionMatch.caseGuids)
                            return sectionMatch.caseGuids.findIndex((x) => x === guid) > -1;
                        break;
                    case EntityTypes.Note:
                        if (sectionMatch.noteGuids)
                            return sectionMatch.noteGuids.findIndex((x) => x === guid) > -1;
                        break;
                    default:
                }
            }
        }
        return false;
    }

    handleSelectAllCheckedChanged = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections
    ) => {
        const selectAllSections = this.state.selectAllSections;
        const inputModel = this.state.inputModel;

        if (!inputModel.reportSectionDetails) inputModel.reportSectionDetails = [];

        let sectionMatch = inputModel.reportSectionDetails.find((x) => x.section === section);
        if (!sectionMatch) {
            sectionMatch = { section: section, sectionName: section.toString() };
            inputModel.reportSectionDetails.push(sectionMatch);
        }

        if (event.target.checked) {
            if (selectAllSections.findIndex((x) => x === section) === -1)
                selectAllSections.push(section);
            const allGuids = this.getAllGuidsBySectionForExport(section);
            sectionMatch.caseGuids = allGuids.caseGuids;
            sectionMatch.noteGuids = allGuids.noteGuids;
        } else {
            if (selectAllSections.findIndex((x) => x === section) > -1)
                selectAllSections.splice(
                    selectAllSections.findIndex((x) => x === section),
                    1
                );
            sectionMatch.caseGuids = [];
            sectionMatch.noteGuids = [];
        }

        this.setState({
            selectAllSections: selectAllSections,
            inputModel: inputModel,
        });
    };

    isSectionRowsSelectAllChecked = (section: TimelineReportSections) => {
        return this.state.selectAllSections.filter((x) => x === section).length > 0;
    };

    getParentCaseGuidByNoteGuid = (noteGuid: string, section: TimelineReportSections): string => {
        if (this.state.reportData) {
            switch (section) {
                case TimelineReportSections.InitialCaseEval:
                    for (let i = 0; i < this.state.reportData.initialCaseEval.length; i++) {
                        if (
                            this.state.reportData.initialCaseEval[i].notes.filter(
                                (x) => x.guid === noteGuid
                            ).length > 0
                        )
                            return this.state.reportData.initialCaseEval[i].caseGuid;
                    }
                    break;
                case TimelineReportSections.TenderedCases:
                    for (let i = 0; i < this.state.reportData.tenderedCases.length; i++) {
                        if (
                            this.state.reportData.tenderedCases[i].notes.filter(
                                (x) => x.guid === noteGuid
                            ).length > 0
                        )
                            return this.state.reportData.tenderedCases[i].caseGuid;
                    }
                    break;
                case TimelineReportSections.RiskIssues:
                    for (let i = 0; i < this.state.reportData.riskIssues.length; i++) {
                        if (
                            this.state.reportData.riskIssues[i].notes.filter(
                                (x) => x.guid === noteGuid
                            ).length > 0
                        )
                            return this.state.reportData.riskIssues[i].caseGuid;
                    }
                    break;
                case TimelineReportSections.SignificantActivity:
                    for (let i = 0; i < this.state.reportData.significantActivities.length; i++) {
                        if (
                            this.state.reportData.significantActivities[i].notes.filter(
                                (x) => x.guid === noteGuid
                            ).length > 0
                        )
                            return this.state.reportData.significantActivities[i].caseGuid;
                    }
                    break;
                case TimelineReportSections.RequestForAuth:
                    for (let i = 0; i < this.state.reportData.clientRequests.length; i++) {
                        if (
                            this.state.reportData.clientRequests[i].notes.filter(
                                (x) => x.guid === noteGuid
                            ).length > 0
                        )
                            return this.state.reportData.significantActivities[i].caseGuid;
                    }
                    break;
                default:
                    return '';
            }
        }

        return '';
    };

    getNoteGuidsByCaseAndSection = (
        caseGuid: string,
        section: TimelineReportSections
    ): string[] => {
        const returnValue: string[] = [];

        if (this.state.reportData) {
            switch (section) {
                case TimelineReportSections.InitialCaseEval: {
                    const initialCaseEvalMatch = this.state.reportData.initialCaseEval.find(
                        (x) => x.caseGuid === caseGuid
                    );
                    if (initialCaseEvalMatch && initialCaseEvalMatch.notes)
                        initialCaseEvalMatch.notes.map((item: IReportNoteBaseModel) => {
                            return returnValue.push(item.guid);
                        });
                    break;
                }
                case TimelineReportSections.TenderedCases: {
                    const tenderedcaseMatch = this.state.reportData.tenderedCases.find(
                        (x) => x.caseGuid === caseGuid
                    );
                    if (tenderedcaseMatch && tenderedcaseMatch.notes)
                        tenderedcaseMatch.notes.map((item: IReportNoteBaseModel) => {
                            return returnValue.push(item.guid);
                        });
                    break;
                }
                case TimelineReportSections.RiskIssues: {
                    const riskIssuesMatch = this.state.reportData.riskIssues.find(
                        (x) => x.caseGuid === caseGuid
                    );
                    if (riskIssuesMatch && riskIssuesMatch.notes)
                        riskIssuesMatch.notes.map((item: IReportNoteBaseModel) => {
                            return returnValue.push(item.guid);
                        });
                    break;
                }
                case TimelineReportSections.SignificantActivity: {
                    const sigActMatch = this.state.reportData.significantActivities.find(
                        (x) => x.caseGuid === caseGuid
                    );
                    if (sigActMatch && sigActMatch.notes)
                        sigActMatch.notes.map((item: IReportNoteBaseModel) => {
                            return returnValue.push(item.guid);
                        });
                    break;
                }
                case TimelineReportSections.RequestForAuth: {
                    const reqForAuthMatch = this.state.reportData.clientRequests.find(
                        (x) => x.caseGuid === caseGuid
                    );
                    if (reqForAuthMatch && reqForAuthMatch.notes)
                        reqForAuthMatch.notes.map((item: IReportNoteBaseModel) => {
                            return returnValue.push(item.guid);
                        });
                    break;
                }
                default:
            }
        }
        return returnValue;
    };

    getAllGuidsBySectionForExport = (section: TimelineReportSections) => {
        const result: IReportSectionDetailModel = {
            section: section,
            sectionName: section.toString(),
            caseGuids: [],
            noteGuids: [],
        };
        if (this.state.reportData) {
            switch (section) {
                case TimelineReportSections.InitialCaseEval:
                    for (let i = 0; i < this.state.reportData.initialCaseEval.length; i++) {
                        result.caseGuids!.push(this.state.reportData.initialCaseEval[i].caseGuid);
                        if (this.state.reportData.initialCaseEval[i].notes) {
                            for (
                                let j = 0;
                                j < this.state.reportData.initialCaseEval[i].notes.length;
                                j++
                            ) {
                                result.noteGuids!.push(
                                    this.state.reportData.initialCaseEval[i].notes[j].guid
                                );
                            }
                        }
                    }
                    break;
                case TimelineReportSections.TenderedCases:
                    for (let i = 0; i < this.state.reportData.tenderedCases.length; i++) {
                        result.caseGuids!.push(this.state.reportData.tenderedCases[i].caseGuid);
                        if (this.state.reportData.tenderedCases[i].notes) {
                            for (
                                let j = 0;
                                j < this.state.reportData.tenderedCases[i].notes.length;
                                j++
                            ) {
                                result.noteGuids!.push(
                                    this.state.reportData.tenderedCases[i].notes[j].guid
                                );
                            }
                        }
                    }
                    break;
                case TimelineReportSections.RiskIssues:
                    for (let i = 0; i < this.state.reportData.riskIssues.length; i++) {
                        result.caseGuids!.push(this.state.reportData.riskIssues[i].caseGuid);
                        if (this.state.reportData.riskIssues[i].notes) {
                            for (
                                let j = 0;
                                j < this.state.reportData.riskIssues[i].notes.length;
                                j++
                            ) {
                                result.noteGuids!.push(
                                    this.state.reportData.riskIssues[i].notes[j].guid
                                );
                            }
                        }
                    }
                    break;
                case TimelineReportSections.SignificantActivity:
                    for (let i = 0; i < this.state.reportData.significantActivities.length; i++) {
                        result.caseGuids!.push(
                            this.state.reportData.significantActivities[i].caseGuid
                        );
                        if (this.state.reportData.significantActivities[i].notes) {
                            for (
                                let j = 0;
                                j < this.state.reportData.significantActivities[i].notes.length;
                                j++
                            ) {
                                result.noteGuids!.push(
                                    this.state.reportData.significantActivities[i].notes[j].guid
                                );
                            }
                        }
                    }
                    break;
                case TimelineReportSections.RequestForAuth:
                    for (let i = 0; i < this.state.reportData.clientRequests.length; i++) {
                        result.caseGuids!.push(this.state.reportData.clientRequests[i].caseGuid);
                        if (this.state.reportData.clientRequests[i].notes) {
                            for (
                                let j = 0;
                                j < this.state.reportData.clientRequests[i].notes.length;
                                j++
                            ) {
                                result.noteGuids!.push(
                                    this.state.reportData.clientRequests[i].notes[j].guid
                                );
                            }
                        }
                    }
                    break;
                case TimelineReportSections.ZoneNotes:
                    for (let i = 0; i < this.state.reportData.zoneNotes.length; i++) {
                        result.noteGuids!.push(this.state.reportData.zoneNotes[i].noteGuid);
                    }
                    break;
                default:
            }
        }
        return result;
    };

    handleCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections,
        entityType: EntityTypes
    ) => {
        const inputModel = this.state.inputModel;
        const selectAllSections = this.state.selectAllSections;

        if (event.target.checked) {
            if (!inputModel.reportSectionDetails) inputModel.reportSectionDetails = [];

            if (inputModel.reportSectionDetails.findIndex((x) => x.section === section) === -1)
                inputModel.reportSectionDetails.push({
                    section: section,
                    sectionName: section.toString(),
                });

            const sectionMatch = inputModel.reportSectionDetails.find((x) => x.section === section);
            if (!sectionMatch!.caseGuids) sectionMatch!.caseGuids = [];
            if (!sectionMatch!.noteGuids) sectionMatch!.noteGuids = [];

            switch (entityType) {
                case EntityTypes.Case: {
                    if (sectionMatch!.caseGuids!.findIndex((x) => x === event.target.name) === -1)
                        sectionMatch!.caseGuids!.push(event.target.name);

                    const noteGuidsToAddToSelectedList = this.getNoteGuidsByCaseAndSection(
                        event.target.name,
                        section
                    );
                    if (noteGuidsToAddToSelectedList.length > 0) {
                        for (let i = 0; i < noteGuidsToAddToSelectedList.length; i++) {
                            if (
                                sectionMatch!.noteGuids!.findIndex(
                                    (x) => x === noteGuidsToAddToSelectedList[i]
                                ) === -1
                            )
                                sectionMatch!.noteGuids!.push(noteGuidsToAddToSelectedList[i]);
                        }
                    }
                    break;
                }
                case EntityTypes.Note: {
                    if (sectionMatch!.noteGuids!.findIndex((x) => x === event.target.name) === -1) {
                        sectionMatch!.noteGuids!.push(event.target.name);

                        // Find the parent;  if it is not checked, check it ( for all sections except other client requests)
                        const parentCaseGuid = this.getParentCaseGuidByNoteGuid(
                            event.target.name,
                            section
                        );
                        if (
                            parentCaseGuid &&
                            sectionMatch!.caseGuids!.findIndex((x) => x === parentCaseGuid) == -1
                        )
                            sectionMatch!.caseGuids!.push(parentCaseGuid);
                    }
                    break;
                }
                default:
            }
        } else {
            if (selectAllSections.findIndex((x) => x === section) > -1)
                selectAllSections.splice(
                    selectAllSections.findIndex((x) => x === section),
                    1
                );

            if (inputModel.reportSectionDetails && inputModel.reportSectionDetails) {
                const sectionMatch = inputModel.reportSectionDetails.find(
                    (x) => x.section === section
                );
                if (sectionMatch) {
                    switch (entityType) {
                        case EntityTypes.Case:
                            if (
                                sectionMatch.caseGuids &&
                                sectionMatch.caseGuids.findIndex((x) => x === event.target.name) >
                                    -1
                            ) {
                                sectionMatch.caseGuids.splice(
                                    sectionMatch.caseGuids.findIndex(
                                        (x) => x === event.target.name
                                    ),
                                    1
                                );
                                if (sectionMatch!.noteGuids) {
                                    const noteGuidsToRemoveFromSelectedList =
                                        this.getNoteGuidsByCaseAndSection(
                                            event.target.name,
                                            section
                                        );
                                    if (noteGuidsToRemoveFromSelectedList.length > 0) {
                                        for (
                                            let i = 0;
                                            i < noteGuidsToRemoveFromSelectedList.length;
                                            i++
                                        ) {
                                            if (
                                                sectionMatch!.noteGuids!.findIndex(
                                                    (x) =>
                                                        x === noteGuidsToRemoveFromSelectedList[i]
                                                ) > -1
                                            )
                                                sectionMatch!.noteGuids!.splice(
                                                    sectionMatch!.noteGuids!.findIndex(
                                                        (x) =>
                                                            x ===
                                                            noteGuidsToRemoveFromSelectedList[i]
                                                    ),
                                                    1
                                                );
                                        }
                                    }
                                }
                            }
                            break;
                        case EntityTypes.Note:
                            if (
                                sectionMatch.noteGuids &&
                                sectionMatch.noteGuids.findIndex((x) => x === event.target.name) >
                                    -1
                            )
                                sectionMatch.noteGuids.splice(
                                    sectionMatch.noteGuids.findIndex(
                                        (x) => x === event.target.name
                                    ),
                                    1
                                );
                            break;
                        default:
                    }
                }
            }
        }

        this.setState({
            inputModel: inputModel,
            selectAllSections: selectAllSections,
        });
    };

    generateAndDownloadReport = async () => {
        this.setState({ pendingResponse: true, validation: {} });

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + ApiRoutes.TimelineRecapExport, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this.setState({ pendingResponse: false, validation: {} });
                let fileName = 'WeeklyRecapReport.pdf';
                const blob = xhr.response;

                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    const contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (let i = 0; i < contentDispositionItems.length; i++) {
                            const currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                const n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                const validation = this.state.validation;
                validation.model = [DisplayMessages.ReportGenerateError];
                this.setState({ pendingResponse: false, validation: validation });
            }
        };

        xhr.send(JSON.stringify(this.state.inputModel));
    };

    getHelpText(key: string) {
        const match = this.state.organizationSettings.find((x) => x.settingName === key);
        if (match) return match.settingValue;
        return '';
    }

    buildReportSection(item: IReportCaseDetailModel, section: TimelineReportSections) {
        const rows: any[] = [];
        rows.push(
            <tr>
                <td width={25} className={item.notes.length > 0 ? 'border-0' : ''}>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        name={item.caseGuid}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            this.handleCheckedChange(e, section, EntityTypes.Case);
                        }}
                        checked={this.getCheckedValue(section, EntityTypes.Case, item.caseGuid)}
                    />
                </td>
                <td className={(item.notes.length > 0 ? 'border-0 ' : '') + 'col-sm-2'}>
                    <Link target="_blank" to={LocalRoutes.CaseFile.replace(':guid', item.caseGuid)}>
                        {item.caseName}
                    </Link>
                    {item.priorServed ? '*' : null}
                </td>
                {section === TimelineReportSections.InitialCaseEval ? (
                    <td className={(item.notes.length > 0 ? 'border-0 ' : '') + 'col-sm-1'}>
                        {Common.dateFormat(item.serviceDate)}
                    </td>
                ) : null}
                {section === TimelineReportSections.TenderedCases ? (
                    <td className={(item.notes.length > 0 ? 'border-0 ' : '') + 'col-sm-1'}>
                        {item.status!.displayName ? item.status!.displayName : item.status!.name!}
                    </td>
                ) : null}
                <td
                    className={
                        (item.notes.length > 0 ? 'border-0 ' : '') +
                        (section === TimelineReportSections.InitialCaseEval ||
                        section === TimelineReportSections.TenderedCases
                            ? 'col-sm-2'
                            : 'col-sm-3')
                    }
                >
                    {item.jurisdiction}
                </td>
                <td className={(item.notes.length > 0 ? 'border-0 ' : '') + 'col-sm-3'}>
                    {item.plaintiffsFirmDisplayName}
                </td>
                <td
                    className={
                        (item.notes.length > 0 ? 'border-0 ' : '') +
                        (section === TimelineReportSections.InitialCaseEval
                            ? 'col-sm-1'
                            : 'col-sm-2')
                    }
                >
                    {item.diagnosis}
                </td>
                <td className={item.notes.length > 0 ? 'border-0' : ''}>
                    {item.additionalCaseIdentifier}
                </td>
                {Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, this.props.user) ? (
                    <td
                        width={45}
                        className={(item.notes.length > 0 ? 'border-0 ' : '') + 'center'}
                    >
                        {' '}
                        <button
                            className="btn-no-bg"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                this.handleEasyUpdate(e, item.caseGuid);
                            }}
                        >
                            <i
                                className={`fal fa-edit ${
                                    this.state.easyUpdateAttemptedCaseList.filter(
                                        (x) => x === item.caseGuid
                                    ).length > 0
                                        ? 'color-gray'
                                        : 'color-black'
                                } `}
                            />
                        </button>
                    </td>
                ) : null}
            </tr>
        );

        let colSpan = 5;
        switch (section) {
            case TimelineReportSections.InitialCaseEval:
            case TimelineReportSections.TenderedCases:
                colSpan = 6;
                break;
            default:
        }

        if (item.notes)
            this.buildNotesRows(item, section, colSpan).map((row: any) => {
                rows.push(row);
            });
        return rows;
    }

    buildNotesRows(
        caseItem: IReportCaseDetailModel,
        section: TimelineReportSections,
        colSpan: number
    ) {
        const rows: any[] = [];
        for (let i = 0; i < caseItem.notes.length; i++) {
            const currentNote: INoteModel = {
                guid: caseItem.notes[i].guid,
                type: { id: NoteTypes.CaseNote },
                status: { id: 0 },
                purpose: { id: 0 },
                caseGuid: caseItem.caseGuid,
                caseName: caseItem.caseName,
                qScore: caseItem.qScore,
                qProjection: caseItem.qProjection,
            };

            rows.push(
                <tr key={i}>
                    <td className={caseItem.notes.length - 1 === i ? '' : 'border-0'}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name={caseItem.notes[i].guid}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.handleCheckedChange(e, section, EntityTypes.Note);
                            }}
                            checked={this.getCheckedValue(
                                section,
                                EntityTypes.Note,
                                caseItem.notes[i].guid
                            )}
                        />
                    </td>
                    <td
                        className={caseItem.notes.length - 1 === i ? '' : 'border-0'}
                        colSpan={colSpan}
                    >
                        {caseItem.notes[i].content ? (
                            <TextWithLineBreaks text={caseItem.notes[i].content} />
                        ) : null}
                    </td>
                    {Authorization.userHasRight(UserRightsEnum.EditCaseNote, this.props.user) &&
                    (Authorization.getUserDefaultOrganizationDataScope(this.props.user) !==
                        DataScopesEnum.LocalBasic.Value ||
                        caseItem.notes[i].allowLocalEdit) ? (
                        <td
                            width={45}
                            className={
                                (caseItem.notes.length - 1 === i ? '' : 'border-0 ') + 'center'
                            }
                        >
                            <button
                                className="btn btn-no-bg"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    this.handleNoteEdit(e, currentNote);
                                }}
                            >
                                <i
                                    className={`fal fa-pen ${
                                        this.state.noteEditAttemptedGuidList.filter(
                                            (x) => x === currentNote.guid
                                        ).length > 0
                                            ? 'color-gray'
                                            : 'color-black'
                                    } `}
                                />
                            </button>
                        </td>
                    ) : null}
                </tr>
            );
        }

        return rows;
    }

    getNoteCategoryTypesByNoteType = () => {
        const noteTypeId = this.state.currentNote.type ? this.state.currentNote.type.id : 0;
        return this.state.noteCategoryTypes.filter((x) => x.noteType.id === noteTypeId);
    };

    handleNoteEdit = (e: React.FormEvent<HTMLButtonElement>, noteItem: INoteModel) => {
        if (!noteItem.guid) return;

        const noteEditAttemptedGuidList = this.state.noteEditAttemptedGuidList;
        if (noteEditAttemptedGuidList.filter((x) => x === noteItem.guid).length === 0)
            noteEditAttemptedGuidList.push(noteItem.guid);
        this.setState({
            openNoteEditor: true,
            currentNote: noteItem,
        });
    };

    onSaveNoteComplete = () => {
        const validation = this.state.validation;
        validation.categories = [];
        validation.content = [];
        this.setState(
            {
                openNoteEditor: false,
                currentNote: {
                    status: { id: NoteStatusTypes.Open },
                    purpose: { id: NotePurposeTypesEnum.Info },
                },
                validation: validation,
            },
            this.getReportData
        );
    };

    onCancelAddEditNote = () => {
        const validation = this.state.validation;
        validation.categories = [];
        validation.content = [];
        this.setState({
            openNoteEditor: false,
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            validation: validation,
        });
    };

    easyUpdateDone = async () => {
        this.setState({ easyUpdateMode: false, easyUpdateCaseGuid: undefined }, this.getReportData);
    };

    handleCommentsChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
        reportSection: ILookupModel
    ) => {
        let inputModel = this.state.inputModel;
        if (!inputModel) {
            inputModel = { reportSectionDetails: [] };
        }
        if (!inputModel.reportSectionDetails) {
            inputModel.reportSectionDetails = [];
        }

        const match = inputModel.reportSectionDetails.find((x) => x.section === reportSection.id);
        if (match) {
            match.comment = event.target.value;
        } else {
            inputModel.reportSectionDetails.push({
                section: reportSection.id,
                sectionName: reportSection.name || '',
                comment: event.target.value,
            });
        }
        this.setState({ inputModel: inputModel });
    };

    getCommentText = (reportSection: number) => {
        if (this.state.inputModel && this.state.inputModel.reportSectionDetails) {
            const sectionMatch = this.state.inputModel.reportSectionDetails.find(
                (x) => x.section === reportSection
            );
            if (sectionMatch) return sectionMatch.comment;
        }

        return '';
    };

    clearComments = () => {
        const inputModel = this.state.inputModel;
        if (inputModel && inputModel.reportSectionDetails) {
            for (let i = 0; i < inputModel.reportSectionDetails.length; i++) {
                inputModel.reportSectionDetails[i].comment = '';
                inputModel.reportSectionDetails[i].excludeInReport = undefined;
            }
            this.setState({ inputModel: inputModel, showClearCommentsConfirmModal: false });
        }
    };

    getSectionSettingsUI = (section: ILookupModel) => {
        const sectionSelected =
            this.state.inputModel.reportSectionDetails &&
            this.state.inputModel.reportSectionDetails.filter((x) => x.section === section.id)
                .length > 0;
        return (
            <div className="mt-2">
                <input
                    id="chkSection"
                    className="form-check-input d-inline-block mt-1"
                    type="checkbox"
                    checked={sectionSelected}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.handleSectionCheckedChange(e, section.id);
                    }}
                />
                <span className="ms-2 align-top">{section.displayName}</span>
            </div>
        );
    };

    handleCheckedChangeAlt = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputModel = this.state.inputModel;
        if (!inputModel.additionalSettings) inputModel.additionalSettings = {};
        inputModel.additionalSettings.caseInventoryByStateDisplayNumberOfPlaintiffs =
            event.target.checked;
        this.setState({ inputModel: inputModel });
    };

    handleSectionCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections
    ) => {
        const inputModel = this.state.inputModel;
        const selectAllSections = this.state.selectAllSections;

        if (!inputModel.reportSectionDetails) {
            inputModel.reportSectionDetails = [];
        }

        if (event.target.checked) {
            const sectionMatch = inputModel.reportSectionDetails.find((x) => x.section === section);
            if (sectionMatch) sectionMatch.excludeInReport = false;
            else
                inputModel.reportSectionDetails!.push({
                    section: section,
                    sectionName: section.toString(),
                });

            this.setState({ inputModel: inputModel, selectAllSections: selectAllSections });
        } else {
            const sectionToRemove = inputModel.reportSectionDetails.find(
                (x) => x.section === section
            );
            if (sectionToRemove && sectionToRemove.comment)
                this.setState({ sectionToBeRemoved: section });
            else this.removeSection(section);
        }

        if (!this.state.showSettingsModal) {
            this.setState({ validation: this.runValidation() ?? {} });
        }
    };

    handleConfirmRemoveSection = () => {
        if (this.state.sectionToBeRemoved) this.removeSection(this.state.sectionToBeRemoved);
    };

    removeSection = (section: TimelineReportSections) => {
        const inputModel = this.state.inputModel;
        const selectAllSections = this.state.selectAllSections;

        if (!inputModel.reportSectionDetails) return;

        inputModel.reportSectionDetails!.splice(
            inputModel.reportSectionDetails.findIndex((x) => x.section === section),
            1
        );

        if (selectAllSections.findIndex((x) => x === section) >= 0)
            selectAllSections.splice(
                selectAllSections.findIndex((x) => x === section),
                1
            );

        this.setState({
            inputModel: inputModel,
            selectAllSections: selectAllSections,
            sectionToBeRemoved: undefined,
        });
    };

    handleCheckmateSelectChange = (selectedItem: any, key: string) => {
        if (selectedItem === null || !selectedItem.id) return;

        const inputModel = this.state.inputModel;
        if (!inputModel.additionalSettings) inputModel.additionalSettings = {};
        switch (key) {
            case ReportSettings_PlaintiffsFirmDisplayMode:
                inputModel.additionalSettings.plaintiffsFirmDisplayMode = selectedItem.id;
                this.setState({
                    plaintiffsFirmDisplayMode: selectedItem,
                });
                break;
            case ReportSettings_ReportCaseDetailsLastColumn:
                inputModel.additionalSettings.reportCaseDetailsLastColumn = selectedItem.id;
                inputModel.additionalSettings.reportCaseDetailsLastColumnDisplayName = '';
                this.setState({
                    reportCaseDetailsLastColumn: selectedItem,
                });
                break;
            default:
                break;
        }
    };

    buildRequestForAuthRows(item: IReportCaseDetailModel) {
        const rows: any[] = [];
        rows.push(
            <tr>
                <td width={15}>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        name={item.caseGuid}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            this.handleCheckedChange(
                                e,
                                TimelineReportSections.RequestForAuth,
                                EntityTypes.Case
                            );
                        }}
                        checked={this.getCheckedValue(
                            TimelineReportSections.RequestForAuth,
                            EntityTypes.Case,
                            item.caseGuid
                        )}
                    />
                </td>
                <td>
                    <Link target="_blank" to={LocalRoutes.CaseFile.replace(':guid', item.caseGuid)}>
                        {item.caseName}
                    </Link>
                </td>
                <td className="col-sm-1">
                    {item.authorityRequested
                        ? Common.formatCurrency(item.authorityRequested.toString())
                        : ''}
                </td>
                <td className="col-sm-1">{Common.dateFormat(item.authorityRequestedDate)}</td>
                <td className="col-sm-2">{item.jurisdiction}</td>
                <td className="col-sm-2">{item.plaintiffsFirmDisplayName}</td>
                <td>{item.diagnosis}</td>
                <td className="col-sm-1">{Common.dateFormat(item.trialDate)}</td>
                <td className="col-sm-1">
                    {item.demand ? Common.formatCurrency(item.demand.toString()) : ''}
                </td>
                <td className="col-sm-1">{Common.dateFormat(item.clientExposureStartDate)}</td>
                {Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, this.props.user) ? (
                    <td
                        width={45}
                        className={(item.notes.length > 0 ? 'border-0 ' : '') + 'center'}
                    >
                        {' '}
                        <button
                            className="btn-no-bg"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                this.handleEasyUpdate(e, item.caseGuid);
                            }}
                        >
                            <i
                                className={`fal fa-edit ${
                                    this.state.easyUpdateAttemptedCaseList.filter(
                                        (x) => x === item.caseGuid
                                    ).length > 0
                                        ? 'color-gray'
                                        : 'color-black'
                                } `}
                            />
                        </button>
                    </td>
                ) : null}
            </tr>
        );

        if (item.notes)
            this.buildNotesRows(item, TimelineReportSections.RequestForAuth, 8).map((row: any) => {
                rows.push(row);
            });
        return rows;
    }

    buildZoneNoteRows = (item: IZoneNoteModel) => {
        const rows = [];
        rows.push(
            <tr>
                <td className="border-0">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        name={item.noteGuid}
                        checked={this.getCheckedValue(
                            TimelineReportSections.ZoneNotes,
                            EntityTypes.Note,
                            item.noteGuid
                        )}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            this.handleCheckedChange(
                                e,
                                TimelineReportSections.ZoneNotes,
                                EntityTypes.Note
                            );
                        }}
                    />
                </td>
                <td className="col-sm-6 border-0">{item.topic}</td>
                <td className="border-0">{item.source}</td>
                {Authorization.userHasRight(UserRightsEnum.EditZoneNote, this.props.user) ? (
                    <td className="border-0" width={45}>
                        <button
                            className="btn btn-no-bg float-end"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                this.handleNoteEdit(e, {
                                    guid: item.noteGuid,
                                    type: { id: NoteTypes.ZoneNote },
                                    status: { id: 0 },
                                    purpose: { id: 0 },
                                });
                            }}
                        >
                            <i
                                className={`fal fa-pen ${
                                    this.state.noteEditAttemptedGuidList.filter(
                                        (x) => x === item.noteGuid
                                    ).length > 0
                                        ? 'color-gray'
                                        : 'color-black'
                                } `}
                            />
                        </button>
                    </td>
                ) : null}
            </tr>
        );

        rows.push(
            <tr>
                <td />
                <td colSpan={5}>
                    {item.noteContent ? <TextWithLineBreaks text={item.noteContent} /> : null}
                </td>
            </tr>
        );
        return rows;
    };

    getCommentUI = (section: number, rowCount?: number) => {
        const text = this.getCommentText(section);
        if (text) return <div className="font-size-xs mb-3">{text}</div>;
        else if (rowCount != undefined && rowCount === 0)
            return <div className="mb-3 margin-left-20">No data found for this time period</div>;
    };

    handleEasyUpdate = (
        e: React.FormEvent<HTMLButtonElement>,
        caseGuid: string,
        noteGuid?: string
    ) => {
        const easyUpdateAttemptedCaseList = this.state.easyUpdateAttemptedCaseList;
        const easyUpdateAttemptedNoteGuidList = this.state.noteEditAttemptedGuidList;
        if (easyUpdateAttemptedCaseList.filter((x) => x === caseGuid).length === 0)
            easyUpdateAttemptedCaseList.push(caseGuid);
        if (noteGuid) easyUpdateAttemptedNoteGuidList.push(noteGuid);
        this.setState({
            easyUpdateMode: true,
            easyUpdateCaseGuid: caseGuid,
            easyUpdateAttemptedCaseList: easyUpdateAttemptedCaseList,
            noteEditAttemptedGuidList: easyUpdateAttemptedNoteGuidList,
        });
    };

    handleClearSettings = () => {
        this.setState({
            reportData: undefined,
            validation: {},
            inputModel: this.getDefaultReportInputModel(),
            plaintiffsFirmDisplayMode: {
                label: 'National Plaintiffs Firm (If Exists) or Plaintiffs Firm ',
                value: PlaintiffsFirmDisplayMode.Hierarchy.toString(),
                id: PlaintiffsFirmDisplayMode.Hierarchy,
            },
            reportCaseDetailsLastColumn: {
                label: 'Product/Premises',
                value: ReportCaseDetailsLastColumn.ProductPremises.toString(),
                id: ReportCaseDetailsLastColumn.ProductPremises,
            },
            caseInventoryByStateDisplayMode: {
                label: 'Top 5 States',
                value: CaseInventoryByStateDisplayMode.Top5.toString(),
                id: CaseInventoryByStateDisplayMode.Top5,
            },
            showCommentsModal: false,
            showClearSettingssConfirmModal: false,
        });
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;

        if (this.state.openNoteEditor)
            return (
                <NoteEditor
                    readOnly={NoteHelper.isNoteReadOnly(
                        this.state.currentNote,
                        this.props.user,
                        Authorization.userHasRight(UserRightsEnum.ViewCaseNotes, this.props.user),
                        Authorization.userHasRight(UserRightsEnum.EditCaseNote, this.props.user),
                        Authorization.userHasRight(UserRightsEnum.AddCaseNote, this.props.user)
                    )}
                    user={this.props.user}
                    caseTitle={NoteHelper.getCaseTitle(this.state.currentNote)}
                    noteCategoryTypes={this.getNoteCategoryTypesByNoteType()}
                    currentNote={this.state.currentNote}
                    caseLocalCounselGuid={this.state.currentNote.localCounselOrganizationGuid}
                    defaultAlertTarget={
                        Authorization.isLocalScope(this.props.user)
                            ? this.state.currentNote.caseManager
                            : undefined
                    }
                    defaultWatchTargets={
                        Authorization.isLocalScope(this.props.user) &&
                        this.state.currentNote.alternateCaseManager
                            ? [this.state.currentNote.alternateCaseManager]
                            : undefined
                    }
                    handleSaveComplete={this.onSaveNoteComplete}
                    handleCancel={this.onCancelAddEditNote}
                />
            );

        return (
            <>
                {Authorization.userHasRight(UserRightsEnum.EasyUpdate, this.props.user) ? (
                    <CaseEasyUpdate
                        user={this.props.user}
                        caseGuid={this.state.easyUpdateCaseGuid}
                        onComplete={this.easyUpdateDone}
                        onCancel={() => {
                            this.setState({
                                easyUpdateMode: false,
                                easyUpdateCaseGuid: undefined,
                            });
                        }}
                        hidden={!this.state.easyUpdateMode}
                    />
                ) : null}
                {this.state.easyUpdateMode ? null : (
                    <>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <h1>
                                    Weekly Recap
                                    {this.state.inputModel &&
                                    this.state.inputModel.startDate &&
                                    this.state.inputModel.endDate ? (
                                        <span className="font-size-xs ms-3">
                                            {Common.dateFormat(
                                                this.state.inputModel.startDate.toString()
                                            ) +
                                                ' to ' +
                                                Common.dateFormat(
                                                    this.state.inputModel.endDate.toString()
                                                )}
                                        </span>
                                    ) : null}
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                {this.state.reportData ? (
                                    <button
                                        className="btn btn-orange float-end text-gray"
                                        onClick={this.getReportData}
                                        disabled={
                                            (this.state.inputModel.reportSectionDetails?.length ??
                                                0) === 0
                                        }
                                    >
                                        Refresh
                                    </button>
                                ) : null}
                                <button
                                    className="btn btn-no-bg float-end text-gray"
                                    onClick={() => this.setState({ showSettingsModal: true })}
                                >
                                    <i className="fal fa-user-cog" />
                                    &nbsp;Settings
                                </button>
                                {this.state.reportData ? (
                                    <>
                                        <button
                                            className="btn btn-no-bg float-end text-gray"
                                            onClick={() =>
                                                this.setState({
                                                    currentCommentSection: 0,
                                                    showCommentsModal: true,
                                                })
                                            }
                                        >
                                            <i className="fal fa-comment-lines" />
                                            &nbsp;Comments
                                        </button>
                                        <button
                                            className="btn btn-no-bg float-end text-gray"
                                            onClick={this.generateAndDownloadReport}
                                        >
                                            <i className="fal fa-file-pdf" />
                                            &nbsp;PDF
                                        </button>
                                    </>
                                ) : null}
                            </div>
                        </div>

                        {this.state.validation.model && (
                            <div className="mb-2">
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                        )}

                        {this.state.validation.settings && !this.state.showSettingsModal && (
                            <div className="mb-2">
                                <span className="text-danger">
                                    {this.state.validation.settings}
                                </span>
                            </div>
                        )}

                        {this.state.reportData ? (
                            <div>
                                {/***********************************  INVENTORY  **********************************************************************************************/}
                                <h4>
                                    <input
                                        id="chkSectionCaseInventory"
                                        className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                        type="checkbox"
                                        checked={
                                            this.state.inputModel.reportSectionDetails &&
                                            this.state.inputModel.reportSectionDetails.filter(
                                                (x) =>
                                                    x.section ===
                                                    TimelineReportSections.CaseInventory
                                            ).length > 0
                                                ? true
                                                : false
                                        }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleSectionCheckedChange(
                                                e,
                                                TimelineReportSections.CaseInventory
                                            );
                                        }}
                                    />
                                    Weekly Change in Case Volume
                                    <button
                                        className="btn btn-no-bg text-gray"
                                        onClick={() =>
                                            this.setState({
                                                showCommentsModal: true,
                                                currentCommentSection:
                                                    TimelineReportSections.CaseInventory,
                                            })
                                        }
                                    >
                                        <i className="fal fa-comment-lines" />
                                        &nbsp;Comments
                                    </button>
                                </h4>
                                <table className="table thick-outer-border">
                                    <thead>
                                        <tr>
                                            {this.state.reportData.inventory.map(
                                                (item: IKeyValuePairModel) => {
                                                    return (
                                                        <th className="align-top text-center">
                                                            {item
                                                                .key!.split('<br/>')
                                                                .map((x, key) => {
                                                                    return (
                                                                        <span key={key}>
                                                                            {x}
                                                                            <br />
                                                                        </span>
                                                                    );
                                                                })}
                                                        </th>
                                                    );
                                                }
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody className="align-top text-center">
                                        <tr>
                                            {this.state.reportData.inventory.map(
                                                (item: IKeyValuePairModel) => {
                                                    return <td>{item.value}</td>;
                                                }
                                            )}
                                        </tr>
                                    </tbody>
                                </table>

                                {this.getCommentUI(
                                    TimelineReportSections.CaseInventory,
                                    this.state.reportData.inventory.length
                                )}

                                {/*********************************** NEW CASES RECAP  **********************************************************************************************/}
                                <h4>
                                    <input
                                        id="chkSectionCaseInventory"
                                        className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                        type="checkbox"
                                        checked={
                                            this.state.inputModel.reportSectionDetails &&
                                            this.state.inputModel.reportSectionDetails.filter(
                                                (x) =>
                                                    x.section === TimelineReportSections.CaseInfoNew
                                            ).length > 0
                                                ? true
                                                : false
                                        }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleSectionCheckedChange(
                                                e,
                                                TimelineReportSections.CaseInfoNew
                                            );
                                        }}
                                    />
                                    New Cases Recap
                                    <button
                                        className="btn btn-no-bg text-gray"
                                        onClick={() =>
                                            this.setState({
                                                showCommentsModal: true,
                                                currentCommentSection:
                                                    TimelineReportSections.CaseInfoNew,
                                            })
                                        }
                                    >
                                        <i className="fal fa-comment-lines" />
                                        &nbsp;Comments
                                    </button>
                                </h4>
                                <>
                                    {this.state.reportData.caseInfo.filter(
                                        (x) => x.status.id === TimelineReportStatusEnum.New
                                    ).length > 0 ? (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th /* width={25} */ className="border-0" />
                                                    <th className="col-sm-2">Case Name</th>
                                                    <th className="col-sm-2">Jurisdiction</th>
                                                    <th className="col-sm-3">Plaintiffs Firm</th>
                                                    <th className="col-sm-2">Disease</th>
                                                    <th>
                                                        {this.state.inputModel.additionalSettings
                                                            ? this.state.inputModel
                                                                  .additionalSettings
                                                                  .reportCaseDetailsLastColumnDisplayName
                                                            : 'Last Column Header Name'}
                                                    </th>
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.CaseEasyUpdate,
                                                        this.props.user
                                                    ) ? (
                                                        <th></th>
                                                    ) : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.reportData.caseInfo
                                                    .filter(
                                                        (x) =>
                                                            x.status.id ===
                                                            TimelineReportStatusEnum.New
                                                    )
                                                    .map((item: IReportCaseDetailModel) => {
                                                        return (
                                                            <tr>
                                                                <td
                                                                    width={25}
                                                                    className="border-0"
                                                                />
                                                                <td className="col-sm-2">
                                                                    <Link
                                                                        target="_blank"
                                                                        to={LocalRoutes.CaseFile.replace(
                                                                            ':guid',
                                                                            item.caseGuid
                                                                        )}
                                                                    >
                                                                        {item.caseName}
                                                                    </Link>
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.jurisdiction}
                                                                </td>
                                                                <td className="col-sm-3">
                                                                    {item.plaintiffsFirmDisplayName}
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.diagnosis}
                                                                </td>
                                                                <td>
                                                                    {item.additionalCaseIdentifier}
                                                                </td>
                                                                {Authorization.isAuthorizedToRoute(
                                                                    LocalRoutes.CaseEasyUpdate,
                                                                    this.props.user
                                                                ) ? (
                                                                    <td
                                                                        width={45}
                                                                        className={
                                                                            (item.notes.length > 0
                                                                                ? 'border-0 '
                                                                                : '') + 'center'
                                                                        }
                                                                    >
                                                                        <button
                                                                            className="btn-no-bg"
                                                                            onClick={(
                                                                                e: React.MouseEvent<HTMLButtonElement>
                                                                            ) => {
                                                                                this.handleEasyUpdate(
                                                                                    e,
                                                                                    item.caseGuid
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className={`fal fa-edit ${
                                                                                    this.state.easyUpdateAttemptedCaseList.filter(
                                                                                        (x) =>
                                                                                            x ===
                                                                                            item.caseGuid
                                                                                    ).length > 0
                                                                                        ? 'color-gray'
                                                                                        : 'color-black'
                                                                                } `}
                                                                            />
                                                                        </button>
                                                                    </td>
                                                                ) : null}
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    ) : null}
                                    {this.getCommentUI(
                                        TimelineReportSections.CaseInfoNew,
                                        this.state.reportData.caseInfo.filter(
                                            (x) => x.status.id === TimelineReportStatusEnum.New
                                        ).length
                                    )}
                                </>

                                {/***********************************  DISMISSED CASES RECAP*********************************************************************************************/}
                                <h4>
                                    <input
                                        id="chkSectionCaseInventory"
                                        className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                        type="checkbox"
                                        checked={
                                            this.state.inputModel.reportSectionDetails &&
                                            this.state.inputModel.reportSectionDetails.filter(
                                                (x) =>
                                                    x.section ===
                                                    TimelineReportSections.CaseInfoDismissed
                                            ).length > 0
                                                ? true
                                                : false
                                        }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleSectionCheckedChange(
                                                e,
                                                TimelineReportSections.CaseInfoDismissed
                                            );
                                        }}
                                    />
                                    Dismissed Cases Recap
                                    <button
                                        className="btn btn-no-bg text-gray"
                                        onClick={() =>
                                            this.setState({
                                                showCommentsModal: true,
                                                currentCommentSection:
                                                    TimelineReportSections.CaseInfoDismissed,
                                            })
                                        }
                                    >
                                        <i className="fal fa-comment-lines" />
                                        &nbsp;Comments
                                    </button>
                                </h4>
                                <>
                                    {this.state.reportData.caseInfo.filter(
                                        (x) => x.status.id === TimelineReportStatusEnum.Dismissed
                                    ).length > 0 ? (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th /* width={25} */ className="border-0" />
                                                    <th className="col-sm-2">Case Name</th>
                                                    <th className="col-sm-2">Jurisdiction</th>
                                                    <th className="col-sm-3">Plaintiffs Firm</th>
                                                    <th className="col-sm-2">Disease</th>
                                                    <th>
                                                        {this.state.inputModel.additionalSettings
                                                            ? this.state.inputModel
                                                                  .additionalSettings
                                                                  .reportCaseDetailsLastColumnDisplayName
                                                            : 'Last Column Header Name'}
                                                    </th>
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.CaseEasyUpdate,
                                                        this.props.user
                                                    ) ? (
                                                        <th></th>
                                                    ) : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.reportData.caseInfo
                                                    .filter(
                                                        (x) =>
                                                            x.status.id ===
                                                            TimelineReportStatusEnum.Dismissed
                                                    )
                                                    .map((item: IReportCaseDetailModel) => {
                                                        return (
                                                            <tr>
                                                                <td
                                                                    width={25}
                                                                    className="border-0"
                                                                />
                                                                <td className="col-sm-2">
                                                                    <Link
                                                                        target="_blank"
                                                                        to={LocalRoutes.CaseFile.replace(
                                                                            ':guid',
                                                                            item.caseGuid
                                                                        )}
                                                                    >
                                                                        {item.caseName}
                                                                    </Link>
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.jurisdiction}
                                                                </td>
                                                                <td className="col-sm-3">
                                                                    {item.plaintiffsFirmDisplayName}
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.diagnosis}
                                                                </td>
                                                                <td>
                                                                    {item.additionalCaseIdentifier}
                                                                </td>
                                                                {Authorization.isAuthorizedToRoute(
                                                                    LocalRoutes.CaseEasyUpdate,
                                                                    this.props.user
                                                                ) ? (
                                                                    <td
                                                                        width={45}
                                                                        className={
                                                                            (item.notes.length > 0
                                                                                ? 'border-0 '
                                                                                : '') + 'center'
                                                                        }
                                                                    >
                                                                        <button
                                                                            className="btn-no-bg"
                                                                            onClick={(
                                                                                e: React.MouseEvent<HTMLButtonElement>
                                                                            ) => {
                                                                                this.handleEasyUpdate(
                                                                                    e,
                                                                                    item.caseGuid
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className={`fal fa-edit ${
                                                                                    this.state.easyUpdateAttemptedCaseList.filter(
                                                                                        (x) =>
                                                                                            x ===
                                                                                            item.caseGuid
                                                                                    ).length > 0
                                                                                        ? 'color-gray'
                                                                                        : 'color-black'
                                                                                } `}
                                                                            />
                                                                        </button>
                                                                    </td>
                                                                ) : null}
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    ) : null}
                                    {this.getCommentUI(
                                        TimelineReportSections.CaseInfoDismissed,
                                        this.state.reportData.caseInfo.filter(
                                            (x) =>
                                                x.status.id === TimelineReportStatusEnum.Dismissed
                                        ).length
                                    )}
                                </>

                                {/*********************************** SETTLED CASES RECAP**********************************************************************************************/}
                                <h4>
                                    <input
                                        id="chkSectionCaseInventory"
                                        className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                        type="checkbox"
                                        checked={
                                            this.state.inputModel.reportSectionDetails &&
                                            this.state.inputModel.reportSectionDetails.filter(
                                                (x) =>
                                                    x.section ===
                                                    TimelineReportSections.CaseInfoSettled
                                            ).length > 0
                                                ? true
                                                : false
                                        }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleSectionCheckedChange(
                                                e,
                                                TimelineReportSections.CaseInfoSettled
                                            );
                                        }}
                                    />
                                    Settled Cases Recap
                                    <button
                                        className="btn btn-no-bg text-gray"
                                        onClick={() =>
                                            this.setState({
                                                showCommentsModal: true,
                                                currentCommentSection:
                                                    TimelineReportSections.CaseInfoSettled,
                                            })
                                        }
                                    >
                                        <i className="fal fa-comment-lines" />
                                        &nbsp;Comments
                                    </button>
                                </h4>
                                <>
                                    {this.state.reportData.caseInfo.filter(
                                        (x) => x.status.id === TimelineReportStatusEnum.Settled
                                    ).length > 0 ? (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th /* width={25} */ className="border-0" />
                                                    <th className="col-sm-2">Case Name</th>
                                                    <th className="col-sm-2">Jurisdiction</th>
                                                    <th className="col-sm-3">Plaintiffs Firm</th>
                                                    <th className="col-sm-2">Disease</th>
                                                    <th className="col-sm-1">Amount</th>
                                                    <th>Date</th>
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.CaseEasyUpdate,
                                                        this.props.user
                                                    ) ? (
                                                        <th></th>
                                                    ) : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.reportData.caseInfo
                                                    .filter(
                                                        (x) =>
                                                            x.status.id ===
                                                            TimelineReportStatusEnum.Settled
                                                    )
                                                    .map((item: IReportCaseDetailModel) => {
                                                        return (
                                                            <tr>
                                                                <td
                                                                    width={25}
                                                                    className="border-0"
                                                                />
                                                                <td className="col-sm-2">
                                                                    <Link
                                                                        target="_blank"
                                                                        to={LocalRoutes.CaseFile.replace(
                                                                            ':guid',
                                                                            item.caseGuid
                                                                        )}
                                                                    >
                                                                        {item.caseName}
                                                                    </Link>
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.jurisdiction}
                                                                </td>
                                                                <td className="col-sm-3">
                                                                    {item.plaintiffsFirmDisplayName}
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.diagnosis}
                                                                </td>
                                                                <td className="col-sm-1">
                                                                    {item.settlementAmount
                                                                        ? Common.formatCurrency(
                                                                              item.settlementAmount.toString()
                                                                          )
                                                                        : ''}
                                                                </td>
                                                                <td>
                                                                    {Common.dateFormat(
                                                                        item.resolutionDate
                                                                    )}
                                                                </td>
                                                                {Authorization.isAuthorizedToRoute(
                                                                    LocalRoutes.CaseEasyUpdate,
                                                                    this.props.user
                                                                ) ? (
                                                                    <td
                                                                        width={45}
                                                                        className={
                                                                            (item.notes.length > 0
                                                                                ? 'border-0 '
                                                                                : '') + 'center'
                                                                        }
                                                                    >
                                                                        <button
                                                                            className="btn-no-bg"
                                                                            onClick={(
                                                                                e: React.MouseEvent<HTMLButtonElement>
                                                                            ) => {
                                                                                this.handleEasyUpdate(
                                                                                    e,
                                                                                    item.caseGuid
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className={`fal fa-edit ${
                                                                                    this.state.easyUpdateAttemptedCaseList.filter(
                                                                                        (x) =>
                                                                                            x ===
                                                                                            item.caseGuid
                                                                                    ).length > 0
                                                                                        ? 'color-gray'
                                                                                        : 'color-black'
                                                                                } `}
                                                                            />
                                                                        </button>
                                                                    </td>
                                                                ) : null}
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    ) : null}
                                    {this.getCommentUI(
                                        TimelineReportSections.CaseInfoSettled,
                                        this.state.reportData.caseInfo.filter(
                                            (x) => x.status.id === TimelineReportStatusEnum.Settled
                                        ).length
                                    )}
                                </>

                                {/***********************************  INITIAL CASE EVAL **********************************************************************************************/}
                                <h4>
                                    <input
                                        id="chkSectionCaseInventory"
                                        className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                        type="checkbox"
                                        checked={
                                            this.state.inputModel.reportSectionDetails &&
                                            this.state.inputModel.reportSectionDetails.filter(
                                                (x) =>
                                                    x.section ===
                                                    TimelineReportSections.InitialCaseEval
                                            ).length > 0
                                                ? true
                                                : false
                                        }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleSectionCheckedChange(
                                                e,
                                                TimelineReportSections.InitialCaseEval
                                            );
                                        }}
                                    />
                                    Initial Case Evaluations
                                    <button
                                        className="btn btn-no-bg text-gray"
                                        onClick={() =>
                                            this.setState({
                                                showCommentsModal: true,
                                                currentCommentSection:
                                                    TimelineReportSections.InitialCaseEval,
                                            })
                                        }
                                    >
                                        <i className="fal fa-comment-lines" />
                                        &nbsp;Comments
                                    </button>
                                </h4>
                                <>
                                    {this.state.reportData.initialCaseEval.length > 0 ? (
                                        <div className="mb-2">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th /* width={25} */>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                name="selectAll"
                                                                checked={this.isSectionRowsSelectAllChecked(
                                                                    TimelineReportSections.InitialCaseEval
                                                                )}
                                                                onChange={(
                                                                    e: React.ChangeEvent<HTMLInputElement>
                                                                ) => {
                                                                    this.handleSelectAllCheckedChanged(
                                                                        e,
                                                                        TimelineReportSections.InitialCaseEval
                                                                    );
                                                                }}
                                                            />
                                                        </th>
                                                        <th className="col-sm-2">Case Name</th>
                                                        <th className="col-sm-1">Date Served</th>
                                                        <th className="col-sm-3">Jurisdiction</th>
                                                        <th className="col-sm-3">
                                                            Plaintiffs Firm
                                                        </th>
                                                        <th className="col-sm-1">Disease</th>
                                                        <th>
                                                            {this.state.inputModel
                                                                .additionalSettings
                                                                ? this.state.inputModel
                                                                      .additionalSettings
                                                                      .reportCaseDetailsLastColumnDisplayName
                                                                : 'Last Column Header Name'}
                                                        </th>
                                                        {Authorization.isAuthorizedToRoute(
                                                            LocalRoutes.CaseEasyUpdate,
                                                            this.props.user
                                                        ) ? (
                                                            <th></th>
                                                        ) : null}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.reportData.initialCaseEval.map(
                                                        (item: IReportCaseDetailModel) => {
                                                            return this.buildReportSection(
                                                                item,
                                                                TimelineReportSections.InitialCaseEval
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                            {this.state.reportData.initialCaseEval.filter(
                                                (x) => x.priorServed === true
                                            ).length > 0 ? (
                                                <span className="mb-2">
                                                    *Case received from client during this reporting
                                                    period but date served was prior to this
                                                    reporting period
                                                </span>
                                            ) : null}
                                        </div>
                                    ) : null}
                                    {this.getCommentUI(
                                        TimelineReportSections.InitialCaseEval,
                                        this.state.reportData.initialCaseEval.length
                                    )}
                                </>

                                {/***********************************  TENDERED CASES  **********************************************************************************************/}
                                <div>
                                    <h4>
                                        <input
                                            id="chkSectionCaseInventory"
                                            className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                            type="checkbox"
                                            checked={
                                                this.state.inputModel.reportSectionDetails &&
                                                this.state.inputModel.reportSectionDetails.filter(
                                                    (x) =>
                                                        x.section ===
                                                        TimelineReportSections.TenderedCases
                                                ).length > 0
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleSectionCheckedChange(
                                                    e,
                                                    TimelineReportSections.TenderedCases
                                                );
                                            }}
                                        />
                                        Tendered Cases
                                        <Help
                                            type={ApplicationHelpType.Info}
                                            additionalStyleClassNames="font-size-sm"
                                            title="Tendered Cases"
                                            helpText="Cases that are Tendered-Pending as of the last date of the reporting period will be displayed along with the most recent case note, Category = Case.  \n\nCases with a Tendered-Accepted date during the reporting period will be displayed along with the most recent case note, Category = Case."
                                        />
                                        <button
                                            className="btn btn-no-bg text-gray"
                                            onClick={() =>
                                                this.setState({
                                                    showCommentsModal: true,
                                                    currentCommentSection:
                                                        TimelineReportSections.TenderedCases,
                                                })
                                            }
                                        >
                                            <i className="fal fa-comment-lines" />
                                            &nbsp;Comments
                                        </button>
                                    </h4>
                                </div>
                                <>
                                    {this.state.reportData.tenderedCases.length > 0 ? (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th /* width={25} */>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="selectAll"
                                                            checked={this.isSectionRowsSelectAllChecked(
                                                                TimelineReportSections.TenderedCases
                                                            )}
                                                            onChange={(
                                                                e: React.ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                                this.handleSelectAllCheckedChanged(
                                                                    e,
                                                                    TimelineReportSections.TenderedCases
                                                                );
                                                            }}
                                                        />
                                                    </th>
                                                    <th className="col-sm-2">Case Name</th>
                                                    <th>Status</th>
                                                    <th className="col-sm-2">Jurisdiction</th>
                                                    <th className="col-sm-2">Plaintiffs Firm</th>
                                                    <th className="col-sm-2">Disease</th>
                                                    <th className="col-sm-2">
                                                        {this.state.inputModel.additionalSettings
                                                            ? this.state.inputModel
                                                                  .additionalSettings
                                                                  .reportCaseDetailsLastColumnDisplayName
                                                            : 'Last Column Header Name'}
                                                    </th>
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.CaseEasyUpdate,
                                                        this.props.user
                                                    ) ? (
                                                        <th></th>
                                                    ) : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.reportData.tenderedCases.map(
                                                    (item: IReportCaseDetailModel) => {
                                                        return this.buildReportSection(
                                                            item,
                                                            TimelineReportSections.TenderedCases
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    ) : null}
                                    {this.getCommentUI(
                                        TimelineReportSections.TenderedCases,
                                        this.state.reportData.tenderedCases.length
                                    )}
                                </>

                                {/***********************************  CLIENT DECISIONS OUTSTANDING **********************************************************************************************/}
                                <h4>
                                    <input
                                        id="chkSectionCaseInventory"
                                        className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                        type="checkbox"
                                        checked={
                                            this.state.inputModel.reportSectionDetails &&
                                            this.state.inputModel.reportSectionDetails.filter(
                                                (x) =>
                                                    x.section ===
                                                    TimelineReportSections.RequestForAuth
                                            ).length > 0
                                                ? true
                                                : false
                                        }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleSectionCheckedChange(
                                                e,
                                                TimelineReportSections.RequestForAuth
                                            );
                                        }}
                                    />
                                    Outstanding Requests for Authority
                                    <button
                                        className="btn btn-no-bg text-gray"
                                        onClick={() =>
                                            this.setState({
                                                showCommentsModal: true,
                                                currentCommentSection:
                                                    TimelineReportSections.RequestForAuth,
                                            })
                                        }
                                    >
                                        <i className="fal fa-comment-lines" />
                                        &nbsp;Comments
                                    </button>
                                </h4>
                                <>
                                    {this.state.reportData.clientRequests.length > 0 ? (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="selectAll"
                                                            checked={this.isSectionRowsSelectAllChecked(
                                                                TimelineReportSections.RequestForAuth
                                                            )}
                                                            onChange={(
                                                                e: React.ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                                this.handleSelectAllCheckedChanged(
                                                                    e,
                                                                    TimelineReportSections.RequestForAuth
                                                                );
                                                            }}
                                                        />
                                                    </th>
                                                    <th>Case</th>
                                                    <th className="col-sm-1">
                                                        Requested Authority
                                                    </th>
                                                    <th className="col-sm-1">Date Requested</th>
                                                    <th className="col-sm-2">Jurisdiction</th>
                                                    <th className="col-sm-2">Plaintiffs Firm</th>
                                                    <th className="col-sm-1">Disease</th>
                                                    <th className="col-sm-1">Trial Date</th>
                                                    <th className="col-sm-1">Demand</th>
                                                    <th className="col-sm-1">
                                                        Client Exposure Start Date
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.reportData.clientRequests.map(
                                                    (item: IReportCaseDetailModel) => {
                                                        return this.buildRequestForAuthRows(item);
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    ) : null}
                                    {this.getCommentUI(
                                        TimelineReportSections.RequestForAuth,
                                        this.state.reportData.clientRequests.length
                                    )}
                                </>

                                {/***********************************  RISK ISSUES  **********************************************************************************************/}
                                <h4>
                                    <input
                                        id="chkSectionCaseInventory"
                                        className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                        type="checkbox"
                                        checked={
                                            this.state.inputModel.reportSectionDetails &&
                                            this.state.inputModel.reportSectionDetails.filter(
                                                (x) =>
                                                    x.section === TimelineReportSections.RiskIssues
                                            ).length > 0
                                                ? true
                                                : false
                                        }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleSectionCheckedChange(
                                                e,
                                                TimelineReportSections.RiskIssues
                                            );
                                        }}
                                    />
                                    Risk Issues
                                    <button
                                        className="btn btn-no-bg text-gray"
                                        onClick={() =>
                                            this.setState({
                                                showCommentsModal: true,
                                                currentCommentSection:
                                                    TimelineReportSections.RiskIssues,
                                            })
                                        }
                                    >
                                        <i className="fal fa-comment-lines" />
                                        &nbsp;Comments
                                    </button>
                                </h4>
                                <>
                                    {this.state.reportData.riskIssues.length > 0 ? (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th /* width={25} */>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="selectAll"
                                                            checked={this.isSectionRowsSelectAllChecked(
                                                                TimelineReportSections.RiskIssues
                                                            )}
                                                            onChange={(
                                                                e: React.ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                                this.handleSelectAllCheckedChanged(
                                                                    e,
                                                                    TimelineReportSections.RiskIssues
                                                                );
                                                            }}
                                                        />
                                                    </th>
                                                    <th className="col-sm-2">Case Name</th>
                                                    <th className="col-sm-2">Jurisdiction</th>
                                                    <th className="col-sm-3">Plaintiffs Firm</th>
                                                    <th className="col-sm-2">Disease</th>
                                                    <th>
                                                        {this.state.inputModel.additionalSettings
                                                            ? this.state.inputModel
                                                                  .additionalSettings
                                                                  .reportCaseDetailsLastColumnDisplayName
                                                            : 'Last Column Header Name'}
                                                    </th>
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.CaseEasyUpdate,
                                                        this.props.user
                                                    ) ? (
                                                        <th></th>
                                                    ) : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.reportData.riskIssues.map(
                                                    (item: IReportCaseDetailModel) => {
                                                        return this.buildReportSection(
                                                            item,
                                                            TimelineReportSections.RiskIssues
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    ) : null}
                                    {this.getCommentUI(
                                        TimelineReportSections.RiskIssues,
                                        this.state.reportData.riskIssues.length
                                    )}
                                </>

                                {/***********************************  SIGNIFICANT ACTIVITY  **********************************************************************************************/}
                                <h4>
                                    <input
                                        id="chkSectionCaseInventory"
                                        className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                        type="checkbox"
                                        checked={
                                            this.state.inputModel.reportSectionDetails &&
                                            this.state.inputModel.reportSectionDetails.filter(
                                                (x) =>
                                                    x.section ===
                                                    TimelineReportSections.SignificantActivity
                                            ).length > 0
                                                ? true
                                                : false
                                        }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleSectionCheckedChange(
                                                e,
                                                TimelineReportSections.SignificantActivity
                                            );
                                        }}
                                    />
                                    Significant Activity
                                    <button
                                        className="btn btn-no-bg text-gray"
                                        onClick={() =>
                                            this.setState({
                                                showCommentsModal: true,
                                                currentCommentSection:
                                                    TimelineReportSections.SignificantActivity,
                                            })
                                        }
                                    >
                                        <i className="fal fa-comment-lines" />
                                        &nbsp;Comments
                                    </button>
                                </h4>
                                <>
                                    {this.state.reportData.significantActivities.length > 0 ? (
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th /* width={25} */>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="selectAll"
                                                            checked={this.isSectionRowsSelectAllChecked(
                                                                TimelineReportSections.SignificantActivity
                                                            )}
                                                            onChange={(
                                                                e: React.ChangeEvent<HTMLInputElement>
                                                            ) => {
                                                                this.handleSelectAllCheckedChanged(
                                                                    e,
                                                                    TimelineReportSections.SignificantActivity
                                                                );
                                                            }}
                                                        />
                                                    </th>
                                                    <th className="col-sm-2">Case Name</th>
                                                    <th className="col-sm-2">Jurisdiction</th>
                                                    <th className="col-sm-2">Plaintiffs Firm</th>
                                                    <th className="col-sm-2">Disease</th>
                                                    <th>
                                                        {this.state.inputModel.additionalSettings
                                                            ? this.state.inputModel
                                                                  .additionalSettings
                                                                  .reportCaseDetailsLastColumnDisplayName
                                                            : 'Last Column Header Name'}
                                                    </th>
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.CaseEasyUpdate,
                                                        this.props.user
                                                    ) ? (
                                                        <th></th>
                                                    ) : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.reportData.significantActivities.map(
                                                    (item: IReportCaseDetailModel) => {
                                                        return this.buildReportSection(
                                                            item,
                                                            TimelineReportSections.SignificantActivity
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    ) : null}
                                    {this.getCommentUI(
                                        TimelineReportSections.SignificantActivity,
                                        this.state.reportData.significantActivities.length
                                    )}
                                </>
                                {/***********************************  UPCOMING DEPOSITIONS  **********************************************************************************************/}
                                <h4>
                                    <input
                                        id="chkSectionCaseInventory"
                                        className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                        type="checkbox"
                                        checked={
                                            this.state.inputModel.reportSectionDetails &&
                                            this.state.inputModel.reportSectionDetails.filter(
                                                (x) =>
                                                    x.section === TimelineReportSections.Depositions
                                            ).length > 0
                                                ? true
                                                : false
                                        }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleSectionCheckedChange(
                                                e,
                                                TimelineReportSections.Depositions
                                            );
                                        }}
                                    />
                                    Upcoming Depositions
                                    <button
                                        className="btn btn-no-bg text-gray"
                                        onClick={() =>
                                            this.setState({
                                                showCommentsModal: true,
                                                currentCommentSection:
                                                    TimelineReportSections.Depositions,
                                            })
                                        }
                                    >
                                        <i className="fal fa-comment-lines" />
                                        &nbsp;Comments
                                    </button>
                                </h4>
                                <>
                                    {this.state.reportData.depositions.length > 0 ? (
                                        <table
                                            className="table margin-left-20"
                                            style={{ width: '98.5%' }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Case Name</th>
                                                    <th>Jurisdiction</th>
                                                    <th>Witness</th>
                                                    <th>Status</th>
                                                    <th>Date</th>
                                                    <th>
                                                        {this.state.inputModel.additionalSettings
                                                            ? this.state.inputModel
                                                                  .additionalSettings
                                                                  .reportCaseDetailsLastColumnDisplayName
                                                            : 'Last Column Header Name'}
                                                    </th>
                                                    {/*                         <th>
                          {this.state.inputModel.additionalSettings
                            ? this.state.inputModel.additionalSettings
                                .reportCaseDetailsLastColumnDisplayName
                            : "Last Column Header Name"}
                        </th> */}
                                                    {Authorization.isAuthorizedToRoute(
                                                        LocalRoutes.CaseEasyUpdate,
                                                        this.props.user
                                                    ) ? (
                                                        <th></th>
                                                    ) : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.reportData.depositions.map(
                                                    (item: IReportDepositionModel) => {
                                                        return (
                                                            <tr>
                                                                <td className="col-sm-2">
                                                                    <Link
                                                                        target="_blank"
                                                                        to={LocalRoutes.CaseFile.replace(
                                                                            ':guid',
                                                                            item.caseGuid
                                                                        )}
                                                                    >
                                                                        {item.caseName}
                                                                    </Link>
                                                                </td>
                                                                <td className="col-sm-3">
                                                                    {item.jurisdiction}
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.witness}
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.status.displayName}
                                                                </td>
                                                                <td className="col-sm-1">
                                                                    {Common.dateFormat(item.date)}
                                                                </td>
                                                                <td>
                                                                    {item.additionalCaseIdentifier}
                                                                </td>
                                                                {Authorization.isAuthorizedToRoute(
                                                                    LocalRoutes.CaseEasyUpdate,
                                                                    this.props.user
                                                                ) ? (
                                                                    <td
                                                                        className="center"
                                                                        style={{ width: '3%' }}
                                                                    >
                                                                        <button
                                                                            className="btn-no-bg"
                                                                            onClick={(
                                                                                e: React.MouseEvent<HTMLButtonElement>
                                                                            ) => {
                                                                                this.handleEasyUpdate(
                                                                                    e,
                                                                                    item.caseGuid
                                                                                );
                                                                            }}
                                                                        >
                                                                            <i
                                                                                className={`fal fa-edit ${
                                                                                    this.state.easyUpdateAttemptedCaseList.filter(
                                                                                        (x) =>
                                                                                            x ===
                                                                                            item.caseGuid
                                                                                    ).length > 0
                                                                                        ? 'color-gray'
                                                                                        : 'color-black'
                                                                                } `}
                                                                            />
                                                                        </button>
                                                                    </td>
                                                                ) : null}
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    ) : null}
                                    {this.getCommentUI(
                                        TimelineReportSections.Depositions,
                                        this.state.reportData.depositions.length
                                    )}
                                </>

                                <h4>
                                    <input
                                        id="chkSectionZoneNotes"
                                        className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                        type="checkbox"
                                        checked={
                                            this.state.inputModel.reportSectionDetails &&
                                            this.state.inputModel.reportSectionDetails.filter(
                                                (x) =>
                                                    x.section === TimelineReportSections.ZoneNotes
                                            ).length > 0
                                                ? true
                                                : false
                                        }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.handleSectionCheckedChange(
                                                e,
                                                TimelineReportSections.ZoneNotes
                                            );
                                        }}
                                    />
                                    Industry News
                                    <Help
                                        type={ApplicationHelpType.Info}
                                        title="Industry News"
                                        helpText={this.getHelpText(HelpKeys.ZoneNotes.Name)}
                                        additionalStyleClassNames="font-size-sm"
                                    />
                                    <button
                                        className="btn btn-no-bg text-gray"
                                        onClick={() =>
                                            this.setState({
                                                showCommentsModal: true,
                                                currentCommentSection:
                                                    TimelineReportSections.ZoneNotes,
                                            })
                                        }
                                    >
                                        <i className="fal fa-comment-lines" />
                                        &nbsp;Comments
                                    </button>
                                </h4>
                                {this.state.reportData.zoneNotes.length > 0 ? (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="selectAll"
                                                        checked={this.isSectionRowsSelectAllChecked(
                                                            TimelineReportSections.ZoneNotes
                                                        )}
                                                        onChange={(
                                                            e: React.ChangeEvent<HTMLInputElement>
                                                        ) => {
                                                            this.handleSelectAllCheckedChanged(
                                                                e,
                                                                TimelineReportSections.ZoneNotes
                                                            );
                                                        }}
                                                    />
                                                </th>
                                                <th className="col-sm-6">Topic</th>
                                                <th>Source</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.reportData.zoneNotes.map(
                                                (item: IZoneNoteModel) => {
                                                    return this.buildZoneNoteRows(item);
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                ) : null}
                                {this.getCommentUI(
                                    TimelineReportSections.ZoneNotes,
                                    this.state.reportData.zoneNotes.length
                                )}
                            </div>
                        ) : null}

                        {this.state.showSettingsModal ? (
                            <Modal
                                centered
                                size="lg"
                                show={this.state.showSettingsModal}
                                onHide={() => this.setState({ showSettingsModal: false })}
                                backdrop={false}
                                dialogClassName="board-settings-dialog"
                            >
                                <Modal.Header>
                                    <Modal.Title> Report Settings </Modal.Title>
                                    <button
                                        className="btn btn-no-bg float-end"
                                        onClick={() => this.setState({ showSettingsModal: false })}
                                    >
                                        <i className="fal fa-xmark-large" />
                                    </button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row">
                                        <span className="text-danger">
                                            {this.state.validation.settings}
                                        </span>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-sm-2">
                                            <label className="control-label" htmlFor="dateRange">
                                                Date Range
                                            </label>
                                        </div>
                                        <div className="col-sm-10">
                                            <input
                                                type="date"
                                                name="startDate"
                                                className={
                                                    'form-control d-inline-block horizontal-margin' +
                                                    (this.state.inputModel &&
                                                    this.state.inputModel.startDate &&
                                                    Common.isValidDate(
                                                        this.state.inputModel.startDate
                                                    )
                                                        ? ''
                                                        : ' unselectClass')
                                                }
                                                value={
                                                    this.state.inputModel &&
                                                    this.state.inputModel.startDate
                                                        ? this.state.inputModel.startDate
                                                        : ''
                                                }
                                                onChange={this.handleChange}
                                            />
                                            <span className="horizontal-margin">to</span>
                                            <input
                                                type="date"
                                                name="endDate"
                                                value={
                                                    this.state.inputModel &&
                                                    this.state.inputModel.endDate
                                                        ? this.state.inputModel.endDate
                                                        : ''
                                                }
                                                className={
                                                    'form-control d-inline-block horizontal-margin' +
                                                    (this.state.inputModel &&
                                                    this.state.inputModel.endDate &&
                                                    Common.isValidDate(
                                                        this.state.inputModel.endDate
                                                    )
                                                        ? ''
                                                        : ' unselectClass')
                                                }
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <label className="control-label" htmlFor="section">
                                            Sections
                                        </label>
                                    </div>
                                    <>
                                        {WeeklyRecapReportSectionList.map(
                                            (section: ILookupModel) => {
                                                return this.getSectionSettingsUI(section);
                                            }
                                        )}
                                    </>

                                    {this.state.inputModel.reportSectionDetails &&
                                    this.state.inputModel.reportSectionDetails.filter(
                                        (element) =>
                                            element.section === TimelineReportSections.RiskIssues ||
                                            element.section ===
                                                TimelineReportSections.SignificantActivity ||
                                            element.section ===
                                                TimelineReportSections.UpcomingTrialDates ||
                                            element.section === TimelineReportSections.TenderedCases
                                    ).length > 0 ? (
                                        <>
                                            <div className="row my-2">
                                                <label className="control-label" htmlFor="section">
                                                    Case Details
                                                    <Help
                                                        type={ApplicationHelpType.Info}
                                                        title="Case Details"
                                                        helpText="Settings under the Case Details apply to the respective column in sections throughout the report that have a Header row and a list of cases."
                                                    />
                                                </label>
                                            </div>
                                            <>
                                                <div className="row mt-1 ms-3">
                                                    <div className="col-sm-4">
                                                        <label className="col-form-label text-gray">
                                                            Plaintiffs Firm *
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <CheckmateSelect
                                                            options={CheckmateSelectHelper.getPlaintiffsFirmDisplayOptions()}
                                                            name={
                                                                ReportSettings_PlaintiffsFirmDisplayMode
                                                            }
                                                            value={
                                                                this.state.plaintiffsFirmDisplayMode
                                                            }
                                                            onChange={(e: any) => {
                                                                this.handleCheckmateSelectChange(
                                                                    e,
                                                                    ReportSettings_PlaintiffsFirmDisplayMode
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-1 ms-3">
                                                    <div className="col-sm-4">
                                                        <label className="col-form-label text-gray">
                                                            Last Column *
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <CheckmateSelect
                                                            options={CheckmateSelectHelper.getAdditionalCaseIdentifierOptions()}
                                                            name={
                                                                ReportSettings_ReportCaseDetailsLastColumn
                                                            }
                                                            value={
                                                                this.state
                                                                    .reportCaseDetailsLastColumn
                                                            }
                                                            onChange={(e: any) => {
                                                                this.handleCheckmateSelectChange(
                                                                    e,
                                                                    ReportSettings_ReportCaseDetailsLastColumn
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-1 ms-3">
                                                    <div className="col-sm-4">
                                                        <label className="col-form-label text-gray">
                                                            Last Column Header Name *
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name={
                                                                ReportSettings_ReportCaseDetailsLastColumnDisplayName
                                                            }
                                                            placeholder="Header Name"
                                                            value={
                                                                this.state.inputModel
                                                                    .additionalSettings
                                                                    ? this.state.inputModel
                                                                          .additionalSettings
                                                                          .reportCaseDetailsLastColumnDisplayName
                                                                    : ''
                                                            }
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        </>
                                    ) : null}

                                    <div className="mt-2">
                                        <button
                                            className="btn btn-orange float-end text-gray"
                                            onClick={this.getReportData}
                                        >
                                            Run
                                        </button>

                                        <button
                                            className="btn btn-default float-end"
                                            onClick={() =>
                                                this.setState({
                                                    showClearSettingssConfirmModal: true,
                                                })
                                            }
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        ) : null}

                        <ResizableDialog
                            isShowingModal={!!this.state.showCommentsModal}
                            title="Comments"
                            defaultWidth={500}
                            minHeight={300}
                            minWidth={350}
                            bodyComponent={
                                <Accordion
                                    defaultActiveKey={this.state.currentCommentSection.toString()}
                                >
                                    {WeeklyRecapReportSectionList.map((section: ILookupModel) => {
                                        return (
                                            <Accordion.Item
                                                key={section.id}
                                                eventKey={section.id.toString()}
                                            >
                                                <Accordion.Header>
                                                    {section.displayName}
                                                    {this.getCommentText(section.id) ? (
                                                        <i className="fal fa-comment-lines ps-2" />
                                                    ) : null}
                                                </Accordion.Header>
                                                <Accordion.Body className="py-0">
                                                    <textarea
                                                        className="form-control"
                                                        value={this.getCommentText(section.id)}
                                                        rows={5}
                                                        onChange={(
                                                            e: React.ChangeEvent<HTMLTextAreaElement>
                                                        ) => {
                                                            this.handleCommentsChange(e, section);
                                                        }}
                                                    />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        );
                                    })}
                                </Accordion>
                            }
                            confirmText="Clear"
                            handleConfirm={() => {
                                this.setState({ showClearCommentsConfirmModal: true });
                            }}
                            confirmButtonClassName="btn btn-default float-end"
                            cancelText="Done"
                            handleClose={() => this.setState({ showCommentsModal: false })}
                            closeButtonClassName="btn btn-orange float-end"
                            draggable
                        />

                        <CheckmateDialog
                            isShowingModal={this.state.showClearCommentsConfirmModal || false}
                            body="Are you sure you want to clear all comments?"
                            handleClose={() => {
                                this.setState({ showClearCommentsConfirmModal: false });
                            }}
                            handleConfirm={this.clearComments}
                            confirmText="Yes"
                            cancelText="No"
                            confirmButtonClassName="btn btn-black float-end"
                            dialogClassName="confirm-document-delete-dialog"
                            closeButtonClassName="btn btn-default float-end"
                        />

                        <CheckmateDialog
                            isShowingModal={this.state.showClearSettingssConfirmModal || false}
                            body="Are you sure you want to clear all settings and set to default?"
                            handleClose={() => {
                                this.setState({ showClearSettingssConfirmModal: false });
                            }}
                            handleConfirm={this.handleClearSettings}
                            confirmText="Yes"
                            cancelText="No"
                            confirmButtonClassName="btn btn-black float-end"
                            dialogClassName="confirm-document-delete-dialog"
                            closeButtonClassName="btn btn-default float-end"
                        />

                        <CheckmateDialog
                            isShowingModal={this.state.sectionToBeRemoved ? true : false}
                            body="The section you are trying to remove contains comments. Removing the section will delete the comment for the section. Are you sure you want to remove this section? "
                            handleClose={() => {
                                this.setState({ sectionToBeRemoved: undefined });
                            }}
                            handleConfirm={this.handleConfirmRemoveSection}
                            confirmText="Yes"
                            cancelText="No"
                            confirmButtonClassName="btn btn-black float-end"
                            dialogClassName="confirm-document-delete-dialog"
                            closeButtonClassName="btn btn-default float-end"
                        />
                    </>
                )}
            </>
        );
    }
}
