import * as React from 'react';

import { EmptyGuid, UserRightsEnum } from '../../../utilities/Constants';
import { ICaseModel, IDepositionModel } from '../../../interfaces/ICase';
import { ILookupModel, IMultiSelectOptions } from '../../../interfaces/ILookup';

import ApiClient from '../../../services/apiClient';
import Authorization from '../../../stores/Authorization';
import { CheckmateDialog } from '../../shared/dialog';
import { DepositionDetailsModal } from '../../depositions/DepositionDetailsModal';
import { DepositionTable } from '../../shared/DepositionTable';
import { IAssociatedOrganizationModel } from '../../../interfaces/IOrganization';
import { IUserModel } from '../../../interfaces/IUser';
import { IValidation } from '../../../interfaces/IError';
import Sort from '../../../stores/Sort';
import ValidateUtils from '../../../shared/validations';
import { cloneDeep } from 'lodash';

const _apiClient = new ApiClient();

interface ICaseDepositionListProps {
    allDefenseCounselsForZone: IAssociatedOrganizationModel[];
    caseGuid: string;
    categories: ILookupModel[];
    currentCase?: ICaseModel;
    depositions: IDepositionModel[];
    forceHideDelete?: boolean;
    hideEdit?: boolean;
    onDepositionsChange?: (depositions: IDepositionModel[]) => void;
    showCaseColumn?: boolean;
    showEasyUpdateColumn?: boolean;
    statusTypes: ILookupModel[];
    user: IUserModel;
    usersInZone: IUserModel[];
}

interface ICaseDepositionListState {
    addNew?: boolean;
    currentDepo?: IDepositionModel;
    depositions: IDepositionModel[];
    pendingResponse: boolean;
    selectedGuidToDelete: string;
    showConfirmDeleteDialog?: boolean;
    showDepoAddEditDialog: boolean;
    validation: IValidation;
}

export class CaseDepositionList extends React.Component<
    ICaseDepositionListProps,
    ICaseDepositionListState
> {
    constructor(props: ICaseDepositionListProps) {
        super(props);
        this.state = {
            pendingResponse: false,
            validation: {},
            showDepoAddEditDialog: false,
            addNew: false,
            depositions: [],
            selectedGuidToDelete: '',
        };
    }

    componentDidMount() {
        if (this.props.depositions) {
            this.setState({
                depositions: this.props.depositions,
            });
        }
    }

    componentDidUpdate(prevProps: ICaseDepositionListProps) {
        if (this.props.depositions != prevProps.depositions) {
            this.setState({
                depositions: this.props.depositions,
            });
        }
    }

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let depositions = [...this.state.depositions];

        if (depositions) {
            if (key == 'date') {
                depositions = depositions.sort(Sort.compareDate(key, subKey, order));
            } else {
                depositions = depositions.sort(Sort.compareValues(key, subKey, order, subGrandKey));
            }
        }

        this.setState({ depositions: depositions });
    };

    editDeposition = async (depo: IDepositionModel) => {
        this.setState({ currentDepo: depo, showDepoAddEditDialog: true });
    };

    handleAdd = (caseGuid: string) => {
        const currentDepo: IDepositionModel = {
            case: { guid: caseGuid },
        };

        this.setState({ currentDepo }, () => {
            this.setState({ showDepoAddEditDialog: true, addNew: true });
        });
    };

    onCheckmateSelectChange = (optionList: IMultiSelectOptions, name: string) => {
        const currentDepo = this.state.currentDepo;
        const validation = this.state.validation;
        if (!currentDepo) return; // this technically cannot happen

        switch (name) {
            case 'depoCategory':
                currentDepo.category = undefined;
                validation.category = [''];
                if (optionList && optionList.id) {
                    const match = this.props.categories.find((x) => x.id == optionList.id);
                    if (match) currentDepo.category = JSON.parse(JSON.stringify(match));
                }
                break;
            case 'depoStatus':
                currentDepo.status = undefined;
                validation.status = [''];
                if (optionList && optionList.id) {
                    const match = this.props.statusTypes.find((x) => x.id == optionList.id);
                    if (match) currentDepo.status = JSON.parse(JSON.stringify(match));
                }
                break;
            case 'depoDefenseCounsel':
                currentDepo.defenseCounselOrganization = undefined;
                currentDepo.defenseCounselUser = undefined;
                if (optionList && optionList.guid) {
                    const match = this.props.allDefenseCounselsForZone.find(
                        (x) => x.guid == optionList.guid
                    );
                    if (match)
                        currentDepo.defenseCounselOrganization = JSON.parse(JSON.stringify(match));
                }
                validation.defenseCounsel = [''];
                break;
            case 'depoDefenseCounselUser':
                currentDepo.defenseCounselUser = undefined;
                if (optionList && optionList.guid) {
                    const userMatch = this.props.usersInZone.find((x) => x.guid == optionList.guid);
                    if (userMatch)
                        currentDepo.defenseCounselUser = JSON.parse(JSON.stringify(userMatch));
                }
                validation.defenseCounselUser = [''];
                break;
            default:
                break;
        }
        this.setState({ currentDepo: currentDepo, validation: validation });
    };

    delete = async () => {
        if (!this.state.selectedGuidToDelete) return;

        const response = await _apiClient.deleteDeposition(this.state.selectedGuidToDelete);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        const depositions = this.state.depositions;
        depositions.splice(
            depositions.findIndex((x) => x.guid === this.state.selectedGuidToDelete),
            1
        );
        this.setState({ depositions: depositions, showConfirmDeleteDialog: false }, () => {
            if (typeof this.props.onDepositionsChange === 'function') {
                this.props.onDepositionsChange(this.state.depositions);
            }
        });
    };

    handleCancelAddEditDepo = () => {
        this.setState({
            currentDepo: undefined,
            showDepoAddEditDialog: false,
            addNew: false,
            validation: {},
        });
    };

    handleDepositionCreated = (newDeposition: IDepositionModel) => {
        const deposCopy = cloneDeep(this.state.depositions);

        const foundIdx = deposCopy.findIndex((depo) => depo.case.guid === newDeposition.case.guid);
        const needToUpdateEmptyDeposition = foundIdx > -1 && deposCopy[foundIdx].guid === EmptyGuid;

        if (needToUpdateEmptyDeposition) {
            deposCopy.splice(foundIdx, 1, newDeposition);
        } else {
            deposCopy.push(newDeposition);
        }

        this.setState(
            {
                pendingResponse: false,
                depositions: deposCopy,
                showDepoAddEditDialog: false,
                currentDepo: undefined,
                addNew: false,
                validation: {},
            },
            () => {
                if (typeof this.props.onDepositionsChange === 'function') {
                    this.props.onDepositionsChange(deposCopy);
                }
            }
        );
    };

    handleDepositionUpdated = (updatedDeposition: IDepositionModel) => {
        const deposCopy = cloneDeep(this.state.depositions);
        const depoIdx = deposCopy.findIndex(
            (depo) => depo.guid === updatedDeposition.guid && depo.guid === updatedDeposition.guid
        );

        if (depoIdx > -1) {
            deposCopy.splice(depoIdx, 1, updatedDeposition);
        }

        this.setState(
            {
                pendingResponse: false,
                depositions: deposCopy,
                showDepoAddEditDialog: false,
                currentDepo: undefined,
            },
            () => {
                if (typeof this.props.onDepositionsChange === 'function') {
                    this.props.onDepositionsChange(deposCopy);
                }
            }
        );
    };

    public render() {
        const allowAdd = Authorization.userHasRight(UserRightsEnum.AddDeposition, this.props.user);
        const allowEdit = Authorization.userHasRight(
            UserRightsEnum.EditDeposition,
            this.props.user
        );
        const allowDelete = Authorization.userHasRight(
            UserRightsEnum.DeleteDeposition,
            this.props.user
        );

        return (
            <div className="container-fluid">
                <div>
                    <span className="text-danger">{this.state.validation.model}</span>
                </div>
                {allowAdd && this.props.caseGuid ? (
                    <div>
                        <button
                            className="btn-no-bg float-end"
                            onClick={() => this.handleAdd(this.props.caseGuid)}
                        >
                            {' '}
                            <span className="btn-green btn float-end btn-icon">
                                <i className="fal fa-lg fa-plus color-white" />
                            </span>
                        </button>
                    </div>
                ) : null}
                <DepositionTable
                    currentCaseMeta={{
                        guid: this.props.currentCase?.guid ?? '',
                        caseName: this.props.currentCase?.caseName ?? '',
                    }}
                    depostions={this.state.depositions ?? []}
                    onRowAddClick={(caseGuid) => this.handleAdd(caseGuid)}
                    onRowDeleteClick={(item: IDepositionModel) => {
                        this.setState({
                            showConfirmDeleteDialog: true,
                            selectedGuidToDelete: item.guid!,
                        });
                    }}
                    onRowEditClick={(item: IDepositionModel) => {
                        this.editDeposition(item);
                    }}
                    showCaseColumn={this.props.showCaseColumn}
                    showDeleteButton={allowDelete && !this.props.forceHideDelete}
                    showEditButton={allowEdit && !this.props.hideEdit}
                    showEasyUpdateColumn={this.props.showEasyUpdateColumn}
                />
                {this.state.showDepoAddEditDialog && (
                    <DepositionDetailsModal
                        addNew={!!this.state.addNew}
                        caseGuid={this.props.caseGuid}
                        currentCaseMeta={{
                            guid: this.props.currentCase?.guid ?? '',
                            caseName: this.props.currentCase?.caseName ?? '',
                        }}
                        onDepositionCreated={this.handleDepositionCreated}
                        onDepositionUpdated={this.handleDepositionUpdated}
                        onHide={this.handleCancelAddEditDepo}
                        open
                        currentDepo={this.state.currentDepo}
                        categories={this.props.categories}
                        allDefenseCounselsForZone={this.props.allDefenseCounselsForZone}
                        statusTypes={this.props.statusTypes}
                        zoneUsers={this.props.usersInZone}
                    />
                )}
                <CheckmateDialog
                    isShowingModal={this.state.showConfirmDeleteDialog || false}
                    body="Are you sure you want to delete this item? This operation is permanent and cannot be reverted back."
                    handleClose={() => this.setState({ showConfirmDeleteDialog: false })}
                    handleConfirm={this.delete}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </div>
        );
    }
}
