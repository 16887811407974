import {
    CaseFileStaticItems,
    CaseStatusTypesEnum,
    CaseSubStatusTypesEnum,
} from '../utilities/Constants';
import { ICaseAssertionModel, ICaseModel, ICaseStatusLookupModel } from '../interfaces/ICase';
import { ILookupModel, IMultiSelectOptions, IStaticItemLookupModel } from '../interfaces/ILookup';
import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';

import CaseDataCustomLogicHelper from '../utilities/CaseDataCustomLogicHelper';
import CaseFileCheckmateSelectHelper from '../utilities/CaseFileCheckmateSelectHelper';
import { IAssertionDescriptorModel } from '../interfaces/IAssertionDescriptor';
import { IAssociatedOrganizationModel } from '../interfaces/IOrganization';
import { IDisplaySectionModel } from '../interfaces/IDisplaySectionModel';
import { INoteModel } from '../interfaces/INote';
import { IUserModel } from '../interfaces/IUser';
import { IViewCaseExpertModelWithDefaultEdit } from '../interfaces/ICaseExpert';
import { cloneDeep } from 'lodash';

// Define the types for the context
type CaseFileContextType = {
    assertionDescriptors: IAssertionDescriptorModel[];
    caseDisplayCategories: ILookupModel[];
    caseManagers: IUserModel[];
    caseStatuses: ICaseStatusLookupModel[];
    currentCase: ICaseModel;
    currentCaseExpert: IViewCaseExpertModelWithDefaultEdit | null;
    currentNarrativeCaseAssertion: ICaseAssertionModel | null;
    currentNote: INoteModel | null;
    displaySections: IDisplaySectionModel[];
    isReadOnlyMode: boolean;
    originalCase: ICaseModel;
    showAdditionalFieldsDialog: boolean;
    staticItems: IStaticItemLookupModel[];
    user: IUserModel;
    zoneDefenseCounsels: IAssociatedOrganizationModel[];
    zoneUsers: IUserModel[];

    setAssertionDescriptors: React.Dispatch<React.SetStateAction<IAssertionDescriptorModel[]>>;
    setCaseDisplayCategories: React.Dispatch<React.SetStateAction<ILookupModel[]>>;
    setCaseManagers: React.Dispatch<React.SetStateAction<IUserModel[]>>;
    setCaseStatuses: React.Dispatch<React.SetStateAction<ICaseStatusLookupModel[]>>;
    setCurrentCase: React.Dispatch<React.SetStateAction<ICaseModel>>;
    setCurrentCaseExpert: React.Dispatch<
        React.SetStateAction<IViewCaseExpertModelWithDefaultEdit | null>
    >;
    setCurrentNote: React.Dispatch<React.SetStateAction<INoteModel | null>>;
    setCurrentNarrativeCaseAssertion: React.Dispatch<
        React.SetStateAction<ICaseAssertionModel | null>
    >;
    setDisplaySections: React.Dispatch<React.SetStateAction<IDisplaySectionModel[]>>;
    setIsReadOnlyMode: React.Dispatch<React.SetStateAction<boolean>>;
    setOriginalCase: React.Dispatch<React.SetStateAction<ICaseModel>>;
    setShowAdditionalFieldsDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setStaticItems: React.Dispatch<React.SetStateAction<IStaticItemLookupModel[]>>;
    setUser: React.Dispatch<React.SetStateAction<IUserModel>>;
    setZoneDefenseCounsels: React.Dispatch<React.SetStateAction<IAssociatedOrganizationModel[]>>;
    setZoneUsers: React.Dispatch<React.SetStateAction<IUserModel[]>>;

    handleChangeCaseValue: (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => void;
    handleChangeADTextValue: (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => void;
    handleEditNote: (noteGuidToEdit: string) => void;
    handleAssertionDescriptorSearchableSingleSelectChange: (
        selectedOption: IMultiSelectOptions,
        assertionDescriptorId: string,
        isBoolean?: boolean
    ) => void;
    handleAssertionDescriptorSearchableMultiSelectChange: (
        selectedOptions: IMultiSelectOptions[],
        assertionDescriptorId: string
    ) => void;
    handleStaticItemSearchableSingleSelectChange: (
        selectedOption: IMultiSelectOptions,
        staticItemId: number
    ) => void;
    handleNarrativeChange: (assertionDescriptorId: string, value: string) => void;
};

// Create the context
const CaseFileContext = createContext<CaseFileContextType | undefined>(undefined);

// Create a provider component for the context
type CaseFileProviderProps = {
    children: ReactNode;
};

export function CaseFileProvider({ children }: CaseFileProviderProps) {
    const [originalCase, setOriginalCase] = useState<ICaseModel>({});
    const [currentCase, setCurrentCase] = useState<ICaseModel>({});
    const [user, setUser] = useState<IUserModel>({ active: false } as IUserModel);
    const [caseStatuses, setCaseStatuses] = useState<ICaseStatusLookupModel[]>([]);
    const [caseManagers, setCaseManagers] = useState<IUserModel[]>([]);
    const [displaySections, setDisplaySections] = useState<IDisplaySectionModel[]>([]);
    const [assertionDescriptors, setAssertionDescriptors] = useState<IAssertionDescriptorModel[]>(
        []
    );
    const [caseDisplayCategories, setCaseDisplayCategories] = useState<ILookupModel[]>([]);
    const [staticItems, setStaticItems] = useState<IStaticItemLookupModel[]>([]);
    const [zoneDefenseCounsels, setZoneDefenseCounsels] = useState<IAssociatedOrganizationModel[]>(
        []
    );
    const [zoneUsers, setZoneUsers] = useState<IUserModel[]>([]);
    const [currentNote, setCurrentNote] = useState<INoteModel | null>(null);
    const [currentCaseExpert, setCurrentCaseExpert] =
        useState<IViewCaseExpertModelWithDefaultEdit | null>(null);
    const [showAdditionalFieldsDialog, setShowAdditionalFieldsDialog] = useState<boolean>(false);
    const [isReadOnlyMode, setIsReadOnlyMode] = useState<boolean>(true);
    const [currentNarrativeCaseAssertion, setCurrentNarrativeCaseAssertion] =
        useState<ICaseAssertionModel | null>(null);

    const handleChangeADTextValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const caseCopy = { ...currentCase };
        const { name: assertionDescriptorId, value } = event.target;

        const caDataIdx = caseCopy.caseAssertions?.findIndex(
            (ca) => ca.assertionDescriptor?.guid === assertionDescriptorId
        );

        if (caDataIdx && caDataIdx > 1) {
            const caData = caseCopy.caseAssertions?.find(
                (ca) => ca.assertionDescriptor?.guid === assertionDescriptorId
            );

            const caDataCopy = {
                ...caData,
                text: value,
                userOverride: true,
            };

            // todo jec: why is this object not reflecting the text and userOverride assignments

            if (caDataCopy) {
                caseCopy.caseAssertions?.splice(caDataIdx, 1, caDataCopy);
            }
        } else {
            const found = assertionDescriptors.find((ad) => ad.guid === assertionDescriptorId);
            const newCA: ICaseAssertionModel = {
                assertionDescriptor: found,
                text: value,
                userOverride: true,
            };

            if (caseCopy.caseAssertions) caseCopy.caseAssertions.push(newCA);
            else caseCopy.caseAssertions = [newCA];
        }

        CaseDataCustomLogicHelper.Run(caseCopy, assertionDescriptors);
        setCurrentCase(caseCopy);
    };

    const handleNarrativeChange = (assertionDescriptorId: string, value: string) => {
        const caseCopy = { ...currentCase };

        const caData = caseCopy.caseAssertions?.find(
            (ca) => ca.assertionDescriptor?.guid === assertionDescriptorId
        );

        if (caData) {
            caData.text = value;
        } else {
            const found = assertionDescriptors.find((ad) => ad.guid === assertionDescriptorId);
            const newCA: ICaseAssertionModel = { assertionDescriptor: found, text: value };

            if (caseCopy.caseAssertions) caseCopy.caseAssertions.push(newCA);
            else caseCopy.caseAssertions = [newCA];
        }

        CaseDataCustomLogicHelper.Run(caseCopy, assertionDescriptors);
        setCurrentCase(caseCopy);
    };

    const handleChangeCaseValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const caseCopy = { ...currentCase };
        let showFieldsDialog = false;
        const value = event.target.value;

        const { name: fieldName } = event.target;

        switch (fieldName) {
            case 'caseStatus': {
                const lookupMatch = caseStatuses.find((x) => x.id === parseInt(value));

                if (lookupMatch) {
                    currentCase.caseStatus = { ...lookupMatch, displayName: lookupMatch.name };
                    showFieldsDialog =
                        lookupMatch.status &&
                        (lookupMatch.status.id === CaseStatusTypesEnum.Resolved.Value ||
                            (lookupMatch.status.id === CaseStatusTypesEnum.Tendered.Value &&
                                lookupMatch.subStatus?.id ===
                                    CaseSubStatusTypesEnum.Accepted.Value) ||
                            lookupMatch.status.id === CaseStatusTypesEnum.Alt.Value);
                } else {
                    caseCopy.caseStatus = undefined;
                }
                break;
            }
            case 'caseManager': {
                const match = caseManagers.find((x) => x.guid == value);
                if (match) {
                    caseCopy.caseManagerUser = JSON.parse(JSON.stringify(match));
                } else caseCopy.caseManagerUser = undefined;
                break;
            }
            case 'alternateCaseManager': {
                const match = caseManagers.find((x) => x.guid == value);
                if (match) {
                    currentCase.alternateCaseManagerUser = JSON.parse(JSON.stringify(match));
                } else currentCase.alternateCaseManagerUser = undefined;
                break;
            }
            case 'caseName':
                caseCopy.caseName = value;
                break;
            case 'caseNumber':
                caseCopy.caseNumber = value;
                break;
            case 'uniqueCaseId':
                caseCopy.uniqueCaseId = value;
                break;
            case 'description':
                caseCopy.description = value;
                break;
            case 'defenseCounsel':
                // todo: this.removePrimaryDefenseCounsel();
                if (value !== '') {
                    zoneDefenseCounsels.map((defenseCounsel: IAssociatedOrganizationModel) => {
                        if (defenseCounsel.guid == value) {
                            defenseCounsel.priority = 1;
                            if (currentCase.defenseCounsels) {
                                currentCase.defenseCounsels.push(defenseCounsel);
                            } else {
                                currentCase.defenseCounsels = [defenseCounsel];
                            }
                        }
                    });
                }
                break;

            default:
                return;
        }

        CaseDataCustomLogicHelper.Run(caseCopy, assertionDescriptors);
        setCurrentCase(caseCopy);
        setShowAdditionalFieldsDialog(showFieldsDialog);
    };

    const handleEditNote = useCallback(
        (noteGuidToEdit: string) => {
            if (!currentCase.notes) return;

            const currentNote = currentCase.notes.find((x) => x.guid == noteGuidToEdit);
            if (currentNote) {
                setCurrentNote(currentNote);
            }
        },
        [currentCase.guid]
    );

    const handleStaticItemSearchableSingleSelectChange = (
        selectedOption: IMultiSelectOptions,
        staticItemId: number
    ) => {
        const caseCopy = { ...currentCase };
        const selectedItem: IMultiSelectOptions = { ...selectedOption };
        let showDialog = showAdditionalFieldsDialog;

        switch (staticItemId) {
            case CaseFileStaticItems.CaseStatus.Id: {
                if (selectedItem && selectedItem.id) {
                    const lookupMatch = caseStatuses.find((x) => x.id == selectedItem.id);
                    caseCopy.caseStatus = JSON.parse(JSON.stringify(lookupMatch));
                    if (lookupMatch) {
                        caseCopy.caseStatus = JSON.parse(JSON.stringify(lookupMatch));
                        if (
                            lookupMatch.status &&
                            (lookupMatch.status.id == CaseStatusTypesEnum.Resolved.Value ||
                                (lookupMatch.status.id === CaseStatusTypesEnum.Tendered.Value &&
                                    lookupMatch.subStatus &&
                                    lookupMatch.subStatus.id ===
                                        CaseSubStatusTypesEnum.Accepted.Value) ||
                                lookupMatch.status.id === CaseStatusTypesEnum.Alt.Value)
                        )
                            showDialog = true;
                    }
                } else caseCopy.caseStatus = undefined;
                break;
            }
            case CaseFileStaticItems.CaseManager.Id: {
                if (selectedItem && selectedItem.guid) {
                    const match = zoneUsers.find((x) => x.guid == selectedItem.guid);
                    caseCopy.caseManagerUser = JSON.parse(JSON.stringify(match));
                } else caseCopy.caseManagerUser = undefined;
                break;
            }
            case CaseFileStaticItems.AlternateCaseManager.Id: {
                if (selectedItem && selectedItem.guid) {
                    const match = zoneUsers.find((x) => x.guid == selectedItem.guid);
                    caseCopy.alternateCaseManagerUser = JSON.parse(JSON.stringify(match));
                } else caseCopy.alternateCaseManagerUser = undefined;
                break;
            }
            case CaseFileStaticItems.LocalCounsel.Id: {
                // If first ever time adding any Defense Counsel; just add
                if (!caseCopy.defenseCounsels) {
                    if (selectedItem?.guid)
                        caseCopy.defenseCounsels = [{ guid: selectedItem.guid, priority: 1 }];
                    break;
                }
                // If no Local Counsel, Add
                const localCounselMatch = caseCopy.defenseCounsels.find((x) => x.priority === 1);
                if (!localCounselMatch) {
                    if (selectedItem.guid) {
                        caseCopy.defenseCounsels.push({ guid: selectedItem.guid, priority: 1 });
                    }
                } else {
                    if (selectedItem?.guid) {
                        localCounselMatch.guid = selectedItem.guid;
                        localCounselMatch.name = selectedItem.label;
                    } else {
                        const matchIndex = caseCopy.defenseCounsels.findIndex(
                            (x) => x.priority === 1
                        );
                        if (matchIndex >= 0) {
                            caseCopy.defenseCounsels.splice(matchIndex, 1);
                        }
                    }
                }
                break;
            }
        }

        CaseDataCustomLogicHelper.Run(caseCopy, assertionDescriptors);
        setCurrentCase(caseCopy);
        setShowAdditionalFieldsDialog(showDialog);
    };

    const handleAssertionDescriptorSearchableSingleSelectChange = (
        option: any,
        assertionDescriptorId: string,
        isBoolean?: boolean
    ) => {
        let caseCopy = { ...currentCase };
        const selectedItem: IMultiSelectOptions = option ? { ...option } : undefined;

        caseCopy = CaseFileCheckmateSelectHelper.updateCaseOnSelectedAssertionDescriptorValueChange(
            caseCopy,
            assertionDescriptorId,
            isBoolean || false,
            selectedItem,
            assertionDescriptors
        );

        CaseDataCustomLogicHelper.Run(caseCopy, assertionDescriptors);
        setCurrentCase(caseCopy);
    };

    const handleAssertionDescriptorSearchableMultiSelectChange = (
        options: IMultiSelectOptions[],
        assertionDescriptorId: string
    ) => {
        let caseCopy: ICaseModel | null = cloneDeep(currentCase);
        const adMatch = assertionDescriptors.find((ad) => ad.guid === assertionDescriptorId);

        if (!caseCopy.caseAssertions) caseCopy.caseAssertions = [];

        caseCopy.caseAssertions = caseCopy.caseAssertions?.filter(
            (ca) => ca.assertionDescriptor?.guid !== assertionDescriptorId
        );

        const matchingValues = adMatch?.assertionDescriptorValues?.filter((adv) =>
            options.some((opt) => opt.value === adv.guid)
        );

        if (adMatch) {
            const newCaseAssertions =
                matchingValues?.map((match) => ({
                    assertionDescriptor: adMatch,
                    assertionDescriptorValue: match,
                })) ?? [];
            caseCopy.caseAssertions = caseCopy.caseAssertions.concat(newCaseAssertions);
        }

        CaseDataCustomLogicHelper.Run(caseCopy, assertionDescriptors);
        setCurrentCase(caseCopy);

        caseCopy = null;
    };

    return (
        <CaseFileContext.Provider
            value={{
                assertionDescriptors,
                caseDisplayCategories,
                caseManagers,
                caseStatuses,
                currentCase,
                currentCaseExpert,
                currentNarrativeCaseAssertion,
                currentNote,
                displaySections,
                isReadOnlyMode,
                originalCase,
                showAdditionalFieldsDialog,
                staticItems,
                user,
                zoneDefenseCounsels,
                zoneUsers,

                setAssertionDescriptors,
                setCaseDisplayCategories,
                setCaseManagers,
                setCaseStatuses,
                setCurrentCase,
                setCurrentCaseExpert,
                setCurrentNarrativeCaseAssertion,
                setCurrentNote,
                setDisplaySections,
                setIsReadOnlyMode,
                setOriginalCase,
                setShowAdditionalFieldsDialog,
                setStaticItems,
                setUser,
                setZoneDefenseCounsels,
                setZoneUsers,

                handleChangeADTextValue,
                handleChangeCaseValue,
                handleEditNote,
                handleNarrativeChange,
                handleAssertionDescriptorSearchableSingleSelectChange,
                handleAssertionDescriptorSearchableMultiSelectChange,
                handleStaticItemSearchableSingleSelectChange,
            }}
        >
            {children}
        </CaseFileContext.Provider>
    );
}

// Custom hook to use the context
export function useCaseFileContext() {
    const context = useContext(CaseFileContext);
    if (!context) {
        throw new Error('useCaseFileContext must be used within a CaseFileProvider');
    }
    return context;
}
