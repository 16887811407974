import { useParams } from 'react-router-dom';
import { IUserModel } from '../../interfaces/IUser';
import { ProjectDetail } from '../strategy/ProjectDetail';

function ProjectDetailWrapper(props: { user: IUserModel }) {
    const { guid } = useParams();
    return <ProjectDetail guid={guid} user={props.user} />;
}

export default ProjectDetailWrapper;
