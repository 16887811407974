import { useLocation, useParams } from 'react-router-dom';

import { IQueryFilterCriteria } from '../../interfaces/IQuery';
import { IUserModel } from '../../interfaces/IUser';
import { Query } from '../query/Query';
import { QueryToolRedirectTypeEnum } from '../../utilities/Constants';

function QueryWrapper(props: { user: IUserModel }) {
    const { key, state } = useLocation();

    let queryFilterCriteriaSelected: IQueryFilterCriteria[] = [];
    let title: string = '';
    let redirectComponentCurrentState: string = '';
    let redirectUrl: string = '';
    let redirectType: QueryToolRedirectTypeEnum = 0;

    if (state) {
        const additionalData: any = state;

        if (additionalData) {
            if (additionalData.queryFilterCriteriaSelected)
                queryFilterCriteriaSelected = JSON.parse(
                    JSON.stringify(additionalData.queryFilterCriteriaSelected)
                );
            title = additionalData.title;
            redirectComponentCurrentState = additionalData.redirectComponentCurrentState;
            redirectUrl = additionalData.redirectUrl;
            redirectType = additionalData.redirectType;
        }
    }

    return (
        <Query
            user={props.user}
            queryFilterCriteriaSelected={
                queryFilterCriteriaSelected.length > 0 ? queryFilterCriteriaSelected : undefined
            }
            title={title}
            redirectUrl={redirectUrl}
            redirectType={redirectType}
            redirectComponentCurrentState={redirectComponentCurrentState}
            refreshIdentifier={key}
        />
    );
}

export default QueryWrapper;
