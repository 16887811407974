import * as React from 'react';

import {
    BoardCaseDetailsExportTypes,
    BoardCriteriaEnum,
    BoardFilterDateTypesEnum,
    BoardViewDataTypesEnum,
    CaseStatusTypesEnum,
    CaseSubStatusTypesEnum,
    DateTypesEnum,
    UserRightsEnum,
    UserSettingTypesEnum,
} from '../../utilities/Constants';
import {
    IBoardComment,
    IBoardExportPreferencesModel,
    IBoardParametersModel,
} from '../../interfaces/Report/IBoard';
import { ILookupModel, IMultiSelectOptions } from '../../interfaces/ILookup';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import BoardHelper from '../../utilities/BoardHelper';
import { BoardPreferencesHeader } from '../../components/board/BoardPreferencesHeader';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import Common from '../../stores/Common';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import Html5ReportViewer from '../../components/report/Html5ReportViewer';
import { ICaseStatusLookupModel } from '../../interfaces/ICase';
import { IOrganizationBaseModel } from '../../interfaces/IOrganization';
import { IUserModel } from '../../interfaces/IUser';
import { IUserSettingModel } from '../../interfaces/ITask';
import { IValidation } from '../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import ParamUtils from '../../shared/ParamUtilities';
import ValidateUtils from '../../shared/validations';

let _apiClient = new ApiClient();

interface IBoardProps {
    user: IUserModel;
}

interface IBoardState {
    pendingResponse: boolean;
    showSettingsModal: boolean;
    defaultOrganization: IOrganizationBaseModel;
    validation: IValidation;
    initBoardPreferences: IBoardParametersModel;
    boardPreferences: IBoardParametersModel;
    boardExportPreferences: IBoardExportPreferencesModel;
    readyToRenderReport: boolean;
    refreshReport: boolean;
    saveToFavourites: boolean;
    userBoardSetting: IUserSettingModel;
    initComments: IBoardComment[];
    comments: IBoardComment[];
    showCommentsModal: boolean;
    showExportModal: boolean;
    caseStatuses: ICaseStatusLookupModel[];
}

export class Board extends React.Component<IBoardProps, IBoardState> {
    constructor(props: any) {
        super(props);

        this.state = {
            validation: {},
            showSettingsModal: true,
            defaultOrganization: {},
            boardPreferences: {
                statusId: CaseStatusTypesEnum.Open.Value,
                criteria: BoardCriteriaEnum.CaseVolume.Value,
            },
            initBoardPreferences: {
                statusId: CaseStatusTypesEnum.Open.Value,
                criteria: BoardCriteriaEnum.CaseVolume.Value,
            },
            boardExportPreferences: {
                statusId: CaseStatusTypesEnum.Open.Value,
                criteria: BoardCriteriaEnum.CaseVolume.Value,
                viewDataTypes: [
                    BoardViewDataTypesEnum.State.Value,
                    BoardViewDataTypesEnum.Jurisdiction.Value,
                    BoardViewDataTypesEnum.PlaintiffsFirm.Value,
                    BoardViewDataTypesEnum.Diagnosis.Value,
                    BoardViewDataTypesEnum.Timeline.Value,
                ],
            },
            readyToRenderReport: false,
            refreshReport: false,
            pendingResponse: true,
            saveToFavourites: false,
            userBoardSetting: {
                type: { id: UserSettingTypesEnum.Board.Value },
                settingsJson: '',
            },
            initComments: [],
            comments: [],
            showCommentsModal: false,
            showExportModal: false,
            caseStatuses: [],
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.Board, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        this.setInitialBoardState(ParamUtils.getParameterByName('preferences'));
        this.loadLoggedInUserDefaultOrganization();
        this.loadBoardComments();
        this.loadCaseStatuses();
    }

    loadLoggedInUserDefaultOrganization = async () => {
        let response = await _apiClient.getDefaultUserOrganizationFromServer();
        if (!response) return;
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({
            defaultOrganization: response.payload!,
            pendingResponse: false,
        });
    };

    setInitialBoardState = async (preferences: string) => {
        let boardPreferencesJSONString = '';
        // Regardless of whether user settings exist for the user, Preferences passed in query string take precedence over saved preferences.
        if (preferences) {
            boardPreferencesJSONString = atob(preferences);

            if (boardPreferencesJSONString) {
                let userBoardPreferences: IBoardParametersModel = JSON.parse(
                    boardPreferencesJSONString
                );
                let initBoardPreferences: IBoardParametersModel = JSON.parse(
                    boardPreferencesJSONString
                );

                // Check to make sure the Json is still valid and it at least set the basic parameters
                if (userBoardPreferences.statusId > 0 && userBoardPreferences.criteria > 0) {
                    let readyToRenderReport: boolean =
                        userBoardPreferences.date &&
                        (userBoardPreferences.date.startDate || userBoardPreferences.date.endDate)
                            ? true
                            : false;
                    this.setState({
                        initBoardPreferences: initBoardPreferences,
                        boardPreferences: userBoardPreferences,
                        readyToRenderReport: readyToRenderReport,
                        showSettingsModal: !readyToRenderReport,
                    });
                }
            }
        }
    };

    loadBoardComments = async () => {
        let response = await _apiClient.getBoardComments();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        let comments = response.payload!;
        this.setState({
            initComments: JSON.parse(JSON.stringify(comments)),
            comments: comments,
            pendingResponse: false,
        });
    };

    loadCaseStatuses = async () => {
        var response = await _apiClient.getCaseStatusLookupData();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        const caseStatusesToInclude = response.payload!.filter(
            (x) =>
                x.status &&
                (x.status.id === CaseStatusTypesEnum.Open.Value ||
                    x.status.id === CaseStatusTypesEnum.Resolved.Value)
        );
        this.setState({ caseStatuses: caseStatusesToInclude, pendingResponse: false });
    };

    showSettingsModal = () => {
        this.setState({ showSettingsModal: true, refreshReport: false, saveToFavourites: false });
    };

    cancelSettingsModal = () => {
        this.setState({
            boardPreferences: JSON.parse(JSON.stringify(this.state.initBoardPreferences)),
            showSettingsModal: false,
            validation: {},
            refreshReport: false,
            saveToFavourites: false,
        });
    };

    showCommentsModal = () => {
        this.setState({ showCommentsModal: true });
    };

    closeCommentsModal = () => {
        this.setState({
            comments: JSON.parse(JSON.stringify(this.state.initComments)),
            showCommentsModal: false,
        });
    };

    saveComments = async () => {
        this.setState({ pendingResponse: true });

        let response = await _apiClient.saveBoardComments(this.state.comments);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({
            pendingResponse: false,
            showCommentsModal: false,
            initComments: JSON.parse(JSON.stringify(this.state.comments)),
        });
    };

    showExportModal = () => {
        this.setState({ showExportModal: true, validation: {} });
    };

    closeExportModal = () => {
        this.setState({ showExportModal: false, validation: {} });
    };

    generateAndDownloadReport = async () => {
        this.setState({ pendingResponse: true, validation: {} });

        var xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + ApiRoutes.ExportBoard, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                // Reset Comments
                let comments = this.state.comments;
                for (var i = 0; i < comments.length; i++) {
                    comments[i].text = '';
                }

                this.setState({
                    pendingResponse: false,
                    showExportModal: false,
                    comments: comments,
                    initComments: comments,
                });

                var fileName = 'Board.pdf';
                var blob = xhr.response;

                var contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    let contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (var i = 0; i < contentDispositionItems.length; i++) {
                            let currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                var n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                var a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                let validation = this.state.validation;
                validation.model = [DisplayMessages.ReportGenerateError];
                this.setState({ pendingResponse: false, validation: validation });
            }
        };

        let boardExportPreferences = this.state.boardExportPreferences;
        // Always Copy boardPreferences to boardExportPreferences
        boardExportPreferences.statusId = this.state.boardPreferences.statusId;
        boardExportPreferences.subStatusIdList = this.state.boardPreferences.subStatusIdList;
        boardExportPreferences.subStatusIds = this.state.boardPreferences.subStatusIds;
        boardExportPreferences.criteria = this.state.boardPreferences.criteria;
        boardExportPreferences.date = JSON.parse(JSON.stringify(this.state.boardPreferences.date));

        xhr.send(JSON.stringify(boardExportPreferences));
    };

    refreshDashboard = async () => {
        let validation: IValidation = {};
        if (this.state.boardPreferences.statusId == 0) {
            validation.model = ['Status is required'];
            this.setState({ validation: validation });
            return;
        }

        if (this.state.boardPreferences.criteria == 0) {
            validation.model = ['Criteria is required'];
            this.setState({ validation: validation });
            return;
        }

        if (
            !this.state.boardPreferences.date ||
            this.state.boardPreferences.date.type == 0 ||
            (!this.state.boardPreferences.date.startDate &&
                !this.state.boardPreferences.date.endDate)
        ) {
            validation.model = ['At least One Date Filter is required'];
            this.setState({ validation: validation });
            return;
        }

        this.setState({
            initBoardPreferences: JSON.parse(JSON.stringify(this.state.boardPreferences)),
            refreshReport: true,
            showSettingsModal: false,
            saveToFavourites: false,
            readyToRenderReport: true,
            validation: {},
        });
    };

    isChecked(type: string, value: number) {
        switch (type) {
            case BoardFilterDateTypesEnum.AsOfDate.Name:
            case BoardFilterDateTypesEnum.ServiceDate.Name:
            case BoardFilterDateTypesEnum.ResolutionDate.Name:
            case BoardFilterDateTypesEnum.TrialDate.Name:
            case BoardFilterDateTypesEnum.ProjectedSettlementDate.Name:
                return (
                    this.state.boardPreferences.date &&
                    value == this.state.boardPreferences.date.type
                );
            case 'Criteria':
                return value == this.state.boardPreferences.criteria;
            case 'ViewDataType':
                return this.state.boardExportPreferences.viewDataTypes.indexOf(value) > -1;
            case 'IncludeComments':
                return this.state.boardExportPreferences.includeComment;
            case 'BoardCaseDetailsExportTypes':
                return (
                    this.state.boardExportPreferences.viewDataTypes.indexOf(
                        BoardViewDataTypesEnum.CaseDetails.Value
                    ) > -1 && this.state.boardExportPreferences.boardCaseDetailsExportType == value
                );
            default:
                return false;
        }
    }

    handleCheckedChange(event: React.ChangeEvent<HTMLInputElement>, type: string, value: number) {
        const target = event.target;
        const checked = target.checked;

        let boardPreferences = this.state.boardPreferences;
        let boardExportPreferences = this.state.boardExportPreferences;

        switch (type) {
            case BoardFilterDateTypesEnum.AsOfDate.Name:
                if (checked) {
                    if (!boardPreferences.date) boardPreferences.date = {};
                    boardPreferences.date.type = BoardFilterDateTypesEnum.AsOfDate.Value;
                    boardPreferences.date.startDate = undefined;
                    boardPreferences.date.endDate = undefined;
                } else if (
                    boardPreferences.date &&
                    boardPreferences.date.type == BoardFilterDateTypesEnum.AsOfDate.Value
                ) {
                    boardPreferences.date.type = 0;
                    boardPreferences.date.startDate = undefined;
                    boardPreferences.date.endDate = undefined;
                }
                break;
            case BoardFilterDateTypesEnum.ServiceDate.Name:
                if (checked) {
                    if (!boardPreferences.date) boardPreferences.date = {};
                    boardPreferences.date.type = BoardFilterDateTypesEnum.ServiceDate.Value;
                    boardPreferences.date.startDate = undefined;
                    boardPreferences.date.endDate = undefined;
                } else if (
                    boardPreferences.date &&
                    boardPreferences.date.type == BoardFilterDateTypesEnum.ServiceDate.Value
                ) {
                    boardPreferences.date.type = 0;
                    boardPreferences.date.startDate = undefined;
                    boardPreferences.date.endDate = undefined;
                }
                break;
            case BoardFilterDateTypesEnum.ResolutionDate.Name:
                if (checked) {
                    if (!boardPreferences.date) boardPreferences.date = {};
                    boardPreferences.date.type = BoardFilterDateTypesEnum.ResolutionDate.Value;
                    boardPreferences.date.startDate = undefined;
                    boardPreferences.date.endDate = undefined;
                } else if (
                    boardPreferences.date &&
                    boardPreferences.date.type == BoardFilterDateTypesEnum.ResolutionDate.Value
                ) {
                    boardPreferences.date.type = 0;
                    boardPreferences.date.startDate = undefined;
                    boardPreferences.date.endDate = undefined;
                }
                break;
            case BoardFilterDateTypesEnum.TrialDate.Name:
                if (checked) {
                    if (!boardPreferences.date) boardPreferences.date = {};
                    boardPreferences.date.type = BoardFilterDateTypesEnum.TrialDate.Value;
                    boardPreferences.date.startDate = undefined;
                    boardPreferences.date.endDate = undefined;
                } else if (
                    boardPreferences.date &&
                    boardPreferences.date.type == BoardFilterDateTypesEnum.TrialDate.Value
                ) {
                    boardPreferences.date.type = 0;
                    boardPreferences.date.startDate = undefined;
                    boardPreferences.date.endDate = undefined;
                }
                break;
            case BoardFilterDateTypesEnum.ProjectedSettlementDate.Name:
                if (checked) {
                    if (!boardPreferences.date) boardPreferences.date = {};
                    boardPreferences.date.type =
                        BoardFilterDateTypesEnum.ProjectedSettlementDate.Value;
                    boardPreferences.date.startDate = undefined;
                    boardPreferences.date.endDate = undefined;
                } else if (
                    boardPreferences.date &&
                    boardPreferences.date.type ==
                        BoardFilterDateTypesEnum.ProjectedSettlementDate.Value
                ) {
                    boardPreferences.date.type = 0;
                    boardPreferences.date.startDate = undefined;
                    boardPreferences.date.endDate = undefined;
                }
                break;
            case 'Criteria':
                boardPreferences.criteria = checked ? value : 0;
                // Since Criteria is changing, Reset Date Range
                boardPreferences.date = { type: 0 };
                break;
            case 'SaveToFavourites':
                this.setState({ saveToFavourites: checked });
                return;
            case 'ViewDataType':
                if (checked) {
                    boardExportPreferences.viewDataTypes.push(value);
                    if (value == BoardViewDataTypesEnum.CaseDetails.Value)
                        boardExportPreferences.boardCaseDetailsExportType =
                            BoardCaseDetailsExportTypes.PDF.Value;
                } else {
                    let index = boardExportPreferences.viewDataTypes.indexOf(value);
                    if (index > -1) boardExportPreferences.viewDataTypes.splice(index, 1);
                }
                break;
            case 'IncludeComments':
                boardExportPreferences.includeComment = checked;
                break;
            case 'BoardCaseDetailsExportTypes':
                if (checked) {
                    boardExportPreferences.boardCaseDetailsExportType = value;
                } else {
                    boardExportPreferences.boardCaseDetailsExportType =
                        BoardCaseDetailsExportTypes.PDF.Value;
                }
                break;
            default:
                break;
        }

        this.setState({
            boardPreferences: boardPreferences,
            boardExportPreferences: boardExportPreferences,
        });
    }

    handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>, dateType: number) => {
        let boardPreferences = this.state.boardPreferences;
        if (dateType == DateTypesEnum.StartDate) {
            boardPreferences.date!.startDate = event.target.value;
            this.setState({ boardPreferences: boardPreferences });
        } else if (dateType == DateTypesEnum.EndDate) {
            boardPreferences.date!.endDate = event.target.value;
            this.setState({ boardPreferences: boardPreferences });
        }
    };

    handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        switch (event.target.name) {
            case 'status': {
                let boardPreferences = this.state.boardPreferences;
                boardPreferences.statusId =
                    parseInt(event.target.value) > 0
                        ? (boardPreferences.statusId = parseInt(event.target.value))
                        : 0;
                boardPreferences.subStatusIdList = [];
                boardPreferences.subStatusIds = '';
                boardPreferences.criteria = BoardCriteriaEnum.CaseVolume.Value;
                boardPreferences.date = undefined;
                this.setState({ boardPreferences: boardPreferences });
                break;
            }
            default:
        }
    };

    handleCommentsChange = (event: React.ChangeEvent<HTMLTextAreaElement>, dataType: number) => {
        let comments = this.state.comments;
        let match = comments.find((x) => x.boardViewDataType == dataType);
        if (match) {
            match.text = event.target.value;
            this.setState({ comments: comments });
        }
    };

    getBoardViewDataTypeName(value: number) {
        switch (value) {
            case BoardViewDataTypesEnum.State.Value:
                return BoardViewDataTypesEnum.State.DisplayName;
            case BoardViewDataTypesEnum.Jurisdiction.Value:
                return BoardViewDataTypesEnum.Jurisdiction.DisplayName;
            case BoardViewDataTypesEnum.PlaintiffsFirm.Value:
                return BoardViewDataTypesEnum.PlaintiffsFirm.DisplayName;
            case BoardViewDataTypesEnum.Diagnosis.Value:
                return BoardViewDataTypesEnum.Diagnosis.DisplayName;
            case BoardViewDataTypesEnum.Timeline.Value:
                return BoardViewDataTypesEnum.Timeline.DisplayName;
            default:
                return '';
        }
    }

    isValidCriteria(value: number) {
        const subStatusIds: number[] = this.state.boardPreferences.subStatusIdList || [];

        switch (value) {
            case BoardCriteriaEnum.CaseVolume.Value:
                return true;
            case BoardCriteriaEnum.ProjectedSettlement.Value:
                return this.state.boardPreferences.statusId === CaseStatusTypesEnum.Open.Value;
            case BoardCriteriaEnum.ActualSettlement.Value:
                return (
                    this.state.boardPreferences.statusId === CaseStatusTypesEnum.Resolved.Value &&
                    subStatusIds.length == 1 &&
                    subStatusIds[0] === CaseSubStatusTypesEnum.Settled.Value
                );
            default:
                return false;
        }
    }

    isValidDateRange(value: number) {
        switch (value) {
            case BoardFilterDateTypesEnum.AsOfDate.Value:
                return (
                    this.state.boardPreferences.criteria == BoardCriteriaEnum.CaseVolume.Value &&
                    this.state.boardPreferences.statusId === CaseStatusTypesEnum.Open.Value
                );
            case BoardFilterDateTypesEnum.ServiceDate.Value:
            case BoardFilterDateTypesEnum.TrialDate.Value:
                return this.state.boardPreferences.criteria == BoardCriteriaEnum.CaseVolume.Value;
            case BoardFilterDateTypesEnum.ProjectedSettlementDate.Value:
                return (
                    this.state.boardPreferences.criteria ==
                        BoardCriteriaEnum.ProjectedSettlement.Value &&
                    this.state.boardPreferences.statusId === CaseStatusTypesEnum.Open.Value
                );
            case BoardFilterDateTypesEnum.ResolutionDate.Value:
                return (
                    (this.state.boardPreferences.criteria == BoardCriteriaEnum.CaseVolume.Value &&
                        this.state.boardPreferences.statusId ===
                            CaseStatusTypesEnum.Resolved.Value) ||
                    (this.state.boardPreferences.criteria ==
                        BoardCriteriaEnum.ActualSettlement.Value &&
                        this.state.boardPreferences.statusId ===
                            CaseStatusTypesEnum.Resolved.Value &&
                        this.state.boardPreferences.subStatusIdList &&
                        this.state.boardPreferences.subStatusIdList.length == 1 &&
                        this.state.boardPreferences.subStatusIdList[0] ===
                            CaseSubStatusTypesEnum.Settled.Value)
                );
            default:
                return false;
        }
    }

    getStatusLookupValues = () => {
        let statusLookupValues: IMultiSelectOptions[] = [];

        for (let index = 0; index < this.state.caseStatuses.length; index++) {
            const element = this.state.caseStatuses[index];
            if (statusLookupValues.filter((x) => x.id == element.status.id).length == 0)
                statusLookupValues.push({
                    label: element.status.displayName!,
                    value: element.status.name!,
                    id: element.status.id,
                });
        }
        return statusLookupValues;
    };

    getSelectedStatusValue = () => {
        if (this.state.boardPreferences.statusId) {
            const match = this.state.caseStatuses.filter(
                (x) => x.id == this.state.boardPreferences.statusId
            );
            if (match.length > 0) {
                const element = match[0];
                return {
                    label: element.status.displayName!,
                    value: element.status.name!,
                    id: element.status.id,
                };
            }
        }
    };

    getSubStatusLookupValues = (statusId: number) => {
        let subStatusLookupValues: IMultiSelectOptions[] = [];
        this.state.caseStatuses
            .filter((x) => x.status.id == statusId && x.subStatus)
            .map((y) =>
                subStatusLookupValues.push({
                    label: y.subStatus.displayName!,
                    value: y.subStatus.name!,
                    id: y.subStatus.id,
                })
            );
        return subStatusLookupValues;
    };

    getSelectedSubStatusValues = () => {
        let returnData: IMultiSelectOptions[] = [];
        if (
            this.state.boardPreferences.statusId > 0 &&
            this.state.boardPreferences.subStatusIdList
        ) {
            let subStatusValues = this.getSubStatusLookupValues(
                this.state.boardPreferences.statusId
            );
            returnData = subStatusValues.filter(
                (x) => this.state.boardPreferences.subStatusIdList!.indexOf(x.id!) !== -1
            );
        }

        return returnData;
    };

    handleSubStatusValuesSelected = (optionList: any) => {
        let boardPreferences = this.state.boardPreferences;
        let subStatusIds: number[] = [];
        for (var i = 0; i < optionList.length; i++) {
            subStatusIds.push(optionList[i].id!);
        }
        boardPreferences.subStatusIdList = subStatusIds;
        boardPreferences.subStatusIds = subStatusIds.join(',');
        this.setState({ boardPreferences: boardPreferences });
    };

    handleStatusValuesSelected = (optionList: any) => {
        let boardPreferences = this.state.boardPreferences;
        boardPreferences.statusId = optionList.id;
        this.setState({ boardPreferences: boardPreferences });
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;

        return (
            <div>
                <h1 style={{ marginLeft: '10px' }}>
                    {this.state.defaultOrganization.shortName}
                    <BoardPreferencesHeader
                        boardPreferences={this.state.boardPreferences}
                        includeCriteria={true}
                        caseStatuses={this.state.caseStatuses}
                    />
                    {/*<span className="btn btn-blue float-end"> <Link to={LocalRoutes.UserReportList} style={{ color: "white" }}>View Existing Reports</Link></span>*/}
                    {this.state.comments.length > 0 ? (
                        <button
                            className="btn btn-orange float-end"
                            onClick={this.showCommentsModal}
                        >
                            Comments
                        </button>
                    ) : null}
                    {this.state.readyToRenderReport ? (
                        <button
                            className="btn btn-no-bg float-end text-gray"
                            onClick={this.showExportModal}
                        >
                            <i className="fal fa-file-chart-column" />
                            &nbsp;Report
                        </button>
                    ) : null}
                    <button
                        className="btn btn-no-bg float-end text-gray"
                        onClick={this.showSettingsModal}
                    >
                        <i className="fal fa-user-cog" />
                        &nbsp;Settings
                    </button>
                    {(Authorization.userHasRight(
                        UserRightsEnum.ViewCaseFileBasic,
                        this.props.user
                    ) ||
                        Authorization.userHasRight(UserRightsEnum.ViewCaseFile, this.props.user)) &&
                    this.state.readyToRenderReport ? (
                        <Link
                            to={
                                LocalRoutes.BoardCaseDetail +
                                '?preferences=' +
                                btoa(JSON.stringify(this.state.boardPreferences))
                            }
                        >
                            <span className="btn btn-no-bg float-end text-gray">
                                <i className="fal fa-ballot" />
                                &nbsp;Case Details
                            </span>
                        </Link>
                    ) : null}
                </h1>

                {this.state.readyToRenderReport ? (
                    <Html5ReportViewer
                        reportName="Board.trdp"
                        reportParameters={BoardHelper.getBoardReportParameters(
                            this.state.boardPreferences
                        )}
                        refreshReport={this.state.refreshReport}
                    />
                ) : null}

                {this.state.showSettingsModal ? (
                    <Modal
                        centered
                        size="lg"
                        show={this.state.showSettingsModal}
                        onHide={this.cancelSettingsModal}
                        backdrop={false}
                        dialogClassName="board-settings-dialog"
                    >
                        <Modal.Header>
                            <Modal.Title> Board Settings </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col-sm-12">
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <label className="control-label col-sm-2" htmlFor="caseStatus">
                                        Status
                                    </label>
                                    <div className="col-sm-3">
                                        <CheckmateSelect
                                            options={this.getStatusLookupValues()}
                                            onChange={this.handleStatusValuesSelected}
                                            value={this.getSelectedStatusValue()}
                                            placeholder="-- Select --"
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <CheckmateSelect
                                            isMulti={true}
                                            options={this.getSubStatusLookupValues(
                                                this.state.boardPreferences.statusId
                                            )}
                                            onChange={this.handleSubStatusValuesSelected}
                                            value={this.getSelectedSubStatusValues()}
                                            placeholder="-- Select to filter further --"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 vertical-padding-med">
                                    <label className="control-label" htmlFor="caseStatus">
                                        Criteria
                                    </label>
                                    <div>
                                        <input
                                            id="chkCriteriaCaseVolume"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked(
                                                'Criteria',
                                                BoardCriteriaEnum.CaseVolume.Value
                                            )}
                                            disabled={
                                                !this.isValidCriteria(
                                                    BoardCriteriaEnum.CaseVolume.Value
                                                )
                                            }
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(
                                                    e,
                                                    'Criteria',
                                                    BoardCriteriaEnum.CaseVolume.Value
                                                );
                                            }}
                                        />
                                        <span
                                            className={
                                                this.isValidCriteria(
                                                    BoardCriteriaEnum.CaseVolume.Value
                                                )
                                                    ? ''
                                                    : 'grayed-out'
                                            }
                                        >
                                            &nbsp;&nbsp;{BoardCriteriaEnum.CaseVolume.Name}
                                        </span>
                                    </div>
                                    <div>
                                        <input
                                            id="chkCriteriaProjSettlement"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked(
                                                'Criteria',
                                                BoardCriteriaEnum.ProjectedSettlement.Value
                                            )}
                                            disabled={
                                                !this.isValidCriteria(
                                                    BoardCriteriaEnum.ProjectedSettlement.Value
                                                )
                                            }
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(
                                                    e,
                                                    'Criteria',
                                                    BoardCriteriaEnum.ProjectedSettlement.Value
                                                );
                                            }}
                                        />
                                        <span
                                            className={
                                                this.isValidCriteria(
                                                    BoardCriteriaEnum.ProjectedSettlement.Value
                                                )
                                                    ? ''
                                                    : 'grayed-out'
                                            }
                                        >
                                            &nbsp;&nbsp;{BoardCriteriaEnum.ProjectedSettlement.Name}
                                        </span>
                                    </div>
                                    <div>
                                        <input
                                            id="chkCriteriaActualSettlement"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked(
                                                'Criteria',
                                                BoardCriteriaEnum.ActualSettlement.Value
                                            )}
                                            disabled={
                                                !this.isValidCriteria(
                                                    BoardCriteriaEnum.ActualSettlement.Value
                                                )
                                            }
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(
                                                    e,
                                                    'Criteria',
                                                    BoardCriteriaEnum.ActualSettlement.Value
                                                );
                                            }}
                                        />
                                        <span
                                            className={
                                                this.isValidCriteria(
                                                    BoardCriteriaEnum.ActualSettlement.Value
                                                )
                                                    ? ''
                                                    : 'grayed-out'
                                            }
                                        >
                                            &nbsp;&nbsp;{BoardCriteriaEnum.ActualSettlement.Name}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-sm-12 vertical-padding-med">
                                    <label className="control-label" htmlFor="dateRange">
                                        Date Range
                                    </label>
                                    <div>
                                        <input
                                            id="chkDateRangeTypeActiveAsOfDate"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked(
                                                BoardFilterDateTypesEnum.AsOfDate.Name,
                                                BoardFilterDateTypesEnum.AsOfDate.Value
                                            )}
                                            disabled={
                                                !this.isValidDateRange(
                                                    BoardFilterDateTypesEnum.AsOfDate.Value
                                                )
                                            }
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(
                                                    e,
                                                    BoardFilterDateTypesEnum.AsOfDate.Name,
                                                    BoardFilterDateTypesEnum.AsOfDate.Value
                                                );
                                            }}
                                        />
                                        <span
                                            className={
                                                'padding-right ' +
                                                (this.isValidDateRange(
                                                    BoardFilterDateTypesEnum.AsOfDate.Value
                                                )
                                                    ? ''
                                                    : 'grayed-out')
                                            }
                                        >
                                            &nbsp;&nbsp;
                                            {BoardFilterDateTypesEnum.AsOfDate.DisplayName}
                                        </span>
                                        {this.isChecked(
                                            BoardFilterDateTypesEnum.AsOfDate.Name,
                                            BoardFilterDateTypesEnum.AsOfDate.Value
                                        ) &&
                                        this.isValidDateRange(
                                            BoardFilterDateTypesEnum.AsOfDate.Value
                                        ) ? (
                                            <span>
                                                <input
                                                    type="date"
                                                    name="activeAsOfDateStartDate"
                                                    className={
                                                        'form-control ' +
                                                        (this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date
                                                            .startDate === ''
                                                            ? 'unselectClass '
                                                            : '')
                                                    }
                                                    style={{ display: 'inline-block' }}
                                                    value={
                                                        this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.startDate
                                                            ? Common.dateFormat(
                                                                  this.state.boardPreferences.date
                                                                      .startDate
                                                              )
                                                            : ''
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleChangeDate(
                                                            e,
                                                            DateTypesEnum.StartDate
                                                        );
                                                    }}
                                                />{' '}
                                                &nbsp;&nbsp;to&nbsp;&nbsp;
                                                <input
                                                    type="date"
                                                    name="activeAsOfDateEndDate"
                                                    className={
                                                        'form-control ' +
                                                        (this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.endDate ===
                                                            ''
                                                            ? 'unselectClass '
                                                            : '')
                                                    }
                                                    style={{ display: 'inline-block' }}
                                                    value={
                                                        this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.endDate
                                                            ? Common.dateFormat(
                                                                  this.state.boardPreferences.date
                                                                      .endDate
                                                              )
                                                            : ''
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleChangeDate(
                                                            e,
                                                            DateTypesEnum.EndDate
                                                        );
                                                    }}
                                                />
                                            </span>
                                        ) : null}
                                    </div>
                                    <div>
                                        <input
                                            id="chkDateRangeTypeServiceDate"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked(
                                                BoardFilterDateTypesEnum.ServiceDate.Name,
                                                BoardFilterDateTypesEnum.ServiceDate.Value
                                            )}
                                            disabled={
                                                !this.isValidDateRange(
                                                    BoardFilterDateTypesEnum.ServiceDate.Value
                                                )
                                            }
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(
                                                    e,
                                                    BoardFilterDateTypesEnum.ServiceDate.Name,
                                                    BoardFilterDateTypesEnum.ServiceDate.Value
                                                );
                                            }}
                                        />
                                        <span
                                            className={
                                                'padding-right ' +
                                                (this.isValidDateRange(
                                                    BoardFilterDateTypesEnum.ServiceDate.Value
                                                )
                                                    ? ''
                                                    : 'grayed-out')
                                            }
                                        >
                                            &nbsp;&nbsp;
                                            {BoardFilterDateTypesEnum.ServiceDate.DisplayName}
                                        </span>
                                        {this.isChecked(
                                            BoardFilterDateTypesEnum.ServiceDate.Name,
                                            BoardFilterDateTypesEnum.ServiceDate.Value
                                        ) &&
                                        this.isValidDateRange(
                                            BoardFilterDateTypesEnum.ServiceDate.Value
                                        ) ? (
                                            <span>
                                                <input
                                                    className={
                                                        'form-control ' +
                                                        (this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date
                                                            .startDate === ''
                                                            ? 'unselectClass '
                                                            : '')
                                                    }
                                                    style={{ display: 'inline-block' }}
                                                    type="date"
                                                    name="serviceDateStartDate"
                                                    value={
                                                        this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.startDate
                                                            ? Common.dateFormat(
                                                                  this.state.boardPreferences.date
                                                                      .startDate
                                                              )
                                                            : ''
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleChangeDate(
                                                            e,
                                                            DateTypesEnum.StartDate
                                                        );
                                                    }}
                                                />{' '}
                                                &nbsp;&nbsp;to&nbsp;&nbsp;
                                                <input
                                                    className={
                                                        'form-control ' +
                                                        (this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.endDate ===
                                                            ''
                                                            ? 'unselectClass '
                                                            : '')
                                                    }
                                                    style={{ display: 'inline-block' }}
                                                    type="date"
                                                    name="serviceDateEndDate"
                                                    value={
                                                        this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.endDate
                                                            ? Common.dateFormat(
                                                                  this.state.boardPreferences.date
                                                                      .endDate
                                                              )
                                                            : ''
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleChangeDate(
                                                            e,
                                                            DateTypesEnum.EndDate
                                                        );
                                                    }}
                                                />
                                            </span>
                                        ) : null}
                                    </div>
                                    <div>
                                        <input
                                            id="chkDateRangeTypeDateOfDismissal"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked(
                                                BoardFilterDateTypesEnum.ResolutionDate.Name,
                                                BoardFilterDateTypesEnum.ResolutionDate.Value
                                            )}
                                            disabled={
                                                !this.isValidDateRange(
                                                    BoardFilterDateTypesEnum.ResolutionDate.Value
                                                )
                                            }
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(
                                                    e,
                                                    BoardFilterDateTypesEnum.ResolutionDate.Name,
                                                    BoardFilterDateTypesEnum.ResolutionDate.Value
                                                );
                                            }}
                                        />
                                        <span
                                            className={
                                                'padding-right ' +
                                                (this.isValidDateRange(
                                                    BoardFilterDateTypesEnum.ResolutionDate.Value
                                                )
                                                    ? ''
                                                    : 'grayed-out')
                                            }
                                        >
                                            &nbsp;&nbsp;
                                            {BoardFilterDateTypesEnum.ResolutionDate.DisplayName}
                                        </span>
                                        {this.isChecked(
                                            BoardFilterDateTypesEnum.ResolutionDate.Name,
                                            BoardFilterDateTypesEnum.ResolutionDate.Value
                                        ) &&
                                        this.isValidDateRange(
                                            BoardFilterDateTypesEnum.ResolutionDate.Value
                                        ) ? (
                                            <span>
                                                <input
                                                    className={
                                                        'form-control ' +
                                                        (this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date
                                                            .startDate === ''
                                                            ? 'unselectClass '
                                                            : '')
                                                    }
                                                    style={{ display: 'inline-block' }}
                                                    type="date"
                                                    name="dateOfDismissalStartDate"
                                                    value={
                                                        this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.startDate
                                                            ? Common.dateFormat(
                                                                  this.state.boardPreferences.date
                                                                      .startDate
                                                              )
                                                            : ''
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleChangeDate(
                                                            e,
                                                            DateTypesEnum.StartDate
                                                        );
                                                    }}
                                                />{' '}
                                                &nbsp;&nbsp;to&nbsp;&nbsp;
                                                <input
                                                    className={
                                                        'form-control ' +
                                                        (this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.endDate ===
                                                            ''
                                                            ? 'unselectClass '
                                                            : '')
                                                    }
                                                    style={{ display: 'inline-block' }}
                                                    type="date"
                                                    name="dateOfDismissalEndDate"
                                                    value={
                                                        this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.endDate
                                                            ? Common.dateFormat(
                                                                  this.state.boardPreferences.date
                                                                      .endDate
                                                              )
                                                            : ''
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleChangeDate(
                                                            e,
                                                            DateTypesEnum.EndDate
                                                        );
                                                    }}
                                                />
                                            </span>
                                        ) : null}
                                    </div>
                                    <div>
                                        <input
                                            id="chkDateRangeTypeTrialDate"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked(
                                                BoardFilterDateTypesEnum.TrialDate.Name,
                                                BoardFilterDateTypesEnum.TrialDate.Value
                                            )}
                                            disabled={
                                                !this.isValidDateRange(
                                                    BoardFilterDateTypesEnum.TrialDate.Value
                                                )
                                            }
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(
                                                    e,
                                                    BoardFilterDateTypesEnum.TrialDate.Name,
                                                    BoardFilterDateTypesEnum.TrialDate.Value
                                                );
                                            }}
                                        />
                                        <span
                                            className={
                                                'padding-right ' +
                                                (this.isValidDateRange(
                                                    BoardFilterDateTypesEnum.TrialDate.Value
                                                )
                                                    ? ''
                                                    : 'grayed-out')
                                            }
                                        >
                                            &nbsp;&nbsp;
                                            {BoardFilterDateTypesEnum.TrialDate.DisplayName}
                                        </span>
                                        {this.isChecked(
                                            BoardFilterDateTypesEnum.TrialDate.Name,
                                            BoardFilterDateTypesEnum.TrialDate.Value
                                        ) &&
                                        this.isValidDateRange(
                                            BoardFilterDateTypesEnum.TrialDate.Value
                                        ) ? (
                                            <span>
                                                <input
                                                    className={
                                                        'form-control ' +
                                                        (this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date
                                                            .startDate === ''
                                                            ? 'unselectClass '
                                                            : '')
                                                    }
                                                    style={{ display: 'inline-block' }}
                                                    type="date"
                                                    name="trialDateStartDate"
                                                    value={
                                                        this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.startDate
                                                            ? Common.dateFormat(
                                                                  this.state.boardPreferences.date
                                                                      .startDate
                                                              )
                                                            : ''
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleChangeDate(
                                                            e,
                                                            DateTypesEnum.StartDate
                                                        );
                                                    }}
                                                />{' '}
                                                &nbsp;&nbsp;to&nbsp;&nbsp;
                                                <input
                                                    className={
                                                        'form-control ' +
                                                        (this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.endDate ===
                                                            ''
                                                            ? 'unselectClass '
                                                            : '')
                                                    }
                                                    style={{ display: 'inline-block' }}
                                                    type="date"
                                                    name="trialDateEndDate"
                                                    value={
                                                        this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.endDate
                                                            ? Common.dateFormat(
                                                                  this.state.boardPreferences.date
                                                                      .endDate
                                                              )
                                                            : ''
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleChangeDate(
                                                            e,
                                                            DateTypesEnum.EndDate
                                                        );
                                                    }}
                                                />
                                            </span>
                                        ) : null}
                                    </div>
                                    <div>
                                        <input
                                            id="chkDateRangeTypeProjectedSettlementDate"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked(
                                                BoardFilterDateTypesEnum.ProjectedSettlementDate
                                                    .Name,
                                                BoardFilterDateTypesEnum.ProjectedSettlementDate
                                                    .Value
                                            )}
                                            disabled={
                                                !this.isValidDateRange(
                                                    BoardFilterDateTypesEnum.ProjectedSettlementDate
                                                        .Value
                                                )
                                            }
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(
                                                    e,
                                                    BoardFilterDateTypesEnum.ProjectedSettlementDate
                                                        .Name,
                                                    BoardFilterDateTypesEnum.ProjectedSettlementDate
                                                        .Value
                                                );
                                            }}
                                        />
                                        <span
                                            className={
                                                'padding-right ' +
                                                (this.isValidDateRange(
                                                    BoardFilterDateTypesEnum.ProjectedSettlementDate
                                                        .Value
                                                )
                                                    ? ''
                                                    : 'grayed-out')
                                            }
                                        >
                                            &nbsp;&nbsp;
                                            {
                                                BoardFilterDateTypesEnum.ProjectedSettlementDate
                                                    .DisplayName
                                            }
                                        </span>
                                        {this.isChecked(
                                            BoardFilterDateTypesEnum.ProjectedSettlementDate.Name,
                                            BoardFilterDateTypesEnum.ProjectedSettlementDate.Value
                                        ) &&
                                        this.isValidDateRange(
                                            BoardFilterDateTypesEnum.ProjectedSettlementDate.Value
                                        ) ? (
                                            <span>
                                                <input
                                                    className={
                                                        'form-control ' +
                                                        (this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date
                                                            .startDate === ''
                                                            ? 'unselectClass '
                                                            : '')
                                                    }
                                                    style={{ display: 'inline-block' }}
                                                    type="date"
                                                    name="projectedSettlementDateStartDate"
                                                    value={
                                                        this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.startDate
                                                            ? Common.dateFormat(
                                                                  this.state.boardPreferences.date
                                                                      .startDate
                                                              )
                                                            : ''
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleChangeDate(
                                                            e,
                                                            DateTypesEnum.StartDate
                                                        );
                                                    }}
                                                />{' '}
                                                &nbsp;&nbsp;to&nbsp;&nbsp;
                                                <input
                                                    className={
                                                        'form-control ' +
                                                        (this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.endDate ===
                                                            ''
                                                            ? 'unselectClass '
                                                            : '')
                                                    }
                                                    style={{ display: 'inline-block' }}
                                                    type="date"
                                                    name="projectedSettlementDateEndDate"
                                                    value={
                                                        this.state.boardPreferences.date &&
                                                        this.state.boardPreferences.date.endDate
                                                            ? Common.dateFormat(
                                                                  this.state.boardPreferences.date
                                                                      .endDate
                                                              )
                                                            : ''
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleChangeDate(
                                                            e,
                                                            DateTypesEnum.EndDate
                                                        );
                                                    }}
                                                />
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.cancelSettingsModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={this.refreshDashboard}
                                >
                                    Go
                                </button>
                                {/*<input id="chkSaveToFav" type="checkbox" className="form-check-input" onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleCheckedChange(e, 'SaveToFavourites', 0) }} /><span>&nbsp;&nbsp;Save to Favourites</span>*/}
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                {this.state.showCommentsModal ? (
                    <Modal
                        centered
                        show={this.state.showCommentsModal}
                        onHide={this.closeCommentsModal}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> Comments </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.comments.map((comment: IBoardComment, i: number) => {
                                return (
                                    <div className="form-group" style={{ paddingLeft: '10px' }}>
                                        <label className="control-label">
                                            {this.getBoardViewDataTypeName(
                                                comment.boardViewDataType
                                            )}
                                        </label>
                                        <textarea
                                            className="form-control"
                                            value={comment.text}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => {
                                                this.handleCommentsChange(
                                                    e,
                                                    comment.boardViewDataType
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            })}
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.closeCommentsModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={this.saveComments}
                                >
                                    Save
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                {this.state.showExportModal ? (
                    <Modal
                        centered
                        show={this.state.showExportModal}
                        onHide={this.closeExportModal}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> Export Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                            <div className="container-fluid">
                                <div className="col-sm-12">
                                    <label className="control-label" htmlFor="exportViewDataTypes">
                                        Data
                                    </label>
                                    <div>
                                        <input
                                            id="chkViewDataTypeState"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked(
                                                'ViewDataType',
                                                BoardViewDataTypesEnum.State.Value
                                            )}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(
                                                    e,
                                                    'ViewDataType',
                                                    BoardViewDataTypesEnum.State.Value
                                                );
                                            }}
                                        />
                                        <span>&nbsp;&nbsp;{BoardViewDataTypesEnum.State.Name}</span>
                                    </div>
                                    <div>
                                        <input
                                            id="chkViewDataTypeJurisdiction"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked(
                                                'ViewDataType',
                                                BoardViewDataTypesEnum.Jurisdiction.Value
                                            )}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(
                                                    e,
                                                    'ViewDataType',
                                                    BoardViewDataTypesEnum.Jurisdiction.Value
                                                );
                                            }}
                                        />
                                        <span>
                                            &nbsp;&nbsp;{BoardViewDataTypesEnum.Jurisdiction.Name}
                                        </span>
                                    </div>
                                    <div>
                                        <input
                                            id="chkViewDataTypePlaintiffsFirm"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked(
                                                'ViewDataType',
                                                BoardViewDataTypesEnum.PlaintiffsFirm.Value
                                            )}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(
                                                    e,
                                                    'ViewDataType',
                                                    BoardViewDataTypesEnum.PlaintiffsFirm.Value
                                                );
                                            }}
                                        />
                                        <span>
                                            &nbsp;&nbsp;{BoardViewDataTypesEnum.PlaintiffsFirm.Name}
                                        </span>
                                    </div>
                                    <div>
                                        <input
                                            id="chkViewDataTypeDiagnosis"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked(
                                                'ViewDataType',
                                                BoardViewDataTypesEnum.Diagnosis.Value
                                            )}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(
                                                    e,
                                                    'ViewDataType',
                                                    BoardViewDataTypesEnum.Diagnosis.Value
                                                );
                                            }}
                                        />
                                        <span>
                                            &nbsp;&nbsp;{BoardViewDataTypesEnum.Diagnosis.Name}
                                        </span>
                                    </div>
                                    <div>
                                        <input
                                            id="chkViewDataTypeTimeline"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked(
                                                'ViewDataType',
                                                BoardViewDataTypesEnum.Timeline.Value
                                            )}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(
                                                    e,
                                                    'ViewDataType',
                                                    BoardViewDataTypesEnum.Timeline.Value
                                                );
                                            }}
                                        />
                                        <span>
                                            &nbsp;&nbsp;{BoardViewDataTypesEnum.Timeline.Name}
                                        </span>
                                    </div>
                                    <div>
                                        <input
                                            id="chkViewDataTypeCaseDetails"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked(
                                                'ViewDataType',
                                                BoardViewDataTypesEnum.CaseDetails.Value
                                            )}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(
                                                    e,
                                                    'ViewDataType',
                                                    BoardViewDataTypesEnum.CaseDetails.Value
                                                );
                                            }}
                                        />
                                        <span>
                                            &nbsp;&nbsp;{BoardViewDataTypesEnum.CaseDetails.Name}
                                        </span>
                                    </div>
                                    {this.isChecked(
                                        'ViewDataType',
                                        BoardViewDataTypesEnum.CaseDetails.Value
                                    ) ? (
                                        <div style={{ paddingLeft: '20px' }}>
                                            <div>
                                                <input
                                                    id="chkBoardCaseDetailsExportTypesPDF"
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={this.isChecked(
                                                        'BoardCaseDetailsExportTypes',
                                                        BoardCaseDetailsExportTypes.PDF.Value
                                                    )}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleCheckedChange(
                                                            e,
                                                            'BoardCaseDetailsExportTypes',
                                                            BoardCaseDetailsExportTypes.PDF.Value
                                                        );
                                                    }}
                                                />
                                                <span>
                                                    &nbsp;&nbsp;
                                                    {BoardCaseDetailsExportTypes.PDF.Name}
                                                </span>
                                            </div>
                                            <div>
                                                <input
                                                    id="chkBoardCaseDetailsExportTypesExcel"
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={this.isChecked(
                                                        'BoardCaseDetailsExportTypes',
                                                        BoardCaseDetailsExportTypes.Excel.Value
                                                    )}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleCheckedChange(
                                                            e,
                                                            'BoardCaseDetailsExportTypes',
                                                            BoardCaseDetailsExportTypes.Excel.Value
                                                        );
                                                    }}
                                                />
                                                <span>
                                                    &nbsp;&nbsp;
                                                    {BoardCaseDetailsExportTypes.Excel.Name}
                                                </span>
                                            </div>
                                            <div>
                                                <input
                                                    id="chkBoardCaseDetailsExportTypesBoth"
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={this.isChecked(
                                                        'BoardCaseDetailsExportTypes',
                                                        BoardCaseDetailsExportTypes.Both.Value
                                                    )}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleCheckedChange(
                                                            e,
                                                            'BoardCaseDetailsExportTypes',
                                                            BoardCaseDetailsExportTypes.Both.Value
                                                        );
                                                    }}
                                                />
                                                <span>
                                                    &nbsp;&nbsp;
                                                    {BoardCaseDetailsExportTypes.Both.Name}
                                                </span>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col-sm-12">
                                    <hr />
                                    <div>
                                        <input
                                            id="chkIncludeComments"
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={this.isChecked('IncludeComments', -1)}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleCheckedChange(e, 'IncludeComments', -1);
                                            }}
                                        />
                                        <span>&nbsp;&nbsp;Include Comments</span>
                                    </div>
                                </div>
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.closeExportModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={this.generateAndDownloadReport}
                                >
                                    Go
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}
            </div>
        );
    }
}
