import { AssertionDescriptorValueDisplayTypes } from "../utilities/Constants";
import moment from "moment";

export default class Common {
    static currentLocalTime() {
        return moment().format("HH:mm:ss sss");
    }

    static currentUTCTime() {
        return moment().utc().format("YYYY-MM-DD HH:mm:ss");
    }

    static currentDate() {
        return moment().format("YYYY-MM-DD");
    }

    static currentDateTime() {
        return moment().format("YYYY-MM-DD HH:mm");
    }

    static dateFormat(date?: string) {
        if (date && date.length > 0) {
            return moment(date).format("YYYY-MM-DD");
        }
        return "";
    }

    static dateTimeFormat(date?: string) {
        if (date && date.length > 0) {
            return moment(date).format("YYYY-MM-DD HH:mm");
        }
        return "";
    }

    static dateTimeFormatToLocal(date?: string) {
        if (date && date.length > 0) {
            let localDate = moment.utc(date).local().format("YYYY-MM-DD HH:mm");
            return localDate;
        }
        return "";
    }

    static toUTC(date: string) {
        return moment(date).utc().format("YYYY-MM-DD HH:mm");
    }

    static localEndOfDayInUTC(date: string) {
        return moment(date).endOf('day').utc().format("YYYY-MM-DD HH:mm");
    }

    static localStartOfDayInUTC(date: string) {
        return moment(date).startOf('day').utc().format("YYYY-MM-DD HH:mm");
    }

    static getMonthAndYear(strDate: string) {
        return moment(strDate).format("MMMM YYYY");
    }

    static Year(strDate: string) {
        return moment(strDate).year();
    }

    static getStartOfCalendarYear() {
        return moment().startOf('year').format("YYYY-MM-DD");
    }

    static getEndOfCalendarYear() {
        return moment().endOf('year').format("YYYY-MM-DD");
    }

    static isMinDateTime(date: string) {
        return moment(date).year() == 1;
    }

    static formatCurrency(value?: string, excludeSymbol?: boolean) {
        if (value) {
            let amount = parseInt(value);
            if (Number.isNaN(amount)) return '';

            let formattedCurrency = Math.abs(amount).toLocaleString();

            if (!excludeSymbol) {
                formattedCurrency = '$' + formattedCurrency;

                if (amount < 0)
                    formattedCurrency = '(' + formattedCurrency + ')';
            }                

            return formattedCurrency;
        }
        return value;
    }
    
    static isValidDate(strDate: string) {
        var formats = [
            moment.ISO_8601,
            'YYYY-MM-DD'
        ];

        return moment(strDate, formats, true).isValid();
    }

    static dateDiffInYears(strDate1: string, strDate2: string): number {
        var a = moment(strDate1);
        var b = moment(strDate2);
        return a.diff(b, 'years');
    }

    static dateDiffInDays(strDate1: string, strDate2: string): number {
        var a = moment(strDate1);
        var b = moment(strDate2);
        return a.diff(b, 'days');
    }

    static dateDiff(strDate1: string, strDate2: string): number {
        var a = moment(strDate1);
        var b = moment(strDate2);
        return a.diff(b);
    }

    static isValidPhoneNumber(str: string): boolean {
        const regEx = /^\(?([0-9]{3})\)?[-]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (str.match(regEx))
            return true;
        return false;
    }

    static isValidEmail(email: string): boolean {
        const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.toLowerCase().match(regEx))
            return true;
        return false;
    }

    static formatPhoneNumber(value: string): string {
        const phoneNumber = value.replace(/[^\d]/g, "");
        if (phoneNumber.length < 4) return phoneNumber;
        if (phoneNumber.length < 7) {
            return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
        }
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }

    static formatTextByDisplayType(value: string, format?: number) {
        if (format) {
            switch (format) {
                case AssertionDescriptorValueDisplayTypes.Currency.Value:
                    return this.formatCurrency(value);
                case AssertionDescriptorValueDisplayTypes.DateTime.Value:
                    return this.dateFormat(value);
                case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                    return value + "%";
                default:
            }
        }
        return value;
    }
}

export const EMPTY_VALUE = '__________';