import * as React from 'react';

import { IInvitationModel, IOrganizationRoleModel } from '../../interfaces/IUser';

import ApiClient from '../../services/apiClient';
import Auth from '../../stores/authentication';
import Authorization from '../../stores/Authorization';
import { CheckmateDialog } from '../../components/shared/dialog';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import { IDialogModel } from '../../interfaces/IDialog';
import { ILookupModel } from '../../interfaces/ILookup';
import { IOrganizationModel } from '../../interfaces/IOrganization';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Navigate } from 'react-router-dom';
import { OrganizationRole } from '../../components/organization/OrganizationRoles';
import { OrganizationTypesEnum } from '../../utilities/Constants';
import ValidateUtils from '../../shared/validations';

let _apiClient = new ApiClient();

interface IInvitationProps {
    user: IUserModel;
}

interface IInvitationState {
    userInvitation: IInvitationModel;
    validation: IValidation;
    redirect: boolean;
    isShowSuccessMsg: boolean;
    dialog: IDialogModel;
    roles: ILookupModel[];
    currentRoles: ILookupModel[];
    zones: IOrganizationModel[];
    organizationsList: IOrganizationModel[];
    openOrganizationRolesModal?: boolean;
    currentOrganizationRole?: IOrganizationModel;
}

export class Invitation extends React.Component<IInvitationProps, IInvitationState> {
    constructor(props: any) {
        super(props);

        this.state = {
            userInvitation: { organizationRoles: [] },
            validation: {},
            redirect: false,
            isShowSuccessMsg: false,
            dialog: { isShowingModal: false },
            roles: [],
            currentRoles: [],
            zones: [],
            organizationsList: [],
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.CreateInvitation, this.props.user))
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadOrganizations();
        this.displayDefaultSettings();
    }

    displayDefaultSettings = () => {
        let defaultOrganization = Auth.getUserDefaultOrganization();
        if (defaultOrganization) {
            var currentUserInvitation = this.state.userInvitation;

            let homeOrganization = Auth.getUserHomeOrganization(this.props.user);
            if (homeOrganization) {
                currentUserInvitation.homeOrganization = homeOrganization;
            }

            this.setState({
                userInvitation: currentUserInvitation,
            });

            this.loadZones(defaultOrganization.guid!);
        } else {
            let validation = this.state.validation;
            validation.error = ['Could not find Default organization for User'];
            this.setState({
                redirect: false,
                validation: validation,
            });
        }
    };

    loadOrganizations = async () => {
        var res = await _apiClient.getOrganizations();
        if (res.httpResponse.status == 401) {
            window.location.reload();
            return;
        }

        if (res.errorMessage) {
            this.setState({ validation: ValidateUtils.parseErrors(res.errors, res.errorMessage) });
            return;
        }

        if (res.payload) {
            //let allOrganizations = res.payload;
            //let filteredOrgsWithoutDuplicates: IOrganizationModel[] = [];

            //for (var i = 0; i < allOrganizations.length; i++) {
            //    let currentOrg = allOrganizations[i];
            //    if (currentOrg.organizationType!.id != OrganizationTypesEnum.Portfolio.Value) {
            //        filteredOrgsWithoutDuplicates.push(currentOrg);
            //    }
            //    else {
            //        let portfolioWithSameNameAsCompanyMatchIndex = allOrganizations.findIndex(org => org.name == currentOrg.name && org.organizationType!.id == OrganizationTypesEnum.Company.Value);
            //        if (portfolioWithSameNameAsCompanyMatchIndex == -1) {// i.e. a match for Portfolio with same name as Company not found
            //            filteredOrgsWithoutDuplicates.push(currentOrg);
            //        }
            //    }
            //}

            this.setState({ organizationsList: res.payload });
        }
    };

    loadZones = async (guid: string) => {
        var response = await _apiClient.getAssociatedOrganizationsById(guid);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }
        if (response.payload) {
            let associatedOrganizations = response.payload;
            let associatedPortfolios = associatedOrganizations.filter(
                (org) => org.organizationType!.id == OrganizationTypesEnum.Portfolio.Value
            );
            this.setState({
                zones: associatedPortfolios,
            });
        }
    };

    handleCheckmateSelectChange = (selectedItem: any, name: string) => {
        let userInvitation = this.state.userInvitation;
        let validation = this.state.validation;
        switch (name) {
            case 'homeOrganization':
                const value = selectedItem && selectedItem.guid ? selectedItem.guid : '';
                this.state.organizationsList.map(
                    (homeOrganization: IOrganizationModel, i: number) => {
                        if (value == homeOrganization.guid) {
                            userInvitation.homeOrganization = homeOrganization;
                        }
                    }
                );
                this.loadZones(value);
                validation.homeOrganization = [''];
                break;
            default:
                break;
        }
        this.setState({
            userInvitation: userInvitation,
            validation: validation,
        });
    };

    changeValue = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        this.setChangedValue(name, value);
    };

    setChangedValue(name: string, value: any) {
        let userInvitation = this.state.userInvitation;
        let validation = this.state.validation;

        switch (name) {
            case 'firstName':
                userInvitation.firstName = value;
                validation.firstname = [''];
                break;
            case 'lastName':
                userInvitation.lastName = value;
                validation.lastname = [''];
                break;
            case 'email':
                let email = value as string;
                if (email) {
                    email = email.replace(/\s/g, '').trim();
                }
                userInvitation.email = email;
                validation.email = [''];
                break;
            default:
                break;
        }
        this.setState({
            userInvitation: userInvitation,
        });
    }

    sendInvite = async () => {
        let userInvitation = this.state.userInvitation;

        let validation: IValidation = {};
        let hasErrors = false;
        if (!userInvitation.firstName) {
            validation.firtsName = ['First Name is required'];
            hasErrors = true;
        }
        if (!userInvitation.lastName) {
            validation.lastName = ['Last Name is required'];
            hasErrors = true;
        }
        if (!userInvitation.email) {
            validation.email = ['Email is required'];
            hasErrors = true;
        }

        if (!userInvitation.organizationRoles || userInvitation.organizationRoles.length == 0) {
            validation.zoneAccess = ['At least one Zone Access is required'];
            hasErrors = true;
        }

        if (hasErrors) {
            this.setState({ validation: validation });
            return;
        }

        if (userInvitation.email) {
            userInvitation.email = userInvitation.email.replace(/\s/g, '').trim();
        }

        var response = await _apiClient.createInvitation(userInvitation);

        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
        } else {
            this.setState({
                dialog: {
                    isShowingModal: true,
                    title: 'Confirmation',
                    body: 'Invitation sent successfully, Do you want to continue to send another invite?',
                    confirmText: 'Yes',
                    cancelText: 'No',
                },
                isShowSuccessMsg: true,
            });
        }
    };

    cancelInvite = () => {
        this.setState({
            dialog: {
                isShowingModal: true,
                title: 'Confirmation',
                body: 'Are you sure you want to cancel invite?',
                confirmText: 'OK',
                cancelText: 'Cancel',
            },
            isShowSuccessMsg: false,
        });
    };

    handleConfirm = () => {
        if (this.state.isShowSuccessMsg) {
            this.setState(
                {
                    userInvitation: { organizationRoles: [] },
                    dialog: { isShowingModal: false },
                },
                this.displayDefaultSettings
            );
            return;
        }
        this.setState({ redirect: true });
    };

    handleClose = () => {
        if (this.state.isShowSuccessMsg) {
            this.setState({ redirect: true });
            return;
        }
        this.setState({ dialog: { isShowingModal: false } });
    };

    saveUserOrganization = (organizationRole: IOrganizationRoleModel) => {
        let userInvitation = this.state.userInvitation;
        if (!userInvitation.organizationRoles)
            userInvitation.organizationRoles = [organizationRole];
        else {
            let match = userInvitation.organizationRoles.find(
                (x) => x.guid === organizationRole.guid
            );
            if (match) match = organizationRole;
            else userInvitation.organizationRoles.push(organizationRole);
        }

        this.setState({ userInvitation: userInvitation, openOrganizationRolesModal: false });
    };

    removeSelectedOrganizationRole = (
        event: React.FormEvent<HTMLButtonElement>,
        organizationRole: IOrganizationRoleModel
    ) => {
        let userInvitation = this.state.userInvitation;
        if (!userInvitation.organizationRoles) return;

        let matchIndex = userInvitation.organizationRoles.findIndex(
            (x) => x.guid === organizationRole.guid
        );
        if (matchIndex >= 0) userInvitation.organizationRoles.splice(matchIndex, 1);

        this.setState({ userInvitation: userInvitation });
    };

    render() {
        if (this.state.redirect) {
            return <Navigate to="/" />;
        }
        return (
            <div className="modal-container">
                <div>
                    <div className="row mb-3">
                        <h1 className="col-sm-6">User Invitation</h1>
                        <div className="col-sm-6">
                            <input
                                type="button"
                                className="btn btn-default float-end"
                                onClick={this.cancelInvite}
                                value="Cancel"
                            />
                            <input
                                type="button"
                                className="btn btn-orange float-end"
                                onClick={this.sendInvite}
                                value="Send Invite"
                            />
                        </div>
                    </div>
                    <span className="text-danger">{this.state.validation.model}</span>
                    <div className="row mb-2">
                        <label className="control-label col-sm-2" htmlFor="firstName">
                            First Name*
                        </label>
                        <div className="col-sm-10">
                            <input
                                className="form-control"
                                type="text"
                                name="firstName"
                                value={this.state.userInvitation.firstName || ''}
                                onChange={this.changeValue}
                            />
                            <span className="text-danger">{this.state.validation.firstname}</span>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="control-label col-sm-2" htmlFor="lastName">
                            Last Name*
                        </label>
                        <div className="col-sm-10">
                            <input
                                className="form-control"
                                type="text"
                                name="lastName"
                                value={this.state.userInvitation.lastName || ''}
                                onChange={this.changeValue}
                            />
                            <span className="text-danger">{this.state.validation.lastname}</span>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="control-label col-sm-2" htmlFor="email">
                            Email Address*
                        </label>
                        <div className="col-sm-10">
                            <input
                                className="form-control"
                                type="email"
                                name="email"
                                value={this.state.userInvitation.email || ''}
                                onChange={this.changeValue}
                            />
                            <span className="text-danger">{this.state.validation.email}</span>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="control-label col-sm-2" htmlFor="homeOrganization">
                            Organization*
                        </label>
                        <div className="col-sm-10">
                            <CheckmateSelect
                                options={CheckmateSelectHelper.getOrgOptions(
                                    this.state.organizationsList.filter(
                                        (x) =>
                                            x.organizationType &&
                                            x.organizationType.id !==
                                                OrganizationTypesEnum.Portfolio.Value
                                    )
                                )}
                                isDisabled={
                                    Auth.isSystemAdmin(this.props.user) ||
                                    Auth.isMasterAdmin(this.props.user)
                                        ? false
                                        : true
                                }
                                name="homeOrganization"
                                value={CheckmateSelectHelper.getSelectedOrgValue(
                                    this.state.organizationsList,
                                    this.state.userInvitation.homeOrganization
                                        ? this.state.userInvitation.homeOrganization.guid || ''
                                        : ''
                                )}
                                onChange={(e: any) => {
                                    this.handleCheckmateSelectChange(e, 'homeOrganization');
                                }}
                            />
                        </div>
                        <span className="text-danger">
                            {this.state.validation.homeOrganization}
                        </span>
                    </div>
                </div>

                {this.state.userInvitation.homeOrganization ? (
                    <div className="row mb-2">
                        <div className="col-sm-2">
                            <label className="control-label" htmlFor="phones">
                                Zone Access
                            </label>
                            <button
                                className="btn btn-default btn-icon ms-2"
                                onClick={() => this.setState({ openOrganizationRolesModal: true })}
                            >
                                <i className="fal fa-lg fa-plus" />
                            </button>
                        </div>
                        <div>
                            {this.state.userInvitation &&
                            this.state.userInvitation.organizationRoles &&
                            this.state.userInvitation.organizationRoles.length > 0 ? (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Role</th>
                                            <th>Data Scope</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.userInvitation.organizationRoles.map(
                                            (organizationRole: IOrganizationRoleModel) => {
                                                return (
                                                    <tr key={organizationRole.guid}>
                                                        <td>{organizationRole.displayName}</td>
                                                        <td>
                                                            {organizationRole.role
                                                                ? organizationRole.role.displayName
                                                                : ''}
                                                        </td>
                                                        <td>
                                                            {organizationRole.dataScope
                                                                ? organizationRole.dataScope
                                                                      .displayName
                                                                : ''}
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn btn-no-bg float-end"
                                                                onClick={(
                                                                    e: React.MouseEvent<HTMLButtonElement>
                                                                ) => {
                                                                    this.removeSelectedOrganizationRole(
                                                                        e,
                                                                        organizationRole
                                                                    );
                                                                }}
                                                            >
                                                                <i className="fal fa-lg fa-trash-alt"></i>
                                                            </button>
                                                            <button
                                                                className="btn btn-no-bg float-end"
                                                                onClick={(
                                                                    e: React.MouseEvent<HTMLButtonElement>
                                                                ) => {
                                                                    this.setState({
                                                                        currentOrganizationRole:
                                                                            organizationRole,
                                                                        openOrganizationRolesModal:
                                                                            true,
                                                                    });
                                                                }}
                                                            >
                                                                <i className="fal fa-lg fa-edit" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            ) : null}
                        </div>
                    </div>
                ) : null}

                <OrganizationRole
                    open={this.state.openOrganizationRolesModal || false}
                    zones={this.state.zones}
                    handleSave={this.saveUserOrganization}
                    handleCancel={() => this.setState({ openOrganizationRolesModal: false })}
                    homeOrganization={this.state.userInvitation.homeOrganization || {}}
                />

                <CheckmateDialog
                    isShowingModal={this.state.dialog.isShowingModal}
                    body={this.state.dialog.body}
                    handleClose={this.handleClose}
                    handleConfirm={this.handleConfirm}
                    confirmText={this.state.dialog.confirmText}
                    cancelText={this.state.dialog.cancelText}
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="send-invite-confirmation-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </div>
        );
    }
}
