import * as React from 'react';

import {
    DataScopesEnum,
    EntityTypes,
    LookupDataEnums,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    ReportTypesEnum,
    StrategyTypes,
    TaskActivityTypesEnum,
    TaskTypesEnum,
    UserRightsEnum,
} from '../../utilities/Constants';
import { INoteCategoryTypeModel, INoteModel } from '../../interfaces/INote';

import ApiClient from '../../services/apiClient';
import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import Common from '../../stores/Common';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import EntityAssertionControl from '../../components/shared/EntityAssertionControl';
import { ExportFile } from '../../components/shared/ExportFile';
import { ICheckmateTaskModel } from '../../interfaces/ICase';
import { IEntityAssertionModel } from '../../interfaces/IAssertionDescriptor';
import { ILookupModel } from '../../interfaces/ILookup';
import { IProjectModel } from '../../interfaces/IStrategy';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import { NoteEditor } from '../../components/notes/NoteEditor';
import NoteHelper from '../../utilities/NoteHelper';
import { NoteList } from '../../components/notes/NoteList';
import Sort from '../../stores/Sort';
import { TaskList } from '../../components/tasks/TaskList';
import { TextWithLineBreaks } from '../../components/shared/TextWithLineBreaks';
import ValidateUtils from '../../shared/validations';
import { WorkProductWrapper } from '../../components/work-product/WorkProductWrapper';
import { cloneDeep } from 'lodash';

const _apiClient = new ApiClient();

interface IProjectProps {
    user: IUserModel;
    guid?: string;
}

interface IProjectState {
    pendingResponse: boolean;
    readOnly: boolean;
    validation: IValidation;
    projectDetails?: IProjectModel;
    originalProjectDetails?: IProjectModel;
    projectTypes: ILookupModel[];
    projectStatusTypes: ILookupModel[];
    currentNarrativeCaseAssertionOnEditMode?: IEntityAssertionModel;
    ownerList: IUserModel[];
    noteCategoryTypes: INoteCategoryTypeModel[];
    currentNote: INoteModel;
    openNoteEditor: boolean;
    loggedInUserDataScopeId: number;
}

export class ProjectDetail extends React.Component<IProjectProps, IProjectState> {
    constructor(props: IProjectProps) {
        super(props);

        this.state = {
            pendingResponse: true,
            readOnly: true,
            validation: {},
            projectTypes: [],
            projectStatusTypes: [],
            ownerList: [],
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            noteCategoryTypes: [],
            openNoteEditor: false,
            loggedInUserDataScopeId: 0,
        };
    }

    componentDidMount() {
        if (
            !Authorization.isAuthorizedToStrategyType(
                StrategyTypes.Jurisdiction.Name,
                this.props.user
            )
        )
            window.location.assign(LocalRoutes.AccessDenied);

        this.loadInitialLookupData();
        const id = this.props.guid;
        if (id) this.loadProjectDetails(id);
    }

    loadProjectDetails = async (id: string) => {
        const response = await _apiClient.getProjectDetails(id);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (response.payload) {
            this.setState({
                projectDetails: response.payload,
                originalProjectDetails: cloneDeep(response.payload),
                pendingResponse: false,
                readOnly: true,
                validation: {},
            });
        }
    };

    loadInitialLookupData = async () => {
        this.setState({ pendingResponse: true });
        const projectTypesResponse = await _apiClient.getLookupData(LookupDataEnums.ProjectTypes);
        if (projectTypesResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (projectTypesResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    projectTypesResponse.errors,
                    projectTypesResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        const projectStatusTypesResponse = await _apiClient.getLookupData(
            LookupDataEnums.PackageSettlementStatusTypes
        );
        if (projectStatusTypesResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (projectStatusTypesResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    projectStatusTypesResponse.errors,
                    projectStatusTypesResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        const usersResponse = await _apiClient.getCaseManagersInZone();
        if (usersResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (usersResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    usersResponse.errors,
                    usersResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        const noteCategoryTypesResponse = await _apiClient.getStrategyNoteCategoryTypes();
        if (noteCategoryTypesResponse.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (noteCategoryTypesResponse.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(
                    noteCategoryTypesResponse.errors,
                    noteCategoryTypesResponse.errorMessage
                ),
                pendingResponse: false,
            });
            return;
        }

        const loggedInUserDataScopeId = Authorization.getUserDefaultOrganizationDataScope(
            this.props.user
        );

        this.setState({
            projectTypes: projectTypesResponse.payload!,
            projectStatusTypes: projectStatusTypesResponse.payload!,
            ownerList: usersResponse.payload!,
            noteCategoryTypes: noteCategoryTypesResponse.payload!,
            pendingResponse: false,
            loggedInUserDataScopeId: loggedInUserDataScopeId,
        });
    };

    finalizeChange = (modifiedAssertions: IEntityAssertionModel[]) => {
        if (
            this.state.projectDetails &&
            modifiedAssertions.length == 1 &&
            modifiedAssertions[0].assertionDescriptor
        ) {
            const projectDetails = this.state.projectDetails;

            if (!modifiedAssertions[0].assertionDescriptor.guid) {
                switch (modifiedAssertions[0].assertionDescriptor.name) {
                    case 'situation':
                        projectDetails.situation = modifiedAssertions[0].textValue;
                        break;
                    case 'typeDescription':
                        projectDetails.typeDescription = modifiedAssertions[0].textValue;
                        break;
                    default:
                }
            } else {
                const assertionMatch = projectDetails.assertions.filter(
                    (x) =>
                        x.assertionDescriptor &&
                        x.assertionDescriptor.guid &&
                        x.assertionDescriptor.guid.toUpperCase() ===
                            modifiedAssertions[0].assertionDescriptor!.guid!.toUpperCase()
                );
                if (assertionMatch.length > 0) {
                    assertionMatch[0].textValue = modifiedAssertions[0].textValue;
                    assertionMatch[0].booleanValue = modifiedAssertions[0].booleanValue;
                    assertionMatch[0].numericValue = modifiedAssertions[0].numericValue;
                    assertionMatch[0].dateTimeValue = modifiedAssertions[0].dateTimeValue;
                    assertionMatch[0].assertionDescriptorValue =
                        modifiedAssertions[0].assertionDescriptorValue;
                } else {
                    projectDetails.assertions.push({
                        entityType: { id: EntityTypes.Project },
                        assertionDescriptor: JSON.parse(
                            JSON.stringify(modifiedAssertions[0].assertionDescriptor)
                        ),
                        textValue: modifiedAssertions[0].textValue,
                        booleanValue: modifiedAssertions[0].booleanValue,
                        numericValue: modifiedAssertions[0].numericValue,
                        dateTimeValue: modifiedAssertions[0].dateTimeValue,
                        assertionDescriptorValue: modifiedAssertions[0].assertionDescriptorValue,
                    });
                }
            }

            this.setState({
                projectDetails: projectDetails,
                currentNarrativeCaseAssertionOnEditMode: undefined,
            });
        }
    };

    handleNarrativeFieldEdit = (item: IEntityAssertionModel) => {
        if (
            this.state.projectDetails &&
            item.assertionDescriptor &&
            item.assertionDescriptor.guid
        ) {
            const projectDetails = this.state.projectDetails;
            const assertionMatch = projectDetails.assertions.filter(
                (x) =>
                    x.assertionDescriptor &&
                    x.assertionDescriptor.guid &&
                    x.assertionDescriptor.guid.toUpperCase() ===
                        item.assertionDescriptor!.guid!.toUpperCase()
            );
            if (assertionMatch.length > 0)
                this.setState({
                    currentNarrativeCaseAssertionOnEditMode: JSON.parse(
                        JSON.stringify(assertionMatch[0])
                    ),
                });
        }
    };

    handleClearNarrativeFieldEdit = () => {
        if (this.state.currentNarrativeCaseAssertionOnEditMode) {
            const currentNarrativeCaseAssertionOnEditMode =
                this.state.currentNarrativeCaseAssertionOnEditMode;
            currentNarrativeCaseAssertionOnEditMode.textValue = '';
            this.setState({
                currentNarrativeCaseAssertionOnEditMode: currentNarrativeCaseAssertionOnEditMode,
            });
        }
    };

    handleNarrativeFieldTextChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (this.state.currentNarrativeCaseAssertionOnEditMode) {
            const currentNarrativeCaseAssertionOnEditMode =
                this.state.currentNarrativeCaseAssertionOnEditMode;
            currentNarrativeCaseAssertionOnEditMode.textValue = event.target.value;
            this.setState({
                currentNarrativeCaseAssertionOnEditMode: currentNarrativeCaseAssertionOnEditMode,
            });
        }
    };

    save = async () => {
        const validation: IValidation = {};
        if (!this.state.projectDetails) {
            validation.model = [DisplayMessages.UnexpectedError];
            this.setState({ validation: validation });
            return;
        }

        let hasErrors = false;
        if (!this.state.projectDetails.name) {
            validation.name = ['*Required'];
            hasErrors = true;
        }

        if (!this.state.projectDetails.type || this.state.projectDetails.type.id == 0) {
            validation.type = ['*Required'];
            hasErrors = true;
        }

        if (!this.state.projectDetails.status || this.state.projectDetails.status.id == 0) {
            validation.status = ['*Required'];
            hasErrors = true;
        }

        if (hasErrors) {
            this.setState({ validation: validation });
            return;
        }

        this.setState({ pendingResponse: true });

        const response = await _apiClient.updateProject(
            this.state.projectDetails!.guid!,
            this.state.projectDetails
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.loadProjectDetails(this.state.projectDetails!.guid!);
    };

    refreshTasks = (tasks: ICheckmateTaskModel[]) => {
        if (this.state.projectDetails) {
            const projectDetails = this.state.projectDetails;
            projectDetails.tasks = JSON.parse(JSON.stringify(tasks));
            this.setState({ projectDetails: projectDetails });
        }
    };

    handleCheckedChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!this.state.projectDetails) return;

        const projectDetails = this.state.projectDetails;
        switch (event.target.name) {
            case 'isOngoing':
                projectDetails.isOngoing = event.target.checked ? true : undefined;
                break;
            default:
                break;
        }

        this.setState({ projectDetails: projectDetails });
    };

    handleCheckmateSelectChange = (selectedItem: any, name: string) => {
        if (!this.state.projectDetails) return;

        const projectDetails = this.state.projectDetails;
        const validation: IValidation = this.state.validation;
        const value = selectedItem ? selectedItem.id : 0;
        switch (name) {
            case 'type':
                if (value) {
                    const typeMatch = this.state.projectTypes.find((x) => x.id === parseInt(value));
                    if (typeMatch) {
                        validation.type = [''];
                        projectDetails.type = JSON.parse(JSON.stringify(typeMatch));
                    } else {
                        validation.type = [' Invalid'];
                    }
                } else {
                    projectDetails.type = undefined;
                    validation.type = ['*Required'];
                }
                break;
            case 'status':
                if (value) {
                    const statusMatch = this.state.projectStatusTypes.find(
                        (x) => x.id === parseInt(value)
                    );
                    if (statusMatch) {
                        validation.status = [''];
                        projectDetails.status = JSON.parse(JSON.stringify(statusMatch));
                    } else {
                        validation.status = [' Invalid'];
                    }
                } else {
                    projectDetails.status = undefined;
                    validation.status = ['*Required'];
                }
                break;
            default:
                break;
        }

        this.setState({ projectDetails: projectDetails, validation: validation });
    };

    handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (!this.state.projectDetails) return;

        const projectDetails = this.state.projectDetails;
        const validation: IValidation = this.state.validation;
        switch (event.target.name) {
            case 'name':
                projectDetails.name = event.target.value;
                validation.name = event.target.value ? [''] : ['*Required'];
                break;
            case 'targetDate':
                projectDetails.targetDate = event.target.value;
                validation.targetDate = [''];
                break;
            case 'situation':
                projectDetails.situation = event.target.value;
                validation.situation = [''];
                break;
            case 'typeDescription':
                projectDetails.typeDescription = event.target.value;
                validation.typeDescription = [''];
                break;
            default:
                break;
        }

        this.setState({ projectDetails: projectDetails, validation: validation });
    };

    handleExportError = () => {
        const validation = this.state.validation;
        validation.model = [DisplayMessages.UnexpectedError];
        this.setState({ pendingResponse: false, validation: validation });
    };

    handleCancelNoteEditor = () => {
        this.setState({
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            openNoteEditor: false,
        });
    };

    onSaveNoteComplete = (noteItem: INoteModel) => {
        let matchFound = false;
        const projectDetails = this.state.projectDetails;
        for (let i = 0; i < projectDetails!.notes.length; i++) {
            if (projectDetails!.notes[i].guid == noteItem.guid) {
                matchFound = true;
                projectDetails!.notes[i] = JSON.parse(JSON.stringify(noteItem));
                break;
            }
        }

        if (!matchFound) projectDetails!.notes.push(JSON.parse(JSON.stringify(noteItem)));
        projectDetails!.notes = projectDetails!.notes.sort(
            Sort.compareDate('modifiedDate', undefined, 'desc')
        );
        this.setState({ projectDetails: projectDetails, openNoteEditor: false });
    };

    handleEditNote = (noteGuidToEdit: string) => {
        const validation: IValidation = {};

        const currentNote = this.state.projectDetails!.notes.find((x) => x.guid == noteGuidToEdit);
        if (!currentNote) {
            validation.model = [DisplayMessages.UnexpectedError];
            this.setState({ pendingResponse: false, validation: validation });
            return;
        }

        this.setState({
            currentNote: JSON.parse(JSON.stringify(currentNote)),
            openNoteEditor: true,
        });
    };

    refreshNotes = (notes: INoteModel[]) => {
        const projectDetails = this.state.projectDetails;
        if (projectDetails) {
            projectDetails.notes = notes;
            this.setState({ projectDetails: projectDetails });
        }
    };

    onClickAddNote = () => {
        const currentNote: INoteModel = {
            type: { id: NoteTypes.StrategyNote },
            status: { id: NoteStatusTypes.Open },
            parent: {
                entityType: { id: EntityTypes.Project },
                entityGuid: this.state.projectDetails!.guid,
            },
            purpose: { id: NotePurposeTypesEnum.Info },
        };

        this.setState({ openNoteEditor: true, currentNote: currentNote });
    };

    render() {
        if (this.state.pendingResponse || !this.state.projectDetails) return <Loader />;

        if (this.state.openNoteEditor)
            return (
                <NoteEditor
                    readOnly={NoteHelper.isNoteReadOnly(
                        this.state.currentNote,
                        this.props.user,
                        Authorization.userHasRight(
                            UserRightsEnum.ViewProjectsStrategyNote,
                            this.props.user
                        ),
                        Authorization.userHasRight(
                            UserRightsEnum.UpdateProjectsStrategyNote,
                            this.props.user
                        ),
                        Authorization.userHasRight(
                            UserRightsEnum.AddProjectsStrategyNote,
                            this.props.user
                        )
                    )}
                    strategyTypeId={StrategyTypes.Projects.Value}
                    user={this.props.user}
                    originEntityName={this.state.projectDetails.name}
                    originEntityKey={this.state.projectDetails.guid}
                    originEntityType={StrategyTypes.Projects.Value}
                    noteCategoryTypes={this.state.noteCategoryTypes}
                    currentNote={this.state.currentNote}
                    handleSaveComplete={this.onSaveNoteComplete}
                    handleCancel={this.handleCancelNoteEditor}
                />
            );

        return (
            <div>
                <h1 className="mb-3">
                    {this.state.projectDetails!.name || '    '}
                    {this.state.readOnly ? (
                        <span className="font-size-xs ms-2">
                            {(this.state.projectDetails.type &&
                            this.state.projectTypes.filter(
                                (x) => x.id === this.state.projectDetails!.type!.id!
                            ).length > 0
                                ? this.state.projectTypes.filter(
                                      (x) => x.id === this.state.projectDetails!.type!.id!
                                  )[0].displayName!
                                : '') +
                                ' | ' +
                                (this.state.projectDetails.targetDate
                                    ? Common.dateFormat(this.state.projectDetails.targetDate)
                                    : ' Ongoing') +
                                ' | ' +
                                (this.state.projectDetails.status &&
                                this.state.projectStatusTypes.filter(
                                    (x) => x.id === this.state.projectDetails!.status!.id!
                                ).length > 0
                                    ? this.state.projectStatusTypes.filter(
                                          (x) => x.id === this.state.projectDetails!.status!.id!
                                      )[0].displayName!
                                    : '')}
                        </span>
                    ) : null}
                    {Authorization.userHasRight(
                        UserRightsEnum.AddEditDeleteProject,
                        this.props.user
                    ) ? (
                        <span>
                            {this.state.readOnly ? (
                                <span>
                                    <ExportFile
                                        url={'/' + ApiRoutes.ExportReport}
                                        reportType={{
                                            id: ReportTypesEnum.ProjectDetailExport.Value,
                                            availableReportOptions: { includeTasks: true },
                                        }}
                                        reportParameters={{
                                            reportType: {
                                                id: ReportTypesEnum.ProjectDetailExport.Value,
                                            },
                                            options: { key: this.state.projectDetails.guid },
                                        }}
                                        handleError={this.handleExportError}
                                        text="PDF"
                                        icon="fa-file-pdf"
                                    />
                                    <button
                                        className="btn btn-no-bg float-end text-gray "
                                        onClick={() => this.setState({ readOnly: false })}
                                    >
                                        <i className="fal fa-edit" />
                                        &nbsp;Edit
                                    </button>
                                    <a
                                        className="btn btn-no-bg float-end text-gray  "
                                        href={window.location.pathname + '#Notes'}
                                    >
                                        <i className="fal fa-memo" />
                                        &nbsp;Strategy Notes
                                    </a>
                                </span>
                            ) : (
                                <span>
                                    <button
                                        className="btn btn-default float-end"
                                        onClick={() =>
                                            this.setState({
                                                projectDetails: this.state.originalProjectDetails,
                                                readOnly: true,
                                                validation: {},
                                            })
                                        }
                                    >
                                        Cancel
                                    </button>
                                    <input
                                        type="button"
                                        className="btn btn-orange float-end "
                                        onClick={this.save}
                                        value="Save"
                                    />
                                </span>
                            )}
                        </span>
                    ) : null}
                </h1>
                <span className="text-danger">{this.state.validation.model}</span>
                {this.state.readOnly ? null : (
                    <div className="row mb-2">
                        <div className="col-sm-3">
                            <label className="text-gray">Title*</label>
                        </div>
                        <div className="col-sm-5 ">
                            <input
                                className="form-control"
                                type="text"
                                name="name"
                                value={this.state.projectDetails.name}
                                maxLength={100}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="text-danger col-sm-4">{this.state.validation.name}</div>
                    </div>
                )}

                {this.state.readOnly ? null : (
                    <div className="row mb-2">
                        <div className="col-sm-3">
                            <label className="text-gray">Risk or Opportunity*</label>
                        </div>
                        <div className="col-sm-2 ">
                            <CheckmateSelect
                                name="type"
                                onChange={(e: any) => {
                                    this.handleCheckmateSelectChange(e, 'type');
                                }}
                                value={CheckmateSelectHelper.getSelectedValueById(
                                    this.state.projectTypes,
                                    this.state.projectDetails.type
                                        ? this.state.projectDetails.type.id
                                        : 0
                                )}
                                options={CheckmateSelectHelper.getLookupOptions(
                                    this.state.projectTypes
                                )}
                            />
                        </div>
                        <div className="text-danger col-sm-4">{this.state.validation.type}</div>
                    </div>
                )}

                {this.state.readOnly ? null : (
                    <div className="row mb-2">
                        <div className="col-sm-3">
                            <label className="text-gray">Target Date</label>
                        </div>
                        <div className="col-sm-8 ">
                            <input
                                type="date"
                                name="targetDate"
                                className={
                                    this.state.projectDetails.targetDate &&
                                    Common.isValidDate(this.state.projectDetails.targetDate)
                                        ? 'form-control'
                                        : 'form-control unselectClass'
                                }
                                style={{ display: 'inline-block' }}
                                value={this.state.projectDetails.targetDate}
                                onChange={this.handleChange}
                            />
                            <input
                                type="checkbox"
                                className="form-check-input ms-2 me-1 d-inline-block"
                                style={{ marginTop: '10px' }}
                                name="isOngoing"
                                checked={this.state.projectDetails.isOngoing}
                                onChange={this.handleCheckedChanged}
                            />
                            &nbsp;Ongoing
                        </div>
                    </div>
                )}

                {!this.state.readOnly ? (
                    <div className="row mb-2">
                        <div className="col-sm-3">
                            <label className="text-gray">Status*</label>
                        </div>
                        <div className="col-sm-2 ">
                            <CheckmateSelect
                                name="status"
                                onChange={(e: any) => {
                                    this.handleCheckmateSelectChange(e, 'status');
                                }}
                                value={CheckmateSelectHelper.getSelectedValueById(
                                    this.state.projectStatusTypes,
                                    this.state.projectDetails.status
                                        ? this.state.projectDetails.status.id
                                        : 0
                                )}
                                options={CheckmateSelectHelper.getLookupOptions(
                                    this.state.projectStatusTypes
                                )}
                            />
                        </div>
                        <div className="text-danger col-sm-4">{this.state.validation.status}</div>
                    </div>
                ) : null}

                <div className="row mb-2">
                    <div className="col-sm-3">
                        <label className="text-gray">Situation</label>
                    </div>
                    <div className="col-sm-9 ">
                        {this.state.readOnly ? (
                            <TextWithLineBreaks text={this.state.projectDetails.situation || ' '} />
                        ) : (
                            <button
                                className="btn btn-no-bg"
                                onClick={() =>
                                    this.setState({
                                        currentNarrativeCaseAssertionOnEditMode: {
                                            assertionDescriptor: {
                                                name: 'situation',
                                                displayName: 'Situation',
                                            },
                                            textValue: this.state.projectDetails!.situation,
                                            entityType: { id: 0 },
                                        },
                                    })
                                }
                                title={this.state.projectDetails.situation}
                            >
                                <i
                                    className={
                                        this.state.projectDetails.situation
                                            ? 'fal fa-edit color-black'
                                            : 'fal fa-edit color-gray'
                                    }
                                />
                            </button>
                        )}
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-sm-3">
                        <label className="text-gray">Risks/Opportunities</label>
                    </div>
                    <div className="col-sm-9 ">
                        {this.state.readOnly ? (
                            <TextWithLineBreaks
                                text={this.state.projectDetails.typeDescription || ' '}
                            />
                        ) : (
                            <button
                                className="btn btn-no-bg"
                                onClick={() =>
                                    this.setState({
                                        currentNarrativeCaseAssertionOnEditMode: {
                                            assertionDescriptor: {
                                                name: 'typeDescription',
                                                displayName: 'Risks/Opportunities',
                                            },
                                            textValue: this.state.projectDetails!.typeDescription,
                                            entityType: { id: 0 },
                                        },
                                    })
                                }
                                title={this.state.projectDetails.typeDescription}
                            >
                                <i
                                    className={
                                        this.state.projectDetails.typeDescription
                                            ? 'fal fa-edit color-black'
                                            : 'fal fa-edit color-gray'
                                    }
                                />
                            </button>
                        )}
                    </div>
                </div>

                {this.state.projectDetails.assertions
                    ? this.state.projectDetails.assertions.map((item: IEntityAssertionModel) => {
                          return (
                              <div className="row mb-2">
                                  <div className="col-sm-3 ">
                                      <label className="text-gray">
                                          {item.assertionDescriptor!.displayName!}
                                      </label>
                                  </div>
                                  <div className="col-sm-9 ">
                                      <EntityAssertionControl
                                          assertionDescriptor={item.assertionDescriptor!}
                                          entityAssertions={[item]}
                                          editMode={!this.state.readOnly}
                                          refreshChange={this.finalizeChange}
                                          handleNarrativeFieldEdit={this.handleNarrativeFieldEdit}
                                          narrativeFieldAsPopup={true}
                                      />
                                  </div>
                              </div>
                          );
                      })
                    : null}

                <TaskList
                    user={this.props.user}
                    taskType={TaskTypesEnum.StrategyTactics}
                    parentEntityGuid={this.state.projectDetails!.guid!}
                    parentEntityType={{ id: EntityTypes.Project }}
                    defaultTaskActivityTypeId={TaskActivityTypesEnum.AnalysisStrategy}
                    ownerList={this.state.ownerList}
                    readOnly={this.state.readOnly}
                    refreshParent={this.refreshTasks}
                    title="Tactics"
                    tasks={this.state.projectDetails.tasks}
                />

                {this.state.readOnly && (
                    <div className="row" id="AttachmentsDiv">
                        <WorkProductWrapper
                            uploadOnly={true}
                            user={this.props.user}
                            entityGuid={this.state.projectDetails.guid!}
                            entityTypeId={EntityTypes.Project}
                            strategyTypeId={StrategyTypes.Projects.Value}
                            title="Attachments"
                            documents={this.state.projectDetails.documents}
                            parentEntityJson={JSON.stringify(this.state.projectDetails)}
                            parentEntityRedirectUrl={LocalRoutes.ProjectDetail.replace(
                                ':guid',
                                this.state.projectDetails.guid!
                            )}
                            authorizedToEdit={Authorization.userHasRight(
                                UserRightsEnum.AddEditDeleteProject,
                                this.props.user
                            )}
                        />
                    </div>
                )}

                {this.state.readOnly ? (
                    <div className="row" id="Notes">
                        {/*No explicit Strategy Note Right. User has access to Strategy Notes if they have access to the corresponding Strategy Page*/}
                        <NoteList
                            title="Strategy Notes"
                            titleClassName="text-gray font-size-sm"
                            user={this.props.user}
                            notes={this.state.projectDetails.notes}
                            noteCategoryTypes={this.state.noteCategoryTypes}
                            handleAddNote={this.onClickAddNote}
                            handleViewEditNote={this.handleEditNote}
                            refreshParent={this.refreshNotes}
                            allowView={Authorization.userHasRight(
                                UserRightsEnum.ViewProjectsStrategyNote,
                                this.props.user
                            )}
                            allowEdit={
                                Authorization.userHasRight(
                                    UserRightsEnum.UpdateProjectsStrategyNote,
                                    this.props.user
                                ) &&
                                (this.state.loggedInUserDataScopeId !==
                                    DataScopesEnum.LocalBasic.Value ||
                                    this.state.currentNote.allowLocalEdit == true)
                            }
                            allowDelete={Authorization.userHasRight(
                                UserRightsEnum.DeleteProjectsStrategyNote,
                                this.props.user
                            )}
                            allowAddNew={Authorization.userHasRight(
                                UserRightsEnum.AddProjectsStrategyNote,
                                this.props.user
                            )}
                        />
                    </div>
                ) : null}

                {this.state.currentNarrativeCaseAssertionOnEditMode ? (
                    <Modal
                        centered
                        show={true}
                        onHide={() =>
                            this.setState({ currentNarrativeCaseAssertionOnEditMode: undefined })
                        }
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>
                                {
                                    this.state.currentNarrativeCaseAssertionOnEditMode
                                        .assertionDescriptor!.displayName
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                            <div>
                                <textarea
                                    className="form-control"
                                    value={
                                        this.state.currentNarrativeCaseAssertionOnEditMode.textValue
                                    }
                                    name={
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            .assertionDescriptor!.guid
                                    }
                                    rows={10}
                                    onChange={this.handleNarrativeFieldTextChange}
                                />
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={() =>
                                        this.state.currentNarrativeCaseAssertionOnEditMode
                                            ? this.finalizeChange([
                                                  this.state
                                                      .currentNarrativeCaseAssertionOnEditMode,
                                              ])
                                            : {}
                                    }
                                >
                                    OK
                                </button>
                                <button
                                    className="btn btn-default float-end "
                                    onClick={() =>
                                        this.setState({
                                            currentNarrativeCaseAssertionOnEditMode: undefined,
                                        })
                                    }
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.handleClearNarrativeFieldEdit}
                                >
                                    Clear
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}
            </div>
        );
    }
}
