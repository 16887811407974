import { AuthLayout } from './AuthLayout';
import { HubConnection } from '@microsoft/signalr';
import { Loader } from './shared/Loader';
import { useLoading } from '../contexts/LoadingContext';
import { useLocation } from 'react-router-dom';

interface IAuthLayoutWrapperProps {
    alertHubConnection: HubConnection;
}

export default function AuthLayoutWrapper({ alertHubConnection }: IAuthLayoutWrapperProps) {
    const { key } = useLocation();
    const { isLoading } = useLoading();

    const location: { state: any } = useLocation();
    const isAuthenticated: boolean = location.state?.isAuthenticated ?? false;

    return (
        <>
            <AuthLayout
                isAuthenticated={isAuthenticated}
                alertHubConnection={alertHubConnection}
                locationKey={key}
            />
            {isLoading && <Loader />}
        </>
    );
}
