import Select, { ActionMeta, GroupBase, GroupProps } from 'react-select';

import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import { IMultiSelectOptions } from '../../interfaces/ILookup';
import { groupBy } from 'lodash';

interface IMenuGroup {
    label: JSX.Element;
    options: IMultiSelectOptions[];
}

export interface IGroupableOption extends IMultiSelectOptions {
    groupLabel: string;
}

function CheckmateSelectGrouped(props: {
    options: IGroupableOption[];
    value?: any;
    onChange: (optionsList: any) => void;
    name?: string;
    isLoading?: boolean;
    isMulti?: boolean;
    placeholder?: string;
    isDisabled?: boolean;
    systemPopulated?: boolean;
    notClearable?: boolean;
    tabIndex?: number;
    groupComponent?: React.ComponentType<GroupProps<IMenuGroup, boolean, GroupBase<IMenuGroup>>>;
}) {
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            borderRadius: 0,
            height: props.isMulti ? null : 34.4,
            minHeight: props.isMulti ? null : 34.4,
            borderColor: 'var(--gray-color)',
            boxShadow: 'none',
            '&:hover, &:focus-within': {
                border: 'solid 1px var(--blue-color)',
                boxShadow: '0 0 0 1px var(--blue-color)',
            },
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            height: props.isMulti ? null : 34.4,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        input: (provided: any) => ({
            ...provided,
            margin: 0,
            height: props.isMulti ? null : 34.4,
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            height: props.isMulti ? null : 34.4,
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#C7C8CA',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: props.systemPopulated ? '#4ABDAC' : 'black',
        }),
        multiValueLabel: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: '#ffffff',
            fontSize: '100%',
            fontWeight: state.data.isFixed ? 'bold' : 'inherit',
        }),
        multiValueRemove: (base: any, state: any) => {
            return state.data.isFixed ? { ...base, display: 'none' } : base;
        },
        option: CheckmateSelectHelper.getGroupedSelectOptionStyles,
    };

    const groupedOptions = groupBy(props.options ?? [], 'groupLabel');

    const createGroup = (groupName: string, options: IMultiSelectOptions[]) => {
        return {
            label: (() => {
                return <div style={{ fontSize: '1.2em' }}>{groupName}</div>;
            })(),
            options: options,
        };
    };

    const options = Object.entries(groupedOptions).map(([key, value]) => createGroup(key, value));

    const handleChange = (newValue: any, actionMeta: ActionMeta<any>) => {
        switch (actionMeta.action) {
            case 'remove-value':
            case 'pop-value':
                if (actionMeta.removedValue?.isFixed) {
                    return;
                }
                break;
            case 'clear':
                if (Array.isArray(props.value)) {
                    newValue = props.value
                        ?.filter((v) => v !== undefined && v !== null)
                        .filter((v) => v.isFixed);
                }
                break;
        }

        props.onChange(newValue);
    };

    return (
        <div style={{ minWidth: '125px' }}>
            <Select
                closeMenuOnSelect={props.isMulti ? false : true}
                hideSelectedOptions={false}
                isClearable={!props.notClearable}
                isDisabled={props.isDisabled}
                isLoading={props.isLoading}
                isMulti={props.isMulti ? true : undefined}
                loadingMessage={() => 'Loading...'}
                onChange={handleChange}
                options={options}
                placeholder={props.placeholder || '-- Select --'}
                styles={customStyles}
                tabIndex={props.tabIndex}
                value={props.value ?? null}
            />
        </div>
    );
}

export default CheckmateSelectGrouped;
