import { ICaseModel, IDepositionModel } from '../../../interfaces/ICase';

import { CaseDepositionList } from './CaseDepositionList';
import { IAssociatedOrganizationModel } from '../../../interfaces/IOrganization';
import { IUserModel } from '../../../interfaces/IUser';
import { useGetDepositionCategories } from '../../../shared/react-query-hooks/useGetDepositionCategories';
import { useGetDepositionStatusTypes } from '../../../shared/react-query-hooks/useGetDepositionStatusTypes';

export interface ICaseDepositionListWrapperProps {
    user: IUserModel;
    depositions: IDepositionModel[];
    caseGuid?: string;
    currentCase?: ICaseModel;
    forceHideDelete?: boolean;
    forceHideEdit?: boolean;
    showCaseColumn?: boolean;
    showEasyUpdateColumn?: boolean;
    usersInZone: IUserModel[];
    allDefenseCounselsForZone: IAssociatedOrganizationModel[];
    onDepositionsChange?: (depositions: IDepositionModel[]) => void;
}

export function CaseDepositionListWrapper(props: ICaseDepositionListWrapperProps) {
    const depositionStatusTypes = useGetDepositionStatusTypes();
    const depositionCategories = useGetDepositionCategories();

    const isLoading = depositionStatusTypes.isLoading || depositionCategories.isLoading;
    if (isLoading) return <div>Loading Depositions...</div>;

    return (
        <CaseDepositionList
            allDefenseCounselsForZone={props.allDefenseCounselsForZone}
            caseGuid={props.caseGuid ?? ''}
            categories={depositionCategories.data ?? []}
            currentCase={props.currentCase}
            depositions={props.depositions}
            forceHideDelete={props.forceHideDelete}
            hideEdit={props.forceHideEdit}
            onDepositionsChange={props.onDepositionsChange}
            showCaseColumn={props.showCaseColumn}
            showEasyUpdateColumn={props.showEasyUpdateColumn}
            statusTypes={depositionStatusTypes.data ?? []}
            user={props.user}
            usersInZone={props.usersInZone}
        />
    );
}
