import * as React from 'react';

import { Accordion, Modal } from 'react-bootstrap';
import {
    ApplicationHelpType,
    BoardCriteriaEnum,
    CaseInventoryByStateDisplayMode,
    CaseStatusTypesEnum,
    DataScopesEnum,
    EntityTypes,
    HelpKeys,
    MonthlyUpdateReportSectionList,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    PlaintiffsFirmDisplayMode,
    ReportCaseDetailsLastColumn,
    ReportSettings_CaseInventoryByStateDisplayMode,
    ReportSettings_PlaintiffsFirmDisplayMode,
    ReportSettings_ReportCaseDetailsLastColumn,
    ReportSettings_ReportCaseDetailsLastColumnDisplayName,
    TimelineReportSections,
    TimelineReportTypeEnum,
    UserRightsEnum,
} from '../../../utilities/Constants';
import {
    IInventoryDetailModel,
    IMonthlyRecapModel,
    ISettlementRecapModel,
    IZoneNoteModel,
} from '../../../interfaces/Report/ITimelineReport';
import { IKeyValuePairModel, ILookupModel, IMultiSelectOptions } from '../../../interfaces/ILookup';
import { INoteCategoryTypeModel, INoteModel } from '../../../interfaces/INote';
import {
    IReportCaseDetailModel,
    IReportNoteBaseModel,
} from '../../../interfaces/Report/IReportCaseDetailModel';
import {
    IReportSectionDetailModel,
    ITimelineReportInputModel,
} from '../../../interfaces/Report/ITimelineReport';

import { ApiRoutes } from '../../../utilities/ApiRoutes';
import Authorization from '../../../stores/Authorization';
import { CaseEasyUpdate } from '../../case/CaseEasyUpdate';
import CaseHelper from '../../../utilities/CaseHelper';
import CheckmateApiClient from '../../../services/apiClient';
import { CheckmateDialog } from '../../../components/shared/dialog';
import CheckmateSelect from '../../../components/shared/CheckmateSelect';
import CheckmateSelectHelper from '../../../utilities/CheckmateSelectHelper';
import Common from '../../../stores/Common';
import { DisplayMessages } from '../../../utilities/DisplayMessages';
import { Help } from '../../../components/shared/Help';
import { IBoardParametersModel } from '../../../interfaces/Report/IBoard';
import { ICaseModel } from '../../../interfaces/ICase';
import { IOrganizationSettingModel } from '../../../interfaces/IOrganization';
import { IValidation } from '../../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../../components/shared/Loader';
import { LocalRoutes } from '../../../utilities/LocalRoutes';
import { NoteEditor } from '../../../components/notes/NoteEditor';
import NoteHelper from '../../../utilities/NoteHelper';
import { PortfolioRisk } from '../../../components/report/PortfolioRisk';
import RelativeReportViewer from '../../../components/report/RelativeReportViewer';
import { ResizableDialog } from '../../../components/shared/ResizableDialog';
import { TextWithLineBreaks } from '../../../components/shared/TextWithLineBreaks';
import ValidateUtils from '../../../shared/validations';
import { inputCSS } from 'react-select/dist/declarations/src/components/Input';

const _apiClient = new CheckmateApiClient();

interface IMonthlyRecapState {
    pendingResponse: boolean;
    mapRenderComplete?: boolean;
    diseaseChartRenderComplete?: boolean;
    renderReportViewer?: boolean;
    validation: IValidation;
    reportData?: IMonthlyRecapModel;
    reportViewerHeight?: number;
    showSettingsModal: boolean;
    showCommentsModal?: boolean;
    currentCommentSection: number;
    selectAll: string[];
    helpDialogToShow: string;
    organizationSettings: IOrganizationSettingModel[];
    selectAllSections: TimelineReportSections[];
    inputModel: ITimelineReportInputModel;
    showExportModal?: boolean;
    plaintiffsFirmDisplayMode: IMultiSelectOptions;
    reportCaseDetailsLastColumn: IMultiSelectOptions;
    caseInventoryByStateDisplayMode: IMultiSelectOptions;
    getReportData: boolean;
    easyUpdateMode: boolean;
    easyUpdateCaseGuid?: string;
    easyUpdateAttemptedCaseList: string[];
    noteEditAttemptedGuidList: string[];
    openNoteEditor?: boolean;
    currentNote: INoteModel;
    noteCategoryTypes: INoteCategoryTypeModel[];
    showClearCommentsConfirmModal?: boolean;
    showClearSettingssConfirmModal?: boolean;
    sectionToBeRemoved?: TimelineReportSections;
}

export class MonthlyRecap extends React.Component<any, IMonthlyRecapState> {
    constructor(props: any) {
        super(props);

        this.state = {
            pendingResponse: false,
            validation: {},
            selectAll: [],
            helpDialogToShow: '',
            organizationSettings: [],
            inputModel: this.getDefaultReportInputModel(),
            selectAllSections: [],
            showSettingsModal: true,
            currentCommentSection: 0,
            plaintiffsFirmDisplayMode: {
                label: 'National Plaintiffs Firm (If Exists) or Plaintiffs Firm ',
                value: PlaintiffsFirmDisplayMode.Hierarchy.toString(),
                id: PlaintiffsFirmDisplayMode.Hierarchy,
            },
            reportCaseDetailsLastColumn: {
                label: 'Product/Premises',
                value: ReportCaseDetailsLastColumn.ProductPremises.toString(),
                id: ReportCaseDetailsLastColumn.ProductPremises,
            },
            caseInventoryByStateDisplayMode: {
                label: 'Top 5 States',
                value: CaseInventoryByStateDisplayMode.Top5.toString(),
                id: CaseInventoryByStateDisplayMode.Top5,
            },
            getReportData: true,
            easyUpdateMode: false,
            easyUpdateAttemptedCaseList: [],
            noteEditAttemptedGuidList: [],
            noteCategoryTypes: [],
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
        };
    }

    componentDidMount() {
        this.loadInitialData();
    }

    getDefaultReportInputModel = () => {
        const inputModel: ITimelineReportInputModel = {
            type: TimelineReportTypeEnum.Monthly,
            reportSectionDetails: [],
        };
        MonthlyUpdateReportSectionList.map((section: ILookupModel) => {
            const sectionDetail: IReportSectionDetailModel = {
                section: section.id,
                sectionName: section.name!,
            };
            inputModel.reportSectionDetails!.push(sectionDetail);
        });

        inputModel.additionalSettings = {
            plaintiffsFirmDisplayMode: PlaintiffsFirmDisplayMode.Hierarchy,
            caseInventoryByStateDisplayMode: CaseInventoryByStateDisplayMode.Top5,
            caseInventoryByStateDisplayNumberOfPlaintiffs: false,
            reportCaseDetailsLastColumn: ReportCaseDetailsLastColumn.ProductPremises,
            reportCaseDetailsLastColumnDisplayName: 'Product/Premises',
        };

        return inputModel;
    };

    loadInitialData = async () => {
        const validation: IValidation = {};
        this.setState({ pendingResponse: true, validation: validation });
        const orgSettings = await this.getOrganizationSettings();
        const noteCategoryTypes = await this.fetchNoteCategoryTypes();
        if (!orgSettings || !noteCategoryTypes) {
            validation.model = [DisplayMessages.UnexpectedError];
        }
        this.setState({
            organizationSettings: orgSettings!,
            noteCategoryTypes: noteCategoryTypes!,
            pendingResponse: false,
        });
    };

    getOrganizationSettings = async () => {
        this.setState({ pendingResponse: true, validation: {} });
        const response = await _apiClient.getOrganizationSettings();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        return response.payload;
    };

    fetchNoteCategoryTypes = async () => {
        const response = await _apiClient.getAllNoteCategoryTypes();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    getReportData = async () => {
        const validation: IValidation = {};

        if (!this.state.inputModel.startDate || !this.state.inputModel.endDate) {
            validation.settings = ['Enter Report Parameters'];
            this.setState({ validation: validation });
            return;
        }

        if (
            Common.dateDiffInDays(this.state.inputModel.endDate, this.state.inputModel.startDate) <
            0
        ) {
            validation.settings = ['Invalid Report Parameters'];
            this.setState({ validation: validation });
            return;
        }

        if (
            !this.state.inputModel.reportSectionDetails ||
            this.state.inputModel.reportSectionDetails.length === 0
        ) {
            validation.settings = ['Select at least 1 section'];
            this.setState({ validation: validation });
            return;
        }

        if (
            this.state.inputModel.reportSectionDetails &&
            this.state.inputModel.reportSectionDetails.filter(
                (element) =>
                    element.section === TimelineReportSections.RiskIssues ||
                    element.section === TimelineReportSections.SignificantActivity ||
                    element.section === TimelineReportSections.UpcomingTrialDates ||
                    element.section === TimelineReportSections.TenderedCases
            ).length > 0 &&
            (!this.state.inputModel.additionalSettings ||
                !this.state.inputModel.additionalSettings.reportCaseDetailsLastColumnDisplayName)
        ) {
            validation.settings = ['Case Details - Last Column Header Name is required'];
            this.setState({ validation: validation, pendingResponse: false });
            return;
        }

        this.setState({
            pendingResponse: true,
            validation: {},
            mapRenderComplete: false,
            diseaseChartRenderComplete: false,
        });

        const response = await _apiClient.getMonthlyRecapReportData(this.state.inputModel);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (response.payload) {
            this.setState({
                reportData: response.payload,
                pendingResponse: false,
                renderReportViewer: true,
                showSettingsModal: false,
                getReportData: false,
            });
        }
    };

    handleChange = (
        event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const inputModel = this.state.inputModel;
        const validation = this.state.validation;
        const name = event.target.name;
        const value = event.target.value;
        let getReportData = false;

        switch (name) {
            case 'startDate':
                inputModel.startDate = value;
                getReportData = true;
                break;
            case 'endDate':
                inputModel.endDate = value;
                getReportData = true;
                break;
            case ReportSettings_ReportCaseDetailsLastColumnDisplayName:
                if (!inputModel.additionalSettings) inputModel.additionalSettings = {};
                inputModel.additionalSettings.reportCaseDetailsLastColumnDisplayName = value;
                if (value) validation.settings = [''];
                break;
            default:
                break;
        }
        this.setState({
            inputModel: inputModel,
            validation: validation,
            getReportData: getReportData,
        });
    };

    handleCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections,
        entityType: EntityTypes
    ) => {
        const inputModel = this.state.inputModel;
        const selectAllSections = this.state.selectAllSections;

        if (event.target.checked) {
            if (!inputModel.reportSectionDetails) inputModel.reportSectionDetails = [];

            if (inputModel.reportSectionDetails.findIndex((x) => x.section === section) === -1)
                inputModel.reportSectionDetails.push({
                    section: section,
                    sectionName: section.toString(),
                });

            const sectionMatch = inputModel.reportSectionDetails.find((x) => x.section === section);
            if (!sectionMatch!.caseGuids) sectionMatch!.caseGuids = [];
            if (!sectionMatch!.noteGuids) sectionMatch!.noteGuids = [];

            switch (entityType) {
                case EntityTypes.Case:
                    if (sectionMatch!.caseGuids!.findIndex((x) => x === event.target.name) == -1)
                        sectionMatch!.caseGuids!.push(event.target.name);

                    const noteGuidsToAddToSelectedList = this.getNoteGuidsByCaseAndSection(
                        event.target.name,
                        section
                    );
                    if (noteGuidsToAddToSelectedList.length > 0) {
                        for (var i = 0; i < noteGuidsToAddToSelectedList.length; i++) {
                            if (
                                sectionMatch!.noteGuids!.findIndex(
                                    (x) => x === noteGuidsToAddToSelectedList[i]
                                ) === -1
                            )
                                sectionMatch!.noteGuids!.push(noteGuidsToAddToSelectedList[i]);
                        }
                    }
                    break;
                case EntityTypes.Note:
                    if (sectionMatch!.noteGuids!.findIndex((x) => x === event.target.name) === -1) {
                        sectionMatch!.noteGuids!.push(event.target.name);

                        // Find the parent;  if it is not checked, check it ( for all sections except other client requests)
                        const parentCaseGuid = this.getParentCaseGuidByNoteGuid(
                            event.target.name,
                            section
                        );
                        if (
                            parentCaseGuid &&
                            sectionMatch!.caseGuids!.findIndex((x) => x === parentCaseGuid) == -1
                        )
                            sectionMatch!.caseGuids!.push(parentCaseGuid);
                    }
                    break;
                default:
            }
        } else {
            if (selectAllSections.findIndex((x) => x === section) > -1)
                selectAllSections.splice(
                    selectAllSections.findIndex((x) => x === section),
                    1
                );

            if (inputModel.reportSectionDetails && inputModel.reportSectionDetails) {
                const sectionMatch = inputModel.reportSectionDetails.find(
                    (x) => x.section === section
                );
                if (sectionMatch) {
                    switch (entityType) {
                        case EntityTypes.Case:
                            if (
                                sectionMatch.caseGuids &&
                                sectionMatch.caseGuids.findIndex((x) => x === event.target.name) >
                                    -1
                            ) {
                                sectionMatch.caseGuids.splice(
                                    sectionMatch.caseGuids.findIndex(
                                        (x) => x === event.target.name
                                    ),
                                    1
                                );
                                if (sectionMatch!.noteGuids) {
                                    const noteGuidsToRemoveFromSelectedList =
                                        this.getNoteGuidsByCaseAndSection(
                                            event.target.name,
                                            section
                                        );
                                    if (noteGuidsToRemoveFromSelectedList.length > 0) {
                                        for (
                                            var i = 0;
                                            i < noteGuidsToRemoveFromSelectedList.length;
                                            i++
                                        ) {
                                            if (
                                                sectionMatch!.noteGuids!.findIndex(
                                                    (x) =>
                                                        x === noteGuidsToRemoveFromSelectedList[i]
                                                ) > -1
                                            )
                                                sectionMatch!.noteGuids!.splice(
                                                    sectionMatch!.noteGuids!.findIndex(
                                                        (x) =>
                                                            x ===
                                                            noteGuidsToRemoveFromSelectedList[i]
                                                    ),
                                                    1
                                                );
                                        }
                                    }
                                }
                            }
                            break;
                        case EntityTypes.Note:
                            if (
                                sectionMatch.noteGuids &&
                                sectionMatch.noteGuids.findIndex((x) => x === event.target.name) >
                                    -1
                            )
                                sectionMatch.noteGuids.splice(
                                    sectionMatch.noteGuids.findIndex(
                                        (x) => x === event.target.name
                                    ),
                                    1
                                );
                            break;
                        default:
                    }
                }
            }
        }

        this.setState({
            inputModel: inputModel,
            selectAllSections: selectAllSections,
        });
    };

    handleCheckedChangeAlt = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputModel = this.state.inputModel;
        if (!inputModel.additionalSettings) inputModel.additionalSettings = {};
        inputModel.additionalSettings.caseInventoryByStateDisplayNumberOfPlaintiffs =
            event.target.checked;
        this.setState({ inputModel: inputModel });
    };

    handleSectionCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections
    ) => {
        let inputModel = this.state.inputModel;
        let selectAllSections = this.state.selectAllSections;
        if (!inputModel.reportSectionDetails) {
            inputModel.reportSectionDetails = [];
        }

        if (event.target.checked) {
            const sectionMatch = inputModel.reportSectionDetails.find((x) => x.section === section);
            if (sectionMatch) sectionMatch.excludeInReport = false;
            else
                inputModel.reportSectionDetails!.push({
                    section: section,
                    sectionName: section.toString(),
                });

            this.setState({ inputModel: inputModel, selectAllSections: selectAllSections });
        } else {
            const sectionToRemove = inputModel.reportSectionDetails.find(
                (x) => x.section === section
            );
            if (sectionToRemove && sectionToRemove.comment)
                this.setState({ sectionToBeRemoved: section });
            else this.removeSection(section);
        }
    };

    handleConfirmRemoveSection = () => {
        if (this.state.sectionToBeRemoved) this.removeSection(this.state.sectionToBeRemoved);
    };

    removeSection = (section: TimelineReportSections) => {
        let inputModel = this.state.inputModel;
        let selectAllSections = this.state.selectAllSections;

        if (!inputModel.reportSectionDetails) return;

        inputModel.reportSectionDetails!.splice(
            inputModel.reportSectionDetails.findIndex((x) => x.section === section),
            1
        );

        if (selectAllSections.findIndex((x) => x === section) >= 0)
            selectAllSections.splice(
                selectAllSections.findIndex((x) => x === section),
                1
            );

        this.setState({
            inputModel: inputModel,
            selectAllSections: selectAllSections,
            sectionToBeRemoved: undefined,
        });
    };

    buildZoneNoteRows = (item: IZoneNoteModel) => {
        const rows = [];
        rows.push(
            <tr key={item.noteGuid}>
                <td className="border-0">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        name={item.noteGuid}
                        checked={this.getCheckedValue(
                            TimelineReportSections.ZoneNotes,
                            EntityTypes.Note,
                            item.noteGuid
                        )}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            this.handleCheckedChange(
                                e,
                                TimelineReportSections.ZoneNotes,
                                EntityTypes.Note
                            );
                        }}
                    />
                </td>
                <td className="col-sm-6 border-0">{item.topic}</td>
                <td className="border-0">{item.source}</td>
                {Authorization.userHasRight(UserRightsEnum.EditZoneNote, this.props.user) ? (
                    <td className="border-0" width={45}>
                        <button
                            className="btn btn-no-bg float-end"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                this.handleNoteEdit(e, {
                                    guid: item.noteGuid,
                                    type: { id: NoteTypes.ZoneNote },
                                    status: { id: 0 },
                                    purpose: { id: 0 },
                                });
                            }}
                        >
                            <i
                                className={`fal fa-pen ${
                                    this.state.noteEditAttemptedGuidList.filter(
                                        (x) => x === item.noteGuid
                                    ).length > 0
                                        ? 'color-gray'
                                        : 'color-black'
                                } `}
                            />
                        </button>
                    </td>
                ) : null}
            </tr>
        );

        rows.push(
            <tr>
                <td />
                <td colSpan={5}>
                    {item.noteContent ? <TextWithLineBreaks text={item.noteContent} /> : null}
                </td>
            </tr>
        );
        return rows;
    };

    generateAndDownloadReport = async () => {
        const validation: IValidation = {};
        if (!this.state.inputModel.startDate || !this.state.inputModel.endDate) {
            validation.model = ['Enter Report Parameters'];
            this.setState({ validation: validation });
            return;
        }

        if (
            !this.state.inputModel.reportSectionDetails ||
            this.state.inputModel.reportSectionDetails.length === 0
        ) {
            validation.model = ['Select at least 1 section'];
            this.setState({ validation: validation });
            return;
        }

        this.setState({ pendingResponse: true, validation: {} });

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + ApiRoutes.TimelineRecapExport, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this.setState({
                    pendingResponse: false,
                    validation: {},
                    showExportModal: false,
                });
                let fileName = 'RecapReport.pdf';
                const blob = xhr.response;

                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    const contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (let i = 0; i < contentDispositionItems.length; i++) {
                            const currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                const n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                const validation = this.state.validation;
                validation.model = [DisplayMessages.ReportGenerateError];
                this.setState({
                    pendingResponse: false,
                    validation: validation,
                    showExportModal: false,
                });
            }
        };

        xhr.send(JSON.stringify(this.state.inputModel));
    };

    getBoardPreferences() {
        const boardPreferences: IBoardParametersModel = {
            statusId: CaseStatusTypesEnum.Open.Value,
            criteria: BoardCriteriaEnum.CaseVolume.Value,
            date: JSON.parse(JSON.stringify(this.state.inputModel!)),
        };

        return boardPreferences;
    }

    reportViewerRenderComplete = (height: number) => {
        this.setState({ reportViewerHeight: height });
    };

    handleCommentsChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
        reportSection: ILookupModel
    ) => {
        let exportInputModel = this.state.inputModel;
        if (!exportInputModel) {
            exportInputModel = { reportSectionDetails: [] };
        }
        if (!exportInputModel.reportSectionDetails) {
            exportInputModel.reportSectionDetails = [];
        }

        const match = exportInputModel.reportSectionDetails.find(
            (x) => x.section === reportSection.id
        );
        if (match) {
            match.comment = event.target.value;
        } else {
            exportInputModel.reportSectionDetails.push({
                section: reportSection.id,
                sectionName: reportSection.name || '',
                comment: event.target.value,
            });
        }
        this.setState({ inputModel: exportInputModel });
    };

    getCommentText = (reportSection: number) => {
        if (this.state.inputModel && this.state.inputModel.reportSectionDetails) {
            const sectionMatch = this.state.inputModel.reportSectionDetails.find(
                (x) => x.section === reportSection
            );
            if (sectionMatch) return sectionMatch.comment;
        }

        return '';
    };

    clearComments = () => {
        const inputModel = this.state.inputModel;
        if (inputModel && inputModel.reportSectionDetails) {
            for (let i = 0; i < inputModel.reportSectionDetails.length; i++) {
                inputModel.reportSectionDetails[i].comment = '';
                inputModel.reportSectionDetails[i].excludeInReport = undefined;
            }
            this.setState({ inputModel: inputModel, showClearCommentsConfirmModal: false });
        }
    };

    getHelpText(key?: string) {
        let valueToCheck = this.state.helpDialogToShow;
        if (valueToCheck == '' && key) valueToCheck = key;
        const match = this.state.organizationSettings.find((x) => x.settingName == valueToCheck);
        if (match) return match.settingValue;
        return '';
    }

    getHelpTitle() {
        switch (this.state.helpDialogToShow) {
            case HelpKeys.NumberOfPlaintiffs.Name:
                return HelpKeys.NumberOfPlaintiffs.DisplayName;
            case HelpKeys.ZoneNotes.Name:
                return HelpKeys.ZoneNotes.DisplayName;
            default:
                return '';
        }
    }

    getCheckedValue(section: TimelineReportSections, entityType: EntityTypes, guid: string) {
        const exportInputModel = this.state.inputModel;
        if (exportInputModel.reportSectionDetails && exportInputModel.reportSectionDetails) {
            const sectionMatch = exportInputModel.reportSectionDetails.find(
                (x) => x.section === section
            );
            if (sectionMatch) {
                switch (entityType) {
                    case EntityTypes.Case:
                        if (sectionMatch.caseGuids)
                            return sectionMatch.caseGuids.findIndex((x) => x === guid) > -1;
                        break;
                    case EntityTypes.Note:
                        if (sectionMatch.noteGuids)
                            return sectionMatch.noteGuids.findIndex((x) => x === guid) > -1;
                        break;
                    default:
                }
            }
        }
        return false;
    }

    handleSelectAllCheckedChanged = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections
    ) => {
        const selectAllSections = this.state.selectAllSections;
        const exportInputModel = this.state.inputModel;

        if (!exportInputModel.reportSectionDetails) exportInputModel.reportSectionDetails = [];

        let sectionMatch = exportInputModel.reportSectionDetails.find((x) => x.section === section);
        if (!sectionMatch) {
            sectionMatch = { section: section, sectionName: section.toString() };
            exportInputModel.reportSectionDetails.push(sectionMatch);
        }

        if (event.target.checked) {
            if (selectAllSections.findIndex((x) => x === section) == -1)
                selectAllSections.push(section);
            const allGuids = this.getAllGuidsBySectionForExport(section);
            sectionMatch.caseGuids = allGuids.caseGuids;
            sectionMatch.noteGuids = allGuids.noteGuids;
        } else {
            if (selectAllSections.findIndex((x) => x === section) > -1)
                selectAllSections.splice(
                    selectAllSections.findIndex((x) => x === section),
                    1
                );
            sectionMatch.caseGuids = [];
            sectionMatch.noteGuids = [];
        }

        this.setState({
            selectAllSections: selectAllSections,
            inputModel: exportInputModel,
        });
    };

    isSelectAllChecked = (section: TimelineReportSections) => {
        return this.state.selectAllSections.filter((x) => x === section).length > 0;
    };

    getParentCaseGuidByNoteGuid = (noteGuid: string, section: TimelineReportSections): string => {
        if (this.state.reportData) {
            switch (section) {
                case TimelineReportSections.TenderedCases:
                    for (var i = 0; i < this.state.reportData.tenderedCases.length; i++) {
                        if (
                            this.state.reportData.tenderedCases[i].notes.filter(
                                (x) => x.guid == noteGuid
                            ).length > 0
                        )
                            return this.state.reportData.tenderedCases[i].caseGuid;
                    }
                    break;
                case TimelineReportSections.RiskIssues:
                    for (var i = 0; i < this.state.reportData.riskIssues.length; i++) {
                        if (
                            this.state.reportData.riskIssues[i].notes.filter(
                                (x) => x.guid == noteGuid
                            ).length > 0
                        )
                            return this.state.reportData.riskIssues[i].caseGuid;
                    }
                    break;
                case TimelineReportSections.SignificantActivity:
                    for (var i = 0; i < this.state.reportData.significantActivities.length; i++) {
                        if (
                            this.state.reportData.significantActivities[i].notes.filter(
                                (x) => x.guid == noteGuid
                            ).length > 0
                        )
                            return this.state.reportData.significantActivities[i].caseGuid;
                    }
                    break;
                default:
                    return '';
            }
        }

        return '';
    };

    getNoteGuidsByCaseAndSection = (
        caseGuid: string,
        section: TimelineReportSections
    ): string[] => {
        const returnValue: string[] = [];

        if (this.state.reportData) {
            switch (section) {
                case TimelineReportSections.TenderedCases:
                    const tenderedcaseMatch = this.state.reportData.tenderedCases.find(
                        (x) => x.caseGuid === caseGuid
                    );
                    if (tenderedcaseMatch && tenderedcaseMatch.notes)
                        tenderedcaseMatch.notes.map((item: IReportNoteBaseModel) => {
                            return returnValue.push(item.guid);
                        });
                    break;
                case TimelineReportSections.RiskIssues:
                    const riskIssuesMatch = this.state.reportData.riskIssues.find(
                        (x) => x.caseGuid === caseGuid
                    );
                    if (riskIssuesMatch && riskIssuesMatch.notes)
                        riskIssuesMatch.notes.map((item: IReportNoteBaseModel) => {
                            return returnValue.push(item.guid);
                        });
                    break;
                case TimelineReportSections.SignificantActivity:
                    const sigActMatch = this.state.reportData.significantActivities.find(
                        (x) => x.caseGuid === caseGuid
                    );
                    if (sigActMatch && sigActMatch.notes)
                        sigActMatch.notes.map((item: IReportNoteBaseModel) => {
                            return returnValue.push(item.guid);
                        });
                    break;
                default:
            }
        }
        return returnValue;
    };

    getAllGuidsBySectionForExport = (section: TimelineReportSections) => {
        const result: IReportSectionDetailModel = {
            section: section,
            sectionName: section.toString(),
            caseGuids: [],
            noteGuids: [],
        };
        if (this.state.reportData) {
            switch (section) {
                case TimelineReportSections.TenderedCases:
                    for (var i = 0; i < this.state.reportData.tenderedCases.length; i++) {
                        result.caseGuids!.push(this.state.reportData.tenderedCases[i].caseGuid);
                        for (
                            var j = 0;
                            j < this.state.reportData.tenderedCases[i].notes.length;
                            j++
                        ) {
                            result.noteGuids!.push(
                                this.state.reportData.tenderedCases[i].notes[j].guid
                            );
                        }
                    }
                    break;
                case TimelineReportSections.RiskIssues:
                    for (var i = 0; i < this.state.reportData.riskIssues.length; i++) {
                        result.caseGuids!.push(this.state.reportData.riskIssues[i].caseGuid);
                        for (var j = 0; j < this.state.reportData.riskIssues[i].notes.length; j++) {
                            result.noteGuids!.push(
                                this.state.reportData.riskIssues[i].notes[j].guid
                            );
                        }
                    }
                    break;
                case TimelineReportSections.SignificantActivity:
                    for (var i = 0; i < this.state.reportData.significantActivities.length; i++) {
                        result.caseGuids!.push(
                            this.state.reportData.significantActivities[i].caseGuid
                        );
                        for (
                            var j = 0;
                            j < this.state.reportData.significantActivities[i].notes.length;
                            j++
                        ) {
                            result.noteGuids!.push(
                                this.state.reportData.significantActivities[i].notes[j].guid
                            );
                        }
                    }
                    break;
                case TimelineReportSections.ZoneNotes:
                    for (var i = 0; i < this.state.reportData.zoneNotes.length; i++) {
                        result.noteGuids!.push(this.state.reportData.zoneNotes[i].noteGuid);
                    }
                    break;
                default:
            }
        }
        return result;
    };

    buildReportSection(item: IReportCaseDetailModel, section: TimelineReportSections) {
        const rows: any[] = [];
        rows.push(
            <tr>
                <td width={25} className={item.notes.length > 0 ? 'border-0 ' : ''}>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        name={item.caseGuid}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            this.handleCheckedChange(e, section, EntityTypes.Case);
                        }}
                        checked={this.getCheckedValue(section, EntityTypes.Case, item.caseGuid)}
                    />
                </td>
                <td className={(item.notes.length > 0 ? 'border-0 ' : '') + 'col-sm-2'}>
                    <Link target="_blank" to={LocalRoutes.CaseFile.replace(':guid', item.caseGuid)}>
                        {item.caseName}
                    </Link>
                </td>
                {section === TimelineReportSections.TenderedCases ? (
                    <td className={(item.notes.length > 0 ? 'border-0 ' : '') + 'col-sm-1'}>
                        {item.status!.displayName ? item.status!.displayName : item.status!.name!}
                    </td>
                ) : null}
                <td
                    className={
                        (item.notes.length > 0 ? 'border-0 ' : '') +
                        (section === TimelineReportSections.TenderedCases ? 'col-sm-2' : 'col-sm-3')
                    }
                >
                    {item.jurisdiction}
                </td>
                <td className={(item.notes.length > 0 ? 'border-0 ' : '') + 'col-sm-3'}>
                    {item.plaintiffsFirmDisplayName}
                </td>
                <td className={(item.notes.length > 0 ? 'border-0 ' : '') + 'col-sm-2'}>
                    {item.diagnosis}
                </td>
                <td className={item.notes.length > 0 ? 'border-0' : ''}>
                    {item.additionalCaseIdentifier}
                </td>
                {Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, this.props.user) ? (
                    <td
                        width={45}
                        className={(item.notes.length > 0 ? 'border-0 ' : '') + 'center'}
                    >
                        <button
                            className="btn-no-bg"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                this.handleEasyUpdate(e, item.caseGuid);
                            }}
                        >
                            <i
                                className={`fal fa-edit ${
                                    this.state.easyUpdateAttemptedCaseList.filter(
                                        (x) => x === item.caseGuid
                                    ).length > 0
                                        ? 'color-gray'
                                        : 'color-black'
                                } `}
                            />
                        </button>
                    </td>
                ) : null}
            </tr>
        );

        let colSpan = 5;
        switch (section) {
            case TimelineReportSections.TenderedCases:
                colSpan = 6;
                break;
            default:
        }

        if (item.notes)
            this.buildNotesRows(item, section, colSpan).map((row: any) => {
                rows.push(row);
            });
        return rows;
    }

    buildNotesRows(
        caseItem: IReportCaseDetailModel,
        section: TimelineReportSections,
        colSpan: number
    ) {
        const rows: any[] = [];
        for (let i = 0; i < caseItem.notes.length; i++) {
            const currentNote: INoteModel = {
                guid: caseItem.notes[i].guid,
                type: { id: NoteTypes.CaseNote },
                status: { id: 0 },
                purpose: { id: 0 },
                caseGuid: caseItem.caseGuid,
                caseName: caseItem.caseName,
                qScore: caseItem.qScore,
                qProjection: caseItem.qProjection,
            };

            rows.push(
                <tr key={i}>
                    <td className={caseItem.notes.length - 1 === i ? '' : 'border-0'}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name={caseItem.notes[i].guid}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                this.handleCheckedChange(e, section, EntityTypes.Note);
                            }}
                            checked={this.getCheckedValue(
                                section,
                                EntityTypes.Note,
                                caseItem.notes[i].guid
                            )}
                        />
                    </td>
                    <td
                        className={caseItem.notes.length - 1 === i ? '' : 'border-0'}
                        colSpan={colSpan}
                    >
                        {caseItem.notes[i].content ? (
                            <TextWithLineBreaks text={caseItem.notes[i].content} />
                        ) : null}
                    </td>
                    {Authorization.userHasRight(UserRightsEnum.EditCaseNote, this.props.user) &&
                    (Authorization.getUserDefaultOrganizationDataScope(this.props.user) !==
                        DataScopesEnum.LocalBasic.Value ||
                        caseItem.notes[i].allowLocalEdit) ? (
                        <td
                            width={45}
                            className={
                                (caseItem.notes.length - 1 === i ? '' : 'border-0 ') + 'center'
                            }
                        >
                            <button
                                className="btn btn-no-bg"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    this.handleNoteEdit(e, currentNote);
                                }}
                            >
                                <i
                                    className={`fal fa-pen ${
                                        this.state.noteEditAttemptedGuidList.filter(
                                            (x) => x === currentNote.guid
                                        ).length > 0
                                            ? 'color-gray'
                                            : 'color-black'
                                    } `}
                                />
                            </button>
                        </td>
                    ) : null}
                </tr>
            );
        }

        return rows;
    }

    buildRiskProfileHeader() {
        const rows: any[] = [];
        if (!this.state.reportData) return rows;

        rows.push(
            <tr>
                <th className="col-sm-2" rowSpan={2}>
                    Projected Resolution
                </th>
                <th className="col-sm-2 border-0">
                    {
                        this.state.reportData.riskProfile.filter((x) => x.columnSortOrder === 1)[0]
                            .columnDetail
                    }
                </th>
                <th className="col-sm-2 border-0">
                    {
                        this.state.reportData.riskProfile.filter((x) => x.columnSortOrder === 2)[0]
                            .columnDetail
                    }
                </th>
                <th className="col-sm-2 border-0">
                    {
                        this.state.reportData.riskProfile.filter((x) => x.columnSortOrder === 3)[0]
                            .columnDetail
                    }
                </th>
                <th className="col-sm-2 border-0">
                    {
                        this.state.reportData.riskProfile.filter((x) => x.columnSortOrder === 4)[0]
                            .columnDetail
                    }
                </th>
                <th className="col-sm-2 border-0">
                    {
                        this.state.reportData.riskProfile.filter((x) => x.columnSortOrder === 5)[0]
                            .columnDetail
                    }
                </th>
            </tr>
        );

        rows.push(
            <tr>
                <th className="col-sm-2 border-0-top font-weight-normal no-padding-left">
                    {
                        this.state.reportData.riskProfile.filter((x) => x.columnSortOrder === 1)[0]
                            .columnDetailHelpText
                    }
                </th>
                <th className="col-sm-2 border-0-top font-weight-normal no-padding-left">
                    {
                        this.state.reportData.riskProfile.filter((x) => x.columnSortOrder === 2)[0]
                            .columnDetailHelpText
                    }
                </th>
                <th className="col-sm-2 border-0-top font-weight-normal no-padding-left">
                    {
                        this.state.reportData.riskProfile.filter((x) => x.columnSortOrder === 3)[0]
                            .columnDetailHelpText
                    }
                </th>
                <th className="col-sm-2 border-0-top font-weight-normal no-padding-left">
                    {
                        this.state.reportData.riskProfile.filter((x) => x.columnSortOrder === 4)[0]
                            .columnDetailHelpText
                    }
                </th>
                <th className="col-sm-2 border-0-top font-weight-normal no-padding-left">
                    {
                        this.state.reportData.riskProfile.filter((x) => x.columnSortOrder === 5)[0]
                            .columnDetailHelpText
                    }
                </th>
            </tr>
        );

        return rows;
    }

    buildRiskProfileRows() {
        const rows: any[] = [];
        if (!this.state.reportData) return rows;

        const totalColumns = Math.max.apply(
            Math,
            this.state.reportData.riskProfile.map(function (o) {
                return o.columnSortOrder;
            })
        );
        const totalRows = Math.max.apply(
            Math,
            this.state.reportData.riskProfile.map(function (o) {
                return o.rowSortOrder;
            })
        );

        for (var i = 1; i < totalRows + 1; i++) {
            const columns = [];
            columns.push(
                <td className="col-sm-2">
                    {
                        this.state.reportData.riskProfile.filter((x) => x.rowSortOrder === i)[0]
                            .rowDetail
                    }
                </td>
            );
            for (var j = 1; j < totalColumns + 1; j++) {
                columns.push(
                    <td className="col-sm-2">
                        {
                            this.state.reportData.riskProfile.filter(
                                (x) => x.rowSortOrder === i && x.columnSortOrder === j
                            )[0].value
                        }{' '}
                        cases
                    </td>
                );
            }
            const styleString = {
                color: this.state.reportData.riskProfile.filter((x) => x.rowSortOrder === i)[0]
                    .format,
            };
            rows.push(
                <tr key={i} style={styleString}>
                    {columns}
                </tr>
            );
        }

        return rows;
    }

    getExcludeCheckedValue = (section: TimelineReportSections) => {
        const exportInputModel = this.state.inputModel;
        if (exportInputModel.reportSectionDetails && exportInputModel.reportSectionDetails) {
            const sectionMatch = exportInputModel.reportSectionDetails.find(
                (x) => x.section === section
            );
            if (sectionMatch) {
                return sectionMatch.excludeInReport || false;
            }
        }
        return false;
    };

    getCommentUI = (section: number, rowCount?: number) => {
        const text = this.getCommentText(section);
        if (text) return <div className="font-size-xs mb-3">{text}</div>;
        else if (rowCount != undefined && rowCount === 0)
            return <div className="mb-3">No data found for this time period</div>;
    };

    getCaseInventoryByStateOptions = () => {
        const options: IMultiSelectOptions[] = [];
        options.push({
            label: 'Top 5 States',
            value: CaseInventoryByStateDisplayMode.Top5.toString(),
            id: CaseInventoryByStateDisplayMode.Top5,
        });
        options.push({
            label: 'Top 10 States',
            value: CaseInventoryByStateDisplayMode.Top10.toString(),
            id: CaseInventoryByStateDisplayMode.Top10,
        });
        options.push({
            label: 'All States',
            value: CaseInventoryByStateDisplayMode.All.toString(),
            id: CaseInventoryByStateDisplayMode.All,
        });
        return options;
    };

    handleCheckmateSelectChange = (selectedItem: any, key: string) => {
        if (selectedItem === null || !selectedItem.id) return;

        const inputModel = this.state.inputModel;
        if (!inputModel.additionalSettings) inputModel.additionalSettings = {};
        switch (key) {
            case ReportSettings_PlaintiffsFirmDisplayMode:
                inputModel.additionalSettings.plaintiffsFirmDisplayMode = selectedItem.id;
                this.setState({
                    plaintiffsFirmDisplayMode: selectedItem,
                    getReportData: true,
                });
                break;
            case ReportSettings_ReportCaseDetailsLastColumn:
                inputModel.additionalSettings.reportCaseDetailsLastColumn = selectedItem.id;
                inputModel.additionalSettings.reportCaseDetailsLastColumnDisplayName = '';
                this.setState({
                    reportCaseDetailsLastColumn: selectedItem,
                    getReportData: true,
                });
                break;
            case ReportSettings_CaseInventoryByStateDisplayMode:
                inputModel.additionalSettings.caseInventoryByStateDisplayMode = selectedItem.id;
                this.setState({
                    caseInventoryByStateDisplayMode: selectedItem,
                    getReportData: true,
                });
                break;
            default:
                break;
        }
    };

    getSectionSettingsUI = (section: ILookupModel) => {
        const sectionSelected =
            this.state.inputModel.reportSectionDetails &&
            this.state.inputModel.reportSectionDetails.filter((x) => x.section === section.id)
                .length > 0;
        return (
            <div className="mt-2">
                <input
                    id="chkSection"
                    className="form-check-input d-inline-block mt-1"
                    type="checkbox"
                    checked={sectionSelected}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.handleSectionCheckedChange(e, section.id);
                    }}
                />
                <span className="ms-2 align-top">{section.displayName}</span>
                {section.id === TimelineReportSections.CaseInventoryByState && sectionSelected ? (
                    <>
                        <div className="row mt-1 ms-3">
                            <div className="col-sm-4">
                                <label className="col-form-label text-gray">States *</label>
                            </div>
                            <div className="col-sm-4">
                                <CheckmateSelect
                                    options={this.getCaseInventoryByStateOptions()}
                                    name={ReportSettings_CaseInventoryByStateDisplayMode}
                                    value={this.state.caseInventoryByStateDisplayMode}
                                    onChange={(e: any) => {
                                        this.handleCheckmateSelectChange(
                                            e,
                                            ReportSettings_CaseInventoryByStateDisplayMode
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row mt-1 ms-3">
                            <div className="col-sm-12">
                                <input
                                    id="chkCaseInventoryByStateDisplayNumberOfPlaintiffs"
                                    className="form-check-input d-inline-block mt-1"
                                    type="checkbox"
                                    checked={
                                        this.state.inputModel.additionalSettings &&
                                        this.state.inputModel.additionalSettings
                                            .caseInventoryByStateDisplayNumberOfPlaintiffs
                                            ? true
                                            : false
                                    }
                                    onChange={this.handleCheckedChangeAlt}
                                />
                                <span className="ms-2 text-gray">Display Number of Plaintiffs</span>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
        );
    };

    easyUpdateDone = async (updatedCase: ICaseModel) => {
        /*         let reportData = this.state.reportData;
                if (reportData) {
                    let reportCaseMatch = reportData.tenderedCases.find(x => x.caseGuid === updatedCase.guid);
                    if (reportCaseMatch)
                        CaseHelper.updateReportData(reportCaseMatch, updatedCase);
        
                    reportCaseMatch = reportData.riskIssues.find(x => x.caseGuid === updatedCase.guid);
                    if (reportCaseMatch)
                        CaseHelper.updateReportData(reportCaseMatch, updatedCase);
        
                    reportCaseMatch = reportData.significantActivities.find(x => x.caseGuid === updatedCase.guid);
                    if (reportCaseMatch)
                        CaseHelper.updateReportData(reportCaseMatch, updatedCase);
        
                    reportCaseMatch = reportData.trialDates.find(x => x.caseGuid === updatedCase.guid);
                    if (reportCaseMatch)
                        CaseHelper.updateReportData(reportCaseMatch, updatedCase); */

        this.setState({ easyUpdateMode: false, easyUpdateCaseGuid: undefined }, this.getReportData);
    };

    handleEasyUpdate = (e: React.FormEvent<HTMLButtonElement>, caseGuid: string) => {
        const easyUpdateAttemptedCaseList = this.state.easyUpdateAttemptedCaseList;
        if (easyUpdateAttemptedCaseList.filter((x) => x === caseGuid).length === 0)
            easyUpdateAttemptedCaseList.push(caseGuid);
        this.setState({
            easyUpdateMode: true,
            easyUpdateCaseGuid: caseGuid,
            easyUpdateAttemptedCaseList: easyUpdateAttemptedCaseList,
        });
    };

    handleNoteEdit = (e: React.FormEvent<HTMLButtonElement>, noteItem: INoteModel) => {
        if (!noteItem.guid) return;

        const noteEditAttemptedGuidList = this.state.noteEditAttemptedGuidList;
        if (noteEditAttemptedGuidList.filter((x) => x === noteItem.guid).length === 0)
            noteEditAttemptedGuidList.push(noteItem.guid);
        this.setState({
            openNoteEditor: true,
            currentNote: noteItem,
        });
    };

    getNoteCategoryTypesByNoteType = () => {
        const noteTypeId = this.state.currentNote.type ? this.state.currentNote.type.id : 0;
        return this.state.noteCategoryTypes.filter((x) => x.noteType.id === noteTypeId);
    };

    onSaveNoteComplete = (noteItem: INoteModel) => {
        const validation = this.state.validation;
        validation.categories = [];
        validation.content = [];
        this.setState(
            {
                openNoteEditor: false,
                currentNote: {
                    status: { id: NoteStatusTypes.Open },
                    purpose: { id: NotePurposeTypesEnum.Info },
                },
                validation: validation,
            },
            this.getReportData
        );
    };

    onCancelAddEditNote = () => {
        const validation = this.state.validation;
        validation.categories = [];
        validation.content = [];
        this.setState({
            openNoteEditor: false,
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            validation: validation,
            mapRenderComplete: false,
            diseaseChartRenderComplete: false,
        });
    };

    handleClearSettings = () => {
        this.setState({
            reportData: undefined,
            validation: {},
            inputModel: this.getDefaultReportInputModel(),
            plaintiffsFirmDisplayMode: {
                label: 'National Plaintiffs Firm (If Exists) or Plaintiffs Firm ',
                value: PlaintiffsFirmDisplayMode.Hierarchy.toString(),
                id: PlaintiffsFirmDisplayMode.Hierarchy,
            },
            reportCaseDetailsLastColumn: {
                label: 'Product/Premises',
                value: ReportCaseDetailsLastColumn.ProductPremises.toString(),
                id: ReportCaseDetailsLastColumn.ProductPremises,
            },
            caseInventoryByStateDisplayMode: {
                label: 'Top 5 States',
                value: CaseInventoryByStateDisplayMode.Top5.toString(),
                id: CaseInventoryByStateDisplayMode.Top5,
            },
            showCommentsModal: false,
            showClearSettingssConfirmModal: false,
        });
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;

        if (this.state.openNoteEditor)
            return (
                <NoteEditor
                    readOnly={NoteHelper.isNoteReadOnly(
                        this.state.currentNote,
                        this.props.user,
                        Authorization.userHasRight(UserRightsEnum.ViewCaseNotes, this.props.user),
                        Authorization.userHasRight(UserRightsEnum.EditCaseNote, this.props.user),
                        Authorization.userHasRight(UserRightsEnum.AddCaseNote, this.props.user)
                    )}
                    user={this.props.user}
                    caseTitle={NoteHelper.getCaseTitle(this.state.currentNote)}
                    noteCategoryTypes={this.getNoteCategoryTypesByNoteType()}
                    currentNote={this.state.currentNote}
                    caseLocalCounselGuid={this.state.currentNote.localCounselOrganizationGuid}
                    defaultAlertTarget={
                        Authorization.isLocalScope(this.props.user)
                            ? this.state.currentNote.caseManager
                            : undefined
                    }
                    defaultWatchTargets={
                        Authorization.isLocalScope(this.props.user) &&
                        this.state.currentNote.alternateCaseManager
                            ? [this.state.currentNote.alternateCaseManager]
                            : undefined
                    }
                    handleSaveComplete={this.onSaveNoteComplete}
                    handleCancel={this.onCancelAddEditNote}
                />
            );

        return (
            <>
                {Authorization.userHasRight(UserRightsEnum.EasyUpdate, this.props.user) ? (
                    <CaseEasyUpdate
                        user={this.props.user}
                        caseGuid={this.state.easyUpdateCaseGuid}
                        onComplete={this.easyUpdateDone}
                        onCancel={() => {
                            this.setState({
                                easyUpdateMode: false,
                                easyUpdateCaseGuid: undefined,
                                mapRenderComplete: false,
                                diseaseChartRenderComplete: false,
                            });
                        }}
                        hidden={!this.state.easyUpdateMode}
                    />
                ) : null}
                {this.state.easyUpdateMode ? null : (
                    <>
                        <div className="row mb-3">
                            <div className="col-sm-6">
                                <h1>
                                    Monthly Update
                                    {this.state.inputModel &&
                                    this.state.inputModel.startDate &&
                                    this.state.inputModel.endDate ? (
                                        <span className="font-size-xs ms-3">
                                            {Common.dateFormat(
                                                this.state.inputModel.startDate.toString()
                                            ) +
                                                ' to ' +
                                                Common.dateFormat(
                                                    this.state.inputModel.endDate.toString()
                                                )}
                                        </span>
                                    ) : null}
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                {this.state.reportData &&
                                this.state.mapRenderComplete &&
                                this.state.diseaseChartRenderComplete ? (
                                    <button
                                        className="btn btn-orange float-end text-gray"
                                        onClick={this.getReportData}
                                    >
                                        Refresh
                                    </button>
                                ) : null}
                                <button
                                    className="btn btn-no-bg float-end text-gray"
                                    onClick={() => this.setState({ showSettingsModal: true })}
                                >
                                    <i className="fal fa-user-cog" />
                                    &nbsp;Settings
                                </button>
                                {this.state.reportData &&
                                this.state.mapRenderComplete &&
                                this.state.diseaseChartRenderComplete ? (
                                    <>
                                        <button
                                            className="btn btn-no-bg float-end text-gray"
                                            onClick={() =>
                                                this.setState({
                                                    currentCommentSection: 0,
                                                    showCommentsModal: true,
                                                })
                                            }
                                        >
                                            <i className="fal fa-comment-lines" />
                                            &nbsp;Comments
                                        </button>
                                        <button
                                            className="btn btn-no-bg float-end text-gray"
                                            onClick={this.generateAndDownloadReport}
                                        >
                                            <i className="fal fa-file-pdf" />
                                            &nbsp;PDF
                                        </button>
                                    </>
                                ) : null}
                            </div>
                        </div>

                        <div>
                            <span className="text-danger">{this.state.validation.model}</span>
                        </div>

                        {this.state.reportData ? (
                            <div>
                                {this.state.mapRenderComplete ? (
                                    <>
                                        <h4>
                                            <input
                                                id="chkSectionCaseInventory"
                                                className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                                type="checkbox"
                                                checked={
                                                    this.state.inputModel.reportSectionDetails &&
                                                    this.state.inputModel.reportSectionDetails.filter(
                                                        (x) =>
                                                            x.section ===
                                                            TimelineReportSections.CaseInventory
                                                    ).length > 0
                                                        ? true
                                                        : false
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleSectionCheckedChange(
                                                        e,
                                                        TimelineReportSections.CaseInventory
                                                    );
                                                }}
                                            />
                                            Case Inventory
                                            <button
                                                className="btn btn-no-bg text-gray"
                                                onClick={() =>
                                                    this.setState({
                                                        showCommentsModal: true,
                                                        currentCommentSection:
                                                            TimelineReportSections.CaseInventory,
                                                    })
                                                }
                                            >
                                                <i className="fal fa-comment-lines" />
                                                &nbsp;Comments
                                            </button>
                                        </h4>
                                        <table className="table thick-outer-border">
                                            <thead>
                                                <tr>
                                                    {this.state.reportData.inventory.map(
                                                        (item: IKeyValuePairModel) => {
                                                            return (
                                                                <th className="align-top text-center">
                                                                    {item
                                                                        .key!.split('<br/>')
                                                                        .map((x, key) => {
                                                                            return (
                                                                                <span key={key}>
                                                                                    {x}
                                                                                    <br />
                                                                                </span>
                                                                            );
                                                                        })}
                                                                </th>
                                                            );
                                                        }
                                                    )}
                                                </tr>
                                            </thead>
                                            <tbody className="align-top text-center">
                                                <tr>
                                                    {this.state.reportData.inventory.map(
                                                        (item: IKeyValuePairModel) => {
                                                            return <td>{item.value}</td>;
                                                        }
                                                    )}
                                                </tr>
                                            </tbody>
                                        </table>
                                        {this.getCommentUI(TimelineReportSections.CaseInventory)}
                                    </>
                                ) : null}

                                {this.state.mapRenderComplete ? (
                                    <h4>
                                        <input
                                            id="chkSectionOpenActiveCasesStateMap"
                                            className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                            type="checkbox"
                                            checked={
                                                this.state.inputModel.reportSectionDetails &&
                                                this.state.inputModel.reportSectionDetails.filter(
                                                    (x) =>
                                                        x.section ===
                                                        TimelineReportSections.OpenActiveCasesStateMap
                                                ).length > 0
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                this.handleSectionCheckedChange(
                                                    e,
                                                    TimelineReportSections.OpenActiveCasesStateMap
                                                );
                                            }}
                                        />
                                        Open - Active Cases by State
                                        <button
                                            className="btn btn-no-bg text-gray"
                                            onClick={() =>
                                                this.setState({
                                                    showCommentsModal: true,
                                                    currentCommentSection:
                                                        TimelineReportSections.OpenActiveCasesStateMap,
                                                })
                                            }
                                        >
                                            <i className="fal fa-comment-lines" />
                                            &nbsp;Comments
                                        </button>
                                    </h4>
                                ) : null}
                                <RelativeReportViewer
                                    reportName="OpenActiveCasesMap.trdp"
                                    heightInPixels={470}
                                    reportParameters={{ endDate: this.state.inputModel!.endDate }}
                                    refreshReport={false}
                                    renderComplete={() =>
                                        this.setState({ mapRenderComplete: true })
                                    }
                                />
                                {this.getCommentUI(TimelineReportSections.OpenActiveCasesStateMap)}

                                {this.state.mapRenderComplete &&
                                this.state.reportData.inventoryByState.length > 0 ? (
                                    <>
                                        <h4>
                                            <input
                                                id="chkSectionCaseInventoryByState"
                                                className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                                type="checkbox"
                                                checked={
                                                    this.state.inputModel.reportSectionDetails &&
                                                    this.state.inputModel.reportSectionDetails.filter(
                                                        (x) =>
                                                            x.section ===
                                                            TimelineReportSections.CaseInventoryByState
                                                    ).length > 0
                                                        ? true
                                                        : false
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleSectionCheckedChange(
                                                        e,
                                                        TimelineReportSections.CaseInventoryByState
                                                    );
                                                }}
                                            />
                                            Case Inventory by State
                                            <button
                                                className="btn btn-no-bg text-gray"
                                                onClick={() =>
                                                    this.setState({
                                                        showCommentsModal: true,
                                                        currentCommentSection:
                                                            TimelineReportSections.CaseInventoryByState,
                                                    })
                                                }
                                            >
                                                <i className="fal fa-comment-lines" />
                                                &nbsp;Comments
                                            </button>
                                        </h4>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="col-sm-2">State</th>
                                                    <th className="col-sm-2">Open-Active</th>
                                                    {this.state.inputModel.additionalSettings &&
                                                    this.state.inputModel.additionalSettings
                                                        .caseInventoryByStateDisplayNumberOfPlaintiffs ? (
                                                        <th className="col-sm-2">
                                                            # of Plaintiffs
                                                        </th>
                                                    ) : null}
                                                    <th className="col-sm-2">YTD New Cases </th>
                                                    <th className="col-sm-2">
                                                        YTD Dismissed Cases
                                                    </th>
                                                    <th className="col-sm-2">YTD Settled Cases</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.reportData.inventoryByState.map(
                                                    (item: IInventoryDetailModel) => {
                                                        return (
                                                            <tr>
                                                                <td className="col-sm-2">
                                                                    {item.key}
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.openActive}
                                                                </td>
                                                                {this.state.inputModel
                                                                    .additionalSettings &&
                                                                this.state.inputModel
                                                                    .additionalSettings
                                                                    .caseInventoryByStateDisplayNumberOfPlaintiffs ? (
                                                                    <td className="col-sm-2">
                                                                        {item.numberOfPlaintiffs}
                                                                    </td>
                                                                ) : null}
                                                                <td className="col-sm-2">
                                                                    {item.new}
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.dismissed}
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.settled}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                        {this.getCommentUI(
                                            TimelineReportSections.CaseInventoryByState
                                        )}
                                    </>
                                ) : null}

                                {this.state.mapRenderComplete ? (
                                    <>
                                        <h4>
                                            <input
                                                id="chkSectionOpenActiveCasesByDisease"
                                                className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                                type="checkbox"
                                                checked={
                                                    this.state.inputModel.reportSectionDetails &&
                                                    this.state.inputModel.reportSectionDetails.filter(
                                                        (x) =>
                                                            x.section ===
                                                            TimelineReportSections.OpenActiveCasesByDisease
                                                    ).length > 0
                                                        ? true
                                                        : false
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleSectionCheckedChange(
                                                        e,
                                                        TimelineReportSections.OpenActiveCasesByDisease
                                                    );
                                                }}
                                            />
                                            Open - Active Cases by Disease
                                            <button
                                                className="btn btn-no-bg text-gray"
                                                onClick={() =>
                                                    this.setState({
                                                        showCommentsModal: true,
                                                        currentCommentSection:
                                                            TimelineReportSections.OpenActiveCasesByDisease,
                                                    })
                                                }
                                            >
                                                <i className="fal fa-comment-lines" />
                                                &nbsp;Comments
                                            </button>
                                        </h4>
                                        <RelativeReportViewer
                                            reportName="OpenActiveCasesByDisease.trdp"
                                            heightInPixels={325}
                                            reportParameters={{
                                                endDate: this.state.inputModel!.endDate,
                                            }}
                                            refreshReport={false}
                                            renderComplete={() =>
                                                this.setState({ diseaseChartRenderComplete: true })
                                            }
                                        />

                                        {this.getCommentUI(
                                            TimelineReportSections.OpenActiveCasesByDisease
                                        )}
                                    </>
                                ) : null}

                                {this.state.mapRenderComplete ? (
                                    <>
                                        <h4>
                                            <input
                                                id="chkSectionRiskProfile"
                                                className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                                type="checkbox"
                                                checked={
                                                    this.state.inputModel.reportSectionDetails &&
                                                    this.state.inputModel.reportSectionDetails.filter(
                                                        (x) =>
                                                            x.section ===
                                                            TimelineReportSections.RiskProfile
                                                    ).length > 0
                                                        ? true
                                                        : false
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleSectionCheckedChange(
                                                        e,
                                                        TimelineReportSections.RiskProfile
                                                    );
                                                }}
                                            />
                                            Portfolio Risk
                                            <button
                                                className="btn btn-no-bg text-gray"
                                                onClick={() =>
                                                    this.setState({
                                                        showCommentsModal: true,
                                                        currentCommentSection:
                                                            TimelineReportSections.RiskProfile,
                                                    })
                                                }
                                            >
                                                <i className="fal fa-comment-lines" />
                                                &nbsp;Comments
                                            </button>
                                        </h4>
                                        <PortfolioRisk
                                            riskProfile={this.state.reportData.riskProfile}
                                        />
                                        {this.getCommentUI(
                                            TimelineReportSections.RiskProfile,
                                            this.state.reportData.riskProfile.length
                                        )}

                                        <h4>
                                            <input
                                                id="chkSectionSettlementRecap"
                                                className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                                type="checkbox"
                                                checked={
                                                    this.state.inputModel.reportSectionDetails &&
                                                    this.state.inputModel.reportSectionDetails.filter(
                                                        (x) =>
                                                            x.section ===
                                                            TimelineReportSections.SettlementRecap
                                                    ).length > 0
                                                        ? true
                                                        : false
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleSectionCheckedChange(
                                                        e,
                                                        TimelineReportSections.SettlementRecap
                                                    );
                                                }}
                                            />
                                            Settlement Recap YTD
                                            <button
                                                className="btn btn-no-bg text-gray"
                                                onClick={() =>
                                                    this.setState({
                                                        showCommentsModal: true,
                                                        currentCommentSection:
                                                            TimelineReportSections.SettlementRecap,
                                                    })
                                                }
                                            >
                                                <i className="fal fa-comment-lines" />
                                                &nbsp;Comments
                                            </button>
                                        </h4>
                                        <table className="table thick-outer-border">
                                            <thead>
                                                <tr>
                                                    <th className="col-sm-2">State</th>
                                                    <th className="col-sm-2">Meso</th>
                                                    <th className="col-sm-2">Lung Cancer</th>
                                                    <th className="col-sm-2">Other Disease</th>
                                                    <th className="col-sm-2">YTD Total</th>
                                                    {this.state.reportData.settlementRecap.filter(
                                                        (x) => x.priorYearTotal
                                                    ).length > 0 ? (
                                                        <th className="col-sm-2">
                                                            {Common.Year(
                                                                this.state.inputModel!.endDate!
                                                            ) - 1}{' '}
                                                            Total
                                                        </th>
                                                    ) : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.reportData.settlementRecap.map(
                                                    (
                                                        item: ISettlementRecapModel,
                                                        index: number
                                                    ) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="col-sm-2">
                                                                    {item.key}
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.diagnosis1
                                                                        ? item.diagnosis1 +
                                                                          (item.diagnosis1Count
                                                                              ? ' | ' +
                                                                                item.diagnosis1Count
                                                                              : '')
                                                                        : ''}
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.diagnosis2
                                                                        ? item.diagnosis2 +
                                                                          (item.diagnosis2Count
                                                                              ? ' | ' +
                                                                                item.diagnosis2Count
                                                                              : '')
                                                                        : ''}
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.diagnosis3
                                                                        ? item.diagnosis3 +
                                                                          (item.diagnosis3Count
                                                                              ? ' | ' +
                                                                                item.diagnosis3Count
                                                                              : '')
                                                                        : ''}
                                                                </td>
                                                                <td className="col-sm-2">
                                                                    {item.total}
                                                                </td>
                                                                <td>
                                                                    {item.priorYearTotal
                                                                        ? item.priorYearTotal
                                                                        : null}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                        {this.getCommentUI(TimelineReportSections.SettlementRecap)}

                                        {/***********************************  TENDERED CASES  **********************************************************************************************/}
                                        <div>
                                            <h4>
                                                <input
                                                    id="chkSectionTenderedCases"
                                                    className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                                    type="checkbox"
                                                    checked={
                                                        this.state.inputModel
                                                            .reportSectionDetails &&
                                                        this.state.inputModel.reportSectionDetails.filter(
                                                            (x) =>
                                                                x.section ===
                                                                TimelineReportSections.TenderedCases
                                                        ).length > 0
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleSectionCheckedChange(
                                                            e,
                                                            TimelineReportSections.TenderedCases
                                                        );
                                                    }}
                                                />
                                                Tendered Cases
                                                <Help
                                                    type={ApplicationHelpType.Info}
                                                    title="Tendered Cases"
                                                    additionalStyleClassNames="font-size-sm"
                                                    helpText="Cases that are Tendered-Pending as of the last date of the reporting period will be displayed along with the most recent case note, Category = Case.  \n\nCases with a Tendered-Accepted date during the reporting period will be displayed along with the most recent case note, Category = Case."
                                                />
                                                <button
                                                    className="btn btn-no-bg text-gray"
                                                    onClick={() =>
                                                        this.setState({
                                                            showCommentsModal: true,
                                                            currentCommentSection:
                                                                TimelineReportSections.TenderedCases,
                                                        })
                                                    }
                                                >
                                                    <i className="fal fa-comment-lines" />
                                                    &nbsp;Comments
                                                </button>
                                            </h4>{' '}
                                        </div>
                                        {this.state.reportData.tenderedCases.length > 0 ? (
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th /* width={25} */>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                name="selectAll"
                                                                checked={this.isSelectAllChecked(
                                                                    TimelineReportSections.TenderedCases
                                                                )}
                                                                onChange={(
                                                                    e: React.ChangeEvent<HTMLInputElement>
                                                                ) => {
                                                                    this.handleSelectAllCheckedChanged(
                                                                        e,
                                                                        TimelineReportSections.TenderedCases
                                                                    );
                                                                }}
                                                            />
                                                        </th>
                                                        <th className="col-sm-2">Case Name</th>
                                                        <th>Status</th>
                                                        <th className="col-sm-2">Jurisdiction</th>
                                                        <th className="col-sm-3">
                                                            Plaintiffs Firm
                                                        </th>
                                                        <th className="col-sm-2">Disease</th>
                                                        <th className="col-sm-2">
                                                            {this.state.inputModel
                                                                .additionalSettings
                                                                ? this.state.inputModel
                                                                      .additionalSettings
                                                                      .reportCaseDetailsLastColumnDisplayName
                                                                : 'Last Column Header Name'}
                                                        </th>
                                                        {Authorization.isAuthorizedToRoute(
                                                            LocalRoutes.CaseEasyUpdate,
                                                            this.props.user
                                                        ) ? (
                                                            <th></th>
                                                        ) : null}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.reportData.tenderedCases.map(
                                                        (item: IReportCaseDetailModel) => {
                                                            return this.buildReportSection(
                                                                item,
                                                                TimelineReportSections.TenderedCases
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        ) : null}
                                        {this.getCommentUI(
                                            TimelineReportSections.TenderedCases,
                                            this.state.reportData.tenderedCases.length
                                        )}

                                        {/***********************************  RISK ISSUES  **********************************************************************************************/}
                                        <h4>
                                            <input
                                                id="chkSectionRiskIssues"
                                                className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                                type="checkbox"
                                                checked={
                                                    this.state.inputModel.reportSectionDetails &&
                                                    this.state.inputModel.reportSectionDetails.filter(
                                                        (x) =>
                                                            x.section ===
                                                            TimelineReportSections.RiskIssues
                                                    ).length > 0
                                                        ? true
                                                        : false
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleSectionCheckedChange(
                                                        e,
                                                        TimelineReportSections.RiskIssues
                                                    );
                                                }}
                                            />
                                            Risk Issues
                                            <button
                                                className="btn btn-no-bg text-gray"
                                                onClick={() =>
                                                    this.setState({
                                                        showCommentsModal: true,
                                                        currentCommentSection:
                                                            TimelineReportSections.RiskIssues,
                                                    })
                                                }
                                            >
                                                <i className="fal fa-comment-lines" />
                                                &nbsp;Comments
                                            </button>
                                        </h4>
                                        {this.state.reportData.riskIssues.length > 0 ? (
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th /* width={25} */>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                name="selectAll"
                                                                checked={this.isSelectAllChecked(
                                                                    TimelineReportSections.RiskIssues
                                                                )}
                                                                onChange={(
                                                                    e: React.ChangeEvent<HTMLInputElement>
                                                                ) => {
                                                                    this.handleSelectAllCheckedChanged(
                                                                        e,
                                                                        TimelineReportSections.RiskIssues
                                                                    );
                                                                }}
                                                            />
                                                        </th>
                                                        <th className="col-sm-2">Case Name</th>
                                                        <th className="col-sm-2">Jurisdiction</th>
                                                        <th className="col-sm-3">
                                                            Plaintiffs Firm
                                                        </th>
                                                        <th className="col-sm-2">Disease</th>
                                                        <th>
                                                            {this.state.inputModel
                                                                .additionalSettings
                                                                ? this.state.inputModel
                                                                      .additionalSettings
                                                                      .reportCaseDetailsLastColumnDisplayName
                                                                : 'Last Column Header Name'}
                                                        </th>
                                                        {Authorization.isAuthorizedToRoute(
                                                            LocalRoutes.CaseEasyUpdate,
                                                            this.props.user
                                                        ) ? (
                                                            <th></th>
                                                        ) : null}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.reportData.riskIssues.map(
                                                        (item: IReportCaseDetailModel) => {
                                                            return this.buildReportSection(
                                                                item,
                                                                TimelineReportSections.RiskIssues
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        ) : null}
                                        {this.getCommentUI(
                                            TimelineReportSections.RiskIssues,
                                            this.state.reportData.riskIssues.length
                                        )}

                                        {/***********************************  SIGNIFICANT ACTIVITY  **********************************************************************************************/}
                                        <h4>
                                            <input
                                                id="chkSectionSignificantActivities"
                                                className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                                type="checkbox"
                                                checked={
                                                    this.state.inputModel.reportSectionDetails &&
                                                    this.state.inputModel.reportSectionDetails.filter(
                                                        (x) =>
                                                            x.section ===
                                                            TimelineReportSections.SignificantActivity
                                                    ).length > 0
                                                        ? true
                                                        : false
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleSectionCheckedChange(
                                                        e,
                                                        TimelineReportSections.SignificantActivity
                                                    );
                                                }}
                                            />
                                            Significant Activity
                                            <button
                                                className="btn btn-no-bg text-gray"
                                                onClick={() =>
                                                    this.setState({
                                                        showCommentsModal: true,
                                                        currentCommentSection:
                                                            TimelineReportSections.SignificantActivity,
                                                    })
                                                }
                                            >
                                                <i className="fal fa-comment-lines" />
                                                &nbsp;Comments
                                            </button>
                                        </h4>
                                        {this.state.reportData.significantActivities.length > 0 ? (
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th /* width={25} */>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                name="selectAll"
                                                                checked={this.isSelectAllChecked(
                                                                    TimelineReportSections.SignificantActivity
                                                                )}
                                                                onChange={(
                                                                    e: React.ChangeEvent<HTMLInputElement>
                                                                ) => {
                                                                    this.handleSelectAllCheckedChanged(
                                                                        e,
                                                                        TimelineReportSections.SignificantActivity
                                                                    );
                                                                }}
                                                            />
                                                        </th>
                                                        <th className="col-sm-2">Case Name</th>
                                                        <th className="col-sm-2">Jurisdiction</th>
                                                        <th className="col-sm-3">
                                                            Plaintiffs Firm
                                                        </th>
                                                        <th className="col-sm-2">Disease</th>
                                                        <th>
                                                            {this.state.inputModel
                                                                .additionalSettings
                                                                ? this.state.inputModel
                                                                      .additionalSettings
                                                                      .reportCaseDetailsLastColumnDisplayName
                                                                : 'Last Column Header Name'}
                                                        </th>
                                                        {Authorization.isAuthorizedToRoute(
                                                            LocalRoutes.CaseEasyUpdate,
                                                            this.props.user
                                                        ) ? (
                                                            <th></th>
                                                        ) : null}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.reportData.significantActivities.map(
                                                        (item: IReportCaseDetailModel) => {
                                                            return this.buildReportSection(
                                                                item,
                                                                TimelineReportSections.SignificantActivity
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        ) : null}
                                        {this.getCommentUI(
                                            TimelineReportSections.SignificantActivity,
                                            this.state.reportData.significantActivities.length
                                        )}

                                        <h4>
                                            <input
                                                id="chkSectionUpcomingTrialDates"
                                                className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                                type="checkbox"
                                                checked={
                                                    this.state.inputModel.reportSectionDetails &&
                                                    this.state.inputModel.reportSectionDetails.filter(
                                                        (x) =>
                                                            x.section ===
                                                            TimelineReportSections.UpcomingTrialDates
                                                    ).length > 0
                                                        ? true
                                                        : false
                                                }
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleSectionCheckedChange(
                                                        e,
                                                        TimelineReportSections.UpcomingTrialDates
                                                    );
                                                }}
                                            />
                                            Upcoming Trial Dates
                                            <button
                                                className="btn btn-no-bg text-gray"
                                                onClick={() =>
                                                    this.setState({
                                                        showCommentsModal: true,
                                                        currentCommentSection:
                                                            TimelineReportSections.UpcomingTrialDates,
                                                    })
                                                }
                                            >
                                                <i className="fal fa-comment-lines" />
                                                &nbsp;Comments
                                            </button>
                                        </h4>
                                        {this.state.reportData.trialDates.length > 0 ? (
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th /* width={25} */ className="border-0" />
                                                        <th className="col-sm-2">Case Name</th>
                                                        <th className="col-sm-1">Trial Date</th>
                                                        <th className="col-sm-3">Jurisdiction</th>
                                                        <th className="col-sm-2">
                                                            Plaintiffs Firm
                                                        </th>
                                                        <th className="col-sm-1">Disease</th>
                                                        <th>
                                                            {this.state.inputModel
                                                                .additionalSettings
                                                                ? this.state.inputModel
                                                                      .additionalSettings
                                                                      .reportCaseDetailsLastColumnDisplayName
                                                                : 'Last Column Header Name'}
                                                        </th>
                                                        {Authorization.isAuthorizedToRoute(
                                                            LocalRoutes.CaseEasyUpdate,
                                                            this.props.user
                                                        ) ? (
                                                            <th></th>
                                                        ) : null}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.reportData.trialDates.map(
                                                        (item: IReportCaseDetailModel) => {
                                                            return (
                                                                <tr>
                                                                    <td
                                                                        width={25}
                                                                        className="border-0"
                                                                    />
                                                                    <td className="col-sm-2">
                                                                        <Link
                                                                            target="_blank"
                                                                            to={LocalRoutes.CaseFile.replace(
                                                                                ':guid',
                                                                                item.caseGuid
                                                                            )}
                                                                        >
                                                                            {item.caseName}
                                                                        </Link>
                                                                    </td>
                                                                    <td className="col-sm-1">
                                                                        {Common.dateFormat(
                                                                            item.trialDate
                                                                        )}
                                                                    </td>
                                                                    <td className="col-sm-3">
                                                                        {item.jurisdiction}
                                                                    </td>
                                                                    <td className="col-sm-2">
                                                                        {
                                                                            item.plaintiffsFirmDisplayName
                                                                        }
                                                                    </td>
                                                                    <td className="col-sm-1">
                                                                        {item.diagnosis}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.additionalCaseIdentifier
                                                                        }
                                                                    </td>
                                                                    {Authorization.isAuthorizedToRoute(
                                                                        LocalRoutes.CaseEasyUpdate,
                                                                        this.props.user
                                                                    ) ? (
                                                                        <td
                                                                            width={45}
                                                                            className="center"
                                                                        >
                                                                            {' '}
                                                                            <button
                                                                                className="btn-no-bg"
                                                                                onClick={(
                                                                                    e: React.MouseEvent<HTMLButtonElement>
                                                                                ) => {
                                                                                    this.handleEasyUpdate(
                                                                                        e,
                                                                                        item.caseGuid
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <i
                                                                                    className={`fal fa-edit ${
                                                                                        this.state.easyUpdateAttemptedCaseList.filter(
                                                                                            (x) =>
                                                                                                x ===
                                                                                                item.caseGuid
                                                                                        ).length > 0
                                                                                            ? 'color-gray'
                                                                                            : 'color-black'
                                                                                    } `}
                                                                                />
                                                                            </button>
                                                                        </td>
                                                                    ) : null}
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        ) : null}
                                        {this.getCommentUI(
                                            TimelineReportSections.UpcomingTrialDates,
                                            this.state.reportData.trialDates.length
                                        )}

                                        <div>
                                            <h4>
                                                <input
                                                    id="chkSectionZoneNotes"
                                                    className="form-check-input d-inline-block mt-2 me-1 font-size-sm"
                                                    type="checkbox"
                                                    checked={
                                                        this.state.inputModel
                                                            .reportSectionDetails &&
                                                        this.state.inputModel.reportSectionDetails.filter(
                                                            (x) =>
                                                                x.section ===
                                                                TimelineReportSections.ZoneNotes
                                                        ).length > 0
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        this.handleSectionCheckedChange(
                                                            e,
                                                            TimelineReportSections.ZoneNotes
                                                        );
                                                    }}
                                                />
                                                Industry News
                                                <Help
                                                    type={ApplicationHelpType.Info}
                                                    title="Industry News"
                                                    helpText={this.getHelpText(
                                                        HelpKeys.ZoneNotes.Name
                                                    )}
                                                    additionalStyleClassNames="font-size-sm"
                                                />
                                                <button
                                                    className="btn btn-no-bg text-gray"
                                                    onClick={() =>
                                                        this.setState({
                                                            showCommentsModal: true,
                                                            currentCommentSection:
                                                                TimelineReportSections.ZoneNotes,
                                                        })
                                                    }
                                                >
                                                    <i className="fal fa-comment-lines" />
                                                    &nbsp;Comments
                                                </button>
                                            </h4>
                                        </div>

                                        {this.state.reportData.zoneNotes.length > 0 ? (
                                            <>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    name="selectAll"
                                                                    checked={this.isSelectAllChecked(
                                                                        TimelineReportSections.ZoneNotes
                                                                    )}
                                                                    onChange={(
                                                                        e: React.ChangeEvent<HTMLInputElement>
                                                                    ) => {
                                                                        this.handleSelectAllCheckedChanged(
                                                                            e,
                                                                            TimelineReportSections.ZoneNotes
                                                                        );
                                                                    }}
                                                                />
                                                            </th>
                                                            <th className="col-sm-6">Topic</th>
                                                            <th>Source</th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.reportData.zoneNotes.map(
                                                            (item: IZoneNoteModel) => {
                                                                return this.buildZoneNoteRows(item);
                                                            }
                                                        )}
                                                    </tbody>
                                                </table>
                                            </>
                                        ) : null}
                                        {this.getCommentUI(
                                            TimelineReportSections.ZoneNotes,
                                            this.state.reportData.zoneNotes.length
                                        )}
                                    </>
                                ) : null}
                            </div>
                        ) : null}

                        {this.state.showSettingsModal ? (
                            <Modal
                                centered
                                size="lg"
                                show={this.state.showSettingsModal}
                                onHide={() => this.setState({ showSettingsModal: false })}
                                backdrop={false}
                                dialogClassName="board-settings-dialog"
                            >
                                <Modal.Header>
                                    <Modal.Title>Report Settings</Modal.Title>
                                    <button
                                        className="btn btn-no-bg float-end"
                                        onClick={() => this.setState({ showSettingsModal: false })}
                                    >
                                        <i className="fal fa-xmark-large" />
                                    </button>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row">
                                        <span className="text-danger">
                                            {this.state.validation.settings}
                                        </span>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-sm-2">
                                            <label className="control-label" htmlFor="dateRange">
                                                Date Range
                                            </label>
                                        </div>
                                        <div className="col-sm-10">
                                            <input
                                                type="date"
                                                name="startDate"
                                                className={
                                                    'form-control d-inline-block horizontal-margin' +
                                                    (this.state.inputModel &&
                                                    this.state.inputModel.startDate &&
                                                    Common.isValidDate(
                                                        this.state.inputModel.startDate
                                                    )
                                                        ? ''
                                                        : ' unselectClass')
                                                }
                                                value={
                                                    this.state.inputModel &&
                                                    this.state.inputModel.startDate
                                                        ? this.state.inputModel.startDate
                                                        : ''
                                                }
                                                onChange={this.handleChange}
                                            />
                                            <span className="horizontal-margin">to</span>
                                            <input
                                                type="date"
                                                name="endDate"
                                                value={
                                                    this.state.inputModel &&
                                                    this.state.inputModel.endDate
                                                        ? this.state.inputModel.endDate
                                                        : ''
                                                }
                                                className={
                                                    'form-control d-inline-block horizontal-margin' +
                                                    (this.state.inputModel &&
                                                    this.state.inputModel.endDate &&
                                                    Common.isValidDate(
                                                        this.state.inputModel.endDate
                                                    )
                                                        ? ''
                                                        : ' unselectClass')
                                                }
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                        <label className="control-label" htmlFor="section">
                                            Sections
                                        </label>
                                    </div>
                                    <>
                                        {MonthlyUpdateReportSectionList.map(
                                            (section: ILookupModel) => {
                                                return this.getSectionSettingsUI(section);
                                            }
                                        )}
                                    </>

                                    {this.state.inputModel.reportSectionDetails &&
                                    this.state.inputModel.reportSectionDetails.filter(
                                        (element) =>
                                            element.section === TimelineReportSections.RiskIssues ||
                                            element.section ===
                                                TimelineReportSections.SignificantActivity ||
                                            element.section ===
                                                TimelineReportSections.UpcomingTrialDates ||
                                            element.section === TimelineReportSections.TenderedCases
                                    ).length > 0 ? (
                                        <>
                                            <div className="row my-2">
                                                <label className="control-label" htmlFor="section">
                                                    Case Details
                                                    <Help
                                                        type={ApplicationHelpType.Info}
                                                        title="Case Details"
                                                        helpText="Settings under the Case Details apply to the respective column in sections throughout the report that have a Header row and a list of cases."
                                                    />
                                                </label>
                                            </div>
                                            <>
                                                <div className="row mt-1 ms-3">
                                                    <div className="col-sm-4">
                                                        <label className="col-form-label text-gray">
                                                            Plaintiffs Firm *
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <CheckmateSelect
                                                            options={CheckmateSelectHelper.getPlaintiffsFirmDisplayOptions()}
                                                            name={
                                                                ReportSettings_PlaintiffsFirmDisplayMode
                                                            }
                                                            value={
                                                                this.state.plaintiffsFirmDisplayMode
                                                            }
                                                            onChange={(e: any) => {
                                                                this.handleCheckmateSelectChange(
                                                                    e,
                                                                    ReportSettings_PlaintiffsFirmDisplayMode
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-1 ms-3">
                                                    <div className="col-sm-4">
                                                        <label className="col-form-label text-gray">
                                                            Last Column *
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <CheckmateSelect
                                                            options={CheckmateSelectHelper.getAdditionalCaseIdentifierOptions()}
                                                            name={
                                                                ReportSettings_ReportCaseDetailsLastColumn
                                                            }
                                                            value={
                                                                this.state
                                                                    .reportCaseDetailsLastColumn
                                                            }
                                                            onChange={(e: any) => {
                                                                this.handleCheckmateSelectChange(
                                                                    e,
                                                                    ReportSettings_ReportCaseDetailsLastColumn
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-1 ms-3">
                                                    <div className="col-sm-4">
                                                        <label className="col-form-label text-gray">
                                                            Last Column Header Name *
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name={
                                                                ReportSettings_ReportCaseDetailsLastColumnDisplayName
                                                            }
                                                            placeholder="Header Name"
                                                            value={
                                                                this.state.inputModel
                                                                    .additionalSettings
                                                                    ? this.state.inputModel
                                                                          .additionalSettings
                                                                          .reportCaseDetailsLastColumnDisplayName
                                                                    : ''
                                                            }
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        </>
                                    ) : null}

                                    <div className="mt-2">
                                        <button
                                            className="btn btn-orange float-end text-gray"
                                            onClick={this.getReportData}
                                        >
                                            Run
                                        </button>
                                        <button
                                            className="btn btn-default float-end"
                                            onClick={() =>
                                                this.setState({
                                                    showClearSettingssConfirmModal: true,
                                                })
                                            }
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        ) : null}

                        <ResizableDialog
                            isShowingModal={!!this.state.showCommentsModal}
                            title="Comments"
                            defaultWidth={500}
                            minHeight={300}
                            minWidth={350}
                            bodyComponent={
                                <Accordion
                                    defaultActiveKey={this.state.currentCommentSection.toString()}
                                >
                                    {MonthlyUpdateReportSectionList.map((section: ILookupModel) => {
                                        return (
                                            <Accordion.Item
                                                key={section.id}
                                                eventKey={section.id.toString()}
                                            >
                                                <Accordion.Header>
                                                    {section.displayName}
                                                    {this.getCommentText(section.id) ? (
                                                        <i className="fal fa-comment-lines ps-2" />
                                                    ) : null}
                                                </Accordion.Header>
                                                <Accordion.Body className="py-0">
                                                    <textarea
                                                        className="form-control"
                                                        value={this.getCommentText(section.id)}
                                                        rows={5}
                                                        onChange={(
                                                            e: React.ChangeEvent<HTMLTextAreaElement>
                                                        ) => {
                                                            this.handleCommentsChange(e, section);
                                                        }}
                                                    />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        );
                                    })}
                                </Accordion>
                            }
                            confirmText="Clear"
                            handleConfirm={() => {
                                this.setState({ showClearCommentsConfirmModal: true });
                            }}
                            confirmButtonClassName="btn btn-default float-end"
                            cancelText="Done"
                            handleClose={() => this.setState({ showCommentsModal: false })}
                            closeButtonClassName="btn btn-orange float-end"
                            draggable
                        />

                        <CheckmateDialog
                            isShowingModal={this.state.helpDialogToShow != ''}
                            title={this.getHelpTitle()}
                            body={this.getHelpText()}
                            handleClose={() => this.setState({ helpDialogToShow: '' })}
                            closeButtonClassName="btn btn-black float-end"
                            cancelText="OK"
                            dialogClassName="resource-dialog"
                        />

                        <CheckmateDialog
                            isShowingModal={this.state.showClearCommentsConfirmModal || false}
                            body="Are you sure you want to clear all comments?"
                            handleClose={() => {
                                this.setState({ showClearCommentsConfirmModal: false });
                            }}
                            handleConfirm={this.clearComments}
                            confirmText="Yes"
                            cancelText="No"
                            confirmButtonClassName="btn btn-black float-end"
                            dialogClassName="confirm-document-delete-dialog"
                            closeButtonClassName="btn btn-default float-end"
                        />

                        <CheckmateDialog
                            isShowingModal={this.state.showClearSettingssConfirmModal || false}
                            body="Are you sure you want to clear all settings and set to default?"
                            handleClose={() => {
                                this.setState({ showClearSettingssConfirmModal: false });
                            }}
                            handleConfirm={this.handleClearSettings}
                            confirmText="Yes"
                            cancelText="No"
                            confirmButtonClassName="btn btn-black float-end"
                            dialogClassName="confirm-document-delete-dialog"
                            closeButtonClassName="btn btn-default float-end"
                        />

                        <CheckmateDialog
                            isShowingModal={this.state.sectionToBeRemoved ? true : false}
                            body="The section you are trying to remove contains comments. Removing the section will delete the comment for the section. Are you sure you want to remove this section? "
                            handleClose={() => {
                                this.setState({ sectionToBeRemoved: undefined });
                            }}
                            handleConfirm={this.handleConfirmRemoveSection}
                            confirmText="Yes"
                            cancelText="No"
                            confirmButtonClassName="btn btn-black float-end"
                            dialogClassName="confirm-document-delete-dialog"
                            closeButtonClassName="btn btn-default float-end"
                        />
                    </>
                )}
            </>
        );
    }
}
