import { Component, FunctionComponent, ReactNode } from 'react';
import Select, { MultiValue, StylesConfig, defaultTheme } from 'react-select';

import { ApplicationHelpType } from '../../utilities/Constants';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import { Help } from './Help';
import { IMultiSelectOptions } from '../../interfaces/ILookup';

const { colors } = defaultTheme;

const selectStyles: StylesConfig<IMultiSelectOptions, true> = {
    control: (provided) => ({
        ...provided,
        minWidth: 240,
        margin: 8,
        height: 34.4,
        minHeight: 34.4,
        borderRadius: 0,
        borderColor: 'var(--gray-color)',
        boxShadow: 'none',
        '&:hover, &:focus-within': {
            border: 'solid 1px var(--blue-color)',
            boxShadow: '0 0 0 1px var(--blue-color)',
        },
    }),
    option: CheckmateSelectHelper.getSelectOptionStyles,
    menu: () => ({
        boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)',
    }),
};

interface CheckmateNSelectState {
    isOpen: boolean;
    value: MultiValue<IMultiSelectOptions> | null | undefined;
}

interface CheckmateNSelectProps {
    options: IMultiSelectOptions[];
    value: IMultiSelectOptions[];
    placeholder?: string;
    name?: string;
    displayName?: string;
    widthPercent?: string;
    onChange: (optionsList: any) => void;
    includeSelectedValuesInfo?: boolean;
    disabled?: boolean;
}

export default class CheckmateNSelect extends Component<
    CheckmateNSelectProps,
    CheckmateNSelectState
> {
    state: CheckmateNSelectState = { isOpen: false, value: undefined };

    componentDidMount() {
        this.setState({ value: this.props.value });
    }

    toggleOpen = () => {
        this.setState((state) => ({ isOpen: !state.isOpen }));
    };

    onSelectChange = (value: MultiValue<IMultiSelectOptions>) => {
        //this.toggleOpen();
        this.setState({ value });
        this.props.onChange(value);
    };

    getSelectedValueText = (value?: MultiValue<IMultiSelectOptions> | null) => {
        if (value === null || !value || value.length === 0)
            return this.props.placeholder || '-- Select --';
        if (value.length === 1) return value[0].label;
        return value.length + ' selected';
    };

    getSelectedValuesInfoText = (value?: MultiValue<IMultiSelectOptions> | null) => {
        if (value === null || !value || value.length === 0) return '';
        let text = '';
        for (let index = 0; index < value.length; index++) {
            text += value[index].label + '\n';
        }

        return text;
    };

    render() {
        const { isOpen, value } = this.state;

        const spanStyle = {
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            padding: '5px 30px 5px 5px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: '24px',
            textAlign: 'left' as const,
            verticalAlign: 'middle',
        };

        return (
            <>
                <div className={'d-inline-block w-' + (this.props.widthPercent || '100')}>
                    <Dropdown
                        isOpen={isOpen}
                        onClose={this.toggleOpen}
                        target={
                            <button
                                className="btn btn-default w-100 d-flex flex-row-reverse position-relative"
                                onClick={this.toggleOpen}
                                disabled={this.props.disabled}
                                style={{
                                    height: 34.4,
                                    background: this.props.disabled ? 'rgb(242, 242, 242)' : '#fff',
                                    borderColor: this.props.disabled ? 'rgb(199, 200, 202)' : '',
                                }}
                            >
                                <span
                                    style={spanStyle}
                                    className={
                                        'float-start flex-grow-1 text-nowrap position-absolute ' +
                                        (this.getSelectedValueText(value) ==
                                        (this.props.placeholder || '--Select--')
                                            ? ''
                                            : 'color-black')
                                    }
                                >
                                    {this.getSelectedValueText(value)}
                                </span>
                                <i
                                    className="fal fa-lg fa-angle-down ps-2 flex-grow-0"
                                    style={{ paddingTop: '10px', alignSelf: 'baseline' }}
                                />
                            </button>
                        }
                    >
                        <Select
                            autoFocus
                            backspaceRemovesValue={false}
                            closeMenuOnSelect={false}
                            components={{ DropdownIndicator, IndicatorSeparator: null }}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            isClearable={true}
                            isDisabled={this.props.disabled}
                            isMulti={true}
                            menuIsOpen
                            onChange={this.onSelectChange}
                            onInputChange={(newValue, actionMeta) => {
                                if (actionMeta.action === 'set-value') {
                                    return actionMeta.prevInputValue;
                                }
                            }}
                            options={this.props.options}
                            styles={selectStyles}
                            tabSelectsValue={false}
                            value={value}
                        />
                    </Dropdown>
                </div>
                {this.props.includeSelectedValuesInfo ? (
                    <div className="d-inline-block">
                        <Help
                            type={ApplicationHelpType.Check}
                            title={
                                this.props.displayName
                                    ? this.props.displayName + ' - Selected Values'
                                    : 'Selected Values'
                            }
                            helpText={this.getSelectedValuesInfoText(value)}
                        />
                    </div>
                ) : null}
            </>
        );
    }
}

// styled components

const Menu = (props: JSX.IntrinsicElements['div']) => {
    const shadow = 'hsla(218, 50%, 10%, 0.1)';
    return (
        <div
            style={{
                backgroundColor: 'white',
                borderRadius: 4,
                boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
                marginTop: 8,
                position: 'absolute',
                zIndex: 2,
            }}
            {...props}
        />
    );
};

const Blanket = (props: JSX.IntrinsicElements['div']) => (
    <div
        style={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: 'fixed',
            zIndex: 1,
        }}
        {...props}
    />
);

interface DropdownProps {
    readonly isOpen: boolean;
    readonly target: ReactNode;
    readonly onClose: () => void;
    children: any;
}

const Dropdown: FunctionComponent<DropdownProps> = ({ children, isOpen, target, onClose }) => (
    <div style={{ position: 'relative' }}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);

const Svg = (p: JSX.IntrinsicElements['svg']) => (
    <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
);

const DropdownIndicator = () => (
    <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
        <Svg>
            <path
                d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </Svg>
    </div>
);
