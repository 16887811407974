import * as React from 'react';

import { ApiRoutes } from '../../utilities/ApiRoutes';
import { IUserModel } from '../../interfaces/IUser';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { config } from '../../config/config';

//var newReportCheckInterval: number;

interface IReportStatusProps {
    user: IUserModel;
}

interface IReportStatusState {
    userHasUnreadReports?: boolean;
}

export class ReportStatus extends React.Component<IReportStatusProps, IReportStatusState> {
    constructor(props: IReportStatusProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.loadData();
        //newReportCheckInterval = window.setInterval(this.loadData, 10000);
    }

    componentWillUnmount() {
        //clearInterval(newReportCheckInterval);
    }

    loadData = async () => {
        const id = btoa(this.props.user.guid + '|' + this.props.user.securityStamp);
        const userHasUnreadReports = await fetch(
            config.baseUrl + ApiRoutes.ReportStatus.replace('{id}', id),
            {
                method: 'GET',
                credentials: 'omit',
            }
        ).then((response) => {
            return response.json();
        });

        this.setState({ userHasUnreadReports: userHasUnreadReports });
    };

    getReportListUrl() {
        let url = LocalRoutes.UserReportList;
        const currentUrl = window.location.href.replace('https://', '').replace('http://', '');
        if (
            currentUrl.substring(currentUrl.indexOf('/')).toUpperCase() ==
            LocalRoutes.UserReportList.toUpperCase()
        ) {
            url += '?reload=true';
        }

        return url;
    }

    render() {
        const linkUrl = this.getReportListUrl();
        if (this.state.userHasUnreadReports) {
            return (
                <Link to={linkUrl} className="align-self-center">
                    &nbsp;&nbsp;
                    <i className="fal fa-file-exclamation color-yellow" />
                </Link>
            );
        } else {
            return null;
        }
    }
}
