import { useLocation, useParams } from 'react-router-dom';

import { EntityStrategy } from '../strategy/EntityStrategy';
import { IUserModel } from '../../interfaces/IUser';

function EntityStrategyWrapper(props: { user: IUserModel }) {
    const { type } = useParams();
    const { key } = useLocation();
    return <EntityStrategy type={parseInt(type || '')} user={props.user} refreshIdentifier={key} />;
}

export default EntityStrategyWrapper;
