import * as React from 'react';

import {
    DataScopesEnum,
    EmptyGuid,
    FavoriteScopeEnum,
    FavoriteTypesEnum,
    UserRightsEnum,
} from '../../utilities/Constants';
import { ILookupModel, IMultiSelectOptions } from '../../interfaces/ILookup';

import ApiClient from '../../services/apiClient';
import Authorization from '../../stores/Authorization';
import { CheckmateDialog } from './dialog';
import CheckmateSelect from './CheckmateSelect';
import { IFavoriteModel } from '../../interfaces/IFavorite';
import { INoteUISearchModel } from '../../interfaces/IQuery';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from './Loader';
import { Modal } from 'react-bootstrap';
import Sort from '../../stores/Sort';
import ValidateUtils from '../../shared/validations';

const _apiClient = new ApiClient();

interface IFavoriteProps {
    user: IUserModel;
    type: ILookupModel;
    currentFavoriteSettingJson: string;
    currentFavoriteDetailsString: string;
    handleFavoriteSelected?: (settingJson?: string) => void;
    applyFavoriteSelected: (settingJson?: string, isDefault?: boolean) => void;
    handleDefaultFavoriteChange: (settingJson?: string) => void;
    onValidateFilters?: () => boolean;
}

interface IFavoriteState {
    showAddNewModal?: boolean;
    showFavoritesModal?: boolean;
    pendingResponse: boolean;
    validation: IValidation;
    favorites: IFavoriteModel[];
    selectedUserFavoriteGuid?: string;
    selectedZoneFavoriteGuid?: string;
    selectedFavoriteScope?: number;
    newFavorite?: IFavoriteModel;
    showSuccessDialog: boolean;
    showFavoriteDetailsDialog?: boolean;
    showDeleteFavoriteDialog?: boolean;
}

export class Favorite extends React.Component<IFavoriteProps, IFavoriteState> {
    constructor(props: any) {
        super(props);
        this.state = {
            pendingResponse: false,
            validation: {},
            favorites: [],
            showSuccessDialog: false,
        };
    }

    componentDidMount() {
        this.loadFavorites();
    }

    componentDidUpdate(prevProps: IFavoriteProps) {
        if (
            prevProps.currentFavoriteSettingJson != this.props.currentFavoriteSettingJson &&
            this.state.newFavorite
        ) {
            const newFavorite = this.state.newFavorite;
            newFavorite.settingJson = this.props.currentFavoriteSettingJson;
            this.setState({ newFavorite: newFavorite });
        }
    }

    loadFavorites = async () => {
        const response = await _apiClient.getFavorites(this.props.type.id);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        this.setState({ favorites: response.payload! });
    };

    addNew = async () => {
        const newFavorite = this.state.newFavorite;
        const validation = this.state.validation;

        if (!newFavorite) {
            validation.model = ['Invalid Input'];
            this.setState({ validation: validation });
            return;
        }

        if (!newFavorite.settingJson) {
            validation.model = ['Favorite Setting Data is missing'];
            this.setState({ validation: validation });
            return;
        }

        if (!newFavorite.name) {
            validation.name = ['Name is Required'];
            this.setState({ validation: validation });
            return;
        }

        const settings: INoteUISearchModel = JSON.parse(newFavorite.settingJson);
        if (settings.noteTypes?.length == 0) {
            validation.name = ['At least one Type is required.'];
            this.setState({ validation: validation });
            return;
        }

        if (
            newFavorite.favoriteScope != FavoriteScopeEnum.User &&
            (!newFavorite.dataScope || newFavorite.dataScope.id === 0)
        ) {
            validation.dataScope = ['Required'];
            this.setState({ validation: validation });
            return;
        }

        this.setState({ pendingResponse: true });
        const response = await _apiClient.addFavorite(this.state.newFavorite!);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        newFavorite.guid = response.payload!.data;
        let favorites = this.state.favorites;
        if (newFavorite.default) {
            newFavorite.name += ' (Default)';
            favorites.map((item: IFavoriteModel) => {
                item.default = false;
                if (item.name) item.name = item.name.replace(' (Default)', '');
            });
        }
        favorites.push(JSON.parse(JSON.stringify(newFavorite)));
        favorites = favorites.sort(Sort.compareValues('name'));
        this.setState({
            pendingResponse: false,
            favorites: favorites,
            showFavoritesModal: false,
            newFavorite: undefined,
            showSuccessDialog: true,
            showAddNewModal: false,
            validation: {},
        });
        if (newFavorite.default) this.props.handleDefaultFavoriteChange(newFavorite.settingJson);
    };

    handleFavoriteSelected = () => {
        if (!this.state.selectedUserFavoriteGuid && !this.state.selectedZoneFavoriteGuid) {
            const validation = this.state.validation;
            validation.favorites = ['Please select a favorite'];
            this.setState({ validation: validation });
            return;
        }

        const match =
            this.state.selectedFavoriteScope === FavoriteScopeEnum.User
                ? this.state.favorites.find((x) => x.guid === this.state.selectedUserFavoriteGuid)
                : this.state.favorites.find((x) => x.guid === this.state.selectedZoneFavoriteGuid);
        if (match) {
            this.setState({ showFavoritesModal: false });
            this.props.applyFavoriteSelected(match.settingJson, match.default);
        }
    };

    handleChangeValue = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        const validation = this.state.validation;
        let newFavorite = this.state.newFavorite;
        if (!newFavorite)
            newFavorite = {
                type: this.props.type,
                settingJson: this.props.currentFavoriteSettingJson,
                favoriteScope: FavoriteScopeEnum.User,
            };

        switch (name) {
            case 'name':
                if (value) validation.name = [''];
                newFavorite.name = value;
                this.setState({ newFavorite: newFavorite, validation: validation });
                break;
            case 'description':
                newFavorite.description = value;
                this.setState({ newFavorite: newFavorite, validation: validation });
                break;
            default:
                break;
        }
    };

    handleCheckedChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newFavorite = this.state.newFavorite;
        if (!newFavorite)
            newFavorite = {
                type: this.props.type,
                settingJson: this.props.currentFavoriteSettingJson,
                favoriteScope: FavoriteScopeEnum.User,
            };
        switch (event.target.name) {
            case 'chkDefault':
                newFavorite.default = event.target.checked;
                break;
            default:
                break;
        }
        this.setState({ newFavorite: newFavorite });
    };

    enableDelete = () => {
        if (this.state.selectedUserFavoriteGuid) {
            const match = this.state.favorites.find(
                (x) => x.guid === this.state.selectedUserFavoriteGuid
            );
            if (match && match.favoriteScope === FavoriteScopeEnum.User) return true;
        } else if (this.state.selectedZoneFavoriteGuid) {
            const match = this.state.favorites.find(
                (x) => x.guid === this.state.selectedZoneFavoriteGuid
            );
            if (
                match &&
                match.favoriteScope === FavoriteScopeEnum.Zone &&
                Authorization.userHasRight(UserRightsEnum.AddZoneFavorites, this.props.user)
            )
                return true;
        }
        return false;
    };

    delete = async () => {
        const guid = this.state.selectedUserFavoriteGuid || this.state.selectedZoneFavoriteGuid;

        if (!guid) {
            const validation = this.state.validation;
            validation.favorites = ['Please select a favorite to delete'];
            this.setState({ validation: validation });
            return;
        }

        const response = await _apiClient.deleteFavorite(guid);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
                showDeleteFavoriteDialog: false,
            });
            return;
        }

        const favorites = this.state.favorites;
        const matchIndex = favorites.findIndex((x) => x.guid === guid);
        const match = favorites.find((x) => x.guid === guid);
        if (matchIndex >= 0) {
            favorites.splice(matchIndex, 1);
            this.setState({
                favorites: favorites,
                selectedUserFavoriteGuid: undefined,
                selectedZoneFavoriteGuid: undefined,
                showDeleteFavoriteDialog: false,
            });
            if (match!.default) this.props.handleDefaultFavoriteChange();
        }
    };

    getSelectedFavoriteDescription = () => {
        const match = this.state.favorites.find(
            (x) => x.guid === this.state.selectedUserFavoriteGuid
        );
        if (match) return match.description || '';
        else return '';
    };

    getSelectedFavoriteName = () => {
        const match = this.state.favorites.find(
            (x) => x.guid === this.state.selectedUserFavoriteGuid
        );
        if (match) return match.name || '';
        else return '';
    };

    allowZoneFavorites = () => {
        if (
            (this.props.type.id === FavoriteTypesEnum.EasyUpdate ||
                this.props.type.id == FavoriteTypesEnum.Query) &&
            Authorization.userHasRight(UserRightsEnum.AddZoneFavorites, this.props.user)
        )
            return true;
        return false;
    };

    allowSetAsDefault = () => {
        return (
            this.state.newFavorite &&
            this.state.newFavorite.favoriteScope === FavoriteScopeEnum.User &&
            (this.props.type.id === FavoriteTypesEnum.MyAlertList ||
                this.props.type.id === FavoriteTypesEnum.ZoneAlertList ||
                this.props.type.id === FavoriteTypesEnum.CaseList)
        );
    };

    getFavoriteScopeOptions = () => {
        const options: IMultiSelectOptions[] = [];
        options.push({
            label: 'User Favorite',
            value: FavoriteScopeEnum.User.toString(),
            id: FavoriteScopeEnum.User,
        });
        options.push({
            label: 'Zone Favorite',
            value: FavoriteScopeEnum.Zone.toString(),
            id: FavoriteScopeEnum.Zone,
        });
        return options;
    };

    getSelectedFavoriteScopeValue = () => {
        if (!this.state.newFavorite) return;
        switch (this.state.newFavorite.favoriteScope) {
            case FavoriteScopeEnum.User:
                return {
                    label: 'User Favorite',
                    value: FavoriteScopeEnum.User.toString(),
                    id: FavoriteScopeEnum.User,
                };
            case FavoriteScopeEnum.Zone:
                return {
                    label: 'Zone Favorite',
                    value: FavoriteScopeEnum.Zone.toString(),
                    id: FavoriteScopeEnum.Zone,
                };
            default:
                break;
        }
    };

    getDataScopeOptions = () => {
        const options: IMultiSelectOptions[] = [];
        options.push({
            label: DataScopesEnum.National.DisplayName,
            value: DataScopesEnum.National.Value.toString(),
            id: DataScopesEnum.National.Value,
        });
        options.push({
            label: DataScopesEnum.LocalBasic.DisplayName,
            value: DataScopesEnum.LocalBasic.Value.toString(),
            id: DataScopesEnum.LocalBasic.Value,
        });
        //options.push({ label: 'All', value: '-1', id: -1 });
        return options;
    };

    getSelectedDataScopeValue = () => {
        if (!this.state.newFavorite) return;
        const dataScopeId = this.state.newFavorite.dataScope
            ? this.state.newFavorite.dataScope.id
            : 0;
        switch (dataScopeId) {
            case DataScopesEnum.National.Value:
                return {
                    label: DataScopesEnum.National.DisplayName,
                    value: DataScopesEnum.National.Value.toString(),
                    id: DataScopesEnum.National.Value,
                };
            case DataScopesEnum.LocalBasic.Value:
                return {
                    label: DataScopesEnum.LocalBasic.DisplayName,
                    value: DataScopesEnum.LocalBasic.Value.toString(),
                    id: DataScopesEnum.LocalBasic.Value,
                };
            case -1:
                return { label: 'All', value: '-1', id: -1 };
            default:
                break;
        }
    };

    getFavoriteOptions = (values: IFavoriteModel[]) => {
        const options: IMultiSelectOptions[] = [];
        for (let i = 0; i < values.length; i++) {
            options.push({
                label: values[i].name!,
                value: values[i].name!,
                id: i,
                guid: values[i].guid!,
            });
        }
        return options;
    };

    getSelectedFavoriteValue = (guid: string, favoriteScope: FavoriteScopeEnum) => {
        const match = this.state.favorites.find((x) => x.guid === guid);
        if (match && this.state.selectedFavoriteScope === favoriteScope)
            return { label: match.name!, value: match.name!, id: 0, guid: match.guid! };
        else return { label: '', value: '', id: -1, guid: '' };
    };

    handleSaveFavoriteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (typeof this.props.onValidateFilters === 'function') {
            const hasError = this.props.onValidateFilters();
            if (hasError) return;
        }

        this.setState({
            showAddNewModal: true,
            newFavorite: {
                type: this.props.type,
                settingJson: this.props.currentFavoriteSettingJson,
                favoriteScope: FavoriteScopeEnum.User,
            },
        });
    };

    handleCheckmateSelectChange = (selectedItem: any, name: string, subKey?: number) => {
        let newFavorite = this.state.newFavorite;
        if (!newFavorite)
            newFavorite = {
                type: this.props.type,
                settingJson: this.props.currentFavoriteSettingJson,
                favoriteScope: FavoriteScopeEnum.User,
            };
        const validation = this.state.validation;
        const valueId = selectedItem ? selectedItem.id : 0;
        const valueGuid =
            selectedItem && selectedItem.guid && selectedItem.guid != EmptyGuid
                ? selectedItem.guid
                : '';
        switch (name) {
            case 'favoriteScope':
                if (valueId) validation.favoriteScope = [''];
                newFavorite.favoriteScope = valueId;
                this.setState({ newFavorite: newFavorite, validation: validation });
                break;
            case 'dataScope':
                if (valueId) newFavorite.dataScope = { id: valueId };
                else newFavorite.dataScope = undefined;
                this.setState({ newFavorite: newFavorite, validation: validation });
                break;
            case 'favorites':
                if (valueGuid) validation.favorites = [''];
                this.setState({
                    selectedUserFavoriteGuid: subKey === FavoriteScopeEnum.User ? valueGuid : '',
                    selectedZoneFavoriteGuid: subKey === FavoriteScopeEnum.Zone ? valueGuid : '',
                    selectedFavoriteScope: subKey,
                    validation: validation,
                });

                if (this.props.handleFavoriteSelected) {
                    let settingJson = '';
                    if (valueGuid) {
                        const match = this.state.favorites.find((x) => x.guid === valueGuid);
                        if (match && match.settingJson) settingJson = match.settingJson;
                    }
                    this.props.handleFavoriteSelected(settingJson);
                }
                break;
            default:
                break;
        }
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;
        return (
            <span>
                <button
                    className="btn btn-no-bg float-end text-gray"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        this.setState({ showFavoritesModal: true });
                    }}
                >
                    <i className="fal fa-heart" />
                    &nbsp;Favorites
                </button>
                <button
                    className="btn btn-no-bg float-end text-gray"
                    onClick={this.handleSaveFavoriteClick}
                >
                    <i className="fal fa-heart-square" />
                    &nbsp;Save Favorite View
                </button>
                {this.state.showAddNewModal && this.state.newFavorite ? (
                    <Modal
                        centered
                        size="lg"
                        show={true}
                        onHide={() => {
                            this.setState({ showAddNewModal: false });
                        }}
                        backdrop={false}
                        dialogClassName="board-settings-dialog"
                    >
                        <Modal.Header>
                            <Modal.Title> Save Favorite View </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="validation-error-message">
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                            <div className="container-fluid">
                                {this.allowZoneFavorites() ? (
                                    <div className="row mb-2">
                                        <label className="control-label col-sm-2" htmlFor="type">
                                            Type*
                                        </label>
                                        <div className="col-sm-4">
                                            <CheckmateSelect
                                                options={this.getFavoriteScopeOptions()}
                                                name="favoriteScope"
                                                value={this.getSelectedFavoriteScopeValue()}
                                                onChange={(e: any) => {
                                                    this.handleCheckmateSelectChange(
                                                        e,
                                                        'favoriteScope'
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="text-danger col-sm-6">
                                            {this.state.validation.favoriteScope}
                                        </div>
                                    </div>
                                ) : null}
                                {this.state.newFavorite.favoriteScope === FavoriteScopeEnum.Zone ? (
                                    <div className="row mb-2">
                                        <label
                                            className="control-label col-sm-2"
                                            htmlFor="dataScope"
                                        >
                                            Data Scope*
                                        </label>
                                        <div className="col-sm-4">
                                            <CheckmateSelect
                                                options={this.getDataScopeOptions()}
                                                name="dataScope"
                                                value={this.getSelectedDataScopeValue()}
                                                onChange={(e: any) => {
                                                    this.handleCheckmateSelectChange(
                                                        e,
                                                        'dataScope'
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="text-danger col-sm-6">
                                            {this.state.validation.dataScope}
                                        </div>
                                    </div>
                                ) : null}
                                <div className="row mb-2">
                                    <label className="control-label col-sm-2" htmlFor="name">
                                        Name*
                                    </label>
                                    <div className="col-sm-10">
                                        <input
                                            className="form-control d-inline-block"
                                            type="text"
                                            maxLength={200}
                                            name="name"
                                            value={this.state.newFavorite.name}
                                            onChange={this.handleChangeValue}
                                        />
                                        {this.allowSetAsDefault() ? (
                                            <span className="btn btn-no-bg text-gray">
                                                <input
                                                    name="chkDefault"
                                                    type="checkbox"
                                                    className="form-check-input d-inline-block"
                                                    onChange={this.handleCheckedChanged}
                                                    checked={this.state.newFavorite.default}
                                                />
                                                &nbsp;&nbsp;Set as Default View
                                            </span>
                                        ) : null}
                                        <span className="text-danger">
                                            <br></br>
                                            &nbsp;{this.state.validation.name}
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <label className="control-label" htmlFor="description">
                                        Description
                                    </label>
                                    <span className="text-danger">
                                        &nbsp;{this.state.validation.description}
                                    </span>
                                    <textarea
                                        className="form-control"
                                        value={this.state.newFavorite.description}
                                        name="description"
                                        rows={4}
                                        maxLength={2000}
                                        onChange={this.handleChangeValue}
                                    />
                                </div>
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({
                                            showAddNewModal: false,
                                            newFavorite: undefined,
                                            validation: {},
                                        })
                                    }
                                >
                                    Cancel
                                </button>
                                <button className="btn btn-orange float-end" onClick={this.addNew}>
                                    Save
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}
                {this.state.showFavoritesModal ? (
                    <Modal
                        centered
                        show={this.state.showFavoritesModal}
                        onHide={() => this.setState({ showFavoritesModal: false })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> Favorites </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="padding-bottom-med">
                                <span className="text-danger">
                                    {this.state.validation.favorites}
                                </span>
                            </div>
                            {this.state.favorites.filter(
                                (x) => x.favoriteScope === FavoriteScopeEnum.Zone
                            ).length > 0 ? (
                                <div className="row mb-2">
                                    <label className="control-label col-sm-3 pt-1">
                                        Zone Favorites
                                    </label>
                                    <div className="col-sm-7">
                                        <CheckmateSelect
                                            options={this.getFavoriteOptions(
                                                this.state.favorites.filter(
                                                    (x) =>
                                                        x.favoriteScope === FavoriteScopeEnum.Zone
                                                )
                                            )}
                                            name="favorites"
                                            value={this.getSelectedFavoriteValue(
                                                this.state.selectedZoneFavoriteGuid || '',
                                                FavoriteScopeEnum.Zone
                                            )}
                                            onChange={(e: any) => {
                                                this.handleCheckmateSelectChange(
                                                    e,
                                                    'favorites',
                                                    FavoriteScopeEnum.Zone
                                                );
                                            }}
                                        />
                                    </div>
                                    {this.state.selectedZoneFavoriteGuid &&
                                    this.props.currentFavoriteDetailsString &&
                                    this.state.favorites.find(
                                        (x) => x.guid === this.state.selectedZoneFavoriteGuid
                                    ) &&
                                    this.state.favorites.find(
                                        (x) => x.guid === this.state.selectedZoneFavoriteGuid
                                    )!.favoriteScope === FavoriteScopeEnum.Zone ? (
                                        <button
                                            className="col-sm-1 btn btn-no-bg"
                                            onClick={() =>
                                                this.setState({ showFavoriteDetailsDialog: true })
                                            }
                                        >
                                            <i className="fal fa-lg fa-check-circle color-gray" />
                                        </button>
                                    ) : null}
                                </div>
                            ) : null}
                            <div className="row">
                                <label className="control-label col-sm-3 pt-1">
                                    User Favorites
                                </label>
                                <div className="col-sm-7">
                                    <CheckmateSelect
                                        options={this.getFavoriteOptions(
                                            this.state.favorites.filter(
                                                (x) => x.favoriteScope === FavoriteScopeEnum.User
                                            )
                                        )}
                                        name="favorites"
                                        value={this.getSelectedFavoriteValue(
                                            this.state.selectedUserFavoriteGuid || '',
                                            FavoriteScopeEnum.User
                                        )}
                                        onChange={(e: any) => {
                                            this.handleCheckmateSelectChange(
                                                e,
                                                'favorites',
                                                FavoriteScopeEnum.User
                                            );
                                        }}
                                    />
                                </div>
                                {this.state.selectedUserFavoriteGuid &&
                                this.props.currentFavoriteDetailsString &&
                                this.state.favorites.find(
                                    (x) => x.guid === this.state.selectedUserFavoriteGuid
                                ) &&
                                this.state.favorites.find(
                                    (x) => x.guid === this.state.selectedUserFavoriteGuid
                                )!.favoriteScope === FavoriteScopeEnum.User ? (
                                    <button
                                        className="col-sm-1 btn btn-no-bg"
                                        onClick={() =>
                                            this.setState({ showFavoriteDetailsDialog: true })
                                        }
                                    >
                                        <i className="fal fa-lg fa-check-circle color-gray" />
                                    </button>
                                ) : null}
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                {this.enableDelete() ? (
                                    <button
                                        className="btn btn-no-bg float-end"
                                        onClick={() =>
                                            this.setState({ showDeleteFavoriteDialog: true })
                                        }
                                    >
                                        <i className="fal fa-lg fa-trash-alt" />
                                    </button>
                                ) : null}
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={this.handleFavoriteSelected}
                                >
                                    Select
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() =>
                                        this.setState({ showFavoritesModal: false, validation: {} })
                                    }
                                >
                                    Cancel
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                {this.state.showFavoriteDetailsDialog ? (
                    <Modal
                        centered
                        show={this.state.showFavoriteDetailsDialog}
                        onHide={() => this.setState({ showFavoriteDetailsDialog: false })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> {this.getSelectedFavoriteName()} </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(this.state.selectedUserFavoriteGuid ||
                                this.state.selectedZoneFavoriteGuid) &&
                            this.props.currentFavoriteDetailsString
                                ? this.props.currentFavoriteDetailsString
                                      .replace(/\\n/gi, '\n')
                                      .split('\n')
                                      .map((item, key) => {
                                          return (
                                              <span key={key}>
                                                  {item}
                                                  <br />
                                              </span>
                                          );
                                      })
                                : null}
                            {this.getSelectedFavoriteDescription() ? (
                                <div>
                                    <label className="control-label" htmlFor="description">
                                        DESCRIPTION
                                    </label>
                                    <div>{this.getSelectedFavoriteDescription()}</div>
                                </div>
                            ) : null}
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-black float-end"
                                    onClick={() =>
                                        this.setState({
                                            showFavoriteDetailsDialog: false,
                                            validation: {},
                                        })
                                    }
                                >
                                    OK
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                ) : null}

                <CheckmateDialog
                    isShowingModal={this.state.showSuccessDialog}
                    title="Save Favorite View"
                    body="The Favorite View was successfully saved."
                    handleClose={() => {
                        this.setState({ showSuccessDialog: false });
                    }}
                    cancelText="Ok"
                    dialogClassName="user-profile-dialog"
                    closeButtonClassName="btn btn-black float-end"
                />

                <CheckmateDialog
                    isShowingModal={this.state.showDeleteFavoriteDialog || false}
                    body="Are you sure you want to delete this favorite? This operation is permanent and cannot be reverted back."
                    handleClose={() => this.setState({ showDeleteFavoriteDialog: false })}
                    handleConfirm={this.delete}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </span>
        );
    }
}
